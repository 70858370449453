import { OrderOfMeritEnums } from "./OrderOfMeritEnums";

export class OrderOfMeritConfig {
  orderOfMeritConfigId: number;
  competitionHostId: number;
  title: string;
  startDate: Date;
  endDate: Date;
  description: string;
  gameFormat: OrderOfMeritEnums.OrderOfMeritGameTypeEnum;
  pointsFormat: OrderOfMeritEnums.PointsFormatEnum;
  noOfTournaments: number;
  bestScore: boolean;
  worstScore: boolean;
  bestOf: number;
  meritPoints: boolean;
  pointsAllocation: string;
  qualification: number;
  noOfGolfers: number;
  numberOfCurrentGames: number;
}
