<div class="body">
  <div class="component">
    <div class="header">
      <img src="../../../assets/images/poweredby.png" alt="">
    </div>
    <div class="order-info">
      <h2>{{orderOfMeritConfig.title}} 1</h2>
      <p id="info-title">{{this.orderOfMeritConfig.description}}</p>
      <div class="middle-panels">
        <div class="paragraph-container">
          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms-icons-calendarC.svg" alt="" />
            {{startFormattedDate}} - {{endFormattedDate}}
          </p>
        </div>
        <div class="paragraph-container">
          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms_icons_podium.svg" alt="" />
            <span *ngIf="pointsFormatStringValue === 'MeritPoints'"> Custom Merits Points</span>
            <span *ngIf="pointsFormatStringValue === 'StrokePlay'"> Accumulated Strokeplay</span>
            <span *ngIf="pointsFormatStringValue === 'StableFord'"> Accumulated Stableford</span>
          </p>
        </div>
        <div class="paragraph-container" *ngIf="orderOfMeritConfig.bestOf != 0">
          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms-icons-tournamentsC.svg" alt="" />
            Best {{orderOfMeritConfig.bestOf}}
          </p>
        </div>
        <div class="paragraph-container" [hidden]="!orderOfMeritConfig.meritPoints">
          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms-icons-podiumC.svg" alt="" />
            Merit Points
          </p>
        </div>
      </div>
    </div>

    <div class="leaderboard">
      <table id="leaderboardTable">
        <thead>
          <p class="title">Leaderboard</p>
          <tr>
            <th style="padding-left: 20px; font-size: 15px; width:5%;">Pos</th>
            <th style="font-size: 15px; margin-left:-30px; width:30%;">Name</th>
            <th style="font-size: 15px;">HCP</th>
            <th style="font-size:15px;">Played</th>
            <th style="font-size: 15px;">Total</th>
            <th *ngFor="let score of tournamentScores; let i = index" style="color: #e65c24; font-weight: bold;">
              {{ getDaySuffixVal(i + 1) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of leaderboardData">
            <td class="Pos">{{ item.Position }}</td>
            <td>
              <img [src]="item.Thumbnail.Url" alt="Thumbnail" class="thumbnail">
              <span class="title_image">{{ item.Title }}</span>

              <span *ngIf="item.IsPremium" class="spanone">
                <img src="assets/images/premium_icon.png" alt="premium_icon" class="premium-image">
              </span>

            </td>
            <td style="font-size: 15px;">{{formatHCP(item.HCP) }}</td>
            <td style="font-size: 15px;">{{ item.Played }}</td>
            <td style="font-size: 15px;">{{ item.Total }}</td>
            <td *ngFor="let score of item.IndexOfScores" class="round_data">{{ score.Points }}</td>
          </tr>
        </tbody>
      </table>
      <div [hidden]="leaderboardData.length !== 0" class="condition-leaderboard">
        <div class="empty-leaderboard">
          <img src="../../../assets/icons/order-of-merit-icons/tms_icons_add golfer.svg" alt="">
          <p id="title">No players have qualified</p>
          <p id="text">When a golfer qualifies for the order of merit they will appear here.</p>
        </div>
      </div>
    </div>
  </div>
</div>