<mat-tab-group [(selectedIndex)]="selectedIndex">
  <ng-container *ngFor="let item of model; let i = index">
    <mat-tab *ngIf="item.visible && !(isMobile && item.label === 'Stats')" [disabled]="item.disabled" [label]="item.label" [ngClass]="{
      'ui-tabmenuitem-hasicon': item.icon 
    }">
      <ng-template mat-tab-label>
        <span class="ui-menuitem-icon fa" [ngClass]="item.icon" *ngIf="item.icon"></span>
        <span class="ui-menuitem-text" (click)="itemClick($event, item, i)">{{ item.label }}</span>
      </ng-template>
      <div *ngIf="!item.routerLink; else routerLinkTemplate">
        <a [href]="item.url || '#'" class="ui-menuitem-link ui-corner-all" (click)="itemClick($event, item, i)"
          [attr.target]="item.target" [attr.title]="item.title">
        </a>
      </div>
      <ng-template #routerLinkTemplate>
        <a [routerLink]="item.routerLink" [queryParams]="item.queryParams" class="ui-menuitem-link ui-corner-all"
          (click)="itemClick($event, item, i)" [attr.target]="item.target" [attr.title]="item.title">
        </a>
      </ng-template>
    </mat-tab>
  </ng-container>
</mat-tab-group>
