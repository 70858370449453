<div class="row round-messaging-component">
    <div class="col-xs-12">
        <div class="panel">
            <div class="panel-content">
                <div class="row text-right">
                    <div class="col-md-12">
                        <label class="pull-left m-l-20 m-t-5 f-20 w-800 mobile-hidden">Messaging</label>
                    </div>
                </div>
                <div class="row p-t-20 ">

                    <div class="col-lg-12 tabs tabs-linetriangle p-20">
                        <form (ngSubmit)="onSubmit(messagingForm.value)" [formGroup]="messagingForm">

                            <div class="form-group col-md-3">
                                <p class="text-muted bold">Tee Times</p>
                                <select class="form-control input-lg tee-time-field" data-placeholder="Select an option..." formControlName="TeeTimeID">
                                    <option *ngFor="let p of currentTeeTimes" [value]="p.value">{{p.label}}</option>
                                </select>
                                <div *ngIf="messagingForm.controls.TeeTimeID.invalid && messagingForm.dirty" class="alert alert-danger tee-time-field">
                                    Tee time is required
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <p class="text-muted bold">Title</p>
                                <input type="text" class="form-control input-lg title-field" formControlName="Title" placeholder="Enter Title" maxlength="50">
                                <div *ngIf="messagingForm.controls.Title.invalid && messagingForm.dirty" class="alert alert-danger title-field">
                                    Title is required
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <p class="text-muted bold">Message</p>
                                <textarea class="form-control area-lg big-field" formControlName="Messages" placeholder="Enter Message" maxlength="250" rows="3"></textarea>                                
                                <div *ngIf="messagingForm.controls.Messages.invalid && messagingForm.dirty" class="alert alert-danger big-field">
                                    Message is required
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <p class="text-muted bold">Link</p>
                                <input type="text" class="form-control input-lg big-field" formControlName="Link" placeholder="Enter Link" maxlength="100">                                
                            </div>

                            <div class="p-t-10">
                                <button type="submit" class="btn btn-default  pull-right">Send</button>
                            </div>

                        </form>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>