import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";

export function encodeString(s) {
  var cryptoKey = environment.cryptoSecret;
  var key = CryptoJS.enc.Utf8.parse(cryptoKey);
  var iv = CryptoJS.enc.Utf8.parse(cryptoKey);
  var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(s), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  return encrypted;
}
