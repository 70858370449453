<p-dialog class="stats-modal" [(visible)]="displayStatsDialog" [responsive]="true" [modal]="true" [blockScroll]="true" [resizable]="true" [draggable]="false" (visibleChange)="visibleChange($event)">
    <p-header>
        {{data.title}}
    </p-header>
    <div style="width:100%;">
        <div style="display: flex; flex-direction: column">
            <div class="stats-header">
                <span class="type gross">GROSS</span>
                <label class="switch pull-left">
                    <input type="checkbox" [(ngModel)]="statsType" (change)="onStatsTypeChange($event)">
                    <span class="slider round"></span>
                </label>
                <span class="type">NET</span>
            </div>
            <div class="stats-container">
                <div class="stats-header">
                    <span class="title">&nbsp;</span>
                    <span class="total">{{data.total}}</span>
                </div>
                <div class="stats-content" *ngFor="let player of data.players; let index = index">
                    <div class="pos"><span>{{player.pos}}</span></div>
                    <div class="player">
                        <span>{{player.name}}</span>
                    </div>
                    <div *ngIf="statsType" class="value"><span>{{player.valueNet}}</span></div>
                    <div *ngIf="!statsType" class="value"><span>{{player.valueGross}}</span></div>
                </div>
            </div>
        </div>
        <div class="col-md-12 button-section">
            <div class="btn-group pull-right m-r-0">
                <button class="btn btn-action-ok w-600 f-13 m-b-0" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</p-dialog>