/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tournament-overview-teetimes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../flightrendering/flights.renderer.component.ngfactory";
import * as i3 from "../../flightrendering/flights.renderer.component";
import * as i4 from "./tournament-overview-teetimes.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/FlightMembersService";
import * as i7 from "../../../services/TeamsService";
import * as i8 from "../../../services/FlightService";
import * as i9 from "../../../services/CourseService";
var styles_TournamentOverviewTeeTimesComponent = [i0.styles];
var RenderType_TournamentOverviewTeeTimesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TournamentOverviewTeeTimesComponent, data: {} });
export { RenderType_TournamentOverviewTeeTimesComponent as RenderType_TournamentOverviewTeeTimesComponent };
export function View_TournamentOverviewTeeTimesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "panel-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "flights-renderer", [], null, null, null, i2.View_FlightsRenderer_0, i2.RenderType_FlightsRenderer)), i1.ɵdid(6, 49152, null, 0, i3.FlightsRenderer, [], { flights: [0, "flights"], flightType: [1, "flightType"], editable: [2, "editable"], deleteable: [3, "deleteable"], round: [4, "round"], holes: [5, "holes"], teams: [6, "teams"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flights; var currVal_1 = _co.flightType; var currVal_2 = false; var currVal_3 = false; var currVal_4 = _co.currentRound; var currVal_5 = _co.holes; var currVal_6 = _co.teams; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_TournamentOverviewTeeTimesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tee-times", [], null, null, null, View_TournamentOverviewTeeTimesComponent_0, RenderType_TournamentOverviewTeeTimesComponent)), i1.ɵdid(1, 245760, null, 0, i4.TournamentOverviewTeeTimesComponent, [i5.ActivatedRoute, i6.FlightMembersService, i7.TeamsService, i8.FlightService, i9.CourseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TournamentOverviewTeeTimesComponentNgFactory = i1.ɵccf("tee-times", i4.TournamentOverviewTeeTimesComponent, View_TournamentOverviewTeeTimesComponent_Host_0, {}, {}, []);
export { TournamentOverviewTeeTimesComponentNgFactory as TournamentOverviewTeeTimesComponentNgFactory };
