import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppSharedModule } from '../../shared/app.shared.module';

import { DataTableModule, ToggleButtonModule, ProgressBarModule, ButtonModule, TabMenuModule, InputSwitchModule, TooltipModule, InputTextModule, AutoCompleteModule, DialogModule, DropdownModule } from 'primeng/primeng';

import { TournamentComponent } from './tournament.component';
import { TournamentSummaryComponent } from './summary/tournament-summary.component';
import { TournamentListComponent } from './tournaments-list.component';
import { TournamentRoundSelectorComponent } from './tournament-round.selector.component';
import { TournamentCreateModule } from './create/tournament.create.module';
import { TournamentOverviewTeeTimesComponent } from './teetimes/tournament-overview-teetimes.component';
import { LeaderboardBrandingModule } from './leaderboard/leaderboard.branding.module'
import { PublicModule } from './public/public.module';
import { TeeTimesScorecardModule } from './teetimesscorecard/tee.times.scorecard.module';
import { PlayerListModule } from './playerlist/playerlist.module';
import { TeamScorecardModule } from './teamscorecard/team.scorecard.module';
import { PrintingModule } from './printing/printing.module';
import { CarouselComponent } from './public/carousel.component';
import { ScoresComponent } from './scores/scores.component';
import { FlightRenderingModule } from '../flightrendering/flight.rendering.module';
import { FlightService, PlayerPoolService, FlightMembersService, FlightMemberService, TvFeedService, DeviceService, TrackingService, MessagingService } from "../../services";
import { TranslateModule } from '@ngx-translate/core';

import { OVERVIEW_COMPONENTS } from './overview/index';
import { ROUNDS_COMPONENTS } from './rounds/index';
import { INDIVIDUAL_SCORECARD_COMPONENTS } from './individual-scorecard/index';
import { TVArrivalComponent } from './tvarrival/tv-arrivals.component';
import { TeeTimeComponent } from './tvarrival/tee-time.component';
import { STATS_COMPONENTS } from './stats/index';
import { ADMIN_COMPONENTS } from './admin/index';
import { TVLeadeboardComponent } from './tv-leaderboard/tv-leaderboard.component';
import { SocietyLeadeboardComponent } from './tv-leaderboard/tv-leaderboardsociety.component';
import { TRACKING_COMPONENTS } from './tracking/index';
import { MESSAGING_COMPONENTS } from './messaging/index';
import { HANDICAP_COMPONENTS } from './coursehandicap';
import { TVJumboScreen } from './tv-leaderboard/jumbo-leaderboard/main-component/tv-jumbo-screen.component';
import { JumboLeaderboard } from './tv-leaderboard/jumbo-leaderboard/leaderboard/jumbo-leaderboard.component';

@NgModule({
    imports: [BrowserModule, FormsModule, RouterModule, DataTableModule, AppSharedModule, FlightRenderingModule, ToggleButtonModule, ProgressBarModule,
        TournamentCreateModule,
        PublicModule,
        PlayerListModule,
        TeeTimesScorecardModule,
        TeamScorecardModule,
        PrintingModule,
        LeaderboardBrandingModule,
        ButtonModule,
        TabMenuModule,
        InputSwitchModule,
        TooltipModule,
        InputTextModule,
        AutoCompleteModule,
        DialogModule,
        DropdownModule,
        TranslateModule,
        ReactiveFormsModule
    ],    // module dependencies
    declarations: [ScoresComponent,
        TournamentOverviewTeeTimesComponent,
        TournamentSummaryComponent,
        TournamentListComponent,
        TournamentRoundSelectorComponent,
        TournamentComponent,
        CarouselComponent,
        ...OVERVIEW_COMPONENTS,
        ...ROUNDS_COMPONENTS,
        ...INDIVIDUAL_SCORECARD_COMPONENTS,
        TVArrivalComponent,
        TVLeadeboardComponent,
        TVJumboScreen,
        JumboLeaderboard,
        SocietyLeadeboardComponent,
        TeeTimeComponent,
        ...STATS_COMPONENTS,
        ...ADMIN_COMPONENTS,
        ...TRACKING_COMPONENTS,
        ...HANDICAP_COMPONENTS,
        ...MESSAGING_COMPONENTS],
    exports: [
        ScoresComponent,
        TournamentOverviewTeeTimesComponent,
        TournamentSummaryComponent,
        TournamentListComponent,
        TournamentRoundSelectorComponent,
        TournamentComponent,
        ...OVERVIEW_COMPONENTS,
        ...ROUNDS_COMPONENTS,
        ...INDIVIDUAL_SCORECARD_COMPONENTS,
        CarouselComponent,
        ...STATS_COMPONENTS,
        ...ADMIN_COMPONENTS,
        ...TRACKING_COMPONENTS,
        ...HANDICAP_COMPONENTS,
        ...MESSAGING_COMPONENTS], // root component
    providers: [FlightService, FlightMemberService, FlightMembersService, PlayerPoolService, TvFeedService, DeviceService, TrackingService, MessagingService]
})
export class TournamentModule { }
