import { combineLatest, startWith, distinctUntilChanged } from "rxjs/operators";
import {
  Component,
  Output,
  Input,
  OnInit,
  OnChanges,
  ViewChild,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Venue, HoleColour, Course, TeamType, Round } from "../../../models";
import {
  CourseService,
  RoundService,
  TeamTypesService,
  VenueService,
} from "../../../services";
import { SelectItem } from "primeng/primeng";
import { ConfirmDialog } from "../../../shared/index";
import { DomainHelpers } from "../../../helpers/domain.helpers";
import { Subject } from "rxjs";
import { UniversalValidators } from "ngx-validators";
import { AmazingTimePickerService } from "amazing-time-picker";
import { ActivatedRoute } from "@angular/router";
import { OrderOfMeritSwitchInfo } from "src/app/models/OrderOfMerit/OrderOfMeritSwitchInfo";
import { OrderOfMeritSharedServices } from "src/app/shared/services/orderOfMeritSharedServices";

@Component({
  selector: "round-renderer",
  providers: [TeamTypesService, VenueService],
  templateUrl: "round.renderer.component.html",
  styleUrls: ["round.renderer.component.scss"],
})
export class RoundRendererComponent implements OnInit, OnChanges {
  @Input() index: number;
  @Input() item: FormGroup;
  @Input() venueID: number;
  @Input() isAgent: boolean;
  @Input() roundCount: number;
  @Input() dateFormat: string;
  @Input() dateFormatMoment: string;
  @Output() roundDeleted: EventEmitter<any> = new EventEmitter();

  canDelete: boolean = false;
  displayFlightSize: boolean = false;
  courses: Course[];
  courseSelect: Array<SelectItem> = [{ label: "Choose...", value: null }];
  displayTeamHandicap: boolean = false;
  displayAllowance: boolean = false;
  showCalloway: boolean =
    this.activatedRoute.parent.snapshot.data["branding"].isCallaway;
  hcpAdjustment: boolean =
    this.activatedRoute.parent.snapshot.data["branding"].hcpAdjustment;
  isCallowayStyle: boolean = false;
  venueIsSelected: boolean = false;
  venueNameValid: boolean = false;
  venueSuggestions: Venue[];
  handicapMultiplierInitial: number = null;
  teamHandicapInitial: number = null;
  hasScores = false;
  displayNoTeeDialog = false;
  get existingRound(): boolean {
    return this.item.controls["roundID"].value > 0;
  }
  @ViewChild("removeround", { static: false })
  removeRoundConfirmDialog: ConfirmDialog;
  @ViewChild("changehandicap", { static: false })
  changeHandicapConfirmDialog: ConfirmDialog;

  currentCourse: Subject<Course> = new Subject<Course>();
  currentVenue: Subject<Venue> = new Subject<Venue>();
  currentCourseId = 0;

  gameTypes: Array<SelectItem> = DomainHelpers.SelectLists.getBasicGameformat();

  unitTypes: Array<SelectItem> = [
    { value: 1, label: "Yards" },
    { value: 2, label: "Meters" },
  ];

  scoreBestOfs: Array<SelectItem> = [];
  holeColours: Array<HoleColour> = [];

  flightTypes: Array<SelectItem> = [];

  teamTypeDetail: Array<TeamType> = [];

  teamTypes: Array<SelectItem> = [];

  countbackTypes: Array<SelectItem> = [
    { value: 1, label: "Standard 9,6,3,1" },
    { value: 2, label: "Handicap" },
    { value: 3, label: "Stroke Index" },
    { value: 4, label: "None" },
  ];
  teamHandicapMultipliers: Array<SelectItem> = [];
  handicapMultipliers: Array<SelectItem> = [];
  teeTimeIncrements: Array<SelectItem> = [];

  startTime = "";
  maleColors: SelectItem[] = new Array<SelectItem>();
  femaleColors: SelectItem[] = new Array<SelectItem>();

  teamHandicapLabel = "";
  displayTeamHandicapLabel = false;
  teamFormat: string = "";

  @Output() gameTypeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private teamTypesService: TeamTypesService,
    public venueService: VenueService,
    private atp: AmazingTimePickerService,
    public activatedRoute: ActivatedRoute,
    private courseServices: CourseService,
    private orderOfMeritSharedServices: OrderOfMeritSharedServices
  ) {
    teamTypesService.teamTypes$.subscribe((data) => {
      this.teamTypeDetail = data;
      this.teamTypes = data.map((teamType) => {
        var item = {
          value: teamType.teamTypeID,
          label: teamType.name,
        };
        return item;
      });
    });
  }

  // Send message to parent about game type change
  sendGameTypeChange(gameTypeId: string) {
    this.gameTypeChange.emit(gameTypeId);
  }

  receiveOrderOfMeritChange(info: OrderOfMeritSwitchInfo) {
    if (info) {
      if (!info.active || ![1, 2, 3].includes(info.gameFormat)) {
        this.gameTypes = DomainHelpers.SelectLists.getBasicGameformat();
      } else if (info.gameFormat == 1) {
        this.gameTypes = DomainHelpers.SelectLists.getPointsGameFormat();
      } else if (info.gameFormat == 2) {
        this.gameTypes = DomainHelpers.SelectLists.getStableFordFormat();
      } else if (info.gameFormat == 3) {
        this.gameTypes = DomainHelpers.SelectLists.getStrokeplayFormat();
      }
    }
  }

  ngOnInit() {
    var i: number;
    for (i = 100; i >= 0; ) {
      var value = i / 100;
      this.handicapMultipliers.push({ value: value, label: i.toString() });
      i = i - 5;
    }
    for (i = 100; i >= 0; ) {
      //var value = (i / 100);
      this.teamHandicapMultipliers.push({ value: i, label: i.toString() });
      i = i - 5;
    }
    for (i = 25; i > 0; i--) {
      this.teeTimeIncrements.push({ value: i, label: i.toString() });
    }
    this.venueService.venue$.subscribe(
      (data) => {
        if (data) {
          var venue = _.find(data, { venueID: this.venueID });
          if (venue) {
            if (this.item.get("venueSelect").value == null) {
              this.item.controls["venueSelect"].setValue(venue);
            }
            this.currentVenue.next(venue);
          }
        }
      },
      (err) => console.log(err),
      () => console.log("Complete Get Venue")
    );

    this.currentVenue.subscribe((venue: Venue) => {
      if (venue) {
        this.courses = venue.courses;
        this.courseSelect = [];
        this.courseSelect.push({ label: "Choose...", value: null });
        if (this.courses) {
          var options = this.courses.map((course: Course) => {
            var item = {
              label: course.title.toString(),
              value: course.courseID.toString(),
            };
            return item;
          });
          for (let courseSelect of options) {
            this.courseSelect.push(courseSelect);
          }
        }
        this.venueIsSelected = true;
        this.venueSuggestions = [];
        var courseID = this.item.controls["courseID"].value;
        if (courseID) {
          var course = _.find(this.courses, { courseID: +courseID });
          this.currentCourse.next(course);
        }
      } else {
        if (this.item.controls["venueSelect"].value) {
          this.clearVenueSelection();
        }
      }
    });

    this.currentCourse.pipe(distinctUntilChanged()).subscribe((course) => {
      if (course) {
        this.maleColors = [];
        this.femaleColors = [];
        if (course.tees) {
          course.tees.forEach((t) => {
            let newItem: SelectItem = {
              value: t.holeColourID,
              label: `${t.title} CR: ${t.courseRating} SR: ${t.slopeRating}`,
            };
            t.gender == 1
              ? this.maleColors.push(newItem)
              : this.femaleColors.push(newItem);
          });
        }
        this.maleColors;
        this.holeColours = course.holeColours;
        this.setColorDefaults(course);
        this.currentCourseId = course.courseID;
      } else {
        this.holeColours = [];
        this.item.controls["maleHoleColourID"].setValue(null);
        this.item.controls["femaleHoleColourID"].setValue(null);
      }
    });

    this.item.controls["venueSelect"].valueChanges.subscribe((venue: Venue) => {
      //If it is a typed string i.e. "th" do nothing; if the click on a selected venue then proceed.
      if (venue !== null && typeof venue === "object") {
        this.venueID = venue.venueID;
        this.venueService.load(venue.venueID);

        this.item.controls["units"].setValue(venue.unitTypeOrDefault);
      }
    });

    this.item.controls["courseID"].valueChanges.subscribe((value) => {
      if (value != null && value != "null") {
        let course = _.find(this.courses, { courseID: +value });
        if (course.tees.length > 0) {
          this.currentCourseId = course.courseID;
          this.currentCourse.next(course);
        } else {
          this.courseServices.sendMissingTeeDataSlackMessage(value);
          this.displayNoTeeDialog = true;
          this.item.controls["courseID"].setValue(this.currentCourseId);
        }
      } else {
        this.currentCourse.next(null);
      }
    });

    this.item.controls["gameType"].valueChanges
      .pipe(startWith(this.item.controls["gameType"].value))
      .subscribe((gameypeID) => {
        this.sendGameTypeChange(gameypeID);

        if (gameypeID == 5 && !_.find(this.gameTypes, { value: gameypeID })) {
          this.gameTypes.push({ value: 5, label: "PowerPlay" });
        }
        this.teamTypesService.loadAll(gameypeID);
      });

    this.item.controls["gameType"].valueChanges
      .pipe(
        startWith(this.item.controls["gameType"].value),
        combineLatest(
          this.item.controls["teamType"].valueChanges.pipe(
            startWith(this.item.controls["teamType"].value)
          ),
          function (gameTypeID, teamTypeID) {
            return DomainHelpers.SelectLists.getScoreTypes(
              gameTypeID,
              teamTypeID
            );
          }
        )
      )
      .subscribe((scoreBestOfs) => {
        this.scoreBestOfs = scoreBestOfs;
        if (this.scoreBestOfs.length === 1) {
          this.item.controls["scoreBestOf"].setValue(
            this.scoreBestOfs[0].value
          );
        }
      });

    this.item.controls["scoreBestOf"].valueChanges.subscribe((scoreBestOf) => {
      var currentType = _.find(this.scoreBestOfs, { value: +scoreBestOf });
      //Clear value if not in current team types list
      if (!currentType) {
        this.item.controls["scoreBestOf"].setValue(null);
      }
    });

    //Revert handicap back to initial value if they don't confirm
    this.item.controls["handicapMultiplier"].valueChanges
      .pipe(startWith(this.item.controls["handicapMultiplier"].value))
      .subscribe((handicapMultiplier) => {
        var self = this;
        if (self.existingRound && self.changeHandicapConfirmDialog) {
          var callBackPo = function () {
            self.handicapMultiplierInitial =
              self.item.controls["handicapMultiplier"].value;
          };
          var callBackNeg = function () {
            self.item.controls["handicapMultiplier"].setValue(
              self.handicapMultiplierInitial
            );
          };
          if (handicapMultiplier != self.handicapMultiplierInitial) {
            self.changeHandicapConfirmDialog.showDualDialog(
              callBackPo,
              callBackNeg
            );
          }
        }
      });

    //Revert team handicap back to initial value if they don't confirm
    this.item.controls["teamHandicap"].valueChanges
      .pipe(startWith(this.item.controls["teamHandicap"].value))
      .subscribe((teamHandicap) => {
        var self = this;
        if (self.existingRound && self.changeHandicapConfirmDialog) {
          var callBackPo = function () {
            self.teamHandicapInitial = self.item.controls["teamHandicap"].value;
          };
          var callBackNeg = function () {
            self.item.controls["teamHandicap"].setValue(
              self.teamHandicapInitial
            );
          };
          if (teamHandicap != self.teamHandicapInitial) {
            self.changeHandicapConfirmDialog.showDualDialog(
              callBackPo,
              callBackNeg
            );
          }
        }
      });

    this.item.controls["teamType"].valueChanges
      .pipe(
        startWith(this.item.controls["teamType"].value),
        combineLatest(this.teamTypesService.teamTypes$)
      )
      .subscribe((value) => {        
        this.getFlightTypes(value[0], value[1]);
        var currentType = _.find(this.teamTypes, { value: +value[0] });
        if (currentType) {          
          //Probably refactor to get isSharedBallFormat from get Flight Types
          this.teamFormat = currentType.label;    
          switch (currentType.value) {
            case 60: {
              this.teamHandicapLabel = this.getTeamHandicapLabelText(60);
              this.displayTeamHandicapLabel = true;
              this.displayAllowance = false;              
              break;
            }
            case 70: {
              this.displayTeamHandicapLabel = true;
              this.teamHandicapLabel = this.getTeamHandicapLabelText(70);
              this.displayAllowance = false;
              break;
            }
            case 80: {
              this.displayTeamHandicapLabel = true;              
              this.displayAllowance = false;
              break;
            }
            default: {
              this.displayTeamHandicapLabel = false;
              this.displayAllowance = true;
              break;
            }
          }
        }

        //if not in current team types list then select the first value in dropdown
        if (!currentType && this.item.controls["teamType"].value) {
          this.item.controls["teamType"].setValue(
            value[1][0].teamTypeID.toString()
          );
        }
      });
    this.setControlsAvailability();
    this.orderOfMeritSharedServices.currentInfo.subscribe((info) => {
      this.receiveOrderOfMeritChange(info);
    });
  }

  setControlsAvailability() {
    if (this.item.controls["hasScores"].value) {
      this.item.controls["maleHoleColourID"].disable();
      this.item.controls["femaleHoleColourID"].disable();
      this.item.controls["gameType"].disable();
      this.item.controls["teamType"].disable();
      this.item.controls["courseID"].disable();
      this.item.controls["handicapMultiplier"].disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.venueID && this.venueID != 0 && !this.isAgent) {
      this.venueService.load(this.venueID);
    }

    if (this.item.controls["teamHandicap"]) {
      this.teamHandicapInitial = this.item.controls["teamHandicap"].value;
    }
    if (this.item.controls["handicapMultiplier"]) {
      this.handicapMultiplierInitial =
        this.item.controls["handicapMultiplier"].value;
    }
    if (
      this.item.controls["startTime"] &&
      this.item.controls["startTime"].value !== null
    ) {
      this.startTime =
        this.item.controls["startTime"].value.toTimeString().substring(0, 2) +
        ":" +
        this.item.controls["startTime"].value.toTimeString().substring(3, 5);
    }

    var courseID = this.item.controls["courseID"].value;
    if (courseID && courseID != 0 && this.isAgent) {
      this.venueService.loadForCourse(courseID).subscribe((data) => {
        this.venueID = data.venueID;
      });
    }

    this.canDelete = this.roundCount >= 2;
  }

  openTimePicker(event: any) {
    if (this.atp["appRef"].viewCount === 1) {
      let date = this.startTime;
      if (this.startTime === "") {
        date =
          new Date().toTimeString().substring(0, 2) +
          ":" +
          new Date().toTimeString().substring(3, 5);
      }
      const amazingTimePicker = this.atp.open({
        time: date,
        arrowStyle: {
          background: "#556973",
          color: "white",
        },
      });
      amazingTimePicker.afterClose().subscribe((time) => {
        this.startTime = time;
        this.item.controls["startTime"].setValue(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            parseInt(time.substring(0, 2)),
            parseInt(time.substring(3, 5))
          )
        );
        this.item.markAsDirty();
      });
    }
  }

  static buildItem(
    round: Round,
    venueIsSelected: boolean,
    isCreate: boolean = false,
    hcpAdjustment: boolean = true
  ) {
    var fb = new FormBuilder();
    var formGroup = fb.group({
      hasScores: isCreate ? false : [round.hasScores],
      roundID: [round.roundID, Validators.required],
      competitionID: [round.competitionID, Validators.required],
      startTime: [round.startTime, Validators.required],
      startDate: [round.startDate, Validators.required],
      teeTimeIncrement: [round.teeTimeIncrement, Validators.required],
      units: [round.units, Validators.required],
      countbackType: [round.countbackType, Validators.required],
      teamType: [round.teamType, Validators.required],
      scoreBestOf: [round.scoreBestOf, Validators.required],
      handicapMultiplier: [round.handicapMultiplier, Validators.required],
      teamHandicap: [round.teamHandicap, Validators.required],
      isCallowayStyle: [round.isCallowayStyle, Validators.required],
      flightType: [round.flightType, Validators.required],
      gameType: [round.gameType, Validators.required],
      courseID: [round.courseID, Validators.required],
      //courseID: [{ value: round.courseID, disabled: !venueIsSelected }, Validators.required],
      maleHoleColourID: [round.maleHoleColourID, Validators.required],
      femaleHoleColourID: [round.femaleHoleColourID, Validators.required],
      venueSelect: [],
      roundConfiguration: fb.group({
        roundID: [round.roundID, Validators.required],
        roundConfigurationID: [
          round.roundConfiguration.roundConfigurationID,
          Validators.required,
        ],
        ////Par is friend
        forceMinimumRelativeNetScoreToContribute: [
          round.roundConfiguration.forceMinimumRelativeNetScoreToContribute,
        ],
        useDecimalScoring: [round.roundConfiguration.useDecimalScoring],
        isModifiedRules: [round.roundConfiguration.isModifiedRules],
        albatross: [round.roundConfiguration.albatross],
        eagle: [round.roundConfiguration.eagle],
        birdie: [round.roundConfiguration.birdie],
        par: [round.roundConfiguration.par],
        bogey: [round.roundConfiguration.bogey],
        doubleBogey: [round.roundConfiguration.doubleBogey],
        handicapIsAdjustedForSlopeRating: isCreate
          ? hcpAdjustment
          : [round.roundConfiguration.handicapIsAdjustedForSlopeRating],
      }),
    });

    return formGroup;
  }

  getVenues(event: any) {
    //Looks for the suggestions when the string inserted is >= 5
    this.venueService.loadMatching(event.query).subscribe(
      (data) => {
        this.venueSuggestions = data;
      },
      (err) => console.log(err),
      () => console.log("Complete Get Venue")
    );
  }

  clearVenueSelection() {
    this.venueIsSelected = false;
    this.venueID = 0;
    var controls = this.item.controls;
    controls["venueSelect"].setValue(null);
    controls["femaleHoleColourID"].setValue(null);
    controls["maleHoleColourID"].setValue(null);
    controls["courseID"].setValue(null);
    this.courses = [];
    this.item.markAsDirty();
  }

  roundDelete() {
    var self = this;
    var callBack = function () {
      self.roundDeleted.next(self.index);
    };
    this.removeRoundConfirmDialog.showDialog(callBack);
    //Just for testing
    //this.roundDeleted.next(this.index);
  }

  getFlightTypes(teamTypeID: string, teamTypes: Array<TeamType>) {
    var teamTypeDetail = _.find(teamTypes, { teamTypeID: +teamTypeID });
    var flightType = this.item.controls["flightType"];
    if (teamTypeDetail) {
      this.flightTypes = teamTypeDetail.playerPerTeeAllowed.map(
        (teamSize: number) => {
          var item = {
            label: teamSize.toString(),
            value: teamSize.toString(),
          };
          return item;
        }
      );

      if (this.flightTypes.length === 1) {
        flightType.setValue(this.flightTypes[0].value);
      }

      // show players per tee time dropdown only on singles and scramble (only on strokeplay and stableford)
      if (
        this.item.controls["gameType"].value &&
        (this.item.controls["gameType"].value.toString() === "1" ||
          this.item.controls["gameType"].value.toString() === "3") &&
        (teamTypeID.toString() === "20" || teamTypeID.toString() === "80")
      ) {
        this.displayFlightSize = true;
      } else {
        this.displayFlightSize = false;
      }
    } else {
      this.flightTypes = [];
      flightType.setValue(null);
      this.displayFlightSize = false;
    }
  }

  setColorDefaults(currentCourse: Course) {
    if (currentCourse != null) {
      var femaleHoleColourIDControl = this.item.controls["femaleHoleColourID"];
      if (
        !_.find(currentCourse.holeColours, {
          holeColourID: +femaleHoleColourIDControl.value,
        })
      ) {
        femaleHoleColourIDControl.setValue(null);
      }
      if (femaleHoleColourIDControl.value == null) {
        // default for female is red
        if (_.findIndex(currentCourse.holeColours, { holeColourID: 2 }) >= 0) {
          femaleHoleColourIDControl.setValue(2);
        } else {
          femaleHoleColourIDControl.setValue(
            currentCourse.holeColours[0].holeColourID
          );
        }
      }
      var maleHoleColourIDControl = this.item.controls["maleHoleColourID"];
      if (
        !_.find(currentCourse.holeColours, {
          holeColourID: +maleHoleColourIDControl.value,
        })
      ) {
        maleHoleColourIDControl.setValue(null);
      }
      //if no colour is set for the round and Yellow exists, then default to Yellow, else white
      if (maleHoleColourIDControl.value == null) {
        // yellow = 1, white = 3
        if (_.findIndex(currentCourse.holeColours, { holeColourID: 1 }) >= 0) {
          maleHoleColourIDControl.setValue(1);
        } else if (
          _.findIndex(currentCourse.holeColours, { holeColourID: 3 }) >= 0
        ) {
          maleHoleColourIDControl.setValue(3);
        } else {
          maleHoleColourIDControl.setValue(
            currentCourse.holeColours[0].holeColourID
          );
        }
      }
    }
  }

  getTeamHandicapLabelText(teamType: number): string {
    if (teamType == 60) {
      return "Team HCP Allowance for this format is 60% low / 40% high";
    } else if (teamType == 70) {
      return "Team HCP Allowance for this format is 50% of combined team handicap";
    } else if (teamType == 80) {
      return `
            <ul>
                <li>25% low / 20% / 15% / 10% high (4 players)</li>
                <li>30% low / 20% / 10% high (3 players)</li>
                <li>35% low / 15% high (2 players)</li>
            </ul>
        `;
    } else {
      return "";
    }
  }
}
