/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./challenge-overview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./challenge-overview.component";
var styles_ChallengeComponent = [i0.styles];
var RenderType_ChallengeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChallengeComponent, data: {} });
export { RenderType_ChallengeComponent as RenderType_ChallengeComponent };
export function View_ChallengeComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_ChallengeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ChallengeComponent_0, RenderType_ChallengeComponent)), i1.ɵdid(1, 245760, null, 0, i2.ChallengeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChallengeComponentNgFactory = i1.ɵccf("ng-component", i2.ChallengeComponent, View_ChallengeComponent_Host_0, {}, {}, []);
export { ChallengeComponentNgFactory as ChallengeComponentNgFactory };
