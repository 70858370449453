import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { Round, Team, Flight, FlightMember, Profile } from "../../../models";
import { SelectItem } from "primeng/primeng";
import {
  FlightService,
  TeamsService,
  FlightMembersService,
  ResultService,
  PlayerPoolService,
  LeaderboardService,
} from "../../../services";
import { DeviceService } from "../../../services/DeviceService";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { ConfirmDialog } from "src/app/shared/components/confirm-dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "round-admin",
  templateUrl: "round-admin.component.html",
  styleUrls: ["round-admin.component.scss"],
})
export class RoundAdminComponent implements OnInit {
  loading = false;
  roundID: string = "";
  tournamentId: string;

  teamID: string = "";
  teeTimeID: string = "";
  playerID: string = "";

  deviceTeeTimeID: string = "";
  playerPoolID: string = "";

  $stop: Subject<boolean> = new Subject<boolean>();

  teams: SelectItem[] = new Array<SelectItem>();
  flightMembers: SelectItem[] = new Array<SelectItem>();
  teeTimes: SelectItem[] = new Array<SelectItem>();
  playerPool: SelectItem[] = new Array<SelectItem>();
  currentTeams: SelectItem[] = new Array<SelectItem>();
  currentFlightMembers: SelectItem[] = new Array<SelectItem>();
  currentTeeTimes: SelectItem[] = new Array<SelectItem>();
  currentplayerPool: SelectItem[] = new Array<SelectItem>();

  selectPlayersOptionEnabled: boolean;
  selectLeaderboardsOptionEnabled: boolean;
  selectTeamOptionEnabled: boolean;
  selectTeeTimeOptionEnabled: boolean;
  @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;

  constructor(
    private route: ActivatedRoute,
    private teamsService: TeamsService,
    private flightService: FlightService,
    private flightMembersService: FlightMembersService,
    private resultService: ResultService,
    private playerPoolService: PlayerPoolService,
    private deviceService: DeviceService,
    private leaderboardService: LeaderboardService,
    private toastr: ToastrService
  ) {
    this.currentTeams = [{ label: "-", value: "" }];
    this.currentFlightMembers = [{ label: "-", value: "" }];
    this.currentTeeTimes = [{ label: "-", value: "" }];
    this.currentplayerPool = [{ label: "-", value: "" }];
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.$stop)).subscribe((data) => {
      this.roundID = (<Round>data["round"]).roundID.toString();
      this.tournamentId = (<Round>data["round"]).competitionID.toString();
      this.flightService.loadAll(this.tournamentId, this.roundID);
      this.flightMembersService.loadAll(this.tournamentId, this.roundID);
      this.playerPoolService.loadAllOnly(this.tournamentId);
    });

    this.teamsService.teams$
      .pipe(takeUntil(this.$stop))
      .subscribe((data: Array<Team>) => {
        if (data) {
          var result = data.map((team) => {
            return { label: team.title, value: team.teamID };
          });
          this.teams = _.sortBy(result, "label");

          var teams = [];
          teams.push({ label: "-", value: null });
          teams = teams.concat(this.teams);
          this.currentTeams = teams;
        }
      });

    this.playerPoolService.playerPool$
      .pipe(takeUntil(this.$stop))
      .subscribe((data: Array<Profile>) => {
        if (data) {
          var result = data.map((player) => {
            return { label: player.displayName, value: player.profileID };
          });
          this.playerPool = _.sortBy(result, "label");

          var players = [];
          players.push({ label: "-", value: null });
          players = players.concat(this.playerPool);
          this.currentplayerPool = players;
        }
      });

    this.flightService.flights$
      .pipe(takeUntil(this.$stop))
      .subscribe((data: Array<Flight>) => {
        this.teeTimes = this.teeTimes.concat(
          data.map((flight) => {
            return {
              label:
                "Hole " +
                flight.startHole.toString() +
                " - " +
                moment.utc(flight.teeOffTime).format("HH:mm"),
              value: flight.flightID,
            };
          })
        );

        var flight = [];
        flight.push({ label: "-", value: null });
        flight = flight.concat(this.teeTimes);
        this.currentTeeTimes = flight;
      });

    this.flightMembersService.flightMembers$
      .pipe(takeUntil(this.$stop))
      .subscribe((flightMembers: Array<FlightMember>) => {
        var list = flightMembers.map((flightMember) => {
          return {
            label:
              flightMember.profile.firstName +
              " " +
              flightMember.profile.surname,
            value: flightMember.flightMemberID,
          };
        });
        this.flightMembers = _.sortBy(list, "label");

        var flightmember = [];
        flightmember.push({ label: "-", value: null });
        flightmember = flightmember.concat(this.flightMembers);
        this.currentFlightMembers = flightmember;
      });

    this.route.parent.parent.data
      .pipe(takeUntil(this.$stop))
      .subscribe((data) => {
        this.teamsService.loadAll(this.tournamentId);
      });
  }

  wipeAll() {
    var self = this;
    var callBack = function () {
      self.resultService.wipeAll(self.roundID);
      self.leaderboardService.recalculateAllLeaderboards(self.tournamentId);
    };
    self.confirmDialog.showDialog(callBack);
  }

  wipeByTeam() {
    var self = this;
    var callBack = function () {
      self.resultService.wipeByTeam(self.roundID, self.teamID);
      self.leaderboardService.recalculateAllLeaderboards(self.tournamentId);
    };
    self.confirmDialog.showDialog(callBack);
  }

  wipeByTeeTime() {
    var self = this;
    var callBack = function () {
      self.resultService.wipeByTeeTime(self.roundID, self.teeTimeID);
      self.leaderboardService.recalculateAllLeaderboards(self.tournamentId);
    };
    self.confirmDialog.showDialog(callBack);
  }

  wipeByPlayer() {
    var self = this;
    var callBack = function () {
      self.resultService.wipeByPlayer(self.roundID, self.playerID);
      self.leaderboardService.recalculateAllLeaderboards(self.tournamentId);
    };
    self.confirmDialog.showDialog(callBack);
  }

  refreshByTeeTime() {
    this.deviceService.refreshByFligh(this.deviceTeeTimeID);
  }

  refreshAll() {
    this.deviceService.refreshAll(this.roundID);
  }

  removeByPlayer() {
    this.playerPoolService.remove(this.tournamentId, this.playerPoolID);
  }

  refreshResults() {    
    this.leaderboardService
      .removeLeaderboardData(this.roundID)
      .subscribe((deleted) => {        
        if (deleted) {
          this.resultService.refreshbyRoundId(this.roundID).subscribe();
        } else this.toastr.error("Failed to recalculate");
      });
  }

  removeAll() {}
}
