import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Tracking, Round } from '../../../models';
import { TrackingService } from '../../../services';

@Component({
    selector: 'round-tracking',
    templateUrl: 'round-tracking.component.html',
    styleUrls: ['round-tracking.component.scss']
})

export class RoundTrackingComponent implements OnInit {

    roundId: string;
    $stop: Subject<boolean> = new Subject<any>();
    trackingData: Tracking[] = [];
    emptyMessage: string = "Loading...";
    lastUpdateDate = '';

    constructor(private route: ActivatedRoute,
        private trackingService: TrackingService) {
        window.setInterval(() => this.loadData(), 600000);
    }

    ngOnInit() {
        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            this.roundId = (<Round>data['round']).roundID.toString();
            this.loadData();
        });
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }

    checkFlightFinished(Thur: number, CurrentHole: number) {

        if (Thur === 18) return 'F'

        return CurrentHole;
    }

    getHoursMinutes(totalMinutes: number) {

        if (totalMinutes === 0) return 0;

        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        if (hours === 0) return minutes+ 'm';

        return hours + 'h' + minutes+ 'm';
    }


    private loadData() {

        if (this.roundId) {

            this.lastUpdateDate = moment(new Date()).format("HH:mm:ss");

            this.trackingService.getRoundTracking(this.roundId).subscribe((data) => {
                this.trackingData = data !== null ? data : [];
            });
        }
    }

}
