import { Injectable } from "@angular/core";
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Competition, Round, Course } from '../models';

@Injectable()
export class CompetitionRouteService {
    constructor() {
        this.currentRound$.subscribe(currentRound => {
            if (currentRound) {
                var notSinglesOrIndividual = (currentRound.teamType != 20 && currentRound.teamType != 0);
                var MP = (currentRound.gameType == 2);
                var teamDisplay = MP || notSinglesOrIndividual;
                this.teamDisplay$.next(teamDisplay);
                this.shotsDisplay$.next(MP);
            } else {
                this.teamDisplay$.next(true);
                this.shotsDisplay$.next(true);
            }
        });
    }
    public teamDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public shotsDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public roundID$: BehaviorSubject<string> = new BehaviorSubject('');
    public competitionID$: BehaviorSubject<string> = new BehaviorSubject('');
    //This emits a null value on refresh of bage. Probably need to look at making it a default empty comp or not emitting anything if empty.
    public currentCompetition$: BehaviorSubject<Competition> = new BehaviorSubject(null);
    public currentRound$: BehaviorSubject<Round> = new BehaviorSubject(null);
    public currentCourse$: BehaviorSubject<Course> = new BehaviorSubject(null);
}