<p-dialog [(visible)]="displayManageMediaDialog" header="Manage Device Branding" [responsive]="false" [modal]="true" [width]="800" [height]="600" [resizable]="false" [draggable]="false">
    <div style="width: 755px; height: 480px">
        <div class="menu-container" *ngIf="!displayCropTool">
            <vpar-tabMenu [model]="tabItems" styleClass="media-manage-menu" [activeItem]="activeTab"></vpar-tabMenu>
        </div>
        <!-- BANNER -->
        <div *ngIf="activeTab.label && activeTab.label==='Banner' && !displayCropTool">
            <div class="row tab-container">
                <div class="col-md-12 data-section banner">
                    <label>Banner</label>
                    <div style="display: flex;">
                        <select class="form-control input-md" [(ngModel)]="mediaBanner.mediaID" (change)="selectBanner($event.target.value)">
                                <option value="0">Please Select</option>
                                <option *ngFor="let banner of bannerList" [value]="banner.mediaID">{{banner.title}}</option>
                            </select>
                        <button class="btn btn-action-ok m-b-0" (click)="toggleCropTool(true)"><i class="fa fa-upload"></i></button>
                    </div>
                </div>
                <div class="preview-section">
                    <select class="form-control input-sm m-b-10" [(ngModel)]="selectedPreview">
                        <option value="0">iPhone</option>
                        <option value="1">Android</option>
                    </select>
                    <img class="banner-image" *ngIf="selectedBannerImage !== ''" src="{{selectedBannerImage}}" [ngClass]="{'iphone': selectedPreview==='0', 'android': selectedPreview==='1'}">
                    <img *ngIf="selectedPreview === '0'" class="phone" src="../../../../assets/images/iphone.png">
                    <img *ngIf="selectedPreview === '1'" class="phone" src="../../../../assets/images/android.png">
                </div>
            </div>
        </div>
        <!-- POPUP -->
        <div *ngIf="activeTab.label && activeTab.label==='Popup' && !displayCropTool">
            <div class="row tab-container">
                <div class="data-section popup">
                    <div class="col-md-12 top-section">
                        <label (click)="addPopup()" class="action"><i class="icon-plus p-r-10"></i>Add popup</label>
                        <label (click)="deletePopup()" class="action"><i class="icon-trash p-r-10"></i>Delete</label>
                    </div>
                    <div *ngIf="mediaPopups.length > 0" class="col-md-12 popup-header">
                        <div class="hole">Hole</div>
                        <div style="flex:1">Popup</div>
                        <div>Select</div>
                    </div>
                    <div class="col-md-12 media-list">
                        <media-popup *ngFor="let media of mediaPopups; let index = index" [popup]="media" [popupList]="popupList" [highlighted]="selectedPopupIndex === index" (onToggleCropTool)="selectedPopupIndex = index;toggleCropTool(true)" (onMediaSend)="highlightMedia($event, index)"></media-popup>
                    </div>
                </div>
                <div class="preview-section">
                    <select class="form-control input-sm m-b-10" [(ngModel)]="selectedPreview">
                        <option value="0">iPhone</option>
                        <option value="1">Android</option>
                    </select>
                    <img class="popup-image" *ngIf="selectedPopupImage !== ''" src="{{selectedPopupImage}}" [ngClass]="{'iphone': selectedPreview==='0', 'android': selectedPreview==='1'}">
                    <img *ngIf="selectedPreview === '0'" class="phone" src="../../../../assets/images/iphone.png">
                    <img *ngIf="selectedPreview === '1'" class="phone" src="../../../../assets/images/android.png">
                </div>
            </div>
        </div>
        <div class="col-md-12 button-section">
            <div class="btn-group pull-right">
                <button class="btn btn-action-ok w-600 f-13 m-b-0" style="padding: 10px;" (click)="saveSettings()">Save Settings</button>
            </div>
            <div class="btn-group pull-right">
                <button class="btn btn-action-cancel w-600  f-13 m-b-0" style="padding: 10px;" (click)="close('')">Close</button>
            </div>
        </div>
        <!-- CROP TOOL -->
        <div *ngIf="displayCropTool" class="crop-tool">
            <div class="col-md-12">
                <h4>
                    <strong>Device media upload</strong>
                </h4>
                <div class="upload-content">
                    <span *ngIf="activeTab.label==='Banner'">Select an image and edit it to be used as banner on devices</span>
                    <span *ngIf="activeTab.label==='Popup'">Select an image and edit it to be used as popup before scoring a hole</span>
                    <div class="file-upload">
                        <span class="text">Select file</span>
                        <input id="custom-input" type="file" (change)="fileChangeListener($event)">
                    </div>
                </div>
                <div class="col-md-12 m-t-20 p-l-0 p-r-0">
                    <img-cropper #cropper class="cropper" [image]="cropperData" [settings]="cropperSettings"></img-cropper>
                    <span class="result" *ngIf="cropperData.image" [ngClass]="{'popup':activeTab.label==='Popup' }">
                        <img *ngIf="activeTab.label==='Banner'" [src]="cropperData.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight">
                        <img *ngIf="activeTab.label==='Popup'" [src]="cropperData.image" [width]="192" [height]="320">
                        <p *ngIf="activeTab.label==='Banner'">Final result (240 x 96)</p>
                        <p *ngIf="activeTab.label==='Popup'">Final result (480 x 800)</p>
                    </span>
                </div>
            </div>
            <div class="col-md-12 button-section">
                <div class="btn-group pull-right">
                    <button class="btn btn-action-ok w-600 f-13 m-b-0" [disabled]="uploadInProgress" (click)="uploadImage()">Upload</button>
                </div>
                <div class="btn-group pull-right">
                    <button class="btn btn-action-cancel w-600 f-13 m-b-0" [disabled]="uploadInProgress" (click)="toggleCropTool(false)">Back</button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
