import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppCookieService } from "./CookiesServices";
import { Observable } from "rxjs";
import { LoginService } from "./login.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HttpServices {
  constructor(
    private http: HttpClient,
    private cookieService: AppCookieService,
    private loginService: LoginService,
    private router: Router
  ) {}

  get(
    url: string,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null
  ): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );

    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });

    return this.http.get(url, { headers, params });
  }

  getTyped<T>(
    url: string,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null
  ): Observable<T> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );

    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });

    return this.http.get<T>(url, { headers, params, observe: "body" });
  }

  post<T>(
    url: string,
    body: any | null,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null,
    responseType?: "json"
  ): Observable<T> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders) {
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    }

    return this.http.post<T>(url, body, { headers, params, responseType });
  }

  postBlob(
    url: string,
    body: any | null,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null
  ): Observable<Blob> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders) {
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    }

    return this.http.post(url, body, {
      headers: headers,
      params,
      responseType: "blob",
    });
  }

  postTyped<T>(
    url: string,
    body: any | null,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null,
    responseType?: "json"
  ): Observable<T> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    return this.http.post<T>(url, body, { headers, params, responseType });
  }

  put(
    url: string,
    body: any | null,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null,
    responseType?: "json"
  ): Observable<Object> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    return this.http.put(url, body, { headers, params, responseType });
  }
  putTyped<T>(
    url: string,
    body: any | null,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null,
    responseType?: "json"
  ): Observable<T> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    return this.http.put<T>(url, body, { headers, params, responseType });
  }

  delete(
    url: string,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null,
    responseType?: "json"
  ): Observable<Object> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    return this.http.delete(url, { headers, params, responseType });
  }

  patch(
    url: string,
    body: any | null,
    passedHeaders: { name: string; value: string }[] = null,
    params: HttpParams = null,
    responseType?: "json"
  ): Observable<Object> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      `Bearer ${this.cookieService.get("token")}`
    );
    headers = headers.append("Content-Type", "application/json; charset=utf-8");
    headers = headers.append(
      "CompetitionHostId",
      this.cookieService.getCompetitionHostIdCookie()
    );
    if (passedHeaders)
      passedHeaders.forEach((header) => {
        headers = headers.append(header.name, header.value);
      });
    return this.http.patch(url, body, { headers, params, responseType });
  }

  private checkToken() {
    if (!window.location.href.includes("public")) {
      if (!this.loginService.isLoggedIn()) {
        this.router.navigate(["login"]);
        this.cookieService.remove("token");
      }
    }
  }
}
