import { Component, OnInit, Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OrderOfMeritService } from "src/app/services/orderOfMeritService";
import { OrderOfMeritConfig } from "src/app/models/OrderOfMerit/orderofmerit";
import { DatePipe } from "@angular/common";
import { OrderOfMeritEnums } from "src/app/models/OrderOfMerit/OrderOfMeritEnums";
import { OrderOfMeritCommon } from "./orderofmerit-common";

@Component({
  templateUrl: "orderofmerit-edit.component.html",
  styleUrls: ["./orderofmerit-create-edit.component.scss"],
})
@Injectable()
export class OrderofmeritEditComponent implements OnInit, OnDestroy {
  inputsForm: FormGroup;
  formats: SelectItem[];
  pointFormats: SelectItem[];
  qualifications: SelectItem[];
  selectedQualification: number;
  bestof: SelectItem[];
  selectedBestof: number;
  labelBest: string = "Not Included";
  labelWorst: string = "Not Included";
  disableMeritPoints: boolean = false;
  disableAccumulativeStableford: boolean = false;
  disableAccumulativeStrokeplay: boolean = false;
  validate: boolean;
  orderOfMerit: OrderOfMeritConfig;
  selectedGameType: OrderOfMeritEnums.OrderOfMeritGameTypeEnum;
  selectedPointsFormat: OrderOfMeritEnums.PointsFormatEnum;
  disableGameFormats = false;
  submitted = false;
  pointsAllocationError = false;
  disablePointsFormat = false;
  displayPointsAllocation = false;
  hasOrderOfMeritGames = false;
  orderOfMeritCommon = new OrderOfMeritCommon();

  places = [
    { place: "1st", points: 10 },
    { place: "2nd", points: 8 },
    { place: "3rd", points: 6 },
    { place: "4th", points: 4 },
    { place: "5th", points: 2 },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private service: OrderOfMeritService,
    private datePipe: DatePipe,
    private route: Router
  ) {
    this.orderOfMerit = new OrderOfMeritConfig();

    this.qualifications = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "10", value: 10 },
      { label: "11", value: 11 },
      { label: "12", value: 12 },
      { label: "13", value: 13 },
      { label: "14", value: 14 },
      { label: "15", value: 15 },
      { label: "16", value: 16 },
      { label: "17", value: 17 },
      { label: "18", value: 18 },
      { label: "19", value: 19 },
      { label: "20", value: 20 },
    ];
    this.bestof = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "10", value: 10 },
      { label: "11", value: 11 },
      { label: "12", value: 12 },
      { label: "13", value: 13 },
      { label: "14", value: 14 },
      { label: "15", value: 15 },
      { label: "16", value: 16 },
      { label: "17", value: 17 },
      { label: "18", value: 18 },
      { label: "19", value: 19 },
      { label: "20", value: 20 },
    ];

    this.inputsForm = this.formBuilder.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      gameFormats: ["", Validators.required],
      pointsFormats: ["", Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      bestOf: [null],
      qualifications: [null],
      bestScore: [false],
      worstScore: [false],
      pointsAllocation: [""],
      orderOfMeritConfigId: [0],
    });
  }

  ngOnInit() {
    this.initDropDowns();
    this.inputsForm.get("startDate").valueChanges.subscribe((startDate) => {
      const endDate = this.inputsForm.get("endDate").value;

      if (endDate && endDate < startDate) {
        this.inputsForm.get("endDate").setValue(null);
      }
    });
    this.inputsForm.get("endDate").valueChanges.subscribe((endDate) => {
      const startDate = this.inputsForm.get("startDate").value;

      if (startDate && endDate < startDate) {
        this.inputsForm.get("startDate").setValue(null);
      }
    });

    this.service.getConfig().subscribe((data) => {
      this.initValues(data);
      // Set the labels
      this.onToggleChangeBest();
      this.onToggleChangeWorst();
    });
  }
  ngOnDestroy() {}

  removePlace(index: number): void {
    this.places.splice(index, 1);
    this.updateIndexes();
  }

  updateIndexes(): void {
    for (let i = 0; i < this.places.length; i++) {
      if (i == 0) {
        this.places[i].place = `${i + 1}st`;
      } else if (i == 1) {
        this.places[i].place = `${i + 1}nd`;
      } else if (i == 2) {
        this.places[i].place = `${i + 1}rd`;
      } else {
        this.places[i].place = `${i + 1}th`;
      }
    }
  }

  onFormatChange(selectedValue: any) {
    const selectedFormatNumber =
      this.orderOfMeritCommon.mapNumberToGameFormatEnum(selectedValue.value);
    switch (selectedFormatNumber) {
      case OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Both:
        this.disablePointsFormat = false;
        this.displayPointsAllocation = true;
        this.inputsForm
          .get("pointsFormats")
          .setValue(OrderOfMeritEnums.PointsFormatEnum.MeritPoints);
        break;
      case OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Stableford:
        this.disablePointsFormat = true;
        this.inputsForm
          .get("pointsFormats")
          .setValue(OrderOfMeritEnums.PointsFormatEnum.StableFord);
        this.displayPointsAllocation = false;
        break;
      case OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Strokeplay:
        this.disablePointsFormat = true;
        this.inputsForm
          .get("pointsFormats")
          .setValue(OrderOfMeritEnums.PointsFormatEnum.StrokePlay);
        this.displayPointsAllocation = false;
    }
  }

  onSubmit() {    
    this.submitted = true;
    if (this.inputsForm.valid) {
      // If the points format is merit points check if we have at least one allocation
      if (
        this.selectedPointsFormat ==
        OrderOfMeritEnums.PointsFormatEnum.MeritPoints
      ) {
        if (this.places.length === 0) {
          this.pointsAllocationError = true;
          return;
        } else {
          let stringPoints = "";
          this.places.forEach((place) => {
            stringPoints += `${place.points},`;
          });
          stringPoints = stringPoints.substring(0, stringPoints.length - 1);
          this.inputsForm.get("pointsAllocation").setValue(stringPoints);
        }
      }

      // Fix the date format before sending to the API
      this.updateDateFormat();

      // Create config object from form
      const payload = this.orderOfMeritCommon.mapFormGroupToConfig(
        this.inputsForm
      );

      this.service.updateGame(payload).subscribe(() => {
        this.route.navigate(["order-of-merit/overview"]);
      });
    } else {
      return;
    }
  }
  updateDateFormat() {
    const startDate = this.inputsForm.get("startDate").value;
    const endDate = this.inputsForm.get("endDate").value;
    const formattedStartDateString = this.datePipe.transform(
      startDate,
      "yyyy-MM-dd"
    );
    const formattedStartDate = new Date(formattedStartDateString);
    const formattedEndDateString = this.datePipe.transform(
      endDate,
      "yyyy-MM-dd"
    );
    const formattedEndDate = new Date(formattedEndDateString);
    this.inputsForm.get("startDate").setValue(formattedStartDate);
    this.inputsForm.get("endDate").setValue(formattedEndDate);
  }

  addPosition() {
    let newPosition = { place: `${this.places.length + 1}`, points: 0 };
    this.places.push(newPosition);
    this.updateIndexes();
  }

  onPointsFormatChange(selectedValue: any) {
    this.selectedPointsFormat = selectedValue.value;
    const pointsFormatEnumValue =
      this.orderOfMeritCommon.mapNumberToPointsFormatEnum(selectedValue.value);
    switch (pointsFormatEnumValue) {
      case OrderOfMeritEnums.PointsFormatEnum.MeritPoints:
        this.inputsForm
          .get("gameFormats")
          .setValue(OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Both);
        this.disableGameFormats = false;
        this.displayPointsAllocation = true;
        break;
      case OrderOfMeritEnums.PointsFormatEnum.StableFord:
        this.selectedGameType =
          OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Stableford;
        this.disableGameFormats = true;
        this.displayPointsAllocation = false;
        break;
      case OrderOfMeritEnums.PointsFormatEnum.StrokePlay:
        this.selectedGameType =
          OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Strokeplay;
        this.disableGameFormats = true;
        this.displayPointsAllocation = false;
        break;
    }
  }

  onToggleChangeBest() {
    if (this.inputsForm.get("bestScore").value) {
      this.labelBest = "Included";
    } else {
      this.labelBest = "Not Included";
    }
  }
  onToggleChangeWorst() {
    if (this.inputsForm.get("worstScore").value) {
      this.labelWorst = "Included";
    } else {
      this.labelWorst = "Not Included";
    }
  }

  initDropDowns() {
    this.formats = Object.keys(OrderOfMeritEnums.OrderOfMeritGameTypeEnum)
      .filter(
        (key) => !isNaN(Number(OrderOfMeritEnums.OrderOfMeritGameTypeEnum[key]))
      )
      .map((key) => ({
        label: key,
        value: OrderOfMeritEnums.OrderOfMeritGameTypeEnum[key],
      }));

    this.pointFormats = Object.keys(OrderOfMeritEnums.PointsFormatEnum)
      .filter((key) => !isNaN(Number(OrderOfMeritEnums.PointsFormatEnum[key])))
      .map((key) => ({
        label: key,
        value: OrderOfMeritEnums.PointsFormatEnum[key],
      }));
  }

  initValues(config: OrderOfMeritConfig) {
    const startDate = new Date(config.startDate);
    const endDate = new Date(config.endDate);
    this.inputsForm.get("title").setValue(config.title);

    this.inputsForm.get("description").setValue(config.description);
    this.inputsForm.get("gameFormats").setValue(config.gameFormat);
    this.inputsForm.get("pointsFormats").setValue(config.pointsFormat);
    this.inputsForm.get("startDate").setValue(startDate);
    this.inputsForm.get("endDate").setValue(endDate);
    this.inputsForm.get("bestOf").setValue(config.bestOf);
    this.inputsForm.get("qualifications").setValue(config.qualification);
    this.inputsForm.get("bestScore").setValue(config.bestScore);
    this.inputsForm.get("worstScore").setValue(config.worstScore);
    this.inputsForm.get("pointsAllocation").setValue(config.pointsAllocation);
    this.inputsForm
      .get("orderOfMeritConfigId")
      .setValue(config.orderOfMeritConfigId);
    this.displayPointsAllocation =
      config.pointsFormat == OrderOfMeritEnums.PointsFormatEnum.MeritPoints;
    this.selectedPointsFormat = config.pointsFormat;

    if (config.pointsFormat == OrderOfMeritEnums.PointsFormatEnum.MeritPoints) {
      const pointsArray = config.pointsAllocation.split(",").map(Number);
      this.places = [];

      pointsArray.forEach((point, index) => {
        this.places.push({ place: `${index + 1}`, points: point });
      });
    }

    if (config.numberOfCurrentGames > 0) {
      this.hasOrderOfMeritGames = true;
    }
  }
}
