<div class="row p-t-10 p-b-10 m-0 config-container" [ngClass]="{'new-row': roundLeaderboardConfig.highlighted}">
    <div class="col-md-5">
        <label class="col-md-12 p-0">Hole Range</label>
        <p-spinner class="col-md-5 OverInputNew p-0" [ngClass]="{'error-field' : roundLeaderboardConfig.errorHoleStart}" [min]="1" [max]="18" required [(ngModel)]="roundLeaderboardConfig.holeStart"></p-spinner>
        <span class="col-md-1 p-0 text-center">-</span>
        <p-spinner class="col-md-5 OverInputNew p-0" [ngClass]="{'error-field' : roundLeaderboardConfig.errorHoleEnd}" [min]="1" [max]="18" required [(ngModel)]="roundLeaderboardConfig.holeEnd"></p-spinner>
        <label class="error-label" *ngIf="roundLeaderboardConfig.errorHoleStart || roundLeaderboardConfig.errorHoleEnd">{{roundLeaderboardConfig.errorMessage}}</label>
    </div>
    <div class="col-md-5">
        <label class="col-md-12 p-0">Scoring Method</label>
        <p-dropdown class="OverInput_override" [options]="holeConfig" id="teamFormat" [(ngModel)]="roundLeaderboardConfig.leaderboardCode" [autoWidth]="false"></p-dropdown>
    </div>
    <div class="col-md-2">
        <label class="col-md-12 p-0">Select</label>
        <input type="checkbox" class="center-block holeConfigCheckbox" [(ngModel)]="roundLeaderboardConfig.selected">
    </div>
</div>