import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChallengeComponent } from './challenge-overview.component';

import { 
    SharedModule, DropdownModule, DataTableModule, 
    DialogModule, SpinnerModule, CalendarModule,
    ConfirmDialogModule,
    ConfirmationService

} from 'primeng/primeng';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';;

@NgModule({
    imports: [CalendarModule, ReactiveFormsModule, DropdownModule, SpinnerModule, DialogModule, BrowserModule, SharedModule, FormsModule, DataTableModule, RouterModule,
    ConfirmDialogModule],
    declarations: [ChallengeComponent ],
    bootstrap: [ChallengeComponent],
    providers: [ConfirmationService]
})
export class ChallengeModule { }