import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from "../../services";

declare var $: any;

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styles: [".logo { background: url('/assets/images/logoWhite.png'); }" ]
})
export class LoginComponent implements OnInit {

    public errorMessage: string;

    constructor(public router: Router,
                public loginService: LoginService)
    {}

    login(username, password) {
        var redirectUrl = '/tournaments';
        this.errorMessage = '';

        this.loginService.login(username, password)
            .subscribe((data:any) => {
                if (data.authenticated) {
                    //Get last url target so we can redirect
                    //Porbably a cleaner way to do this
                    if (localStorage.getItem('lastUrl') !== null && localStorage.getItem('lastUrl') !== '') {
                        redirectUrl = localStorage.getItem('lastUrl');
                        localStorage.removeItem('lastUrl');
                    }
                    this.router.navigateByUrl(redirectUrl);
                    $("body").removeClass('account2');
                    $.backstretch("destroy", false);
                } else {
                    this.errorMessage = 'Wrong username or password';
                }
            },
                error => {
                    this.errorMessage = 'Sorry there was a problem logging you in';
                    console.log(error.text());
                });
    }

    ngOnInit() {
        $("body").addClass('account2');
        $.backstretch("/assets/images/login.jpg",
            {
                fade: 600,
                duration: 4000
            });
    }
}
