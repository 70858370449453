import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';

export module Helpers {
    export class Utility {
        static isNumber(n): boolean {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        static validateEmail(email): boolean {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        static getTextColorLuma(color: string) {
            var rgb = parseInt(color, 16);   // convert rrggbb to decimal
            var r = (rgb >> 16) & 0xff;  // extract red
            var g = (rgb >> 8) & 0xff;  // extract green
            var b = (rgb >> 0) & 0xff;  // extract blue
    
            var luma = (r + g + b) / 3;
    
            if (luma < 128) {
                return 'white';
            }
            return 'black';
        }

    }
    export class DateUtil {
        private static pad(n, width, z='0'): string {
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        constructor() {
            throw new Error("Cannot new this class");
        }

        static momentFormatFromDotNet(dateString: string): DateFormat {
            var dateStringFormat = dateString.toUpperCase();
            return new DateFormat(dateStringFormat);
        }

        //Horrible forcing of a date into its pure UTC format
        static UTCDate(dateForConvert: Date): Date {
            if (dateForConvert) {
                var year = dateForConvert.getFullYear();
                var month = dateForConvert.getMonth() + 1;
                var day = dateForConvert.getDate();
                var dateString = year + '-' + this.pad(month, 2) + '-' + this.pad(day, 2) + 'T00:00:00.000Z'
                var date = new Date(dateString);
                return date;
            }
            return dateForConvert;
        }

        //Horrible forcing of a date into its pure UTC format with time
        static UTCDateTime(dateForConvert: Date): Date {
            if (dateForConvert) {
                var year = dateForConvert.getFullYear();
                var month = dateForConvert.getMonth() + 1;
                var day = dateForConvert.getDate();
                var hours = dateForConvert.getHours();
                var minutes = dateForConvert.getMinutes();
                var dateString = year + '-' + this.pad(month, 2) + '-' + this.pad(day, 2) + 'T' + this.pad(hours, 2) + ':' + this.pad(minutes, 2)+':00.000Z'
                var date = new Date(dateString);
                return date;
            }
            return dateForConvert;
        }

        static jQueryFormatFromDotNet(dateString: string): DateFormat {
            var arraParts = dateString.split("/");
            for (let i = 0; i < arraParts.length; i++) {
                if (arraParts[i] === "yyyy") {
                    arraParts[i] = "yy";
                }
                if (arraParts[i] === "MM") {
                    arraParts[i] = "mm";
                }
            }
            var dateFormat = arraParts.join("/");
            return new DateFormat(dateFormat);
        }

        static jsonToDate(dateFormat: DateFormat, dateString: string): string {
            var formatted = moment.utc(dateString).format(dateFormat.format);
            return formatted;
        }
    }

    export class DateFormat {
        constructor(format) {
            this.format = format;
        }
        public format: string;
    }

    export class ImageUtil{

        static getFileFromURL(imageURL, filename) {
            return this.blobToFile(this.dataURItoBlob(imageURL), filename);
        }
    
        static blobToFile(theBlob: Blob, fileName: string): File {
            var blob: any = theBlob;
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            blob.lastModifiedDate = new Date();
            blob.name = fileName;
    
            //Cast to a File() type
            return <File>blob;
        }
    
        static dataURItoBlob(dataURI) {
    
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var buffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(buffer);
    
            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }
    
            var blob = new Blob([buffer], { type: mimeString });
            return blob;
    
        }
    }
}
