<div class="container-fluid">
  <div class="row" id="table-section">
      <div class="col-md-12">
          <div class="row">
              <div class="col-md-12">
                  <div class="row-fluid" style="margin-top:69px;">
                      <div class="col-md-12 bg-gray-light border bd-6 m-b-20 p-t-20">
                          <div class="SetupWizard_leaderboard">
                              <div class="row SetupWizard1">
                                  <a [routerLink]="['/tournaments/'+tournamentId+'/edit']">
                                      <div class="col-md-4 SetupWizard_step SetupWizard_step2">
                                          <span>Setup</span>
                                      </div>
                                  </a>
                                  <a [routerLink]="['/tournaments/'+ tournamentId +'/round/' + firstRoundId + '/playerlist']">
                                      <div class="col-md-4 SetupWizard_step SetupWizard_step3">
                                          <span>Players</span>
                                      </div>
                                  </a>
                                  <div class="col-md-4 SetupWizard_step SetupWizard_step1">
                                      <span class="span_active">Leaderboards</span>
                                  </div>
                              </div>
                          </div>
                          <div class="btn-group  pull-right" *ngIf="roundsSelect.length>1">
                              <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                                  <span>{{selectorText}}</span>
                                  <span class="caret"></span>
                              </button>
                              <span class="dropdown-arrow dropdown-arrow-inverse"></span>
                              <ul class="dropdown-menu dropdown-inverse" role="menu">
                                  <li *ngFor="let roundSelect of roundsSelect">
                                      <a [routerLink]="['/tournaments/'+tournamentId+'/round/'+roundSelect.value+'/createleaderboard']">Round {{roundSelect.label}}</a>
                                  </li>
                              </ul>
                          </div>
                          <div class="m-b-20">
                              <div class="btn-group">
                                  <button id="table-edit_new" class="btn btn-default m-b-5" style="width:fit-content;" (click)="showLeaderboardAdd()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_tvleaderboard.svg" style="margin-right: 6px">Add Leaderboard</button>
                              </div>
                              <div class="btn-group" *toggleFeature="'tvMobileAdvertSpace';offeringLevel:offeringLevel">
                                  <button id="table-edit_new" class="btn btn-default m-b-5" style="width: auto" (click)="showAdvertdAdd()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_advert.svg" style="margin-right: 6px">{{'TOURNAMENT.LEADERBOARD.ADD_ADVERT' | translate}}</button>
                              </div>
                              <div pTooltip="{{getTooltipText()}}" tooltipPosition="bottom" class="pull-right m-r-10" *toggleFeature="'deviceLBBranding';offeringLevel:offeringLevel">
                                  <button id="table-edit_new" class="btn btn-default m-b-5 pull-right" type="button" style="width:fit-content;" [disabled]="leaderboardDetails.length === 0" (click)="showTvDisplaySettings()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_settings.svg" style="margin-right: 6px">Manage TV Leaderboard Branding</button>
                              </div>
                              <div class="pull-right m-r-10" *toggleFeature="'deviceLBBranding';offeringLevel:offeringLevel">
                                  <button *ngIf="isAgent" class="btn btn-default m-b-5 pull-right" type="button" style="width:fit-content;" (click)="showManageMediaModal()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_media.svg" style="margin-right: 6px">Manage Device Branding</button>
                              </div>
                              <div class="pull-right m-r-10" *toggleFeature="'deviceLBBranding';offeringLevel:offeringLevel">
                                  <button *ngIf="isCalloway" class="btn btn-default m-b-5 pull-right" type="button" style="width:auto;" (click)="runCallowayAdjustment()">Run Calloway Adjustment</button>
                              </div>

                          </div>
                          <hr>
                          <h2>
                            <strong>Add & Schedule your Leaderboards</strong>
                          </h2>
                          <div class="container-fluid bg-lightgray border bd-6" style="background-color:#f8f8f8; margin-bottom:20px;padding:120px 0px" *ngIf="showPods == false">
                              <div class="row centered">
                                  <div class="col-md-12 center-card">
                                      <div class="row" style="margin-bottom:25px;">
                                          <div class="col-md-12 center-text">

                                              <img src="../../../../assets/icons/leaderboard-icon/tms_icons_avatar.svg" alt="">
                                              <p>Get started by creating a Leaderboard or Advert.</p>
                                          </div>
                                      </div>
                                      <div class="row">
                                        <div class="btn-group">
                                          <button id="table-edit_new" class="btn btn-default m-b-5" style="width:fit-content;" (click)="showLeaderboardAdd()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_tvleaderboard.svg" style="margin-right: 6px">Add Leaderboard</button>
                                      </div>
                                      <div class="btn-group" *toggleFeature="'tvMobileAdvertSpace';offeringLevel:offeringLevel">
                                          <button id="table-edit_new" class="btn btn-default m-b-5" style="width: auto" (click)="showAdvertdAdd()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_advert.svg" style="margin-right: 6px">{{'TOURNAMENT.LEADERBOARD.ADD_ADVERT' | translate}}</button>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="row" *ngIf="showPods == true">
                              <div [dragula]='"leaderboard-bag"' [dragulaModel]="LeaderboardPods" [dragulaOptions]="dragulaOptions">
                                  <leaderboard-pod [ChildDisplayDetails]="pod" *ngFor="let pod of LeaderboardPods" style="max-width:300px; float:left; margin-right:20px; margin-left:15px;" (RequestDelete)="showDeleteSettings($event)" (RequestEdit)="showEdit($event)"></leaderboard-pod>
                              </div>
                          </div>
                          <div class="row">
                              <div class="sf-controls clearfix">
                                  <a class="prev-btn sf-left sf-btn sf-btn-prev" style="margin-left:10px;" [routerLink]="['/tournaments/'+ tournamentId +'/round/' + firstRoundId + '/playerlist']">
                                      <div class="btn btn-default div-btn" >BACK</div>
                                  </a>
                                  <a class="next-btn sf-right sf-btn sf-btn-next" (click)="navigateToTournament()">
                                      <div class="btn btn-default pull-right div-btn">
                                          SAVE & EXIT
                                      </div>
                                  </a>
                              <button id="table-edit_new" class="btn btn-default m-b-5 pull-right" style="width: fit-content;" (click)="preview()"><img src="../../../../assets/icons/leaderboard-icon/tms_icons_preview.svg" style="margin-right: 6px">Preview Leaderboards</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<leaderboard-edit></leaderboard-edit>
<advert-edit></advert-edit>
<leaderboard-display-settings (displaySettingsUpdated)="displaySettingsUpdated($event)"></leaderboard-display-settings>
<leaderboard-item-delete [(displayIteamDeleteDialog)]="displayIteamDeleteDialog" (CloseDeleteItem)="hideDeleteSettings($event)" [ItemToRemove]="Item" [ItemTitle]="lbTitle"></leaderboard-item-delete>
<manage-media-modal [roundId]="roundID"></manage-media-modal>


<script>
  function prelink() {
      var host = window.location.host;
      var tourID = "/public/tournaments/" + tournamentId;
      var RouID = "/round/" + roundID;
      var url = host + tourID + RouID + "/liveleaderboard";
      window.open(url);
  }
</script>
