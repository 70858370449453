<div class="col-md-12 bg-primary bd-6 playerPool" pDroppable="dd" (onDrop)="DropFlightMemberOnPool($event)">
    <div class="row col-md-12">
        <h2 class="gl-color">
            <strong>Golfer</strong> List
        </h2>
    </div>
    <div>
        <select class="form-control input-lg" data-style="white" name="searchScope" required [(ngModel)]="searchScope" (change)="searchScopeChanged($event.target.value)">
            <option *ngFor="let p of searchScopeSelect" [value]="p.value">{{p.label}}</option>
        </select>
        <input class="form-control input-lg" type="search" style="margin-top:10px;" [formControl]="playerSearchText" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" placeholder="Enter player name">

        <span *ngIf="searchInProgress"><i class="fa fa-spinner fa-spin"></i> Search...</span>
    </div>
    <div class="dd nestable">
        <h3 class="gl-color">
            <strong>Search Results</strong> List
        </h3>
        <ul class="dd-list">
            <player-pool-renderer [profile]="golfer" *ngFor="let golfer of playerSearchResultStored" (dragPlayerEnd)="reloadData()"></player-pool-renderer>
        </ul>
        <div class="gl-color" *ngIf="(totalCount>0 && filteredCount==0 && !searchInProgress)">No Matching Results</div>
        <div class="gl-color" *ngIf="(totalCount==0 && !searchInProgress && searchScope!='VPAR')">No Results</div>
        <div class="gl-color" *ngIf="(totalCount==0 && !searchInProgress && searchScope=='VPAR')">Enter player name</div>
        <p-paginator [rows]="10" [totalRecords]="filteredCount" (onPageChange)="loadData($event)" *ngIf="(filteredCount>0)" styleClass="grayHyperlink">
        </p-paginator>
    </div>
</div>
