import * as i0 from "@angular/core";
export class AppCookieService {
    constructor() {
        this.cookieStore = {};
        this.parseCookies(document.cookie);
    }
    parseCookies(cookies = document.cookie) {
        this.cookieStore = {};
        if (!!cookies === false) {
            return;
        }
        const cookiesArr = cookies.split(";");
        for (const cookie of cookiesArr) {
            const cookieArr = cookie.split("=");
            this.cookieStore[cookieArr[0].trim()] = cookieArr[1];
        }
    }
    get(key) {
        this.parseCookies();
        return !!this.cookieStore[key] ? this.cookieStore[key] : null;
    }
    remove(key) {
        document.cookie = `${key} = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
    }
    removeToken() {
        this.cookieStore["token"].remove();
    }
    set(key, value) {
        document.cookie = key + "=" + (value || "");
    }
    setCompetitionHostCookie(value) {
        let hostName = `${window.location.host}`;
        if (hostName.includes("localhost")) {
            hostName = "localhost";
        }
        document.cookie = `${hostName}_host_key` + "=" + (value || "");
    }
    getCompetitionHostIdCookie() {
        this.parseCookies();
        let hostName = `${window.location.host}`;
        if (hostName.includes("localhost")) {
            hostName = "localhost";
        }
        return !!this.cookieStore[`${hostName}_host_key`]
            ? this.cookieStore[`${hostName}_host_key`]
            : "";
    }
}
AppCookieService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppCookieService_Factory() { return new AppCookieService(); }, token: AppCookieService, providedIn: "root" });
