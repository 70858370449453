/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-picture-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./profile-picture-upload.component";
import * as i5 from "../../../services/uploadservice";
import * as i6 from "ngx-toastr";
var styles_ProfilePictureUpload = [i0.styles];
var RenderType_ProfilePictureUpload = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfilePictureUpload, data: {} });
export { RenderType_ProfilePictureUpload as RenderType_ProfilePictureUpload };
function View_ProfilePictureUpload_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uploading"]))], null, null); }
function View_ProfilePictureUpload_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-paperclip"]], null, null, null, null, null))], null, null); }
export function View_ProfilePictureUpload_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["form", 1]], null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 10, "label", [["class", "btn btn-default m-b-5"]], [[1, "for", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "btn-disabled": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 0, "input", [["style", "display:none;"], ["type", "file"]], [[1, "id", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload Photo"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfilePictureUpload_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfilePictureUpload_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "btn btn-default m-b-5"; var currVal_9 = _ck(_v, 9, 0, (_co.uploadInProgress || _co.disabled)); _ck(_v, 8, 0, currVal_8, currVal_9); var currVal_12 = _co.uploadInProgress; _ck(_v, 14, 0, currVal_12); var currVal_13 = _co.fileAdded; _ck(_v, 16, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = "picture-selector"; _ck(_v, 6, 0, currVal_7); var currVal_10 = "picture-selector"; var currVal_11 = (_co.uploadInProgress || _co.disabled); _ck(_v, 10, 0, currVal_10, currVal_11); }); }
export function View_ProfilePictureUpload_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-picture-upload", [], null, null, null, View_ProfilePictureUpload_0, RenderType_ProfilePictureUpload)), i1.ɵdid(1, 245760, null, 0, i4.ProfilePictureUpload, [i5.UploadService, i6.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfilePictureUploadNgFactory = i1.ɵccf("profile-picture-upload", i4.ProfilePictureUpload, View_ProfilePictureUpload_Host_0, { profileID: "profileID", disabled: "disabled" }, { fileUploaded: "fileUploaded" }, []);
export { ProfilePictureUploadNgFactory as ProfilePictureUploadNgFactory };
