import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { IBranding } from "../models";
import { encodeString } from "../shared/utils/encryption";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class BrandingService {
  constructor(public http: HttpClient) {}

  public loadBranding(): Observable<IBranding> {
    const encrypted = encodeString(window.location.host);
    if (environment.isLive) {
      return this.http
        .post<IBranding>(
          `${environment.apiUrl}/api/CompetitionHostBranding/get-branding`,
          { siteUrl: encrypted }
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError((error) => {
            console.error("Error loading branding", error);
            return throwError(error);
          })
        );
    } else {
      return this.http
        .post<IBranding>(
          `${environment.apiUrl}/api/CompetitionHostBranding/get-branding`,
          {
            siteUrl: encrypted,
            competitionHostId: environment.overrideCompetitionHost,
          }
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError((error) => {
            console.error("Error loading branding", error);
            return throwError(error);
          })
        );
    }
  }
}
