export class ArrivalsMedia {
    
    constructor() {        
    }

    accessCode = '';
    backgroundImg: string = null;
    mediaLeft: string = null;
    mediaRight: string = null;
    title = '';
    venue = '';
    venueLogoFileName: string = null;
}