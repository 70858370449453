import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ResultService, RoundService } from "../../../services";
import { ToastrService } from "ngx-toastr";
import { ConfirmDialog } from "../../../shared/index";
import { environment } from "src/environments/environment";

@Component({
  selector: "player-sheet-upload",
  templateUrl: "PlayerSheetUpload.html",
  styleUrls: ["./PlayerSheetUpload.scss"],
})
export class PlayerSheetUpload implements OnInit {
  @Input() competitionID: string;
  @Input() roundID: string;
  @Output() sheetUploaded = new EventEmitter();
  uploadInProgress: boolean;
  fileUploadUrl: string;

  @ViewChild("uploadPlayersConfirm", { static: false })
  uploadPlayersConfirmDialog: ConfirmDialog;

  constructor(
    private toastr: ToastrService,
    private resultService: ResultService,
    private roundService: RoundService
  ) {}

  ngOnInit() {
    this.fileUploadUrl = `${environment.competitionApiUrl}/api/5.0/rounds/import-startsheet/${this.roundID}`;
  }

  uploadPlayers() {
    this.resultService.roundInPlay(this.roundID).subscribe((res) => {
      let uploadControl: HTMLElement = document.querySelector(
        "#playersUploadControl input"
      ) as HTMLElement;

      if (res === 200) {
        uploadControl.click();
      } else if (res === 409) {
        var callBack = function (event) {
          uploadControl.click();
        };
        this.uploadPlayersConfirmDialog.showDialog(callBack);
      }
    });
  }

  myUploader(event) {
    this.uploadInProgress = true;
    this.toastr.info("Uploading Players. This may take a minute or so");

    if (event.files) {
      this.roundService
        .uploadPlayers(event, parseInt(this.roundID))
        .then((result) => {
          this.uploadInProgress = false;
          if (result.success) {
            this.sheetUploaded.emit(true);
            this.toastr.success("Players were uploaded successfully");
          } else {
            this.toastr.error(`Failed to upload player sheet.`);
            result.errors.forEach((e) => {
              this.toastr.error(e);
            });
          }
        })
        .catch((error) => {
          console.log("aaaa ", error);
          console.error("Error uploading players:", error);
          this.uploadInProgress = false;
          this.toastr.error("Failed to upload player sheet");
        });
    }
  }

  onUpload(event) {    
    this.uploadInProgress = false;
    this.toastr.info(event.xhr.response);    
    this.sheetUploaded.emit(true);
  }
}
