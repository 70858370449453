import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoundService, CompetitionRouteService } from '../services';
import { Observable, of } from 'rxjs';

@Injectable()
export class RoundIdResolver implements Resolve<Observable<string>> {
    constructor(private competitionRouteService: CompetitionRouteService, private roundService: RoundService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        var roundID = route.params['roundId'];
        if (roundID) {
            this.competitionRouteService.roundID$.next(roundID);
            this.roundService.rounds$.subscribe(rounds => {
                var currentRound = _.find(rounds, { roundID: +roundID });
                this.competitionRouteService.currentRound$.next(currentRound);
            })
            this.roundService.load(roundID);
        } else {
            this.competitionRouteService.roundID$.next("");
            this.competitionRouteService.currentRound$.next(null);
        }
        return of((<string>roundID));
    }
}