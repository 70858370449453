import { Component, OnInit  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


import { TournamentComponent } from '../tournament/tournament.component';

@Component({
    selector: 'mainapp',
    templateUrl: 'loggedin.component.html',
    styleUrls:['loggedin.component.scss']
})

export class LoggedInComponent implements OnInit {


    constructor(private router: Router) { }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
        if(this.router.url.includes("public")){
          document.getElementById('main').style.marginLeft = "0px"
       }
    }
}
