import { Injectable, ElementRef } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RoundForScoredcard } from "../models";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";
import { LoginService } from "./login.service";
import { HttpHeaders } from "@angular/common/http";
import { MappingService } from "./mapping.service";

@Injectable()
export class ScoresService {
  constructor(
    private httpService: HttpServices,
    private loginService: LoginService,
    private mappingService: MappingService
  ) {}

  loadScores(
    roundID: string,
    teamID: number = null,
    flightID: number = null
  ): Observable<RoundForScoredcard> {
    var scoresLoadObserverable = new Subject<RoundForScoredcard>();
    var url = this.buildUrl(roundID, teamID, flightID);
    this.httpService.getTyped<RoundForScoredcard>(url).subscribe(
      (data) => {
        scoresLoadObserverable.next(data);
      },
      (err) => console.log(err),
      () => console.log(`Complete Get Scorecard`)
    );
    return scoresLoadObserverable;
  }

  post(roundData: RoundForScoredcard): Observable<boolean> {
    var scoresObserverable = new Subject<boolean>();
    let authToken = "";
    console.log(roundData)
    this.loginService.loggedInDetail$.subscribe((token) => {
      if (token !== "" && token !== undefined && token !== null) {
        roundData.profileId = token.ProfileId;
        authToken = token.AuthToken;
      }
    });

    var url = `${environment.competitionApiUrl}/api/5.0/scores`;
    const payload = this.mappingService.mapScoreToPostScore(roundData);
    this.httpService
      .post(url, payload, [{ name: "APIToken", value: authToken }])
      .subscribe(
        (data) => {
          scoresObserverable.next(true);
        },
        (err) => {
          console.log(err);
          scoresObserverable.next(true);
          console.log(`Error During Post Scorecard`);
        },
        () => console.log(`Complete Post Scorecard`)
      );
    return scoresObserverable;
  }
  private buildUrl(roundID: string, teamID: number, flightID: number): string {
    // var environment = this.configService.GetAPIUrl();
    var url = `${environment.apiUrl}/api/rounds/${roundID}`;
    if (teamID) {
      url = url + `/team/${teamID}/scorecard`;
    }
    if (flightID) {
      url = url + `/flight/${flightID}/scorecard`;
    }
    return url;
  }
}
