import { Component, Input, OnInit } from '@angular/core';
import { LeaderboardService } from '../../../services';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { SelectItem } from 'primeng/primeng';
import { RoundLeaderboard, RoundLeaderboardConfig, DisplayItem } from '../../../models';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'leaderboard-edit',
    templateUrl: 'leaderboard.edit.component.html',
    styleUrls: ['leaderboard.edit.component.scss']
})

export class LeaderboardEditComponent  {
    @Input() parentGroup: FormGroup;

    public leaderboardEditDetail: RoundLeaderboard;
    public roundLeaderboardConfigs: RoundLeaderboardConfig[] = [];
    public show: boolean = false;
    public timer: any;
    public roundID: string;
    public leaderboardHeaderText: string;
    inputsForm:FormGroup;
    
    teamFormat: SelectItem[] = [];
    playerType: SelectItem[] = [];
    gameFormat: SelectItem[] = [];
    scoringMethod: SelectItem[] = [];
    scoringType: SelectItem[] = [];
    aggregateType: SelectItem[] = [];
    aggregateThruType: SelectItem[] = [];
    countbackType: SelectItem[] = [];
    skinCurrencyTypes: SelectItem[] = [];
    skinRolloverType: SelectItem[] = [];
    freezeOnXHole: SelectItem[]=[];
    IsOnWebsite: boolean = false;
    IsOnHandset: boolean = false;
    

    closeLBedit: boolean
    tabSelect: boolean = false;
    showEmptyState: boolean = true;

    offeringLevel: number;
    gameFormatError:boolean;

    public environment = environment;


 
   constructor(public leaderboardService: LeaderboardService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder) {

    this.teamFormat = DomainHelpers.SelectLists.getTeamformat();
    this.playerType = DomainHelpers.SelectLists.getPlayerTypes(true);
    this.gameFormat = DomainHelpers.SelectLists.getGameformat();
    this.scoringMethod = DomainHelpers.SelectLists.getScoringmethod();
    this.scoringType = DomainHelpers.SelectLists.getScoringtype();
    this.aggregateType = DomainHelpers.SelectLists.getAggregatetype();;
    this.aggregateThruType = DomainHelpers.SelectLists.getAggregatethru();
    this.countbackType = DomainHelpers.SelectLists.getCountbacktype();
    this.skinCurrencyTypes = DomainHelpers.SelectLists.getSkincurrencytypes();
    this.skinRolloverType = DomainHelpers.SelectLists.getSkinrollovertype();
    this.freezeOnXHole = DomainHelpers.SelectLists.getFreezeOnXHole();

    this.offeringLevel = this.route.parent.snapshot.data['branding'].offeringType;
    this.gameFormatError = false;
}

      onToggleWebSite(event) {
          this.leaderboardEditDetail.isOnWebsite = event.target.checked;
       }
       onToggleHandset(event) {
        this.leaderboardEditDetail.isOnHandset = event.target.checked;
    }
  

    addLeaderboard(event) {
        console.log(this.leaderboardEditDetail);
        if (this.validHoleRanges() && this.validateGameFormat()) {
            if (this.leaderboardEditDetail.roundLeaderboardID == 0) {
                this.leaderboardEditDetail.roundID = parseInt(this.roundID);
                this.leaderboardService.add(30, this.leaderboardEditDetail).subscribe(a => {
                    //Hack to force loading of leaderboards after adding
                    this.leaderboardService.loadAll(this.leaderboardEditDetail.roundID.toString());
                });
            } else {
                this.leaderboardService.update(this.leaderboardEditDetail, this.roundID).subscribe(a => {
                    //Hack to force loading of display items after updating
                    this.leaderboardService.LoadAllDisplayItems(this.leaderboardEditDetail.roundID.toString());
                });
            }

            this.roundLeaderboardConfigs.forEach((t, index) => {
                t.roundID = parseInt(this.roundID);
                t.roundLeaderboardID = this.leaderboardEditDetail.roundLeaderboardID;
            });

            // this.leaderboardService.SaveHoleConfig(this.roundLeaderboardConfigs);
            this.leaderboardService.UpdateHoleConfig(this.roundLeaderboardConfigs);

            this.leaderboardService.recalculateLeaderboard(this.roundID).subscribe({ error: e => console.error(e) });

            this.tabSelect = false;
            this.show = false;
            this.gameFormatError = false;
        }
    }

    Close(event) {
        this.tabSelect = false;
        this.roundLeaderboardConfigs = [];
        this.show = false;
        this.gameFormatError = false;
    }

    ShowHoleConfig(event) {
        this.tabSelect = false;

    }

    ShowLbSetup(event) {
        alert('a')
        if (this.leaderboardEditDetail.roundLeaderboardID == 0) {
            this.leaderboardEditDetail.roundID = parseInt(this.roundID);
            this.roundLeaderboardConfigs = [];
            this.leaderboardService.add(30, this.leaderboardEditDetail).subscribe((data: DisplayItem) => {
                this.leaderboardEditDetail.roundLeaderboardID = data.itemId;
                //Hack to force loading of leaderboards after adding
                this.leaderboardService.loadAll(this.leaderboardEditDetail.roundID.toString());
            });
        }
        if (this.roundLeaderboardConfigs.length === 0) {
            this.leaderboardService.GetHoleConfig(this.leaderboardEditDetail.roundLeaderboardID).subscribe(d => {
                this.roundLeaderboardConfigs = d;
                if (d.length > 0) {
                    this.showEmptyState = false;
                }
            });
        }
        this.tabSelect = true;
    }

    CopyHoleConfig(event) {
        this.roundLeaderboardConfigs.forEach((l, index) => {
            if (l.selected) {
                let CopyConfig: RoundLeaderboardConfig = new RoundLeaderboardConfig();
                CopyConfig.holeEnd = l.holeEnd;
                CopyConfig.holeStart = l.holeStart;
                CopyConfig.leaderboardCode = l.leaderboardCode;
                this.roundLeaderboardConfigs.push(CopyConfig);
            }
        });
    }

    DeleteHoleConfig(event) {
        this.roundLeaderboardConfigs.forEach((c, index) => {
            if (c.selected) {
                this.roundLeaderboardConfigs.splice(index, 1);
            }
        });
    }

    SelectAllHoleConfig(event) {
        this.roundLeaderboardConfigs.forEach((t, index) => {
            t.selected = true;
        });
    }

    AddHoleConfig(event) {
        this.showEmptyState = false;
        let emptyConfig: RoundLeaderboardConfig = new RoundLeaderboardConfig();
        if (this.roundLeaderboardConfigs.length === 0) {
            this.pushConfig(emptyConfig);
        } else if (this.roundLeaderboardConfigs[this.roundLeaderboardConfigs.length - 1].holeEnd < 18) {
            emptyConfig.holeStart = this.roundLeaderboardConfigs[this.roundLeaderboardConfigs.length - 1].holeEnd + 1;
            if (emptyConfig.holeStart === 18) {
                emptyConfig.holeEnd = 18;
            } else {
                emptyConfig.holeEnd = this.roundLeaderboardConfigs[this.roundLeaderboardConfigs.length - 1].holeEnd + 2;
            }
            this.pushConfig(emptyConfig);
        } else {
            if (!this.checkGaps()) {
                this.toastr.error("You can't add more holes");
            }
        }
    }

    onTeamFormatChange(event: any) {
        if (event.value === '1') {
            this.leaderboardEditDetail.flightMemberType = 100;
        }
        if (event.value === '3') {
            this.leaderboardEditDetail.overrideGameType = this.gameFormat[2].value;
        }
        // in creation mode, set title of the leaderboard as the selected team format
        if (this.leaderboardEditDetail.isCreationMode) {
            if (event.value !== null) {
                this.leaderboardEditDetail.title = this.teamFormat.find((item) => item.value === event.value).label;
            } else {
                this.leaderboardEditDetail.title = '';
            }
        }
    }

    private validateGameFormat() {
        this.gameFormatError = false;
        if (!this.leaderboardEditDetail.overrideGameType) {
            this.gameFormatError = true;
            return false;
        }

        return true;
    }

    private checkGaps() {

        let emptyConfig: RoundLeaderboardConfig = new RoundLeaderboardConfig();

        for (let i = 0; i < this.roundLeaderboardConfigs.length; i++) {
            if (this.roundLeaderboardConfigs[i + 1] && (this.roundLeaderboardConfigs[i].holeEnd + 1) !== this.roundLeaderboardConfigs[i + 1].holeStart) {
                emptyConfig.holeStart = this.roundLeaderboardConfigs[i].holeEnd + 1;
                emptyConfig.holeEnd = this.roundLeaderboardConfigs[i + 1].holeStart - 1;
                emptyConfig.highlighted = true;
                this.roundLeaderboardConfigs.splice(i + 1, 0, emptyConfig);
                setTimeout(() => {
                    this.roundLeaderboardConfigs[i + 1].highlighted = false;
                }, 3000);

                return true;
            }
        }

        return false;
    }

    private pushConfig(config: RoundLeaderboardConfig) {
        config.highlighted = true;
        const indexToHighlight = this.roundLeaderboardConfigs.length;
        this.roundLeaderboardConfigs.push(config);
        setTimeout(() => {
            if (this.roundLeaderboardConfigs[indexToHighlight]) {
                this.roundLeaderboardConfigs[indexToHighlight].highlighted = false;
            }
        }, 3000);
    }

    private validHoleRanges() {
        // clean errors first
        this.roundLeaderboardConfigs.forEach((config) => {
            config.errorHoleEnd = false;
            config.errorHoleStart = false;
            config.errorMessage = '';
        });
        let hasErrors = false;

        if (this.roundLeaderboardConfigs.length > 0) {
            // Hole configuration starst at 1st hole
            if (this.roundLeaderboardConfigs[0].holeStart !== 1) {
                this.roundLeaderboardConfigs[0].errorHoleStart = true;
                this.roundLeaderboardConfigs[0].errorMessage = 'First range should start at 1st hole';
                hasErrors = true;
            }
            // and ends at 18th hole
            if (this.roundLeaderboardConfigs[this.roundLeaderboardConfigs.length - 1].holeEnd !== 18) {
                this.roundLeaderboardConfigs[this.roundLeaderboardConfigs.length - 1].errorHoleEnd = true;
                this.roundLeaderboardConfigs[this.roundLeaderboardConfigs.length - 1].errorMessage = 'Last range should finish at 18th hole';
                hasErrors = true;
            }

            // validate all ranges
            this.roundLeaderboardConfigs.forEach((config) => {
                if (config.holeStart > config.holeEnd) {
                    config.errorHoleStart = true;
                    config.errorHoleEnd = true;
                    config.errorMessage = 'Invalid range';
                    hasErrors = true;
                }
            });

            for (let i = 0; i < this.roundLeaderboardConfigs.length; i++) {
                if (this.roundLeaderboardConfigs[i + 1] && (this.roundLeaderboardConfigs[i].holeEnd + 1) !== this.roundLeaderboardConfigs[i + 1].holeStart) {
                    this.roundLeaderboardConfigs[i].errorHoleEnd = true;
                    this.roundLeaderboardConfigs[i + 1].errorHoleStart = true;
                    this.roundLeaderboardConfigs[i].errorMessage = 'Ranges should be consecutive';
                    this.roundLeaderboardConfigs[i + 1].errorMessage = 'Ranges should be consecutive';
                    hasErrors = true;
                }
            }
        }

        return !hasErrors;
    }
}
function ngOnInit() {
    throw new Error('Function not implemented.');
}

