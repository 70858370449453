import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "./../../environments/environment";
import { AppCookieService } from "./CookiesServices";
import { BrandingService } from "./branding.service";
import { HttpServices } from "./HttpServices";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class LoginService {
  jwtHelper: JwtHelperService = new JwtHelperService();
  loggedInDetail$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private _dataStore: {
    token: any;
  };

  constructor(
    private router: Router,
    public http: HttpClient,
    public cookiesServices: AppCookieService,
    private brandingServices: BrandingService
  ) {
    this._dataStore = { token: "" };
    if (this.isLoggedIn()) {
      var token = this.cookiesServices.get("token");
      var decodedToken = this.jwtHelper.decodeToken(token);
      this._dataStore.token = decodedToken;
      this.loggedInDetail$.next(this._dataStore.token);
    }
  }

  login(username, password): Observable<Response> {
    var loginObserver = new Subject<Response>();

    this.brandingServices.loadBranding().subscribe((b) => {
      this.cookiesServices.setCompetitionHostCookie(
        b.competitionHostID.toString()
      );

      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        CompetitionHostId: `${b.competitionHostID}`,
      });

      // ajax call
      this.http
        .post(
          `${environment.apiUrl}/api/Token`,
          { username, password },
          { headers }
        )
        .subscribe((response: any) => {
          if (response.authenticated) {
            this.cookiesServices.set("token", response.token);

            var decodedToken = this.jwtHelper.decodeToken(response.token);
            this._dataStore.token = decodedToken;
            this.loggedInDetail$.next(this._dataStore.token);
          }
          loginObserver.next(response);
          loginObserver.complete();
        });
    });
    (error) => {
      this.logout();
      console.log(error.text());
    };

    return loginObserver;
  }

  logout() {
    this.cookiesServices.remove("token");
    //this.cookiesServices.removeToken();
    this._dataStore.token = "";
    this.loggedInDetail$.next(this._dataStore.token);
    this.router.navigate(["login"]);
  }

  isLoggedIn(): boolean {
    var token = this.cookiesServices.get("token");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    return false;
  }
}
