<div class="row round-overview-data">
    <!-- Round Details -->
    <div class="detail-section first-section">
        <div class="overview-section width-100p">
            <p class="m-b-20 section-title">Round Details:</p>
            <p class="c-gray m-b-5 f-13"><i class="fa fa-calendar-o p-r-20 f-15"></i><span
                    class="c-dark">{{buildRoundStartDate(round.startDate)}}</span></p>
            <p class="c-gray m-b-5 f-13"><i class="vpar-icon vpar-course course-icon f-19"></i><span
                    class="c-dark">{{round.course.venueTitle}},&nbsp;{{round.course.title}}</span></p>
            <p class="c-gray m-b-5 f-13"><i class="vpar-icon vpar-edit p-r-20 f-15"></i><span
                    class="c-dark">{{round.gameTypeDescription}},&nbsp;{{round.teamTypeDescription}}</span></p>
            <p class="c-gray m-b-5 f-13"><i class="vpar-icon vpar-tee p-r-20 f-14"></i><span
                    class="c-dark">{{round.course.holeCount}} holes</span></p>
        </div>
    </div>

    <div class="detail-section middle-section middle-left-section" id="middle-section" [ngClass]="{'mobile-hidden': !loggedId}">
        <!-- For single-round tournaments -->
        <!-- <div class="middle-left-section"> -->
            <div class="overview-section top-simple-section">
                <p class="m-b-5 gray-title">Tournament Status</p>
                <label class="f-20  c-dark m-b-0" *ngIf="isTournamentOpen">Open</label>
                <label class="f-20  c-dark m-b-0" *ngIf="!isTournamentOpen">Closed</label>
                <label class="switch pull-right" *onlyLoggedIn>
                    <input type="checkbox" [(ngModel)]="isTournamentOpen" (change)="onStatusChange($event)">
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="overview-section bottom-icon-section" *ngIf="offering != 1">
                <div class="key-code">
                    <img src="../assets/icons/overview-icons/key.svg" alt="">
                </div>
                <div class="key-text">
                    <p class="m-b-0 gray-title" id="title-text">Tournament Code</p>
                    <p id="key-code">{{tournament?.accessCode}}</p>
                </div>
            </div>
        <!-- </div> -->


        <!-- For multi-round tournaments -->
        <!-- <div *ngIf="isMultiRound" class="overview-section bottom-icon-section">
            <div class="quick-link overview-icon">
                <div class="row m-auto">
                    <div class="icon circle">
                        <i class="vpar-icon vpar-vpar"></i>
                    </div>
                </div>
            </div>
            <div class="counter-section">
                <p class="m-b-5 section-title">VPAR Members</p>
                <label class="data">{{vparMembers}}</label>
            </div>
        </div> -->
    </div>
    <!--Arrivals -->
    <div class="detail-section middle-section mobile-hidden" id="arrived">
        <div class="overview-section top-icon-section">
            <div class="arrived">
                <img src="./assets/icons/overview-icons/done.svg" alt="">
            </div>
            <div class="counter-section">
                <p class="m-b-5 gray-title" id="arrived-title">Golfers Arrived</p>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <label class="f-20  c-dark m-b-0 percent-bar">{{playersArrived}}/{{totalPlayers}}</label>
                        <p-progressBar [value]="registrationPercentage"></p-progressBar>
                    </div>
                </div>
            </div>
        </div>
        <div class="overview-section bottom-simple-section" id="checkedIn" [style.visibility]="loggedId ? 'visible' : 'hidden'">
            <div class="checked-in">
                <img src="../assets/icons/overview-icons/checkedin.svg" alt="">
            </div>
            <div class="counter-section">


                <p class="m-b-5 gray-title">Checked In</p>
                <label class="data">{{playersCheckedIn}}</label>
            </div>
        </div>
    </div>

    <!-- TV Leaderboard -->
    <div class="detail-section last-section mobile-hidden" id="lastSection" [style.visibility]="loggedId ? 'visible' : 'hidden'">
        <div class=" leaderboard-container width-100p">
            <div class="overview-section top-section">
                <div class="pull-right">
                    <button class="btn btn-launch w-600 f-14 m-t-0 button-style" type="button" (click)="goToLeaderboard()"
                        *onlyLoggedIn>Launch</button>
                    <button class="btn btn-edit w-600 f-14 m-b-0 button-style" id="edit-btn" type="button" (click)="editLeaderboard()"
                        *onlyLoggedIn>Edit</button>
                </div>
                <p class="gray-title">TV Leaderboard</p>
                <p class="description">Connect a TV screen to display live scores.</p>
            </div>
            <div class="overview-section tv-leader" >
                <div class="leaderboard-section">
                    <a class="arrivalsLink" (click)="goToArrivalScreen()">View arrivals screen</a>
                </div>


            </div>
        </div>
    </div>
</div>

<confirm-dialog titleText="Close Tournament" message="Do you wish to close this tournament?"></confirm-dialog>
