import { Injectable } from "@angular/core";
import { Round, Stats } from "../models";
import { Observable, of, Subject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class ResultService {
  result$: Observable<boolean>;
  headers: HttpHeaders = new HttpHeaders();

  constructor(private toastr: ToastrService, public httpService: HttpServices) {
    this.headers.append("Content-Type", "application/json; charset=utf-8");
  }

  refresh(round: Round): Observable<boolean> {
    var observable = new Subject<boolean>();
    const url =
      environment.competitionApiUrl +
      `/api/5.0/leaderboards/recalculate/${round.roundID}`;

    this.httpService
      .post(url, null)
      .pipe(
        catchError((error: any) => {
          if (error.status === 200) {
            this.toastr.success("Results refreshed");
            observable.next(true);
            observable.complete();
          } else {
            console.log(error);
            this.toastr.error("Could not refresh result");
          }

          return of(error);
        })
      )
      .subscribe();

    return observable;
  }

  refreshbyRoundId(roundId: string): Observable<boolean> {
    var observable = new Subject<boolean>();
    const url =
      environment.competitionApiUrl +
      `/api/5.0/leaderboards/recalculate/${roundId}`;

    this.httpService
      .post(url, null)
      .pipe(
        tap((res: any) => {
          if (res) {
            this.toastr.success("Results refreshed");
            observable.next(true);
            observable.complete();
          }
        }),
        catchError((error: any) => {
          console.log("Error:", error);
          this.toastr.error("Could not refresh results");
          observable.next(false);
          observable.complete();
          return of(false);
        })
      )
      .subscribe();

    return observable;
  }

  stats(roundId: string): Observable<Stats[]> {
    var observable = new Subject<Stats[]>();
    this.httpService
      .get(`${environment.apiUrl}/api/Result/${roundId}/Stats`)
      .subscribe(
        (data: Array<Stats>) => {
          observable.next(data);
          observable.complete();
          return data;
        },
        (error) => {
          this.toastr.error("Could not load stats");
        }
      );
    return observable;
  }

  wipeAll(roundID: string): Observable<boolean> {
    var wipeAll = new Subject<boolean>();

    this.httpService
      .delete(`${environment.apiUrl}/api/Result/WipeScore/${roundID}`)
      .subscribe(
        (data: Array<boolean>) => {
          wipeAll.complete();
          this.toastr.success("Round scores wiped");
        },
        (error) => {
          this.toastr.error("Could not wipe round scores");
        }
      );
    return wipeAll;
  }

  wipeByTeam(roundID: string, teamID: string): Observable<boolean> {
    var wipeAll = new Subject<boolean>();

    this.httpService
      .delete(
        `${environment.apiUrl}/api/Result/WipeScore/${roundID}/team/${teamID}`
      )
      .subscribe(
        (data: Array<boolean>) => {
          wipeAll.complete();
          this.toastr.success("Team scores wiped");
        },
        (error) => {
          this.toastr.error("Could not wipe team scores");
        }
      );
    return wipeAll;
  }

  wipeByTeeTime(roundID: string, flightID: string): Observable<boolean> {
    var wipeAll = new Subject<boolean>();

    this.httpService
      .delete(
        `${environment.apiUrl}/api/Result/WipeScore/${roundID}/flight/${flightID}`
      )
      .subscribe(
        (data: Array<boolean>) => {
          wipeAll.complete();
          this.toastr.success("Tee-Time scores wiped");
        },
        (error) => {
          this.toastr.error("Could not wipe tee-time scores");
        }
      );
    return wipeAll;
  }

  wipeByPlayer(roundID: string, flightMemberID: string): Observable<boolean> {
    var wipeAll = new Subject<boolean>();

    this.httpService
      .delete(
        `${environment.apiUrl}/api/Result/WipeScore/${roundID}/player/${flightMemberID}`
      )
      .subscribe(
        (data: Array<boolean>) => {
          wipeAll.complete();
          this.toastr.success("Player scores wiped");
        },
        (error) => {
          this.toastr.error("Could not wipe player score");
        }
      );
    return wipeAll;
  }

  roundInPlay(roundId: string): Observable<number> {
    var popupsObserverable = new Subject<number>();
    this.httpService
      .get(`${environment.apiUrl}/api/Result/InPlay/` + roundId)
      .subscribe(
        (res: number) => {
          popupsObserverable.next(res);
        },
        (err) => console.log(err)
      );
    return popupsObserverable;
  }
}
