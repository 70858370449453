import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class PrintingService {
  customHeaders = [{ name: "Accept", value: "'application/pdf'" }];

  constructor(public httpService: HttpServices) {}

  Get(competitionID: string, query: any): Observable<string> {
    var printObserver = new Subject<string>();
    var url = `${environment.apiUrl}/api/printing/Competition/${competitionID}`;
    this.httpService.post(url, query, this.customHeaders).subscribe(
      (data: string) => {
        printObserver.next(data);
        printObserver.complete();
      },
      (error) => console.log("Could not get Print Url.")
    );
    return printObserver;
  }

  Export(query: any): Observable<Blob> {
    var url = `${environment.apiUrl}/api/printing/export-flights`;
    return this.httpService.postBlob(url, query);
  }

  ExportLeaderBoard(query: any): Observable<Blob> {
    var url = `${environment.apiUrl}/api/printing/export-leaderboard`;
    return this.httpService.postBlob(url, query, this.customHeaders);
  }

  ExportResults(query: any): Observable<Blob> {
    var url = `${environment.apiUrl}/api/printing/export-results`;
    return this.httpService.postBlob(url, query, this.customHeaders);
  }

  ExportScores(query: any): Observable<Blob> {
    var url = `${environment.apiUrl}/api/printing/export-scores`;
    return this.httpService.postBlob(url, query, this.customHeaders);
  }

  Get2(query: any): Observable<any> {
    const url = `${environment.apiUrl}/api/printing/startsheet`;
    return this.httpService.postBlob(url, query, this.customHeaders);
  }
}
