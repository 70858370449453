import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Round, Team } from '../../../models';
import { ConfirmDialog } from '../../../shared/index';
import { TeamsService } from '../../../services';
import { SelectItem } from 'primeng/primeng';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'team-management',
    templateUrl: 'teammanagement.html',
    styleUrls: ['./teammanagement.scss']
})
export class TeamManagement implements OnChanges {

    _teams: Array<Team>;
    originalTeams: Array<Team>;

    private reloadedTeamIndex = -1;
    private reloadedTeamId = -1;

    showError = false;
    isMatchplay = false;
    showMessage: boolean = false;
    messageText: string = '';

    asciiCodeA = 65;
    asciiCodeZ = 90;
    currentAsciiCode;
    doubleLetterMode;

    get teams() {
        return this._teams;
    }
    @Input() set teams(value: Array<Team>) {
        this.originalTeams = value;
    }
    @Input() teamDeleteEnabled: boolean = false;
    @Input() competitionID: string;
    @Input() teamColoursSelect: SelectItem[] = [];

    @Input() displayTeamDialog: boolean = false;
    @Input() currentRound: Round = new Round();
    @Output() displayTeamDialogChange = new EventEmitter();
    @Output() teamsSave = new EventEmitter();

    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;

    constructor(public teamsService: TeamsService) {
        this.asciiCodeA = 65; 
        this.asciiCodeZ = 90; 
        this.currentAsciiCode = this.asciiCodeA;
        this.doubleLetterMode = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        // if showing the modal then reload the original teams
        if (changes.displayTeamDialog && changes.displayTeamDialog.currentValue) {
            this._teams = _.cloneDeep(this.originalTeams);
            this.reloadedTeamIndex = -1;
            this.reloadedTeamId = -1;
            this.showError = false;
        }

        // if teams updated (added a logo) then update logo fields of the team
        if (changes.teams && this.reloadedTeamIndex !== -1) {
            const tempTeam = changes.teams.currentValue.find((team) => team.teamID === this.reloadedTeamId);
            this._teams[this.reloadedTeamIndex].logoFilename = tempTeam.logoFilename;
            this._teams[this.reloadedTeamIndex].pathLogoFilename = tempTeam.pathLogoFilename;
            this.reloadedTeamIndex = -1;
            this.reloadedTeamId = -1;
        }

        // if team was added
        if (changes.teams && this._teams && changes.teams.currentValue.length > this._teams.length) {
            const tempTeam = changes.teams.currentValue[0];
            tempTeam.highlighted = true;
            this._teams.splice(0, 0, tempTeam);
            setTimeout(() => {
                this._teams[0].highlighted = false;
            }, 3000);
        }
    }


    // Function to display the message when the disabled element is clicked
    displayMessage() {
      this.messageText = 'This element is disabled. Message goes here.';
      this.showMessage = true;
    }

    getLogoUrl(team: Team) {
        if (team.logoFilename) {
            return "https://" + environment.cdnUrl + "/" + team.pathLogoFilename;
        }
        return "";
    }

    reloadTeam(teamID: number) {
        this.reloadedTeamIndex = this._teams.findIndex((team) => team.teamID === teamID);
        this.reloadedTeamId = teamID;
        this.teamsService.load(this.competitionID, teamID.toString());
    }

    save() {
        if (this.validateTeams()) {
            this.teamsService.saveTeams(this.competitionID, this._teams);
            this.teamsSave.emit(true);
            this.close();
        }

    }

    validateTeams() {
        this.showError = false;

        this._teams.forEach((team) => {
            if (team.title.trim() === '') {
                this.showError = true;
            }
        });

        this._teams.forEach((team) => {
            if (this.checkNameRepeated(team.title,team.teamID)) {
                this.showError = true;
            }
        });

        return !this.showError;
    }

    checkNameRepeated(teamName, teamId){
        return _.some(this._teams, function (t) {
            return (t.title.trim() === teamName.trim()) && (t.teamID !== teamId);
        })
    }

    delete(teamIndex: number) {
        if (this.currentRound.gameType === 2) {
            this.messageText = 'For MatchPlay games, team deletion is restricted. However, you have the flexibility to update both the team name and its associated colour';
            this.showMessage = true;
        } else {
            var self = this;
            const tempTeam = this._teams[teamIndex];
            var callBack = function () {
                self.teamsService.remove(tempTeam.competitionID, tempTeam.teamID);
                self._teams.splice(teamIndex, 1);
            }
            this.confirmDialog.showDialog(callBack);
        }
    }
    

    deleteClick(teamIndex) {
        this._teams[teamIndex].logoFilename = '';
        this._teams[teamIndex].pathLogoFilename = '';
    }

    close() {
        this.displayTeamDialogChange.emit(false);
        this.showMessage = false;
    }

    visibleChange(event) {
        this.displayTeamDialogChange.emit(event);
    }

    addTeam() {
      if(this.currentRound.gameType === 2 && this._teams.length >= 2){
        this.isMatchplay = true;
      }
      if(!this.isMatchplay){
        const newTeam = new Team();
        newTeam.title = this.getTeamName();
        newTeam.competitionID = parseInt(this.competitionID);
        this.teamsService.add(this.competitionID, newTeam);
      }else{
        this.isMatchplay = false;
        this.messageText = 'You can’t add any more that two teams to a Matchplay game.';
        this.showMessage = true;
      }

    }

    private getTeamName() {
        let result = "Team ";

        if (this.doubleLetterMode) {
            result += String.fromCharCode(this.currentAsciiCode) + String.fromCharCode(this.currentAsciiCode);
        } else {
            result += String.fromCharCode(this.currentAsciiCode);
        }

        this.currentAsciiCode++;

        if (this.currentAsciiCode > this.asciiCodeZ && !this.doubleLetterMode) {
            this.currentAsciiCode = this.asciiCodeA;
            this.doubleLetterMode = true;
        }
        return result;
    }
}
