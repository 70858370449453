/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./team-logo-upload.component";
import * as i4 from "../../../services/uploadservice";
import * as i5 from "ngx-toastr";
var styles_TeamLogoUpload = [];
var RenderType_TeamLogoUpload = i0.ɵcrt({ encapsulation: 2, styles: styles_TeamLogoUpload, data: {} });
export { RenderType_TeamLogoUpload as RenderType_TeamLogoUpload };
function View_TeamLogoUpload_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "uploading-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Uploading"]))], null, null); }
export function View_TeamLogoUpload_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { form: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["form", 1]], null, 10, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(3, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 5, "label", [["class", "btn btn-dark m-b-5 uploader-label"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "input", [["style", "display:none;"], ["type", "file"]], [[1, "id", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "uploader-edit-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-upload"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TeamLogoUpload_1)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.uploadInProgress; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = ("team-logo-selector_" + _co.teamID); _ck(_v, 6, 0, currVal_7); var currVal_8 = ("team-logo-selector_" + _co.teamID); var currVal_9 = _co.uploadInProgress; _ck(_v, 7, 0, currVal_8, currVal_9); }); }
export function View_TeamLogoUpload_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "team-logo-upload", [], null, null, null, View_TeamLogoUpload_0, RenderType_TeamLogoUpload)), i0.ɵdid(1, 245760, null, 0, i3.TeamLogoUpload, [i4.UploadService, i5.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeamLogoUploadNgFactory = i0.ɵccf("team-logo-upload", i3.TeamLogoUpload, View_TeamLogoUpload_Host_0, { competitionID: "competitionID", teamID: "teamID" }, { fileUploaded: "fileUploaded" }, []);
export { TeamLogoUploadNgFactory as TeamLogoUploadNgFactory };
