import { takeUntil } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Round, Competition, IBranding } from "../../../models";
import { VenueService, CompetitionDataService } from "../../../services";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { RoundRendererComponent } from "./round.renderer.component";
import { Helpers } from "../../../helpers/helpers";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { Settings } from "src/app/models/settings";
import { SettingsServices } from "src/app/services/SettingsService";

@Component({
  templateUrl: "tournament-create.component.html",
  styleUrls: ["tournament-create.component.scss"],
})
export class TournamentCreateComponent implements OnInit, OnDestroy {
  model: Competition = new Competition();
  public competitionForm: FormGroup;
  public $stop: Subject<boolean> = new Subject<any>();
  hasChanges: boolean;
  venueID: number;
  competitionHostId: number;
  active: boolean = true;
  dateFormat: string;
  dateFormatMoment: string;
  isAgent: boolean = false;
  isHandicapQualified: boolean = false;
  handicapQualifiedDisabled: boolean = false;
  isTeeAdjustment: boolean = true;
  roundActiveIndex: number = 0;
  showVPARHandicap: boolean = false;
  showCalloway: boolean = false;
  showTeeAdjustment: boolean = false;
  hcpAdjustment: boolean = true;
  settingsHandicap: boolean = true;
  orderOfMeritActive: boolean = false;
  orderOfMeritRoundValue: boolean = false;

  constructor(
    private _fb: FormBuilder,
    public router: Router,
    public competitionDataService: CompetitionDataService,
    public venueService: VenueService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private settingsService: SettingsServices
  ) {}

  getBranding() {
    var branding = <IBranding>(
      this.activatedRoute.parent.snapshot.data["branding"]
    );
    this.venueID = branding.venueID;
    this.competitionHostId = branding.competitionHostID;
    if (this.venueID && this.venueID != 0) {
      this.venueService.load(this.venueID);
    }
    let dateFormat = Helpers.DateUtil.jQueryFormatFromDotNet(
      branding.dateFormat
    ).format;
    this.dateFormat = dateFormat;
    this.dateFormatMoment = Helpers.DateUtil.momentFormatFromDotNet(
      branding.dateFormat
    ).format;
    this.isAgent = branding.isAgent;
    this.showVPARHandicap = branding.isVPARHandicap;
    this.showCalloway = branding.isCallaway;
    this.orderOfMeritActive = branding.orderOfMeritActive;
  }

  ngOnInit() {
    this.competitionForm = this._fb.group({
      title: [this.model.title, [Validators.required, Validators.minLength(2)]],
      rounds: this.initRounds(),
      isHandicapQualified: this.isHandicapQualified,
      isTeeAdjustment: this.isTeeAdjustment,
    });
    this.getBranding();
    this.settingsService
      .getSettings(this.competitionHostId)
      .subscribe((data) => {
        if (data) {
          this.hcpAdjustment = data.hcpAdjustments;
          this.competitionForm.controls["isHandicapQualified"].setValue(
            data.vparHandicap
          );
          if (data.vparHandicap) {
            this.showTeeAdjustment = false;
            this.isHandicapQualified = true;
          } else {
            this.showTeeAdjustment = true;
          }
          this.competitionForm.valueChanges
            .pipe(takeUntil(this.$stop))
            .subscribe((values) => (this.hasChanges = true));

          this.competitionDataService.useDecimalScoring$.subscribe((value) => {
            var self = this;
            var rounds = (<FormArray>this.competitionForm.controls["rounds"])
              .controls;

            _.forEach(rounds, function (round: FormGroup) {
              self.competitionDataService.decScoringBroadcasted$ = true;
              (<FormGroup>round.controls["roundConfiguration"]).controls[
                "useDecimalScoring"
              ].setValue(value);
            });
          });
        }
        var rounds = (<FormArray>this.competitionForm.controls["rounds"])
          .controls;
        var hcpAdjust = this.hcpAdjustment;
        _.forEach(rounds, function (round: FormGroup) {
          (<FormGroup>round.controls["roundConfiguration"]).controls[
            "handicapIsAdjustedForSlopeRating"
          ].setValue(hcpAdjust);
        });
      });
  }

  ngDoCheck() {}

  receiveGameTypeChange(message: string) {
    if (message == "2") {
      this.competitionForm.get("isHandicapQualified").setValue(false);
      this.isHandicapQualified = false;
      this.handicapQualifiedDisabled = true;
    } else this.handicapQualifiedDisabled = false;
  }

  initRounds() {
    var array = this._fb.array([]);
    this.model.rounds.forEach((round) => {
      array.push(RoundRendererComponent.buildItem(round, this.venueID != 0));
    });
    return array;
  }

  getRounds(): FormGroup[] {
    return (this.competitionForm.controls["rounds"] as FormArray)
      .controls as FormGroup[];
  }

  addRound() {
    var rounds = <FormArray>this.competitionForm.controls["rounds"];
    var latest: Round = rounds.value[rounds.length - 1];
    var latestCopy: Round = _.clone(latest);
    latestCopy.roundID = 0;
    latestCopy.startDate = null;
    latestCopy.startTime = null;
    latestCopy.courseID = null;
    var newRoundElement = RoundRendererComponent.buildItem(
      latestCopy,
      this.venueID != 0
    );
    rounds.push(newRoundElement);
    this.roundActiveIndex = rounds.length - 1;
  }

  setTeeAdjustment(e) {
    if (e.checked) {
      this.showTeeAdjustment = false;
      this.isTeeAdjustment = true;
      this.isHandicapQualified = true;
    } else {
      this.showTeeAdjustment = true;
      this.isTeeAdjustment = false;
    }
  }

  setRoundOrderOfMerit(e) {
    this.orderOfMeritRoundValue = e.checked;
  }

  deleteRound(roundIndex) {
    var rounds = <FormArray>this.competitionForm.controls["rounds"];
    rounds.removeAt(roundIndex);
    if (roundIndex == rounds.length) {
      this.roundActiveIndex = roundIndex - 1;
    } else {
      this.roundActiveIndex = roundIndex;
    }
  }

  onSubmit(competition: Competition) {
    competition.startDate = Helpers.DateUtil.UTCDate(
      competition.rounds[0].startDate
    );

    competition.isOrderOfMerit = this.orderOfMeritRoundValue;
    competition.rounds.forEach((round) => {
      round["venueSelect"] = null;
      round.startDate = new Date(
        round.startDate.getFullYear(),
        round.startDate.getMonth(),
        round.startDate.getDate(),
        round.startTime.getHours(),
        round.startTime.getMinutes()
      );
      round.startDate = Helpers.DateUtil.UTCDateTime(round.startDate);
    });

    if (this.validDates(competition)) {
      this.competitionDataService.add(competition).subscribe(
        (data: Competition) => {
          var redirectUrl = `/tournaments/${data.competitionID}/round/${data.rounds[0].roundID}/playerlist`;
          this.router.navigateByUrl(redirectUrl);
          this.toastr.success("The tournament was successfully created");
        },
        (err) => console.log(err),
        () => console.log("Competition created")
      );
    }
  }

  validDates(competition: Competition) {
    // check round are ordered in time
    for (let i = 0; i < competition.rounds.length; i++) {
      if (i + 1 < competition.rounds.length) {
        if (
          moment(competition.rounds[i].startDate).isAfter(
            moment(competition.rounds[i + 1].startDate)
          )
        ) {
          this.toastr.error("Round dates should be ordered");
          return false;
        }
      }
    }

    // check rounds doesn't start before tournament date
    if (
      moment(competition.startDate).isAfter(
        moment(competition.rounds[0].startDate),
        "day"
      )
    ) {
      this.toastr.error("Tournament date should be before rounds dates");
      return false;
    }

    return true;
  }

  ngOnDestroy() {
    this.$stop.next(true);
  }
}
