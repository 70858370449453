<div class="page-content page-cnt">
    <div class="edit-pnl">
        <div class="panel-content row" id="table-section">
            <div class="SetupWizard">
                <div class="col-lg-4 SetupWizard_step SetupWizard_step1">
                    <span class="span_active">Setup</span>
                </div>
                <div class="col-lg-4 SetupWizard_step SetupWizard_step2">
                    <div class="arrow-right"></div>
                    <span>Players</span>
                </div>
                <div class="col-lg-4 SetupWizard_step SetupWizard_step3">
                    <span>Leaderboards</span>
                </div>
            </div>
            <div class="col-lg-12 tabs tabs-linetriangle p-20">
                <form (ngSubmit)="onSubmit(competitionForm.value)" [formGroup]="competitionForm">
                    <input type="hidden" formControlName="competitionID" />
                    <p class="m-b-15 f-18 bold">First, let's get started by setting up your tournament</p>
                    <div class="row">
                        <div class="form-group col-lg-5">
                            <p class="text-muted bold">Tournament Name</p>
                            <input type="text" class="form-control input-lg input-color" formControlName="title"
                                placeholder="Enter Tournament Name">
                            <div [hidden]="competitionForm.controls.title.valid || competitionForm.controls.title.pristine"
                                class="alert alert-danger">
                                Tournament Name is required
                            </div>
                        </div>
                        <div class="form-group col-lg-2" *ngIf="showVPARHandicap">
                            <p class="text-muted bold">
                                <span>
                                    <a href="#" class="popoverInfo"
                                        data-content="Enable if you want this game to count towards your players' VPAR handicap calculation.">
                                        <i class="fa fa-question-circle tooltip-colour"></i>
                                    </a>
                                </span>VPAR Handicap
                            </p>
                            <div class="form-control Override_input-lg inputSwitch"
                                [class.inputSwitchOff]="!competitionForm.controls.isHandicapQualified.value">
                                <p-inputSwitch styleClass="p-inputSwitch" (onChange)="setIsHandicapQualifiedManually($event)"
                                formControlName="isHandicapQualified"  ></p-inputSwitch>
                            </div>
                        </div>
                        <div class="form-group col-lg-2" *ngIf="showTeeAdjustment">
                            <p class="text-muted bold">
                                <span>
                                    <a href="#" class="popoverInfo"
                                        data-content="Allows you to run the course difficulty tee adjustments to your players' handicaps when the game is not enabled for VPAR Handicap.">
                                        <i class="fa fa-question-circle tooltip-colour"></i>
                                    </a>
                                </span>Tee Adjustment
                            </p>
                            <div class="form-control Override_input-lg inputSwitch"
                                [class.inputSwitchOff]="!isTeeAdjustment">
                                <p-inputSwitch styleClass="p-inputSwitch" (onChange)="setTeeAdjustmentManully($event)"
                                    formControlName="isTeeAdjustment"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="form-group col-lg-2" *ngIf="orderOfMeritActive">
                            <p class="text-muted bold">
                                <span>
                                    <a href="#" class="popoverInfo"
                                       data-content="If this is on the scores from this round will update your order of merit leaderboard. When your competition is with Order Of Merit active you can have only one round">
                                        <i class="fa fa-question-circle m-r-10 tooltip-colour" ></i>
                                    </a>
                                </span>Order of Merit
                            </p>
                            <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!competitionForm.get('isOrderOfMerit').value">
                                <p-inputSwitch styleClass="p-inputSwitch" (onChange)="setOrderOfMerit($event)" formControlName="isOrderOfMerit" [disabled]="disabledOrderOfMeritToggle"></p-inputSwitch>
                            </div>
                        </div>
                    </div>
                    <p-tabView [activeIndex]="roundActiveIndex" (onChange)="tabChange($event)" class="roundsTab">
                        <p-tabPanel *ngFor="let item of getRounds(); let i=index" header="Round {{i + 1}}"
                            [selected]="roundActiveIndex === i" [disabled]="!competitionForm.valid">
                            <round-renderer (roundDeleted)="deleteRound($event)"
                                (gameTypeChange)="receiveGameTypeChange($event)"
                                [roundCount]="competitionForm.get('rounds')['controls'].length" [isAgent]="isAgent"
                                [venueID]="venueID" [index]="i" [item]="item" [dateFormat]="dateFormat"
                                [dateFormatMoment]="dateFormatMoment"></round-renderer>
                        </p-tabPanel>
                    </p-tabView>
                    <div class="p-t-10 text-right bd-t">
                        <button type="button" (click)="cancel()" class="btn btn-default"
                            *ngIf="competitionForm.dirty || isDirty">Cancel</button>
                        <button type="submit" class="btn btn-default"
                            *ngIf="(competitionForm.dirty || isDirty) && competitionForm.valid && !displaySaveWithModal">Save</button>
                        <button type="button" class="btn btn-default" (click)="openModal(competitionForm.value)"
                            *ngIf="(competitionForm.dirty || isDirty) && competitionForm.valid && displaySaveWithModal">Save</button>
                        <button type="button" (click)="next()" class="btn btn-default"
                            *ngIf="!competitionForm.dirty && !isDirty">Next</button>
                    </div>
                    <button type="button" class="btn btn-link addRoundButton text-muted" (click)="addRound()"
                        [disabled]="getRounds().length >= 10 || !competitionForm.valid">
                        <i class="fa icon-plus"></i> Add another round
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
<p-dialog header="Info" [(visible)]="displayModalHasScores" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
    [resizable]="false">
    <p class="m-0">This round already has scores, you can change only limited fields</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayModalHasScores=false" label="Ok"
            styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>


<div *ngIf="displayModal">
    <div class="confirm-modal">
        <div class="confirm-modal-content">
            <span class="close" (click)="closeModal()">&times;</span>
            <h4><b>Confirm changes</b></h4>
            <hr />
            <div style="padding: 30px; font-size: 17px;">
                <p>Making this change could affect the playing handicaps for this game. Please choose an option.</p>
                <br />
                <p><b>Revert Changes</b> Discard these changes and return you to the game overview.</p>
                <br />
                <p><b>Keep current handicaps</b> Confirm these changes and keep currently calculated handicaps.</p>
                <br />
                <p><b>Recalculate Handicaps</b> Confirm these changes and update handicaps based on new SR/CR and
                    handicap
                    allowance data.</p>
            </div>
            <div class="button-container">
                <button (click)="revertChanges()">Revert Changes</button>
                <button (click)="keepChanges()">Keep Current</button>
                <button (click)="recalculateHandicaps()">Recalculate Handicaps</button>
            </div>
        </div>
    </div>
</div>