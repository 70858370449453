import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Round, Flight, RoundMessage, UrbanAirshipByNameUser } from '../../../models';
import { SelectItem } from 'primeng/primeng';
import { FlightService, MessagingService } from '../../../services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'round-messaging',
    templateUrl: 'round-messaging.component.html',
    styleUrls: ['./round-messaging.component.scss']
})

export class RoundMessagingComponent implements OnInit {

    roundID: string;
    tournamentId: string;
    $stop: Subject<boolean> = new Subject<any>();

    teeTimeID: string = '';
    teeTimes: SelectItem[] = new Array<SelectItem>();
    currentTeeTimes: SelectItem[] = new Array<SelectItem>();

    public messagingForm: FormGroup;

    constructor(private route: ActivatedRoute,
        private flightService: FlightService,
        private _fb: FormBuilder,
        private messagingService: MessagingService,
        private toastr: ToastrService) {
        this.currentTeeTimes = [{ label: '-', value: '' }];
    }

    ngOnInit() {

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            this.roundID = (<Round>data['round']).roundID.toString();
            this.tournamentId = (<Round>data['round']).competitionID.toString();
            this.flightService.loadAll(this.tournamentId, this.roundID);

        });

        this.flightService.flights$.pipe(takeUntil(this.$stop)).subscribe(
            (data: Array<Flight>) => {
                this.teeTimes = this.teeTimes.concat(data.map(flight => { return { label: "Hole " + flight.startHole.toString() + " - " + moment.utc(flight.teeOffTime).format("HH:mm"), value: flight.flightID } }));

                var flight = [];
                flight.push({ label: '-', value: null });
                flight = flight.concat(this.teeTimes);
                this.currentTeeTimes = flight;
            });

        this.messagingForm = this._fb.group({
            Title: ['', [Validators.required, Validators.maxLength(50)]],
            Messages: ['', [Validators.required, Validators.maxLength(250)]],
            Link: ['', [Validators.maxLength(250)]],
            TeeTimeID: [null, [this.teeTimeValidator]]
        });
    }

    onSubmit(formData: RoundMessage) {

        this.messagingForm.markAsDirty();

        if (this.messagingForm.valid) {            

            this.messagingService.sendMessage(formData);

            this.toastr.success('Messages sent');
        }
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    private teeTimeValidator(control: FormControl) {

        if (control.value === null || control.value === 'null') {
            return { teeTimeID: true };
        }

        return null;
    }
}
