<div class="row">
    <div class="col-md-6 col-xs-6">
        <div class="form-group m-b-0">
            <!-- <span class="holeTitle">Hole</span> -->
            <p-dropdown [options]="holes" [(ngModel)]="startHole" [scrollHeight]="'285px'" [style]="{'width':'85px','border-radius':'8px','color':'#e65c24'}" (onChange)="changedHole()" [disabled]="!editable"></p-dropdown>
        </div>
    </div>
    <div class="col-md-1 pull-right" *ngIf="deleteable">
        <a (click)="removeFlight()" class="pull-right">
            <i class="fa fa-times"></i></a>
    </div> 
    <div class="col-md-3 col-xs-6 pull-right clock-part">
        <img src="assets/icons/golfers-icons/ios_icons_time.svg" class="clock-icon">
        <input class="form-control input-sm pull-right time-input" [(ngModel)]="teeOffTimeText" type="time" (blur)="blurTeeOffTime()" [disabled]="!editable">
    </div>
</div>
<div class="dd nestable">
    <ul class="dd-list">
        <flight-team-group *ngFor="let flightTeamGroup of flight.flightTeamGroups; let i = index;" [flightTeamGroup]="flightTeamGroup" [competitionID]="round.competitionID" [teams]="teams">
        </flight-team-group>
    </ul>
</div>
<confirm-dialog titleText="Remove Tee-Time " message="Do you wish to remove this tee-time?" *ngIf="deleteable"></confirm-dialog>
