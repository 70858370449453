import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintOptionDetail } from '.././PrintOptionDetail';
import { PrintingService, FlightService } from '../../../../services';
import { SelectItem } from 'primeng/primeng';
import { Round, Flight, Competition } from '../../../../models';
import { Subject } from 'rxjs';
import { UploadService, MediaService } from '../../../../services';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'print-component-v2',
    templateUrl: 'Print2.component.html',
    styleUrls: ['Print2.component.scss']
})

export class PrintingComponent2 implements OnInit, OnDestroy {
    roundId: string;
    tournamentId: string;

    PrintOptions: Array<PrintOptionDetail> = new Array<PrintOptionDetail>();

    selectedOption: PrintOptionDetail = null;

    teams: SelectItem[] = new Array<SelectItem>();
    flightMembers: SelectItem[] = new Array<SelectItem>();
    teeTimes: SelectItem[] = new Array<SelectItem>();
    currentTeams: SelectItem[] = new Array<SelectItem>();
    currentFlightMembers: SelectItem[] = new Array<SelectItem>();
    currentTeeTimes: SelectItem[] = new Array<SelectItem>();
    teeTimeID?: number;
    flightMemberID?: number;
    teamID?: number;
    currentPrintTypes: SelectItem[];
    selectedPrintType: SelectItem;
    isPrintTypeSelected: boolean = false;

    selectPlayersOptionEnabled: boolean;
    selectTeamOptionEnabled: boolean;
    selectTeeTimeOptionEnabled: boolean;

    freeText = '';
    fileName = '';
    logoFileName = '';
    fileToUpload: File | null = null;
    resultsNumberOfRows = 3;

    public $stop: Subject<boolean> = new Subject<any>();

    loading = false;
    uploadInProgress = false;
    showLogoUpload = false;
    showFreeTextBox = false;
    showTeeTimedropdown = false;
    showResultsRowNumber = false;

    constructor(private route: ActivatedRoute, private printingService: PrintingService,
        private flightService: FlightService, private uploadService: UploadService,
        private toastr: ToastrService, private mediaService: MediaService) {

        this.currentPrintTypes = [
            { label: "Select Print", value: null },
            { label: "CartSign", value: 1 },
            { label: "StartSheet", value: 2 },
            { label: "Labels", value: 3 },
            { label: "Scores", value: 4 },
            { label: "Leaderboard", value: 5 },
            { label: "Results", value: 6 },
        ];

        this.currentTeeTimes = [{ label: '-', value: '' }];

        this.PrintOptions.push(new PrintOptionDetail(1, "Alpha List", "ProfileFirstName"));
    }

    ngOnInit() {

        var self = this;
        this.flightService.flights$.pipe(takeUntil(this.$stop)).subscribe(
            (data: Array<Flight>) => {
                this.teeTimes = this.teeTimes.concat(data.map(flight => { return { label: "Hole " + flight.startHole.toString() + " - " + moment.utc(flight.teeOffTime).format("HH:mm"), value: flight.flightID } }));
            });

        this.route.parent.parent.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var tournament = <Competition>data['tournament'];
            this.tournamentId = tournament.competitionID.toString();
        });

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var round = <Round>data['round'];
            this.roundId = round.roundID.toString();
            this.flightService.loadAll(this.tournamentId, this.roundId);
        });

        this.mediaService.FileName$.pipe(takeUntil(this.$stop)).subscribe(data => {
            this.logoFileName = data ? `https://${environment.cdnUrl}/media/cart-sign/${data}`
                : `https://${environment.cdnUrl}/media/cart-sign/placeholder.png`;
        });

        this.mediaService.GetCartSignFileName(this.roundId);
    }

    onPrintTypeChange(event) {
        if (event.value != null) {
            this.isPrintTypeSelected = true;

            this.showLogoUpload = event.value == 1 ? true : false;
            this.showTeeTimedropdown = event.value == 1 ? true : false;
            this.showFreeTextBox = event.value == 3 ? true : false;
            this.showResultsRowNumber = event.value == 6 ? true : false;

            var teeTimes = [];
            teeTimes.push({ label: 'All', value: null });
            teeTimes = teeTimes.concat(this.teeTimes);
            this.currentTeeTimes = teeTimes;
        }
        else {
            this.isPrintTypeSelected = false;
            this.currentTeeTimes = [];
            this.showLogoUpload = false;
            this.showFreeTextBox = false;
            this.showTeeTimedropdown = false;
            this.showResultsRowNumber = false;
        }

    }

    printOptionUpdated(detail: PrintOptionDetail) {
        this.selectedOption = null;
        if (detail.selected) {
            this.selectedOption = detail;
        }
    }

    download() {
        this.loading = true;
        let query: any;
        query = {
            roundId: this.roundId,
            flightId: this.teeTimeID,
            printType: this.selectedPrintType,
            freeText: this.freeText,
            resultsRowToDisplay: this.resultsNumberOfRows
        };

        switch (parseInt(query.printType)) {
            case 1:
            case 2:
            case 3:
                this.exportFligts(query);
                break;
            case 4:
                this.exportScores(query);
                break;
            case 5:
                this.exportLeaderBoard(query);
                break;
            case 6:
                this.exportResults(query);
                break;
        }

    }

    exportFligts(query) {
        this.printingService.Export(query).subscribe((data) => {
            this.loading = false;
            const downloadedFile = new Blob([data], { type: 'application/pdf' });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            switch (parseInt(query.printType)) {
                case 1:
                    a.download = `Round-${query.roundId}-CartSigns.pdf`;
                    break;
                case 2:
                    a.download = `Round-${query.roundId}-StartSheet.pdf`;
                    break;
                case 3:
                    a.download = `Round-${query.roundId}-Labels.pdf`;
                    break;
            }
            
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        });
    }

    exportScores(query) {

        this.printingService.ExportScores(query).subscribe((data) => {
            this.loading = false;
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = `Round-${query.roundId}-Scores.xlsx`;
            a.href = URL.createObjectURL(blob);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        });
    }

    exportLeaderBoard(query) {
        this.printingService.ExportLeaderBoard(query).subscribe((data) => {
            this.loading = false;
            const downloadedFile = new Blob([data], { type: 'application/pdf' });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = `Round-${query.roundId}-Leaderboard.pdf`;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        });
    }

    exportResults(query) {
        this.printingService.ExportResults(query).subscribe((data) => {
            this.loading = false;
            const downloadedFile = new Blob([data], { type: 'application/pdf' });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = `Round-${query.roundId}-Results.pdf`;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        });
    }

    onFileSelected(files: FileList) {
        this.fileName = files.item(0).name;
        this.fileToUpload = files.item(0);
    }

    uploadLogo() {
        var self = this;
        this.uploadInProgress = true;

        this.uploadService.addMedia(`${environment.apiUrl}/api/Media/UploadCartSignLogo/${this.roundId}`, [this.fileToUpload]).subscribe(response => {
            this.uploadInProgress = false;
            response = String(response);
            response = response.slice(1, -1);
            self.logoFileName = `https://${environment.cdnUrl}/media/cart-sign/${response.toString()}`;
        }, err => {
            this.toastr.error('Failed to upload Image');
            this.uploadInProgress = false;
        });
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

}
// Helper
const StringIsNumber = value => isNaN(Number(value)) === false;

// Turn enum into array
function ToArray(enumme) {
    return Object.keys(enumme)
        .filter(StringIsNumber)
        .map(key => enumme[key]);
}
