import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Title } from "@angular/platform-browser";
//import { enableProdMode, Injector } from '@angular/core';

//https://github.com/auth0-samples/auth0-angularjs2-systemjs-sample
import { ValidatorsModule } from "ngx-validators";
import { CanActivateViaAuthGuard } from "./guards/canActivateViaAuthGuard";
import { RoundIdResolver } from "./resolvers/roundId.resolver";
import { RoundResolver } from "./resolvers/round.resolver";
import { BrandingResolver } from "./resolvers/branding.resolver";
import { CompetitionResolver } from "./resolvers/competition.resolver";
import {
  CompetitionRouteService,
  CompetitionDataService,
  ScoresService,
  RoundService,
  LoginService,
  BrandingService,
} from "./services";
import { NotFoundComponent } from "./components/notfound/notfound.component";
import { TournamentModule } from "./components/tournament/tournament.module";
import { LoginComponent } from "./components/login/login.component";
import { LoggedInComponent } from "./components/loggedin/loggedin.component";
import { GolfersModule } from "./components/golfers/golfers.module";
import { ChallengeModule } from "./components/challenge/challenge.module";
import { OrderofmeritModule } from "./components/orderofmerit/orderofmerit.module";
import { AppSharedModule } from "./shared/app.shared.module";
import { SettingsComponent } from "./components/settings/settings.component";

// import ngx-translate and the http loader
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CourseResolver } from "./resolvers/course.resolver";

import { ConfirmDeactivateGuard } from "./guards/canDeactivateGuard";
// time picker
import { AmazingTimePickerModule } from "amazing-time-picker";

import { ToastrModule } from "ngx-toastr";
import { DragulaModule } from "ng2-dragula";
import { JwtModule } from "@auth0/angular-jwt";
import { OrderBy } from "./pipes/orderBy";
import { SearchFilterPipe } from "./pipes/searchFilter.pipe";
import { SettingsServices } from "./services/SettingsService";
import { AppCookieService } from "./services/CookiesServices";
import { HttpServices } from "./services/HttpServices";
import { MappingService } from "./services/mapping.service";

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  imports: [
    ValidatorsModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    GolfersModule,
    ChallengeModule,
    OrderofmeritModule,
    TournamentModule,
    AppSharedModule,
    HttpClientModule,
    AmazingTimePickerModule,
    ToastrModule.forRoot(),
    DragulaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          "localhost:44317",
          "localhost:53343",
          "localhost:4200",
          "clubplatform-api-dev.azurewebsites.net",
          "clubplatform-ui-dev.azurewebsites.net",
          "clubplatform-api-live.azurewebsites.net",
          "clubplatform-ui-live.azurewebsites.net",
          "vpardevfrontdoor.azurefd.net",
          "vparfrontdoor.azurefd.net",
          "*.vpar.com",
        ],
        skipWhenExpired: true,
      },
    }),
  ], // module dependencies
  declarations: [
    NotFoundComponent,
    LoggedInComponent,
    LoginComponent,
    AppComponent,
    OrderBy,
    SearchFilterPipe,
    SettingsComponent,
  ], // components and directives
  bootstrap: [AppComponent], // root component
  providers: [
    Title,
    CanActivateViaAuthGuard,
    ConfirmDeactivateGuard,
    CompetitionRouteService,
    CompetitionDataService,
    BrandingResolver,
    CompetitionResolver,
    CourseResolver,
    RoundResolver,
    RoundIdResolver,
    RoundService,
    LoginService,
    BrandingService,
    ScoresService,
    SettingsServices,
    AppCookieService,
    HttpServices,
    MappingService,
  ],
})
export class AppModule {
  public test: boolean = true;
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
