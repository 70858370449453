export class RoundConfiguration {
    constructor() {
    }
    roundID: number;
    roundConfigurationID: number = 0;
    //Par is friend
    forceMinimumRelativeNetScoreToContribute: boolean = false;
    useDecimalScoring: boolean = false;
    isModifiedRules: boolean = false;
    albatross?: number=8
    eagle?: number = 5;
    birdie?: number = 2;
    par?: number = 0;
    bogey?: number = -1;
    doubleBogey?: number = -3;
    handicapIsAdjustedForSlopeRating: boolean = true;
}