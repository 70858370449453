import { Component, OnInit } from '@angular/core';
import { ResultService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Round, Stats } from '../../../models';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'round-stats',
    templateUrl: 'round-stats.component.html',
    styleUrls: ['round-stats.component.scss']
})

export class RoundStatsComponent implements OnInit {

    loading = false;
    roundID: string = '';
    stats: Stats[] = [];
    statsType = true;

    statsList: any[] = [];

    playerList: Stats[] = [];
    selectedPlayer: Stats;

    displayStatsDialog: boolean = false;
    modalStatLine = {};

    isStableford = false;

    $stop: Subject<boolean> = new Subject<any>();

    constructor(private resultService: ResultService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            this.roundID = (<Round>data['round']).roundID.toString();
            this.isStableford = (<Round>data['round']).gameType === 3;
            this.getStats();
        });
    }

    onStatsTypeChange(e) {
        this.clearSelectedPlayer();
        this.selectedPlayer = null;
        this.buildStats();
    }

    buildStats() {
        this.statsList = [];

        const statsLines = DomainHelpers.RoundHelper.getStatsLines(this.isStableford);

        statsLines.forEach((item) => {
            this.buildStatSection(item);
        });
    }

    /**
     * Thrown when writing on the autocomplete
     * @param event 
     */
    searchPlayer(event) {
        this.playerList = _.filter(this.stats, function (stat) {
            return stat.salutation.toLowerCase().indexOf(event.query.toLowerCase()) >= 0;
        });
    }

    /**
     * Thrown when user selects a player in autocomplete
     * @param statLine 
     */
    selectPlayer(statLine) {

        this.statsList.forEach((statList, index) => {

            this.statsList[index].showSearchedPlayer = statList.players.filter((item) => item.name === statLine.salutation).length === 0;
            this.statsList[index].searchedPlayer = { pos: this.getPlayerPosition(statList.field, statList.order, statLine), name: statLine.salutation, valueNet: statLine['net' + statList.field], valueGross: statLine['gross' + statList.field] };
        });
    }

    /**
     * Thrown when the user deletes the player in the autocomplete input
     */
    clearSelectedPlayer() {
        this.statsList.forEach((statList) => {
            statList.searchedPlayer = null;
            statList.showSearchedPlayer = false;
        });
    }

    /**
     * Autocomplete change selection
     * @param event 
     */
    selectedPlayerChange(event) {
        // this is a workaround to clear selected player when the user doesn't click on the suggestion list and the selection is cleared
        if (event === null) {
            this.clearSelectedPlayer();
        }
    }

    openAllStatsModal(statLine) {
        this.modalStatLine = statLine;
        this.displayStatsDialog = true;
    }

    private getPlayerPosition(field, order, player) {
        const sortProperty = this.statsType ? 'net' : 'gross';

        let tempPlayers = [];
        if (order === 'asc') {

            tempPlayers = _.sortByOrder(_.filter(this.stats, function (item) { return item[sortProperty + field] !== 0 }), sortProperty + field, order);
            tempPlayers.push(..._.sortByOrder(_.filter(this.stats, function (item) { return item[sortProperty + field] === 0 }), 'salutation', 'asc'));

            this.addPosition(tempPlayers, sortProperty, field);


        } else {
            tempPlayers = _.sortByOrder(this.stats, sortProperty + field, order);

            this.addPosition(tempPlayers, sortProperty, field);
        }
        return tempPlayers[tempPlayers.indexOf(player)].pos;
    }

    private getStats() {
        this.loading = true;
        this.resultService.stats(this.roundID)
            .subscribe(data => {
                if (data.length > 0) {
                    this.stats = data;
                    this.buildStats();
                }
                this.loading = false;
            },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    private buildStatSection(list) {

        const sortProperty = this.statsType ? 'net' : 'gross';

        let tempPlayers = [];
        if (list.order === 'asc') {
            tempPlayers = _.sortByOrder(_.filter(this.stats, function (item) { return item[sortProperty + list.field] !== 0 }), sortProperty + list.field, list.order);
            tempPlayers.push(..._.sortByOrder(_.filter(this.stats, function (item) { return item[sortProperty + list.field] === 0 }), 'salutation', 'asc'));
        } else {
            tempPlayers = _.sortByOrder(this.stats, sortProperty + list.field, list.order);
        }

        this.addPosition(tempPlayers, sortProperty, list.field);        

        if (tempPlayers.length > 0) {
            list.players.push({
                pos: tempPlayers[0].pos, name: tempPlayers[0].salutation,
                valueNet: list.netGross ? tempPlayers[0]['net' + list.field] : tempPlayers[0][`${list.field}`]
                , valueGross: list.netGross ? tempPlayers[0]['gross' + list.field] : tempPlayers[0][`${list.field}`]
            });
        }
        if (tempPlayers.length > 1) {
            list.players.push({
                pos: tempPlayers[1].pos, name: tempPlayers[1].salutation,
                valueNet: list.netGross ? tempPlayers[1]['net' + list.field] : tempPlayers[1][`${list.field}`]
                , valueGross: list.netGross ? tempPlayers[1]['gross' + list.field] : tempPlayers[1][`${list.field}`]
            });    
        }
        if (tempPlayers.length > 2) {
            list.players.push({
                pos: tempPlayers[2].pos, name: tempPlayers[2].salutation,
                valueNet: list.netGross ? tempPlayers[2]['net' + list.field] : tempPlayers[2][`${list.field}`] 
                , valueGross: list.netGross ? tempPlayers[2]['gross' + list.field] : tempPlayers[2][`${list.field}`]
            });
        }

        this.statsList.push(list);
    }

    private addPosition(list: any[], sortProperty, field) {
        let pos = 1;
        for (let i = 0; i < list.length; i++) {
            if (list[i - 1] && list[i - 1][sortProperty + field] === list[i][sortProperty + field]) {
                if (list[i - 1].pos.indexOf('=') >= 0) {
                    list[i].pos = list[i - 1].pos;
                } else {
                    list[i - 1].pos = list[i - 1].pos + '=';
                    list[i].pos = list[i - 1].pos;
                }
            } else {
                list[i]['pos'] = pos.toString();
                pos++;
            }
        }
    }
}
