<div class="dd3-content strongBorder">
    <div *ngIf="changeTeamToggle">
        <span>
            <p-dropdown [options]="teamSelect" [(ngModel)]="team.teamID" [style]="{'width':'150px', height:'25px'}"></p-dropdown>
            <i class="fa fa-check editCheck"  aria-hidden="true" (click)="confirmChangeTeam()"></i>
            <i class="fa fa-times editTimes"  aria-hidden="true" (click)="cancelChangeTeam()"></i>
        </span>
    </div>
    <div *ngIf="!changeTeamToggle">
        <div>
            <span class="teamTitle" *ngIf="team">{{team.title}}</span>
            <span class="emptyTeamTitle" *ngIf="!(team)">Team Name</span>
            <span class="teamcircle" *ngIf="team?.colour" [style.background]="'#'+team.colour"></span>
                <ng-template *ngIf="isEditMode" onlyLoggedIn>
                    <i class="fa fa-pencil editPencil" aria-hidden="true" (click)="changeTeam()" *ngIf="allowReassign"></i>
                </ng-template>
        </div>
    </div>
</div>