import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<any> {

    canDeactivate(target: any) {
        if (target.isEditingScorecards()) {
            const subject = new Subject<boolean>();
            target.subject = subject;
            target.canDeactivate();
            return subject.asObservable();
        }
        return true;
    }

}