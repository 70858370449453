export class DeviceDetail {

    constructor() {
    }

    mediaID: number = 0;
    mediaCategoryID: number = 0;
    title: string = '';
    filename: string = '';
    cdnPath: string = '';
}

export class RoundMediaDetails {

    constructor() {
    }

    roundMediaID: number = 0;
    holeNumber: number = 1;
    roundMediaType: number = 1;
    mediaID: number = 0;
    mediaDetails: DeviceDetail = new DeviceDetail();
    selected: boolean = false;
    highlighted: boolean = false;
    errorHole: boolean = false;
    errorImage: boolean = false;
}

export class RoundMediaCommand {

    constructor() {
    }

    roundID: number;
    roundMediaDetails: RoundMediaCommandDetails[] = [];
}

export class RoundMediaCommandDetails {

    constructor() {
    }

    roundMediaID: number = 0;
    holeNumber: number = 1;
    roundMediaType: number = 1;
    mediaID: number = 0;
    holeID: number = 0;
    roundID: number = 0;
    created: Date = new Date();
}

