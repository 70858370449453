/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i5 from "@angular/material/tabs";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "primeng/components/dom/domhandler";
import * as i8 from "./tab-menu.component";
import * as i9 from "@angular/cdk/layout";
var styles_VparTabMenu = [i0.styles];
var RenderType_VparTabMenu = i1.ɵcrt({ encapsulation: 0, styles: styles_VparTabMenu, data: {} });
export { RenderType_VparTabMenu as RenderType_VparTabMenu };
function View_VparTabMenu_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "ui-menuitem-icon fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "ui-menuitem-icon fa"; var currVal_1 = _v.parent.parent.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VparTabMenu_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VparTabMenu_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "ui-menuitem-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClick($event, _v.parent.parent.context.$implicit, _v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_1); }); }
function View_VparTabMenu_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [["class", "ui-menuitem-link ui-corner-all"]], [[8, "href", 4], [1, "target", 0], [1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClick($event, _v.parent.parent.context.$implicit, _v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.url || "#"); var currVal_1 = _v.parent.parent.context.$implicit.target; var currVal_2 = _v.parent.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_VparTabMenu_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ui-menuitem-link ui-corner-all"]], [[1, "target", 0], [1, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.itemClick($event, _v.parent.parent.context.$implicit, _v.parent.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.parent.context.$implicit.queryParams; var currVal_5 = _v.parent.parent.context.$implicit.routerLink; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.target; var currVal_1 = _v.parent.parent.context.$implicit.title; var currVal_2 = i1.ɵnov(_v, 1).target; var currVal_3 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_VparTabMenu_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 11, "mat-tab", [], null, null, null, i4.View_MatTab_0, i4.RenderType_MatTab)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "ui-tabmenuitem-hasicon": 0 }), i1.ɵdid(4, 770048, [[1, 4]], 2, i5.MatTab, [i1.ViewContainerRef], { disabled: [0, "disabled"], textLabel: [1, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VparTabMenu_3)), i1.ɵdid(8, 16384, [[2, 4]], 0, i5.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VparTabMenu_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["routerLinkTemplate", 2]], 0, 0, null, View_VparTabMenu_6)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit.icon); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.disabled; var currVal_2 = _v.parent.context.$implicit.label; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = !_v.parent.context.$implicit.routerLink; var currVal_4 = i1.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_3, currVal_4); }, null); }
function View_VparTabMenu_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VparTabMenu_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.visible && !(_co.isMobile && (_v.context.$implicit.label === "Stats"))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_VparTabMenu_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = ((_co.selectedIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatTabGroup_0, i4.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i5.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i5.MAT_TABS_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { selectedIndex: [0, "selectedIndex"] }, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VparTabMenu_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.selectedIndex; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.model; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_VparTabMenu_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vpar-tabMenu", [], null, null, null, View_VparTabMenu_0, RenderType_VparTabMenu)), i1.ɵprd(4608, null, i7.DomHandler, i7.DomHandler, []), i1.ɵdid(2, 638976, null, 0, i8.VparTabMenu, [i3.Router, i9.BreakpointObserver], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VparTabMenuNgFactory = i1.ɵccf("vpar-tabMenu", i8.VparTabMenu, View_VparTabMenu_Host_0, { model: "model", activeItem: "activeItem", popup: "popup", style: "style", styleClass: "styleClass", disableAutoSelection: "disableAutoSelection", initialIndex: "initialIndex" }, {}, []);
export { VparTabMenuNgFactory as VparTabMenuNgFactory };
