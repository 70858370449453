<p-dialog class="player-modal" [(visible)]="displayPlayerDialog" [responsive]="true" [modal]="true" [blockScroll]="true" [resizable]="true" [draggable]="true" width="698px" (visibleChange)="visibleChange($event)">
    <p-header>
        {{getModalTitle()}}
    </p-header>
    <div style="width:100%; height: 475px;" *ngIf="showPhotoRegistration">
        <div class="col-md-12">
            <h4>
                <strong>Photo Registration</strong>
            </h4>
            <span>Take a snapshot from the video input and save it as player photo</span>
            <div style="display: flex; margin-bottom: 20px; margin-top: 10px;">
                <div style="display: flex; flex-direction: column; margin-right: 30px; align-items: center">
                    <video autoplay style="width: 175px; height: 175px; border: 1px solid lightgray;"></video>
                    <div style="margin-left: 4px;" *ngIf="videoDevices.length > 1">
                        <label>Video source</label>
                        <p-dropdown class="ddl-combo device" [options]="videoDevices" [(ngModel)]="selectedDevice" optionLabel="label" [autoWidth]="false" (onChange)="getVideoStream()"></p-dropdown>
                    </div>
                    <button class="btn btn-action-ok w-600 f-13 m-b-0 take-photo-btn" [disabled]="!videoStreamReady" (click)="takeScreenShot()">Take photo</button>
                </div>
                <canvas style="width: 300px; height: 300px; border: 1px solid lightgray"></canvas>
            </div>
        </div>
        <div class="col-md-12 upload-logo-buttons">
            <div class="btn-group pull-right m-r-0">
                <button class="btn btn-action-ok w-600 f-13 m-b-0" (click)="saveScreenshot()" [disabled]="!photoTaken">Save photo</button>
            </div>
            <div class="btn-group pull-right">
                <button class="btn btn-action-cancel w-600 f-13 m-b-0" (click)="togglePhotoRegistration()">Back</button>
            </div>
        </div>
    </div>
    <form novalidate [formGroup]="playerForm" (ngSubmit)="save(playerForm.value)" style="width:99%; height: 480px; padding-top:20px" *ngIf="!showPhotoRegistration">
        <input type="hidden" formControlName="profileID" />
        <input type="hidden" formControlName="flightMemberID" />
        <input type="hidden" formControlName="flightID" />
        <div class="ui-grid ui-grid-responsive ui-fluid modal-card">
            <div class="col-md-3 col-lg-2 player-photo">
                <div class="profilePictureDiv">
                    <p>Profile photo</p>
                    <img class="profilePicture" [src]="profilePictureUrl" alt="Profile Picture" />
                </div>
                <div class="pictureUpload">
                    <button *ngIf="!isChrome && allowUpload && videoStreamAvailable" class="btn btn-action-ok m-b-5 take-photo" [disabled]="profilePictureUpload && profilePictureUpload.files" style="min-width: 80px !important; height: 40px !important;" (click)="togglePhotoRegistration()">
                                <!-- <i class="fa fa-camera"></i>
                                <i *ngIf="playerPhoto !== ''" class="fa fa-paperclip"></i> -->
                                <p>Take photo</p>
                    </button>
                    <profile-picture-upload *ngIf="allowUpload" [profileID]="playerCreateDetail?.profileID" [disabled]="playerPhoto !== ''" (fileUploaded)="profileImageUploaded($event)">Upload photo</profile-picture-upload>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 player-profile">
                <div class="m-b-5">
                    <label class="modal-title" for="emailAddress">Email Address</label>
                    <input type="email" pInputText class="modal-input" id="emailAddress" formControlName="emailAddress" />
                    <div [hidden]="playerForm.get('emailAddress').valid || playerForm.get('emailAddress').disabled || (playerForm.get('emailAddress').pristine && !submitted)" class="alert alert-danger">
                        Not Valid Email
                    </div>
                </div>
                <div class="m-b-5">
                    <div>
                        <label class="modal-title" for="firstName">First Name*</label>
                        <input pInputText class="modal-input" id="firstName" formControlName="firstName" />
                        <div [hidden]="playerForm.get('firstName').valid ||playerForm.get('firstName').disabled || (playerForm.get('firstName').pristine && !submitted)" class="alert alert-danger">
                            First Name is required
                        </div>
                    </div>
                </div>
                <div class="m-b-5">
                    <label class="modal-title" for="surname">Last Name*</label>
                    <input pInputText class="modal-input" id="surname" formControlName="surname" />
                    <div [hidden]="playerForm.get('surname').valid ||playerForm.get('surname').disabled|| (playerForm.get('surname').pristine && !submitted)" class="alert alert-danger">
                        Surname is required
                    </div>
                </div>
                <div class="m-b-5">
                    <label class="modal-title" for="nationality">Nationality</label>
                    <p-dropdown class="ddl-combo" [options]="nationalities" id="nationality" formControlName="nationality" [autoWidth]="false"></p-dropdown>
                    <div [hidden]="playerForm.get('nationality').valid || playerForm.get('nationality').disabled || (playerForm.get('nationality').pristine && !submitted)" class="alert alert-danger">
                        Nationality is required
                    </div>
                </div>
                <div class="m-b-5">
                    <div class="col-md-6 noMargin" *ngIf="!playerForm.get('vparHandicap').value">
                        <label class="modal-title" for="handicap">Index</label>
                        <p-spinner class="hcp" [step]="0.5" id="handicap" formControlName="handicap"></p-spinner>
                        <div [hidden]="playerForm.get('handicap').valid ||playerForm.get('surname').disabled|| (playerForm.get('handicap').pristine && !submitted)" class="alert alert-danger">
                            Index is required
                        </div>
                    </div>
                    <div class="col-md-6 noMargin" *ngIf="playerForm.get('vparHandicap').value">
                        <label class="modal-title" for="vparHandicap">VPAR Index</label>
                        <p-spinner class="hcp" [step]="0.5" id="vparHandicap" formControlName="vparHandicap"></p-spinner>
                    </div>
                    <div class="col-md-6 noMargin">
                        <label class="modal-title" for="Gender">Gender</label>
                        <p-dropdown class="ddl-combo" [options]="genders" formControlName="gender" [autoWidth]="false"></p-dropdown>
                    </div>
                    <div [hidden]="playerForm.get('gender').valid ||playerForm.get('gender').disabled|| (playerForm.get('gender').pristine && !submitted)" class="alert alert-danger">
                        Gender is required
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-4 round-details">
                <div class="m-b-5">
                    <label for="FlightMemberType" class="modal-title">Player Type</label>
                    <p-dropdown class="ddl-combo" styleClass="dropdown" [options]="playerTypes" formControlName="flightMemberType" [autoWidth]="false"></p-dropdown>
                    <div [hidden]="playerForm.get('flightMemberType').valid ||playerForm.get('flightMemberType').disabled|| (playerForm.get('flightMemberType').pristine && !submitted)" class="alert alert-danger">
                        Type is required
                    </div>
                </div>
                <div class="m-b-5">
                    <label class="modal-title" for="startHole">Status</label>
                    <p-dropdown class="ddl-combo modal-input" [options]="flightStatus" formControlName="flightMemberStatus" [scrollHeight]="'100px'" [autoWidth]="false"></p-dropdown>
                </div>
                <div class="m-b-5" *ngIf="!newPlayer">
                    <label style="float: left" class="modal-title" pTooltip="Just enabled to check out" tooltipPosition="left" [tooltipDisabled]="playerForm.get('registered').value || playerForm.get('vparMember').value">Checked In</label>
                    <input style="float: left; clear: left; margin-top: 5px !important;" type="checkbox" class="icheckbox_square-green modal-input" formControlName="registered">
                </div>
                <div class="manualAdj" *ngIf="!newPlayer">
                    <label style="float: left" class="modal-title" tooltipPosition="left">IsManuallyAdjusted</label>
                    <input style="float: left; clear: left; margin-top: 5px !important;" type="checkbox" class="icheckbox_square-green modal-input" [checked]="isManuallyAdjusted" (change)="onAdjustedChange($event)" formControlName="isManuallyAdjusted">
                </div>
                <div class="m-b-5">
                    <label class="modal-title" for="flightHandicap">Game HCP</label>
                    <p-spinner class="hcp" [step]="handicapStep" id="flightHandicap" formControlName="flightHandicap" (onChange)="onHcpChange()"></p-spinner>
                    <!--<div [hidden]="playerForm.get('flightHandicap').valid ||playerForm.get('flightHandicap').disabled|| (playerForm.get('flightHandicap').pristine && !submitted)" class="alert alert-danger modal-input">
                        Game Handicap is required
                    </div>-->
                </div>
                <div class="m-b-5" *ngIf="shotsDisplay">
                    <label class="modal-title" for="shots">Game Shots</label>
                    <p-spinner formControlName="shots" [step]="1" id="shots" class="hcp"></p-spinner>
                    <!--<div [hidden]="playerForm.get('shots').valid ||playerForm.get('shots').disabled|| (playerForm.get('shots').pristine && !submitted)" class="alert alert-danger modal-input">
                        Game Shots is required 
                    </div>-->
                </div>
            </div>
        </div>
        <div>
            <div class="btn-section">
                <div class="btn-group m-t-10 f-right">
                    <button type="submit" class="btn btn-action-ok w-600 f-14 m-b-0 btn-save">Save</button>
                </div>
               <!-- <div class="btn-group m-t-10 f-right">
                    <a class="btn btn-action-cancel w-600 f-14 m-b-0 btn-clear" (click)="removePlayerDialog()" *ngIf="playerCreateDetail?.profileID>0">Remove Player</a>
                </div>-->

            </div>
        </div>
    </form>
</p-dialog>
<!--<confirm-dialog titleText="Remove player" message="Are you sure you want to remove this player?"></confirm-dialog>-->
