import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Profile } from "../models";
import { Observable, Subject } from "rxjs";

import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class PlayerPoolService {
  playerPool$: Observable<Profile[]>;
  private _flightMembersObserver = new Subject<Profile[]>();

  private _dataStore: {
    playerPool: Profile[];
  };

  headers: HttpHeaders = new HttpHeaders();

  constructor(
    private toastr: ToastrService,
    public httpService: HttpServices
  ) {
    this._dataStore = { playerPool: [] };
    this.playerPool$ = this._flightMembersObserver.asObservable().pipe(share());
    this.headers.append("Content-Type", "application/json; charset=utf-8");
  }

  loadAll(competitionId: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/PlayerPool`;
    this.httpService.get(url).subscribe(
      (data: Array<Profile>) => {
        this._dataStore.playerPool = data;
        this._flightMembersObserver.next(this._dataStore.playerPool);
      },
      (error) => console.log("Could not load  flight member.")
    );
  }

  loadAllOnly(competitionId: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/PlayerPoolOnly`;
    this.httpService.get(url).subscribe(
      (data: Array<Profile>) => {
        this._dataStore.playerPool = data;
        this._flightMembersObserver.next(this._dataStore.playerPool);
      },
      (error) => console.log("Could not load  flight member.")
    );
  }

  add(competitionID: string, profileID: number): Observable<Profile> {
    var self = this;
    var poolObserver = new Subject<Profile>();
    self.httpService
      .post(
        `${environment.apiUrl}/api/Competitions/${competitionID}/PlayerPool/${profileID}`,
        ""
      )
      .subscribe(
        (data: Profile) => {
          var currentIndex = _.findIndex(self._dataStore.playerPool, {
            profileID: data.profileID,
          });
          if (currentIndex != -1) {
            this._dataStore.playerPool.splice(currentIndex, 1);
          }
          if (
            _.findIndex(self._dataStore.playerPool, {
              profileID: data.profileID,
            }) == -1
          ) {
            self._dataStore.playerPool.push(data);
          }
          self._flightMembersObserver.next(self._dataStore.playerPool);
          poolObserver.next(data);
          poolObserver.complete();
          return data;
        },
        (error) => console.log("Could not add player to pool.")
      );
    return poolObserver;
  }

  remove(competitionID: string, profileID: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionID}/PlayerPool/${profileID}`;
    this.httpService.delete(url).subscribe(
      (response) => {
        this._dataStore.playerPool.forEach((t, index) => {
          if (t.profileID.toString() === profileID.toString()) {
            this._dataStore.playerPool.splice(index, 1);
            this.toastr.success("Player removed from pool");
          }
        });
        this._flightMembersObserver.next(this._dataStore.playerPool);
      },
      (error) => console.log("Could not remove player from pool.")
    );
  }
}
