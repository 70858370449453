import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Helpers } from '../../../helpers/helpers';

@Component({
    selector: 'default-leaderboardSociety',
    templateUrl: 'default-leaderboardSociety.component.html',
    styleUrls: ['./default-leaderboardSociety.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('1 => 0', animate('300ms')),
            transition('0 => 1', animate('300ms'))
        ])
    ]
})

export class DefaultLeaderboardComponentSociety implements OnInit {

    @Input() leaderboard: any;
    @Input() decimalScoring = false;

    constructor() { }

    ngOnInit() { }

    buildColumnData(column: any, player: any) {
        if (column.dataItem.indexOf('|') >= 0) {
            const values = column.dataItem.split('|');
            let result = '';
            values.forEach((value) => {
                result += (value === 'HCP') ? '(' + this.removeDecimals(player[value]) + ') ' : this.removeDecimals(player[value]) + ' ';
            });
            return result;
        } else {
            return this.removeDecimals(player[column.dataItem]);
        }
    }

    removeDecimals(value: string) {
        let result = value;
        result = Helpers.Utility.isNumber(value) ? (this.decimalScoring ? value : value.split('.')[0]) : value;
        return result;
    }  
}
