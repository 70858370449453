import { Flight } from "./flight";
import { FlightMember } from "./flightMember";
import { Profile } from "./profile";
import { Course } from "./course";
import { RoundConfiguration } from "./round.configuration";

export class Round {
  constructor() {
    this.flights = new Array<Flight>();
    this.roundConfiguration = new RoundConfiguration();
  }
  competitionID: number = 0;

  roundID: number = 0;
  title: string = "";
  //Coures
  courseID: number = null;
  course: Course;
  //Format
  gameType: number = null;
  handicapMultiplier: number = 1;
  teamHandicap: number = 100;
  //Players Per Tee Time
  flightType: number = 4;
  //Team Format
  teamType: number = 50;
  countbackType: number = 1;
  //units
  units: number = null;
  maleHoleColourID: number = null;
  femaleHoleColourID: number = null;
  //Scoring Method
  scoreBestOf: number = 99;
  //Tee Time Increment (Minutes)
  teeTimeIncrement: number = 10;
  flights: Array<Flight> = new Array<Flight>();

  flightMembers: Array<FlightMember> = new Array<FlightMember>();
  startDate: Date;
  startTime: Date;
  skinValue?: number = null;
  skinCurrencyType?: number = null;
  skinRolloverType?: number = null;
  skinWinBonus?: number = null;
  skinDrawBonus?: number = null;
  isSharedBall: boolean = false;
  pairsFormat: boolean = false;
  roundConfiguration: RoundConfiguration;

  gameTypeDescription: string;
  teamTypeDescription: string;
  isCallowayStyle: boolean = false;
  hasScores: boolean = false;
}
