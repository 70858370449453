/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tournament.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overview/tournament-overview.component.ngfactory";
import * as i3 from "./overview/tournament-overview.component";
import * as i4 from "@angular/router";
import * as i5 from "./tournament.component";
import * as i6 from "../../services/CompetitionRouteService";
var styles_TournamentComponent = [i0.styles];
var RenderType_TournamentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TournamentComponent, data: {} });
export { RenderType_TournamentComponent as RenderType_TournamentComponent };
export function View_TournamentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page-content tournament-content"], ["id", "table-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "tournament-overview-detail", [], null, null, null, i2.View_TournamentOverviewComponent_0, i2.RenderType_TournamentOverviewComponent)), i1.ɵdid(2, 114688, null, 0, i3.TournamentOverviewComponent, [i4.Router, i4.ActivatedRoute], { tournament: [0, "tournament"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tournament; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_TournamentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TournamentComponent_0, RenderType_TournamentComponent)), i1.ɵdid(1, 245760, null, 0, i5.TournamentComponent, [i4.ActivatedRoute, i6.CompetitionRouteService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TournamentComponentNgFactory = i1.ɵccf("ng-component", i5.TournamentComponent, View_TournamentComponent_Host_0, {}, {}, []);
export { TournamentComponentNgFactory as TournamentComponentNgFactory };
