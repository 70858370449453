export class TvDisplay {
    tvdisplayID: number;
    roundID: number;
    roundLeaderboardID: number;
    mediaID: number;
    tvdisplayType: number;
    timer: number;
    created: Date;
}



