import { catchError, map, share, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Round } from "../models";
import { Observable, Subject, of } from "rxjs";

import { LoginService } from "./login.service";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { CourseData } from "../models/RoundInfo";
import { MemberInfo } from "../models/flightMemberInfo";
import { FlightMemberDatas } from "../models/flightMemberDatas";
import { HttpServices } from "./HttpServices";
import { Http } from "@angular/http";
import { responseModel } from "../models/responseModel";

@Injectable()
export class RoundService {
  rounds$: Observable<Round[]>;
  private _roundsObserver = new Subject<Round[]>();

  private _dataStore: {
    rounds: Round[];
  };

  headers: HttpHeaders = new HttpHeaders();

  httpHeaders: HttpHeaders = new HttpHeaders();

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    public httpService: HttpServices,
    private http: HttpClient
  ) {
    this._dataStore = { rounds: [] };
    this.rounds$ = this._roundsObserver.asObservable().pipe(share());

    this.loginService.loggedInDetail$.subscribe((token) => {
      if (token !== "" && token !== undefined && token !== null) {
        this.httpHeaders.append("APIToken", token.AuthToken);
      }
    });
  }

  loadAll(competitionId: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/Rounds`;
    this.httpService.get(url).subscribe(
      (data: Array<Round>) => {
        this._dataStore.rounds = data;
        this._roundsObserver.next(this._dataStore.rounds);
      },
      (error) => console.log("Could not load all rounds.")
    );
  }

  load(roundID: number): void {
    this.httpService
      .get(`${environment.apiUrl}/api/Rounds/${roundID}`)
      .subscribe(
        (data: any) => {
          let notFound = true;
          this._dataStore.rounds.forEach((item, index) => {
            if (item.roundID === data.roundID) {
              this._dataStore.rounds[index] = data;
              notFound = false;
            }
          });

          if (notFound) {
            this._dataStore.rounds.push(data);
          }

          this._roundsObserver.next(this._dataStore.rounds);
        },
        (error) => console.log("Could not load round.")
      );
  }

  add(competitionID: string, round: Round): Observable<Round> {
    var roundObserver = new Subject<Round>();
    this.httpService
      .post(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Round`,
        round
      )
      .subscribe(
        (data: Round) => {
          this._dataStore.rounds.push(data);
          this._roundsObserver.next(this._dataStore.rounds);
          roundObserver.next(data);
          roundObserver.complete();
          return data;
        },
        (error) => console.log("Could not create round.")
      );
    return roundObserver;
  }

  update(
    competitionID: string,
    roundID: string,
    round: Round
  ): Observable<Round> {
    var roundObserver = new Subject<Round>();
    this.httpService
      .put(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/Flights`,
        round
      )
      .subscribe(
        (data: Round) => {
          this._dataStore.rounds.forEach((flight, i) => {
            if (round.roundID === data.roundID) {
              this._dataStore.rounds[i] = data;
            }
          });
          this._roundsObserver.next(this._dataStore.rounds);
          roundObserver.next(data);
          roundObserver.complete();
          return data;
        },
        (error) => console.log("Could not update round.")
      );
    return roundObserver;
  }

  remove(roundID: number): void {
    var url = `${environment.apiUrl}/api/Rounds/${roundID}`;
    this.httpService.delete(url).subscribe(
      (response) => {
        console.log("Deleted round " + roundID);
        this._dataStore.rounds.forEach((t, index) => {
          if (t.roundID === roundID) {
            this._dataStore.rounds.splice(index, 1);
          }
        });
        this._roundsObserver.next(this._dataStore.rounds);
      },
      (error) => console.log("Could not delete round.")
    );
  }

  rerunHandicap(competitionId: string, roundId: string): Observable<number> {
    var handicapObservable = new Subject<number>();

    var environment = environment.apiVpar;
    var url = `https:\\\\${environment}/v1/round/recalculatehandicaps?CompetitionID=${competitionId}&RoundID=${roundId}&RecalculateResults=true`;

    this.httpService.put(url, null).subscribe(
      (data: number) => {
        handicapObservable.next(data);
      },
      (err) => console.log(err)
    );
    return handicapObservable;
  }

  recalculateHandicaps(
    roundId: number,
    competitionId: number
  ): Observable<boolean> {
    let url = `${environment.apiUrl}/api/Round/${roundId}/Competition/${competitionId}/recalculate-handicaps`;
    var refreshAll = new Subject<boolean>();

    this.httpService.put(url, null).subscribe(
      (data: Array<boolean>) => {
        refreshAll.complete();
        this.toastr.success("All handicaps recalculated");
      },
      (error) => {
        this.toastr.error("Could recalculate handicaps");
      }
    );
    return refreshAll;
  }
  calculateHandicap(roundId: number): Observable<CourseData> {
    let url = `${environment.apiUrl}/api/Courses/info-by-round/${roundId}`;
    let info = new Subject<CourseData>();
    this.httpService.get(url).subscribe((data: any) => {
      info.next(data);
    });
    return info;
  }
  getFlightMembers(roundId: number): Observable<MemberInfo[]> {
    let url = `${environment.apiUrl}/api/flight-members/for-handicap-table/${roundId}`;
    let info = new Subject<MemberInfo[]>();
    this.httpService.get(url).subscribe((data: any) => {
      info.next(data);
    });
    return info.asObservable();
  }
  updateFlightMemberInfo(FlightMemberDatas: FlightMemberDatas[]) {
    const count = FlightMemberDatas.length;
    const message = `${count} handicaps updated`;
    let url = `${environment.apiUrl}/api/flight-members/data`;
    this.httpService.put(url, { FlightMemberDatas }).subscribe((data) => {
      this.toastr.success(message);
    });
  }

  uploadPlayers(event: any, roundId: number): Promise<responseModel> {
    return new Promise<responseModel>((resolve, reject) => {
      let response = new responseModel();
      const file = event.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const apiUrl = `${environment.competitionApiUrl}/api/5.0/rounds/import-startsheet/${roundId}`;

      this.loginService.loggedInDetail$.subscribe((token) => {
        if (!token || !token.AuthToken) {
          console.error("Invalid token");
          reject(false);
          return;
        }

        let headers = new HttpHeaders().set("APIToken", token.AuthToken);
        const options = {
          headers: headers,
          responseType: "text" as "json",
        };

        this.http.post(apiUrl, formData, options).subscribe(
          () => {
            response.success = true;
            resolve(response);
          },
          (error) => {
            if (error.status === 400) {
              response.success = false;
              response.status = error.status;
              let errors = JSON.parse(error.error);
              let errArray = [];
              errors.forEach((e) => {
                errArray.push(
                  `Row number : ${e.rowNumber} , detail : ${e.details}`
                );
              });
              response.errors = errArray;
              resolve(response);
            } else reject(error.error);
          }
        );
      });
    });
  }

  hasScores(roundId: number): Observable<boolean> {
    let url = `${environment.competitionApiUrl}/api/5.0/rounds/get-meta-data-tms/${roundId}`;
    return this.loginService.loggedInDetail$.pipe(
      switchMap((token) => {
        if (token !== "" && token !== undefined && token !== null) {
          return this.httpService
            .get(url, [{ name: "APIToken", value: token.AuthToken }])
            .pipe(
              map((data: any) => {
                return data.hasAnyScores;
              }),
              catchError((error) => {
                console.error("Error fetching meta data:", error);
                return of(false); // Return a default value in case of error
              })
            );
        } else {
          return of(false); // Return a default value if token is not valid
        }
      })
    );
  }

  recalculateHandicap(roundId: number) {
    return new Promise<responseModel>((resolve, reject) => {
      let response = new responseModel();

      const apiUrl = `${environment.competitionApiUrl}/api/5.0/rounds/recalculate-handicap/${roundId}`;

      this.loginService.loggedInDetail$.subscribe((token) => {
        if (!token || !token.AuthToken) {
          console.error("Invalid token");
          reject(false);
          return;
        }

        let headers = new HttpHeaders().set("APIToken", token.AuthToken);
        const options = {
          headers: headers,
          responseType: "text" as "json",
        };

        this.http.put(apiUrl, null, options).subscribe(
          () => {
            response.success = true;
            resolve(response);
          },
          (error) => {
            response.success = false;
            resolve(error);
          }
        );
      });
    });
  }
}
