<div class="left-team" *ngIf="gameType === 2" [style.background-color]="'#' + letfTeamColor">&nbsp;</div>
<div class="right-team" *ngIf="gameType === 2" [style.background-color]="'#' + rightTeamColor">&nbsp;</div>
<div class="tee-header">
    <div class="left side" [ngClass]="{'pairs': vsTeamFormat}" *ngIf="leftSideText !== ''">{{leftSideText}}</div>
    <div class="center" [ngClass]="{'pairs': vsTeamFormat}" *ngIf="centerText !== ''">{{centerText}}</div>
    <div class="right side" [ngClass]="{'fourball m-l-20' : bigTeamFormat, 'pairs': vsTeamFormat}" *ngIf="rightSideText !== ''">{{rightSideText}}</div>
</div>
<div class="tee-players">
    <div class="tee-row">
        <div class="player left">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[0]?.status === 30, 'fa-circle disabled': teeTime?.players[0]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[0]?.profile)" />
                <span *ngIf="teeTime?.players[0]">{{teeTime?.players[0]?.profile.displayName}} ({{teeTime?.players[0]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[0]">-</span>
            </div>
        </div>
        <div class="player" *ngIf="gameType === 2 && this.teamType <= 20">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[1]?.status === 30, 'fa-circle disabled': teeTime?.players[1]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[1]?.profile)" />
                <span *ngIf="teeTime?.players[1]">{{teeTime?.players[1]?.profile.displayName}} ({{teeTime?.players[1]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[1]">-</span>
            </div>
        </div>
        <div class="player" *ngIf="gameType !== 2 || (gameType === 2 && this.teamType > 20)">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[2]?.status === 30, 'fa-circle disabled': teeTime?.players[2]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[2]?.profile)" />
                <span *ngIf="teeTime?.players[2]">{{teeTime?.players[2]?.profile.displayName}} ({{teeTime?.players[2]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[2]">-</span>
            </div>
        </div>
    </div>
    <div class="tee-row">
        <div class="player left" *ngIf="gameType !== 2 || (gameType === 2 && this.teamType > 20)">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[1]?.status === 30, 'fa-circle disabled': teeTime?.players[1]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[1]?.profile)" />
                <span *ngIf="teeTime?.players[1]">{{teeTime?.players[1]?.profile.displayName}} ({{teeTime?.players[1]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[1]">-</span>
            </div>
        </div>
        <div class="player left" *ngIf="gameType === 2 && this.teamType <= 20">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[2]?.status === 30, 'fa-circle disabled': teeTime?.players[2]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[2]?.profile)" />
                <span *ngIf="teeTime?.players[2]">{{teeTime?.players[2]?.profile.displayName}} ({{teeTime?.players[2]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[2]">-</span>
            </div>
        </div>
        <div class="player">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[3]?.status === 30, 'fa-circle disabled': teeTime?.players[3]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[3]?.profile)" />
                <span *ngIf="teeTime?.players[3]">{{teeTime?.players[3]?.profile.displayName}} ({{teeTime?.players[3]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[3]">-</span>
            </div>
        </div>
    </div>
    <div class="tee-row" *ngIf="teeTime?.players[4]">
        <div class="player left">
            <div class="arrived">
                <i class="fa " [ngClass]="{'fa-check-circle': teeTime?.players[4]?.status === 30, 'fa-circle disabled': teeTime?.players[4]?.status !== 30}"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(teeTime?.players[4]?.profile)" />
                <span *ngIf="teeTime?.players[4]">{{teeTime?.players[4]?.profile.displayName}} ({{teeTime?.players[4]?.handicap}})</span>
                <span *ngIf="!teeTime?.players[4]">-</span>
            </div>
        </div>
        <div class="player" style="visibility:hidden">
            <div class="arrived">
                <i class="fa fa-circle disabled"></i>
            </div>
            <div class="info">
                <img class="profilePicture" [src]="showProfilePicture(0)" />
                <span>-</span>
            </div>
        </div>
    </div>
</div>