/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./publicroute.components";
var styles_PublicRouteComponent = ["body[_ngcontent-%COMP%] { overflow: hidden; }"];
var RenderType_PublicRouteComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PublicRouteComponent, data: {} });
export { RenderType_PublicRouteComponent as RenderType_PublicRouteComponent };
export function View_PublicRouteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PublicRouteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "publicmainapp", [], null, null, null, View_PublicRouteComponent_0, RenderType_PublicRouteComponent)), i0.ɵdid(1, 49152, null, 0, i2.PublicRouteComponent, [], null, null)], null, null); }
var PublicRouteComponentNgFactory = i0.ɵccf("publicmainapp", i2.PublicRouteComponent, View_PublicRouteComponent_Host_0, {}, {}, []);
export { PublicRouteComponentNgFactory as PublicRouteComponentNgFactory };
