import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightService, FlightMembersService, TeamsService, CourseService, ProfileService } from '../../../services';
import { FlightMember, Team, PlayerCreateDetail, Profile, Round, Competition, Flight } from '../../../models';
import { combineLatest,  Subject } from 'rxjs';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { SelectItem } from 'primeng/primeng';
import { CourseData,TeeData } from 'src/app/models/RoundInfo';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { RoundService } from 'src/app/services';

@Component({
    selector: 'player-list',
    templateUrl: 'tournament-overview-playerlist.component.html',
    styleUrls: ['./tournament-overview-playerlist.component.scss']
})

export class TournamentOverviewPlayerlistComponent implements OnInit, OnDestroy {
    public LogoURL: string;
    public flightMembers: Array<FlightMember>
    public FlightMemberId: any;
    public Status: number;
    public tournamentId: string;
    public roundId: string;
    public currentRound: Round;
    public playerArrived: boolean;
    public teamDisplay: boolean = true;
    public shotsDisplay: boolean;
    public displayPlayerDialog: boolean;
    public tournamentOpen: boolean = true;
    public emptyMessage: string = "Loading...";
    public selectedPlayer: PlayerCreateDetail;
    public $stop: Subject<boolean> = new Subject<any>();
    public decimalScoring: boolean = false;
    holes: SelectItem[] = [];
    flights: Flight[] = new Array<Flight>();
    Allowance:number;
    SlopeRating: number;
    FemaleSlopeRating: number;
    FemalePar: number;
    MalePar: number;
    FemalecourseRating: number;
    MalecourseRating: number;
    CourseRating: number;
    Par: number;
    ProfileIndex: number = 0;
    CourseHCP:number;
    GameHCP: number;
    windowSize = 0;
    SR: number;
    FSR: number;
    hidePublicCalculator: boolean = false;
    dynamicStyle: { [key: string]: string } = {};

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.windowSize = window.innerWidth || document.body.clientWidth;
    }

    constructor(private route: ActivatedRoute,
        private flightMembersService: FlightMembersService,
        public flightService: FlightService,
        private courseService: CourseService,
        private teamsService: TeamsService,
        private profileService: ProfileService,
        private roundService:RoundService,
        private router: Router
        ) {
    }

    ngOnInit() {
      if(this.router.url.includes('public')){
        this.hidePublicCalculator = true;
        this.dynamicStyle = { 'width': ''}
      }else{
        this.dynamicStyle = {'width':""}
      }
        this.windowSize = window.innerWidth || document.body.clientWidth;
        var self = this;
        this.flightService.flights$
            .pipe(takeUntil(this.$stop))
            .subscribe((flights: Flight[]) => {
                self.flights = flights;
            });

        this.courseService.course$.pipe(takeUntil(this.$stop)).subscribe(courses => {
            let currentCourse = _.find(courses, { 'courseID': this.currentRound.courseID });
            this.holes = DomainHelpers.SelectLists.getHoleDetails(currentCourse);
            });

        combineLatest(this.teamsService.teams$, this.flightMembersService.flightMembers$,
            function (teams: Array<Team>, flightMembers: Array<FlightMember>) {
                flightMembers.forEach(flightMember => {
                    var team = _.find(teams, { 'teamID': flightMember.teamID });
                    if (team == null) {
                        team = new Team();
                        team.title = "None";
                    }
                    flightMember.team = team;
                    if (flightMember.flightMemberType) {
                        flightMember.flightMemberTypeText = DomainHelpers.SelectLists.getPlayerTypeText(flightMember.flightMemberType.toString());
                    }
                    flightMember.registered = flightMember.profile.competitionProfileDetails.length > 0 && flightMember.profile.competitionProfileDetails[0].status == 1;

                });
                return flightMembers;
            })
            .pipe(takeUntil(this.$stop))
            .subscribe((data) => {
                this.flightMembers = data.sort((n1,n2) => {
                    return n1.flight.teeOffTime > n2.flight.teeOffTime ? 1 : (n1.flight.teeOffTime === n2.flight.teeOffTime ? (n1.flight.startHole > n2.flight.startHole ? 1 : (n1.flight.startHole === n2.flight.startHole ? (n1.ball > n2.ball ? 1 : -1) : -1)) : -1);
                 });
                this.emptyMessage = "No players found";
            });

        this.route.parent.parent.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var tournament = <Competition>data['tournament'];
            if (tournament) {
                this.decimalScoring = tournament.useDecimalScoring;
                this.tournamentOpen = (tournament.status != 3);
            }
            this.teamsService.loadAll(tournament.competitionID.toString());
        });

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            this.currentRound = <Round>data['round'];
            this.Allowance = this.currentRound.handicapMultiplier * 100;
            this.roundId = this.currentRound.roundID.toString();
            this.tournamentId = this.currentRound.competitionID.toString();
            this.teamDisplay = DomainHelpers.RoundHelper.getTeamDisplay(this.currentRound);
            this.shotsDisplay = DomainHelpers.RoundHelper.shotsDisplay(this.currentRound);
            this.flightMembersService.loadAll(this.tournamentId, this.roundId);
            this.courseService.load(this.currentRound.courseID);
            this.flightService.loadAll(this.tournamentId, this.roundId);
            if(this.currentRound.isSharedBall || this.currentRound.gameType == 2)            
                this.hidePublicCalculator = true;
            
            this.roundService.calculateHandicap(Number(this.roundId))
                .subscribe((data:CourseData) => {
                this.SlopeRating = data.maleTeeData.slopeRating;
                this.FemaleSlopeRating = data.femaleTeeData.slopeRating;
                this.SR = data.maleTeeData.slopeRating;
                this.FSR = data.femaleTeeData.slopeRating;
                this.FemalePar = data.femaleTeeData.par;
                this.MalePar = data.maleTeeData.par;
                this.Par = data.maleTeeData.par;
                this.CourseRating = data.maleTeeData.courseRating;
                this.MalecourseRating = data.maleTeeData.courseRating;
                this.FemalecourseRating = data.femaleTeeData.courseRating;
                })
        });
    }

    isPublic():boolean{
      return this.router.url.includes("public");
    }
    onChange(event) {
        this.playerArrived = event.currentTarget.checked;
        this.FlightMemberId = event.target.value;
        if (this.playerArrived) {
            this.setPlayerArrived(this.FlightMemberId, this.Status = 30);
        } else {
            this.setPlayerArrived(this.FlightMemberId, this.Status = 100);
        }
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }

    setPlayerArrived(flightMemberID: any, Status: number): void {
        this.flightMembersService.setArrived(flightMemberID, Status);
    }

    convertProfileToPlayerCreateDetail(profile: Profile, flightMember?: FlightMember, flight?: Flight, team?: Team): PlayerCreateDetail {
        var playerCreateDetail: PlayerCreateDetail = jQuery.extend(new PlayerCreateDetail(), profile);
        if (flightMember != null && flight != null) {
            playerCreateDetail.teeOffTime = this.getTime(flight.teeOffTime);
            playerCreateDetail.startHole = flight.startHole;
            playerCreateDetail.flightID = flight.flightID;
            playerCreateDetail.roundID = flight.roundID;
            playerCreateDetail.handicap = profile.handicap;
            playerCreateDetail.flightMemberID = flightMember.flightMemberID;
            playerCreateDetail.flightMemberType = flightMember.flightMemberType;
            playerCreateDetail.flightMemberStatus = flightMember.status;
            playerCreateDetail.ball = flightMember.ball;
            playerCreateDetail.flightHandicap = flightMember.handicap;
            playerCreateDetail.shots = flightMember.shots;
            playerCreateDetail.vparHandicap = flightMember.vparHandicap;
        }
        if (team) {
            playerCreateDetail.teamTitle = team.title;
        }

        playerCreateDetail.handicap = Math.round(playerCreateDetail.handicap * 10) / 10;

        playerCreateDetail.registered = flightMember.registered;

        return playerCreateDetail;
    }


    onRowSelect(event) {
        var flightMember = <FlightMember>event.data;
        var selectedProfile = <Profile>event.data.profile;
        var flight = <Flight>event.data.flight;
        var team = <Team>event.data.team;
        this.selectedPlayer = this.convertProfileToPlayerCreateDetail(_.clone(selectedProfile), flightMember, _.clone(flight), _.clone(team));
        this.displayPlayerDialog = true;
    }

    calculateHandicapIndex() {
        const rawCourseHandicap = this.ProfileIndex * (this.SlopeRating / 113) + (this.CourseRating - this.Par);
        const courseHandicap = parseFloat(rawCourseHandicap.toFixed(2));
        this.CourseHCP =  courseHandicap;
        this.GameHCP = Math.round(this.CourseHCP * (this.Allowance /100));
      }

    ngOnDestroy() {
        //Cleanup using takeUntil
        this.$stop.next(true);
    }

    showProfilePicture(profile: Profile): string {
        return this.profileService.showProfilePicture(profile);
    }
}
