import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
  } from "@angular/core";
  import { MenuItem } from "primeng/primeng";
  import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
  import { LoginService, BrandingService } from "../../../services";
  import { IBranding } from "../../../models";
  import { checkFeatureDisabled } from "../../../shared/utils/index";
  import { filter } from "rxjs/operators";

@Component({
    selector: 'round-overview-menu',
    templateUrl: 'round-overview-menu.component.html'
})
export class RoundOverviewMenuComponent implements OnInit, OnChanges {

    private _roundID: string;
    @Input() set roundId(value: string) {
        this._roundID = value;
        this.ngOnInit();  // Ensure ngOnInit is called whenever roundId changes
    }

    @Input() offeringLevel: number;

    tabItems: MenuItem[];
    activeRound: MenuItem;
    selectedIndex: number = 0;
    admimToolAllowed = false;
    userIsLoggedIn = false;
    private result: IBranding;

    constructor(private activatedRoute: ActivatedRoute, private router: Router,
        private loginService: LoginService, private brandingService: BrandingService) {
        this.userIsLoggedIn = loginService.isLoggedIn();
        // this.router.events.pipe(
        //     filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
        //   ).subscribe((event: any) => {
        //     if (event instanceof NavigationEnd) {
        //       if(event.urlAfterRedirects.includes("players")){
        //         this.selectedIndex = 0;
        //         this.activeRound = this.tabItems[0];
        //       }
        //     }
        //   });
    }

    ngOnInit() {
        this.buildMenuItems();
        const branding = this.activatedRoute.parent.snapshot.data['branding'];

        if (this.userIsLoggedIn) {
            this.admimToolAllowed = branding.adminTools;
        }

        const url = this.router.url;
        if (url.indexOf('/') >= 0) {
            const options = url.split('/');
            switch (options[options.length - 1]) {
                case 'players':
                    this.setMenuOption(0);
                    break;
                case 'teetimes':
                    this.setMenuOption(1);
                    break;
                case 'scores':
                    this.setMenuOption(2);
                    break;
                case 'leaderboard':
                    this.setMenuOption(3);
                    break;
                case 'printing':
                    this.setMenuOption(4);
                    break;
                case 'stats':
                    this.setMenuOption(5);
                    break;
                case 'admin':
                    this.setMenuOption(6);
                    break;
                case 'tracking':
                    this.setMenuOption(7);
                    break;
                case 'handicap':
                    this.setMenuOption(8);
                    break;
            }
        }
        if (this.router.url.includes("public")) {
            var labelsToRemove = ['Admin', 'Handicap', 'Printing', 'Tracking'];
            var filteredMenuItems = this.tabItems.filter(item => !labelsToRemove.includes(item.label));
            this.tabItems = filteredMenuItems;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['roundId']) {
            this.ngOnInit();  // Call ngOnInit to rebuild the menu items when roundId changes
        }
    }

    private buildMenuItems() {
        this.tabItems = [
            { label: 'Players List', routerLink: 'round/' + this._roundID + '/players', visible: true, command: (event) => { this.setMenuOption(0); } },
            { label: 'Tee Times', routerLink: 'round/' + this._roundID + '/teetimes', visible: true, command: (event) => { this.setMenuOption(1); } },
            { label: 'Scores', routerLink: 'round/' + this._roundID + '/scores', visible: true, command: (event) => { this.setMenuOption(2); } },
            { label: 'Leaderboard', routerLink: 'round/' + this._roundID + '/leaderboard', visible: true, command: (event) => { this.setMenuOption(3); } },
            { label: 'Printing', routerLink: 'round/' + this._roundID + '/printing', visible: !this.disabledFeature("printing", this.offeringLevel) && this.userIsLoggedIn, command: (event) => { this.setMenuOption(4); } },
            { label: 'Stats', routerLink: 'round/' + this._roundID + '/stats', visible: true, command: (event) => { this.setMenuOption(5); } },
            { label: 'Admin', routerLink: 'round/' + this._roundID + '/admin', visible: this.admimToolAllowed, command: (event) => { this.setMenuOption(6); } },
            { label: 'Tracking', routerLink: 'round/' + this._roundID + '/tracking', visible: !this.disabledFeature("tracking", this.offeringLevel) && this.userIsLoggedIn, command: (event) => { this.setMenuOption(7); } },
            { label: 'Handicap', routerLink: 'round/' + this._roundID + '/handicap', visible: this.userIsLoggedIn, command: (event) => { this.setMenuOption(8); } }
        ];
    }

    private setMenuOption(selectedIndex: number) {
        this.selectedIndex = selectedIndex;
        this.activeRound = this.tabItems[selectedIndex];
    }

    private disabledFeature(toggleFeature: string, toggleFeatureOfferingLevel: number) {
        return checkFeatureDisabled(toggleFeature, toggleFeatureOfferingLevel);
    }
}
