<div class="row">
    <div class="col-xs-12 border m-b-15 team">
        <div class="col-xs-12 tall center cursor-pointer" (click)="toggle()">
            <i class="fa fa-chevron-circle-right vparorange" aria-hidden="true" *ngIf="!open"></i>
            <i class="fa fa-chevron-circle-down vparorange" aria-hidden="true" *ngIf="open"></i>
            <strong>{{team.title}}</strong>
        </div>
        <div class="teamHolder">
            <scorecard [roundID]="roundID" [teamType]="teamType" [teamID]="team.teamID" [tournamentOpen]="tournamentOpen" [courseData]="courseData" [maleTeeColor]="maleTeeColor" [femaleTeeColor]="femaleTeeColor" (onEditingChange)="editScorecardChanged($event)"></scorecard>
        </div>
    </div>
</div>