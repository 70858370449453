/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tournament-overview-leaderboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory";
import * as i3 from "primeng/components/progressspinner/progressspinner";
import * as i4 from "../tv-leaderboard/default-leaderboard.component.ngfactory";
import * as i5 from "../tv-leaderboard/default-leaderboard.component";
import * as i6 from "../tv-leaderboard/matchplay-leaderboard.component.ngfactory";
import * as i7 from "../tv-leaderboard/matchplay-leaderboard.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../shared/components/leaderboard-menu/leaderboard-menu.component.ngfactory";
import * as i10 from "primeng/components/dom/domhandler";
import * as i11 from "../../../shared/components/leaderboard-menu/leaderboard-menu.component";
import * as i12 from "@angular/router";
import * as i13 from "@angular/cdk/layout";
import * as i14 from "./tournament-overview-leaderboard.component";
import * as i15 from "../../../services/tv-feed.service";
var styles_TournamentOverviewLeaderboardComponent = [i0.styles];
var RenderType_TournamentOverviewLeaderboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TournamentOverviewLeaderboardComponent, data: {} });
export { RenderType_TournamentOverviewLeaderboardComponent as RenderType_TournamentOverviewLeaderboardComponent };
function View_TournamentOverviewLeaderboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "lastUpdated"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["last updated: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lastUpdateDate; _ck(_v, 1, 0, currVal_0); }); }
function View_TournamentOverviewLeaderboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p-progressSpinner", [], null, null, null, i2.View_ProgressSpinner_0, i2.RenderType_ProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i3.ProgressSpinner, [], null, null)], null, null); }
function View_TournamentOverviewLeaderboardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "default-leaderboard", [["style", "flex:1; display: flex;flex-direction: column"]], null, null, null, i4.View_DefaultLeaderboardComponent_0, i4.RenderType_DefaultLeaderboardComponent)), i1.ɵdid(1, 114688, null, 0, i5.DefaultLeaderboardComponent, [], { leaderboard: [0, "leaderboard"], decimalScoring: [1, "decimalScoring"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.parent.context.$implicit; var currVal_1 = _co.roundIsDecimalScoring; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TournamentOverviewLeaderboardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "matchplay-leaderboard", [["style", "flex: 1;display: flex;flex-direction: column;"]], null, null, null, i6.View_MatchplayLeaderboardComponent_0, i6.RenderType_MatchplayLeaderboardComponent)), i1.ɵdid(1, 114688, null, 0, i7.MatchplayLeaderboardComponent, [], { matchplayLeaderboard: [0, "matchplayLeaderboard"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TournamentOverviewLeaderboardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "leaderboard-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_6)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_7)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit.dataLoaded && (_v.parent.parent.context.$implicit.config.columns.length > 0)) && (_v.parent.parent.context.$implicit.screenPlayers.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.parent.parent.context.$implicit.dataLoaded && (_v.parent.parent.context.$implicit.config.columns.length === 0)) && (_v.parent.parent.context.$implicit.screenPlayers.length > 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TournamentOverviewLeaderboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_4)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_5)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.dataLoaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.dataLoaded; _ck(_v, 4, 0, currVal_1); }, null); }
function View_TournamentOverviewLeaderboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.title === _co.activeTab.label); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TournamentOverviewLeaderboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "panel-content tournament-leaderboard-container"], ["style", "min-height:128px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "menu-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "leaderboard-menu", [["styleClass", "media-manage-menu"]], null, null, null, i9.View_LeaderboardTabMenu_0, i9.RenderType_LeaderboardTabMenu)), i1.ɵprd(4608, null, i10.DomHandler, i10.DomHandler, []), i1.ɵdid(9, 638976, null, 0, i11.LeaderboardTabMenu, [i12.Router, i13.BreakpointObserver], { model: [0, "model"], activeItem: [1, "activeItem"], styleClass: [2, "styleClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewLeaderboardComponent_2)), i1.ɵdid(11, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.lastUpdateDate !== ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.tabItems; var currVal_2 = _co.activeTab; var currVal_3 = "media-manage-menu"; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.pages; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_TournamentOverviewLeaderboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leaderboard", [], null, null, null, View_TournamentOverviewLeaderboardComponent_0, RenderType_TournamentOverviewLeaderboardComponent)), i1.ɵdid(1, 245760, null, 0, i14.TournamentOverviewLeaderboardComponent, [i12.ActivatedRoute, i15.TvFeedService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TournamentOverviewLeaderboardComponentNgFactory = i1.ɵccf("leaderboard", i14.TournamentOverviewLeaderboardComponent, View_TournamentOverviewLeaderboardComponent_Host_0, {}, {}, []);
export { TournamentOverviewLeaderboardComponentNgFactory as TournamentOverviewLeaderboardComponentNgFactory };
