export module ScoresHelpers {
  export class Calculations {
    static getShotsOnHole(
      holeCount: number,
      SI: number,
      handicap: number
    ): number {
      const handicapRound: number =
        handicap > 0 ? Math.ceil(handicap) : Math.floor(handicap);
      const SINormalised: number =
        SI > holeCount && holeCount > 9 ? SI - holeCount : SI;
      return (
        Math.trunc(handicap / holeCount) +
        (handicap % holeCount >= SINormalised
          ? 1
          : handicapRound % holeCount < SINormalised - holeCount
          ? -1
          : 0) +
        (handicap > 0
          ? Math.abs(handicapRound) % holeCount === SINormalised ||
            (handicapRound === 18 &&
              SI === 18 &&
              Math.abs(handicapRound) % holeCount === 0)
            ? handicap - Math.floor(handicap)
            : 0
          : holeCount - (Math.abs(handicapRound) % holeCount) + 1 ===
            SINormalised
          ? handicap - Math.floor(handicap)
          : 0)
      );
    }

    static calcAdjustedGross(
      par: number,
      shots: number,
      grossScore: number
    ): number {
      const maxGross = par + shots + 2;
      return maxGross < grossScore ? maxGross : grossScore;
    }

    static calculateNetDoubleBogey(
      holePar: number,
      shotAllowance: number
    ): number {
      return holePar + shotAllowance + 2;
    }

    static calculatePoints(
      gross: number,
      holePar: number,
      shotAllowance: number
    ): number {
      let netPar = holePar + shotAllowance;
      return gross - netPar >= 2 ? 0 : netPar - gross + 2;
    }
  }
}
