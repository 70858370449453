import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FlightMember, PlayerCreateDetail } from '../../../models';
import { FlightMemberService, FlightMembersService } from '../../../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'player-pool-renderer',
    templateUrl: 'poolplayer.renderer.component.html',
    styleUrls: ['poolplayer.renderer.component.scss']
})

export class PoolplayerRenderer implements OnDestroy, OnInit {
    @Input() profile: PlayerCreateDetail;
    @Output() dragPlayerEnd = new EventEmitter();

    //Toggles visibility we are not using *ngIf as events are in progress relating to dom element and it causes dehydrated exception
    //Therefore we can only hide the element not remove it from the dom. We also needed a hidden style to ensure that it vanishes.
    visible: boolean = true;
    $stop: Subject<boolean> = new Subject<any>();
    constructor(public flightMemberService: FlightMemberService, public flightMembersService: FlightMembersService) {
    }

    ngOnInit() {
        this.flightMembersService.flightMembers$.pipe(takeUntil(this.$stop)).subscribe((flightMembers: Array<FlightMember>) => {
            var index = _.findIndex(flightMembers, { 'profileID': this.profile.profileID });
            this.visible = index === -1;
        });
    }

    dragStart(event, profile: PlayerCreateDetail) {
        var jsonProfile = JSON.stringify(profile);
        event.dataTransfer.setData("profile", jsonProfile);
    }

    dropOnExistingPlayer($event) {
        var flightMember = $event.dataTransfer.getData("flightmember");
        if (flightMember) {
            this.flightMemberService.DropFlightMemberOnExistingPoolPlayer(this.profile, JSON.parse(flightMember));
        }
    }

    dragEnd(event) {
        event.dataTransfer.clearData();
        this.dragPlayerEnd.emit();
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
}
