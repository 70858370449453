import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FlightMembersService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { FlightMember, ArrivalsMedia } from '../../../models';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'tv-arrivals',
    templateUrl: 'tv-arrivals.component.html',
    styleUrls: ['./tv-arrivals.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('1 => 0', animate('300ms')),
            transition('0 => 1', animate('300ms'))
        ])
    ]
})

export class TVArrivalComponent implements OnInit {

    playerShow = false;

    tournamentID: string;
    roundID: string;
    dashboardLogoUrl: string;
    backgroundImage: string;

    arrivalsMedia: ArrivalsMedia = new ArrivalsMedia();

    players: FlightMember[];
    screenPlayers: FlightMember[];
    playerIndex = 0;
    toggleFlip = true;

    teeTimesAll: any[];
    teeTimes: any[] = [];
    teeTimesIndex = 0;

    gameType = 0;
    teamType = 0;
    isFiveBall = false;

    dataLoaded = false;

    offeringLevel: number;

    public environment = environment;

    constructor(private flightMembersService: FlightMembersService,
        private route: ActivatedRoute) {

        window.setInterval(() => this.flipTeeTimes(), 20000);
        window.setInterval(() => this.loadData(), 30000);
        window.setInterval(() => this.paginatePlayers(), 20000);
    }

    ngOnInit() {

        this.getBranding();

        this.route.params.subscribe(params => {
            this.tournamentID = params['tournamentId'];
            this.roundID = params['roundId'];

            this.flightMembersService.getArrivals(this.tournamentID, this.roundID).subscribe(res => {
                this.gameType = res.gameType;
                this.teamType = res.teamType;

                this.isFiveBall = res.flightType === 5;

                this.players = _.sortBy(res.flightMembers, 'profile.displayName');

                this.playerIndex = 0;
                this.paginatePlayers();

                // build tee times list
                this.buildTeeTimes(res.flightMembers);

                this.teeTimesIndex = 0;
                // front cards data
                this.setTeeTimes(true);
                // back cards data
                this.setTeeTimes(false);

                this.dataLoaded = true;
            });

            this.flightMembersService.getArrivalsMedia(this.tournamentID, this.roundID).subscribe(res => {
                this.arrivalsMedia = res;
                this.imageExists(this.arrivalsMedia.backgroundImg, 'backgroundImg');
                this.imageExists(this.arrivalsMedia.mediaLeft, 'mediaLeft');
                this.imageExists(this.arrivalsMedia.mediaRight, 'mediaRight');
                this.LoadBackgroundImage();
            });
        });
    }

    private paginatePlayers() {
        let loop = this.playerIndex;
        this.playerShow = false;
        setTimeout(() => {
            this.screenPlayers = [];
            for (let i = loop; i < this.players.length && i < (loop + 10); i++) {
                this.screenPlayers.push(this.players[i]);
                this.playerIndex++;
                if (this.playerIndex >= this.players.length) {
                    this.playerIndex = 0;
                }
            }
            this.playerShow = true;
        }, 300);
    }

    private loadData() {
        this.flightMembersService.getArrivals(this.tournamentID, this.roundID).subscribe(res => {
            this.players = _.sortBy(res.flightMembers, 'profile.displayName');
            // build tee times list
            this.buildTeeTimes(res.flightMembers);
        });
    }

    private buildTeeTimes(arrivalsData: any) {
        this.teeTimesAll = _.chain(arrivalsData)
            .groupBy("flight.flightID")
            .pairs()
            .map(function (currentItem) {
                return _.object(_.zip(["tee", "players"], currentItem));
            })
            .value();
    }

    private getBranding() {
        var branding = this.route.snapshot.data['branding'];
        this.dashboardLogoUrl = branding.logoURL;
        this.offeringLevel = branding.offeringType;
    }

    /**
     * Checks if an image exists and in afirmative case, then set the value in arrivals media
     * @param image_url 
     * @param property 
     */
    private imageExists(image_url, property) {
        const self = this;

        if (image_url !== null && image_url !== undefined) {
            var image = new Image();

            image.onload = function () {
                self.arrivalsMedia[property] = "https://" + environment.cdnUrl + image_url;
            }
            image.onerror = function () {
                self.arrivalsMedia[property] = null;
                if (property !== 'backgroundImg') {
                    self.arrivalsMedia[property] = self.dashboardLogoUrl;
                }
            }

            image.src = "https://" + environment.cdnUrl + image_url;
        } else {
            if (property !== 'backgroundImg' && self.dashboardLogoUrl && self.dashboardLogoUrl !== null && self.dashboardLogoUrl !== '') {
                self.arrivalsMedia[property] = self.dashboardLogoUrl;
            }
        }
    }

    private flipTeeTimes() {
        if (this.checkLoopTeeTimes()) {
            if (this.toggleFlip) {
                $('.tee').addClass('flip');
                this.setTeeTimes(true);
            } else {
                $('.tee').removeClass('flip');
                this.setTeeTimes(false);
            }

            this.toggleFlip = !this.toggleFlip;
        }
    }

    /**
     * Updates the tee times cards to rotate values
     * @param isFrontFace 
     */
    private setTeeTimes(isFrontFace: boolean) {

        // if front face update first 3 tee times cards
        // if back face update last 3 tee times cards
        const teeIndex = isFrontFace ? 0 : (this.isFiveBall ? 3 : 4);

        setTimeout(() => {
            if (this.teeTimesIndex < this.teeTimesAll.length) {
                this.teeTimes[teeIndex] = this.teeTimesAll[this.teeTimesIndex];
                this.teeTimesIndex++;
            } else if (this.checkLoopTeeTimes()) {
                this.teeTimes[teeIndex] = this.teeTimesAll[0];
                this.teeTimesIndex = 1;
            }
            if (this.teeTimesIndex < this.teeTimesAll.length) {
                this.teeTimes[teeIndex + 1] = this.teeTimesAll[this.teeTimesIndex];
                this.teeTimesIndex++;
            } else if (this.checkLoopTeeTimes()) {
                this.teeTimes[teeIndex + 1] = this.teeTimesAll[0];
                this.teeTimesIndex = 1;
            }
            if (this.teeTimesIndex < this.teeTimesAll.length) {
                this.teeTimes[teeIndex + 2] = this.teeTimesAll[this.teeTimesIndex];
                this.teeTimesIndex++;
            } else if (this.checkLoopTeeTimes()) {
                this.teeTimes[teeIndex + 2] = this.teeTimesAll[0];
                this.teeTimesIndex = 1;
            }
            if (!this.isFiveBall) {

                if (this.teeTimesIndex < this.teeTimesAll.length)
                {
                    this.teeTimes[teeIndex + 3] = this.teeTimesAll[this.teeTimesIndex];
                    this.teeTimesIndex++;
                } else if (this.checkLoopTeeTimes())
                {
                    this.teeTimes[teeIndex + 3] = this.teeTimesAll[0];
                    this.teeTimesIndex = 1;
                    }
                }

            }, 1000);
    }

    private checkLoopTeeTimes() {
        return this.teeTimesAll.length > (this.isFiveBall ? 3 : 4) || this.teeTimes.length === 0;
    }

    showProfilePicture(profile) {
        let profilePictureUrl = "https://" + environment.cdnUrl + "/profile/defaultThumbnail.jpg";

        if (profile && profile.pictureFilename != null && profile.pictureFilename !== '') {
            profilePictureUrl = "https://" + environment.cdnUrl + "/" + profile.pathPictureFilename;

        }
        return profilePictureUrl;
    }

    getTime(dateTime: any): string {
        return moment.utc(dateTime).format("HH:mm");
    }

    private LoadBackgroundImage() {

        if (this.arrivalsMedia.backgroundImg) {
            this.backgroundImage =  `url(https://${environment.cdnUrl}${this.arrivalsMedia.backgroundImg})`;
        } else if (this.arrivalsMedia.venueLogoFileName) {
            this.backgroundImage = 'url(' + this.arrivalsMedia.venueLogoFileName + ')';
        } else {
            this.backgroundImage = 'url(' + environment.imagesCDNUrl + 'bg1.jpg)';
        }
    }
}
