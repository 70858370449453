import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Round, Competition } from '../models';
@Injectable()
export class RoundResolver implements Resolve<Observable<Round>> {
    constructor() { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Round> {
        var roundID = route.params['roundId'];
        var tournament = <Competition>route.parent.data['tournament'];
        var rounds = tournament.rounds;
        var currentRound = _.find(rounds, { roundID: +roundID });
        return of(currentRound);
    }
}