<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="panel-content">
                <div class="row">
                </div>
                <flights-renderer [editable]="false" [deleteable]="false" [holes]="holes" [teams]="teams" [round]="currentRound" [flightType]="flightType" [flights]="flights"></flights-renderer>
            </div>
        </div>
    </div>
</div>