<div class="container" id="login-block">
    <div class="account-info">
        <a class="logo"></a>
        <h5>The Ultimate Tournament</h5>
        <h5>Management System</h5>
        <ul>
            <li><i class="icon-screen-desktop"></i> Drive Traffic Online</li>
            <li><i class="icon-clock"></i> Live Leaderboards</li>
            <li><i class="icon-bar-chart"></i> Post-round analysis</li>
            <li><i class="icon-screen-smartphone"></i> Integrated smartphone app</li>
        </ul>
    </div>
    <div class="account-form">
        <form #f="ngForm" class="form-signin" role="form" (ngSubmit)="login(username.value, password.value)">
            <h3><strong>Sign in</strong> to your account</h3>
            <div class="append-icon">
                <input type="text" #username class="form-control form-white username" id="Username" placeholder="Username" required />
                <i class="icon-user"></i>
            </div>
            <div class="append-icon m-b-20">
                <input type="password" #password class="form-control form-white password" id="password" placeholder="Password" required />
                <i class="icon-lock"></i>
            </div>
            <button type="submit" id="submit-form" class="btn btn-lg btn-dark btn-rounded ladda-button" data-style="expand-left">Sign In</button>
            <!--<span class="forgot-password"><a id="forgotpasswordlink" href="/forgotpassword">Forgot password?</a></span>-->
            <span class="clearfix" style="color:red">{{errorMessage}}</span>
            <div class="form-footer">
                <div class="clearfix">
                    <p class="text-center"><a href="mailto:enquiry@vpar.com">New user? Contact us to arrange a demo</a></p>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- END LOCKSCREEN BOX -->
<p class="account-copyright">
    <span>Copyright © 2016 </span><span><a href="https://www.vpar.com" style="color:#ef642e">VPAR</a></span><span> All rights reserved.</span>
</p>
