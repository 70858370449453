/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./carousel-example";
var styles_Angular2Carousel = ["body[_ngcontent-%COMP%] { overflow: hidden !important; }"];
var RenderType_Angular2Carousel = i0.ɵcrt({ encapsulation: 0, styles: styles_Angular2Carousel, data: {} });
export { RenderType_Angular2Carousel as RenderType_Angular2Carousel };
export function View_Angular2Carousel_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_Angular2Carousel_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "my-app", [], null, null, null, View_Angular2Carousel_0, RenderType_Angular2Carousel)), i0.ɵdid(1, 49152, null, 0, i1.Angular2Carousel, [], null, null)], null, null); }
var Angular2CarouselNgFactory = i0.ɵccf("my-app", i1.Angular2Carousel, View_Angular2Carousel_Host_0, {}, {}, []);
export { Angular2CarouselNgFactory as Angular2CarouselNgFactory };
