
import {takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Round, Competition } from "../../models";
import { CompetitionRouteService } from '../../services';
import { Subject } from 'rxjs';

@Component({
    templateUrl: 'tournament.component.html',
    styleUrls: ['tournament.component.scss']
})

export class TournamentComponent implements OnInit, OnDestroy {
    public tournament: Competition;
    public roundId: string;
    public roundNumber: string;
    public rounds: any = [];
    public tournamentId: string;
    public $stop: Subject<boolean> = new Subject<any>();

    constructor(private route: ActivatedRoute, private competitionRouteService: CompetitionRouteService)
    {}

    ngOnInit() {
        this.tournament = <Competition>this.route.snapshot.data['tournament'];
        this.tournamentId = this.tournament.competitionID.toString();
        this.rounds = this.roundList(this.tournament.rounds);
        this.competitionRouteService.roundID$.pipe(takeUntil(this.$stop)).subscribe(id => {
            this.roundId = id;
        });
    }

    roundList(rounds: any): any[] {
        var roundDetails = [];
        var i = 0;
        rounds.forEach(entry => {
            i++;
            roundDetails.push({
                roundNumber: i,
                roundID: `${entry.roundID}`
            });
        });
        return roundDetails;
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.$stop.next(true);
    }
}
