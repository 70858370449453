import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Helpers } from '../../../helpers/helpers';

@Component({
    selector: 'matchplay-leaderboard',
    templateUrl: 'matchplay-leaderboard.component.html',
    styleUrls: ['./matchplay-leaderboard.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('1 => 0', animate('300ms')),
            transition('0 => 1', animate('300ms'))
        ])
    ]
})

export class MatchplayLeaderboardComponent implements OnInit {

    @Input() matchplayLeaderboard: any;

    helpers = Helpers;

    constructor() { }

    ngOnInit() { }

    replaceAmp(value: string) {
        return value === 'All Sq' ? value : value.replace('&amp;', '&').replace(' ', '');
    }
}
