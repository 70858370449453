<p-accordion (onOpen)="onTabOpen($event)">
  <p-accordionTab header="Advanced Settings" [selected]="true">
      <div [formGroup]="roundConfiguration">
          <input type="hidden" formControlName="roundID" />
          <input type="hidden" formControlName="roundConfigurationID" />
          <div class="row">
              <div class="col-sm-3">
                  <div class="form-group">
                      <span class="text-muted bold margin" [attr.for]="'isModifiedRules'+index">Modified Stableford Points</span>
                      <a href="#" class="popoverInfo" data-content="Allows you modify the default points value for Stableford scoring.<br/><br/>i.e. Change Par default points from 2 to 4">
                          <i class="fa fa-question-circle tooltip-colour"></i>
                      </a>
                      <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!roundConfiguration.get('isModifiedRules').value">
                          <p-inputSwitch styleClass="p-inputSwitch" formControlName="isModifiedRules"></p-inputSwitch>
                      </div>
                  </div>
              </div>
              <div class="col-sm-3">
                  <div class="form-group">
                      <span class="text-muted bold margin" [attr.for]="'forceMinimumRelativeNetScoreToContribute'+index">Enable Par is Friend</span>
                      <a href="#" class="popoverInfo" data-content="Allows golfers to avoid scoring worse than net Par making a more inclusive competition.<br/><br/>i.e. Strokeplay - Net par is awarded<br/>i.e. Stableford - 2 points are awarded">
                          <i class="fa fa-question-circle tooltip-colour"></i>
                      </a>
                      <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!roundConfiguration.get('forceMinimumRelativeNetScoreToContribute').value">
                          <p-inputSwitch styleClass="p-inputSwitch" formControlName="forceMinimumRelativeNetScoreToContribute"></p-inputSwitch>
                      </div>
                  </div>
              </div>
              </div>
              <div class="row">
              <div class="col-sm-3">
                  <div class="form-group" *toggleFeature="'decimalScoring';offeringLevel:offeringLevel">
                      <span class="text-muted bold margin" [attr.for]="'UseDecimalScoring'+index">Enable decimal scoring</span>
                      <a href="#" class="popoverInfo" data-content="Allows you to use exact handicaps to calculate team and individual scores.<br/><br/>i.e. Use 17.6 in place of 18">
                          <i class="fa fa-question-circle tooltip-colour"></i>
                      </a>
                      <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!roundConfiguration.get('useDecimalScoring').value">
                          <p-inputSwitch styleClass="p-inputSwitch" formControlName="useDecimalScoring"></p-inputSwitch>
                      </div>
                  </div>
              </div>
              <div class="col-sm-3" style="width: 30%;">
                  <div class="form-group">
                      <span class="text-muted bold margin" [attr.for]="'handicapIsAdjustedForSlopeRating'+index">HCP Adjustments on day of event</span>
                      <a href="#" class="popoverInfo" data-content="Turn this feature off if you do not want the app to perform a final handicap adjustment using the very latest VPAR handicaps that could have changed since adding players to their tee times for this event.<br/>You should keep this feature on if using VPAR handicaps and your players are likely to have played in the interim period between you adding players to your tee times and the day of the event meaning that their VPAR handicaps could have changed.<br/>You should turn this feature off if you have made any manual adjustments to game handicaps otherwise these will be overwritten by the final on the day check when players log in to their tee times.">
                          <i class="fa fa-question-circle tooltip-colour"></i>
                      </a>
                      <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!roundConfiguration.get('handicapIsAdjustedForSlopeRating').value">
                          <p-inputSwitch styleClass="p-inputSwitch" formControlName="handicapIsAdjustedForSlopeRating"></p-inputSwitch>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row p-t-10" *ngIf="roundConfiguration.get('isModifiedRules').value">
              <div class="modifiedStableFordContainer ">
                  <div class="text-center">
                      <select class="form-control input-sm modifiedStableford" data-style="white" data-placeholder="Select..." formControlName="albatross">
                          <option *ngFor="let val of modifiedStablefordValues" [value]="val.value">{{val.label}}</option>
                      </select>
                      <label class="text-muted bold p-t-10">Albatross</label>
                  </div>
              </div>
              <div class="modifiedStableFordContainer">
                  <div class="text-center">
                      <select class="form-control input-sm modifiedStableford" data-style="white" data-placeholder="Select..." formControlName="eagle">
                          <option *ngFor="let val of modifiedStablefordValues" [value]="val.value">{{val.label}}</option>
                      </select>
                  </div>
                  <div class="text-center">
                      <label class="text-muted bold p-t-10">Eagle</label>
                  </div>
              </div>
              <div class="modifiedStableFordContainer">
                  <div class="text-center">
                      <select class="form-control input-sm modifiedStableford" data-style="white" data-placeholder="Select..." formControlName="birdie">
                          <option *ngFor="let val of modifiedStablefordValues" [value]="val.value">{{val.label}}</option>
                      </select>
                      <div class="text-center">
                          <label class="text-muted bold p-t-10">Birdie</label>
                      </div>
                  </div>
              </div>
              <div class="modifiedStableFordContainer">
                  <div class="text-center">
                      <select class="form-control input-sm modifiedStableford" data-style="white" data-placeholder="Select..." formControlName="par">
                          <option *ngFor="let val of modifiedStablefordValues" [value]="val.value">{{val.label}}</option>
                      </select>
                      <div class="text-center">
                          <label class="text-muted bold p-t-10">Par</label>
                      </div>
                  </div>
              </div>
              <div class="modifiedStableFordContainer">
                  <div class="text-center">
                      <select class="form-control input-sm modifiedStableford" data-style="white" data-placeholder="Select..." formControlName="bogey">
                          <option *ngFor="let val of modifiedStablefordValues" [value]="val.value">{{val.label}}</option>
                      </select>
                      <div class="text-center">
                          <label class="text-muted bold p-t-10">Bogey</label>
                      </div>
                  </div>
              </div>
              <div class="modifiedStableFordContainer">
                  <div class="text-center">
                      <select class="form-control input-sm modifiedStableford" data-style="white" data-placeholder="Select..." formControlName="doubleBogey">
                          <option *ngFor="let val of modifiedStablefordValues" [value]="val.value">{{val.label}}</option>
                      </select>
                  </div>
                  <div class="text-center">
                      <label class="text-muted bold p-t-10">D. Bogey</label>
                  </div>
              </div>
          </div>
      </div>
  </p-accordionTab>
</p-accordion>
