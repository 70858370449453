import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LeaderboardService } from '../../../services';

@Component({
    selector: 'leaderboard-item-delete',
    templateUrl: 'leaderboard.item.delete.component.html',
})

export class LeaderboardItemDeleteComponent  {
    @Input() displayIteamDeleteDialog: boolean = false;
    @Output() CloseDeleteItem: EventEmitter<boolean> = new EventEmitter();
    closeDeleteItem: boolean;
    @Input() ItemToRemove: any;
    @Input() ItemTitle: any;

    constructor(public leaderboardService: LeaderboardService ) {
    }

    Save(event) {
        this.leaderboardService.removeItem(this.ItemToRemove);
        this.closeDeleteItem = true;
        this.CloseDeleteItem.next(true);
        this.closeDeleteItem = false;
    }

    Close(event) {
        this.closeDeleteItem = true;
        this.CloseDeleteItem.next(false);
        this.closeDeleteItem = false;
    }
}
