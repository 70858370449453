<div class="sidebar" id="mySidenav">
    <!-- Repeat this block for each menu item, changing the img src as needed -->
    <div class="menu-item" routerLinkActive="menu-active" >
        <a class="menu-link" [routerLink]="['/tournaments']">
            <img src="https://dev.thebelfry.vpar.com/assets/icons/menu-icons/tournaments.svg" alt="Icon description" class="tms-menu-icon" id="menu-logo" pTooltip="Tournaments" tooltipPosition="right" placeholder="Right">
            <span class="tooltip">Tournaments</span>
        </a>
    </div>
    <div class="menu-item"  routerLinkActive="menu-active" >

        <a class="menu-link" [routerLink]="['/golfers']">
        <img src="https://dev.thebelfry.vpar.com/assets/icons/menu-icons/golfers.svg" alt="Icon description" pTooltip="Members" tooltipPosition="right" placeholder="Right">
        <span class="tooltip">Members</span>
    </a>
    </div> 
    <div class="menu-item" routerLinkActive="menu-active" *ngIf="orderOfMeritActive">
        <a class="menu-link" [routerLink]="['/order-of-merit']">
        <img src="../../../../assets/icons/menu-icons/stats.svg" alt="Icon description" pTooltip="Order of Merit" tooltipPosition="right" placeholder="Right">
        <span class="tooltip">Order of Merit</span>
    </a>
    </div>
    <div class="menu-item" routerLinkActive="menu-active" >
        <a  class="menu-link" [routerLink]="['/settings']">
        <img src="https://dev.thebelfry.vpar.com/assets/icons/menu-icons/settings.svg" alt="Icon description" pTooltip="Settings" tooltipPosition="right" placeholder="Right">
        
        <span class="tooltip">Settings</span>
    </a>
    </div>
    <div class="menu-item" routerLinkActive="menu-active" >
        <a class="menu-link" href="https://vpar.zendesk.com/hc/en-us" target="_blank">
        <img  class="img" src="https://dev.thebelfry.vpar.com/assets/icons/menu-icons/clubhouse.svg" alt="Icon description" pTooltip="Help" tooltipPosition="right" placeholder="Right">
        <span class="tooltip">Help</span>
    </a>
    </div>
   
    <div class="menu-item" routerLinkActive="menu-active" >
        <a class="menu-link" [routerLink]="['/login']" routerLinkActive="router-link-active" (click)="logout()" >
        <img src="https://dev.thebelfry.vpar.com/assets/icons/menu-icons/logout.svg" alt="Icon description" pTooltip="Logout" tooltipPosition="right" placeholder="Right">
        <span class="tooltip">Logout</span>
    </a>
    </div>
    <div class="divider" routerLinkActive="menu-active" ></div>
    <!-- <div class="sidebar__profile">
      <div class="avatar__wrapper">
        <img class="avatar" [src]="fullPath" alt="Golfer Picture">
      </div>
    </div> -->
</div>
