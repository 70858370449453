import { NgModule } from '@angular/core';
import { FlightTeamRendererComponent } from './flight-team.renderer.component';
import { TournamentOverviewFlightScorecardComponent } from './tournament.overview.flight.scorecard.component';
import { AppSharedModule } from '../../../shared/app.shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { TeamsService } from "../../../services";

@NgModule({
    imports: [BrowserModule, AppSharedModule],    // module dependencies
    declarations: [TournamentOverviewFlightScorecardComponent, FlightTeamRendererComponent],   // components and directives
    exports: [TournamentOverviewFlightScorecardComponent],     // root component
    providers: [TeamsService]            // services
})
export class TeeTimesScorecardModule { }
