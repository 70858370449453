<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="loading" *ngIf="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div class="col-md-12" style="min-height:300px;">
                <div class="panel-content">
                    <div class="row m-b-10">
                        <div class="header-group border-bottom">
                            <i class="fa  fa-print"></i>
                            <div class="text-capitalize headerText">PRINT OVERVIEW</div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div style="padding-bottom:20px;">
                            <span>What would you like to print?</span>
                        </div>
                        <div class="row m-b-20">
                            <div class="col-md-5">
                                <div><label for="startHole">Print type</label></div>
                                <div>
                                    <p-dropdown (onChange)="onPrintTypeChange($event)" [options]="currentPrintTypes" [(ngModel)]="selectedPrintType" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false" a></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="row m-b-20" *ngIf="showTeeTimedropdown">
                            <div class="col-md-5">
                                <div><label for="startHole">Tee-time</label></div>
                                <div>
                                    <p-dropdown [options]="currentTeeTimes" [(ngModel)]="teeTimeID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="row m-b-20" style="display:none;">
                            <div class="col-md-5">
                                <div><label for="startHole">Print Options:</label></div>
                                <div>
                                    <PrintOption [detail]="printOption" (detailUpdated)="printOptionUpdated($event)" *ngFor="let printOption of PrintOptions"></PrintOption>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="showLogoUpload">
                        <input type="file" class="file-input" style="display:none"
                               (change)="onFileSelected($event.target.files)" #fileUpload>

                        <div class="file-upload">

                            {{fileName || "Upload logo."}}

                            <button class="btn-action-ok w-600 f-13 m-b-0"
                                    (click)="fileUpload.click()">
                                ... Browse
                            </button>
                            <button class="btn-action-ok w-600 f-13 m-b-0" [disabled]="uploadInProgress" (click)="uploadLogo()">Upload</button>
                        </div>
                        <div style="min-height:200px;min-width:200px;padding-left:100px;padding-top:10px;">
                            <img [src]="logoFileName" style="max-height:200px; max-width:200px;" />
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="showFreeTextBox">
                        <div><label for="startHole">Labels title:</label></div>
                        <input type="text" placeholder="Enter game format" [(ngModel)]="freeText" />
                    </div>
                    <div class="col-md-6" *ngIf="showResultsRowNumber">
                        <div><label for="startHole">Display results from 1 to :</label></div>
                        <input type="text" placeholder="Enter game format" [(ngModel)]="resultsNumberOfRows" />
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="btn-group col-xs-3 pull-right">
                    <button class="btn m-b-5 print-download" (click)="download()" label="Download" [disabled]="!isPrintTypeSelected">Download</button>
                </div>
            </div>
        </div>
    </div>
</div>
