import { RoundForScoredcard } from "../models/scorecard";
import {
  FlightForPostScores,
  FlightMemberForPostScores,
  PostScoresModel,
  ScoreForPostScoreCard,
} from "../models/scores/postScoresModel";

export class MappingService {
  mapScoreToPostScore(scoresRequest: RoundForScoredcard): PostScoresModel {
    let postModel = new PostScoresModel();
    postModel.roundId = scoresRequest.roundID;

    
    postModel.flights = [];

    
    scoresRequest.flights.forEach((flight) => {
      let flightForPostScores = new FlightForPostScores();
      flightForPostScores.flightID = flight.flightID;
      
      flightForPostScores.flightMembers = [];
      
      flight.flightMembers.forEach((member) => {        
        let flightMemberForPostScores = new FlightMemberForPostScores();
        flightMemberForPostScores.flightID = flight.flightID;
        flightMemberForPostScores.flightMemberID = member.flightMemberID;
        flightMemberForPostScores.ball = member.ball;
        flightMemberForPostScores.handicap = member.handicap;
        flightMemberForPostScores.teamID = member.teamID;

        flightMemberForPostScores.scores = [];

        member.scores.forEach((score) => {
          let scoreForPostScoreCard = new ScoreForPostScoreCard();
          scoreForPostScoreCard.holeID = score.holeID;
          scoreForPostScoreCard.holeNumber = score.holeNumber;
          scoreForPostScoreCard.par = score.par;
          scoreForPostScoreCard.shots = score.shots;
          scoreForPostScoreCard.si = score.strokeIndex;
          scoreForPostScoreCard.gross = score.scoreGross;
          scoreForPostScoreCard.grossVpar = score.scoreGrossVPAR;
          scoreForPostScoreCard.adjustedGross = score.adjustedGross;
          scoreForPostScoreCard.grossPoints = score.scoreGrossPoints;
          scoreForPostScoreCard.scoreGrossPointsVPAR = score.scoreGrossPointsVPAR;
          scoreForPostScoreCard.net = score.scoreNet;
          scoreForPostScoreCard.netVPAR = score.scoreNetVPAR;
          scoreForPostScoreCard.accuracy = score.accuracy;
          scoreForPostScoreCard.points = score.scorePoints;
          scoreForPostScoreCard.grossWithBlob = score.scoreGrossWithBlob;
          scoreForPostScoreCard.club = score.club;
          scoreForPostScoreCard.putts = score.putts;
          scoreForPostScoreCard.scoreType = score.scoreType;
          scoreForPostScoreCard.sandSaveType = score.sandSaveType;
          scoreForPostScoreCard.scorePointsVPAR = score.scorePointsVPAR;

          flightMemberForPostScores.scores.push(scoreForPostScoreCard);
        });

        flightForPostScores.flightMembers.push(flightMemberForPostScores);
      });

      postModel.flights.push(flightForPostScores);
    });

    return postModel;
  }
}
