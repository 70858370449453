<section>
    <!-- BEGIN SIDEBAR -->
 
    <!-- <sidebar>Sidebar</sidebar> -->
    <side-menu></side-menu>
    <!-- END SIDEBAR -->
    <div id="main" class="main-content">
        <!-- BEGIN TOPBAR -->
        <topbar>topbar</topbar>
        <router-outlet></router-outlet>
    </div>
    <!-- END PAGE CONTENT -->
</section>