// shared.service.ts
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { OrderOfMeritSwitchInfo } from "src/app/models/OrderOfMerit/OrderOfMeritSwitchInfo";

@Injectable({
  providedIn: "root",
})
export class OrderOfMeritSharedServices {
  private messageSource = new BehaviorSubject<OrderOfMeritSwitchInfo>(null);
  currentInfo = this.messageSource.asObservable();

  constructor() {}

  sendInfo(info: OrderOfMeritSwitchInfo) {
    this.messageSource.next(info);
  }
}
