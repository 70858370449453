import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Profile } from "../models";
import { Observable, Subject } from "rxjs";

import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class ProfileService {
  profiles$: Observable<Profile[]>;
  private _profilesObserver = new Subject<Profile[]>();

  private _dataStore: {
    profiles: Profile[];
  };

  constructor(private toastr: ToastrService, public httpService: HttpServices) {
    this._dataStore = { profiles: [] };
    this.profiles$ = this._profilesObserver.asObservable().pipe(share());
  }

  loadAll(competitionId: string, roundID: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/Rounds/${roundID}`;
    this.httpService.get(url).subscribe(
      (data: Array<Profile>) => {
        this._dataStore.profiles = data;
        this._profilesObserver.next(this._dataStore.profiles);
      },
      (error) => console.log("Could not load profile")
    );
  }

  loadProfile(profileID: number): Observable<Profile> {
    var observable = new Subject<Profile>();
    if (profileID) {
      this.httpService
        .getTyped<Profile>(`${environment.apiUrl}/api/Profiles/${profileID}`)
        .subscribe(
          (data) => {
            if (data) {
              let notFound = true;
              this._dataStore.profiles.forEach((item, index) => {
                if (item.profileID === data.profileID) {
                  this._dataStore.profiles[index] = data;
                  notFound = false;
                }
              });

              if (notFound) {
                this._dataStore.profiles.push(data);
              }
            }
            observable.next(data);
            observable.complete();
            this._profilesObserver.next(this._dataStore.profiles);
          },
          (error) => console.log("Could not load profile")
        );
    }
    return observable;
  }

  load(email: string): Observable<Profile> {
    var observable = new Subject<Profile>();
    if (email) {
      this.httpService
        .getTyped<Profile>(`${environment.apiUrl}/api/Profiles/email/${email}`)
        .subscribe(
          (data) => {
            if (data) {
              let notFound = true;
              this._dataStore.profiles.forEach((item, index) => {
                if (item.profileID === data.profileID) {
                  this._dataStore.profiles[index] = data;
                  notFound = false;
                }
              });

              if (notFound) {
                this._dataStore.profiles.push(data);
              }
            }
            observable.next(data);
            observable.complete();
            this._profilesObserver.next(this._dataStore.profiles);
          },
          (error) => console.log("Could not load profile")
        );
    }
    return observable;
  }

  add(profile: Profile): Observable<Profile> {
    var observable = new Subject<Profile>();
    this.httpService
      .post(`${environment.apiUrl}/api/Profiles`, profile)
      .subscribe(
        (data: Profile) => {
          this.toastr.success("Player added");
          this._dataStore.profiles.push(data);
          this._profilesObserver.next(this._dataStore.profiles);
          observable.next(data);
          observable.complete();
          return data;
        },
        (error) => console.log("Could not create profile.")
      );
    return observable;
  }

  update(profile: Profile): Observable<Profile> {
    var observable = new Subject<Profile>();
    this.httpService
      .put(`${environment.apiUrl}/api/Profiles`, profile)
      .subscribe(
        (data: Profile) => {
          this.toastr.success("Player details updated");
          var index = _.findIndex(this._dataStore.profiles, {
            profileID: profile.profileID,
          });
          if (index !== -1) {
            this._dataStore.profiles[index] = data;
          } else {
            this._dataStore.profiles.push(data);
          }
          this._profilesObserver.next(this._dataStore.profiles);
          observable.next(data);
          observable.complete();
          return data;
        },
        (error) => console.log("Could not update profile.")
      );
    return observable;
  }

  public showProfilePicture(profile: Profile): string {
    let profilePictureUrl =
      "https://" + environment.cdnUrl + "/profile/defaultThumbnail.jpg";

    if (profile.pictureFilename != null && profile.pictureFilename !== "") {
      profilePictureUrl =
        "https://" + environment.cdnUrl + "/" + profile.pathPictureFilename;
    }
    return profilePictureUrl;
  }
}
