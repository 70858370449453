import {Component} from '@angular/core';
                    //<div style="width: 100%; height:100%;">
                    //  <div>
                    //    <carousel [interval]="NextPhotoInterval" [noWrap]="noLoopSlides">
                    //      <slide *ngFor="#slidez of slides; #index=index"
                    //             [active]="slidez.active">
                    //        <img [src]="slidez.image" style="width: 100%;">

                    //      </slide>
                    //    </carousel>
                    //  </div>
                    //</div>
@Component({
    selector: 'my-app',
    template: `


             `,
    styles: ['body { overflow: hidden !important; }']
})
export class Angular2Carousel {
    private NextPhotoInterval: number = 2000;
    private noLoopSlides: boolean = false;
    private slides: Array<any> = [];

    constructor() {
        this.addNewSlide();
    }

    private addNewSlide() {
        this.slides.push(
            { image: 'http://cdn.vpar.com/media/leaderboard/874a0964-23db-44e6-a9a2-d72c35103f6f.jpg', text: '1' },
            { image: 'http://cdn.vpar.com/media/leaderboard/0ab02472-2a5d-43f6-a59d-1557d28950a9.jpg', text: '2' },
            { image: 'http://cdn.vpar.com/media/leaderboard/aef6246d-5dd2-436f-a18d-c8ccfbea03ef.jpg', text: '3' }
        );
    }
}