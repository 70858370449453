import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceDetail, RoundMediaDetails } from '../../../models';

@Component({
    selector: 'media-popup',
    templateUrl: 'media-popup.component.html',
    styleUrls: ['./media-popup.component.scss']
})

export class MediaPopupComponent implements OnInit {

    @Input() popupList: DeviceDetail[];
    @Input() popup: RoundMediaDetails;
    @Input() highlighted: boolean;

    @Output() onToggleCropTool = new EventEmitter();
    @Output() onSelectMedia = new EventEmitter();

    @Output() onMediaSend = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    toggleCropTool() {
        this.onToggleCropTool.emit();
    }

    sendMedia() {
        this.onMediaSend.emit(this.popup.mediaID.toString() !== '0' ? this.popupList.find((item) => item.mediaID.toString() === this.popup.mediaID.toString()).cdnPath : '');
    }

    selectImage(mediaId) {
        if (mediaId !== '0') {
            this.popup.mediaDetails = this.popupList.find((item) => item.mediaID.toString() === mediaId);
        } else {
            this.popup.mediaDetails = null;
        }
        this.sendMedia();
    }

    selectMedia() {        
        this.sendMedia();
    }
}
