<div class="row">
    <div class="col-xs-12 border m-b-15 team">
        <div class="col-sm-10 col-xs-8 tall center cursor-pointer hole-config" (click)="toggle()">
            <i class="fa fa-chevron-circle-right vparorange" aria-hidden="true" *ngIf="!open"></i>
            <i class="fa fa-chevron-circle-down vparorange" aria-hidden="true" *ngIf="open"></i>
            <strong>{{player.profile.displayName}}</strong>
        </div>
        <div class="col-sm-2 col-xs-4 background tall center teeTime" style="border-bottom:1px solid white;display: flex; align-items: center;">
            <span class="bold hole-config" style="margin: auto;">Hole {{player.flight.startHole}} - {{getTime(player.flight.teeOffTime)}}</span>
        </div>
        <div class="playerHolder">
            <scorecard [roundID]="roundID" [teamType]="0" [flightMemberID]="player.flightMemberID" [flightID]="player.flightID" [tournamentOpen]="tournamentOpen" [courseData]="courseData" [maleTeeColor]="maleTeeColor" [femaleTeeColor]="femaleTeeColor" (onEditingChange)="editScorecardChanged($event)"></scorecard>
        </div>
    </div>
</div>