/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loggedin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/side-menu/side-menu.component.ngfactory";
import * as i3 from "../../shared/components/side-menu/side-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/login.service";
import * as i6 from "../../services/GolfersService";
import * as i7 from "../../shared/components/topbar/topbar.component.ngfactory";
import * as i8 from "../../shared/components/topbar/topbar.component";
import * as i9 from "./loggedin.component";
var styles_LoggedInComponent = [i0.styles];
var RenderType_LoggedInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedInComponent, data: {} });
export { RenderType_LoggedInComponent as RenderType_LoggedInComponent };
export function View_LoggedInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "side-menu", [], null, null, null, i2.View_SideMenuComponent_0, i2.RenderType_SideMenuComponent)), i1.ɵdid(2, 4308992, null, 0, i3.SideMenuComponent, [i4.ActivatedRoute, i5.LoginService, i4.Router, i6.GolfersServices], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "main-content"], ["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "topbar", [], null, null, null, i7.View_TopbarComponent_0, i7.RenderType_TopbarComponent)), i1.ɵdid(5, 4308992, null, 0, i8.TopbarComponent, [i4.Router, i1.ElementRef, i5.LoginService], null, null), (_l()(), i1.ɵted(-1, null, ["topbar"])), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_LoggedInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mainapp", [], null, null, null, View_LoggedInComponent_0, RenderType_LoggedInComponent)), i1.ɵdid(1, 114688, null, 0, i9.LoggedInComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoggedInComponentNgFactory = i1.ɵccf("mainapp", i9.LoggedInComponent, View_LoggedInComponent_Host_0, {}, {}, []);
export { LoggedInComponentNgFactory as LoggedInComponentNgFactory };
