import { Profile } from './profile';
export class PlayerCreateDetail extends Profile {
    constructor() {
        super(...arguments);
        this.addToBallSlot = false;
        this.flightMemberID = 0;
        this.flightMemberType = 1;
        this.flightMemberStatus = 100;
        this.flightID = 0;
        this.flightHandicap = 0;
        this.shots = 0;
        this.isManuallyAdjusted = false;
    }
    get genderText() {
        if (this.gender == null) {
            return "";
        }
        switch (this.gender.toString()) {
            case "1":
                return "M";
            case "2":
                return "F";
        }
        return "";
    }
}
