/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topbar.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../services/login.service";
var styles_TopbarComponent = [i0.styles];
var RenderType_TopbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopbarComponent, data: {} });
export { RenderType_TopbarComponent as RenderType_TopbarComponent };
export function View_TopbarComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_TopbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "topbar", [], null, null, null, View_TopbarComponent_0, RenderType_TopbarComponent)), i1.ɵdid(1, 4308992, null, 0, i2.TopbarComponent, [i3.Router, i1.ElementRef, i4.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopbarComponentNgFactory = i1.ɵccf("topbar", i2.TopbarComponent, View_TopbarComponent_Host_0, {}, {}, []);
export { TopbarComponentNgFactory as TopbarComponentNgFactory };
