import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class OrderOfMeritSharedServices {
    constructor() {
        this.messageSource = new BehaviorSubject(null);
        this.currentInfo = this.messageSource.asObservable();
    }
    sendInfo(info) {
        this.messageSource.next(info);
    }
}
OrderOfMeritSharedServices.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderOfMeritSharedServices_Factory() { return new OrderOfMeritSharedServices(); }, token: OrderOfMeritSharedServices, providedIn: "root" });
