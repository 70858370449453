import { Component, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from "../../../services";

//Left to allow use of dropdownhover - could convert to typed
declare var $: any;
declare var toggleSidebar: Function;
declare var toggleSubmenuHover: Function;
declare var collapsedSidebar: Function;
declare var toggleSidebarTop: Function;
declare var toggleSidebarHover: Function;
declare var toggleboxedLayout: Function;
declare var toggleTopbar: Function;
declare var toggleFullScreen: Function;

@Component({
    selector: 'topbar',
    templateUrl: 'topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements AfterViewInit, OnInit {

    public displayName: string;
    public profileImageUrl: string;
    loggedIn = false;

    constructor(private _router: Router, public elementRef: ElementRef,
        public loginService: LoginService) {
        
    }

    ngOnInit() {
        this.loginService.loggedInDetail$.subscribe(token => {
            if (token) {
                this.displayName = token.DisplayName;
                this.profileImageUrl = "https://" + environment.siteUrl + "/inc/generate/getThumbnail.ashx?fillmode=fill&w=36&h=36&img=http%3a%2f%2fcdn.vpar.com%2fprofile%2f254683%2f0e1d086a-c353-43ba-a7a1-326664d51257.jpg&isRemote=1";
                if (token.ProfileImage != '') {
                    this.profileImageUrl = "https://" + environment.cdnUrl + "/profile/" + token.ProfileImage;
                }
            }
            this.loggedIn = (token !== '' && token !== undefined && token !== null) ? true: false;
        });
    }

    logout() {
        event.preventDefault();
        this.loginService.logout();
        this._router.navigate(['login']);
    }
    ngAfterViewInit() {
        var menutoggle = $(this.elementRef.nativeElement).find('[data-toggle]')[0];
        $(menutoggle).on('click', function (event) {
            event.preventDefault();
            var toggleLayout = $(this).data('toggle');
            if (toggleLayout === 'sidebar-behaviour') toggleSidebar();
            if (toggleLayout === 'submenu') toggleSubmenuHover();
            if (toggleLayout === 'sidebar-collapsed') collapsedSidebar();
            if (toggleLayout === 'sidebar-top') toggleSidebarTop();
            if (toggleLayout === 'sidebar-hover') toggleSidebarHover();
            if (toggleLayout === 'boxed') toggleboxedLayout();
            if (toggleLayout === 'topbar') toggleTopbar();
        });


        var togglefullscreen = $(this.elementRef.nativeElement).find('#toggle_fullscreen')[0];
        $(togglefullscreen).click(() => {
            event.preventDefault();
            toggleFullScreen();
        });

        var userheader = $(this.elementRef.nativeElement).find('[data-hover="dropdown"]');
        $(userheader).dropdownHover();
    }
}
