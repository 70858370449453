import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Competition, FlightMember } from '../../../models';
import { ConfirmDialog } from '../../../shared/index';
import { FlightMembersService, LoginService } from '../../../services';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpServices } from 'src/app/services/HttpServices';

declare var moment: any;

@Component({
    selector: 'tournament-overview-data',
    templateUrl: 'tournament-overview-data.component.html',
    styleUrls: ['./tournament-overview-data.component.scss']
})

export class TournamentOverviewDataComponent implements OnInit, OnChanges, OnDestroy {

    @Input() tournament: Competition;

    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;

    $stop: Subject<boolean> = new Subject<any>();

    isTournamentOpen: boolean;

    OverAllLeaderboardUrl: string;
    vparMembers = 0;

    loggedId = true;

    constructor(
        private flightMembersService: FlightMembersService,
        private router: Router,
        private loginService: LoginService,
        private httpService : HttpServices) {
        this.loggedId = this.loginService.isLoggedIn();
    }

    ngOnInit() {
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadData();
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    private loadData() {
        this.isTournamentOpen = (this.tournament.status == 1 || this.tournament.status == 2) ? true : false;

        this.flightMembersService.loadAll(this.tournament.competitionID.toString(), this.tournament.rounds[0].roundID.toString());

        var url = `${environment.apiUrl}/api/LeaderBoard/OverAllLeaderboardUrl/${this.tournament.competitionID}`
        this.httpService.get(url).subscribe((data: string) => {
            this.OverAllLeaderboardUrl = data;
        }, error => console.log('Could not load TV OverAllLeaderBoard Url.'));

        this.flightMembersService.flightMembers$.pipe(takeUntil(this.$stop)).subscribe((data: Array<FlightMember>) => {
            this.vparMembers = data.filter(x => x.profile.vparMember).length;
        }, error => console.log(`Failed - Flight member service issue.${error}`), () => console.log(`Flight member service complete`));
    }

    onStatusChange(e) {
        var self = this;
        var callBack = () => {
            self.setTournamentStatus(self.tournament.competitionID, e.target.checked);
        }
        var cancelCallback = () => {
            e.target.checked = true;
            setTimeout(() => {
                self.isTournamentOpen = true;
            }, 0);
        }

        if (!e.target.checked) {
            self.confirmDialog.showDualDialog(callBack, cancelCallback);
        } else {
            self.setTournamentStatus(self.tournament.competitionID, e.target.checked);
        }
    }

    //Refactor to service
    setTournamentStatus(tournamentId: number, tournamentStatus: boolean) {
        let url = `${environment.apiUrl}/api/Competitions/${tournamentId}/TournamentStatus/${tournamentStatus}`;
        this.httpService.patch(url, "N/A")
            .subscribe(data => {
            },
                err => console.log(err),
                () => console.log('Tournament Status Updated')
            );
    }

    goToLeaderboard() {
        // window.open(this.OverAllLeaderboardUrl, '_blank');
        // new TV LB
        window.open(window.location.origin + '/public/tournaments/' + this.tournament.competitionID + '/round/' + this.tournament.rounds[0].roundID + '/tvleaderboard', '_blank');
    }

    editLeaderboard() {
        var redirectUrl = `/tournaments/${this.tournament.competitionID}/round/${this.tournament.rounds[0].roundID}/createleaderboard`;
        this.router.navigateByUrl(redirectUrl);
    }
}
