import {
  Component,
  OnInit,
  Injectable,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, DataTable } from "primeng/primeng";
import { LeaderboardOverviewData } from "src/app/models/leaderboardOverviewData";
import { OrderOfMeritConfig } from "src/app/models/OrderOfMerit/orderofmerit";
import { OrderOfMeritService } from "src/app/services/orderOfMeritService";
import { OrderOfMeritEnums } from "src/app/models/OrderOfMerit/OrderOfMeritEnums";
import { forkJoin } from "rxjs";
import { MenuItem } from "primeng/primeng";
import { GamesList } from "src/app/models/OrderOfMerit/GamesList";
import { getDaySuffix } from "../../shared/utils/numbers";
import { formatHCP } from "../../shared/utils/isFloat";
import { mergeMap, switchMap } from "rxjs/operators";
import { AppCookieService } from "src/app/services/CookiesServices";

@Component({
  templateUrl: "orderofmerit-overview.component.html",
  styleUrls: ["./orderofmerit-overview.component.scss"],
})
@Injectable()
export class OrderOfMeritOverviewComponent implements OnInit, OnDestroy {
  @ViewChild("dt", { static: false }) table: DataTable;
  oomCompetitions: number = 50;
  golfersQualified: number;
  orderOfMeritConfig: OrderOfMeritConfig = new OrderOfMeritConfig();
  players: LeaderboardOverviewData[];
  playersNumber: number;
  gameFormatStringValue: string;
  pointsFormatStringValue: string;
  startDay: number;
  startMonth: string;
  startYear: number;
  endDay: number;
  endMonth: string;
  endYear: number;
  startFormattedDate: string;
  endFormattedDate: string;
  leaderboardData: any[] = [];
  tournamentScores: string[] = [];
  games: GamesList[] = [];
  tabItems: MenuItem[];
  activeTab: MenuItem;
  activeTabIndex: number = 0;
  emptyGamesMessage: string = "No games found";

  

  constructor(
    private route: Router,
    private service: OrderOfMeritService,
    private confirmationService: ConfirmationService,
    private cookieAppService: AppCookieService
  ) {}

  ngOnInit() {
    this.buildMenuItems();
    this.service
      .getConfig()
      .pipe(
        mergeMap((configData: any) => {
          // Handle config data
          this.orderOfMeritConfig = configData;
          this.formatDate();
          this.gameFormatStringValue = this.getGameFormatValue(
            configData.gameFormat
          );
          this.pointsFormatStringValue = this.getPointsFormatValue(
            configData.pointsFormat
          );

          return this.service.getLeaderboardData(
            configData.orderOfMeritConfigId
          );
        }),
        mergeMap((leaderboardData: any) => {
          if (leaderboardData) {
            this.leaderboardData = leaderboardData.Response.L.T;
            if (this.leaderboardData.length > 0) {
              this.tournamentScores = this.leaderboardData[0].IndexOfScores.map(
                (score) => ` ${score.Points}`
              );
            }
          }

          // Get order of merit games
          return this.service.getGames(
            this.orderOfMeritConfig.startDate,
            this.orderOfMeritConfig.endDate
          );
        })
      )
      .subscribe((gamesData: any) => {
        this.games = gamesData;
      });
  }

  getPointsFormatValue(
    pointsFormat: OrderOfMeritEnums.PointsFormatEnum
  ): string {
    for (const key in OrderOfMeritEnums.PointsFormatEnum) {
      if ((OrderOfMeritEnums.PointsFormatEnum as any)[key] === pointsFormat) {
        return key;
      }
    }
    return "";
  }
  ngOnDestroy() {}
  customFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.table.filter(filterValue, "title", "contains");
  }
  redirectToEdit() {
    this.route.navigate(["order-of-merit/edit"]);
  }
  redirectToLeaderboard() {
    const competitionHostId = this.cookieAppService.getCompetitionHostIdCookie();
    this.route.navigate([`public/order-of-merit/${competitionHostId}/leaderboard`]);
  }
  getGameFormatValue(enumValue: number): string {
    for (const key in OrderOfMeritEnums.OrderOfMeritGameTypeEnum) {
      if (
        (OrderOfMeritEnums.OrderOfMeritGameTypeEnum as any)[key] === enumValue
      ) {
        return key;
      }
    }
    return "";
  }
  formatDate() {
    this.orderOfMeritConfig.startDate = new Date(
      this.orderOfMeritConfig.startDate
    );
    this.orderOfMeritConfig.endDate = new Date(this.orderOfMeritConfig.endDate);
    this.startDay = this.orderOfMeritConfig.startDate.getDate();
    this.startMonth = this.orderOfMeritConfig.startDate.toLocaleString(
      "default",
      {
        month: "long",
      }
    );
    this.startYear = this.orderOfMeritConfig.startDate.getFullYear();
    this.endDay = this.orderOfMeritConfig.endDate.getDate();
    this.endMonth = this.orderOfMeritConfig.endDate.toLocaleString("default", {
      month: "long",
    });
    this.endYear = this.orderOfMeritConfig.endDate.getFullYear();

    const daySuffix = getDaySuffix(this.startDay);
    this.startFormattedDate = `${daySuffix} of ${this.startMonth} ${this.startYear}`;
    const endDaySuffix = getDaySuffix(this.endDay);
    this.endFormattedDate = `${endDaySuffix} of ${this.endMonth} ${this.endYear}`;
    this.golfersQualified = 100;
  }

  getDaySuffixVal(val: number) {
    return getDaySuffix(val);
  }
  private buildMenuItems() {
    this.tabItems = [];

    this.tabItems.push({
      label: "Tournaments",
      visible: true,
      command: (event) => {
        this.setMenuOption(0);
      },
    });
    this.tabItems.push({
      label: "Leaderboard",
      visible: true,
      command: (event) => {
        this.setMenuOption(1);
      },
    });

    // Set the initial active tab to index 0
    this.setMenuOption(this.activeTabIndex);
  }
  formatHCP(val: number) {
    return formatHCP(val);
  }
  private setMenuOption(selectedIndex: number) {
    this.activeTabIndex = selectedIndex;
    this.activeTab = this.tabItems[selectedIndex];
  }

  sendGameForCalculation($event, rowData) {
    $event.stopPropagation();

    this.confirmationService.confirm({
      message: `Are you sure you want to send '${rowData.title}' for calculation?`,
      accept: () => {
        this.service
          .setGameAsFinished(rowData.competitionId)
          .pipe(
            switchMap(() =>
              this.service.sendForCalculation(rowData.competitionId)
            )
          )
          .subscribe(() => {
            this.service
              .getGames(
                this.orderOfMeritConfig.startDate,
                this.orderOfMeritConfig.endDate
              )
              .subscribe((games) => {
                this.games = games;
              });
          });
      },
    });
  }
}
