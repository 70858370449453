import { Directive, Input, OnInit } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { checkFeatureDisabled } from '../utils/index';

@Directive({ selector: '[toggleFeature]' })
export class ToggleFeatureDirective implements OnInit {

    @Input() toggleFeature: string;
    @Input() toggleFeatureOfferingLevel: number;

    private hasView = false;
    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) {
    }

    ngOnInit(): void {
        var disabled = checkFeatureDisabled(this.toggleFeature, this.toggleFeatureOfferingLevel);

        if (!disabled && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (disabled && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}