/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./team.renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/scorecard/scorecard.component.ngfactory";
import * as i4 from "../../../shared/components/scorecard/scorecard.component";
import * as i5 from "../../../services/scores.service";
import * as i6 from "../../../services/LeaderboardService";
import * as i7 from "ngx-toastr";
import * as i8 from "../../../services/login.service";
import * as i9 from "./team.renderer.component";
var styles_TeamRendererComponent = [i0.styles];
var RenderType_TeamRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeamRendererComponent, data: {} });
export { RenderType_TeamRendererComponent as RenderType_TeamRendererComponent };
function View_TeamRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-circle-right vparorange"]], null, null, null, null, null))], null, null); }
function View_TeamRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-circle-down vparorange"]], null, null, null, null, null))], null, null); }
export function View_TeamRendererComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scorecardComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "col-xs-12 border m-b-15 team"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-xs-12 tall center cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamRendererComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamRendererComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "teamHolder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "scorecard", [], null, [[null, "onEditingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEditingChange" === en)) {
        var pd_0 = (_co.editScorecardChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ScorecardComponent_0, i3.RenderType_ScorecardComponent)), i1.ɵdid(12, 114688, [[1, 4]], 0, i4.ScorecardComponent, [i5.ScoresService, i6.LeaderboardService, i7.ToastrService, i8.LoginService], { tournamentOpen: [0, "tournamentOpen"], roundID: [1, "roundID"], teamType: [2, "teamType"], teamID: [3, "teamID"], courseData: [4, "courseData"], maleTeeColor: [5, "maleTeeColor"], femaleTeeColor: [6, "femaleTeeColor"] }, { onEditingChange: "onEditingChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.open; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.open; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.tournamentOpen; var currVal_4 = _co.roundID; var currVal_5 = _co.teamType; var currVal_6 = _co.team.teamID; var currVal_7 = _co.courseData; var currVal_8 = _co.maleTeeColor; var currVal_9 = _co.femaleTeeColor; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.team.title; _ck(_v, 9, 0, currVal_2); }); }
export function View_TeamRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "team-renderer", [], null, null, null, View_TeamRendererComponent_0, RenderType_TeamRendererComponent)), i1.ɵdid(1, 49152, null, 0, i9.TeamRendererComponent, [i1.ElementRef], null, null)], null, null); }
var TeamRendererComponentNgFactory = i1.ɵccf("team-renderer", i9.TeamRendererComponent, View_TeamRendererComponent_Host_0, { team: "team", roundID: "roundID", teamType: "teamType", femaleTeeColor: "femaleTeeColor", maleTeeColor: "maleTeeColor", courseData: "courseData", tournamentOpen: "tournamentOpen" }, { onEditingChange: "onEditingChange" }, []);
export { TeamRendererComponentNgFactory as TeamRendererComponentNgFactory };
