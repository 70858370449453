import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataSourceResult, Profile, PlayerCreateDetail } from '../../../models';
import { FlightMemberService, PlayerPoolService, BrandingService, LeaderboardService } from '../../../services';
import { SelectItem, Paginator } from 'primeng/primeng';
import { Observable, BehaviorSubject, Subject, Observer, timer, combineLatest } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpServices } from 'src/app/services/HttpServices';

@Component({
    selector: 'playerpool',
    templateUrl: 'playerpool.html',
    styleUrls: ['playerpool.scss']
})

export class PlayerPool implements OnInit, OnDestroy {
    @Input() competitionID: number;
    @Input() roundID: number;
    playerSearchResults: Observable<Array<PlayerCreateDetail>>;
    playerSearchResultStored: Array<PlayerCreateDetail>;

    playerSearchText = new FormControl('');
    searchScope: string = 'Pool';
    totalCount: number = 0;
    filteredCount: number = 0;
    searchInProgress: boolean = false;
    startPageCountObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    searchScopeObservable: BehaviorSubject<string> = new BehaviorSubject<string>('Pool');
    searchScopeSelect: SelectItem[] = [];
    searchTextObservable: Observable<string>;
    searchTextObserver: Observer<string>;
    poolObservable: Observable<Profile[]>;
    poolObserver: Observer<Profile[]>;
    public $stop: Subject<boolean> = new Subject<any>();
    @ViewChild(Paginator, { static: false }) paginator: Paginator;

    lastPage: number = 0;

    constructor(public flightMemberService: FlightMemberService,        
        public playerPoolService: PlayerPoolService,
        private brandingService: BrandingService,
        private leaderboardService: LeaderboardService,
        private httpService : HttpServices) {
        var self = this;

        //Maybe we have this value in the parent component?? so maybe we can save an access to the API.
        this.brandingService.loadBranding().subscribe(branding => {
            this.createSearchScope(branding.isAgent);
        });
        self.searchTextObservable = new Observable<string>(observer => {
            self.searchTextObserver = observer;
        });
        self.poolObservable = new Observable<Profile[]>(observer => {
            self.poolObserver = observer;
        });

        timer(0, 50).subscribe(() => {
            self.searchTextObserver.next(self.playerSearchText.value);
        });
    }

    createSearchScope(agent: boolean): void {
        this.searchScopeSelect.push({ label: 'Pool', value: 'Pool' });
        this.searchScopeSelect.push({ label: 'Members', value: 'Members' });        
        this.searchScopeSelect.push({ label: 'VPAR', value: 'VPAR' });
    }

    ngOnInit() {
        var self = this;
        this.playerPoolService.playerPool$
            .pipe(takeUntil(this.$stop))
            .subscribe(a => {
                if (this.searchScope === 'Pool') {
                    self.poolObserver.next(a);
                }
            });

        combineLatest(
            self.searchScopeObservable.pipe(distinctUntilChanged()),
            self.searchTextObservable.pipe(distinctUntilChanged(), debounceTime(500)),
            self.startPageCountObservable,
            self.poolObservable.pipe(debounceTime(50))
        )
        .pipe(
            switchMap(inputs => this.search(<string>inputs[0], <string>inputs[1], <number>inputs[2])),
            map((response: DataSourceResult) => {
                    let result: Array<PlayerCreateDetail> = [];
                    if (response.data) {
                        response.data.forEach((profile) => {
                            result.push(self.convertProfileToPlayerCreateDetail(profile));
                        });
                    }
                    response.data = result;
                    return response;
                }),
            takeUntil(this.$stop)
        )
        .subscribe(result => {
            self.totalCount = result.totalCount;
            self.filteredCount = result.filteredCount;
            self.playerSearchResultStored = result.data;
            self.searchInProgress = false;
        });

        self.searchScopeObservable
            .pipe(takeUntil(this.$stop))
            .subscribe(a => {
                this.clearSearchReults();
            });
    }

    clearSearchReults() {
        if (this.paginator != null) {
            this.paginator.changePageToFirst(null);
        }
        this.totalCount = 0;
        this.filteredCount = 0;
        this.searchInProgress = false;
        this.startPageCountObservable.next(0);
        this.playerSearchResultStored = new Array<PlayerCreateDetail>();
    }

    searchScopeChanged(value: string) {
        this.searchScopeObservable.next(value);
    }

    convertProfileToPlayerCreateDetail(profile: Profile): PlayerCreateDetail {
        var playerCreateDetail: PlayerCreateDetail = jQuery.extend(new PlayerCreateDetail(), profile);
        if (this.searchScope !== 'Pool') {
            playerCreateDetail['searchPlayer'] = true;
        }
        playerCreateDetail.competitionID = +this.competitionID;
        return playerCreateDetail;
    }

    DropFlightMemberOnPool($event) {
        var JSONdropped = $event.dataTransfer.getData("flightmember");
        if (JSONdropped) {
            var dropped = JSON.parse(JSONdropped);
            this.flightMemberService.DropFlightMemberOnPool(this.competitionID, dropped);
            this.leaderboardService.recalculateLeaderboard(this.roundID).subscribe({ error: e => console.error(e) });
        }
    }
    loadData(event) {
        this.startPageCountObservable.next(event.first);
        this.lastPage = event.first;
    }

    reloadData() {
        setTimeout(() => {
            this.startPageCountObservable.next(this.lastPage);
        }, 2000);
        
    }

    //Refactor to service
    search(searchScope: string, term: string, startRow: number = 0, rows: number = 10): Observable<DataSourceResult> {
        this.searchInProgress = true;
        let params = new HttpParams()
                .set('Take', rows.toString())
                .set('Skip', startRow.toString())
                .set('Term', term)
                .set('CompetitionID', this.competitionID.toString())
                .set('RoundID', this.roundID.toString())
                .set('SearchScope', searchScope);
        return this.httpService
            .getTyped<DataSourceResult>(`${environment.apiUrl}/api/Profiles`, null, params );
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
}
