<div class="row" id="table-section">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="panel radius">
            <div class="panel-header bg-primary" id="golfer-header">
                <h3 style="width:250px;">
                    <a routerLink="/golfers" routerLinkActive="active">
                        <i class="fa fa-chevron-circle-left" aria-hidden="true" style="color: #e65c24"></i>
                    </a>
                    <strong>MEMBER </strong> OVERVIEW
                </h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-4">
                <div class="panel panel-default" id="panel-card">
                    <div class="panel-body p-10">
                        <div class="text-center col-xs-4">
                            <img [src]="fullPath" class="img-responsive m-t-10 golfer-img">
                        </div>
                        <div class="clearfix col-xs-8" style="margin-top:-10px;">
                            <h2 class="c-dark w-600" style="margin:0px; margin-bottom: 10px">{{golfer?.displayName}}</h2>
                            <span style="margin-right: 10px">Profile Index: {{golfer?.handicap}}</span><div class="badge badge-warning clearfix" *ngIf="golfer?.vparMember">VPAR MEMBER</div>
                            <!--<div class="badge badge-primary clearfix">CLUB MEMBER</div>-->
                            <p class="c-gray  m-b-20" style="margin-top:10px;"><i class="icon-envelope p-r-10"></i>{{golfer?.emailAddress}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2 col-sm-4 col-xs-12">
                <div class="panel radius">
                    <div class="panel-content widget-info" style="padding-bottom: 8px;">
                        <div class="row">
                            <div class="left">
                                <i class="fa fa-trophy bg-green"></i>
                            </div>
                            <div class="right">
                                <p class="number countup">{{BestRound}}</p>
                                <p class="text">Best Round</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-12">
                <div class="panel radius">
                    <div class="panel-content widget-info" style="padding-bottom: 8px;">
                        <div class="row">
                            <div class="left">
                                <i class="fa fa-frown-o bg-red"></i>
                            </div>
                            <div class="right">
                                <p class="number countup">{{WorstRound}}</p>
                                <p class="text">Worst Round</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-12">
                <div class="panel radius">
                    <div class="panel-content widget-info" style="padding-bottom: 8px;">
                        <div class="row">
                            <div class="left">
                                <i class="fa fa-line-chart bg-blue"></i>
                            </div>
                            <div class="right">
                                <p class="number countup">{{AverageRound}}</p>
                                <p class="text">Avrg. Score</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-12">
                <div class="panel radius">
                    <div class="panel-content widget-info" style="padding-bottom: 8px;">
                        <div class="row">
                            <div class="left">
                                <i class="fa fa-pie-chart bg-blue"></i>
                            </div>
                            <div class="right">
                                <p class="number countup">{{TotalCompetitions}}</p>
                                <p class="text">Total Rounds</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel radius">
                    <div class="panel-content">
                        <p-dataTable [emptyMessage]="emptyMessage" [value]="LastTenRounds" selectionMode="single" class="table table-dynamic" [rows]="10" [paginator]="true" [responsive]="true" *ngIf="CONGU">
                            <p-column field="created" header="Date" [sortable]="true">
                                <ng-template pTemplate="body" let-round="rowData">
                                    {{jsonToDate(round.created)}}
                                </ng-template>
                            </p-column>
                            <p-column header="Rnd" field="viewOrder"> </p-column>
                            <p-column header="Tournament" field="title" [style]="{'width':'150px'}"> </p-column>
                            <p-column header="Course" field="courseTitle" [style]="{'width':'150px'}"></p-column>
                            <p-column header="SSS" field=""></p-column>
                            <p-column header="SSC" field=""></p-column>
                            <p-column header="Ident" field=""></p-column>
                            <p-column header="Adj Stab" field=""></p-column>
                            <p-column header="Adj Gross" field=""></p-column>
                            <p-column header="Game HCP" field="handicap"></p-column>
                            <p-column header="Par" field="coursePar"></p-column>
                            <p-column header="Gross Diff" field=""></p-column>
                            <p-column header="Net Diff" field=""></p-column>

                            <p-column header="Gross" field="gross"></p-column>
                            <p-column header="NET" field="net"></p-column>
                            <p-column header="Results" field="position"></p-column>
                            <p-column header="H/Cap Adj" field=""></p-column>
                            <p-column header="Rev H/Cap" field=""></p-column>
                            <p-column header="Play H/Cap" field=""></p-column>

                        </p-dataTable>
                        <p-dataTable [emptyMessage]="emptyMessage" [value]="LastTenRounds" selectionMode="single" class="table table-dynamic" [rows]="10" [paginator]="true" [responsive]="true" *ngIf="GHIN">
                            <p-column header="Type" field="gameTypeText"> </p-column>
                            <p-column field="created" header="Date" [sortable]="true">
                                <ng-template pTemplate="body" let-round="rowData">
                                    {{jsonToDate(round.created)}}
                                </ng-template>
                            </p-column>
                            <p-column header="Tournament" field="title" [style]="{'width':'150px'}"> </p-column>
                            <p-column header="Course" field="courseTitle" [style]="{'width':'150px'}"></p-column>
                            <p-column header="Par" field="coursePar"></p-column>
                            <p-column header="Game HCP" field="handicap"></p-column>
                            <p-column header="Gross" field="gross"></p-column>
                            <p-column header="NET" field="net"></p-column>
                            <p-column header="CR/Slope" field="crSlope"></p-column>
                            <p-column header="Diff" field="scoreDifferential"></p-column>
                        </p-dataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
