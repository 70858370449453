import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class DeviceService {
  result$: Observable<boolean>;

  constructor(
    public httpService: HttpServices,
    private toastr: ToastrService
  ) {}

  refreshAll(roundID: string): Observable<boolean> {
    var refreshAll = new Subject<boolean>();

    this.httpService
      .get(`${environment.apiUrl}/api/flights/refresh-by-round/${roundID}`)
      .subscribe(
        (data: number) => {
          refreshAll.complete();
          if (data == 404) this.toastr.warning("No devices found");
          else this.toastr.success("All devices refreshed");
        },
        (error) => {
          this.toastr.error("Could not refresh devices");
        }
      );
    return refreshAll;
  }

  refreshByFligh(flightID: string): Observable<boolean> {
    var refreshAll = new Subject<boolean>();

    this.httpService
      .get(`${environment.apiUrl}/api/flights/refresh/${flightID}`)
      .subscribe(
        (data: number) => {
          refreshAll.complete();
          if (data == 404) this.toastr.warning("No devices found");
          else this.toastr.success("Flight devices refreshed");
        },
        (error) => {
          this.toastr.error("Could not refresh devices");
        }
      );
    return refreshAll;
  }
}
