import { Component, OnInit, Injectable, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GolfersServices, ProfileService } from '../../services';
import { Profile, Golfer, GolferMember } from '../../models';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/primeng';

@Component({
    templateUrl: 'golfers-overview.component.html',
    styleUrls: ['./golfers-overview.component.scss']
})

@Injectable()
export class GolfersComponent implements OnInit, OnDestroy {        
    public emptyGolfersMessage: string = "Loading...";
    public profileDisplayed: boolean;
    public displayPlayerDialog: boolean;
    public profile: Profile
    golfers: Golfer[] = [];
    golfersFiltered: Golfer[] = [];
    $stop: Subject<boolean> = new Subject<any>();
    public displayBulkUpload: boolean = false;
    public golferMembers: GolferMember[];
    public selectedSort: string = 'displayName';
    duplicateGolfers:string[]=[];
    newGolfers:string[]=[];
    addedGolfers:string[]=[];
    

    constructor(private activatedRoute: ActivatedRoute, public router: Router,
        public golfersServices: GolfersServices, private confirmationService: ConfirmationService,
        private profileService: ProfileService) {
    }
    

    ngOnInit() {        

        this.activatedRoute.url.pipe(takeUntil(this.$stop)).subscribe(a => {
            //Used to hide profiles list if we are viewing a single profile
            this.profileDisplayed = this.activatedRoute.firstChild != null;
        });

        this.golfersServices.Post$.pipe(takeUntil(this.$stop)).subscribe((data) => {
            this.golfers = data;
            this.handleSort();
            this.emptyGolfersMessage = "No golfers found";
        });

        this.golfersServices.loadAll();
        this.golfersServices.golferMemberProcessed$
            .pipe(
                tap((golferMember: GolferMember) => {
                   if(this.golferMembers != null && this.golferMembers.length > 0) {
                       const index = _.findIndex(this.golferMembers, golfer => golfer.email === golferMember.email);
                      this.golferMembers[index].status = golferMember.status;
                    this.golferMembers = [...this.golferMembers];

                   }
                   if(golferMember != null){
                    if(golferMember.status == "Added"){
                      this.addedGolfers.push(golferMember.email);
                     }
                     if(golferMember.status == "Already Member"){
                      this.duplicateGolfers.push(golferMember.email)
                     }
                     if(golferMember.status == 'No Profile'){
                      this.newGolfers.push(golferMember.email);
                     }
                  }
                }),
                takeUntil(this.$stop)
            )
            .subscribe();
    }
    addGolfer() {        
        this.profile = new Profile();
        this.golfersServices.startConnection();
        this.displayPlayerDialog = true;
        this.duplicateGolfers = [];
        this.newGolfers = [];
        this.addedGolfers = [];
    }

    loadProfile(event) {
        var profile = <Profile>event.data;
        var profileRoute = ["golfers", profile.profileID, "profile"];
        this.router.navigate(profileRoute);
    }

    handleClosePlayerDialog() {
        this.displayPlayerDialog = false;
      }
    ngOnDestroy() {
        //Cleanup using takeUntil
        this.$stop.next(true);
    }

    removeGolfer($event, golfer) {
        $event.stopPropagation();

        this.confirmationService.confirm({
            message: `Are you sure that you want to remove this golfer: ${golfer.emailAddress}?`,
            accept: () => {
                //Actual logic to perform a confirmation
                this.golfersServices.removeGolfer(golfer)
                .pipe(
                    tap(() => console.log("Golfer removed."))
                )
                .subscribe();
            }
        });


    }

    displayChange(event) {
        this.displayBulkUpload = event;
    }

    showBulkUpload(emails: GolferMember[]) {
        // this.golfersServices.startConnection();
        this.displayBulkUpload = true;
        this.golferMembers = emails;

    }

    showProfilePicture(golfer: Golfer) {
        return this.profileService.showProfilePicture(<Profile>golfer);
    }

    sortGolfers() {
        this.handleSort();
    }

    private handleSort(): void {
        this.golfersFiltered =  _.sortBy(this.golfers, this.selectedSort);
    }
    
}
