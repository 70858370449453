<div class="row">
    <div class="col-xs-12">
        <div class="panel">
            <div class="panel-content">
                <div class="row text-right">
                    <div class="header-group border-bottom" style="margin:5px 15px 0px 15px">
                        <div class="text-capitalize headerText"><span class="bold">TRACKING</span> </div>
                        <div *ngIf="lastUpdateDate !== ''" class="lastUpdated">last updated: {{lastUpdateDate}}</div>
                    </div>
                </div>
                <div class="row p-t-20 players-list-container">
                    <p-dataTable [emptyMessage]="emptyMessage" [value]="trackingData" class="table table-dynamic" [rows]="10" [paginator]="true" expandableRows="true" selectionMode="single">
                        <p-column field="teeTime" header="Tee Time" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{getTime(trackingData.teeTime)}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="startHole" header="Start Hole" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{trackingData.startHole}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="teamName" header="Team Name" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{trackingData.teamName}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="thru" header="Thru" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{trackingData.thru}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="currentHole" header="Current Hole" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{checkFlightFinished(trackingData.thru,trackingData.currentHole)}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="averageTimePerHole" header="Time per hole" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{getHoursMinutes(trackingData.averageTimePerHole)}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="paceOfPlay" header="POP" [sortable]="true">
                            <ng-template pTemplate="body" let-trackingData="rowData">
                                <span class="edit-cell">{{getHoursMinutes(trackingData.paceOfPlay)}}</span>
                            </ng-template>
                        </p-column>

                    </p-dataTable>
                </div>
            </div>
        </div>
    </div>
</div>