export const environment = {
  isLive: true,
  production: true,
  apiUrl: "https://clubplatform-api-live.azurewebsites.net",
  competitionApiUrl: "https://competitionservice.vpar.com",
  apiVpar: "api.vpar.com",
  siteUrl: "www.vpar.com",
  cdnUrl: "vpar.blob.core.windows.net",
  imagesCDNUrl: "https://vpar.blob.core.windows.net/clubplatform/vpar/",
  leaderboardAPIUrl: "https://leaderboards.vpar.com",
  leaderboardRecalculateUrl: "https://leaderboardcalculation-live.azurewebsites.net",
  cryptoSecret : "wpencrypter11239",
  venuesApiUrl: "https://live-venueservice.azurewebsites.net",
  overrideCompetitionHost : 0
};
