import { Component, Input } from '@angular/core';
import { FlightTeamGroup, FlightMember, Team } from '../../models';
import { FlightMembersService } from '../../services';
import { Validators, FormControl } from '@angular/forms';
import { UniversalValidators } from 'ngx-validators'

@Component({
    selector: 'flight-team-group',
    templateUrl: 'flight.team.group.renderer.component.html',
    styleUrls: ['flight.team.group.renderer.component.scss']
})
export class FlightTeamGroupRendererComponent {
    @Input() flightTeamGroup: FlightTeamGroup = new FlightTeamGroup(false);
    @Input() teams: Array<Team>;
    @Input() competitionID: number;

    editingHCP: boolean = false;
    handicapStep: number = 1;
    teamHandicap = new FormControl(1);
    decimalScoring(value: boolean) {
        if (value) {
            this.handicapStep = 0.1;
            if (this.teamHandicap) {
                this.teamHandicap.setValidators([Validators.required, UniversalValidators.isNumber]);
            }
        } else {
            this.handicapStep = 1;
            if (this.teamHandicap) {
                this.teamHandicap.setValidators([Validators.required, UniversalValidators.isNumber]);
            }
        }
    }


    constructor(public flightMembersService: FlightMembersService) {
    }

    public editHCP() {
        this.decimalScoring(this.flightTeamGroup.decimalScoring);
        this.editingHCP = true;
        this.teamHandicap.setValue(this.flightTeamGroup.mergedValue);
    }

    public cancelHCPEdit() {
        this.editingHCP = false;
        this.teamHandicap.setValue(this.flightTeamGroup.mergedValue);
    }

    public saveHCP() {
        if (this.teamHandicap.valid) {
            this.editingHCP = false;
            for (let flightMember of this.flightTeamGroup.flightMembers) {
                if (this.flightTeamGroup.showShots) {
                    flightMember.shots = this.teamHandicap.value;
                } else {
                    flightMember.handicap = this.teamHandicap.value;
                }
            }
            //Filter out only real flight members
            this.flightMembersService.updateMembers(this.competitionID.toString(), this.flightTeamGroup.roundID.toString(), this.flightTeamGroup.flightMembers);
        }
    }
}
