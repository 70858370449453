import {Component, Input } from '@angular/core';

@Component({
    selector: 'confirm-dialog',
    styleUrls:['confirm-dialog.component.scss'],
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialog {
    display: boolean = false;
    confirmed: boolean = false;
    @Input() titleText: string;
    @Input() height: number = 160;
    @Input() message: string = "message..";
    @Input() subMessage: string;
    @Input() okButtonText: string = "Ok";
    confirmPositiveFunction: Function;
    confirmNegativeFunction: Function;

    showDialog(confirmPositiveFunction: Function) {
        this.confirmPositiveFunction = confirmPositiveFunction;
        this.display = true;
    }
    showDualDialog(confirmPositiveFunction: Function, confirmNegativeFunction: Function) {
        this.confirmPositiveFunction = confirmPositiveFunction;
        this.confirmNegativeFunction = confirmNegativeFunction;
        this.display = true;
    }

    private hideDialog() {
        this.display = false;
    }
    cancel() {
        if (this.confirmNegativeFunction){
            this.confirmNegativeFunction();
        }
        this.hideDialog();
    }
    confirm() {
        this.confirmPositiveFunction();
        this.hideDialog();
    }
}
