import { Component, Input, OnInit  } from '@angular/core';
import { Team, FlightTeamGroup } from '../../models';
import { SelectItem } from 'primeng/primeng';
import { FlightMembersService } from '../../services';
import { Router } from '@angular/router';

@Component({
    selector: 'team-header',
    templateUrl: 'team.header.renderer.component.html',
    styleUrls: ['team.header.renderer.component.scss']
})
export class TeamHeaderRendererComponent {

    constructor(private flightMembersService: FlightMembersService,private router: Router) {
    }

    private _flightTeamGroup: FlightTeamGroup;
    team: Team;
    private playersCount: number;
    allowReassign: boolean = true;
    isEditMode: boolean = true;
    changeTeamToggle: boolean = false;
    private _teams: Array<Team> = [];
    teamSelect: Array<SelectItem> = [];

    @Input() set flightTeamGroup(value: FlightTeamGroup) {
        this._flightTeamGroup = value;
        this.team = value.team;
        this.playersCount = value.flightMembers.filter(flightMember => flightMember.flightMemberID > 0).length;
        this.allowReassign = value.allowReassign && this.playersCount > 0;
    }

    get flightTeamGroup(): FlightTeamGroup {
        return this._flightTeamGroup;
    }

    get teams(): Array<Team> {
        return this._teams;
    }

    @Input() set teams(value: Array<Team>) {
        this._teams = value;
        let teamsSelect = [];
        teamsSelect.push({ value: '', label: 'No Team' });
        teamsSelect.push.apply(teamsSelect, _.chain(value).sortBy(a => a.title).map(team => {
            return { value: team.teamID, label: team.title };
        }).value());
        this.teamSelect = teamsSelect;
    }

    ngOnInit(): void {
        this.checkURLContains('teetimes');
      }

    changeTeam() {
        this.changeTeamToggle = true;
    }

    confirmChangeTeam() {
        this.changeTeamToggle = false;
        var flightMembersIDs = this._flightTeamGroup.flightMembers.map(a => { return a.flightMemberID });
        this.flightMembersService.teamSwap(this._flightTeamGroup.competitionID.toString(), this._flightTeamGroup.roundID.toString(), this.team.teamID.toString(), flightMembersIDs);
    }

    cancelChangeTeam() {
        this.changeTeamToggle = false;
    }

    checkURLContains(value: string): void {
        if (this.router.url.includes(value)) {
            this.isEditMode = false;
        }
      }
}
