/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./PrintType";
var styles_PrintType = [".filled[_ngcontent-%COMP%] {\n    background:#556973;\n}\n.printBox[_ngcontent-%COMP%] {\n    border: 3px solid #556973;\n    width: 90px;\n    height: 50px;\n}\n\n.printType[_ngcontent-%COMP%] {\n    display: inline-block;\n    margin-right: 35px;\n}\n\n    .printType[_ngcontent-%COMP%]   span[_ngcontent-%COMP%] {\n        font-weight: bold;\n        margin: 0 auto;\n    }"];
var RenderType_PrintType = i0.ɵcrt({ encapsulation: 0, styles: styles_PrintType, data: {} });
export { RenderType_PrintType as RenderType_PrintType };
export function View_PrintType_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "printType"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "printBox center-block cursor-pointer"]], [[2, "filled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "center-block text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detail.selected; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.detail.label; _ck(_v, 3, 0, currVal_1); }); }
export function View_PrintType_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "PrintType", [], null, null, null, View_PrintType_0, RenderType_PrintType)), i0.ɵdid(1, 49152, null, 0, i1.PrintType, [], null, null)], null, null); }
var PrintTypeNgFactory = i0.ɵccf("PrintType", i1.PrintType, View_PrintType_Host_0, { detail: "detail" }, { detailUpdated: "detailUpdated" }, []);
export { PrintTypeNgFactory as PrintTypeNgFactory };
