<li pDraggable="dd" pDroppable="dd" (onDrag)="onDragEvent($event)" (onDragEnter)="onDragEnter($event)" (onDragOver)="onDragOver($event)" (onDragLeave)="onDragLeave($event)" (onDrop)="dropExistingBall($event)" class="dd-item dd3-item tee-list" [ngClass]="{mergedHandicap: mergedCells, dragHover: draggedOver}"
    *ngIf="flightMember.flightMemberID!=0" (onDragStart)="dragStart($event)" (onDragEnd)="dragEnd($event)">
    <div class="dd-handle dd3-handle tee-drag"></div>
    <div class="container dd3-content tee-row" *ngIf="flightMember.profile!=null">
        <div class="cell2">
            <div class="wrap playerName">
                {{flightMember.profile.firstName}} {{flightMember.profile.surname}}
            </div>
        </div>
        <div class="cell3" *ngIf="!mergedCells">
            <span class="badge badge-primary pull-right" *ngIf="!showShots">HCP {{flightMember.handicap}}</span>
            <span class="badge badge-primary pull-right" *ngIf="showShots && (flightMember.shots||flightMember.shots==0)">Shots {{flightMember.shots}}</span>
        </div>
    </div>
    <div class="dd3-content" *ngIf="flightMember.profile==null">
        No profile
    </div>
</li>
<li (onDragEnter)="onDragEnter($event)" (onDragOver)="onDragOver($event)" (onDragLeave)="onDragLeave($event)" *ngIf="flightMember.flightMemberID==0" pDroppable="dd" (onDrop)="dropEmpty($event)" class="dd-placeholder add-player" [ngClass]="{mergedHandicap: mergedCells, dragHover: draggedOver}"
    (click)="addFlightMember()">
    <span>
        Add Player
        <span class="fa-stack">
            <i class="fa fa-circle-o fa-stack-2x"></i>
            <i class="fa fa-plus fa-stack-1x"></i>
        </span>
    </span>
</li>
