import { catchError, filter, map, share, shareReplay } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Golfer, GolferMember, IBranding, Profile, Results } from "../models";
import { BehaviorSubject, Observable, Observer, Subject } from "rxjs";
import { UploadService } from "./uploadservice";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as signalR from "@microsoft/signalr";
import { Member } from "../models/GolferMember";
import { HttpServices } from "./HttpServices";
import { AppCookieService } from "./CookiesServices";

@Injectable()
export class GolfersServices {
  Post$: Observable<Golfer[]>;
  Golfer$: Observable<Golfer>;
  LastRounds$: Observable<Results[]>;
  Branding$: Observable<IBranding>;

  private PostObserver: Observer<Golfer[]>;
  private GolferObserver: Observer<Golfer>;
  private LastTenRoundsObserver: Observer<Results[]>;
  private BrandingObserver: Observer<IBranding>;
  headers: HttpHeaders = new HttpHeaders();
  public golferMemberProcessed$: BehaviorSubject<GolferMember> =
    new BehaviorSubject<GolferMember>(null);

  private hubConnection: signalR.HubConnection;

  private _dataStore: {
    golfers: Golfer[];
  };

  private _dataStoreGolfer: {
    golfer: Golfer;
  };

  private _dataStoreLastTenRounds: {
    result: Results[];
  };

  constructor(    
    private uploadService: UploadService,
    private toastr: ToastrService,    
    public httpService: HttpServices,
    private cookieService : AppCookieService
  ) {
    this.Golfer$ = new Observable<Golfer>(
      (observer) => (this.GolferObserver = observer)
    ).pipe(share());
    this.Post$ = new Observable<Golfer[]>(
      (observer) => (this.PostObserver = observer)
    ).pipe(share());
    this.LastRounds$ = new Observable<Results[]>(
      (observer) => (this.LastTenRoundsObserver = observer)
    ).pipe(share());
    this.Branding$ = new Observable<IBranding>(
      (observer) => (this.BrandingObserver = observer)
    ).pipe(share());    

    this._dataStore = { golfers: [] };
    this._dataStoreLastTenRounds = { result: [] };
    this._dataStoreGolfer = { golfer: null };
  }

  loadAll(): void {
    var url = `${environment.apiUrl}/api/Golfers`;
    this.httpService.get(url).subscribe(
      (data: Array<Golfer>) => {
        this._dataStore.golfers = data;
        this.PostObserver.next(this._dataStore.golfers);
        console.log("Complete Get Golfers");
      },
      (error) => console.log("Could not load Golfers.")
    );
  }  

  loadGolferDetails(id: any): void {
    let url = `${environment.apiUrl}/api/Golfers/Profile/${id}`;
    this.httpService.get(url).subscribe(
      (data: Golfer) => {
        this._dataStoreGolfer.golfer = data;
        this.GolferObserver.next(this._dataStoreGolfer.golfer);
        console.log("Complete Get Golfer details");
      },
      (error) => console.log("Could not load Golfer details.")
    );
  }
  getGolfersByEmail(email: string): any {
    let url = `${environment.apiUrl}/api/Profiles/email/${email}`;
    this.httpService
      .get(url)
      .pipe(
        catchError((err) => {
          console.log(err.status);
          if (err.status == 404 || err.status == 500) {
            this.toastr.error("Golfer not found");
          }
          return err;
        })
      )
      .subscribe(
        (data: Golfer) => {
          this._dataStoreGolfer.golfer = data;
          this.GolferObserver.next(this._dataStoreGolfer.golfer);
          console.log("Complete Get Golfer By Email");
          return data;
        },
        (error) => console.log("Could not load Golfer By Email.")
      );
    return null;
  }
  checkGolferIsMember(
    email: string,
    competitionId: number
  ): Observable<Member> {
    try {
      let url = `${environment.apiUrl}/api/Golfers/member/${email}/competition-host/${competitionId}`;
      let member$ = this.httpService.getTyped<Member>(url);
      return member$;
    } catch (e) {
      console.error(e);
    }
  }
  addByEmail(
    emailAddress: string,
    competitionHostId: number
  ): Observable<Golfer> {
    var observable = new Subject<Golfer>();
    this.httpService
      .post(`${environment.apiUrl}/api/Golfers/AddByEmail`, {
        emailAddress,
        competitionHostId,
      })
      .subscribe(
        (data: Golfer) => {
          this.toastr.success(`${emailAddress} has been added as a member.`);
          this._dataStore.golfers.push(data);
          this.PostObserver.next(this._dataStore.golfers);
          observable.next(data);
          observable.complete();
          return data;
        },
        (error) => {
          console.log(error);
          if (error.status == 400) this.toastr.error(error.error);
          console.log("Could not create profile.");
        }
      );
    return observable;
  }

  addByEmailBulk(members: GolferMember[]) {
    const emails = members.map((item) => ({ emailAddress: item.email }));
    this.httpService
      .post(`${environment.apiUrl}/api/Golfers/AddByEmailBulk`, emails)
      .subscribe();
  }

  inviteAll(emailsList: string[]): Observable<void> {
    const observable = new Subject<void>();

    this.httpService.post(`${environment.apiUrl}/api/Golfers/send-invites`, {emails : emailsList}).subscribe(
      (r) => {         
        this.toastr.success('Invitations sent successfully.');
        observable.next();
        observable.complete();
      },
      (error) => {
        console.log(error);
        if (error.status === 400) {
          this.toastr.error(error.error);
        }
        console.log('Could not send invitations.');
      }
    );

    return observable.asObservable();
  }

  removeGolfer(golfer: Golfer): Observable<void> {
    var observable = new Subject<void>();
    this.httpService
      .delete(
        `${environment.apiUrl}/api/Golfers/${golfer.profileID}`        
      )
      .subscribe(
        () => {
          this.toastr.success(
            `${golfer.emailAddress} has been removed as a member.`
          );
          this._dataStore.golfers = _.filter(
            this._dataStore.golfers,
            (p) => p.profileID !== golfer.profileID
          );
          this.PostObserver.next(this._dataStore.golfers);
          observable.next();
          observable.complete();
        },
        (error) => {
          console.log(error);
          if (error.status == 400) this.toastr.error(error.error);
          console.log("Could not remove profile.");
        }
      );
    return observable;
  }

  uploadDocument(files: File[]): Observable<any> {
    this.toastr.info("Uploading members. This may take some time.");

    var fileUploadUrl = `${environment.apiUrl}/api/golfers/uploadmembers`;

    return this.uploadService.makeFileRequest(fileUploadUrl, null, files);
  }

  loadLastTenRounds(id: any): void {
    let url = `${environment.apiUrl}/api/Golfers/LastTenRounds/${id}`;
    this.httpService.get(url).subscribe(
      (data: Array<Results>) => {
        this._dataStoreLastTenRounds.result = data;
        this.LastTenRoundsObserver.next(this._dataStoreLastTenRounds.result);
        console.log("Complete Get Last Ten Rounds");
      },
      (error) => console.log("Could not load Last Ten Rounds.")
    );
  }

  public startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/upload`, {
        accessTokenFactory: () => this.cookieService.get('token')
      })
      .withAutomaticReconnect()
      .build();
    this.hubConnection
      .start()
      .then(() => console.log("Connected to upload hub."))
      .catch((err) => console.log("Error while starting connection: " + err));

    this.hubConnection.on("golferMemberProcessed", (data) => {
      var observable = new Subject<Golfer>();
      const golferMember: GolferMember = {
        email: data.email,
        status: data.status,
      };
      this.golferMemberProcessed$.next(golferMember);

      if (data.status === "Added") {
        this._dataStore.golfers.push(data.golfer);
        this.PostObserver.next(this._dataStore.golfers);
        observable.next(data);
        observable.complete();
      }
    });

    this.hubConnection.on("golferMemberUploadFinished", (data) => {
      this.toastr.success("Golfer members upload finished.");
    });
  }

  public stopConnection() {
    this.hubConnection
      .stop()
      .then(() => console.log("Stopped connection to upload hub."))
      .catch((err) => console.log("Error while stopping connection: " + err));
  }
}
