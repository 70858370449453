import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LeaderboardMediaService, UploadService, LeaderboardService, DeviceAdvertService } from '../../../services';
import { TournamentCreateLeaderboardComponent } from './tournament-create-leaderboard.component';
import { TournamentOverviewLeaderboardComponent } from './tournament-overview-leaderboard.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LeaderboardEditComponent } from './leaderboard.edit.component';
import { LeaderboardItemDeleteComponent } from './leaderboard.item.delete.component';
import { LeaderboardDisplaySettingsComponent } from './leaderboard.display.settings.component';
import { AdvertEditSettingsComponent } from './advert.edit.component';
import { MediaUpload } from './MediaUpload';
import { LeaderboardPodComponent } from './leaderboard.pod.component';
import { HoleConfigurationComponent } from './hole.configuration.component';
import { DialogModule, DropdownModule, ToggleButtonModule, InputSwitchModule, BlockUIModule, TriStateCheckboxModule, ChipsModule, SpinnerModule, TooltipModule } from 'primeng/primeng';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-img-cropper';
import { DragulaModule } from 'ng2-dragula';
import { ManageMediaModalComponent } from './manage-media-modal.component';
import { AppSharedModule } from '../../../shared/app.shared.module';
import { MediaPopupComponent } from './media-popup.component';
import { MatchplayLeaderboardComponent } from '../tv-leaderboard/matchplay-leaderboard.component';
import { DefaultLeaderboardComponent } from '../tv-leaderboard/default-leaderboard.component';
import { DefaultLeaderboardComponentSociety } from '../tv-leaderboard/default-leaderboardSociety.component';
import { VisibleColumnPipe } from '../../../pipes/visibleColumn.pipe';


@NgModule({
    imports: [ImageCropperModule, RouterModule, FormsModule, ReactiveFormsModule, BrowserModule, DropdownModule, TooltipModule, DialogModule, ToggleButtonModule, InputSwitchModule, BlockUIModule, TriStateCheckboxModule, ChipsModule, SpinnerModule, TranslateModule, DragulaModule, AppSharedModule],    // module dependencies
    declarations: [TournamentCreateLeaderboardComponent, TournamentOverviewLeaderboardComponent, LeaderboardEditComponent, LeaderboardItemDeleteComponent, LeaderboardDisplaySettingsComponent, LeaderboardEditComponent, AdvertEditSettingsComponent, MediaUpload, LeaderboardPodComponent, HoleConfigurationComponent, ManageMediaModalComponent, MediaPopupComponent,
        MatchplayLeaderboardComponent,
        DefaultLeaderboardComponent, DefaultLeaderboardComponentSociety, VisibleColumnPipe],   // components and directives
    exports: [TournamentCreateLeaderboardComponent, TournamentOverviewLeaderboardComponent, LeaderboardEditComponent, LeaderboardItemDeleteComponent, LeaderboardDisplaySettingsComponent, LeaderboardEditComponent, AdvertEditSettingsComponent, MediaUpload, LeaderboardPodComponent, HoleConfigurationComponent,
        MatchplayLeaderboardComponent,
        DefaultLeaderboardComponent, DefaultLeaderboardComponentSociety, VisibleColumnPipe],     // root component
    providers: [LeaderboardService, LeaderboardMediaService, UploadService, DeviceAdvertService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LeaderboardBrandingModule { }
