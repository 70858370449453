<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="loading" *ngIf="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div class="panel-content no-results" *ngIf="statsList.length === 0 && !loading">
                <span>No statistics available yet.</span>
            </div>
            <div class="panel-content stats" *ngIf="statsList.length > 0">
                <div class="content-header">
                    <span class="type gross">GROSS</span>
                    <label class="switch pull-left">
                        <input type="checkbox" [(ngModel)]="statsType" (change)="onStatsTypeChange($event)">
                        <span class="slider round"></span>
                    </label>
                    <span class="type">NET</span>
                    <div style="flex:1">&nbsp;</div>
                    <div class="p-r-10 p-t-5 autocomplete-stats">
                        <i class="fa fa-search autocomplete-search"></i>
                        <p-autoComplete [(ngModel)]="selectedPlayer" [suggestions]="playerList" placeholder="Select a player..." (completeMethod)="searchPlayer($event)" (onSelect)="selectPlayer($event)" (onClear)="clearSelectedPlayer()" (ngModelChange)="selectedPlayerChange($event)"
                            field="salutation"></p-autoComplete>
                    </div>
                </div>
                <div class="stats-container" *ngFor="let stats of statsList">
                    <div class="stats-header">
                        <span class="title">{{stats.title}}</span>
                        <span class="total">{{stats.total}}</span>
                    </div>
                    <div class="stats-content" *ngFor="let player of stats.players; let index = index" [ngClass]="{'selected': stats.searchedPlayer && stats.searchedPlayer.name === player.name}">
                        <div class="pos"><span>{{player.pos}}</span></div>
                        <div class="player">
                            <span>{{player.name}}</span>
                        </div>
                        <div *ngIf="statsType" class="value"><span>{{player.valueNet}}</span></div>
                        <div *ngIf="!statsType" class="value"><span>{{player.valueGross}}</span></div>
                    </div>
                    <div class="stats-content selected" *ngIf="stats.showSearchedPlayer">
                        <div class="pos"><span>{{stats.searchedPlayer.pos}}</span></div>
                        <div class="player">
                            <span>{{stats.searchedPlayer.name}}</span>
                        </div>
                        <div *ngIf="statsType" class="value"><span>{{stats.searchedPlayer.valueNet}}</span></div>
                        <div *ngIf="!statsType" class="value"><span>{{stats.searchedPlayer.valueGross}}</span></div>
                    </div>
                    <div><span class="view-all pull-right" (click)="openAllStatsModal(stats)">View all</span></div>
                </div>
            </div>
        </div>
    </div>
</div>

<stats-modal [(displayStatsDialog)]="displayStatsDialog" [stats]="stats" [statLine]="modalStatLine" [(statsType)]="statsType"></stats-modal>