import { Component, OnInit, ViewChild } from '@angular/core';
import { Team, FlightMember, Round, CourseForScorecard } from '../../../models';
import { CompetitionRouteService, FlightMembersService, TeamsService } from '../../../services';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ScoreTabComponent } from '../../../shared/components/scorecard/index';
import * as moment from 'moment';

@Component({
    templateUrl: 'teams.component.html',
    styleUrls: ['teams.component.scss']
})

export class TournamentOverviewTeamsComponent extends ScoreTabComponent implements OnInit {
    public roundID: string;
    teamType: number;
    public competitionID: string;
    public teams: Array<Team>;
    public tournamentOpen: boolean = true;

    courseHoles: CourseForScorecard;
    round: Round;

    constructor(private competitionRouteService: CompetitionRouteService,
        public flightMembersService: FlightMembersService,
        public teamsService: TeamsService,
        private route: ActivatedRoute) {
            super();
    }

    ngOnInit() {
        this.courseHoles = this.route.parent.snapshot.data['course'];
        this.round = this.route.parent.parent.snapshot.data['round'];
        this.teamType = this.round.teamType;

        this.competitionRouteService.competitionID$.subscribe(competitionID => {
            this.competitionID = competitionID;
        });

        this.competitionRouteService.roundID$.subscribe(roundID => {
            this.roundID = roundID;
        });

        this.competitionRouteService.currentCompetition$.subscribe(comp => {
            if (comp) {
                this.tournamentOpen = (comp.status != 3);
            }
        });

        combineLatest(this.teamsService.teams$, this.flightMembersService.flightMembers$,
            function (teams: Array<Team>, flightMembers: Array<FlightMember>) {
                var currentRoundTeams = new Array<Team>();
                if (teams) {
                    teams.forEach(function (team) {
                        if (_.some(flightMembers, 'teamID', team.teamID)) {
                            currentRoundTeams.push(team);
                        }
                    });
                }
                return currentRoundTeams;
            }).subscribe((data) => {
                // initial status. All scorecards are not being edited
                data.forEach(() => {
                    this.scorecardsEditingStatus.push(false);
                });


                data = data.sort((n1, n2) => {
                    if (n1.title > n2.title) return 1
                    else if (n1.title === n2.title) return 0
                    else return -1
                });

                this.teams = data;
            });
        this.teamsService.loadAll(this.competitionID);
        this.flightMembersService.loadAll(this.competitionID, this.roundID);
    }
    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }
}
