<div class="arrivals-container" [style.background-image]="backgroundImage">
    <div class="no-results" *ngIf="players?.length === 0 && dataLoaded">
        <span>No Tee Times Allocated</span>
    </div>
    <div class="content" *ngIf="players?.length > 0 && dataLoaded">
        <div class="section tournament">
            <div class="section-title">
                <span>TOURNAMENT</span>
            </div>
            <div class="section-content left">
                <div class="text-big">{{arrivalsMedia?.title}}</div>
                <div class="text-small">VENUE:</div>
                <div class="text-big">{{arrivalsMedia?.venue.split(',')[0]}}</div>
                <div class="text-small">COURSE:</div>
                <div class="text-big">{{arrivalsMedia?.venue.split(',')[1]}}</div>
                <div *toggleFeature="'entryCode';offeringLevel:offeringLevel">
                    <div class="text-small" *ngIf="arrivalsMedia?.accessCode !== '' && arrivalsMedia?.accessCode !== null">TOURNAMENT CODE:</div>
                    <div class="text-big code" *ngIf="arrivalsMedia?.accessCode !== ''  && arrivalsMedia?.accessCode !== null">'{{arrivalsMedia?.accessCode}}'</div>
                </div>                
                <div class="vpar-logo">
                    <img src="{{environment.imagesCDNUrl}}vparLogoRGB.svg" alt="" />
                </div>
            </div>
            <div class="section-logo">
                <div class="arrival-logo" *ngIf="arrivalsMedia.mediaLeft !== arrivalsMedia.mediaRight">
                    <img src="{{arrivalsMedia.mediaLeft}}" *ngIf="arrivalsMedia.mediaLeft !== null" alt="" />
                </div>
                <div class="arrival-logo" *ngIf="arrivalsMedia.mediaRight !== null">
                    <img src="{{arrivalsMedia.mediaRight}}" *ngIf="arrivalsMedia.mediaRight !== null" alt="" />
                </div>
            </div>
        </div>
        <div class="section players-arrived m-r-30">
            <div class="section-title">
                <span>PLAYERS ARRIVED</span>
            </div>
            <div class="section-content">
                <div class="header">
                    <div class="title"><i class="fa fa-check-circle"></i>ARRIVED</div>
                    <div class="hole">HOLE</div>
                    <div class="tee-time">TEE TIME</div>
                </div>
                <div class="player-list" [@visibilityChanged]="playerShow">
                    <div class="player" *ngFor="let player of screenPlayers" [ngClass]="{'fixed-height': screenPlayers.length < 10}">
                        <div class="arrived">
                            <i class="fa " [ngClass]="{'fa-check-circle': player.status === 30, 'fa-circle disabled': player.status !== 30}"></i>
                        </div>
                        <div class="info">
                            <img class="profilePicture" [src]="showProfilePicture(player.profile)" />
                            <span>{{player.profile.displayName}} ({{player.handicap}})</span>
                        </div>
                        <div class="hole">
                            <span>{{player.flight.startHole}}</span>
                        </div>
                        <div class="tee-time">
                            <span>{{getTime(player.flight.teeOffTime)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section players-tee-times m-l-30">
            <div class="section-title">
                <span>TEE TIMES</span>
            </div>
            <div class="section-content">
                <div class="header">
                    <div class="title"><i class="fa fa-check-circle"></i>ARRIVED</div>
                </div>
                <div class="tee-times">
                    <div class="tee" [ngClass]="{'max-heigth-tee': teeTimes.length < (isFiveBall ? 3 : 4)}">
                        <div class="front" tee-time [teeTime]="teeTimes[0]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                        <div class="back" tee-time [teeTime]="teeTimes[(this.isFiveBall ? 3 : 4)]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                    </div>
                    <div class="tee" *ngIf="this.teeTimes.length > 1" [ngClass]="{'max-heigth-tee': teeTimes.length < (isFiveBall ? 3 : 4)}">
                        <div class="front" tee-time [teeTime]="teeTimes[1]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                        <div class="back" tee-time [teeTime]="teeTimes[(this.isFiveBall ? 4 : 5)]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                    </div>
                    <div class="tee" *ngIf="this.teeTimes.length > 2" [ngClass]="{'max-heigth-tee': teeTimes.length < (isFiveBall ? 3 : 4)}">
                        <div class="front" tee-time [teeTime]="teeTimes[2]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                        <div class="back" tee-time [teeTime]="teeTimes[(this.isFiveBall ? 5 : 6)]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                    </div>
                    <div class="tee" *ngIf="!isFiveBall && this.teeTimes.length > 3" [ngClass]="{'max-heigth-tee': teeTimes.length < 4}">
                        <div class="front" tee-time [teeTime]="teeTimes[3]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                        <div class="back" tee-time [teeTime]="teeTimes[7]" [gameType]="gameType" [teamType]="teamType">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>