import { Component, Input, OnInit, AfterViewInit, ViewEncapsulation  } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { CompetitionDataService} from '../../../services';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'round-configuration',
    templateUrl: 'round.configuration.component.html',
    styleUrls: ['round.configuration.component.scss']
})
export class RoundConfigurationComponent implements OnInit, AfterViewInit {
    @Input() roundConfiguration: FormGroup;
    @Input() index: number;

    offeringLevel: number;

    constructor(public competitionDataService: CompetitionDataService,
        private route: ActivatedRoute) {
        this.offeringLevel = this.route.parent.snapshot.data['branding'].offeringType;
    }

    modifiedStablefordValues: Array<SelectItem> = [];
    openAcordion: boolean;

    ngOnInit()
    {
        var i: number;
        for (i = 10; i >= -10; i--)
        {
            this.modifiedStablefordValues.push({ value: i, label: i.toString() });
        }
        this.roundConfiguration.controls['useDecimalScoring'].valueChanges.subscribe((value: boolean) => {
            if (!this.competitionDataService.decScoringBroadcasted$)
            {
                this.competitionDataService.useDecimalScoring$.next(value);
            }
            this.competitionDataService.decScoringBroadcasted$ = false;
        });
        
        this.openAcordion = this.roundConfiguration.get('isModifiedRules').value ||
                            this.roundConfiguration.get('forceMinimumRelativeNetScoreToContribute').value ||
                            this.roundConfiguration.get('useDecimalScoring').value 
    }

    ngAfterViewInit()
    {
        $('.popoverInfo').popover({ html: true, toggle: "popover", placement: "bottom", trigger: "focus" }).click(function () { return false });
    }

    onTabOpen(e) {
        this.openAcordion = true;
    }
}


