import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'visibleColumn',
    pure: false,
})

export class VisibleColumnPipe  implements PipeTransform {
    transform(items: Array<any>): Array<any> {
        if (!items) return [];
        return items.filter(it => it.isVisible);
    }
}