<div class="page-content">
    <div class="row" id="table-section">
        <div class="col-lg-12 portlets ui-sortable table-round" >
            <div class="panel" id="table-round">
                <div class="panel-header">
                    <div class="col-md-8">
                        <h3 id="table-header">
                             Tournaments
                        </h3>
                    </div>
                    <div class="ui-widget-header ui-helper-clearfix col-md-4 pull-right p-b-10" style="padding-top:15px">
                        <div class="search-input-container search">
                            <i class="fa fa-search"></i>
                            <input id="search-bar" #gb type="text" pInputText size="50" placeholder="Search Tournaments" [(ngModel)]="filter">

                            <button class="btn" id="newTournament"><img src="../assets/icons/menu-icons/add.svg"><a href="/tournaments/create">New Tournament</a></button>
                            <!--Return later because of the version compatibility-->
                            <!-- <i class="fa fa-search"></i>
                            <input id="search-bar" #gb type="text" pInputText size="50" placeholder="Search Tournaments" [(ngModel)]="filter"> -->

                        </div>
                    </div>
                </div>

                <div class="panel-content tournament-panel">
                    <hr id="no-show-divider"/>
                    <div>
                        <p-dataTable [globalFilter]="gb" [emptyMessage]="emptyMessage" [value]="tournaments" responsiveLayout="stack" [resizableColumns]="true" columnResizeMode="fit" [tableStyle]="{'min-width':'50rem'}" [rows]="20" [paginator]="true" selectionMode="single" [lazy]="true" (onRowSelect)="navigateToTournament($event)" (onLazyLoad)="loadData($event)" [totalRecords]="totalRecords">
                            <p-column field="startDate" header="Date" styleClass="col-date">
                                <ng-template pTemplate="body" let-tournament="rowData">
                                    {{jsonToDate(tournament.startDate)}}
                                </ng-template>
                            </p-column>
                            <p-column field="title" header="Tournament Name" styleClass="format-left"></p-column>
                            <p-column field="courseSummary" header="Course" styleClass="format-left"></p-column>
                            <p-column field="formatSummary" header="Format" styleClass="format-left"></p-column>
                            <p-column field="numberOfPlayers" header="Golfers" styleClass="col-golfers" ></p-column>
                            <p-column field="open" header="Status" styleClass="col-status">
                                <ng-template pTemplate="body" let-tournament="rowData">
                                    <div class="badge alert-danger" *ngIf="!tournament.open">CLOSED</div>
                                    <div class="badge alert-success" *ngIf="tournament.open">OPEN</div>
                                </ng-template>
                            </p-column>
                            <p-column styleClass="col-button" header="Delete">
                                <ng-template pTemplate="body" let-tournament="rowData">
                                    <button type="button" class="btn faremove delete-button" pButton (click)="removeTournament(tournament)"><img src="../assets/icons/menu-icons/delete_2.svg" alt=""></button>
                                </ng-template>
                            </p-column>
                        </p-dataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<confirm-dialog titleText="Remove Tournament" message="Do you wish to remove this tournament?"></confirm-dialog>
