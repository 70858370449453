/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scores.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/tab-menu/tab-menu.component.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "../../../shared/components/tab-menu/tab-menu.component";
import * as i5 from "@angular/router";
import * as i6 from "@angular/cdk/layout";
import * as i7 from "./scores.component";
var styles_ScoresComponent = [i0.styles];
var RenderType_ScoresComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScoresComponent, data: {} });
export { RenderType_ScoresComponent as RenderType_ScoresComponent };
export function View_ScoresComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "panel-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "vpar-tabMenu", [], null, null, null, i2.View_VparTabMenu_0, i2.RenderType_VparTabMenu)), i1.ɵprd(4608, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(6, 638976, null, 0, i4.VparTabMenu, [i5.Router, i6.BreakpointObserver], { model: [0, "model"], activeItem: [1, "activeItem"], disableAutoSelection: [2, "disableAutoSelection"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.activeItem; var currVal_2 = true; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 8, 0); }, null); }
export function View_ScoresComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ScoresComponent_0, RenderType_ScoresComponent)), i1.ɵdid(1, 245760, null, 0, i7.ScoresComponent, [i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScoresComponentNgFactory = i1.ɵccf("ng-component", i7.ScoresComponent, View_ScoresComponent_Host_0, {}, {}, []);
export { ScoresComponentNgFactory as ScoresComponentNgFactory };
