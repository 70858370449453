import { Component, OnInit, Injectable, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/primeng';

@Component({
    templateUrl: 'challenge-overview.component.html',
    styleUrls: ['./challenge-overview.component.scss']
})

@Injectable()
export class ChallengeComponent implements OnInit, OnDestroy {
    ngOnInit() {}
    ngOnDestroy() {}
}