import { Round } from "./round";
import { Venue } from "./venue";
import { Profile } from "./profile";
//import {CompetitionProfileDetail} from './competitionProfileDetail';
export class Competition {
  constructor() {
    this.rounds.push(new Round());
    this.playerPool = new Array<Profile>();
  }
  courseSummary: string;
  title: string;
  startDate: Date;
  competitionID: number;
  firstRoundID: number;
  formatSummary: string;
  venueSelected: Venue;
  venueID: number;
  status: number;
  totalPlayers: number;
  numberOfPlayers: number;
  endDate: Date;
  // tournamentType: number;
  rounds: Array<Round> = new Array<Round>();
  RoundIdsForDeletion: number[] = [];
  playerPool: Array<Profile> = new Array<Profile>();
  isHandicapQualified: boolean = false;
  isTeeAdjustment: boolean = false;
  useDecimalScoring: boolean = false;
  registrationStatus: boolean;
  accessCode: string;
  isOrderOfMerit: boolean = false;
  orderOfMeritStatus: number;
}
