import { HttpHeaders } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./CookiesServices";
import * as i3 from "./login.service";
import * as i4 from "@angular/router";
export class HttpServices {
    constructor(http, cookieService, loginService, router) {
        this.http = http;
        this.cookieService = cookieService;
        this.loginService = loginService;
        this.router = router;
    }
    get(url, passedHeaders = null, params = null) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.get(url, { headers, params });
    }
    getTyped(url, passedHeaders = null, params = null) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.get(url, { headers, params, observe: "body" });
    }
    post(url, body, passedHeaders = null, params = null, responseType) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders) {
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        }
        return this.http.post(url, body, { headers, params, responseType });
    }
    postBlob(url, body, passedHeaders = null, params = null) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders) {
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        }
        return this.http.post(url, body, {
            headers: headers,
            params,
            responseType: "blob",
        });
    }
    postTyped(url, body, passedHeaders = null, params = null, responseType) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.post(url, body, { headers, params, responseType });
    }
    put(url, body, passedHeaders = null, params = null, responseType) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.put(url, body, { headers, params, responseType });
    }
    putTyped(url, body, passedHeaders = null, params = null, responseType) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.put(url, body, { headers, params, responseType });
    }
    delete(url, passedHeaders = null, params = null, responseType) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.delete(url, { headers, params, responseType });
    }
    patch(url, body, passedHeaders = null, params = null, responseType) {
        this.checkToken();
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", `Bearer ${this.cookieService.get("token")}`);
        headers = headers.append("Content-Type", "application/json; charset=utf-8");
        headers = headers.append("CompetitionHostId", this.cookieService.getCompetitionHostIdCookie());
        if (passedHeaders)
            passedHeaders.forEach((header) => {
                headers = headers.append(header.name, header.value);
            });
        return this.http.patch(url, body, { headers, params, responseType });
    }
    checkToken() {
        if (!window.location.href.includes("public")) {
            if (!this.loginService.isLoggedIn()) {
                this.router.navigate(["login"]);
                this.cookieService.remove("token");
            }
        }
    }
}
HttpServices.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpServices_Factory() { return new HttpServices(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppCookieService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.Router)); }, token: HttpServices, providedIn: "root" });
