<p-dialog [(visible)]="show" [header]="headerText" [responsive]="true" [modal]="true" [resizable]="true" [draggable]="false" [width]="715">
    <form novalidate [formGroup]="advertForm" (ngSubmit)="Save(advertForm.value)" #form>
        <div class="row">
            <div class="col-md-12">
                <h3 class="m-t-0">Name</h3>
                <input type="text" pInputText class="form-control" id="lb_Title" name="Title" formControlName="title" autofocus/>
                <div [hidden]="advertForm.get('title').valid" class="alert alert-danger">
                    Title is required
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h3>{{'TOURNAMENT.LEADERBOARD.ADVERT_MEDIA' | translate}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-10">
                        <label>Select or upload an image</label>
                        <select class="form-control input-md" data-style="white" data-placeholder="Select an option..." formControlName="advertMediaID" (change)="SetAdvertImage(null)">
                            <option *ngFor="let p of leaderboardAdvertBackImgSelect" [value]="p.value">{{p.label}}</option>
                        </select>
                        <div *ngIf="!advertForm.get('advertMediaID').valid && submitted" class="alert alert-danger">
                            {{'TOURNAMENT.LEADERBOARD.ADVERT_MEDIA_REQUIRED' | translate}}
                        </div>
                    </div>
                    <div class="col-md-2 p-0 p-t-25">
                        <label class="btn btn-dark m-b-5" for="a-file-selector">
                            <input id="a-file-selector" type="file" style="display:none;" (change)="onChangeUpload($event)" formControlName="advertUpload" />
                            <span><i class="fa fa-upload"></i></span>
                        </label>
                    </div>
                </div>
                <div class="row p-t-20">
                    <div class="col-md-10">
                        <label class="Timer_OverLayLbl" for="">Display Timer in Seconds:</label>
                        <input type="number" pInputText class="Skins_OverInput" id="lb_DisplayTimer" name="timer" formControlName="timer" />
                        <div *ngIf="!advertForm.get('timer').valid && submitted" class="alert alert-danger">
                            Timer is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img [src]="advertPreview" class="img-responsive" style="border:1px solid #cccccc; width:100%">
            </div>
        </div>
        <div class="row p-t-20">
            <div class="col-md-12 ">
                <div class="btn-group m-r-0" style="float:right">
                    <button type="button" class="btn btn-default m-b-5 m-r-10" style="margin-top: 10px;" label="Cancel" (click)="Close()">Cancel</button>
                    <button type="submit" class="btn btn-default m-b-5" style="margin-top: 10px;" label="Save">Save</button>
                </div>
            </div>
        </div>
    </form>
</p-dialog>