import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem, DomHandler } from "primeng/primeng";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: "leaderboard-menu",
  templateUrl: `leaderboard-menu.component.html`,
  styleUrls: ["leaderboard-menu.component.scss"],
  providers: [DomHandler],
})
export class LeaderboardTabMenu implements OnInit, OnChanges {
  @Input() model: MenuItem[];
  @Input() activeItem: MenuItem;
  @Input() popup: boolean;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() disableAutoSelection = false;
  @Input() initialIndex: number = 0;

  selectedIndex: number = 0;
  urlDivided: string[];
  isMobile: boolean;

  constructor(private router: Router, private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.setInitialIndex();
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialIndex']) {
      this.setInitialIndex();
    }
  }

  private setInitialIndex() {
    if (this.model && this.model.length > 0 && this.initialIndex >= 0 && this.initialIndex < this.model.length) {
      this.selectedIndex = this.initialIndex;
      this.activeItem = this.model[this.initialIndex];
    }
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }
    if (item.routerLink) {
      this.urlDivided = this.router.url.split('/');
      var tournamentId = this.urlDivided[3];
      const routerLinkString = Array.isArray(item.routerLink) ? item.routerLink.join('/') : item.routerLink;

      const encodedRouterLink = encodeURIComponent(routerLinkString);

      var combinedSegments = '';
      if(this.isPublic()){
        combinedSegments = `public/tournaments/${tournamentId}/${[item.routerLink]}`;
      }else{
        tournamentId = this.urlDivided[2];
        combinedSegments = `tournaments/${tournamentId}/${[item.routerLink]}`;
      }

      this.router.navigate([combinedSegments]);
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }

    if (!this.disableAutoSelection) {
      this.activeItem = item;
      this.selectedIndex = index;
    }
  }

  isPublic(): boolean {
    return this.router.url.includes('public');
  }
}
