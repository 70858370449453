
import {share} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { FlightMember } from "../models";
import { Observable ,  Subject } from 'rxjs';

import { TeamsService } from './TeamsService';
import { LeaderboardService } from "./LeaderboardService";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { HttpServices } from './HttpServices';

@Injectable()
export class FlightMembersService {
    flightMembers$: Observable<FlightMember[]>;
    private _flightMembersObserver = new Subject<FlightMember[]>();
    private _dataStore: {
        flightMembers: FlightMember[];
        competitionID: string;
        roundID: string;
    };    

    constructor(
        public teamsService: TeamsService,
        private leaderboardService: LeaderboardService,
        private toastr: ToastrService ,
        public httpService:HttpServices) {
        this._dataStore = { flightMembers: [], competitionID: '', roundID: '' };
        this.flightMembers$ = this._flightMembersObserver.asObservable().pipe(share());        
    }


    loadAll(competitionID: string, roundID: string): void {
      var url = `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembers`
        this.httpService.get(url).subscribe((data: Array<FlightMember>) => {
            this._dataStore.flightMembers = data;
            this._dataStore.competitionID = competitionID;
            this._dataStore.roundID = roundID;
            this._flightMembersObserver.next(this._dataStore.flightMembers);
        }, error => console.log('Could not load flight member'));
    }

    reloadAll(): void {
        this.loadAll(this._dataStore.competitionID, this._dataStore.roundID);
    }

    add(competitionID: number, roundID: number, flightMember: FlightMember): Observable<FlightMember> {
        var flightMembersObserver = new Subject<FlightMember>();
      this.httpService.post(`${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembers`, flightMember).subscribe((data: FlightMember) => {
            var existing = false;
            this.teamsService.updateLocal(data.team);
            this.toastr.success('Flight member added');
            this._dataStore.flightMembers.forEach((flightMember, i) => {
                if (flightMember.profileID === data.profileID) {
                    this._dataStore.flightMembers[i] = data;
                    existing = true;
                }
            });
            if (!existing) {
                this._dataStore.flightMembers.push(data);
            }
            this._flightMembersObserver.next(this._dataStore.flightMembers);            
            flightMembersObserver.complete();  
            this.reloadAll();            
            return data;
        }, error => console.log('Could not create flight member.'));

        return flightMembersObserver;
    }

    update(competitionID: string, roundID: string, flightMemberUpdate: FlightMember): Observable<FlightMember> {
        var flightMembersObserver = new Subject<FlightMember>();
      this.httpService.put(`${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembers`, flightMemberUpdate).subscribe((data: FlightMember) => {
            this.teamsService.updateLocal(data.team);
            this.toastr.success('Flight member updated');
            this._dataStore.flightMembers.forEach((flightMember, i) => {
                if (flightMember.flightMemberID === data.flightMemberID) {
                    this._dataStore.flightMembers[i] = data;
                }
            });
            this._flightMembersObserver.next(this._dataStore.flightMembers);
            flightMembersObserver.next(data);
            this.reloadAll();
            return data;
        },
            error => {
                console.log('Could not update flight member.');
            });

        return flightMembersObserver;
    }

    updateMembers(competitionID: string, roundID: string, flightMemberUpdate: Array<FlightMember>): Observable<Array<FlightMember>> {
        var flightMembersObserver = new Subject<Array<FlightMember>>();
        var updateCommand = {
            FlightMembers: _.filter(flightMemberUpdate, function (flightMember) {
                return flightMember.flightMemberID > 0;
            })
        };
      this.httpService.put(`${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembers/Members`, updateCommand, 
         ).subscribe((data: Array<FlightMember>) => {
            var updates = false;
            for (let flightMember of data) {
                this.teamsService.updateLocal(flightMember.team);
                this._dataStore.flightMembers.forEach((flightMember, i) => {
                    if (flightMember.flightMemberID === flightMember.flightMemberID) {
                        this._dataStore.flightMembers[i] = flightMember;
                        updates = true;
                    }
                });
            }
            if (updates) {
                this.toastr.success('Flight members updated');
            }
            this._flightMembersObserver.next(this._dataStore.flightMembers);
            flightMembersObserver.next(data);
            this.reloadAll();
            return data;
        }, error => console.log('Could not update flight member.'));

        return flightMembersObserver;
    }


    //Possibly rewrite to just be like a bulk flight member update
    teamSwap(competitionID: string, roundID: string, teamID: string, flightMembers: Array<number>): Observable<Array<FlightMember>> {
        var flightMembersObserver = new Subject<Array<FlightMember>>();
        var update = {
            teamID: teamID,
            flightMemberIDs: flightMembers
        }

      this.httpService.put(`${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembers/ChangeTeam`, update).subscribe((data: Array<FlightMember>) => {
            for (let z = 0; z < data.length; z++) {
                var flightMember = data[z];
                this.teamsService.updateLocal(flightMember.team);
                this.toastr.success('Flight member updated');
                this._dataStore.flightMembers.forEach((flightMember, i) => {
                    if (flightMember.flightMemberID === flightMember.flightMemberID) {
                        this._dataStore.flightMembers[i] = flightMember;
                    }
                });
            }
            this._flightMembersObserver.next(this._dataStore.flightMembers);
            flightMembersObserver.next(data);
            this.reloadAll();
            return data;
        }, error => console.log('Could not update flight member.'));
        return flightMembersObserver;
    }

    swap(roundID: number, flightMember1ID: number, flightMember2ID: number) {
      this.httpService.post(`${environment.apiUrl}/api/Round/${roundID}/FlightMembers/Swap/${flightMember1ID}/${flightMember2ID}`, null)
            .subscribe((data: any) => {
                this.toastr.success('Flight members swapped');
                for (let i = 0; i < data.length; i++) {
                    this.teamsService.updateLocal(data.team);
                    var updatedFlightMember = data[i];
                    var index = _.findIndex(this._dataStore.flightMembers, { 'profileID': updatedFlightMember.profileID });
                    this._dataStore.flightMembers.splice(index, 1);
                }
                for (let i = 0; i < data.length; i++) {
                    this._dataStore.flightMembers.push(data[i]);
                }
                this._dataStore.flightMembers = this._dataStore.flightMembers;
                this._flightMembersObserver.next(this._dataStore.flightMembers);
                this.reloadAll();
                this.leaderboardService.recalculateLeaderboard(roundID).subscribe({ error: e => console.error(e) });
                return data;
            }, error => console.log('Could not update flight member.'));
    }


    setArrived(flightMemberID: any, Status: number): void {
      let url = `${environment.apiUrl}/api/FlightMembers/${flightMemberID}/PlayerArrived/${Status}`;
        this.httpService.patch(url, "").subscribe(
            (data: FlightMember) => {
                this._dataStore.flightMembers.find(f => f.flightMemberID === data.flightMemberID).isCheckedIn = data.isCheckedIn;
                this._flightMembersObserver.next(this._dataStore.flightMembers);
                this.toastr.success('The flight member was successfully updated');                
            }, error => {
                this.toastr.error('Error updating the flight member');
            }
        );

    }

    removeFromTeeTime (flightMemberID : number): Observable<FlightMember> {
        let url = `${environment.apiUrl}/api/flight-members/remove-from-tee/${flightMemberID}`;
        var flightMembersObserver = new Subject<FlightMember>();
        this.httpService.delete(url).subscribe((data: FlightMember) => {
            this._dataStore.flightMembers.forEach((flightMember, index) => {
                if (flightMember.flightMemberID === +flightMemberID) {
                    this._dataStore.flightMembers.splice(index, 1);
                }
            });
            this._flightMembersObserver.next(this._dataStore.flightMembers);
            flightMembersObserver.next(data);
            flightMembersObserver.complete();
            this.reloadAll();
        }, error => console.log('Flightmember is not deleted'));
        return flightMembersObserver;
    }

    remove(competitionID: string, roundID: number, flightMemberID: string): Observable<FlightMember> {
      var url = `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembers/${flightMemberID}`;
        var flightMembersObserver = new Subject<FlightMember>();

        this.httpService.delete(url)
            .subscribe((data: FlightMember) => {
                this._dataStore.flightMembers.forEach((flightMember, index) => {
                    if (flightMember.flightMemberID === +flightMemberID) {
                        this._dataStore.flightMembers.splice(index, 1);
                    }
                });
                this._flightMembersObserver.next(this._dataStore.flightMembers);
                flightMembersObserver.next(data);
                flightMembersObserver.complete();                
            }, error => console.log('Could not  flight member round.'));
        return flightMembersObserver;
    }

    getArrivals(competitionID: string, roundID: string): Observable<any> {

      var url = `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/FlightMembersArrived`;

        return this.httpService
            .get(url);
    }

    getArrivalsMedia(competitionID: string, roundID: string): Observable<any> {

      var url = `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/ArrivedMedia`;

        return this.httpService
            .get(url);
    }
}
