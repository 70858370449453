import { CompetitionProfileDetailLite } from './CompetitionProfileDetail';
export class Profile {
    profileID: number = 0;
    nationality: string = '';
    emailAddress: string = '';
    firstName: string = '';
    surname: string = '';
    gender: number;
    handicap: number = 0;
    vparHandicap: number;
    shirtSize: string = '';
    team: string = '';
    vparMember: boolean = false;
    pictureFilename: string = '';
    displayName: string = '';
    pathPictureFilename: string = '';
    dob: Date;
    competitionProfileDetails: CompetitionProfileDetailLite[] = [];

    get nationalityCode(): string {
        return nationalityCodeLookup(this.nationality);
    }    
}

export function nationalityCodeLookup(nationality: string): string {
    var s = "";
    switch (nationality) {
        case "Afghan":
            s = "af";
            break;
        case "Albanian":
            s = "al";
            break;
        case "Algerian":
            s = "dz";
            break;
        case "American":
            s = "us";
            break;
        case "American Samoan":
            s = "as";
            break;
        case "Andorran":
            s = "ad";
            break;
        case "Angolan":
            s = "ao";
            break;
        case "Anguillan":
            s = "ai";
            break;
        case "Antarctican":
            s = "aq";
            break;
        case "Antiguan":
            s = "ag";
            break;
        case "Antillean":
            s = "an";
            break;
        case "Argentinian":
            s = "ar";
            break;
        case "Armenian":
            s = "am";
            break;
        case "Aruban":
            s = "aw";
            break;
        case "Australian":
            s = "au";
            break;
        case "Austrian":
            s = "at";
            break;
        case "Azerbaijani":
            s = "az";
            break;
        case "Bahamian":
            s = "bs";
            break;
        case "Bahraini":
            s = "bh";
            break;
        case "Bangladeshi":
            s = "bd";
            break;
        case "Barbadian":
            s = "bb";
            break;
        case "Basotho":
            s = "vpar";
            break;
        case "Belarusian":
            s = "by";
            break;
        case "Belgian":
            s = "be";
            break;
        case "Belizean":
            s = "bz";
            break;
        case "Beninese":
            s = "bj";
            break;
        case "Bermudian":
            s = "bm";
            break;
        case "Bhutanese":
            s = "bt";
            break;
        case "Bolivian":
            s = "bo";
            break;
        case "Bosnia and Herzegovina national":
            s = "ba";
            break;
        case "Botswanan":
            s = "bw";
            break;
        case "Bouvet Island":
            s = "bv";
            break;
        case "Brazilian":
            s = "br";
            break;
        case "British":
            s = "gb";
            break;
        case "British Indian Ocean Territory":
            s = "io";
            break;
        case "British Virgin Islander":
            s = "vg";
            break;
        case "Bruneian":
            s = "bn";
            break;
        case "Bulgarian":
            s = "bg";
            break;
        case "Burkinabe":
            s = "bf";
            break;
        case "Burmese":
            s = "mm";
            break;
        case "Burundian":
            s = "bi";
            break;
        case "Cambodian":
            s = "kh";
            break;
        case "Cameroonian":
            s = "cm";
            break;
        case "Canadian":
            s = "ca";
            break;
        case "Cape Verdean":
            s = "cv";
            break;
        case "Caymanian":
            s = "ky";
            break;
        case "Central African":
            s = "cf";
            break;
        case "Chadian":
            s = "ro";
            break;
        case "Chilean":
            s = "cl";
            break;
        case "China":
        case "Chinese":
            s = "cn";
            break;
        case "Christmas Islander":
            s = "cx";
            break;
        case "Cocos Islander":
            s = "cc";
            break;
        case "Colombian":
            s = "co";
            break;
        case "Comorian":
            s = "com";
            break;
        case "Congolese":
            s = "cg";
            break;
        case "Cook Islander":
            s = "ck";
            break;
        case "Costa Rican":
            s = "cr";
            break;
        case "Croat":
            s = "cs";
            break;
        case "Cuban":
            s = "cu";
            break;
        case "Cypriot":
            s = "cy";
            break;
        case "Czech":
            s = "cz";
            break;
        case "Dane":
            s = "dk";
            break;
        case "Djiboutian":
            s = "dj";
            break;
        case "Dominican":
            s = "do";
            break;
        case "Dutch":
        case "Netherlander":
            s = "nl";
            break;
        case "East Timorese":
            s = "tl";
            break;
        case "Ecuadorian":
            s = "ec";
            break;
        case "Egyptian":
            s = "eg";
            break;
        case "Emirian":
            s = "ae";
            break;
        case "English":
            s = "gb";
            break;
        case "Equatorial Guinean":
            s = "gq";
            break;
        case "Eritrean":
            s = "er";
            break;
        case "Estonian":
            s = "ee";
            break;
        case "Ethiopian":
            s = "et";
            break;
        case "Faeroese":
            s = "fo";
            break;
        case "Falkland Islander":
            s = "fk";
            break;
        case "Fiji Islander":
            s = "fj";
            break;
        case "Filipino":
            s = "ph";
            break;
        case "Finn":
            s = "fi";
            break;
        case "French":
            s = "fr";
            break;
        case "French Southern Territories":
            s = "fs";
            break;
        case "Gabonese":
            s = "ga";
            break;
        case "Gambian":
            s = "gm";
            break;
        case "Georgian":
            s = "ge";
            break;
        case "German":
            s = "de";
            break;
        case "Ghanaian":
            s = "gh";
            break;
        case "Gibraltarian":
            s = "gi";
            break;
        case "Greek":
            s = "gr";
            break;
        case "Greenlander":
            s = "gl";
            break;
        case "Grenadian":
            s = "gd";
            break;
        case "Guadeloupean":
            s = "gp";
            break;
        case "Guamanian":
            s = "gu";
            break;
        case "Guatemalan":
            s = "gt";
            break;
        case "Guianese":
            s = "fg";
            break;
        case "Guinea-Bissau national":
            s = "gw";
            break;
        case "Guinean":
            s = "gn";
            break;
        case "Guyanese":
            s = "gy";
            break;
        case "Haitian":
            s = "ht";
            break;
        case "Heard Island and McDonald Islands":
            s = "au";
            break;
        case "Honduran":
            s = "hn";
            break;
        case "Hong Kong Chinese":
            s = "hk";
            break;
        case "Hungarian":
            s = "hu";
            break;
        case "Icelander":
            s = "is";
            break;
        case "Indian":
            s = "in";
            break;
        case "Indonesia":
        case "Indonesian":
            s = "id";
            break;
        case "Iranian":
            s = "ir";
            break;
        case "Iraqi":
            s = "iq";
            break;
        case "Irish":
            s = "ie";
            break;
        case "Israeli":
            s = "il";
            break;
        case "Italian":
            s = "it";
            break;
        case "Ivorian":
            s = "ci";
            break;
        case "Jamaican":
            s = "jm";
            break;
        case "Japanese":
            s = "jp";
            break;
        case "Jordanian":
            s = "jo";
            break;
        case "Kazakh":
            s = "kz";
            break;
        case "Kenyan":
            s = "ke";
            break;
        case "Kiribatian":
            s = "ki";
            break;
        case "Kuwaiti":
            s = "kw";
            break;
        case "Kyrgyz":
            s = "kg";
            break;
        case "Lao":
            s = "la";
            break;
        case "Latvian":
            s = "lv";
            break;
        case "Lebanese":
            s = "lb";
            break;
        case "Liberian":
            s = "lr";
            break;
        case "Libyan":
            s = "ly";
            break;
        case "Liechtensteiner":
            s = "li";
            break;
        case "Lithuanian":
            s = "ly";
            break;
        case "Luxembourger":
            s = "lu";
            break;
        case "Macanese":
            s = "mo";
            break;
        case "Macedonian":
            s = "mk";
            break;
        case "Mahorais":
            s = "vpar";
            break;
        case "Malagasy":
            s = "mg";
            break;
        case "Malawian":
            s = "mw";
            break;
        case "Malaysian":
            s = "my";
            break;
        case "Maldivian":
            s = "mv";
            break;
        case "Malian":
            s = "ml";
            break;
        case "Maltese":
            s = "mt";
            break;
        case "Marshallese":
            s = "mh";
            break;
        case "Martinican":
            s = "mq";
            break;
        case "Mauritanian":
            s = "mr";
            break;
        case "Mauritian":
            s = "mu";
            break;
        case "Mexican":
            s = "mx";
            break;
        case "Micronesian":
            s = "fm";
            break;
        case "Moldovan":
            s = "md";
            break;
        case "Monegasque":
            s = "mc";
            break;
        case "Mongolian":
            s = "mn";
            break;
        case "Montenegrian":
            s = "me";
            break;
        case "Montserratian":
            s = "ms";
            break;
        case "Moroccan":
            s = "ma";
            break;
        case "Mozambican":
            s = "mz";
            break;
        case "Namibian":
            s = "na";
            break;
        case "Nauruan":
            s = "nr";
            break;
        case "Nepalese":
            s = "np";
            break;
        case "New Caledonian":
            s = "nc";
            break;
        case "New Zealander":
            s = "nz";
            break;
        case "Nicaraguan":
            s = "ni";
            break;
        case "Nigerian":
            s = "ng";
            break;
        case "Nigerien":
            s = "ne";
            break;
        case "Niuean":
            s = "nu";
            break;
        case "Norfolk Islander":
            s = "nf";
            break;
        case "North Korean":
            s = "kp";
            break;
        case "Northern Irish":
            s = "gb";
            break;
        case "Northern Mariana Islander":
            s = "mp";
            break;
        case "Norwegian":
            s = "no";
            break;
        case "Omani":
            s = "om";
            break;
        case "Pakistani":
            s = "pk";
            break;
        case "Palauan":
            s = "pw";
            break;
        case "Panamanian":
            s = "pa";
            break;
        case "Papua New Guinean":
            s = "pg";
            break;
        case "Paraguayan":
            s = "py";
            break;
        case "Peruvian":
            s = "pe";
            break;
        case "Pitcairner":
            s = "pn";
            break;
        case "Polish":
        case "Pole":
            s = "pl";
            break;
        case "Polynesian":
            s = "pf";
            break;
        case "Portuguese":
            s = "pt";
            break;
        case "Puerto Rican":
            s = "pr";
            break;
        case "Qatari":
            s = "qa";
            break;
        case "Reunionese":
            s = "fr";
            break;
        case "Romanian":
            s = "ro";
            break;
        case "Russian":
            s = "ru";
            break;
        case "Rwandan":
            s = "rw";
            break;
        case "Sahrawi":
            s = "eh";
            break;
        case "Saint Helenian":
            s = "sh";
            break;
        case "Saint Kitts and Nevis national":
            s = "kn";
            break;
        case "Saint Lucian":
            s = "lc";
            break;
        case "Saint Pierre and Miquelon national":
            s = "pm";
            break;
        case "Salvadorian":
            s = "sv";
            break;
        case "Samoan":
            s = "sam";
            break;
        case "San Marinese":
            s = "sm";
            break;
        case "Sao Tomean":
            s = "st";
            break;
        case "Saudi Arabian":
            s = "sa";
            break;
        case "Scottish":
            s = "gb";
            break;
        case "Senegalese":
            s = "sn";
            break;
        case "Serbian":
            s = "rs";
            break;
        case "Seychellois":
            s = "sc";
            break;
        case "Sierra Leonean":
            s = "sl";
            break;
        case "Singapore":
        case "Singaporean":
            s = "sg";
            break;
        case "Slovak":
            s = "sk";
            break;
        case "Slovene":
            s = "si";
            break;
        case "Solomon Islander":
            s = "sb";
            break;
        case "Somali":
            s = "so";
            break;
        case "South African":
            s = "za";
            break;
        case "South Georgia and the South Sandwich Islands":
            s = "gs";
            break;
        case "South Korean":
            s = "kr";
            break;
        case "Spanish":
            s = "es";
            break;
        case "Sri Lankan":
            s = "lk";
            break;
        case "Sudanese":
            s = "sd";
            break;
        case "Surinamer":
            s = "sr";
            break;
        case "Svalbard and Jan Mayen":
            s = "sj";
            break;
        case "Swazi":
            s = "sz";
            break;
        case "Swede":
            s = "se";
            break;
        case "Swiss":
            s = "ch";
            break;
        case "Syrian":
            s = "sy";
            break;
        case "Taiwan":
        case "Taiwanese":
            s = "tw";
            break;
        case "Tajik":
            s = "tj";
            break;
        case "Tanzanian":
            s = "tz";
            break;
        case "Thai":
            s = "th";
            break;
        case "Togolese":
            s = "tg";
            break;
        case "Tokelauan":
            s = "tk";
            break;
        case "Tongan":
            s = "to";
            break;
        case "Trinidad and Tobago national":
            s = "tt";
            break;
        case "Tunisian":
            s = "tn";
            break;
        case "Turk":
        case "Turkish":
            s = "tr";
            break;
        case "Turkmen":
            s = "tm";
            break;
        case "Turks and Caicos Islander":
            s = "tc";
            break;
        case "Tuvaluan":
            s = "tv";
            break;
        case "Ugandan":
            s = "ug";
            break;
        case "Ukrainian":
            s = "ua";
            break;
        case "Uruguayan":
            s = "uy";
            break;
        case "US Virgin Islander":
            s = "vi";
            break;
        case "Uzbek":
            s = "uz";
            break;
        case "Vanuatuan":
            s = "vu";
            break;
        case "Vatican":
            s = "va";
            break;
        case "Venezuelan":
            s = "ve";
            break;
        case "Vietnamese":
            s = "vn";
            break;
        case "Vincentian":
            s = "vc";
            break;
        case "Wallis and Futuna Islander":
            s = "wf";
            break;
        case "Welsh":
            s = "gb";
            break;
        case "Yemeni":
            s = "ye";
            break;
        case "Zambian":
            s = "zm";
            break;
        case "Zimbabwean":
            s = "zw";
            break;
    }
    return s;
}