<div class="page-content">
  <div class="row" id="table-section">
    <div class="col-lg-12 portlets ui-sortable table-round">
      <div class="panel" id="table-round">
        <div class="panel-header">
          <h3 class="header">Order of Merit</h3>
          <span class="description-text">An Order of Merit (OOM) is a competition where stableford scores or
            merit points are accumulated across a number of tournament results.
            Clubs can create their own OOM with different scoring types like
            stableford, gross, net, etc.</span>
        </div>
        <hr />
        <form class="form" [formGroup]="inputsForm" (ngSubmit)="onSubmit()">
          <div class="panel-content order-panel">
            <div class="first-line m-t-10">
              <div class="name">
                <p>Name your order of merit</p>
                <input class="name-input" type="text" placeholder="Add a name" formControlName="title" />
                <span class="error"
                  *ngIf="submitted && !inputsForm.controls.title.valid && inputsForm.controls.title.errors">
                  Name is required
                </span>
              </div>
              <div class="startDate">
                <p>
                  Start Date
                  <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                    pTooltip="Select date. Date should be before the end date selected" tooltipPosition="top" />
                </p>
                <p-calendar formControlName="startDate" placeholder="Select date" showButtonBar="true"></p-calendar>
                <div *ngIf="submitted && !inputsForm.controls.startDate.valid && inputsForm.controls.startDate.errors"
                  class="error">
                  Start Date is required
                </div>
              </div>
              <div class="endDate">
                <p>
                  End Date
                  <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                    pTooltip="Select date. Date should be after the start date selected" tooltipPosition="top" />
                </p>
                <p-calendar formControlName="endDate" placeholder="Select date" showButtonBar="true"></p-calendar>
                <div *ngIf="submitted && !inputsForm.controls.endDate.valid && inputsForm.controls.endDate.errors"
                  class="error">
                  End Date is required
                </div>
              </div>
            </div>
            <div class="second-line m-t-10">
              <p>
                Description
                <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                  pTooltip="Add description here" tooltipPosition="top" />
              </p>
              <input type="text" placeholder="Add a Description" formControlName="description" />
              <span class="error"
                *ngIf="submitted && !inputsForm.controls.description.valid && inputsForm.controls.description.errors">
                Description is required
              </span>
            </div>
            <div class="third-line m-t-10">
              <div class="game-format">
                <p>
                  Game Format
                  <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                    pTooltip="Select game format Stableford,Strokeplay or Both" tooltipPosition="top" />
                </p>
                <p-dropdown [options]="formats" placeholder="Select" (onChange)="onFormatChange($event)"
                  [disabled]="disableGameFormats" formControlName="gameFormats">
                </p-dropdown>
                <div class="error"
                  *ngIf="submitted && !inputsForm.controls.gameFormats.errors && !inputsForm.controls.gameFormats.touched">
                  Game Format is required.
                </div>
              </div>
              <div class="game-format">
                <p>
                  Points Format
                  <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                    pTooltip="Select game format Stableford,Strokeplay or Both" tooltipPosition="top" />
                </p>
                <p-dropdown [options]="pointFormats" placeholder="Select" (onChange)="onPointsFormatChange($event)"
                  formControlName="pointsFormats" [disabled]="disablePointsFormat"></p-dropdown>
                <div
                  *ngIf="submitted && !inputsForm.controls.pointsFormats.errors && !inputsForm.controls.pointsFormats.touched"
                  style="opacity: 0.6; color: red">
                  Points format is required
                </div>
              </div>
              <div class="qualification">
                <p>
                  Qualification
                  <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                    pTooltip="Select qualification value from 1 to 10" tooltipPosition="top" />
                  (optional)
                </p>
                <p-dropdown [options]="qualifications" placeholder="None" formControlName="qualifications"></p-dropdown>
              </div>
            </div>
            <div class="scoring-formats">
              <h3 class="scoring-formats-header">Scoring Formats</h3>
              <div class="top-format">
                <div class="best-scores">
                  <p>
                    Best Scores
                    <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                      pTooltip="Display or hide best scores" tooltipPosition="top" />
                    (optional)
                  </p>
                  <div class="toggle-container">
                    <label>{{ labelBest }}</label>
                    <p-inputSwitch class="custom-switch" (onChange)="onToggleChangeBest()"
                      formControlName="bestScore"></p-inputSwitch>
                  </div>
                </div>
                <div class="worst-scores">
                  <p>
                    Worst Scores
                    <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                      pTooltip="Display or hide worst scores" tooltipPosition="top" />
                    (optional)
                  </p>
                  <div class="toggle-container">
                    <label>{{ labelWorst }}</label>
                    <p-inputSwitch class="custom-switch" (onChange)="onToggleChangeWorst()"
                      formControlName="worstScore"></p-inputSwitch>
                  </div>
                </div>
                <div class="best-of">
                  <p>
                    Best
                    <img src="../../../assets/icons/overview-icons/ios_icons_info.svg" alt=""
                      pTooltip="Select best value from 1 to 10" tooltipPosition="top" />
                    (optional)
                  </p>
                  <p-dropdown [options]="bestof" placeholder="Select" formControlName="bestOf"></p-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="!displayPointsAllocation" class="toggle-grid">
            <p>Points Allocation</p>
            <hr />
            <div class="table-grid">
              <ul>
                <ng-container *ngFor="let place of places; let i = index">
                  <li>
                    <span>{{ place.place }}:
                      <input type="number" [(ngModel)]="place.points" [ngModelOptions]="{standalone: true}" required />
                      points</span>
                    <img src="../../../assets/icons/overview-icons/tms_icons_remove.svg" (click)="removePlace(i)" />
                  </li>
                </ng-container>
              </ul>
              <a class="btn btn-primary add-btn" (click)="addPosition()">Add position</a>
            </div>
          </div>
          <div *ngIf="pointsAllocationError" class="error">
            Points allocations are required if the format is merit points
          </div>
          <hr />
          <div class="footer">
            <button class="btn btn-primary continue-btn" type="submit">
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>