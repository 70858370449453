import { Component, OnInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Round, Flight, FlightTeam, Team, FlightMember, CourseForScorecard } from '../../../models';
import { TeamsService, FlightService, FlightMembersService, CompetitionRouteService } from "../../../services";
import { combineLatest, Observable ,  Subject } from 'rxjs';
import { ScoreTabComponent } from '../../../shared/components/scorecard/index';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    templateUrl: 'tournament.overview.flight.scorecard.component.html',
    styleUrls: ['tournament.overview.flight.scorecard.component.scss']
})

export class TournamentOverviewFlightScorecardComponent extends ScoreTabComponent implements OnInit, OnDestroy  {
    public roundID: string;
    teamType: number;
    public competitionID: string;
    public flightTeams: Array<FlightTeam>;
    public tournamentOpen: boolean = true;
    public $stop: Subject<boolean> = new Subject<any>();

    courseHoles: CourseForScorecard;
    round: Round;    

    constructor(private route: ActivatedRoute, private competitionRouteService: CompetitionRouteService,
        public flightMembersService: FlightMembersService,
        public teamsService: TeamsService,
        public flightService: FlightService) {
            super();
    }

    ngOnInit() {
        this.courseHoles = this.route.parent.snapshot.data['course'];
        this.round = this.route.parent.parent.snapshot.data['round'];

        var competition = this.route.parent.parent.parent.snapshot.data['tournament'];
        if (competition) {
            this.tournamentOpen = (competition.status != 3);
        }
        var round = <Round>this.route.parent.snapshot.data['round'];
        this.roundID = round.roundID.toString();
        this.teamType = round.teamType;
        this.competitionID = round.competitionID.toString();
        this.teamsService.loadAll(this.competitionID);
        this.flightService.loadAll(this.competitionID, this.roundID);
        this.flightMembersService.loadAll(this.competitionID, this.roundID);


        combineLatest(this.flightMembersService.flightMembers$, this.flightService.flights$, this.teamsService.teams$,
            function (flightMembers: Array<FlightMember>, flights: Array<Flight>, teams: Array<Team>) {
                var flightTeams: Array<FlightTeam> = [];
                flights.forEach(function (flight) {
                    var flightTeam = new FlightTeam();
                    flightTeam.flightID = flight.flightID;
                    flightTeam.roundID = flight.roundID;
                    flightTeam.startHole = flight.startHole;
                    flightTeam.teeOffTimeText = moment.utc(flight.teeOffTime).format("HH:mm");
                    flightTeam.teams = [];
                    var members = _.filter(flightMembers, { 'flightID': flight.flightID });
                    var teamIDs = _.uniq(_.pluck(members, 'teamID'));
                    teamIDs.forEach(teamID => {
                        var team = _.cloneDeep(_.find(teams, { 'teamID': teamID }));
                        if (team) {
                            var teamMembers = _.filter(members, { 'teamID': teamID });
                            team.flightMembers = teamMembers;
                            flightTeam.teams.push(team);
                        }
                    });


                    // if no team, then create fake team with the flight members (to show list of players in header)
                    if (teamIDs.length === 1 && (teamIDs[0] === null || teamIDs[0] === 0)) {
                        let newTeam = new Team();
                        newTeam.flightMembers = members;
                        flightTeam.teams.push(newTeam);
                    }
                    if (members.length > 0) {
                        flightTeams.push(flightTeam);
                    }
                });
                return flightTeams;
            }).pipe(takeUntil(this.$stop)).subscribe((data) => {
                // initial status. All scorecards are not being edited
                data.forEach(() => {
                    this.scorecardsEditingStatus.push(false);
                });
                this.flightTeams = data;
            });
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    
}
