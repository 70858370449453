<div class="jumbo-leaderboard">
    <div class="header">
        <div class="small-column" [ngClass]="{'big-column': column.headerText === ''}" *ngFor="let column of leaderboard.config.columns | visibleColumn">{{column.headerText}}</div>
    </div>
    <div class="player-list" [@visibilityChanged]="leaderboard.showList">
        <div class="player" *ngFor="let player of leaderboard.screenPlayers" [ngClass]="{'fixed-height': leaderboard.screenPlayers.length < 10}">
            <div class="small-column"  [ngClass]="{'pos': column.headerText === 'Pos','big-column': column.headerText === '','white-color':column.dataItem ==='Thumbnail'}" *ngFor="let column of leaderboard.config.columns | visibleColumn; let index = index">                
                <span *ngIf="column.dataItem !== 'Thumbnail'">{{buildColumnData(column,player)}}</span>
            </div>
        </div>
    </div>
</div>
