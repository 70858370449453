export class RoundLeaderboardConfig{
    roundLeaderboardConfigID: number = 0;
    roundLeaderboardID: number;
    roundID: number;
    holeStart: number = 1;
    holeEnd: number = 2;
    leaderboardCode: String;
    samePlayerCanGrossNet: boolean = false;
    grossSupersedesNet: boolean = false;
    selected: boolean;

    errorHoleStart: boolean = false;
    errorHoleEnd: boolean = false;
    errorMessage: string = '';
    highlighted: boolean = false;
}