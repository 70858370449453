<p-dialog [(visible)]="displaySettingsDialog" header="Display Settings" [responsive]="false" [modal]="true" [width]="750" [height]="510" [resizable]="true" [draggable]="false">
    <div style="width: 705px; height: 475px; min-height:480px;" *ngIf="showImageCropper">
        <div class="col-md-12">
            <h4>
                <strong>Leaderboard logo upload</strong>
            </h4>
            <div class="upload-content">
                <span>Select an image and edit it to be used as small logo on leaderboards</span>
                <div class="file-upload">
                    <span class="text">Select file</span>
                    <input id="custom-input" type="file" (change)="fileChangeListener($event)">
                </div>
            </div>
            <div class="col-md-12 m-t-20">
                <img-cropper #cropper class="cropper" [image]="cropperData" [settings]="cropperSettings"></img-cropper>
                <span class="result" *ngIf="cropperData.image">
                    <img [src]="cropperData.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight">
                    <p>Final result (220 x 120)</p>
                </span>
            </div>
        </div>
        <div class="col-md-12 upload-logo-buttons">
            <div class="btn-group pull-right" *ngIf="cropperData.image">
                <button class="btn btn-action-ok w-600 f-13 m-b-0" [disabled]="uploadInProgress" (click)="uploadLogo()">Upload</button>
            </div>
            <div class="btn-group pull-right">
                <button class="btn btn-action-cancel w-600 f-13 m-b-0" (click)="toggleImageCropper('')">Back</button>
            </div>
        </div>
    </div>
    <div style="width: 705px; height: 475px; min-height:480px;" *ngIf="!showImageCropper">
        <div class="col-md-12 p-l-0">
            <div class="col-md-6">
                <h4><strong>TV Leaderboard Design</strong></h4>
                <p>Select a background image or upload your own</p>
                <div class="row">
                    <div style="margin-left:15px;margin-bottom:20px;">
                        <select class="form-control input-lg logoSelect" [(ngModel)]="backVenueMediaID">
                            <option value="0">Please Select</option>
                            <option *ngFor="let p of mediaBackImgOptions" [value]="p.mediaID">{{p.title}}</option>
                        </select>
                        <MediaUpload class="TvLogos btn-upload-background" (fileUploaded)="UploadcompleteBackImg()" [BackType]="90" [LogoType]="0" [index]="0"></MediaUpload>
                    </div>
                </div>
                <h5><strong>Logos</strong></h5>
                <p>Top</p>
                <div class="row">
                    <div style="margin-left:15px;margin-bottom:20px;">
                        <select class="form-control input-lg logoSelect" [(ngModel)]="leftLogoMediaID">
                            <option value="0">Please Select</option>
                            <option *ngFor="let p of mediaOptions" [value]="p.mediaID">{{p.title}}</option>
                        </select>
                        <button class="btn btn-action-ok m-b-5 btn-crop-show" (click)="toggleImageCropper('left')"><i class="fa fa-upload"></i></button>
                    </div>
                </div>
                <p>Bottom</p>
                <div class="row">
                    <div style="margin-left:15px;margin-bottom:20px;">
                        <select class="form-control input-lg logoSelect" [(ngModel)]="rightLogoMediaID">
                            <option value="0">Please Select</option>
                            <option *ngFor="let p of mediaOptions" [value]="p.mediaID">{{p.title}}</option>
                        </select>
                        <button class="btn btn-action-ok m-b-5 btn-crop-show" (click)="toggleImageCropper('right')"><i class="fa fa-upload"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!--<div class="col-md-12 display-Layout-div">
                    <div class="col-md-5 display-Layout-inner text-center">
                        Left
                    </div>
                    <div class="col-md-5 pull-right display-Layout-inner text-center">
                        Right
                    </div>
                    <p>Leaderboard Background</p>
                </div>-->
            </div>
        </div>
        <div class="col-md-12">
            <div class="btn-group pull-right">
                <button type="submit" class="btn btn-action-ok w-600 f-13 m-b-0" (click)="saveSettings($event)">Save</button>
            </div>
            <div class="btn-group pull-right">
                <button type="submit" class="btn btn-action-cancel w-600 f-13 m-b-0" (click)="close($event)">Cancel</button>
            </div>
        </div>
    </div>
</p-dialog>