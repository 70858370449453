import { catchError, filter, map, share, shareReplay } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Golfer, GolferMember, IBranding, Profile, Results } from "../models";
import { BehaviorSubject, Observable, Observer, Subject, of } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Settings } from "../models/settings";
import { HttpServices } from "./HttpServices";

@Injectable()
export class SettingsServices {  
  headers: HttpHeaders = new HttpHeaders();
  public golferMemberProcessed$: BehaviorSubject<GolferMember> =
    new BehaviorSubject<GolferMember>(null);

  private _dataStore: {
    settings: Settings;
  };

  constructor(
    public httpService : HttpServices
  ) {    
    this.headers.append("Content-Type", "application/json; charset=utf-8");
    this._dataStore = { settings: null };
  }

  getSettings(competitionHostId:number): Observable<Settings> {
    var url = `${environment.apiUrl}/api/competition-hosts/settings/${competitionHostId}`;
    return this.httpService.getTyped<Settings>(url);
  }
  updateSettings(settings: Settings): Observable<any> {    
    var url = `${environment.apiUrl}/api/competition-hosts/settings/`;
    return this.httpService.put(url, settings).pipe(
      catchError((error) => {
        console.error('Error updating data:', error);
        return of(null);
      })            
    );
  } 
}
