import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicRouteComponent } from './components/tournament/public/publicroute.components';
import { LoggedInComponent } from './components/loggedin/loggedin.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { LoginComponent } from './components/login/login.component';
import { TournamentCreateComponent } from './components/tournament/create/tournament-create.component';
import { TournamentEditComponent } from './components/tournament/create/tournament-edit.component';
import { TournamentListComponent } from './components/tournament/tournaments-list.component';
import { TournamentComponent } from './components/tournament/tournament.component';

import { TournamentOverviewPlayerlistComponent } from './components/tournament/playerlist/tournament-overview-playerlist.component';
import { TournamentOverviewTeeTimesComponent } from './components/tournament/teetimes/tournament-overview-teetimes.component';
import { TournamentOverviewFlightScorecardComponent } from './components/tournament/teetimesscorecard/tournament.overview.flight.scorecard.component';
import { TournamentOverviewLeaderboardComponent } from './components/tournament/leaderboard/tournament-overview-leaderboard.component';
import { TournamentOverviewTeamsComponent } from './components/tournament/teamscorecard/teams.component';
import { ScoresComponent } from "./components/tournament/scores/scores.component"

import { PrintingComponent } from './components/tournament/printing/printing.component';
import { PrintingComponent2 } from './components/tournament/printing/v2/Print2.component';
import { TournamentSummaryComponent } from './components/tournament/summary/tournament-summary.component';
import { TournamentPlayerlistComponent } from './components/tournament/create/tournament-playerlist.component';
import { ChallengeComponent } from './components/challenge/challenge-overview.component';
import { OrderOfMeritOverviewComponent } from './components/orderofmerit/orderofmerit-overview.component';
import { OrderOfMeritComponent } from './components/orderofmerit/orderofmerit-component';
import { GolfersComponent } from './components/golfers/golfers-overview.component';
import { GolferProfileComponent } from './components/golfers/golfers-profile.component';
import { TournamentCreateLeaderboardComponent } from './components/tournament/leaderboard/tournament-create-leaderboard.component';
import { Angular2Carousel } from './components/tournament/public/carousel-example';
import { CanActivateViaAuthGuard } from './guards/canActivateViaAuthGuard';
import { BrandingResolver } from './resolvers/branding.resolver';
import { CompetitionResolver } from './resolvers/competition.resolver';
import { RoundIdResolver } from './resolvers/roundId.resolver';
import { RoundResolver } from './resolvers/round.resolver';
import { IndividualScorecardComponent } from './components/tournament/individual-scorecard/individual-scorecard.component';
import { TVArrivalComponent } from './components/tournament/tvarrival/tv-arrivals.component';
import { CourseResolver } from './resolvers/course.resolver';
import { ConfirmDeactivateGuard } from './guards/canDeactivateGuard';
import { RoundStatsComponent } from './components/tournament/stats/index';
import { RoundAdminComponent } from './components/tournament/admin/index';
import { RoundTrackingComponent } from './components/tournament/tracking/index';
import { TVLeadeboardComponent } from './components/tournament/tv-leaderboard/tv-leaderboard.component';
import { SocietyLeadeboardComponent } from './components/tournament/tv-leaderboard/tv-leaderboardsociety.component';
import { RoundMessagingComponent } from './components/tournament/messaging/index';
import {RoundHandicapComponent} from './components/tournament/coursehandicap/round-handicap.component'
import { SettingsComponent } from './components/settings/settings.component';
import { OrderofmeritEditComponent } from './components/orderofmerit/orderofmerit-edit.component';
import { OrderofmeritCreateComponent } from './components/orderofmerit/orderofmerit-create.component';
import { OrderofmeritLeaderboardComponent } from './components/orderofmerit/orderofmerit-leaderboard.component';
import { TVJumboScreen } from './components/tournament/tv-leaderboard/jumbo-leaderboard/main-component/tv-jumbo-screen.component';



// Route config let's you map routes to components
export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    {
        path: 'public', component: PublicRouteComponent, children: [
            { path: 'liveleaderboard', component: Angular2Carousel },
            { path: 'tournaments/:tournamentId/round/:roundId/liveleaderboard', component: Angular2Carousel },
            { path: 'tournaments/:tournamentId/round/:roundId/arrivals', component: TVArrivalComponent, resolve: { branding: BrandingResolver } },
            { path: 'tournaments/:tournamentId/round/:roundId/Overallleaderboard', component: SocietyLeadeboardComponent, resolve: { branding: BrandingResolver, tournament: CompetitionResolver } },
            { path: 'tournaments/:tournamentId/round/:roundId/tvleaderboard', component: TVLeadeboardComponent, resolve: { branding: BrandingResolver, tournament: CompetitionResolver } },
            { path: 'tournaments/:tournamentId/round/:roundId/tvjumboscreen', component: TVJumboScreen, resolve: { branding: BrandingResolver, tournament: CompetitionResolver } },
            {
                path: '', component: LoggedInComponent, resolve: { branding: BrandingResolver }, children: [
                    {
                        path: 'tournaments/:tournamentId', component: TournamentComponent, resolve: { tournament: CompetitionResolver }, children: [
                            { path: '', component: TournamentSummaryComponent, pathMatch: 'full', resolve: { roundId: RoundIdResolver } },
                            { path: 'summary', component: TournamentSummaryComponent, resolve: { roundId: RoundIdResolver } },
                            {
                                path: 'round/:roundId', resolve: { roundId: RoundIdResolver, round: RoundResolver }, children: [
                                    { path: '', component: TournamentOverviewPlayerlistComponent, pathMatch: 'full' },
                                    { path: 'players', component: TournamentOverviewPlayerlistComponent },
                                    { path: 'teetimes', component: TournamentOverviewTeeTimesComponent },
                                    {
                                        path: 'scores', component: ScoresComponent, resolve: { course: CourseResolver }, children: [
                                            { path: '', component: TournamentOverviewFlightScorecardComponent, pathMatch: 'full' },
                                            { path: 'teams', component: TournamentOverviewTeamsComponent, canDeactivate: [ConfirmDeactivateGuard] },
                                            { path: 'individual', component: IndividualScorecardComponent, canDeactivate: [ConfirmDeactivateGuard] },
                                            { path: 'teetimes', component: TournamentOverviewFlightScorecardComponent, canDeactivate: [ConfirmDeactivateGuard] }
                                        ]
                                    },
                                    { path: 'leaderboard', component: TournamentOverviewLeaderboardComponent },
                                    { path: 'stats', component: RoundStatsComponent },
                                    { path: 'tracking', component: RoundTrackingComponent },
                                    { path: 'printing', component: PrintingComponent},
                                    { path: 'handicap', component: RoundHandicapComponent},
                                    { path: 'admin', component: RoundAdminComponent}
                                ]
                            }]
                    }]
            },
            {
              path: 'order-of-merit/:competitionHostId/leaderboard', component: OrderofmeritLeaderboardComponent, pathMatch: 'full'
            },
        ],
    },
    {
        path: '', component: LoggedInComponent, resolve: { branding: BrandingResolver }, children: [
            { path: '', redirectTo: '/tournaments', pathMatch: 'full' },
            { path: 'tournaments/create', component: TournamentCreateComponent },
            { path: 'tournaments/:tournamentId/edit', component: TournamentEditComponent, resolve: { tournament: CompetitionResolver } },
            { path: 'tournaments/:tournamentId/round/:roundId/playerlist', component: TournamentPlayerlistComponent, resolve: { tournament: CompetitionResolver, roundId: RoundIdResolver } },
            { path: 'tournaments/:tournamentId/round/:roundId/createleaderboard', component: TournamentCreateLeaderboardComponent, resolve: { tournament: CompetitionResolver, roundId: RoundIdResolver } },
            { path: 'tournaments', component: TournamentListComponent },
            {
                path: 'tournaments/:tournamentId', component: TournamentComponent, runGuardsAndResolvers: "always", resolve: { tournament: CompetitionResolver }, children: [
                    { path: '', component: TournamentSummaryComponent, pathMatch: 'full', resolve: { roundId: RoundIdResolver } },
                    { path: 'summary', component: TournamentSummaryComponent, resolve: { roundId: RoundIdResolver } },
                    {
                        path: 'round/:roundId', resolve: { roundId: RoundIdResolver, round: RoundResolver }, children: [
                            { path: '', component: TournamentOverviewPlayerlistComponent, pathMatch: 'full' },
                            { path: 'players', component: TournamentOverviewPlayerlistComponent },
                            { path: 'teetimes', component: TournamentOverviewTeeTimesComponent },
                            {
                                path: 'scores', component: ScoresComponent, resolve: { course: CourseResolver }, children: [
                                    { path: '', component: TournamentOverviewFlightScorecardComponent, pathMatch: 'full' },
                                    { path: 'teams', component: TournamentOverviewTeamsComponent, canDeactivate: [ConfirmDeactivateGuard] },
                                    { path: 'individual', component: IndividualScorecardComponent, canDeactivate: [ConfirmDeactivateGuard] },
                                    { path: 'teetimes', component: TournamentOverviewFlightScorecardComponent, canDeactivate: [ConfirmDeactivateGuard] }
                                ]
                            },
                            { path: 'leaderboard', component: TournamentOverviewLeaderboardComponent },
                            { path: 'printing', component: PrintingComponent2 },
                            { path: 'printing2', component: PrintingComponent2 },
                            { path: 'stats', component: RoundStatsComponent },
                            { path: 'admin', component: RoundAdminComponent },
                            { path: 'tracking', component: RoundTrackingComponent },
                            { path: 'handicap', component: RoundHandicapComponent},
                            { path: 'messaging', component: RoundMessagingComponent },
                        ]
                    }]
            },
            {
                path: 'golfers', component: GolfersComponent, children: [
                    {
                        path: ':profileId/profile', component: GolferProfileComponent
                    }]
            },
            {
                path: 'challenge', component: ChallengeComponent, pathMatch: 'full'
            },
            {
                path: 'order-of-merit', component: OrderOfMeritComponent, pathMatch: 'full'
            },
            {
                path: 'order-of-merit/edit', component: OrderofmeritEditComponent, pathMatch: 'full'
            },
            {
                path: 'order-of-merit/create', component: OrderofmeritCreateComponent, pathMatch: 'full'
            },
            {
                path: 'order-of-merit/overview', component: OrderOfMeritOverviewComponent, pathMatch: 'full'
            },
            // {
            //     path: 'order-of-merit/leaderboard', component: OrderofmeritLeaderboardComponent, pathMatch: 'full'
            // },
            {   path: 'settings', component : SettingsComponent }
        ], canActivate: [CanActivateViaAuthGuard]
    },
    // { path: 'not-found', component: NotFoundComponent },
    // { path: '*', component: TournamentComponent },
    { path: '**', component: NotFoundComponent },

];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
