import { Component, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { FlightTeam } from '../../../models';
import { ScorecardComponent } from '../../../shared/index';

@Component({
    selector: 'flight-team-renderer',
    templateUrl: 'flight-team.renderer.component.html',
    styleUrls: ['flight-team.renderer.component.scss']
})
export class FlightTeamRendererComponent implements OnInit {

    @Input() flightTeam: FlightTeam;
    @Input() roundID: number;
    @Input() teamType: number;
    @Input() tournamentOpen: boolean;

    @Input() femaleTeeColor: number;
    @Input() maleTeeColor: number;
    @Input() courseData: any;

    @Output() onEditingChange = new EventEmitter<boolean>(false);

    @ViewChild(ScorecardComponent, {static: false}) scorecardComponent: ScorecardComponent;

    open: boolean = false;
    playersList = '';

    constructor(public elementRef: ElementRef) {
    }

    ngOnInit() {
        this.flightTeam.teams.forEach((team) => {
            team.flightMembers.forEach((player) => {
                this.playersList += player.profile.displayName + ', ';
            });
        });

        // remove last comma
        this.playersList = this.playersList.slice(0, -2);
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }

    toggle() {
        var flightHolder = $(this.elementRef.nativeElement).find('div.flightHolder');
        this.open = !this.open;
        if (this.open) {
            this.scorecardComponent.loadScores();
        } else {
            this.scorecardComponent.resetData();
            this.onEditingChange.emit(false);
        }

        $(flightHolder).toggle(500);
    }

    editScorecardChanged(value: boolean){
        this.onEditingChange.emit(value);
    }
}
