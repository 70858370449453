import {Component, Input, Output, EventEmitter} from '@angular/core';
import {PrintOptionDetail} from './PrintOptionDetail';
@Component({
    selector: 'PrintOption',
    templateUrl: 'PrintOption.html',
    styles: [`.printOption {
    display: inline-block;
    border: 2px solid #556973;
    margin-right: 10px;
    font-weight:bold;
    width: 150px;
    height: 30px;
    padding-top: 3px;
}
.filled {
    background:#556973;
    color:#FFFFFF;
}
.disabled {
    color:#f2f3f4;
border-color:#f2f3f4;
}
`]
})

export class PrintOption {
    @Input() detail: PrintOptionDetail;
    @Output() detailUpdated = new EventEmitter<PrintOptionDetail>();
    select() {
        if (this.detail.available) {
            this.detail.selected = !this.detail.selected;
            this.detailUpdated.emit(this.detail);
        }
    }
}
