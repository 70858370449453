/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flight.member.renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/dragdrop/dragdrop";
import * as i5 from "./flight.member.renderer.component";
import * as i6 from "../../services/FlightMemberService";
var styles_FlightMemberRendererComponent = [i0.styles];
var RenderType_FlightMemberRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlightMemberRendererComponent, data: {} });
export { RenderType_FlightMemberRendererComponent as RenderType_FlightMemberRendererComponent };
function View_FlightMemberRendererComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-primary pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["HCP ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flightMember.handicap; _ck(_v, 1, 0, currVal_0); }); }
function View_FlightMemberRendererComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-primary pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Shots ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flightMember.shots; _ck(_v, 1, 0, currVal_0); }); }
function View_FlightMemberRendererComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "cell3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showShots; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.showShots && (_co.flightMember.shots || (_co.flightMember.shots == 0))); _ck(_v, 4, 0, currVal_1); }, null); }
function View_FlightMemberRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container dd3-content tee-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "cell2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "wrap playerName"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.mergedCells; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flightMember.profile.firstName; var currVal_1 = _co.flightMember.profile.surname; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_FlightMemberRendererComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dd3-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No profile "]))], null, null); }
function View_FlightMemberRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "dd-item dd3-item tee-list"], ["pDraggable", "dd"], ["pDroppable", "dd"]], [[8, "draggable", 0]], [[null, "onDrag"], [null, "onDragEnter"], [null, "onDragOver"], [null, "onDragLeave"], [null, "onDrop"], [null, "onDragStart"], [null, "onDragEnd"], [null, "dragstart"], [null, "dragend"], [null, "drop"], [null, "dragenter"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dragStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).dragEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("drop" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).drop($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dragEnter($event) !== false);
        ad = (pd_3 && ad);
    } if (("dragleave" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dragLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("onDrag" === en)) {
        var pd_5 = (_co.onDragEvent($event) !== false);
        ad = (pd_5 && ad);
    } if (("onDragEnter" === en)) {
        var pd_6 = (_co.onDragEnter($event) !== false);
        ad = (pd_6 && ad);
    } if (("onDragOver" === en)) {
        var pd_7 = (_co.onDragOver($event) !== false);
        ad = (pd_7 && ad);
    } if (("onDragLeave" === en)) {
        var pd_8 = (_co.onDragLeave($event) !== false);
        ad = (pd_8 && ad);
    } if (("onDrop" === en)) {
        var pd_9 = (_co.dropExistingBall($event) !== false);
        ad = (pd_9 && ad);
    } if (("onDragStart" === en)) {
        var pd_10 = (_co.dragStart($event) !== false);
        ad = (pd_10 && ad);
    } if (("onDragEnd" === en)) {
        var pd_11 = (_co.dragEnd($event) !== false);
        ad = (pd_11 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { mergedHandicap: 0, dragHover: 1 }), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(5, 4341760, null, 0, i4.Draggable, [i1.ElementRef, i3.DomHandler, i1.NgZone], { scope: [0, "scope"] }, { onDragStart: "onDragStart", onDragEnd: "onDragEnd", onDrag: "onDrag" }), i1.ɵdid(6, 4341760, null, 0, i4.Droppable, [i1.ElementRef, i3.DomHandler, i1.NgZone], { scope: [0, "scope"] }, { onDragEnter: "onDragEnter", onDragLeave: "onDragLeave", onDrop: "onDrop" }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "dd-handle dd3-handle tee-drag"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_6)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "dd-item dd3-item tee-list"; var currVal_2 = _ck(_v, 3, 0, _co.mergedCells, _co.draggedOver); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = "dd"; _ck(_v, 5, 0, currVal_3); var currVal_4 = "dd"; _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.flightMember.profile != null); _ck(_v, 9, 0, currVal_5); var currVal_6 = (_co.flightMember.profile == null); _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_FlightMemberRendererComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "dd-placeholder add-player"], ["pDroppable", "dd"]], null, [[null, "onDragEnter"], [null, "onDragOver"], [null, "onDragLeave"], [null, "onDrop"], [null, "click"], [null, "drop"], [null, "dragenter"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).drop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).dragEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).dragLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("onDragEnter" === en)) {
        var pd_3 = (_co.onDragEnter($event) !== false);
        ad = (pd_3 && ad);
    } if (("onDragOver" === en)) {
        var pd_4 = (_co.onDragOver($event) !== false);
        ad = (pd_4 && ad);
    } if (("onDragLeave" === en)) {
        var pd_5 = (_co.onDragLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("onDrop" === en)) {
        var pd_6 = (_co.dropEmpty($event) !== false);
        ad = (pd_6 && ad);
    } if (("click" === en)) {
        var pd_7 = (_co.addFlightMember() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { mergedHandicap: 0, dragHover: 1 }), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(5, 4341760, null, 0, i4.Droppable, [i1.ElementRef, i3.DomHandler, i1.NgZone], { scope: [0, "scope"] }, { onDragEnter: "onDragEnter", onDragLeave: "onDragLeave", onDrop: "onDrop" }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Player "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "fa-stack"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-circle-o fa-stack-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-plus fa-stack-1x"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dd-placeholder add-player"; var currVal_1 = _ck(_v, 3, 0, _co.mergedCells, _co.draggedOver); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "dd"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FlightMemberRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightMemberRendererComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.flightMember.flightMemberID != 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.flightMember.flightMemberID == 0); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FlightMemberRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "flight-member-renderer", [], null, null, null, View_FlightMemberRendererComponent_0, RenderType_FlightMemberRendererComponent)), i1.ɵdid(1, 49152, null, 0, i5.FlightMemberRendererComponent, [i6.FlightMemberService], null, null)], null, null); }
var FlightMemberRendererComponentNgFactory = i1.ɵccf("flight-member-renderer", i5.FlightMemberRendererComponent, View_FlightMemberRendererComponent_Host_0, { flightMember: "flightMember", competitionID: "competitionID", mergedCells: "mergedCells", showShots: "showShots" }, {}, []);
export { FlightMemberRendererComponentNgFactory as FlightMemberRendererComponentNgFactory };
