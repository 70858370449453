export class RoundLeaderboard {
    roundLeaderboardID: number = 0;
    title: string; 
    roundID: number = 0;
    leaderboardTypeID: number;
    scoreBestOf: number = 1;
    playerGender: number = 0;
    grossNetOption: any = 3;
    overrideGameType: number;
    flightMemberType: number = 0;
    topLeftMediaID: number;
    topRightMediaID: number;
    backgroundMediaID: number;
    viewOrder: number;
    aggregateScoreType: number = 3;
    aggregateThruType: number = 3;
    countbackType: number = 1;
    //Model??
    skinrollovertype: number;
    skinsPlayerFee: number;
    skinsStartingPot: number;
    skinsCurrencyType: number;
    showNetScores: number;
    isOnHandset: boolean;
    isOnWebsite: boolean;
    timer: number;
    filterCategoryArray: string[];
    isCreationMode: boolean;
    freezeOnXHole :number;
}