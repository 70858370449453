import { Injectable } from "@angular/core";
import { DeviceDetail, RoundMediaDetails, RoundMediaCommand } from "../models";
import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class DeviceAdvertService {
  constructor(public http: HttpClient, public httpService: HttpServices) {}

  getBanners(): Observable<DeviceDetail[]> {
    var bannersObserverable = new Subject<DeviceDetail[]>();
    this.httpService
      .getTyped<DeviceDetail[]>(
        `${environment.apiUrl}/api/DeviceAdverts/banners`
      )
      .subscribe(
        (data) => {
          bannersObserverable.next(data);
        },
        (err) => console.log(err)
      );
    return bannersObserverable;
  }

  getPopups(): Observable<DeviceDetail[]> {
    var popupsObserverable = new Subject<DeviceDetail[]>();
    this.httpService
      .getTyped<DeviceDetail[]>(`${environment.apiUrl}/api/DeviceAdverts/popup`)
      .subscribe(
        (data) => {
          popupsObserverable.next(data);
        },
        (err) => console.log(err)
      );
    return popupsObserverable;
  }

  getRoundBanner(roundId: number): Observable<DeviceDetail> {
    var bannerObserverable = new Subject<DeviceDetail>();
    this.httpService
      .getTyped<DeviceDetail>(
        `${environment.apiUrl}/api/DeviceAdverts/Round/${roundId}/banner`
      )
      .subscribe(
        (data) => {
          bannerObserverable.next(data);
        },
        (err) => console.log(err)
      );
    return bannerObserverable;
  }

  updateRoundBanner(banner: any, roundID: number): Observable<any> {
    let RoundMediaObserver = new Subject<any>();
    let url = `${environment.apiUrl}/api/DeviceAdverts/Round/${roundID}/banner/${banner.mediaId}`;
    this.httpService.put(url, banner).subscribe(
      (data: any) => {
        RoundMediaObserver.next(data);
        RoundMediaObserver.complete();
        return data;
      },
      (error) => console.log("Could not update banner.")
    );
    return RoundMediaObserver;
  }

  getRoundPopups(roundId: number): Observable<RoundMediaDetails[]> {
    var advertsObserverable = new Subject<RoundMediaDetails[]>();
    this.httpService
      .getTyped<RoundMediaDetails[]>(
        `${environment.apiUrl}/api/DeviceAdverts/Round/${roundId}/popups`
      )
      .subscribe(
        (data) => {
          advertsObserverable.next(data);
        },
        (err) => console.log(err)
      );
    return advertsObserverable;
  }

  updateRoundPopups(
    mediaList: RoundMediaCommand,
    roundID: number
  ): Observable<RoundMediaCommand> {
    let RoundMediaObserver = new Subject<RoundMediaCommand>();
    let url = `${environment.apiUrl}/api/DeviceAdverts/Round/${roundID}/popup`;
    this.httpService.put(url, mediaList).subscribe(
      (data: RoundMediaCommand) => {
        RoundMediaObserver.next(data);
        RoundMediaObserver.complete();
        return data;
      },
      (error) => console.log("Could not update Media.")
    );
    return RoundMediaObserver;
  }

  createRoundPopups(
    mediaList: RoundMediaCommand,
    roundID: number
  ): Observable<RoundMediaCommand> {
    let RoundMediaObserver = new Subject<RoundMediaCommand>();
    let url = `${environment.apiUrl}/api/DeviceAdverts/Round/${roundID}/popup`;
    this.httpService.post(url, mediaList).subscribe(
      (data: RoundMediaCommand) => {
        RoundMediaObserver.next(data);
        RoundMediaObserver.complete();
        return data;
      },
      (error) => console.log("Could not create Media.")
    );
    return RoundMediaObserver;
  }
}
