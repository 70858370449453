import { Component } from '@angular/core';

@Component({
    selector: 'publicmainapp',
    template: `
  <section>
  <router-outlet></router-outlet>
  </section>`,
    styles: ['body { overflow: hidden; }'],
})

export class PublicRouteComponent {
    title: string = 'VPAR';
}