import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Stats } from '../../../models';

@Component({
    selector: 'stats-modal',
    templateUrl: './stats-modal.component.html',
    styleUrls: ['./stats-modal.component.scss']
})

export class StatsModalComponent implements OnInit {

    @Input() stats: Stats[] = [];
    @Input() statLine: any;
    @Input() displayStatsDialog: boolean = false;
    @Input() statsType: boolean;

    @Output() displayStatsDialogChange = new EventEmitter();
    @Output() statsTypeChange = new EventEmitter();

    data: any = {};

    constructor() { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {       

        // when opening the modal
        if (changes.displayStatsDialog && changes.displayStatsDialog.currentValue) {
            this.buildStats();
        }
    }

    onStatsTypeChange(e){
        this.statsTypeChange.emit(e.target.checked);
        this.buildStats();
    }

    visibleChange(event) {
        this.displayStatsDialogChange.emit(event);
    }

    close(){
        this.displayStatsDialogChange.emit(false);
    }

    buildStats() {
        this.data = {
            title: this.statLine.title, total: this.statLine.total,
            field: this.statLine.field, players: [], order: this.statLine.order,
            netGross: this.statLine.netGross
        };        

        this.buildStatSection(this.data);
    }

    private buildStatSection(list) {

        const sortProperty = this.statsType ? 'net' : 'gross';

        let tempPlayers = [];
        if (list.order === 'asc') {
            tempPlayers = _.sortByOrder(_.filter(this.stats, function(item) { return item[sortProperty + list.field] !== 0 }), sortProperty + list.field, list.order);
            tempPlayers.push(..._.sortByOrder(_.filter(this.stats, function(item) { return item[sortProperty + list.field] === 0 }), 'salutation', 'asc'));
        } else {
            tempPlayers = _.sortByOrder(this.stats, sortProperty + list.field, list.order);
        }

        this.addPosition(tempPlayers, sortProperty, list.field);

        tempPlayers.forEach((item) => {
            list.players.push({
                pos: item.pos, name: item.salutation,
                valueNet: list.netGross ? item['net' + list.field] : item[`${list.field}`] ,
                valueGross: list.netGross ? item['gross' + list.field] : item[`${list.field}`] 
            });
        });
    }

    private addPosition(list: any[], sortProperty, field) {
        let pos = 1;
        for (let i = 0; i < list.length; i++) {
            if (list[i - 1] && list[i - 1][sortProperty + field] === list[i][sortProperty + field]) {
                if (list[i - 1].pos.indexOf('=') >= 0) {
                    list[i].pos = list[i - 1].pos;
                } else {
                    list[i - 1].pos = list[i - 1].pos + '=';
                    list[i].pos = list[i - 1].pos;
                }
            } else {
                list[i]['pos'] = pos.toString();
                pos++;
            }
        }
    }
}
