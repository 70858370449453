/// <reference path="../public/carousel.component.ts" />
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TournamentOverviewPlayerlistComponent } from './tournament-overview-playerlist.component';

import { DataTableModule, DialogModule, DropdownModule, SpinnerModule, InputTextModule } from 'primeng/primeng';
import { TeamsService } from '../../../services';
import { AppSharedModule } from '../../../shared/app.shared.module';
@NgModule({
    imports: [BrowserModule, FormsModule, ReactiveFormsModule, DataTableModule, DialogModule, DropdownModule, SpinnerModule, AppSharedModule, InputTextModule],    // module dependencies
    declarations: [TournamentOverviewPlayerlistComponent],   // components and directives
    exports: [TournamentOverviewPlayerlistComponent],     // root component
    providers: [TeamsService]
})
export class PlayerListModule { }
