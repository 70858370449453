import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Competition, IBranding } from "../../../models";
import {
  RoundService,
  CompetitionDataService,
  LeaderboardService,
} from "../../../services";
import { RoundRendererComponent } from "./round.renderer.component";
import { Helpers } from "../../../helpers/helpers";
import { Observable, Subject, forkJoin } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { switchMap } from "rxjs/operators";
import { OrderOfMeritService } from "src/app/services/orderOfMeritService";
import { OrderOfMeritSwitchInfo } from "src/app/models/OrderOfMerit/OrderOfMeritSwitchInfo";
import { OrderOfMeritSharedServices } from "src/app/shared/services/orderOfMeritSharedServices";

@Component({
  templateUrl: "tournament-edit.component.html",
  styleUrls: ["tournament-edit.component.scss"],
})
export class TournamentEditComponent implements OnInit, OnDestroy {
  competitionForm: FormGroup;
  model: Competition;
  routeComp: Competition;
  venueID: number;
  isAgent: boolean = false;
  dateFormat: string;
  dateFormatMoment: string;
  roundActiveIndex: number = 0;
  isDirty: boolean = false;
  isTeeAdjustment: boolean = false;
  roundIdsForDeletion: number[] = [];
  showVPARHandicap: boolean = false;
  showTeeAdjustment: boolean = false;
  displayModalHasScores: boolean = false;
  displaySaveWithModal: boolean = false;
  tempDisplaySaveWithModal: boolean = false;
  displayModal: boolean = false;
  competition: Competition;
  orderOfMeritActive: boolean = false;
  disabledOrderOfMeritToggle: boolean = false;
  orderOfMeritInfo: OrderOfMeritSwitchInfo = new OrderOfMeritSwitchInfo();

  public $stop: Subject<boolean> = new Subject<any>();

  constructor(
    private _fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private roundService: RoundService,
    public router: Router,
    private route: ActivatedRoute,
    public competitionDataService: CompetitionDataService,
    private leaderboardService: LeaderboardService,
    private toastr: ToastrService,
    private orderOfMeritService: OrderOfMeritService,
    private orderOfMeritSharedService: OrderOfMeritSharedServices
  ) {}

  createModel(competition: any): Competition {
    var momentSet = moment.utc(competition.startDate);
    if (momentSet.isValid) {
      competition.startDate = momentSet.toDate();
    }
    for (var i = 0; i < competition.rounds.length; i++) {
      // build start date of round this way to avoid problems with sum hours
      const momentSet = new Date(
        competition.rounds[i].startDate.substring(0, 4),
        parseInt(competition.rounds[i].startDate.substring(5, 7)) - 1,
        competition.rounds[i].startDate.substring(8, 10),
        competition.rounds[i].startDate.substring(11, 13),
        competition.rounds[i].startDate.substring(14, 16)
      );

      competition.rounds[i].startDate = momentSet; //Same column on Database. StartDate shows the date
      competition.rounds[i].startTime = momentSet; //Same column on Database. StartTime shows the time
    }
    return competition;
  }

  getBranding() {
    var branding = <IBranding>this.route.parent.snapshot.data["branding"];
    this.venueID = branding.venueID;
    this.isAgent = branding.isAgent;
    this.showVPARHandicap = branding.isVPARHandicap;
    let dateFormat = Helpers.DateUtil.jQueryFormatFromDotNet(
      branding.dateFormat
    ).format;
    this.dateFormat = dateFormat;
    this.dateFormatMoment = Helpers.DateUtil.momentFormatFromDotNet(
      branding.dateFormat
    ).format;
    this.orderOfMeritActive = branding.orderOfMeritActive;
  }

  ngOnInit() {
    this.getBranding();
    this.routeComp = _.cloneDeep(
      <Competition>this.route.snapshot.data["tournament"]
    );

    this.orderOfMeritService.getConfig().subscribe((c) => {
      if (c) {
        this.orderOfMeritInfo.active = true;
        this.orderOfMeritInfo.gameFormat = c.gameFormat;
      }

      // override the value if there is no active order of merit competition
      else {
        this.orderOfMeritActive = false;
        this.orderOfMeritInfo.active = false;
      }
    });

    // Disable order of merit toggle if the game is calculated
    if (this.routeComp.orderOfMeritStatus === 2) {
      this.disabledOrderOfMeritToggle = true;
    }

    // Hide order of merit if there are multiple rounds
    if (this.routeComp.rounds.length > 1) {
      this.orderOfMeritActive = false;
    }
    this.model = this.createModel(this.routeComp);
    if (this.model.numberOfPlayers > 0) {
      this.displaySaveWithModal = true;
      this.tempDisplaySaveWithModal = true;
    }
    this.competitionForm = this._fb.group({
      competitionID: [this.model.competitionID, Validators.required],
      title: [this.model.title, [Validators.required, Validators.minLength(2)]],
      rounds: this.initRounds(),
      isHandicapQualified: this.model.isHandicapQualified,
      isTeeAdjustment: this.model.isTeeAdjustment,
      startDate: [this.model.startDate, [Validators.required]],
      isOrderOfMerit: this.routeComp.isOrderOfMerit,
    });
    this.competitionForm.patchValue(this.routeComp);

    this.competitionDataService.useDecimalScoring$.subscribe((value) => {
      var self = this;
      var rounds = (<FormArray>this.competitionForm.controls["rounds"])
        .controls;

      _.forEach(rounds, function (round: FormGroup) {
        self.competitionDataService.decScoringBroadcasted$ = true;
        (<FormGroup>round.controls["roundConfiguration"]).controls[
          "useDecimalScoring"
        ].setValue(value);
      });
    });

    this.cd.detectChanges();
    this.showTeeAdjustment = true;
  }
  toggleTee() {
    this.isTeeAdjustment = !this.isTeeAdjustment;
  }

  setOrderOfMerit(e) {
    this.competitionForm.get("isOrderOfMerit").setValue(e.checked);
    this.orderOfMeritInfo.active =
      this.competitionForm.get("isOrderOfMerit").value;
    this.orderOfMeritSharedService.sendInfo(this.orderOfMeritInfo);
  }
  receiveGameTypeChange(message: string) {
    if (message == "2") {
      this.competitionForm.get("isHandicapQualified").setValue(false);
      this.competitionForm.get("isHandicapQualified").disable();
    } else this.competitionForm.get("isHandicapQualified").enable();
  }

  initRounds() {
    var array = this._fb.array([]);
    var newArray = [];
    this.model.rounds.forEach((round) => {
      this.roundService.hasScores(round.roundID).subscribe((hasScores) => {
        round.hasScores = hasScores;
        if (hasScores) {
          this.competitionForm.controls["isHandicapQualified"].disable();
          this.competitionForm.controls["isTeeAdjustment"].disable();
          this.displayModalHasScores = true;
        }
        newArray.push(round);

        newArray.sort((a, b) => {
          if (a.roundID < b.roundID) return -1;
          if (a.roundID > b.roundID) return 1;
          return 0;
        });

        array.clear();

        newArray.forEach((sortedRound) => {
          array.push(
            RoundRendererComponent.buildItem(sortedRound, this.venueID != 0)
          );
        });
      });
    });
    return array;
  }

  getRounds(): FormGroup[] {
    return (this.competitionForm.controls["rounds"] as FormArray)
      .controls as FormGroup[];
  }

  cancel() {
    this.ngOnInit();
    this.roundActiveIndex = 0;
    this.isDirty = false;
  }

  addRound() {
    var rounds = <FormArray>this.competitionForm.controls["rounds"];
    var latest = rounds.getRawValue()[rounds.length - 1];
    var latestCopy = _.clone(latest);
    latestCopy.roundID = 0;
    latestCopy.startDate = null;
    latestCopy.startTime = null;
    latestCopy.courseID = null;
    latestCopy.maleHoleColourID = null;
    latestCopy.femaleHoleColourID = null;
    latestCopy.courseID = latest.courseID;
    var newRound: FormGroup = RoundRendererComponent.buildItem(
      latestCopy,
      this.venueID != 0,
      true
    );
    rounds.push(newRound);
    this.roundActiveIndex = rounds.length - 1;
    this.isDirty = true;
    this.displaySaveWithModal = false;
  }

  deleteRound(roundIndex) {
    var rounds = <FormArray>this.competitionForm.controls["rounds"];
    var roundId = (<FormGroup>rounds.controls[roundIndex]).controls["roundID"]
      .value;
    this.roundIdsForDeletion.push(roundId);
    rounds.removeAt(roundIndex);

    if (roundIndex == rounds.length) {
      this.roundActiveIndex = roundIndex - 1;
    } else {
      this.roundActiveIndex = roundIndex;
    }
    this.isDirty = true;
    this.displaySaveWithModal = this.tempDisplaySaveWithModal;
  }

  setTeeAdjustmentManully(e) {
    if (e.checked) {
      this.isTeeAdjustment = true;
    } else {
      this.isTeeAdjustment = false;
    }
  }

  setIsHandicapQualifiedManually(e) {
    if (e.checked) {
      this.competitionForm.get("isHandicapQualified").setValue(true);
    } else {
      this.competitionForm.get("isHandicapQualified").setValue(false);
    }
  }

  tabChange(e) {
    this.roundActiveIndex = e.index;
  }

  next() {
    var redirectUrl = `/tournaments/${this.model.competitionID}/round/${this.competitionForm.value.rounds[0].roundID}/playerlist`;
    this.router.navigateByUrl(redirectUrl);
  }

  onSubmit(competition: Competition) {
    const roundOneST = competition.rounds[0].startDate;

    competition.startDate = roundOneST;

    // Proceed with other form submission logic
    competition.RoundIdsForDeletion = this.roundIdsForDeletion;

    competition.startDate.setHours(12);
    competition.startDate.setMinutes(0);
    competition.startDate = Helpers.DateUtil.UTCDate(competition.startDate);
    competition.isOrderOfMerit =
      this.competitionForm.get("isOrderOfMerit").value;

    competition.rounds.forEach((round) => {
      round["venueSelect"] = null;
      round.startDate = new Date(
        round.startDate.getFullYear(),
        round.startDate.getMonth(),
        round.startDate.getDate(),
        round.startTime.getHours(),
        round.startTime.getMinutes()
      );
      round.startDate = Helpers.DateUtil.UTCDateTime(round.startDate);
    });

    if (this.validDates(competition)) {
      this.competitionDataService
        .update(competition)
        .subscribe((data: Competition) => {
          const rounds = <FormArray>this.competitionForm.get("rounds");
          rounds.controls.forEach((round) => {
            if (round.dirty) {
              const roundControl = <FormGroup>round;
              this.leaderboardService
                .recalculateLeaderboard(roundControl.controls["roundID"].value)
                .subscribe({ error: (e) => console.error(e) });
            }
          });

          this.route.snapshot.data["tournament"] = data;

          this.competitionForm.markAsPristine();
          this.isDirty = false;
          this.toastr.success("The tournament was successfully updated");
        });
    }
  }

  // TODO: come back and make this in one method
  onSubmitObservable(competition: Competition): Observable<any> {
    return new Observable((observer) => {
      competition.RoundIdsForDeletion = this.roundIdsForDeletion;

      competition.startDate.setHours(12);
      competition.startDate.setMinutes(0);
      competition.startDate = Helpers.DateUtil.UTCDate(competition.startDate);
      competition.isOrderOfMerit =
        this.competitionForm.get("isOrderOfMerit").value;

      competition.rounds.forEach((round) => {
        round["venueSelect"] = null;
        round.startDate = new Date(
          round.startDate.getFullYear(),
          round.startDate.getMonth(),
          round.startDate.getDate(),
          round.startTime.getHours(),
          round.startTime.getMinutes()
        );
        round.startDate = Helpers.DateUtil.UTCDateTime(round.startDate);
      });

      if (this.validDates(competition)) {
        this.competitionDataService
          .update(competition)
          .subscribe((data: Competition) => {
            // when something is updated in round, then call recalculate leaderboard
            const rounds = <FormArray>this.competitionForm.get("rounds");
            rounds.controls.forEach((round) => {
              if (round.dirty) {
                const roundControl = <FormGroup>round;
                this.leaderboardService
                  .recalculateLeaderboard(
                    roundControl.controls["roundID"].value
                  )
                  .subscribe({ error: (e) => console.error(e) });
              }
            });

            this.route.snapshot.data["tournament"] = data;

            this.competitionForm.markAsPristine();
            this.isDirty = false;
            this.toastr.success("The tournament was successfully updated");
            observer.next(); // Notify that onSubmit has finished
            observer.complete();
          });
      } else {
        observer.error("Invalid dates");
      }
    });
  }

  openModal(competition: any) {
    this.competition = competition;
    this.displayModal = true;
  }
  closeModal() {
    this.displayModal = false;
  }

  revertChanges() {
    var redirectUrl = `/tournaments/${this.model.competitionID}/round/${this.competitionForm.value.rounds[0].roundID}/players`;
    this.router.navigateByUrl(redirectUrl);
    this.displayModal = false;
  }

  keepChanges() {
    this.onSubmit(this.competition);
    this.displayModal = false;
  }

  recalculateHandicaps() {
    let isError = false;
    this.onSubmitObservable(this.competition)
      .pipe(
        switchMap(() => {
          const observables = this.competition.rounds.map((round) => {
            return this.roundService.recalculateHandicap(round.roundID);
          });
          return forkJoin(observables);
        })
      )
      .subscribe(
        (responses) => {
          responses.forEach((response) => {
            if (!response.success) {
              isError = true;
              return;
            }
          });

          if (!isError) {
            this.toastr.success("All Flightmember have been updated ");
          }
        },
        (error) => {
          console.error("Error recalculating handicaps:", error);
          isError = true;
        },
        () => {
          this.displayModal = false;
        }
      );
  }

  validDates(competition: Competition) {
    // check round are ordered in time
    for (let i = 0; i < competition.rounds.length; i++) {
      if (i + 1 < competition.rounds.length) {
        if (
          moment(competition.rounds[i].startDate).isAfter(
            moment(competition.rounds[i + 1].startDate)
          )
        ) {
          this.toastr.error("Round dates should be ordered");
          return false;
        }
      }
    }

    // check rounds doesn't start before tournament date
    if (
      moment(competition.startDate).isAfter(
        moment(competition.rounds[0].startDate),
        "day"
      )
    ) {
      this.toastr.error("Tournament date should be before rounds dates");
      return false;
    }

    return true;
  }

  ngOnDestroy() {
    this.$stop.next(true);
  }
}
