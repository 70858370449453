/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "primeng/components/dialog/dialog";
import * as i4 from "@angular/forms";
import * as i5 from "./leaderboard.item.delete.component";
import * as i6 from "../../../services/LeaderboardService";
var styles_LeaderboardItemDeleteComponent = [];
var RenderType_LeaderboardItemDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeaderboardItemDeleteComponent, data: {} });
export { RenderType_LeaderboardItemDeleteComponent as RenderType_LeaderboardItemDeleteComponent };
export function View_LeaderboardItemDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 26, "p-dialog", [["header", "Are you sure?"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.displayIteamDeleteDialog = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i0.ɵdid(2, 12763136, null, 2, i3.Dialog, [i0.ElementRef, i2.DomHandler, i0.Renderer2, i0.NgZone], { header: [0, "header"], draggable: [1, "draggable"], resizable: [2, "resizable"], width: [3, "width"], modal: [4, "modal"], responsive: [5, "responsive"], visible: [6, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i0.ɵeld(5, 0, null, 1, 21, "div", [["style", "height:240px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(8, 4210688, [["f", 4]], 0, i4.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i0.ɵdid(10, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You're about to delete the following item"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Once your delete this screen you can't undo it "])), (_l()(), i0.ɵeld(18, 0, null, null, 8, "div", [["class", "col-md-12 m-t-30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "div", [["class", "btn-group"], ["style", "float:right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 2, "button", [["class", "btn btn-dark m-b-5"], ["label", "Cencel"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Save($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"])), (_l()(), i0.ɵeld(23, 0, null, null, 3, "div", [["class", "btn-group"], ["style", "float:right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 2, "button", [["class", "btn btn-dark m-b-5"], ["label", "Save"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Are you sure?"; var currVal_1 = false; var currVal_2 = false; var currVal_3 = 750; var currVal_4 = true; var currVal_5 = true; var currVal_6 = _co.displayIteamDeleteDialog; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_8 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_9 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_10 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_11 = i0.ɵnov(_v, 10).ngClassValid; var currVal_12 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_13 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.ItemTitle; _ck(_v, 15, 0, currVal_14); }); }
export function View_LeaderboardItemDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "leaderboard-item-delete", [], null, null, null, View_LeaderboardItemDeleteComponent_0, RenderType_LeaderboardItemDeleteComponent)), i0.ɵdid(1, 49152, null, 0, i5.LeaderboardItemDeleteComponent, [i6.LeaderboardService], null, null)], null, null); }
var LeaderboardItemDeleteComponentNgFactory = i0.ɵccf("leaderboard-item-delete", i5.LeaderboardItemDeleteComponent, View_LeaderboardItemDeleteComponent_Host_0, { displayIteamDeleteDialog: "displayIteamDeleteDialog", ItemToRemove: "ItemToRemove", ItemTitle: "ItemTitle" }, { CloseDeleteItem: "CloseDeleteItem" }, []);
export { LeaderboardItemDeleteComponentNgFactory as LeaderboardItemDeleteComponentNgFactory };
