
import {share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from '../../environments/environment';
import { HttpServices } from './HttpServices';

@Injectable()
export class MediaService {
    progress: number;
    progress$: Observable<any>;
    Media$: Observable<any>;
    FileName$: Observable<string>;
    jwtHelper: JwtHelperService = new JwtHelperService();    

    private FileNameObserver: Observer<string>;

    private _dataStoreFileName: {
        fileName: string;
    };

    constructor(public httpService: HttpServices) {
        this.FileName$ = new Observable<string>(observer => this.FileNameObserver = observer).pipe(share());        
        this._dataStoreFileName = { fileName: "" };
    }

   
    GetCartSignFileName(roundId: string): void {
      let url = `${environment.apiUrl}/api/Media/GetCartSignFileName/${roundId}`;
        this.httpService.get(url).subscribe((data: string) => {
            this._dataStoreFileName.fileName = data;
            this.FileNameObserver.next(this._dataStoreFileName.fileName);
            console.log("Complete Get Golfer details");
        }, error => console.log('Could not load Golfer details.'));
    }
}
