<p-dialog [(visible)]="displayIteamDeleteDialog" header="Are you sure?" [responsive]="true" [modal]="true" [width]="750" [resizable]="false" [draggable]="false">
    <div style="height:240px;">
        <form #f="ngForm">
            <div class="col-md-12">
                <h3>You're about to delete the following item</h3>
                <h3>{{ItemTitle}}</h3>
                <h3>Once your delete this screen you can't undo it </h3>
            </div>
            <div class="col-md-12 m-t-30">
                <div class="btn-group" style="float:right">
                    <button type="submit" class="btn btn-dark m-b-5" label="Cencel" (click)="Save($event)"><i class="fa fa-check"></i>Delete</button>
                </div>
                <div class="btn-group" style="float:right">
                    <button type="submit" class="btn btn-dark m-b-5" label="Save" (click)="Close($event)"><i class="fa fa-close"></i>Cancel</button>
                </div>
            </div>
        </form>
    </div>
</p-dialog>