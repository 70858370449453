import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialog } from '../confirm-dialog/index';
import { Subject } from 'rxjs';

@Component({
    selector: 'score-tab',
    templateUrl: 'score-tab.component.html'
})

export class ScoreTabComponent implements OnInit {

    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;
    scorecardsEditingStatus = [];
    subject: Subject<boolean>;

    constructor() { }

    ngOnInit() { }

    editScorecardChanged(index, value) {
        this.scorecardsEditingStatus[index] = value;
    }

    isEditingScorecards() {
        let result = false;
        this.scorecardsEditingStatus.forEach((scorecard) => {
            if (scorecard) {
                result = true;
            }
        });
        return result;
    }

    canDeactivate() {
        var self = this;
        var callback = function () {
            self.subject.next(true);
            self.subject.complete();
        }
        var callback2 = function () {
            self.subject.next(false);
            self.subject.complete();
        }

        this.confirmDialog.showDualDialog(callback, callback2);
    }
}
