import { AbstractControl, ValidatorFn } from '@angular/forms';

enum Offering {
    Society = 1,
    Basic = 2,
    Challenge = 3,
    Tour = 4,
    Major = 5
};

const offeringLevelPermission = [{ action: "entryCode", disabled: [Offering.Society] }
    , { action: "playerUpload", disabled: [Offering.Society] }
    , { action: "printing", disabled: [Offering.Society] }
    , { action: "printing2", disabled: [Offering.Society] }
    , { action: "decimalScoring", disabled: [Offering.Society, Offering.Basic] }
    , { action: "multiRoundScoring", disabled: [Offering.Society, Offering.Basic] }
    , { action: "paceOfPlayManagement", disabled: [Offering.Society, Offering.Basic, Offering.Challenge] }
    , { action: "onCourseMessaging", disabled: [Offering.Society, Offering.Basic, Offering.Challenge] }
    , { action: "tracking", disabled: [Offering.Society, Offering.Basic, Offering.Challenge] }
    , { action: "deviceLBBranding", disabled: [Offering.Society, Offering.Basic, Offering.Challenge, Offering.Tour] }
    , { action: "tvMobileAdvertSpace", disabled: [Offering.Society, Offering.Basic, Offering.Challenge, Offering.Tour] }
    , { action: "holeConfig", disabled: [Offering.Society, Offering.Basic, Offering.Challenge, Offering.Tour] }
    , { action: "messaging", disabled: [Offering.Society, Offering.Basic, Offering.Challenge] }
];

export function checkFeatureDisabled(action: string, offeringLevel: number) {
    const permission = offeringLevelPermission.find(off => { return off.action === action });
    return permission.disabled.indexOf(offeringLevel) >= 0;
}
