import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GolferMember } from 'src/app/models';
import { GolfersServices } from 'src/app/services';

@Component({
  selector: 'add-golfers-bulk',
  templateUrl: './add-golfers-bulk.component.html',
  styleUrls: ['./add-golfers-bulk.component.scss']
})
export class AddGolfersBulkComponent implements OnInit {

  @Input() displayBulkUpload: boolean = false;
  @Input() golfers: GolferMember[] = [];
  @Output() displayChange = new EventEmitter();

  public emptyMessage: string = "Loading...";
  public disableConfirm: boolean = false;

  constructor(private golfersService: GolfersServices) { }

  ngOnInit() {
  }

  visibleChange(event) {
    this.golfersService.stopConnection();
    this.displayChange.emit(event);
    this.disableConfirm = false;
  }

  addMembersBulk() {
    this.disableConfirm = true;
    this.golfersService.addByEmailBulk(this.golfers);
  }

}
