<div class="page-content">
  <div class="row" id="table-section">
    <div class="col-lg-12 portlets ui-sortable table-round">
      <div class="panel comp-info-panel" id="table-round">
        <div class="header">
          <h2>{{orderOfMeritConfig.title}}</h2>
          <p>{{orderOfMeritConfig.description}}</p>
        </div>
        <div class="edit-button">
          <button class="btn" (click)="redirectToEdit()">Edit order of merit</button>
        </div>
      </div>
      <div class="middle-section">
        <div class="middle-panels">
          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms_icons_calendar.svg" alt="" />
            {{startFormattedDate}} - {{endFormattedDate}}
          </p>

          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms_icons_scorecard.svg" alt="" />
            <span *ngIf="gameFormatStringValue === 'Stableford'">Format Stableford games only</span>
            <span *ngIf="gameFormatStringValue === 'Strokeplay'">Format Strokeplay games only</span>
            <span *ngIf="gameFormatStringValue === 'Both'">Format Strokeplay & Stableford</span>
          </p>


          <p>
            <img src="../../../assets/icons/order-of-merit-icons/tms_icons_podium.svg" alt="" />
            <span *ngIf="pointsFormatStringValue === 'MeritPoints'">Custom Merits Points</span>
            <span *ngIf="pointsFormatStringValue === 'StrokePlay'">Accumulated Strokeplay</span>
            <span *ngIf="pointsFormatStringValue === 'StableFord'">Accumulated Stableford</span>
          </p>

          <p [hidden]="!orderOfMeritConfig.bestOf">
            <img src="../../../assets/icons/order-of-merit-icons/tms_icons_tournaments.svg" alt="" />
            Best {{orderOfMeritConfig.bestOf}}
          </p>
        </div>
        <div class="middle-panels">
          <div class="img-div">
            <img src="../../../assets/icons/overview-icons/done.svg" alt="">
          </div>
          <div>
            <p>OOM Competitions</p>
            <p class="stats">{{orderOfMeritConfig.numberOfCurrentGames}}</p>
          </div>
        </div>
        <div class="middle-panels">
          <div class="share-text">
            <h3>Leaderboard</h3>
            <p>Share your public order of merit leaderboard</p>
          </div>
          <div class="share-button">
            <button class="btn" (click)="redirectToLeaderboard()">Share</button>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-12">
          <div class="panel">
            <div class="panel-content tournament-leaderboard-container" style="min-height:128px;">
              <div class="menu-container">
                <vpar-tabMenu [model]="tabItems" styleClass="media-manage-menu" [activeItem]="activeTab"></vpar-tabMenu>
              </div>
              <div *ngIf="activeTabIndex === 0">
                <p-dataTable [emptyMessage]="emptyGamesMessage" #dt [value]="games" class="table table-dynamic"
                  [rows]="20" [responsive]="true">
                  <p-column field="title" header="Title"></p-column>
                  <p-column field="startDate" header="Start Date">
                    <ng-template let-col let-game="rowData" pTemplate="body">
                      {{ game.startDate | date:'dd/M/yyyy' }}
                    </ng-template>
                  </p-column>
                  <p-column field="endDate" header="End Date">
                    <ng-template let-col let-game="rowData" pTemplate="body">
                      {{ game.endDate | date:'dd/M/yyyy' }}
                    </ng-template>
                  </p-column>
                  <p-column field="gameStatus" header="Game Status">
                    <ng-template pTemplate="body" let-rowData="rowData">
                      <span *ngIf="rowData.gameStatus=='0'">Not Started</span>
                      <span *ngIf="rowData.gameStatus=='1'">In Progress</span>
                      <span *ngIf="rowData.gameStatus=='2'">Calculated</span>
                    </ng-template>
                  </p-column>
                  <p-column header="Action">
                    <ng-template pTemplate="body" let-rowData="rowData">
                      <img *ngIf="rowData.gameStatus !== 2" src="assets/icons/order-of-merit-icons/send.svg"
                        alt="Send the game for calculation" title="Send the game for calculation"
                        (click)="sendGameForCalculation($event, rowData)">
                    </ng-template>
                  </p-column>
                </p-dataTable>

              </div>
              <div *ngIf="activeTabIndex === 1">
                <div class="player-list">
                  <div class="table-section-first">
                    <div class="table-section-search" [hidden]="!players">
                      <i class="fa fa-search"></i>
                      <input type="text" id="search-bar" (input)="customFilter($event)" placeholder="Search Players">
                    </div>
                  </div>
                  <div [hidden]="leaderboardData.length === 0">
                    <table id="leaderboardTable">
                      <thead>
                        <tr>
                          <th style="padding-left: 20px; font-size: 15px; width:5%;">Pos</th>
                          <th style="font-size: 15px; margin-left:-30px; width:30%;">Name</th>
                          <th style="font-size: 15px;">HCP</th>
                          <th style="font-size:15px;">Played</th>
                          <th style="font-size: 15px;">Total</th>
                          <th *ngFor="let score of tournamentScores; let i = index"
                            style="color: #e65c24; font-weight: bold;">
                            {{ getDaySuffixVal(i + 1) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of leaderboardData">
                          <td class="Pos">{{ item.Position }}</td>
                          <td>
                            <img [src]="item.Thumbnail.Url" alt="Thumbnail" class="thumbnail">
                            <span class="title_image">{{ item.Title }}</span>

                            <span *ngIf="item.IsPremium" class="spanone">
                              <img src="assets/images/premium_icon.png" alt="premium_icon" class="premium-image">
                            </span>

                          </td>
                          <td style="font-size: 15px;">{{ formatHCP(item.HCP) }}</td>
                          <td style="font-size: 15px;">{{ item.Played }}</td>
                          <td style="font-size: 15px;">{{ item.Total }}</td>
                          <td *ngFor="let score of item.IndexOfScores" class="round_data">{{ score.Points }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div [hidden]="leaderboardData.length !== 0" class="condition-leaderboard">
                    <div class="empty-leaderboard">
                      <img src="../../../assets/icons/order-of-merit-icons/tms_icons_add golfer.svg" alt="">
                      <p id="title">No players have qualified</p>
                      <p id="text">When a golfer qualifies for the order of merit they will appear here.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
    <p-footer>
      <button class="btn btn-dark m-b-5 cancel-btn" type="button" pButton label="Cancel" (click)="cd.reject()"></button>
      <button class="btn btn-dark m-b-5 confirm-btn" type="button" pButton label="Confirm"
        (click)="cd.accept()"></button>
    </p-footer>
  </p-confirmDialog>
</div>