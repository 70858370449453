<div class="page-content">
    <div class="col-md-12" style="padding-top:25px;" [hidden]="profileDisplayed">
        <div class="row" id="table-section">
            <div class="panel col-md-12">
                <div class="panel-content">
                    <div class="golfer-header panel-header">
                        <div class="col-md-6 header-title">
                            <h3>
                                Members
                            </h3>
                        </div>
                        <div class="form-group pull-right has-search col-md-6">
                            <div class="search-input-container">
                                <i class="fa fa-search"></i>
                                <input #gb type="text" placeholder="Search by name or email" class="form-control">
                                <button type="button" class="btn btn-dark m-b-5" style="float:right;"  iconPos="left" label="Add Member" (click)="addGolfer()">
                                    <img src="assets/icons/menu-icons/add.svg" alt="">
                                    Add Members
                                </button>
                                <button type="button" class="btn m-b-5 download-btn" style="float:right;"  iconPos="left" label="CSV" (click)="dt.exportCSV()">
                                    <img src="assets/icons/menu-icons/download-icon.svg" alt="">
                                    Download List
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="panel-content">
                        <p-dataTable [emptyMessage]="emptyGolfersMessage" #dt [value]="golfersFiltered"  class="table table-dynamic" [rows]="20" [paginator]="true" [responsive]="true" [globalFilter]="gb" (onRowClick)="loadProfile($event)">
                            <!--selectionMode="single" has been disabled for a short period-->
                            <p-column field="displayName" header="Name">
                                <ng-template pTemplate="body" let-golfer="rowData">
                                    <span class="f-left mobile-hidden"><img class="profilePicture" [src]="showProfilePicture(golfer)" /></span>
                                    <span class="m-t-10 edit-cell player-name">{{golfer.firstName}} {{golfer.surname}}</span>
                                </ng-template>
                            </p-column>
                            <p-column header="Email" field="emailAddress"> </p-column>
                            <p-column header="Profile handicap" field="handicap"></p-column>
                            <p-column header="VPAR handicap" field="vparHandicap"></p-column>
                            <p-column header="Gender" field="gender">
                                <ng-template pTemplate="body" let-golfers="rowData">
                                    <span *ngIf="golfers.gender=='1'">Male</span>
                                    <span *ngIf="golfers.gender=='2'">Female</span>
                                </ng-template>
                            </p-column>
                            <p-column header="Removed">
                               <ng-template pTemplate="body" let-golfer="rowData">
                                    <img src="assets/icons/menu-icons/remove-golfer.svg" alt="" (click)="removeGolfer($event, golfer)">
                               </ng-template>
                            </p-column>
                        </p-dataTable>
                    </div>
                </div>
            </div>
            <add-golfer (closePlayerDialog)="handleClosePlayerDialog()"></add-golfer>

        </div>
    </div>
    <router-outlet></router-outlet>
    <add-golfer  [(displayPlayerDialog)]="displayPlayerDialog"
                 [addedGolfers]="addedGolfers"
                 [duplicateGolfers]="duplicateGolfers"
                 [newGolfers]="newGolfers"
                 ></add-golfer>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
        <p-footer>
          <button class="btn btn-dark m-b-5 cancel-btn"
          type="button" pButton  label="Cancel" (click)="cd.reject()"></button>
            <button class="btn btn-dark m-b-5 confirm-btn"
            type="button" pButton  label="Confirm" (click)="cd.accept()"></button>
        </p-footer>
    </p-confirmDialog>

    <add-golfers-bulk [(golfers)]="golferMembers"  [(displayBulkUpload)]="displayBulkUpload"
        (displayChange)="displayChange($event)">
    </add-golfers-bulk>
</div>
