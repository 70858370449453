import { Component, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { FlightMember } from '../../../models';
import { ScorecardComponent } from '../../../shared/index';

@Component({
    selector: 'player-renderer',
    templateUrl: 'player-renderer.component.html',
    styleUrls: ['player-renderer.component.scss']
})
export class PlayerRendererComponent {

    @Input() player: FlightMember;
    @Input() roundID: number;
    @Input() tournamentOpen: boolean;
    
    @Input() femaleTeeColor: number;
    @Input() maleTeeColor: number;
    @Input() courseData: any;

    @Output() onEditingChange = new EventEmitter<boolean>(false);

    @ViewChild(ScorecardComponent, { static: false }) scorecardComponent: ScorecardComponent;

    open = false;
    
    constructor(public elementRef: ElementRef) {
    }

    toggle() {
        var flightHolder = $(this.elementRef.nativeElement).find('div.playerHolder');
        this.open = !this.open;
        if (this.open) {
            this.scorecardComponent.loadScores();
        } else{
            this.scorecardComponent.resetData();
            this.onEditingChange.emit(false);
        }

        $(flightHolder).toggle(500);
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }

    editScorecardChanged(value: boolean){
        this.onEditingChange.emit(value);
    }
}
