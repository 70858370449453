import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, Observer, Subject } from "rxjs";
import { Competition } from "../models";

import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";

import { environment } from "./../../environments/environment";
import { HttpServices } from "./HttpServices";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "./login.service";
import { AppCookieService } from "./CookiesServices";

export interface ICompetitionDataService {
  competitions$: Observable<any[]>;
  getTournament(tournamentId: string): Observable<Competition>;
  loadCompetitions(params: HttpParams): void;
}

//Based on https://coryrylan.com/blog/angular-2-observable-data-services
@Injectable()
export class CompetitionDataService implements ICompetitionDataService {
  useDecimalScoring$: Subject<boolean> = new Subject<boolean>();
  decScoringBroadcasted$: boolean = false;
  private competitionObserver: Observer<Competition>;
  competitions$: Observable<any>;
  private competitionsObserver: Observer<any>;
  private dataStore: {
    competitions: Competition[];
    totalCount: number;
    filteredCount: number;
  };
  private authToken: string;
  constructor(
    private toastr: ToastrService,
    private httpService: HttpServices,
    private loginService: LoginService,
    private httpClient: HttpClient,
    private cookieService: AppCookieService
  ) {
    // Create Observable Stream to output our data
    this.competitions$ = new Observable(
      (observer) => (this.competitionsObserver = observer)
    ).pipe(share());
    this.dataStore = { competitions: [], totalCount: 0, filteredCount: 0 };
  }
  getTournament(tournamentId: string): Observable<Competition> {
    if (tournamentId == null) {
      var comp = new Competition();
      var obs = new Observable<Competition>(
        (observer) => (this.competitionsObserver = observer)
      ).pipe(share());
      return obs;
    }
    let url = `${environment.apiUrl}/api/Competitions/${tournamentId}`;
    var self = this;
    return this.httpService.getTyped<Competition>(url).pipe(share());
  }

  add(competition: Competition): Observable<Competition> {
    var observer = new Subject<Competition>();

    this.setRoundTitles(competition);

    this.httpService
      .post(`${environment.apiUrl}/api/competitions`, competition)
      .subscribe(
        (data: Competition) => {
          observer.next(data);
          observer.complete();
        },
        (err) => console.log(err),
        () => console.log("Competition updated")
      );
    return observer;
  }

  update(tournament: Competition): Observable<Competition> {
    this.setRoundTitles(tournament);

    return this.httpService.putTyped<Competition>(
      `${environment.apiUrl}/api/competitions`,
      tournament
    );
  }

  remove(competitionID: number, profileId: number): void {
    // TODO : move all logic to http service and use the token
    this.loginService.loggedInDetail$.subscribe((token) => {
      if (token) {        
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          APIToken: token.AuthToken,
          CompetitionHostId: this.cookieService.getCompetitionHostIdCookie(),
        });
        const options = { headers: headers };
        this.httpClient
          .delete(
            `${environment.competitionApiUrl}/api/5.0/competition/${competitionID}/profile/${profileId}/tms`,
            options
          )
          .subscribe(
            () => {
              var currentIndex = _.findIndex(this.dataStore.competitions, {
                competitionID: competitionID,
              });
              if (currentIndex != -1) {
                this.dataStore.filteredCount = this.dataStore.filteredCount - 1;
                this.dataStore.totalCount = this.dataStore.totalCount - 1;
                this.dataStore.competitions.splice(currentIndex, 1);
                this.dataStore.competitions =
                  this.dataStore.competitions.slice(0);
              }
              this.competitionsObserver.next(this.dataStore);
              this.toastr.success("The tournament was successfully removed");
            },
            (err) => {
              this.toastr.error(
                "The tournament could not be removed. Please contact system administrator"
              );
            }
          );
      }
    });
  }

  loadCompetitions(params: HttpParams): void {
    this.httpService
      .get(`${environment.apiUrl}/api/Competitions`, null, params)
      .subscribe(
        (response) => {
          // Update data store
          this.dataStore.competitions = response.data;
          this.dataStore.totalCount = response.totalCount;
          this.dataStore.filteredCount = response.filteredCount;
          // Push the new list of compeitions into the Observable stream
          this.competitionsObserver.next(this.dataStore);
        },
        (error) => console.log(`Could not load competitions.${error}`)
      );
  }

  deserialize(json, instance) {
    var newObject = jQuery.extend(instance, json);
    return instance;
  }

  private setRoundTitles(competition) {
    for (let i = 0; i < competition.rounds.length; i++) {
      competition.rounds[i].title = "Round " + (i + 1);
    }
  }
}
