<div style="max-height:300px">
    <div style="max-height:300px !important; position:relative">
        <img [src]="ChildDisplayDetails.imgurl" class="img-responsive podimg" alt="" style="max-height:300px; min-height:200px; min-width:300px; margin-left:-1px; max-height:200px; border:1px solid #cccccc">
        <div class="Pod_OverLay">
            <div class="col-md-12">
                <!--<div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary" style="min-width:150px; margin-top:10px; opacity:1 !important" *ngIf="ChildDisplayDetails.tvdisplayType == 2">Preview</button>
                    </div>
                </div>-->
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary" style="min-width:150px; margin-top:33px; opacity:1 !important" value="{{ChildDisplayDetails.tvdisplayID}}" (click)="Remove()">Delete</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary" style="min-width:150px; margin-top:10px; opacity:1 !important" (click)="Edit()">Edit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row podtitleoutline" style="border:1px solid #cccccc; border-top:none; margin-bottom:20px; margin-top:15px; width:300px;">
            <div class="text-center">
                <h4>
                    <strong>{{ChildDisplayDetails.title}}</strong>
                </h4>
            </div>
            <div class="col-md-3">
                <i *ngIf="ChildDisplayDetails.tvdisplayType == 2" class="fa fa-clock-o" aria-hidden="true">  {{ChildDisplayDetails.timer}}</i>
            </div>
            <div class="col-md-6 text-center">
                <h5 *ngIf="ChildDisplayDetails.tvdisplayType == 1">Leaderboard</h5>
                <h5 *ngIf="ChildDisplayDetails.tvdisplayType == 2">{{'TOURNAMENT.LEADERBOARD.ADVERT' | translate}}</h5>
            </div>
            <div class="col-md-3">
            </div>
        </div>
    </div>
</div>