/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./team.header.renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/dropdown/dropdown.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/utils/objectutils";
import * as i5 from "primeng/components/dropdown/dropdown";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "../../shared/directives/only.logged.in.directive";
import * as i9 from "../../services/login.service";
import * as i10 from "./team.header.renderer.component";
import * as i11 from "../../services/FlightMembersService";
import * as i12 from "@angular/router";
var styles_TeamHeaderRendererComponent = [i0.styles];
var RenderType_TeamHeaderRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeamHeaderRendererComponent, data: {} });
export { RenderType_TeamHeaderRendererComponent as RenderType_TeamHeaderRendererComponent };
function View_TeamHeaderRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "p-dropdown", [], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.team.teamID = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Dropdown_0, i2.RenderType_Dropdown)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵprd(512, null, i4.ObjectUtils, i4.ObjectUtils, []), i1.ɵdid(5, 13877248, null, 1, i5.Dropdown, [i1.ElementRef, i3.DomHandler, i1.Renderer2, i1.ChangeDetectorRef, i4.ObjectUtils, i1.NgZone], { style: [0, "style"], options: [1, "options"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵpod(7, { "width": 0, height: 1 }), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.Dropdown]), i1.ɵdid(9, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(11, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check editCheck"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmChangeTeam() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times editTimes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelChangeTeam() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _ck(_v, 7, 0, "150px", "25px"); var currVal_10 = _co.teamSelect; _ck(_v, 5, 0, currVal_9, currVal_10); var currVal_11 = _co.team.teamID; _ck(_v, 9, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).filled; var currVal_1 = i1.ɵnov(_v, 5).focused; var currVal_2 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 11).ngClassValid; var currVal_7 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_TeamHeaderRendererComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "teamTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.team.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TeamHeaderRendererComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "emptyTeamTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Team Name"]))], null, null); }
function View_TeamHeaderRendererComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "teamcircle"]], [[4, "background", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("#" + _co.team.colour); _ck(_v, 0, 0, currVal_0); }); }
function View_TeamHeaderRendererComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-pencil editPencil"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTeam() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_TeamHeaderRendererComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_8)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowReassign; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TeamHeaderRendererComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_7)), i1.ɵdid(1, 16384, null, 0, i8.OnlyLoggedInDirective, [i1.TemplateRef, i1.ViewContainerRef, i9.LoginService], null, null), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TeamHeaderRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_3)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_4)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_5)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_6)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.team; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.team; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.team == null) ? null : _co.team.colour); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.isEditMode; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_TeamHeaderRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dd3-content strongBorder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamHeaderRendererComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changeTeamToggle; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.changeTeamToggle; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TeamHeaderRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "team-header", [], null, null, null, View_TeamHeaderRendererComponent_0, RenderType_TeamHeaderRendererComponent)), i1.ɵdid(1, 114688, null, 0, i10.TeamHeaderRendererComponent, [i11.FlightMembersService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeamHeaderRendererComponentNgFactory = i1.ɵccf("team-header", i10.TeamHeaderRendererComponent, View_TeamHeaderRendererComponent_Host_0, { flightTeamGroup: "flightTeamGroup", teams: "teams" }, {}, []);
export { TeamHeaderRendererComponentNgFactory as TeamHeaderRendererComponentNgFactory };
