<!DOCTYPE html>
<html>

<head>
    <meta charset="utf-8">
    <title>VPAR Club Platform Demo</title>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta content="VPAR Club Platform Demo" name="description" />
    <meta content="VPAR Club Platform Demo" name="author" />
</head>

<body class="sidebar-condensed error-page">
    <div class="row">
        <div class="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-12">
            <div class="error-container">
                <div class="error-main">
                    <h1><span id="404">404</span></h1>
                    <h3><span id="404-txt">Looks like you're out of bounds. It seems that this page doesn't exist or has been removed.</span></h3>
                    <h4><span id="404-txt-2">Go back to our site or <a href='mailto:enquiries@vpar.com'>contact us</a> about the problem.</span></h4>
                    <br>
                    <div class="col-md-12 text-center">
                      <br><br>
                      <div class="btn-group">
                          <a class="btn btn-white " href="/tournaments">
                              <i class="icon-home"></i> Home Page
                          </a>
                          <a class="btn btn-white" href="/login">
                              <i class="icon-login"></i> Login
                          </a>
                          <a class="btn btn-white btn-message" href="mailto:enquiry@vpar.com">
                              <i class="icon-envelope"></i> Send us a message
                          </a>
                      </div>
                  </div>
                    <div class="row" id="content-404">


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="text-center m-b-20">
            <a href="http://www.vpar.com"><img src="../../../assets/icons/vpar/VPAR_Logo_RGB.svg" style="max-height:20px;"></a>
        </div>
        <div class="copyright">© Copyright {{year}}<a href="http://www.vpar.com"> VPAR</a> All Rights Reserved</div>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.6.0/js/bootstrap.min.js"></script>
    <script src="../../../libraries/club/platform/assets/admin/layout2/js/layout.js"></script>
</body>

</html>
