
import {forkJoin as observableForkJoin, empty as observableEmpty, of as observableOf, combineLatest as observableCombineLatest,  Subject ,  Observable } from 'rxjs';

import {defaultIfEmpty, filter, takeUntil, mergeMap, map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Round, Flight, FlightMember, Profile, PlayerCreateDetail, CompetitionProfileDetailLite } from '../../../models';
import { SelectItem } from 'primeng/primeng';
import { FlightService, ProfileService, FlightMembersService, LeaderboardService, UploadService, ResultService } from '../../../services';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { Helpers } from '../../../helpers/helpers'

import { ProfilePictureUpload } from '../profile-picture-upload/index';
import { EmailValidators, UniversalValidators } from 'ngx-validators';
import * as utilFunctions from '../../utils/index';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment.prod';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog';

@Component({
    selector: 'player-modal',
    templateUrl: 'player-modal.component.html',
    styleUrls: ['player-modal.component.scss']
})

export class PlayerModal implements OnChanges, OnInit, OnDestroy {
    public playerForm: FormGroup;
    public $stop: Subject<boolean> = new Subject<any>();

    @Input() currentRound: Round;
    @Input() displayPlayerDialog: boolean = false;
    @Input() newPlayer: boolean;
    @Input() flights: Array<Flight>;
    @Input() holes: SelectItem[] = [];

    @Output() displayPlayerDialogChange = new EventEmitter();

    @ViewChild(ProfilePictureUpload, { static: false }) profilePictureUpload: ProfilePictureUpload;
    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;


    showPhotoRegistration = false;
    streamReference: any;
    photoTaken = false;
    playerPhoto = '';
    videoStreamReady = false;
    videoStreamAvailable = false;
    videoDevices: any[] = [];
    selectedDevice: any;
    isManuallyAdjusted = false;

    profilePictureUrl: string;
    pictureFilename: string;
    allowUpload: boolean = false;
    submitted: boolean = false;
    shotsDisplay: boolean;
    isSharedBall: boolean = false;
    handicapStep: number = 1;
    genders: SelectItem[] = [];
    flightsSelect: SelectItem[] = [];
    nationalities: SelectItem[] = [];
    vparMemberFields: Array<string> = ["emailAddress", "firstName", "surname", "handicap", "nationality", "gender"];
    playerTypes: SelectItem[] = [];
    flightStatus: SelectItem[] = [];
    registeredOptions: SelectItem[] = [];
    currentPlayerCreateDetail: Subject<PlayerCreateDetail> = new Subject<PlayerCreateDetail>();
    observeableCurrentRound: Subject<Round> = new Subject<Round>();
    _playerCreateDetail: PlayerCreateDetail;

    isChrome = false;

    //--------------- Player Create Detail -------------
    get playerCreateDetail() {
        return this._playerCreateDetail;
    }
    @Input() set playerCreateDetail(value: PlayerCreateDetail) {
        
        this.profilePictureUrl = "https://" + environment.cdnUrl + "/profile/defaultThumbnail.jpg";
        this.pictureFilename = "";
        this.allowUpload = false;
        this._playerCreateDetail = value;
        if (this._playerCreateDetail) {
            this.pictureFilename = this._playerCreateDetail.pictureFilename;
            if (this.pictureFilename != null && this.pictureFilename !== '') {
                if (this._playerCreateDetail.pathPictureFilename) {
                    this.profilePictureUrl = "https://" + environment.cdnUrl + "/" + this._playerCreateDetail.pathPictureFilename;
                }
            }
            if (!value.vparMember || (value.vparMember && !this.pictureFilename)) {
                this.allowUpload = true;
            }
        }
    };

    //------------------- Decimal Scoring -------------------
    _decimalScoring: boolean = false;
    get decimalScoring() {
        return this._decimalScoring;
    }

    @Input() set decimalScoring(value: boolean) {
        this._decimalScoring = value;
        var control = null;
        if (this.playerForm) {
            this.playerForm.controls["flightHandicap"];
        }
        if (this._decimalScoring) {
            this.handicapStep = 0.1;
            if (control) {
                control.setValidators([Validators.required, UniversalValidators.isNumber]);
            }
        } else {
            this.handicapStep = 1;
            if (control) {
                control.setValidators([Validators.required, UniversalValidators.isNumber, utilFunctions.isInteger]);
            }
        }
    }


    constructor(private _fb: FormBuilder,
        public flightService: FlightService,
        public flightMembersService: FlightMembersService,
        public profileService: ProfileService,
        private leaderboardService: LeaderboardService,
        private uploadService: UploadService,
        public resultService : ResultService) {

        // Chrome 1+
        this.isChrome = !!window['chrome'] && !!window['chrome'].webstore;

        this.genders = DomainHelpers.SelectLists.getGenders();
        this.nationalities = DomainHelpers.SelectLists.getNationalities();
        this.playerTypes = DomainHelpers.SelectLists.getPlayerTypes();
        this.flightStatus = DomainHelpers.SelectLists.getFlighStatus();
        this.registeredOptions = DomainHelpers.SelectLists.getBooleanOptions();
        let manualAdjustedValues = new Map();

        var self = this;
        observableCombineLatest(this.flightMembersService.flightMembers$, this.flightService.flights$, this.observeableCurrentRound,
            function (flightMembers, flights, currentRound) {
                
                flightMembers.forEach(function (f) {                    
                    manualAdjustedValues.set(f.profileID, f.isManuallyAdjusted);
                  });

                var flightSize = 4;
                if (currentRound) {
                    flightSize = currentRound.flightType;

                }
                return self.createFlightList(flights, flightMembers, flightSize);
            }).pipe(takeUntil(this.$stop)).subscribe((data) => {
                this.flightsSelect = data;
            });

        this.currentPlayerCreateDetail.pipe(takeUntil(this.$stop)).subscribe(currentPlayer => {
            this.submitted = false;
            this.newPlayer = currentPlayer.profileID == 0;
            currentPlayer.isManuallyAdjusted = manualAdjustedValues.get(currentPlayer.profileID);            

            if (this.newPlayer) {
                currentPlayer.nationality = '';
                currentPlayer.gender = 1;
            }

            //Enable or disable the 'VparMember' controls
            if (currentPlayer.vparMember) {
                this.disableVPARMemberDetailForms();
            }
            else {
                this.enableVPARMemberDetailForms();
            }

            //Enable or disable the check in 
            this.playerForm.controls["registered"].enable();
            if (currentPlayer.vparMember || !currentPlayer.registered) {
                this.playerForm.controls["registered"].disable();
            }
                        
            this.playerForm.controls["vparHandicap"].disable();

            this.populateForm(currentPlayer);

        })
    }

    ngOnInit() {        
        var flightHandicapValidators = [Validators.required, UniversalValidators.isNumber];
        if (!this.decimalScoring) {
            flightHandicapValidators = flightHandicapValidators.concat(utilFunctions.isInteger);
        }

        

        this.playerForm = this._fb.group({
            profileID: [0, [Validators.required]],
            flightMemberID: [0, [Validators.required]],
            emailAddress: [null, EmailValidators.normal],
            firstName: [null, [Validators.required]],
            surname: [null, [Validators.required]],
            handicap: [null, [Validators.required, UniversalValidators.isNumber]],
            flightMemberType: [null, [Validators.required]],
            flightMemberStatus: [null],
            nationality: [null],
            gender: [null, [Validators.required]],
            flightHandicap: [0],
            shots: [0],
            flightID: [null],
            vparMember: [false, [Validators.required]],
            startHole: [null],
            teeOffTime: [null],
            registered: [null],
            vparHandicap: [null],
            isManuallyAdjusted :[null]
        });       
        

        this.playerForm.controls["emailAddress"].valueChanges.pipe(debounceTime(400),
            filter(email => email != null),
            map(email => email.trim()),
            filter(email => Helpers.Utility.validateEmail(email)),
            distinctUntilChanged(),).subscribe(email => {
                this.profileService.load(email).subscribe(profile => {
                    if (profile) {
                        this.playerForm.patchValue(profile);
                        if (profile.vparMember) {
                            this.disableVPARMemberDetailForms();
                            this.allowUpload = false;
                        }
                        else {
                            this.enableVPARMemberDetailForms();
                        }

                        //this.playerForm.controls["flightHandicap"].setValue(this.playerForm.controls["vparHandicap"].value ? this.playerForm.controls["vparHandicap"].value : this.playerForm.controls["handicap"].value);
                    }
                });
            });

        this.setShotsHandicapStatus(this.shotsDisplay, this.isSharedBall);
    }

    private setShotsHandicapStatus(shotsDisplay: boolean, isSharedBall: boolean) {
        if (isSharedBall) {
            this.playerForm.controls["shots"].disable();
            this.playerForm.controls["flightHandicap"].disable();
        } else {
            this.setShotsDisplay(shotsDisplay);
        }

    }
    private setShotsDisplay(shotsDisplay: boolean) {
        if (shotsDisplay) {
            this.playerForm.controls["shots"].enable();
            this.playerForm.controls["flightHandicap"].enable();
        } else {
            this.playerForm.controls["shots"].disable();
            this.playerForm.controls["flightHandicap"].enable();
        }
    }

    populateForm(profile: PlayerCreateDetail) {
        this.playerForm.reset(profile);
    }

    disableVPARMemberDetailForms() {

        this.vparMemberFields.forEach(field => {
            this.playerForm.controls[field].disable();
        })
    }

    enableVPARMemberDetailForms() {
        this.vparMemberFields.forEach(field => {
            if (field !== "vparHandicap") {
                this.playerForm.controls[field].enable();
            }
        })
    }

    createFlightList(flights: Array<Flight>, flightMembers: Array<FlightMember>, flightSize): Array<SelectItem> {
        var flightList = new Array<SelectItem>();
        flightList.push({ label: "Choose...", value: "" });
        if (flights != null) {
            flights.map(function (flight) {
                if (_.filter(flightMembers, { 'flightID': flight.flightID }).length < flightSize) {
                    flightList.push({ label: flight.startHole + " - " + moment.utc(flight.teeOffTime).format("HH:mm"), value: flight.flightID });
                }
            });
        }
        return flightList;
    }

    getFlight(hole: number, startTime: Date): Flight {
        if (this.flights == null || this.flights.length == 0) {
            return null;
        }
        for (var i = 0; i < this.flights.length; i++) {
            var flight = this.flights[i];
            var matchedHole = flight.startHole == hole;
            if (matchedHole) {
                var startTimeText = startTime.getTime().toString();
                var teeTimeText = moment.utc(flight.teeOffTime).toDate().getTime().toString();
                var matchedTime = teeTimeText === startTimeText;
                if (matchedTime) {
                    return flight;
                }
            }
        };
        return null;
    }

    removePlayerDialog(): void {
        var player = this.playerForm.getRawValue();    
        var self = this;
        var callBack = function () {            
            self.removePlayer(player.flightMemberID);
        }
        self.confirmDialog.showDialog(callBack);   
            
    }

    removePlayer (flightMemberID){
        this.flightMembersService.removeFromTeeTime(flightMemberID);
        this.resultService.refreshbyRoundId(this.currentRound.roundID.toString());
        this.displayPlayerDialog = false;
        this.displayPlayerDialogChange.emit(this.displayPlayerDialog);
    }

    manageFlight(playerCreateDetail: PlayerCreateDetail): Observable<Flight> {
        var currentFlight = _.find(this.flights, { 'flightID': playerCreateDetail.flightID });
        if (currentFlight != null) {
            return observableOf<Flight>(currentFlight);
        }
        return observableEmpty().pipe(defaultIfEmpty());
    }

    manageProfile(playerCreateDetail: PlayerCreateDetail): Observable<Profile> {
        if (playerCreateDetail.competitionProfileDetails.length === 0) {
            var basicCompetitionProfileDetail: CompetitionProfileDetailLite =
            {
                competitionID: playerCreateDetail.competitionID,
                profileID: playerCreateDetail.profileID,
                status: 0
            };
            playerCreateDetail.competitionProfileDetails = [basicCompetitionProfileDetail];
        }
        else if (playerCreateDetail.registered != null) {
            playerCreateDetail.competitionProfileDetails[0].status = playerCreateDetail.registered ? 1 : 0;
        }

        if (playerCreateDetail.profileID == 0) {
            return this.profileService.add(playerCreateDetail);
        }
        else {
            return this.profileService.update(playerCreateDetail);
        };
    }

    profileImageUploaded(profile: Profile) {
        this.pictureFilename = profile.pictureFilename;
        this.profilePictureUrl = "https://" + environment.cdnUrl + "/" + profile.pathPictureFilename;
        this.flightMembersService.loadAll(this.currentRound.competitionID.toString(), this.currentRound.roundID.toString());
    }

    save(playerCreateDetail: PlayerCreateDetail) {
        this.submitted = true;
        if (this.playerForm.valid) {
            playerCreateDetail = this.playerForm.getRawValue();
            playerCreateDetail.competitionID = this.currentRound.competitionID;
            playerCreateDetail.competitionProfileDetails = this._playerCreateDetail.competitionProfileDetails;

            var profileRequest = this.manageProfile(playerCreateDetail);           
            if(playerCreateDetail.flightID != 0){
            var flightRequest = this.manageFlight(playerCreateDetail);
            var flightMemberHandicap = playerCreateDetail.flightHandicap;
            var flightMemberShots = playerCreateDetail.shots;

            var profileAndFlight = observableForkJoin(profileRequest, flightRequest).pipe(
                mergeMap(data => {
                    var profile = data[0];
                    var flight = data[1];
                    this.addFlightMember(playerCreateDetail, flight, profile, flightMemberHandicap, flightMemberShots);
                    return observableOf(profile);
                })).subscribe(data => {
                    if (this.playerPhoto !== '') {
                        this.uploadPlayerPhoto(data.profileID.toString());
                    }
                    if (this.profilePictureUpload && this.profilePictureUpload.files) {
                        this.profilePictureUpload.upload(data.profileID.toString());
                    }
                    this.leaderboardService.recalculateLeaderboard(this.currentRound.roundID).subscribe({ error: e => console.error(e) });
                    this.playerCreateDetail = null;
                    this.displayPlayerDialog = false;
                    this.displayPlayerDialogChange.emit(this.displayPlayerDialog);
                },
                    err => console.error(err),
                    () => console.log('Allocated player')
                );
            }
        }
    }

    onHcpChange(){
        this.isManuallyAdjusted = true
    }

    onAdjustedChange (event:Event){        
        if ((<HTMLInputElement>event.target).checked) 
            this.isManuallyAdjusted = true;        
        else 
            this.isManuallyAdjusted = false;        
    }

    addFlightMember(playerCreateDetail: PlayerCreateDetail, currentFlight: Flight, updatedProfile: Profile, flightMemberHandicap: number, flightMemberShots: number): Observable<FlightMember> {
        var flightMember = new FlightMember();
        flightMember.flightID = currentFlight ? currentFlight.flightID : 0;
        flightMember.profileID = updatedProfile.profileID;
        flightMember.shots = flightMemberShots;
        flightMember.flightMemberType = playerCreateDetail.flightMemberType;
        flightMember.status = playerCreateDetail.flightMemberStatus;
        var roundID = +this.currentRound.roundID;
        flightMember.roundID = +roundID;
        flightMember.flightMemberID = playerCreateDetail.flightMemberID;
        flightMember.handicap = flightMemberHandicap;
        flightMember.flightMemberType = playerCreateDetail.flightMemberType;
        flightMember.competitionID = this.currentRound.competitionID;        
        flightMember.isManuallyAdjusted = this.isManuallyAdjusted;

        var movedFlight = (currentFlight != null) && (currentFlight.flightID != 0) && (currentFlight.flightID !== playerCreateDetail.flightID);
        if (!movedFlight && this.playerCreateDetail.ball && this.playerCreateDetail.addToBallSlot) {
            flightMember.ball = this.playerCreateDetail.ball;
        }

        var noCurrentFlight = !playerCreateDetail.flightMemberID;
        var createFlightMember = movedFlight || noCurrentFlight;

        if (createFlightMember) {
            return this.flightMembersService.add(flightMember.competitionID, roundID, flightMember);
        }
        return this.flightMembersService.update(flightMember.competitionID.toString(), roundID.toString(), flightMember);
    }

    visibleChange(event) {
        this.stopVideoStream();
        this.displayPlayerDialogChange.emit(event);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.shotsDisplay = DomainHelpers.RoundHelper.shotsDisplay(this.currentRound);
        this.isSharedBall = this.currentRound.isSharedBall;
        var changePlayerCreateDetail = changes['playerCreateDetail'];
        if (changePlayerCreateDetail && changePlayerCreateDetail.currentValue != null) {
            this.currentPlayerCreateDetail.next(changePlayerCreateDetail.currentValue);
        }

        var changeCurrentRound = changes['currentRound'];
        if (changeCurrentRound && changeCurrentRound.currentValue != null) {
            this.observeableCurrentRound.next(changeCurrentRound.currentValue);
        }

        // when opening the modal
        if (changes.displayPlayerDialog && changes.displayPlayerDialog.currentValue) {
            this.photoTaken = false;
            this.playerPhoto = '';
            this.showPhotoRegistration = false;
            this.videoStreamReady = false;
            this.videoStreamAvailable = !!(navigator.mediaDevices &&
                navigator.mediaDevices.getUserMedia);
        }
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    getModalTitle() {
        if (this.playerCreateDetail) {
            return this.playerCreateDetail.profileID == 0 ? 'Create Player' : 'Update Player'
        }
        return '';
    }

    togglePhotoRegistration() {
        this.showPhotoRegistration = !this.showPhotoRegistration;
        if (this.showPhotoRegistration) {

            navigator.mediaDevices.enumerateDevices()
                .then((deviceInfos) => { this.gotDevices(deviceInfos) }).
                then(() => this.getVideoStream());
        }
        else {
            this.videoStreamReady = false;
            this.photoTaken = false;
            this.stopVideoStream();
        }
    }

    private gotDevices(deviceInfos) {
        this.videoDevices = [];
        for (var i = 0; i !== deviceInfos.length; ++i) {
            if (deviceInfos[i].kind === 'videoinput') {
                this.videoDevices.push(deviceInfos[i]);
            }
        }
        this.selectedDevice = this.videoDevices[0];
    }

    public getVideoStream() {

        this.stopVideoStream();

        var constraints = {
            video: {
                deviceId: { exact: this.selectedDevice.deviceId }
            }
        };

        const tvideo = $('video')[0];

        navigator.mediaDevices.getUserMedia(constraints).
            then((stream) => {
                tvideo['srcObject'] = stream;
                this.streamReference = stream;
                this.videoStreamReady = true;
            });
    }

    private stopVideoStream() {
        if (this.streamReference) {
            this.streamReference.getVideoTracks().forEach(function (track) {
                track.stop();
            });
            this.streamReference = null;
        }
    }

    takeScreenShot() {
        const tvideo = <HTMLVideoElement>$('video')[0];
        const canv = <HTMLCanvasElement>$('canvas')[0];
        canv.width = tvideo.videoWidth;
        canv.height = tvideo.videoHeight;

        canv.getContext('2d').drawImage(tvideo, 0, 0, canv.width, canv.height);
        this.photoTaken = true;
    }

    saveScreenshot() {
        const canv = <HTMLCanvasElement>$('canvas')[0];

        this.playerPhoto = canv.toDataURL();
        this.profilePictureUrl = canv.toDataURL();
        this.togglePhotoRegistration();
    }

    private uploadPlayerPhoto(profileID: string) {
        const file = Helpers.ImageUtil.getFileFromURL(this.playerPhoto, profileID + '_photo.png');
        var fileUploadUrl = `${environment}/api/Profiles/${profileID}/Picture`;
        this.uploadService.addMedia(fileUploadUrl, [file]).pipe(
            map(res => JSON.parse(res)))
            .subscribe((response: Profile) => {
                this.flightMembersService.loadAll(this.currentRound.competitionID.toString(), this.currentRound.roundID.toString());
                this.playerPhoto = '';
            }, err => {
                this.playerPhoto = '';
            }, () => {
            });
    }
}
