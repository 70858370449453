import { Component, OnInit } from "@angular/core";
import { error } from "console";
import { ToastrService } from "ngx-toastr";
import { Settings } from "src/app/models/settings";
import { AppCookieService } from "src/app/services/CookiesServices";
import { SettingsServices } from "src/app/services/SettingsService";

@Component({
  styleUrls: ["settings.component.scss"],
  templateUrl: "settings.component.html",
})
export class SettingsComponent implements OnInit {
  settings: Settings;
  competitionHostId: number;

  constructor(
    private settingsService: SettingsServices,
    private toastr: ToastrService,
    private cookieService: AppCookieService
  ) {}

  ngOnInit(): void {
    const competitionHostId = this.cookieService.getCompetitionHostIdCookie();
    this.settingsService
      .getSettings(competitionHostId)
      .subscribe((data) => {
        this.settings = data;
      });
    (error: any) => {
      console.error("Error:", error);
    };
  }

  update(): void {
    this.settingsService.updateSettings(this.settings).subscribe(
      () => {
        this.toastr.success("Settings updated");
      },
      (error: any) => {
        this.toastr.error("Something went wrong");
        console.log(error);
      }
    );
  }
}
