import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { DeviceAdvertService, UploadService } from '../../../services';
import { DeviceDetail, RoundMediaDetails, RoundMediaCommandDetails, RoundMediaCommand } from '../../../models';
import { CropperSettings, ImageCropperComponent } from "ngx-img-cropper";
import { ToastrService } from 'ngx-toastr';
import { Helpers } from '../../../helpers/helpers';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'manage-media-modal',
    templateUrl: './manage-media-modal.component.html',
    styleUrls: ['./manage-media-modal.component.scss']
})

export class ManageMediaModalComponent implements OnInit {

    @Input() roundId: number;

    displayManageMediaDialog: boolean = false;
    tabItems: MenuItem[];
    activeTab: MenuItem;

    displayCropTool = false;

    popupList: DeviceDetail[] = [];
    bannerList: DeviceDetail[] = [];

    data = '';
    mediaBanner: DeviceDetail = new DeviceDetail();
    selectedPreview = '0';
    selectedBannerImage = '';
    showError = false;

    mediaPopups: RoundMediaDetails[] = [];
    selectedPopupImage = '';
    selectedPopupIndex = null;

    isEdit = false;

    cropperData: any;
    fileName = '';
    cropperSettings: CropperSettings;
    @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
    uploadInProgress = false;

    constructor(private deviceAdvertService: DeviceAdvertService,
        private uploadService: UploadService,
        private toastr: ToastrService) {
        this.buildMenuItems();
    }

    ngOnInit() {
    }

    private loadDropdowns() {
        this.deviceAdvertService.getBanners().subscribe((data) => {
            this.bannerList = data;
        });

        this.deviceAdvertService.getPopups().subscribe((data) => {
            this.popupList = data;
        });
    }

    private loadData() {
        this.deviceAdvertService.getRoundPopups(this.roundId).subscribe((data) => {

            this.isEdit = data.length > 0;
            this.mediaPopups = data;
        });

        this.deviceAdvertService.getRoundBanner(this.roundId).subscribe((data) => {
            this.mediaBanner = data !== null ? data : new DeviceDetail();
            if (this.mediaBanner != null) {
                if (this.mediaBanner.cdnPath === "") {
                    this.selectedBannerImage = "";
                } else {
                    this.selectedBannerImage = "https://" + environment.cdnUrl + "/" + this.mediaBanner.cdnPath;
                }

            }
        });
    }

    close(event) {
        this.displayManageMediaDialog = false;
    }

    resetModal() {
        this.selectedBannerImage = '';
        this.selectedPreview = '0';
        this.showError = false;
        this.displayCropTool = false;
        this.setMenuOption(0);
        this.selectedPopupImage = '';
        this.mediaPopups = [];
        this.selectedPopupIndex = null;
        this.loadDropdowns();
        this.loadData();
    }

    toggleCropTool(isOpeningCropTool: boolean) {

        if (isOpeningCropTool) {
            this.cropperSettings = new CropperSettings();
            this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
            this.cropperSettings.cropperDrawSettings.strokeWidth = 1;

            this.cropperData = {};
            this.cropperSettings.keepAspect = true;

            this.cropperSettings.rounded = false;
            this.cropperSettings.noFileInput = true;
            if (this.activeTab.label === 'Banner') {

                this.cropperSettings.width = 240;
                this.cropperSettings.height = 96;

                this.cropperSettings.croppedWidth = 240;
                this.cropperSettings.croppedHeight = 96;

                this.cropperSettings.canvasWidth = 380;
                this.cropperSettings.canvasHeight = 152;

                this.cropperSettings.minWidth = 240;
                this.cropperSettings.minHeight = 96;
            } else if (this.activeTab.label === 'Popup') {

                this.cropperSettings.width = 480;
                this.cropperSettings.height = 800;

                this.cropperSettings.croppedWidth = 480;
                this.cropperSettings.croppedHeight = 800;

                this.cropperSettings.canvasWidth = 192;
                this.cropperSettings.canvasHeight = 320;

                this.cropperSettings.minWidth = 480;
                this.cropperSettings.minHeight = 800;
            }

        }
        this.displayCropTool = !this.displayCropTool;
        if (this.displayCropTool) {
            this.cropperData = {};
            this.fileName = '';
        }
    }

    /**
     * Event launched when the user selects a file to crop.
     * Load the image in the image cropper component
     * @param $event 
     */
    fileChangeListener($event) {
        const image: any = new Image();
        const file: File = $event.target.files[0];
        this.fileName = file.name.toLowerCase();
        const myReader: FileReader = new FileReader();
        const that = this;
        myReader.onloadend = function (loadEvent: any) {
            image.src = loadEvent.target.result;
            that.cropper.setImage(image);
        };
        myReader.readAsDataURL(file);
    }

    uploadImage() {
        const file = Helpers.ImageUtil.getFileFromURL(this.cropperData.image, this.fileName);

        this.uploadInProgress = true;
        this.toastr.info('Uploading Image. This may take a minute or so');

        const mediaType = this.activeTab.label === 'Banner' ? 80 : (this.activeTab.label === 'Popup' ? 90 : 0);

        this.uploadService.addMedia(`${environment.apiUrl}/api/Media/Post/${mediaType}`, [file]).subscribe(response => {
            this.toastr.success('Your image has been uploaded. Please select from the drop down list');
            this.uploadInProgress = false;

            this.loadDropdowns();

            if (this.activeTab.label === 'Banner') {
                setTimeout(() => {
                    this.mediaBanner.mediaID = response;
                    this.selectBanner(this.mediaBanner.mediaID.toString());
                }, 300);
            } else if (this.activeTab.label === 'Popup') {
                setTimeout(() => {
                    this.mediaPopups[this.selectedPopupIndex].mediaID = response;
                    this.mediaPopups[this.selectedPopupIndex].mediaDetails = this.popupList.find((item) => item.mediaID.toString() === response);
                }, 300);
            }

            this.toggleCropTool(false);
        }, err => {
            this.toastr.error('Failed to upload Image');
            this.uploadInProgress = false;
        });
    }

    selectBanner(value: string) {
        if (value === '0') {
            this.selectedBannerImage = '';
        } else {
            const banner = this.bannerList.find((item) => item.mediaID.toString() === value);
            if (banner != null) {
                if (banner.cdnPath === "") {
                    this.selectedBannerImage = "";
                } else {
                    this.selectedBannerImage = "https://" + environment.cdnUrl + "/" + banner.cdnPath;
                }
            }
        }
    }

    addPopup() {
        const newPopup = new RoundMediaDetails();
        newPopup.mediaID = 0;
        newPopup.roundMediaID = 0;
        newPopup.holeNumber = 1;
        newPopup.roundMediaType = 1;
        newPopup.selected = false;
        newPopup.mediaDetails = null;
        newPopup.errorHole = false;
        newPopup.errorImage = false;

        if (this.mediaPopups.length === 0) {
            this.pushMediaPopup(newPopup);
        } else if (this.mediaPopups[this.mediaPopups.length - 1].holeNumber < 18) {
            newPopup.holeNumber = this.mediaPopups[this.mediaPopups.length - 1].holeNumber + 1;
            this.pushMediaPopup(newPopup);
        } else {
            if (!this.checkGaps()) {
                this.toastr.error("You can't add more holes");
            }
        }
    }

    private pushMediaPopup(media: RoundMediaDetails) {
        this.mediaPopups.push(media);
    }

    private checkGaps() {

        let emptyConfig: RoundMediaDetails = new RoundMediaDetails();

        for (let i = 0; i < this.mediaPopups.length; i++) {
            if (this.mediaPopups[i + 1] && (this.mediaPopups[i].holeNumber + 1) !== this.mediaPopups[i + 1].holeNumber) {
                emptyConfig.holeNumber = this.mediaPopups[i].holeNumber + 1;
                this.mediaPopups.splice(i + 1, 0, emptyConfig);

                return true;
            }
        }

        return false;
    }

    deletePopup() {

        for (let i = this.mediaPopups.length - 1; i >= 0; i--) {
            if (this.mediaPopups[i].selected) {
                if (this.mediaPopups[i].mediaDetails && ("https://" +environment.cdnUrl + "/" + this.mediaPopups[i].mediaDetails.cdnPath === this.selectedPopupImage)) {
                    this.selectedPopupImage = '';
                }
                this.mediaPopups.splice(i, 1);
            }
        }
    }

    highlightMedia(mediaPath: string, index: number) {
        this.selectedPopupIndex = index;
        if (mediaPath === '') {
            this.selectedPopupImage = '';
        } else {
            this.selectedPopupImage = "https://" + environment.cdnUrl + "/" + mediaPath;
        }
    }

    saveSettings() {

        if (this.validMediaPopups()) {

            const test = { roundId: this.roundId, mediaId: this.mediaBanner.mediaID };
            this.deviceAdvertService.updateRoundBanner(test, this.roundId).subscribe(result => {
                const mediaCommand = new RoundMediaCommand();
                mediaCommand.roundID = this.roundId;
                let newMedia = new RoundMediaCommandDetails();
                this.mediaPopups.forEach((popup) => {
                    newMedia = new RoundMediaCommandDetails();
                    newMedia.mediaID = popup.mediaID;
                    newMedia.holeNumber = popup.holeNumber;
                    newMedia.roundMediaType = 1;
                    newMedia.roundID = this.roundId;
                    mediaCommand.roundMediaDetails.push(newMedia);
                });

                if (this.isEdit) {
                    this.deviceAdvertService.updateRoundPopups(mediaCommand, this.roundId).subscribe(result => {
                        this.toastr.success('Media settings saved successfully');
                        this.displayManageMediaDialog = false;
                    }, err => {
                        this.toastr.error('Error saving media settings. If problem persists contact your administrator')
                        this.displayManageMediaDialog = true;
                    });
                } else {
                    this.deviceAdvertService.createRoundPopups(mediaCommand, this.roundId).subscribe(result => {
                        this.toastr.success('Media settings saved successfully');
                        this.displayManageMediaDialog = false;
                    }, err => {
                        this.toastr.error('Error saving media settings. If problem persists contact your administrator')
                        this.displayManageMediaDialog = true;
                    });
                }
            }, err => {
                this.toastr.error('Error saving media settings. If problem persists contact your administrator')
                this.displayManageMediaDialog = true;
            });

        } else {
            this.toastr.error('Cannot save popup media settings, check the list for errors');
            this.displayManageMediaDialog = true;
        }

    }

    private validMediaPopups() {
        // clean errors first
        this.mediaPopups.forEach((config) => {
            config.errorHole = false;
            config.errorImage = false;
        });
        let hasErrors = false;

        if (this.mediaPopups.length > 0) {
            // validate all ranges
            for (let i = 0; i < this.mediaPopups.length; i++) {

                for (let j = 0; j < this.mediaPopups.length; j++) {
                    if (i !== j) {
                        if (this.mediaPopups[i].holeNumber === this.mediaPopups[j].holeNumber) {
                            this.mediaPopups[i].errorHole = true;
                            this.mediaPopups[j].errorHole = true;
                            hasErrors = true;
                        }
                    }
                }

                if (this.mediaPopups[i].mediaID === 0) {
                    this.mediaPopups[i].errorImage = true;
                    hasErrors = true;
                }
            }
        }

        return !hasErrors;
    }

    private buildMenuItems() {
        this.tabItems = [
            {
                label: 'Banner', visible: true, command: (event) => {
                    this.setMenuOption(0);
                }
            },
            {
                label: 'Popup', visible: true, command: (event) => {
                    this.setMenuOption(1);
                }
            }
        ];
        this.setMenuOption(0);
    }

    private setMenuOption(selectedIndex: number) {
        this.activeTab = this.tabItems[selectedIndex];
    }
}
