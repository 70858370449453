import { Component, Input, Injector, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Competition } from "../../../models";
import { MenuItem } from 'primeng/primeng';
import * as moment from 'moment';

@Component({
    selector: 'tournament-overview-detail',
    templateUrl: 'tournament-overview.component.html',
    styleUrls: ['tournament-overview.component.scss']
})

export class TournamentOverviewComponent implements OnInit {

    @Input() tournament: Competition;
    @Input() roundId: string;

    tabItems: MenuItem[];
    activeItemIndex: number;
    activeItem: MenuItem;
    activeRoundIdex : number;

    tournamentDate: string;
    scoreCentreNavigation: any[];
    showDetails = false;

    offeringLevel: number;
    package : number;
    displayTournamentCode : boolean = true;

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,) {
        router.events.subscribe((val: any) => {
            this.showDetails = this.router.url.indexOf('/players') > 0 || this.router.url.indexOf('/summary') > 0;
        });
        this.offeringLevel = this.activatedRoute.parent.snapshot.data['branding'].offeringType;        
    }

    ngOnInit() {                
        this.buildMenuItems();
        // TODO: Bring this code back once we have design for summary page
        // if (this.tournament.rounds.length > 1) {
        //     this.scoreCentreNavigation = ['/public', 'tournaments', this.tournament.competitionID, 'summary'];
        // } else {
        //     this.scoreCentreNavigation = ['/public', 'tournaments', this.tournament.competitionID, 'round', this.tournament.rounds[0].roundID, 'players'];
        // }

        this.scoreCentreNavigation = ['/public', 'tournaments', this.tournament.competitionID, 'round', this.tournament.rounds[0].roundID, 'players'];

        const url = this.router.url;
        if (url.indexOf('/round/') > 0) {
            const loadedRoundId = url.split('/round/')[1].split('/')[0];
            this.tournament.rounds.forEach((round, index) => {
                if (round.roundID.toString() === loadedRoundId) {
                    this.setMenuOption(index + 1);
                    this.activeRoundIdex= index;
                }
            });
        }
        if(this.router.url.includes("public")){
          document.getElementById("tournament-btn").style.display = "none";
          this.displayTournamentCode = false;
        }
        
        
    }
 
    private buildMenuItems() {        
        this.tabItems = [ ];
        this.tournament.rounds.forEach((round, index) => {
            this.tabItems.push({
                label: 'Round ' + (index + 1), routerLink: "round/" + round.roundID + "/players", visible: true, command: (event) => {
                    this.setMenuOption(index + 1);
                }
            })
        });        
    }

    private setMenuOption(selectedIndex: number) {   
        this.activeItemIndex = selectedIndex;
        this.activeItem = this.tabItems[selectedIndex];
        if(selectedIndex !=0)
            this.scoreCentreNavigation = ['/public', 'tournaments', this.tournament.competitionID, 'round', this.tournament.rounds[selectedIndex - 1].roundID, 'players'];
    }

    buildTournamentDate(rounds: any[]) {

        let firstDate = new Date('01-01-9999');
        let lastDate = new Date('01-01-0001');
        let tournamentDate = '';

        rounds.forEach((round) => {
            if (moment(round.startDate).isBefore(moment(firstDate))) {
                firstDate = new Date(round.startDate.substring(0, 4) + '-' + round.startDate.substring(5, 7) + '-' + round.startDate.substring(8, 10) + 'T12:00:00Z');
            }
            if (moment(round.startDate).isAfter(moment(lastDate))) {
                lastDate = new Date(round.startDate.substring(0, 4) + '-' + round.startDate.substring(5, 7) + '-' + round.startDate.substring(8, 10) + 'T12:00:00Z');
            }
        });

        const firstDateMoment = moment(firstDate);
        const lastDateMoment = moment(lastDate);

        if (firstDateMoment.isSame(lastDateMoment)) {
            tournamentDate = firstDateMoment.format('Do MMMM, YYYY');
        } else {
            if (firstDateMoment.year() !== lastDateMoment.year()) {
                tournamentDate = firstDateMoment.format('Do MMMM, YYYY') + ' - ' + lastDateMoment.format('Do MMMM, YYYY');
            } else {
                if (firstDateMoment.month() === lastDateMoment.month()) {
                    tournamentDate = firstDateMoment.format('Do') + ' - ' + lastDateMoment.format('Do MMMM, YYYY');
                } else {
                    tournamentDate = firstDateMoment.format('Do MMMM') + ' - ' + lastDateMoment.format('Do MMMM, YYYY');
                }
            }
        }

        return tournamentDate;
    }
}
