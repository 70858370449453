import { Component, OnInit } from "@angular/core";
import { GamesList } from "src/app/models/OrderOfMerit/GamesList";
import { OrderOfMeritConfig } from "src/app/models/OrderOfMerit/orderofmerit";
import { OrderOfMeritService } from "src/app/services/orderOfMeritService";
import { OrderOfMeritEnums } from "src/app/models/OrderOfMerit/OrderOfMeritEnums";
import { mergeMap, switchMap } from "rxjs/operators";
import { getDaySuffix } from "src/app/shared/utils/numbers";
import { formatHCP } from "../../shared/utils/isFloat";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { BrandingService } from "src/app/services";

@Component({
  selector: "app-orderofmerit-leaderboard",
  templateUrl: "./orderofmerit-leaderboard.component.html",
  styleUrls: ["./orderofmerit-leaderboard.component.scss"],
})
export class OrderofmeritLeaderboardComponent implements OnInit {
  inputsForm: FormGroup;
  startMonth: string;
  startYear: number;
  endDay: number;
  endMonth: string;
  endYear: number;
  numberOfTournaments: number;
  games: GamesList[] = [];
  pointsFormatStringValue: string;
  gameFormatStringValue: string;
  orderOfMeritConfig: OrderOfMeritConfig = new OrderOfMeritConfig();
  leaderboardData: any[] = [];
  tournamentScores: string[] = [];
  tournaments: string[] = [];
  oomCompetitions: number = 50;
  golfersQualified: number;
  playersNumber: number;
  startDay: number;
  startFormattedDate: string;
  endFormattedDate: string;
  activeTabIndex: number = 0;
  emptyGamesMessage: string = "No games found";
  orderOfMerit: any;
  year = new Date().getFullYear();

  constructor(
    private service: OrderOfMeritService,
    private brandingService: BrandingService
  ) {}
  getDaySuffixVal(val: number) {
    return getDaySuffix(val);
  }
  formatHCP(val: number) {
    return formatHCP(val);
  }
  ngOnInit() {
    this.brandingService
      .loadBranding()
      .pipe(
        switchMap((b) => this.service.getConfigPublic(b.competitionHostID.toString())),
        mergeMap((configData: any) => {
          // Handle config data
          this.orderOfMeritConfig = configData;
          this.formatDate();
          this.gameFormatStringValue = this.getGameFormatValue(
            configData.gameFormat
          );
          this.pointsFormatStringValue = this.getPointsFormatValue(
            configData.pointsFormat
          );

          return this.service.getLeaderboardData(
            configData.orderOfMeritConfigId
          );
        }),
        mergeMap((leaderboardData: any) => {
          if (leaderboardData) {
            this.leaderboardData = leaderboardData.Response.L.T;
            if (this.leaderboardData.length > 0) {
              this.tournamentScores = this.leaderboardData[0].IndexOfScores.map(
                (score) => ` ${score.Points}`
              );
            }
          }
          // Get order of merit games
          return this.service.getGames(
            this.orderOfMeritConfig.startDate,
            this.orderOfMeritConfig.endDate
          );
        })
      )
      .subscribe(
        (gamesData: any) => {
          this.games = gamesData;
        },
        (error: any) => {
          // Handle error here
          console.error(error);
        }
      );
  }
  getPointsFormatValue(
    pointsFormat: OrderOfMeritEnums.PointsFormatEnum
  ): string {
    for (const key in OrderOfMeritEnums.PointsFormatEnum) {
      if ((OrderOfMeritEnums.PointsFormatEnum as any)[key] === pointsFormat) {
        return key;
      }
    }
    return "";
  }

  getGameFormatValue(enumValue: number): string {
    for (const key in OrderOfMeritEnums.OrderOfMeritGameTypeEnum) {
      if (
        (OrderOfMeritEnums.OrderOfMeritGameTypeEnum as any)[key] === enumValue
      ) {
        return key;
      }
    }
    return "";
  }

  formatDate() {
    this.orderOfMeritConfig.startDate = new Date(
      this.orderOfMeritConfig.startDate
    );
    this.orderOfMeritConfig.endDate = new Date(this.orderOfMeritConfig.endDate);
    this.startDay = this.orderOfMeritConfig.startDate.getDate();
    this.startMonth = this.orderOfMeritConfig.startDate.toLocaleString(
      "default",
      {
        month: "long",
      }
    );
    this.startYear = this.orderOfMeritConfig.startDate.getFullYear();
    this.endDay = this.orderOfMeritConfig.endDate.getDate();
    this.endMonth = this.orderOfMeritConfig.endDate.toLocaleString("default", {
      month: "long",
    });
    this.endYear = this.orderOfMeritConfig.endDate.getFullYear();

    const daySuffix = getDaySuffix(this.startDay);
    this.startFormattedDate = `${daySuffix} of ${this.startMonth} ${this.startYear}`;
    const endDaySuffix = getDaySuffix(this.endDay);
    this.endFormattedDate = `${endDaySuffix} of ${this.endMonth} ${this.endYear}`;
    this.golfersQualified = 100;
  }
}
