<!-- BEGIN PAGE CONTENT -->
<div class="row">
    <div class="col-xs-12">
        <div class="panel">
            <div class="panel-content">
               
                <vpar-tabMenu [model]="items" [activeItem]="activeItem" [disableAutoSelection]="true"></vpar-tabMenu>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>