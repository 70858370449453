<div class="row">
    <div class="col-xs-12 players-table">
        <div class="panel" [ngClass]="{'public-style': isPublic()}">
            <div class="panel-content">
                <ng-template onlyLoggedIn>
                    <div class="row calculator-area" [hidden]="hidePublicCalculator">
                        <h3 style="margin-left:40px;margin-bottom:40px;">Handicap Index Calculator</h3>
                        <p style="margin-left:40px;margin-bottom:10px;"><b>Male:&nbsp; </b> Slope Rating:{{SR}} &nbsp;  &nbsp;Course Rating:{{MalecourseRating}} &nbsp;  &nbsp;Par:{{MalePar}} &nbsp;<b>Female:&nbsp; </b> Slope Rating:{{FSR}} &nbsp;  Course Rating:{{FemalecourseRating}} &nbsp;  &nbsp;Par:{{FemalePar}} </p>
                        <div class="row " style="margin-left:40px;" >
                            <div class="custom-col">
                              <label for="input1">Index: </label>
                              <input type="text" [(ngModel)]="ProfileIndex" class="form-control max-width-200" id="input1">
                            </div>
                            <div class="custom-col">
                              <label for="input2">Slope Rating: </label>
                              <input type="text"[(ngModel)]="SlopeRating"class="form-control max-width-200" id="input2">
                            </div>
                            <div class="custom-col">
                                <label for="input2">Course Rating: </label>
                                <input type="text"[(ngModel)]="CourseRating"class="form-control max-width-200" id="input2">
                              </div>
                            <div class="custom-col">
                                <label for="input2">Par: </label>
                                <input type="text"[(ngModel)]="Par"class="form-control max-width-200" id="input2">
                              </div>
                            <div class="custom-col">
                              <label for="input3">Course HCP:</label>
                              <input type="text" [(ngModel)]="CourseHCP" class="form-control max-width-200" id="input3" readonly>
                            </div>
                            <div class="custom-col">
                              <label for="input4">Allowance %: </label>
                              <input type="text" [(ngModel)]="Allowance" class="form-control max-width-200" id="input4" readonly>
                            </div>
                            <div class="custom-col">
                              <label for="input5">Game HCP: </label>
                              <input type="text" [(ngModel)]="GameHCP" class="form-control max-width-200" id="input5" readonly>
                            </div>
                            <div class="custom-col">
                              <button type="button" class="btn Run-btn" (click)="calculateHandicapIndex()">Run</button>
                            </div>
                          </div>
                          <hr/>
                    </div>
                 </ng-template>
                <div class="row text-right">
                    <div class="col-md-12">
                        <label class="pull-left m-l-20 m-t-5 f-20 w-800 mobile-hidden">Player List</label>
                        <div class="search-input-container m-t-5">
                            <i class="fa fa-search"></i>
                            <input #gb type="text" pInputText size="50" placeholder="Search Players....">
                        </div>
                    </div>
                </div>
                <div class="row p-t-20 players-list-container">
                    <p-dataTable [emptyMessage]="emptyMessage" [globalFilter]="gb" [value]="flightMembers" class="table table-dynamic" [rows]="12" [paginator]="true" expandableRows="true" selectionMode="single" [(selection)]="selectedPlayer" (onRowSelect)="onRowSelect($event)">
                        <p-column field="profile.displayName" class="edit-info" styleClass="player-header" header="Player" [sortable]="true" [style]="dynamicStyle">
                            <ng-template pTemplate="body" let-flightMember="rowData">
                                <span class="f-left mobile-hidden"><img class="profilePicture" [src]="showProfilePicture(flightMember.profile)" [hidden]="windowSize < 750"/></span>
                                <span class="m-t-10 edit-cell player-name edit-cell">{{flightMember.profile.firstName}} {{flightMember.profile.surname}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="profile.handicap" class="edit-info" header="Index" [style]="{'width':'60px'}" [sortable]="true" [hidden]="shotsDisplay || (windowSize < 575  || windowSize < 1200) " [style]="{'width':'95px'}">
                            <ng-template pTemplate="body" let-flightMember="rowData">
                                <span class="edit-cell">{{flightMember.vparHandicap ? flightMember.vparHandicap : flightMember.profile.handicap}}</span>
                            </ng-template>
                        </p-column>
                        <p-column field="profile.handicap" class="edit-info" header="Game HCP" [style]="{'width':'100px'}" [sortable]="true" [hidden]="shotsDisplay" [style]="{'width':'95px'}">
                            <ng-template pTemplate="body" let-flightMember="rowData">
                                <span class="edit-cell">{{flightMember.handicap}}</span>
                            </ng-template>
                        </p-column>
                        <p-column class="edit-info" header="Email" field="profile.emailAddress" [sortable]="true" [hidden]="windowSize < 575 || windowSize < 1200" *onlyLoggedIn>
                            <ng-template let-flightMember="rowData" pTemplate="body">
                                <span class="edit-cell" *ngIf="flightMember.profile.emailAddress!=''">{{ flightMember.profile.emailAddress | lowercase }}</span>
                                <span class="edit-cell editable editable-click editable-empty" style="display: inline;" *ngIf="flightMember.profile.emailAddress==''">Empty</span>
                            </ng-template>
                        </p-column>
                        
                        <p-column field="shots" class="edit-info" header="HCP (Shots)" [sortable]="true" [hidden]="!shotsDisplay">
                            <ng-template pTemplate="body" let-flightMember="rowData">
                                <span class="edit-cell">{{flightMember.vparHandicap ? flightMember.vparHandicap : flightMember.profile.handicap}}</span>
                                <span *ngIf="flightMember.shots||flightMember.shots==0">({{flightMember.shots}})</span>
                            </ng-template>
                        </p-column>
                        <p-column header="Team" class="edit-info" field="team.title" [sortable]="true" [hidden]="!teamDisplay"></p-column>
                        <p-column class="edit-info" header="Member" sortField="profile.vparMember" [style]="{'width':'80px'}" [sortable]="true" [hidden]="shotsDisplay || windowSize < 575 || windowSize < 1200" [style]="{'width':'90px'}">
                            <ng-template pTemplate="body" let-flightMember="rowData">
                                <span class="vpar-logo">
                                    <i *ngIf="flightMember.profile.vparMember" class="vpar-icon vpar-vpar"></i>
                                </span>
                            </ng-template>
                        </p-column>
                        <p-column header="Type" field="flightMemberTypeText" [sortable]="true" [style]="{'width':'90px'}" [hidden]="windowSize < 575 || windowSize < 1200" *onlyLoggedIn></p-column>
                        <p-column header="Hole" field="flight.startHole" [sortable]="true" [style]="{'width':'60px'}" [hidden]="windowSize < 575 || windowSize < 1200" *onlyLoggedIn></p-column>
                        <p-column header="Time" field="flight.teeOffTime" [sortable]="true" [style]="{'width':'65px'}" [hidden]="windowSize < 575 || windowSize < 1200" *onlyLoggedIn>
                            <ng-template let-flightMember="rowData" pTemplate="body">
                                {{getTime(flightMember.flight.teeOffTime)}}
                            </ng-template>
                        </p-column>
                        <p-column header="Tee" field="profile.genderText" [sortable]="true" [style]="{'width':'50px'}" [hidden]="windowSize < 575  || windowSize < 1200" *onlyLoggedIn></p-column>
                        <p-column header="App" sortField="registered" [sortable]="true" [style]="{'width':'50px'}" [hidden]="windowSize < 575" *onlyLoggedIn>
                            <ng-template let-flightMember="rowData" pTemplate="body">
                                <i class="fa fa-circle checked-in-circle" [ngClass]="{'active': flightMember.registered}"></i>
                            </ng-template>
                        </p-column>
                        <p-column field="isCheckedIn" sortField="isCheckedIn" header="Arrived" [sortable]="true" [style]="{'width':'85px'}" [hidden]="windowSize < 575" *onlyLoggedIn>
                            <ng-template let-flightMember="rowData" pTemplate="body">
                                <input type="checkbox" data-checkbox="icheckbox_square-green" class="icheckbox_square-green" [checked]="flightMember.isCheckedIn" (change)="onChange($event)" value="{{flightMember.flightMemberID}}" />
                            </ng-template>
                        </p-column>
                    </p-dataTable>
                </div>
            </div>
        </div>
    </div>
</div>
<player-modal [currentRound]="currentRound" [decimalScoring]="decimalScoring" [(displayPlayerDialog)]="displayPlayerDialog" [playerCreateDetail]="selectedPlayer" [holes]="holes" [flights]="flights" *onlyLoggedIn></player-modal>
