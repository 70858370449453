<div class="row">
    <div class="col-xs-12 border m-b-15 flight">
        <div class="col-sm-10 col-xs-8 tall center cursor-pointer hole-config" (click)="toggle()">
            <i class="fa fa-chevron-circle-right m-l-20 vparorange" aria-hidden="true" *ngIf="!open"></i>
            <i class="fa fa-chevron-circle-down m-l-20 vparorange" aria-hidden="true" *ngIf="open"></i>
            <span class="m-l-5 bold">{{playersList}}</span>
        </div>
        <div class="col-sm-2 col-xs-4 background tall center teeTime" style="border-bottom:1px solid white;display: flex; align-items: center;">
            <span class="bold hole-config" style="margin: auto;">Hole {{flightTeam.startHole}} - {{flightTeam.teeOffTimeText}}</span>
        </div>
        <div class="flightHolder">
            <scorecard [roundID]="roundID" [teamType]="teamType" [flightID]="flightTeam.flightID" [tournamentOpen]="tournamentOpen" [courseData]="courseData" [maleTeeColor]="maleTeeColor" [femaleTeeColor]="femaleTeeColor" (onEditingChange)="editScorecardChanged($event)"></scorecard>
        </div>
    </div>
</div>