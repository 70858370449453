
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/primeng';
import { CompetitionDataService, LoginService } from "../../services";
import { Competition, IBranding } from "../../models";
import { Helpers } from '../../helpers/helpers';
import { ConfirmDialog } from '../../shared/index';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
@Component({
    templateUrl: 'tournaments-list.component.html',
    styleUrls: ['./tournaments-list.component.scss']
})

export class TournamentListComponent implements OnInit, OnDestroy {
    public tournaments: Array<any>;
    public totalRecords: number;
    public dateFormat: string;
    public emptyMessage: string = "Loading...";
    public filter: string;
    public $stop: Subject<boolean> = new Subject<any>();
    public profileId : number;
    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;
    constructor(public router: Router, public competitionDataService: CompetitionDataService, private route: ActivatedRoute, private loginService: LoginService) {

    }

    getBranding() {
        var branding = this.route.snapshot.parent.data['branding'];      
        this.dateFormat = branding.dateFormat;         
    }

    ngOnInit() {
        this.getBranding();
        this.competitionDataService.competitions$.pipe(takeUntil(this.$stop)).subscribe(
            response => {
                this.tournaments = response.competitions;
                this.emptyMessage = "No tournaments found";
                //We want to page based on filtered
                this.totalRecords = response.filteredCount;
            });
        // TODO : make this code reusable
        this.loginService.loggedInDetail$.subscribe((token) => {            
            if (token !== "" && token !== undefined && token !== null) {    
                this.profileId = token.ProfileId;
            }
          });
    }

    loadData(event: LazyLoadEvent) {
        let params = new HttpParams()
                        .set('Take', event.rows.toString())
                        .set('Skip', event.first.toString());                        
        
        //params.set('Filters', event.filters.toString());
        if (event.sortField != null && event.sortOrder != null) {
            //TODO
            params = params.append('Sort[0].Field', event.sortField.toString()).append('Sort[0].IntOrder', event.sortOrder.toString());
        }
        var eventFilters = event.filters;        
        if (this.filter) {            
            params = params.append('globalFilter', this.filter)
        }
        if (eventFilters != null) {
            var setCount = 0;
            var i = 0; for (var propertyName in eventFilters) // for acts as a foreach
            {
                var filter = event.filters[propertyName];
                if (filter.value.toString() !== '') {
                    params = params.append('Filter.Filters[' + setCount + '].Field', propertyName)
                    .append('Filter.Filters[' + setCount + '].Operator', 'contains')
                    .append('Filter.Filters[' + setCount + '].Value', filter.value.toString());
                    setCount++;
                }
                if (setCount > 0) {
                    params = params.append('Filter.Logic', 'and');
                }
                i++;
            }
        }

        this.competitionDataService.loadCompetitions(params);
    }

    navigateToTournament(event: any) {
        let tournament: Competition = event.data;
        let Summary: any;

        // multi-round and single-round tournaments have different entry points
        // if(tournament.rounds.length === 1){
        //     Summary = ["tournaments", tournament.competitionID, "round", tournament.rounds[0].roundID, "players"];
        // } else{
        //     Summary = ["tournaments", tournament.competitionID, "summary"];
        // }        
        Summary = ["tournaments", tournament.competitionID, "round", tournament.rounds[0].roundID, "players"];
        this.router.navigate(Summary);
    }

    removeTournament(tournament: Competition) {
        const profileId = this.profileId;
        var self = this;
        var callBack = function () {            
            self.competitionDataService.remove(tournament.competitionID,profileId);
        }
        self.confirmDialog.showDialog(callBack);
    }

    jsonToDate(dateString: string): string {
        return Helpers.DateUtil.jsonToDate(Helpers.DateUtil.momentFormatFromDotNet(this.dateFormat), dateString)
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.$stop.next(true);
    }
}
