import { Inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppCookieService {
  private cookieStore = {};

  constructor() {
    this.parseCookies(document.cookie);
  }

  public parseCookies(cookies = document.cookie) {
    this.cookieStore = {};
    if (!!cookies === false) {
      return;
    }
    const cookiesArr = cookies.split(";");
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split("=");
      this.cookieStore[cookieArr[0].trim()] = cookieArr[1];
    }
  }

  get(key: string) {
    this.parseCookies();
    return !!this.cookieStore[key] ? this.cookieStore[key] : null;
  }

  remove(key: string) {
    document.cookie = `${key} = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
  }

  removeToken() {
    this.cookieStore["token"].remove();
  }

  set(key: string, value: string) {
    document.cookie = key + "=" + (value || "");
  }

  setCompetitionHostCookie(value: string) {
    let hostName = `${window.location.host}`;
    if (hostName.includes("localhost")) {
      hostName = "localhost";
    }
    document.cookie = `${hostName}_host_key` + "=" + (value || "");
  }

  getCompetitionHostIdCookie() {
    this.parseCookies();
    let hostName = `${window.location.host}`;
    if (hostName.includes("localhost")) {
      hostName = "localhost";
    }
    return !!this.cookieStore[`${hostName}_host_key`]
      ? this.cookieStore[`${hostName}_host_key`]
      : "";
  }
}
