import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { OrderOfMeritConfig } from "../models/OrderOfMerit/orderofmerit";
import { Observable } from "rxjs";
import { HttpServices } from "./HttpServices";
import { AppCookieService } from "./CookiesServices";
import { switchMap } from "rxjs/operators";
import { GamesList } from "../models/OrderOfMerit/GamesList";
import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class OrderOfMeritService {
  constructor(
    private httpClient: HttpClient,
    private http: HttpServices,
    private cookiesService: AppCookieService
  ) {}

  createNewGame(request: OrderOfMeritConfig): Observable<OrderOfMeritConfig> {
    request.competitionHostId =
      this.cookiesService.getCompetitionHostIdCookie();
    return this.http.post(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit`,
      request
    );
  }

  updateGame(request: OrderOfMeritConfig): Observable<OrderOfMeritConfig> {
    return this.http.putTyped<OrderOfMeritConfig>(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit`,
      request
    );
  }

  getConfig(): Observable<OrderOfMeritConfig> {
    return this.http.getTyped<OrderOfMeritConfig>(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit/config`
    );
  }

  getConfigPublic(competitionHostId: string): Observable<OrderOfMeritConfig> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "CompetitionHostId": competitionHostId, // Custom header
    });

    return this.httpClient.get<OrderOfMeritConfig>(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit/config`,
      { headers: headers }
    );
  }

  getLeaderboardData(config: number): Observable<any> {
    return this.http
      .get(
        `${environment.leaderboardAPIUrl}/api/LeaderboardConfig/order-of-merit/${config}`
      )
      .pipe(
        switchMap((data: any) => {
          if (data.FeedURL) return this.http.get(data.FeedURL);
        })
      );
  }
  getGames(startDate: Date, endDate: Date): Observable<GamesList[]> {
    const formattedStartDate = formatDate(
      startDate,
      "yyyy-MM-ddTHH:mm:ss",
      "en-US"
    );
    const formattedEndDate = formatDate(
      endDate,
      "yyyy-MM-ddTHH:mm:ss",
      "en-US"
    );
    return this.http.getTyped<GamesList[]>(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit/games?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    );
  }
  setGameAsFinished(competitionId: number): Observable<any> {
    return this.http.put(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit/games/set-as-finished/${competitionId}`,
      {}
    );
  }
  sendForCalculation(competitionId: number) {
    return this.http.post(
      `${environment.competitionApiUrl}/api/5.0/order-of-merit/send-for-calc/${competitionId}`,
      {}
    );
  }
}
