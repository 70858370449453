import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrandingService } from '../services';
import { IBranding } from "../models";
import { Observable } from 'rxjs';

@Injectable()
export class BrandingResolver implements Resolve<Observable<IBranding>> {
    constructor(private brandingService: BrandingService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBranding> {    
      return this.brandingService.loadBranding();
  }
}
