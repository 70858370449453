
import {forkJoin as observableForkJoin,  Observable ,  Observer ,  Subject } from 'rxjs';

import {share, mergeMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { FlightMember, PlayerCreateDetail, Profile } from "../models";
import { FlightMembersService } from './FlightMembersService';
import { PlayerPoolService } from './PlayerPoolService';
import { LeaderboardService } from "./LeaderboardService";

@Injectable()
export class FlightMemberService {
    public addedFlightMember$: Observable<FlightMember>;
    private _addedFlightMemberObserver = new Subject<FlightMember>();

    constructor(private flightMembersService: FlightMembersService,
        private playerPoolService: PlayerPoolService,
        private leaderboardService: LeaderboardService) {
        this.addedFlightMember$ = this._addedFlightMemberObserver.asObservable().pipe(share());
    }

    AddFlightMemberToBall(flightMember: FlightMember) {
        this._addedFlightMemberObserver.next(flightMember);
    }


    DroppedOnEmptyBallSlot(ballSlot: FlightMember, competitionID: number, draggedProfileID: number) {
        var createdFlightMember = this.CreateFlightMember(ballSlot, draggedProfileID);
        var flightMemberAssign = this.FlightMemberCreate(createdFlightMember);

        flightMemberAssign.subscribe((response) => {
            this.leaderboardService.recalculateLeaderboard(createdFlightMember.roundID).subscribe({ error: e => console.error(e) });
        }, error => console.log(`Failed -Could not post flightMember.${error}`));

    }
    DropExistingBall(flightMemberDroppedOn: FlightMember, draggedFlightMember: FlightMember) {
        this.flightMembersService.swap(flightMemberDroppedOn.roundID, flightMemberDroppedOn.flightMemberID, draggedFlightMember.flightMemberID);
    }

    DropFlightMemberOnPool(competitionID: number, draggedFlightMember: FlightMember) {
        observableForkJoin(this.PoolAssign(draggedFlightMember.profileID, competitionID), this.FlightMemberRemove(draggedFlightMember, competitionID))
            .subscribe((response) => {
                this.leaderboardService.recalculateLeaderboard(draggedFlightMember.roundID).subscribe({ error: e => console.error(e) });
            }, error => console.log(`Failed -Flight member moved to pool player.${error}`));
    }

    DropFlightMemberOnExistingPoolPlayer(PoolPlayerDroppedOn: PlayerCreateDetail, draggedFlightMember: FlightMember) {
        var createdFlightMember = this.CreateFlightMember(draggedFlightMember, PoolPlayerDroppedOn.profileID);
        this.FlightMemberRemove(draggedFlightMember, PoolPlayerDroppedOn.competitionID).pipe(mergeMap(a => {
            return observableForkJoin(this.PoolAssign(draggedFlightMember.profileID, PoolPlayerDroppedOn.competitionID), this.FlightMemberCreate(createdFlightMember))
        })).subscribe((response) => {
            this.leaderboardService.recalculateLeaderboard(createdFlightMember.roundID).subscribe({ error: e => console.error(e) });
        }, error => console.log(`Failed -Flight member swapped with pool player.${error}`));
    }

    DropPoolPlayerOnExistingFlightMember(FlightMemberDroppedOn: FlightMember, draggedPoolMember: any) {
        var createdFlightMember = this.CreateFlightMember(FlightMemberDroppedOn, draggedPoolMember.profileID);
        var competitionID = draggedPoolMember.competitionID;
        this.FlightMemberRemove(FlightMemberDroppedOn, competitionID).pipe(
            mergeMap(a => {
                return observableForkJoin(this.PoolAssign(FlightMemberDroppedOn.profileID, competitionID), this.FlightMemberCreate(createdFlightMember));
            }))
            .subscribe((response) => {
                this.leaderboardService.recalculateLeaderboard(createdFlightMember.roundID).subscribe({ error: e => console.error(e) });
            }, error => console.log(`Failed - Pool player swapped with flight member.${error}`));
    }

    private PoolAssign(profileID: number, competitionID: number): Observable<Profile> {
        return this.playerPoolService.add(competitionID.toString(), profileID);
    }

    private FlightMemberCreate(flightMember: FlightMember): Observable<FlightMember> {
        //Don't neeed ID refactor
        return this.flightMembersService.add(0, flightMember.roundID, flightMember);
    }

    private FlightMemberRemove(flightMember: FlightMember, competitionID: number): Observable<any> {
        //Don't neeed ID refactor
        return this.flightMembersService.remove(competitionID.toString(), flightMember.roundID, flightMember.flightMemberID.toString());
    }

    private CreateFlightMember(flightMember: FlightMember, profileID: number): FlightMember {
        var createdFlightMember = new FlightMember();
        createdFlightMember.flightID = flightMember.flightID;
        createdFlightMember.profileID = profileID;
        createdFlightMember.roundID = flightMember.roundID;
        createdFlightMember.ball = flightMember.ball;
        createdFlightMember.status = 100;
        return createdFlightMember;
    }
}