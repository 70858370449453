import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { GolfersServices, LoginService } from "../../../services";


@Component({
  selector: "side-menu",
  templateUrl: "side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  opened = true;
  title: string;
  logoThumbnailURL: string;
  loggedIn = false;
  orderOfMeritActive: boolean = false;
  public environment = environment;
  isTooltipVisible = false;
  public fullPath: string;  // Add this variable for the golfer's photo
 
  
  constructor(
    private route: ActivatedRoute,
    public loginService: LoginService,
    private router: Router,
    private golfersServices: GolfersServices  // Inject the GolfersServices
  ) {
    this.loginService.loggedInDetail$.subscribe((token) => {
      this.loggedIn =
        token !== "" && token !== undefined && token !== null ? true : false;
    });
  }
  
  ngOnInit() {
    this.getBranding();
    this.subscribeToGolfer();  // Subscribe to golfer data changes
    if (this.router.url.includes("public")) {
      document.getElementById("mySidenav").style.display = "none";
    }
  }

  ngAfterViewInit() {
    // if the user is not logged in, move the main content of the page to cover the full width
    if (!this.loggedIn) {
      document.getElementById("main").style.marginLeft = "0px";
    }
  }

  getBranding() {
    var branding = this.route.snapshot.data["branding"];
    this.title = branding.title;
    this.logoThumbnailURL = branding.logoThumbnailURL;
    this.orderOfMeritActive = branding.orderOfMeritActive;
  }

  // Subscribe to golfer data changes
  private subscribeToGolfer() {
    this.golfersServices.Golfer$.subscribe((data) => {
      if (data && data.pictureFilename) {
        this.fullPath = `https://${environment.cdnUrl}/profile/${data.profileID}/${data.pictureFilename}`;
      } else {
        this.fullPath = environment.imagesCDNUrl + "avatarDefaultUser.svg";
      }
    });
  }

  logout() {
    this.loginService.logout();
  }
}
