<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="loading" *ngIf="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div class="panel-content">
                <div class="row m-b-10">
                    <div class="header-group border-bottom"><i class="fa  fa-print"></i>
                        <div class="text-capitalize headerText">PRINT OVERVIEW</div>
                    </div>
                </div>
                <div class="row m-b-20">
                    <div class="col-md-3">
                        <span class="m-l-30">What would you like to print?</span>
                    </div>
                </div>
                <div class="row m-b-20">
                    <div class="col-md-12 m-l-40">
                        <PrintType [detail]="printType" (detailUpdated)="printTypeUpdated($event)" *ngFor="let printType of PrintTypes"></PrintType>
                    </div>
                </div>
                <div class="row m-b-20">
                    <div class="col-md-8">
                        <div class="row m-b-10">
                            <div class="col-md-3">
                                <span class="m-l-30">Select:</span>
                            </div>
                        </div>
                        <div class="row m-l-40">
                            <div class="col-md-3 ">
                                <div><label for="startHole">Team</label></div>
                                <div>
                                    <p-dropdown (onChange)="printFilterUpdated($event, 'Team')" [options]="currentTeams" [(ngModel)]="teamID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false" [disabled]="!selectTeamOptionEnabled"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div><label for="startHole">Tee-time</label></div>
                                <div>
                                    <p-dropdown (onChange)="printFilterUpdated($event, 'TeeTime')" [options]="currentTeeTimes" [(ngModel)]="teeTimeID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false" [disabled]="!selectTeeTimeOptionEnabled"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div><label for="startHole">Players</label></div>
                                <div>
                                    <p-dropdown (onChange)="printFilterUpdated($event, 'Player')" [options]="currentFlightMembers" [(ngModel)]="flightMemberID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false" [disabled]="!selectPlayersOptionEnabled"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div><label for="startHole">Leaderboards</label></div>
                                <div>
                                    <p-dropdown (onChange)="printFilterUpdated($event, 'Leaderboard')" [options]="currentLeaderboards" [(ngModel)]="learboardID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false" [disabled]="!selectLeaderboardsOptionEnabled"></p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-3">
                                <span>Format:</span>
                            </div>
                        </div>
                        <div class="row p-t-10 m-l-10">
                            <PrintFormat [detail]="printFormat" (detailUpdated)="printFormatUpdated($event)" *ngFor="let printFormat of PrintFormats"></PrintFormat>
                        </div>
                    </div>
                </div>
                <div class="row m-b-10 ">
                    <div class="col-md-3">
                        <span class="m-l-30">Print Options:</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <div class="m-l-40">
                            <PrintOption [detail]="printOption" (detailUpdated)="printOptionUpdated($event)" *ngFor="let printOption of PrintOptions"></PrintOption>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="btn-group col-xs-3 pull-right">
                    <button class="btn m-b-5" (click)="download()" label="Download" [disabled]="!selectedType" [class.btn-gray]="selectedType">Download</button>
                </div>
            </div>
        </div>
    </div>
</div>
