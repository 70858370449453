
export class PrintTypeDetail {
    constructor(
        public id: number,
        public label: string,
        public formats: string[],
        public printTypeEnabledFilters: PrintTypeEnabledFilters = {
            selectTeamOptionEnabled: false,
            selectPlayersOptionEnabled: false,
            selectTeeTimeOptionEnabled: false,
            selectLeaderboardsOptionEnabled: false
        })
    { }
    selected: boolean;
}

export interface PrintTypeEnabledFilters {
    selectTeamOptionEnabled?: boolean;
    selectTeamSingleOption?: number;

    selectTeeTimeOptionEnabled?: boolean;
    selectTeeTimeSingleOption?: number;


    selectPlayersOptionEnabled?: boolean;
    selectPlayersSingleOption?: number;

    selectLeaderboardsOptionEnabled?: boolean;
    selectLeaderboardsSingleOption?: number;
}
