import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { TvFeedService } from '../../../services';
import { TvFeed, Competition } from '../../../models';
import { DomSanitizer } from '@angular/platform-browser';
import { Helpers } from '../../../helpers/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'tv-leaderboard',
    templateUrl: 'tv-leaderboard.component.html',
    styleUrls: ['./tv-leaderboard.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('1 => 0', animate('300ms')),
            transition('0 => 1', animate('300ms'))
        ])
    ]
})

export class TVLeadeboardComponent implements OnInit {

    roundID: string;
    tvFeedConfig: TvFeed;
    pages: any[];

    dashboardLogoUrl: string;

    pageIndex = 0;

    backgroundImage: string;

    dataLoaded = false;

    helpers = Helpers;

    interval = null;

    public $stop: Subject<boolean> = new Subject<any>();
    roundIsDecimalScoring: boolean;
    public environment = environment;

    constructor(private route: ActivatedRoute,
        private tvFeedService: TvFeedService,
        private sanitizer: DomSanitizer) {
        //this.interval = window.setInterval(() => this.paginatePlayers(), 10000);
    }

    ngOnInit() {
        this.getBranding();

        this.route.params.subscribe(params => {
            this.roundID = params['roundId'];

            this.route.data.pipe(takeUntil(this.$stop)).subscribe((data) => {
                let tournament = <Competition>data['tournament'];
                let currentRound = _.find(tournament.rounds, { roundID: +this.roundID });
                this.roundIsDecimalScoring = currentRound.roundConfiguration.useDecimalScoring;
            });

            this.tvFeedService.loadTVFeedConfig(this.roundID).subscribe(res => {
                this.tvFeedConfig = res;
                this.imageExists(this.tvFeedConfig.leftlogo, 'leftlogo');
                this.imageExists(this.tvFeedConfig.rightLogo, 'rightLogo');
                this.pages = [];
                this.pages.push(...res.advert);
                this.pages.push(...res.leaderboard);

                this.pages.forEach((item) => {
                    item.showAnimation = false;
                    item.hideAnimation = false;
                    item.dataLoaded = false;
                    if (item.roundLeaderboardID) {
                        item.players = [];
                        item.screenPlayers = [];
                        item.playersIndex = 0;
                        item.showList = false;
                        item.teams = null;
                    }
                });

                this.pages = _.sortBy(this.pages, 'viewOrder');

                if (this.pages.length > 0) {
                    this.getLeaderboardConfigs();
                    this.LoadBackgroundImage();
                }

                this.dataLoaded = true;
            });
        });
    }

    getImageURL(imageCDNPath: string) {
        return this.sanitizer.bypassSecurityTrustUrl(imageCDNPath);
    }

    private getBranding() {
        var branding = this.route.snapshot.data['branding'];
        this.dashboardLogoUrl = branding.logoURL;
    }

    private imageExists(image_url, property) {
        const self = this;

        if (image_url !== '' && image_url !== undefined) {
            var image = new Image();

            image.onload = function () {
                self.tvFeedConfig[property] = image_url;
            }
            image.onerror = function () {
                self.tvFeedConfig[property] = self.dashboardLogoUrl;
            }

            image.src = image_url;
        } else {
            if (self.dashboardLogoUrl && self.dashboardLogoUrl !== null && self.dashboardLogoUrl !== '') {
                self.tvFeedConfig[property] = self.dashboardLogoUrl;
            }
        }
    }

    private paginatePlayers() {

        if (this.pages[this.pageIndex].roundLeaderboardID) {

            if (this.pages[this.pageIndex].playersIndex >= this.pages[this.pageIndex].players.length) {

                if (this.pages.length > 1) {
                    this.roundCarousel((this.pageIndex + 1) === this.pages.length ? 0 : (this.pageIndex + 1), false, false);
                } else {
                    this.roundCarousel(0, false, true);
                }
            } else {
                this.loadPage();
            }
        }
    }

    private loadPage() {
        let loop = this.pages[this.pageIndex].playersIndex;
        this.pages[this.pageIndex].showList = false;
        setTimeout(() => {
            this.pages[this.pageIndex].screenPlayers = [];
            for (let i = loop; i < this.pages[this.pageIndex].players.length && i < (loop + 10); i++) {
                this.pages[this.pageIndex].screenPlayers.push(this.pages[this.pageIndex].players[i]);
                this.pages[this.pageIndex].playersIndex++;
            }
            this.pages[this.pageIndex].showList = true;
            this.pages[this.pageIndex].dataLoaded = true;
        }, 300);
    }

    private getLeaderboardConfigs() {
        let waitForConfig = false;
        this.pages.forEach((page, index) => {
            if (index === 0) {
                waitForConfig = page.roundLeaderboardID;
            }
            if (page.roundLeaderboardID) {
                this.tvFeedService.getRoundConfig(this.roundID, page.roundLeaderboardID).subscribe(res => {
                    page.config = res;
                    if (waitForConfig && index === 0) {
                        this.roundCarousel(0, true, false);
                    }
                });
            }
        });
        if (!waitForConfig) {
            this.roundCarousel(0, true, false);
        }
    }

    private roundCarousel(index: number, init: boolean, reload: boolean) {

        if (this.pages[index].config) {
            this.tvFeedService.getRoundLeaderboardData(this.pages[index].config.feedURL, this.pages[index].config.columns.length === 0).subscribe(res => {

                // matchplay
                if (this.pages[index].config.columns.length === 0) {

                    this.pages[index].players = res.Response.Pairs ? res.Response.Pairs.Flight : [];
                    this.pages[index].teams = res.Response.Teams ? res.Response.Teams : null;

                } else {

                    // sort the list by Pos                    
                    this.pages[index].players = res.Response.L.T.sort((a, b) => {                        
                        // first transform pos value to something sortable
                        const valueA = isNaN(Number(a.Position.split('=')[0])) ? 99999 : Number(a.Position.split('=')[0]);
                        const valueB = isNaN(Number(b.Position.split('=')[0])) ? 99999 : Number(b.Position.split('=')[0]);
                        // sort ascending (check total in case equal value)                        
                        return valueA - valueB;
                    });                        
                }

                this.pages[index].playersIndex = 0;

                window.clearInterval(this.interval);
                this.interval = window.setInterval(() => this.paginatePlayers(), 10000);
                if (this.pages[this.pageIndex].players.length > 0) {
                    this.paginatePlayers();
                }
            });
        }

        if (!reload) {
            this.pages[index].showAnimation = true;
            this.pages[index].hideAnimation = false;

            if (this.pages.length > 1) {

                if (!init && index > 0) {
                    this.pages[index - 1].showAnimation = false;
                    this.pages[index - 1].hideAnimation = true;
                } else if (!init && index === 0) {
                    this.pages[this.pages.length - 1].showAnimation = false;
                    this.pages[this.pages.length - 1].hideAnimation = true;
                }
            }
        }

        this.pageIndex = index;

        // only in adverts
        if (this.pages[index].timer) {
            setTimeout(() => {
                if (this.pages.length > 1) {
                    this.roundCarousel((index + 1) === this.pages.length ? 0 : (index + 1), false, false);
                } else {
                    this.roundCarousel(0, false, true);
                }
            }, this.pages[index].timer * 1000);
            window.clearInterval(this.interval);
        }
    }

    private LoadBackgroundImage() {
        const self = this;
        var image = new Image();

        if (self.tvFeedConfig.venueImg && self.tvFeedConfig.venueImg !== '') {
            self.backgroundImage = 'url(' + self.tvFeedConfig.venueImg + ')';
            image.src = self.tvFeedConfig.venueImg;
        } else {
            self.backgroundImage = 'url(' + environment.imagesCDNUrl + 'bg1.jpg)';
        }
    }
}
