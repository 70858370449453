import {Component, Input, EventEmitter, Output} from '@angular/core';
import {PrintTypeDetail} from './PrintTypeDetail';

@Component({
    selector: 'PrintType',
    templateUrl: 'PrintType.html',
    styles: [`

.filled {
    background:#556973;
}
.printBox {
    border: 3px solid #556973;
    width: 90px;
    height: 50px;
}

.printType {
    display: inline-block;
    margin-right: 35px;
}

    .printType span {
        font-weight: bold;
        margin: 0 auto;
    }
`]
})

export class PrintType {
    @Input() detail: PrintTypeDetail;
    @Output() detailUpdated = new EventEmitter<PrintTypeDetail>();
    select() {
        this.detail.selected = !this.detail.selected;
        this.detailUpdated.emit(this.detail);
    }
}
