import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Helpers } from '../../../helpers/helpers';
import { ScoresService, LeaderboardService, LoginService } from '../../../services';
import { RoundForScoredcard, FlightMemberForScoredcard, ScoreForScoredcard, CourseForScorecard, nationalityCodeLookup, ProfileForScoredcard, Hole, RoundLeaderboardForScorecard } from '../../../models';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ScoresHelpers } from 'src/app/helpers/scores';

@Component({
    selector: 'scorecard',
    templateUrl: './scorecard.component.html',
    styleUrls: ['./scorecard.component.scss'],
    animations: [
        trigger('visibilityChanged', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('1 => 0', animate('500ms')),
            transition('0 => 1', animate('500ms'))
        ])
    ]
})

export class ScorecardComponent implements OnInit {

    @Input() tournamentOpen: boolean;
    @Input() roundID: string;
    @Input() teamType: number;

    @Input() flightMemberID: number;
    @Input() teamID: number;
    @Input() flightID: number;

    @Input() courseData: CourseForScorecard;
    @Input() maleTeeColor: number;
    @Input() femaleTeeColor: number;

    @Output() onEditingChange = new EventEmitter<boolean>(false);

    loading = true;
    posting = false;
    gameType = 0;
    teeMale = [];
    teeFemale = [];
    equalTeeData = true;
    players: FlightMemberForScoredcard[] = [];
    numberOfHoles = 18;
    scorecardID = '';
    teamTotals = [];
    roundData: RoundForScoredcard = null;
    roundLeaderboards: RoundLeaderboardForScorecard[] = [];


    disabled = false;

    helpers = Helpers;

    public environment = environment;

    constructor(private scoresService: ScoresService,
        private leaderboardService: LeaderboardService,
        private toastr: ToastrService,
        private loginService: LoginService) {
        if (!loginService.isLoggedIn()) {
            this.disabled = true;
        }
    }

    ngOnInit() {
        // I use this scorecardID to get only the elements of this component in jquery selectors
        if (this.flightMemberID) {
            this.scorecardID = 'scorecard_player_' + this.flightMemberID;
        } else if (this.flightID) {
            this.scorecardID = 'scorecard_flight_' + this.flightID;
        } else if (this.teamID) {
            this.scorecardID = 'scorecard_team_' + this.teamID;
        }
        this.loadRoundLeaderboards(this.roundID);
    }

    resetData() {
        // reset all data
        this.loading = true;
        this.posting = false;
        this.gameType = 0;
        this.teeMale = [];
        this.teeFemale = [];
        this.equalTeeData = true;
        this.players = [];
        this.numberOfHoles = 18;
        this.teamTotals = [];
        this.roundData = null;
    }

    loadRoundLeaderboards(roundId: string) {
        this.leaderboardService.loadAll(roundId);
        this.leaderboardService.RoundLeaderboard$.subscribe(
          data => {
            this.roundLeaderboards = data;
          },
          error => {
            console.error('Failed to load leaderboard data', error);
          }
        );
      }

    loadScores() {
        this.resetData();
        // load score data
        this.scoresService.loadScores(this.roundID, this.teamID, this.flightID)
            .subscribe(data => {

                this.onEditingChange.emit(false);
                this.roundData = _.cloneDeep(data);
                this.gameType = data.gameType;

                this.buildCourseData();
                this.buildPlayerList(data);
                this.checkGenderTees();
                this.buildTeamTotals(data);

                // calculate points of every player to have all info updated in case a change in round, handicap, etc
                this.players.forEach((player) => {
                    for (let i = 0; i < this.numberOfHoles; i++) {
                        this.calculatePlayerPoints(player.scores[i].scoreGross, player.scores[i].scoreGross === '/', i, player.profile.profileID, this.gameType);
                    }
                });

                // wait to have all data rendered
                setTimeout(() => {
                    this.applyInputScript();
                    this.calculateTotals();
                    $('.txtScore:first').focus();
                }, 500);

                this.loading = false;
            },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    save() {       
        if (this.tournamentOpen) {
            this.posting = true;
            // Save changes on data to be sent
            this.players.forEach((player) => {
                this.roundData.flights.forEach((flight) => {
                    flight.flightMembers.forEach((member) => {
                        if (member.flightMemberID === player.flightMemberID
                            || (this.teamType >= 60 && (member.flightID === player.flightID && member.teamID === player.teamID))) {
                            member.scores = _.cloneDeep(player.scores);
                        }
                        // blob holes are stored in DB as a 99
                        member.scores.forEach((score) => {
                            if (score.scoreGross === '/') {
                                score.scoreGross = '99';
                            }
                        });
                    });
                });
            });

            this.scoresService.post(this.roundData).subscribe(result => {
                this.leaderboardService.recalculateLeaderboard(this.roundID).subscribe({ error: e => console.error(e) });
                this.posting = false;
                this.onEditingChange.emit(false);
                this.toastr.success('Scorecard saved successfully');
            }, err => console.log(err),
                () => console.log(`Complete Update Scorecard`)
            );
        }
    }

    toggleProfile(event: any, playerProfileClass: string) {

        if ($('.' + playerProfileClass).hasClass('expanded')) {
            $('.' + playerProfileClass).removeClass('expanded');
        } else {
            $('.' + playerProfileClass).addClass('expanded');
        }

        if ($(event.target).find('i').hasClass('fa-chevron-circle-right')) {
            $(event.target).find('i').removeClass('fa-chevron-circle-right');
            $(event.target).find('i').addClass('fa-chevron-circle-down');
        } else if ($(event.target).find('i').hasClass('fa-chevron-circle-down')) {
            $(event.target).find('i').addClass('fa-chevron-circle-right');
            $(event.target).find('i').removeClass('fa-chevron-circle-down');
        }
    }

    /**
     * Calculate subtotal column
     * @param field name of the field we want to calculate
     * @param isOutTotal if is Out total
     * @param tee 'Male' or 'Female'
     */
    calculateSubtotal(field: string, isOutTotal: boolean, tee: string) {
        let result = 0;

        let i = isOutTotal ? 9 : 0;
        let end = isOutTotal ? 18 : 9;

        if (i < this.numberOfHoles) {
            for (i; i < end; i++) {
                result += this['tee' + tee][i][field];
            }
        }

        return result;
    }

    calculateTeamSubtotal(isOutTotal: boolean) {
        let result = 0;

        let i = isOutTotal ? 9 : 0;
        let end = isOutTotal ? 18 : 9;

        if (i < this.numberOfHoles) {
            for (i; i < end; i++) {
                result += this.teamTotals[i];
            }
        }

        return result;
    }

    getNetColor(hole: number, gross: string, net: number, gender: number) {

        if(gross == '0')
            return '';
        const par = gender === 1 ? this.teeMale[hole - 1].Par : this.teeFemale[hole - 1].Par;

        if (net === 0 && gross === '/') {
            return 'scoreDoubleBogey';
        } else if (net === 0) {
            return '';
        }
        // if (net === 1) {
        //     return 'scoreHoleInOne';
        // }
        switch (net - par) {
            case -4:
                return '';
            case -3:
                return 'scoreAlbatross';
            case -2:
                return 'scoreEagle';
            case -1:
                return 'scoreBirdie';
            case 0:
                return 'scorePar';
            case 1:
                return 'scoreBogey';
            case 2:
                return 'scoreDoubleBogey';
            default:
                return 'scoreDoubleBogey';
        }
    }

    getNationalityCode(nationality: string) {
        return nationalityCodeLookup(nationality);
    }

    getPlayerPicture(player: ProfileForScoredcard) {
        let fullPath = environment.imagesCDNUrl + "avatarDefaultUser.svg";
        if (player.pictureFilename && player.pictureFilename !== null && player.pictureFilename !== '') {
            fullPath = "https://" + environment.cdnUrl + "/profile/" + player.profileID + "/" + player.pictureFilename;
        }

        return fullPath
    }

    /**
     * calculate totals
     */
    private calculateTotals() {
        const self = this;
        $('#' + this.scorecardID + ' .gross-score').each(function () {
            let total = 0;
            let totalOut = 0;
            let totalIn = 0;

            $(this).find('.txtScore').each(function () {

                if ($(this).val() === '/' || Helpers.Utility.isNumber($(this).val())) {

                    let gross = 0;

                    // on blob hole, the gross score is double bogey value (par +2)
                    if ($(this).val() === '/') {
                        const holeIndex = parseInt($(this).attr('holeIndex'));
                        const gender = parseInt($(this).attr('playerGender'));
                        const playerId = parseInt($(this).attr('playerid'));
                        const par = gender === 1 ? self.teeMale[holeIndex].Par : self.teeFemale[holeIndex].Par;
                        const player = self.players.find((player) => player.profile.profileID === playerId);
                        const handicap = player.handicap;
                        const holeSI = player.profile.gender === 1 ? self.teeMale[holeIndex].SI : self.teeFemale[holeIndex].SI;

                        gross = par + 2;
                        if (handicap > self.numberOfHoles) {
                            const handicapAdd = Math.floor(handicap / self.numberOfHoles);
                            gross = gross + handicapAdd;
                            if ((handicap - (self.numberOfHoles * handicapAdd)) >= holeSI) {
                                gross++;
                            }
                        } else if (handicap >= holeSI) {
                            gross++;
                        }
                    } else {
                        gross = +($(this).val());
                    }

                    total += gross;

                    if ($(this).hasClass('txtScoreOut')) {
                        totalOut += gross;
                    }
                    else if ($(this).hasClass('txtScoreIn')) {
                        totalIn += gross;
                    }
                }
            });
            if ($(this).find('.totalScore').length > 0) {
                $(this).find('.totalScore').find('input').val(total);
            }
            if ($(this).find('.totalScoreOut').length > 0) {
                $(this).find('.totalScoreOut').find('input').val(totalOut);
            }
            if ($(this).find('.totalScoreIn').length > 0) {
                $(this).find('.totalScoreIn').find('input').val(totalIn);
            }

            let net = 0;
            let netOut = 0;
            let netIn = 0;

            $(this).find('.net-corner span').each(function () {
                if (Helpers.Utility.isNumber($(this).text())) {
                    if (!$(this).hasClass('no-calculate')) {
                        net += parseFloat($(this).text());
                        if ($(this).hasClass('txtScoreOut')) {
                            netOut += parseFloat($(this).text());
                        }
                        else if ($(this).hasClass('txtScoreIn')) {
                            netIn += parseFloat($(this).text());
                        }
                    }
                }
            });

            if ($(this).find('.totalNet').length > 0) {
                $(this).find('.totalNet').find('span').text(net);

                // adjust class of triangles
                $(this).find('.totalNet').removeClass('three-digits');
                if (net > 99) {
                    $(this).find('.totalNet').addClass('three-digits');
                }
            }
            if ($(this).find('.totalNetOut').length > 0) {
                $(this).find('.totalNetOut').find('span').text(netOut);

                // adjust class of triangles
                $(this).find('.totalNetOut').removeClass('three-digits');
                if (netOut > 99) {
                    $(this).find('.totalNetOut').addClass('three-digits');
                }
            }
            if ($(this).find('.totalNetIn').length > 0) {
                $(this).find('.totalNetIn').find('span').text(netIn);

                // adjust class of triangles
                $(this).find('.totalNetIn').removeClass('three-digits');
                if (netIn > 99) {
                    $(this).find('.totalNetIn').addClass('three-digits');
                }
            }
        });
    }

    /**
     * Apply events for inputs
     */
    private applyInputScript(): void {
        const self = this;
        // If click on net triangle, then focus on the input
        $('#' + this.scorecardID + ' .net-corner').on('click', function () {
            if (!$(this).closest('td').hasClass('total-column')) {
                $(this).closest('td').find('input').focus().select();
            }
        });
        // when input data, calculate points, totals and focus on next input
        $('#' + this.scorecardID + ' .gross-score td input').on('input', function () {
            if ($(this).val() != undefined && $(this).val() != '' && (Helpers.Utility.isNumber($(this).val()) || $(this).val() == '/')) {
                var value =+($(this).val());
                if (value > 1 || $(this).val() == '/') {
                    if ($(this).closest('td').next().find('.txtScore').length > 0) {
                        $(this).closest('td').next().find('.txtScore').focus().select();
                    }
                    else if ($(this).closest('td').next().next().find('.txtScore').length > 0) {
                        $(this).closest('td').next().next().find('.txtScore').focus().select();
                    }
                    else if ($(this).closest('tr.gross-score').nextAll('tr.gross-score').find('.txtScore').length > 0) {
                        $(this).closest('tr.gross-score').nextAll('tr.gross-score').find('.txtScore').first().focus().select();
                    }
                }

              if (value > 0 || $(this).val() == '/') {

                self.calculatePlayerPoints(<string>$(this).val(), $(this).val() == '/', parseInt($(this).attr('holeIndex')), parseInt($(this).attr('playerID')), self.gameType);

                    self.buildTeamTotals(self.roundData);

                    setTimeout(() => {
                        self.calculateTotals();
                    }, 300);

                    self.onEditingChange.emit(true);
                }
            }
        });
    }

    private calculatePlayerPoints(grossScore: string, isBlob: boolean, holeIndex: number, playerID: number, gameType: number) {             
        const player = this.players.find((player) => player.profile.profileID === playerID);
        const handicap = player.handicap;
        const holeSI = player.profile.gender === 1 ? this.teeMale[holeIndex].SI : this.teeFemale[holeIndex].SI;
        const holePar = player.profile.gender === 1 ? this.teeMale[holeIndex].Par : this.teeFemale[holeIndex].Par;

        let holeNet = 0;
        // score should be > 0 or blob
        if (!isNaN(Number(grossScore)) && Number(grossScore) > 0
            || isNaN(Number(grossScore)) && isBlob) {

            if (isBlob) {
                holeNet = holePar + 2;
            } else {
                holeNet = parseInt(grossScore);
            }

            if (handicap < 0) {
                let holesNotAdd = 18 - Math.abs(handicap);
                if (holesNotAdd < holeSI) {
                    holeNet++;
                }
            }
            if (handicap > this.numberOfHoles) {
                const handicapAdd = Math.floor(handicap / this.numberOfHoles);
                if (isBlob) {
                    holeNet = holeNet + handicapAdd;
                } else {
                    holeNet = holeNet - handicapAdd;
                }

                if ((handicap - (this.numberOfHoles * handicapAdd)) >= holeSI) {
                    if (isBlob) {
                        holeNet++;
                    } else {
                        holeNet--;
                    }

                }
            } else if (handicap >= holeSI) {
                if (isBlob) {
                    holeNet++;
                } else {
                    holeNet--;
                }
            }


            // calculate stableford points        
            let holePoints = 0;
            switch (holePar - holeNet) {
                case 0:
                    holePoints = 2;
                    break;
                case 1:
                    holePoints = 3;
                    break;
                case 2:
                    holePoints = 4;
                    break;
                case 3:
                    holePoints = 5;
                    break;
                case -1:
                    holePoints = 1;
                    break;
                default:
                    holePoints = 0;
                    break;
            }
            player.scores[holeIndex].scorePoints = holePoints;

            if(grossScore === '/'){
                grossScore = '99';
            }
            const gross = parseInt(grossScore);            
            player.scores[holeIndex].strokeIndex = holeSI;
            player.scores[holeIndex].par = holePar;
            player.scores[holeIndex].shots = ScoresHelpers.Calculations.getShotsOnHole(18,holeSI,handicap);
            player.scores[holeIndex].adjustedGross = ScoresHelpers.Calculations.calcAdjustedGross(holePar,player.scores[holeIndex].shots,gross);
            const grossWithBlob = gross == 99 ?  ScoresHelpers.Calculations.calculateNetDoubleBogey(holePar , player.scores[holeIndex].shots) : gross;
            player.scores[holeIndex].scoreGrossWithBlob = grossWithBlob;
            player.scores[holeIndex].scoreGrossVPAR = grossWithBlob - holePar;
            player.scores[holeIndex].scoreGrossPoints = player.scores[holeIndex].scorePoints - (player.scores[holeIndex].par) < 0 ? 0 : player.scores[holeIndex].scorePoints - (player.scores[holeIndex].par);
            player.scores[holeIndex].scoreGrossPointsVPAR = gross > 0 ? 2 - player.scores[holeIndex].scorePoints : 0;
            player.scores[holeIndex].scoreNet = isBlob && gameType == 1 ? 0 : grossWithBlob - player.scores[holeIndex].shots;
            player.scores[holeIndex].scoreNetVPAR = grossWithBlob - player.scores[holeIndex].shots - holePar;
            player.scores[holeIndex].scorePointsVPAR = 2 - player.scores[holeIndex].scorePoints;            
        }
    }

    private buildPlayerList(roundData: RoundForScoredcard) {

        this.players = [];

        // individual scorecard
        if (this.flightMemberID) {

            this.players.push(this.buildPlayerScores(roundData.flights[0].flightMembers.find((player) => player.flightMemberID === this.flightMemberID)));
            // tee
        } else if (this.flightID) {
            if (this.teamType >= 60) {

                // foursomes, greensomes
                if (this.teamType < 80) {
                    const temp = _.groupBy(roundData.flights[0].flightMembers, 'teamID');

                    _.each(temp, (teamMembers) => {
                        let playerNames = '';
                        teamMembers.forEach((player) => {
                            playerNames += player.profile.surname + '/';
                        });
                        playerNames = playerNames.substr(0, playerNames.length - 1);
                        this.players.push(this.buildPlayerScores(teamMembers[0]));
                        this.players[this.players.length - 1].profile.firstName = playerNames;
                    });

                    // scramble
                } else {
                    let playerNames = '';
                    roundData.flights[0].flightMembers.forEach((player) => {
                        playerNames += player.profile.surname + '/';
                    });
                    playerNames = playerNames.substr(0, playerNames.length - 1);
                    this.players.push(this.buildPlayerScores(roundData.flights[0].flightMembers[0]));
                    this.players[this.players.length - 1].profile.firstName = playerNames;
                }

            } else {
                roundData.flights[0].flightMembers.forEach((player) => {
                    this.players.push(this.buildPlayerScores(player));
                });
            }
            // team
        }
        else {
            let playersInTeam = [];
            roundData.flights.forEach((flight) => {
                flight.flightMembers.forEach((player) => {
                    if (player.teamID === this.teamID) {
                        player.flightID = flight.flightID;
                        playersInTeam.push(this.buildPlayerScores(player));
                    }
                });
            });

            if (this.teamType >= 60) {

                const temp = _.groupBy(playersInTeam, 'flightID');

                _.each(temp, (teamMembers) => {
                    let playerNames = '';
                    teamMembers.forEach((player) => {
                        playerNames += player.profile.surname + '/';
                    });
                    playerNames = playerNames.substr(0, playerNames.length - 1);
                    this.players.push(this.buildPlayerScores(teamMembers[0]));
                    this.players[this.players.length - 1].profile.firstName = playerNames;
                });

            }
            else {
                playersInTeam.forEach((player) => {
                    this.players.push(this.buildPlayerScores(player));
                });
            }
        }

        this.players = this.players.sort((n1, n2) => {
            return n1.ball > n2.ball ? 1 : -1
        });
    }

    private buildPlayerScores(player: FlightMemberForScoredcard) {
        const actualScoreCard = _.cloneDeep(player.scores);

        for (let i = 0; i < this.numberOfHoles; i++) {
            if (actualScoreCard.find((score) => score.holeNumber === (i + 1))) {
                player.scores[i] = actualScoreCard.find((score) => score.holeNumber === (i + 1));
            } else {
                player.scores[i] = new ScoreForScoredcard();
                player.scores[i].holeNumber = (i + 1);
                player.scores[i].scoreGross = '0';
                player.scores[i].scoreNet = 0;
                player.scores[i].scorePoints = 0;
                player.scores[i].holeID = player.profile.gender === 1 ? this.teeMale[i].HoleID : this.teeFemale[i].HoleID;
            }

            // blob holes have a value of 99
            if (player.scores[i].scoreGross.toString() === '99') {
                player.scores[i].scoreGross = '/';
            }
        }

        return player;
    }

    private buildCourseData() {
        this.equalTeeData = true;
        this.numberOfHoles = this.courseData.holes.length;
        this.courseData.holes.forEach((hole) => {

            const male = hole.holeVersions.find((version) => version.holeColour.holeColourID === this.maleTeeColor);
            const female = hole.holeVersions.find((version) => version.holeColour.holeColourID === this.femaleTeeColor);

            this.teeMale.push({ Par: male.par, SI: male.si, Yards: male.yards, TeeColourHex: male.holeColour.colour, HoleID: hole.holeID });
            this.teeFemale.push({ Par: female.par, SI: female.si, Yards: female.yards, TeeColourHex: female.holeColour.colour, HoleID: hole.holeID });

            // If male and female tee data are equals then don't show par and SI rows of female tee
            if (male.par !== female.par || male.si !== female.si) {
                this.equalTeeData = false;
            }
        });
    }

    private checkGenderTees() {
        // check gender of the players and show female/male tee data
        let gender = this.players[0].profile.gender;

        this.players.forEach((player) => {
            if (player.profile.gender !== gender) {
                gender += player.profile.gender;
            }
        });
        if (gender === 1) {
            this.teeFemale = [];
        } else if (gender === 2) {
            this.teeMale = [];
            this.equalTeeData = false;
        }
    }

    private buildTeamTotals(roundData: RoundForScoredcard) {      
        console.log('g')  
        this.teamTotals = [];
        if (this.teamID) {            
            const leaderboard = this.roundLeaderboards.filter((leaderboard) => leaderboard.leaderboardTypeID === 2)
            if (leaderboard.length === 1) {
                
                for (let i = 0; i < this.numberOfHoles; i++) {
                    let points = [];
                    for (let index = 0; index < this.players.length; index++) {
                        const player = this.players[index];
                        if (roundData.gameType === 3) {
                            points.push(player.scores[i].scorePoints);
                        } else {
                            if(this.gameType == 1 && player.scores[i].scoreGross == '/')
                                continue;
                            points.push(player.scores[i].scoreNet);
                        }
                    }       

                    if (roundData.gameType === 3) {
                        points = points.filter((point) => point > 0).sort((a, b) => b - a);
                    } else {
                        points = points.filter((point) => point > 0).sort((a, b) => a - b);
                    }

                    let total = 0;
                    for (let c = 0; c < leaderboard[0].scoreBestOf; c++) {
                        if (points[c]) {
                            total += points[c];
                        }
                    }

                    this.teamTotals.push(total);
                }
            }
        }
    }
}
