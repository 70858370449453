import { Component, Input, OnChanges, ViewChild, SimpleChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SelectItem, Message } from 'primeng/primeng';
import { Team, Flight, Round, FlightMember } from '../../models';
import { FlightService } from '../../services';
import { ConfirmDialog } from '../../shared/index';
import * as _ from 'lodash';

@Component({
    selector: 'editable-flight-renderer',
    templateUrl: 'editable.flight.renderer.component.html',
    styleUrls: ['editable.flight.renderer.component.scss']
})
export class EditableFlightRendererComponent implements OnChanges, OnInit {
    @Input() editable: boolean = false;
    @Input() deleteable: boolean = true;
    @Input() flight: Flight;
    @Input() round: Round;
    @Input() holes: SelectItem[];
    @Input() public teams: Array<Team> = [];

    showTeam: boolean;
    teeOffTimeText: string;
    range: number[];
    startHole: number;
    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;

    constructor(public flightService: FlightService) {
    }
  ngOnInit(): void {
    this.holes = this.holes.map(hole => {
      return {label: `Hole ${hole.value}`, value: hole.value};
    })
  }

    ngOnChanges(changes: SimpleChanges) {
        if (this.flight != null) {
            this.teeOffTimeText = this.getTime(this.flight.teeOffTime);
            this.startHole = this.flight.startHole;
        }
    }

    changedHole() {
        this.flight.startHole = this.startHole;
        this.flightService.update(this.round.competitionID.toString(), this.round.roundID.toString(), this.flight);
    }

    blurTeeOffTime() {
        if (this.getTeeOffTime(this.teeOffTimeText).getTime() != moment.utc(this.flight.teeOffTime).toDate().getTime()) {
            this.flight.teeOffTime = this.getTeeOffTime(this.teeOffTimeText);
            this.flightService.update(this.round.competitionID.toString(), this.round.roundID.toString(), this.flight);
        }
    }

    removeFlight() {
        var self = this;
        var callBack = function () {
            self.flightService.remove(self.round.competitionID.toString(), self.round.roundID.toString(), self.flight.flightID);
        }
        this.confirmDialog.showDialog(callBack);
    }

    getTeeOffTime(timeText: string): Date {
        var momentSet = moment.utc(this.flight.teeOffTime);
        var start = momentSet.startOf('day');
        start.add(moment.duration(timeText));
        return start.toDate();
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }

    getBallMember(ball: number): FlightMember {
        var empty = new FlightMember();
        empty.ball = ball;
        empty.flightID = this.flight.flightID;
        empty.roundID = this.flight.roundID;
        if (this.flight.flightMembers == undefined) {
            return empty;
        }
        var index = _.findIndex(this.flight.flightMembers, 'ball', ball);
        if (index != -1) {
            return this.flight.flightMembers[index];
        }
        return empty;
    }
}
