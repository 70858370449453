/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flights.renderer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./editable.flight.renderer.component.ngfactory";
import * as i3 from "./editable.flight.renderer.component";
import * as i4 from "../../services/FlightService";
import * as i5 from "@angular/common";
import * as i6 from "./flights.renderer.component";
var styles_FlightsRenderer = [i0.styles];
var RenderType_FlightsRenderer = i1.ɵcrt({ encapsulation: 0, styles: styles_FlightsRenderer, data: {} });
export { RenderType_FlightsRenderer as RenderType_FlightsRenderer };
function View_FlightsRenderer_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-4 m-b-20 tee-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "editable-flight-renderer", [], null, null, null, i2.View_EditableFlightRendererComponent_0, i2.RenderType_EditableFlightRendererComponent)), i1.ɵdid(2, 638976, null, 0, i3.EditableFlightRendererComponent, [i4.FlightService], { editable: [0, "editable"], deleteable: [1, "deleteable"], flight: [2, "flight"], round: [3, "round"], holes: [4, "holes"], teams: [5, "teams"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editable; var currVal_1 = _co.deleteable; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.round; var currVal_4 = _co.holes; var currVal_5 = _co.teams; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_FlightsRenderer_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightsRenderer_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FlightsRenderer_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightsRenderer_1)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.range; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FlightsRenderer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "flights-renderer", [], null, null, null, View_FlightsRenderer_0, RenderType_FlightsRenderer)), i1.ɵdid(1, 49152, null, 0, i6.FlightsRenderer, [], null, null)], null, null); }
var FlightsRendererNgFactory = i1.ɵccf("flights-renderer", i6.FlightsRenderer, View_FlightsRenderer_Host_0, { flights: "flights", teamColoursSelect: "teamColoursSelect", flightType: "flightType", editable: "editable", deleteable: "deleteable", round: "round", holes: "holes", teams: "teams" }, {}, []);
export { FlightsRendererNgFactory as FlightsRendererNgFactory };
