
import {share} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Venue } from "../models";
import { Observable ,  Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpServices } from './HttpServices';

@Injectable()
export class VenueService {
    venue$: Observable<Venue[]>;
    private _venuesObserver = new Subject<Venue[]>();

    private _dataStore: {
        venues: Venue[];
    };

    headers: Headers = new Headers();

    constructor(public httpService: HttpServices) {
        this._dataStore = { venues: [] };
        this.venue$ = this._venuesObserver.asObservable().pipe(share());
        this.headers.append('Content-Type', 'application/json; charset=utf-8');
    }

    loadForCourse(courseID: number): Observable<Venue> {
        var venueObserver = new Subject<Venue>();
      var url = `${environment.apiUrl}/api/Venue/ForCourse/${courseID}`
        this.httpService.get(url).subscribe((data: Venue) => {
            venueObserver.next(data);
            venueObserver.complete();
            let notFound = true;
            this._dataStore.venues.forEach((item, index) => {
                if (item.venueID === data.venueID) {
                    this._dataStore.venues[index] = data;
                    notFound = false;
                }
            });

            if (notFound) {
                this._dataStore.venues.push(data);
            }
            this._venuesObserver.next(this._dataStore.venues);
        }, error => console.log('Could not load venue'));
        return venueObserver;
    }

    load(venueID: number): void {
      var url = `${environment.apiUrl}/api/Venue/${venueID}`
        this.httpService.get(url).subscribe((data: Venue) => {
            let notFound = true;
            this._dataStore.venues.forEach((item, index) => {
                if (item.venueID === data.venueID) {
                    this._dataStore.venues[index] = data;
                    notFound = false;
                }
            });

            if (notFound) {
                this._dataStore.venues.push(data);
            }

            this._venuesObserver.next(this._dataStore.venues);
        }, error => console.log('Could not load venue'));
    }

    loadMatching(title: string): Observable<Venue[]> {
        var matched = new Subject<Venue[]>();
      var url = `${environment.apiUrl}/api/Venue/GetName/${title}`
        this.httpService.get(url).subscribe((data: Array<Venue>) => {
            this._dataStore.venues = data;
            this._venuesObserver.next(this._dataStore.venues);
            matched.next(data);
            matched.complete();
        }, error => console.log('Could not load venues.'));
        return matched;
    }
}
