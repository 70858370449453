<p-dialog   [(visible)]="display" modal="modal" showEffect="fade" [resizable]="false" [closable]="false" [draggable]="false">
    <p-header>
        {{titleText}}
        <span (click)="cancel()" class="pull-right">
            <i class="fa fa-times-circle"></i>
        </span>
    </p-header>
    <div>
        {{message}}
    </div>
    <div class="p-t-10">
        {{subMessage}}
    </div>
    <p-footer>
        <button type="button" class="btn btn-default cancel-button" pButton (click)="cancel()" label="Cancel"></button>
        <button type="button" class="btn btn-default confirm-button" pButton (click)="confirm()" label="{{okButtonText}}"></button>
    </p-footer>
</p-dialog>
