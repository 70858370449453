import { FlightMember } from './flightMember';
import { Team } from './team';

export class FlightTeamGroup {
    constructor(decimalScoringVal: boolean) {
        this.decimalScoring = decimalScoringVal;
    }
    teamID: number = 0;
    flightID: number = 0;
    roundID: number = 0;
    competitionID: number = 0;
    title: string = '';
    colour: string = '';
    colourText: string = '';
    displayHeader: boolean = false;
    allowReassign: boolean = true;
    mergedCells: boolean = false;
    decimalScoring: boolean = false;
    mergedValue?: number = null;
    showShots: boolean = false;
    team: Team = new Team();
    flightMembers: Array<FlightMember> = [];
}