
import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { UploadService } from '../../../services';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'team-logo-upload',
    templateUrl: 'team-logo-upload.component.html'
})

export class TeamLogoUpload implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form;
    @Input() competitionID: string;
    @Input() teamID: string;
    @Output() fileUploaded: EventEmitter<any> = new EventEmitter();
    uploadInProgress: boolean;
    public type: any;
    public $stop: Subject<boolean> = new Subject<any>();

    constructor(private uploadService: UploadService, private toastr: ToastrService) {
    }
    ngOnInit() {
        this.uploadService.progress$.pipe(takeUntil(this.$stop)).subscribe(
            data => {
                console.log('progress = ' + data);
            });
    }

    onChangeUpload(event) {
        this.uploadInProgress = true;
        var Files = event.target.files;
        var fileUploadUrl = `${environment.apiUrl}/api/Competitions/${this.competitionID}/Teams/${this.teamID}/PostLogo`;
        this.toastr.info('Uploading Team Logo. This may take a minute or so');

        this.uploadService.addMedia(fileUploadUrl, Files).subscribe(response => {
            this.form.nativeElement.reset();
            this.toastr.success('Your Team Logo has been uploaded');
            this.fileUploaded.next(true);
            this.uploadInProgress = false;
        }, err => {
            this.toastr.error('Failed to upload Team Logo');
        }, () => {
        });
    }    

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.$stop.next(true);
    }
}
