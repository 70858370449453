import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RoundMessage } from '../models';
import { HttpServices } from './HttpServices';

@Injectable()
export class MessagingService {    

    constructor(public httpService: HttpServices) {        
    }

    sendMessage(message: RoundMessage): void {
      var url = `${environment.apiUrl}/api/message/flight/${message.TeeTimeID}?Messages=${message.Messages}&Title=${message.Title}&Link=${message.Link}`;
        this.httpService.post(url,null).subscribe(error => console.log('Could not send messages.'));
    }
}
