<div class="tvFeed">
    <div class="no-results" *ngIf="pages?.length === 0 && dataLoaded">
        <img class="vpar-noresults" src="http://dev.vpar.com/public/games/tv/img/TVEmptyState.jpg">
        <img class="vpar-footer" [src]="getImageURL('http://dev.vpar.com/public/games/tv/img/poweredb-vpar.png')">
    </div>
    <div *ngIf="pages?.length > 0 && dataLoaded">
        <div class="page" *ngFor="let page of pages; let index = index" [ngClass]="{'show-animation': page.showAnimation, 'hide-animation': page.hideAnimation}">
            <div class="advert" *ngIf="page.cdn">
                <img class="image-advert" [src]="getImageURL(page.cdn)">
                <img class="vpar-footer" [src]="getImageURL('http://dev.vpar.com/public/games/tv/img/poweredb-vpar.png')">
            </div>
            <div class="leaderboard" *ngIf="page.roundLeaderboardID" [style.background-image]="backgroundImage">
                <div class="leaderboard-content">
                    <div class="section tournament">
                        <div class="section-title">
                            <span>TOURNAMENT</span>
                        </div>
                        <div class="section-content left">
                            <div class="text-big">{{tvFeedConfig.competitionName}}</div>
                            <div class="text-small">VENUE:</div>
                            <div class="text-big">{{page.venue}}</div>
                            <div class="text-small">COURSE:</div>
                            <div class="text-big">{{page.course}}</div>
                            <div class="vpar-logo">
                                <img src="{{environment.imagesCDNUrl}}vparLogoRGB.svg" alt="" />
                            </div>
                        </div>
                        <div class="section-logo">
                            <div class="arrival-logo" *ngIf="tvFeedConfig.leftlogo !== tvFeedConfig.rightLogo">
                                <img src="{{tvFeedConfig.leftlogo}}" *ngIf="tvFeedConfig.leftlogo !== ''" alt="" />
                            </div>
                            <div class="arrival-logo" *ngIf="tvFeedConfig.rightLogo !== ''">
                                <img src="{{tvFeedConfig.rightLogo}}" *ngIf="tvFeedConfig.rightLogo !== ''" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <div class="section-title">
                            <span>{{page.title}}</span>
                        </div>
                        <div class="section-content">
                            <div class="no-players" *ngIf="page.screenPlayers.length === 0">
                                <span>There are no registered players for this tournament yet</span>
                            </div>
                            <!-- DEFAULT -->
                            <default-leaderboard [leaderboard]="page" [decimalScoring]="roundIsDecimalScoring" *ngIf="page?.dataLoaded && page.config.columns.length > 0 && page.screenPlayers.length > 0" style="flex:1; display: flex;flex-direction: column"></default-leaderboard>
                            <!-- MATCHPLAY -->
                            <matchplay-leaderboard style="flex: 1;display: flex;flex-direction: column;" [matchplayLeaderboard]="page" *ngIf="page?.dataLoaded && page.config.columns.length === 0 && page.screenPlayers.length > 0"></matchplay-leaderboard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>