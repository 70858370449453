// Import other necessary modules and components
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { OrderOfMeritOverviewComponent } from "./orderofmerit-overview.component";

import {
  SharedModule,
  DropdownModule,
  DataTableModule,
  DialogModule,
  SpinnerModule,
  CalendarModule,
  ConfirmDialogModule,
  ConfirmationService,
  ToggleButtonModule,
  InputSwitchModule,
  TooltipModule,
  ProgressBarModule,
} from "primeng/primeng";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrderofmeritCreateComponent } from "./orderofmerit-create.component";
import { OrderOfMeritService } from "src/app/services/orderOfMeritService";
import { DatePipe } from "@angular/common";
import { OrderofmeritEditComponent } from "./orderofmerit-edit.component";
import { OrderOfMeritComponent } from "./orderofmerit-component";
import { AppSharedModule } from "src/app/shared/app.shared.module";
import { OrderofmeritLeaderboardComponent } from "./orderofmerit-leaderboard.component";

@NgModule({
  imports: [
    CalendarModule,
    ReactiveFormsModule,
    DropdownModule,
    SpinnerModule,
    DialogModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    DataTableModule,
    RouterModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    InputSwitchModule,
    TooltipModule,
    ProgressBarModule,
    AppSharedModule
  ],
  declarations: [
    OrderOfMeritOverviewComponent,
    OrderofmeritCreateComponent,
    OrderofmeritEditComponent,
    OrderofmeritLeaderboardComponent,
    OrderOfMeritComponent,
  ],
  bootstrap: [OrderOfMeritComponent],
  providers: [ConfirmationService, OrderOfMeritService, DatePipe],
})
export class OrderofmeritModule {}
