<p-dialog [(visible)]="show" [header]="leaderboardHeaderText" [responsive]="true" [modal]="true" [width]="750" [resizable]="true" [draggable]="false">
    <div style="width: 715px; overflow:hidden"> 
            <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="leaderboardEditDetail">
                <div class="edit-pod-row m-t-0">
                    <h3 class="m-t-0">Name your Leaderboard</h3>
                    <input pInputText class="form-control" id="lb_Title" name="Title" placeholder="Name of Leaderboard" [(ngModel)]="leaderboardEditDetail.title" />
                </div>
                <div class="p-t-10">
                    <a class="pull-left LinkSetup" (click)="ShowHoleConfig($event)">Setup</a>
                    <div *toggleFeature="'holeConfig';offeringLevel:offeringLevel">
                        <a *ngIf="leaderboardEditDetail.leaderboardTypeID == 2" class="pull-left LinkHole" (click)="ShowLbSetup($event)" style="margin-left:45px; margin-top:-10px;">Hole Configuration</a>
                    </div>                    
                </div>
                <hr />
                <div *ngIf="!tabSelect">
                    <div class="col-md-12 edit-pod-row p-l-0 first" style="margin-left: 2px;">
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="" [style]="{'margin-top':'2px'}">Team Format</label>
                            <p-dropdown class="OverInput_override" [options]="teamFormat" id="leaderboardTypeID" name="leaderboardTypeID" (onChange)="onTeamFormatChange($event)" [(ngModel)]="leaderboardEditDetail.leaderboardTypeID" [autoWidth]="false" [style]="{'width':'150px', height:'33px', 'margin-left':'2px'}"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="">{{'TOURNAMENT.LEADERBOARD.GAME_FORMAT' | translate}}</label>
                            <p-dropdown class="OverInput_override" [options]="gameFormat" id="overrideGameType" name="overrideGameType" [(ngModel)]="leaderboardEditDetail.overrideGameType" [autoWidth]="false" [style]="{'width':'150px', height:'33px'}" [disabled]="leaderboardEditDetail.leaderboardTypeID == 3"
                                        [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 3 }"></p-dropdown>
                            <div *ngIf="gameFormatError && !leaderboardEditDetail.overrideGameType" class="alert alert-danger game-format-alert">
                                Required field
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="leaderboardEditDetail.leaderboardTypeID!=2">
                            <label class="OverLayLbl" for="">Player Type</label>
                            <p-dropdown class="OverInput_override" [options]="playerType" id="flightMemberType" name="flightMemberType" [(ngModel)]="leaderboardEditDetail.flightMemberType" [autoWidth]="false" [style]="{'width':'150px', height:'33px'}" [disabled]="leaderboardEditDetail.leaderboardTypeID == 3 || leaderboardEditDetail.leaderboardTypeID == 6"
                                        [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 3 || leaderboardEditDetail.leaderboardTypeID == 6}"></p-dropdown>
                        </div>
                    </div>
                    <div *ngIf="leaderboardEditDetail.leaderboardTypeID==2" class="col-md-12 edit-pod-row p-l-0" style="margin-left:350px; margin-top: -67px; width:360px; height:56px;">
                        <div class="col-md-12">
                            <label for="Categories">Filter Categories</label>
                            <p-chips [(ngModel)]="leaderboardEditDetail.filterCategoryArray" name="filterCategoryArray" allowDuplicate="false" addOnTab="true"></p-chips>
                        </div>
                    </div>
                    <div *ngIf="leaderboardEditDetail.leaderboardTypeID==6" class="col-md-12 edit-pod-row p-l-0" style="margin-left:520px; width:200px; margin-top:-68px; margin-bottom: -60px; height:58px;">
                        <div class="col-md-12">
                            <label>Starting Pot</label>
                            <input type="number" [(ngModel)]="leaderboardEditDetail.skinsStartingPot" name="startingPot"/>
                        </div>
                    </div>
                    <div *ngIf="leaderboardEditDetail.overrideGameType == 4" class="p-l-0">
                        <div class="col-md-12  edit-pod-row" style="margin-top:2px; margin-left:-13px;">
                            <div class="col-md-3">
                                <label class="Skins_OverLayLbl" for="">Skin Value</label>
                                <input pInputText class="Skins_OverInput" id="skinsStartingPot" name="skinsStartingPot" [(ngModel)]="leaderboardEditDetail.skinsStartingPot" />
                            </div>
                            <div class="col-md-3">
                                <label class="Skins_OverLayLbl" for="">Currency</label>
                                <p-dropdown class="OverInput_override" [options]="skinCurrencyTypes" id="skinCurrencyTypes" name="skinCurrencyTypes" [style]="{'width':'150px','height':'33px'}" [(ngModel)]="leaderboardEditDetail.skinsCurrencyType" [autoWidth]="false"></p-dropdown>
                            </div>
                            <div class="col-md-3">
                                <!--Not in model-->
                                <label class="Skins_OverLayLbl" for="">Rollover</label>
                                <p-dropdown class="OverInput_override" [options]="skinRolloverType" id="skinRolloverType" name="skinRolloverType" [style]="{'width':'150px','height':'33px'}" [(ngModel)]="leaderboardEditDetail.skinrollovertype" [autoWidth]="false"></p-dropdown>
                            </div>
                            <div class="col-md-3">
                                <!--skinsStartingPot Appears twice now bound to Win Bonus-->
                                <label class="Skins_OverLayLbl" for="">Win Bonus</label>
                                <input pInputText class="Skins_OverInput" id="skinsStartingPot" name="skinsStartingPot" [(ngModel)]="leaderboardEditDetail.skinsStartingPot" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 edit-pod-row p-l-0 second" style="margin-left:2px;">
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="">Scoring Method</label>
                            <p-dropdown class="OverInput_override" appendTo="body" [disabled]="leaderboardEditDetail.leaderboardTypeID == 1 || leaderboardEditDetail.leaderboardTypeID == 3 || leaderboardEditDetail.leaderboardTypeID == 6" [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 1 || leaderboardEditDetail.leaderboardTypeID == 3 || leaderboardEditDetail.leaderboardTypeID == 6 }"
                                        [options]="scoringMethod" id="scoreBestOf" name="scoreBestOf" [(ngModel)]="leaderboardEditDetail.scoreBestOf" [autoWidth]="false" [style]="{'width':'150px', height:'33px','margin-left':'1px'}"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="">Scoring Type</label>
                            <p-dropdown class="OverInput_override" [options]="scoringType" id="scoringType" name="scoringType" [(ngModel)]="leaderboardEditDetail.grossNetOption" [autoWidth]="false" [style]="{'width':'150px', height:'33px', 'margin-left':'2px'}" [disabled]="leaderboardEditDetail.leaderboardTypeID == 3"
                                        [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 3 }"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="">Aggregate Score</label>
                            <p-dropdown class="OverInput_override" [disabled]="leaderboardEditDetail.leaderboardTypeID == 6" [options]="aggregateType" id="aggregateScoreType" name="aggregateScoreType" [(ngModel)]="leaderboardEditDetail.aggregateScoreType" [autoWidth]="false" [style]="{'width':'150px', height:'33px'}" [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 6 }"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="">Aggregate Thru</label>
                            <p-dropdown class="OverInput_override" [disabled]="leaderboardEditDetail.leaderboardTypeID == 6" [options]="aggregateThruType" id="aggregateThruType" name="aggregateThruType" [(ngModel)]="leaderboardEditDetail.aggregateThruType" [autoWidth]="false" [style]="{'width':'150px', height:'33px'}" [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 6 }"></p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-12 edit-pod-row p-l-0 third">
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="">{{'TOURNAMENT.LEADERBOARD.COUNTBACK_TYPE' | translate}}</label>
                            <p-dropdown class="OverInput_override" appendTo="body" [options]="countbackType" id="countbackType" name="countbackType" [(ngModel)]="leaderboardEditDetail.countbackType" [autoWidth]="false" [style]="{'width':'150px', height:'33px', 'margin-left':'2px'}" [ngClass]="{'drop-disabled': leaderboardEditDetail.leaderboardTypeID == 6}"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <label class="OverLayLbl" for="FreezeOnXHole">Freeze On X Hole</label>
                            <p-dropdown class="OverInput_override" appendTo="body" [options]="freezeOnXHole" [(ngModel)]="leaderboardEditDetail.freezeOnXHole" id="FreezeOnXHole" name="FreezeOnXHole"  [autoWidth]="false" [style]="{ 'width': '150px', height: '33px', 'margin-left': '2px' }"></p-dropdown>
                        </div>                       
                        <!-- <div class="toggle-container col-md-3">
                            <label for="toggle-website">Is On Website</label>
                            <div class="toggle-switch">
                                <input type="checkbox" id="toggle-website" class="toggle-input" (change)="onToggleWebSite($event)">
                                <label for="toggle-website" class="toggle-label"></label>
                            </div>
                        </div>
                        
                        <div class="toggle-container col-md-3">
                            <label for="toggle-handset">Is On Handset</label>
                            <div class="toggle-switch">
                                <input type="checkbox" id="toggle-handset" class="toggle-input" (change)="onToggleHandset($event)">
                                <label for="toggle-handset" class="toggle-label"></label>
                            </div>
                        </div>
                         -->
                        
                        <div class="col-md-3">
                        </div>
                    </div>
                </div>
                <div *ngIf="tabSelect">
                    <div class="col-md-12 edit-pod-row p-l-0">
                        <div class="col-md-3">
                            <button type="submit" class="btn btn-dark m-b-5" (click)="AddHoleConfig($event)">Add Hole</button>
                        </div>
                        <div class="col-md-2 divIcon">
                            <a (click)="CopyHoleConfig($event)" class="cursor-pointer">
                                <img src="{{environment.imagesCDNUrl}}copy.png" (click)="CopyHoleConfig($event)" /> Copy
                            </a>
                        </div>
                        <div class="col-md-2">
                            <a (click)="DeleteHoleConfig($event)" class="cursor-pointer">
                                <img src="{{environment.imagesCDNUrl}}delete.png" (click)="DeleteHoleConfig($event)" /> Delete
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a (click)="SelectAllHoleConfig($event)" class="cursor-pointer">
                                <img src="{{environment.imagesCDNUrl}}select.png" (click)="SelectAllHoleConfig($event)" /> Select All
                            </a>
                        </div>
                    </div>
                    <div *ngIf="showEmptyState == true" class="col-md-12 EmptyHoleConfig p-l-0">
                        <div class="row">
                            <img class="center-block imgpadding" src="{{environment.imagesCDNUrl}}golfFlag.png" />
                        </div>
                        <div class="row">
                            <h3 class="text-center">Add custom scoring combinations hole by hole</h3>
                        </div>
                        <div class="row">
                            <p class="text-center">Get started by selecting a "Team Leaderboard" & adding a hole </p>
                        </div>
                    </div>
                    <div *ngIf="showEmptyState == false" class="ListHoleConfig p-l-0">
                        <hole-configuration [roundLeaderboardConfig]="Configs" *ngFor="let Configs of roundLeaderboardConfigs"></hole-configuration>
                    </div>
                </div>
                <div class="col-md-12 third">
                    <div class="btn-group" style="float:right">
                        <button type="submit" class="btn btn-default m-b-5" label="Save" (click)="addLeaderboard($event)">Save</button>
                    </div>
                    <div class="btn-group" style="float:right">
                        <button type="submit" class="btn btn-default m-b-5" label="Cancel" (click)="Close($event)">Cancel</button>
                    </div>
                </div>
            </div>
    </div>
</p-dialog>
