import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DisplayItem } from '../../../models';

@Component({
    selector: 'leaderboard-pod',
    templateUrl: 'leaderboard.pod.component.html'
})

export class LeaderboardPodComponent {
    @Input() ChildDisplayDetails: DisplayItem;
    @Output() RequestDelete: EventEmitter<any> = new EventEmitter();
    @Output() RequestEdit: EventEmitter<any> = new EventEmitter();
    
    Remove() {
        this.RequestDelete.emit(this.ChildDisplayDetails);
    }

    Edit() {
        this.RequestEdit.emit(this.ChildDisplayDetails);
    }
}
