/// <reference path="publicroute.components.ts" />
import { NgModule } from '@angular/core';
import { Slide } from './slide.component';
import { Angular2Carousel } from './carousel-example';
import { PublicRouteComponent } from './publicroute.components';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [RouterModule],    // module dependencies
    declarations: [Angular2Carousel, PublicRouteComponent, Slide],   // components and directives
    exports: [Angular2Carousel, PublicRouteComponent, Slide],     // root component
    providers: []                // services
})
export class PublicModule { }
