import { Component, OnInit, OnDestroy } from '@angular/core';
import { TvFeedService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { TvFeed, Round } from '../../../models';
import { MenuItem } from 'primeng/primeng';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'leaderboard',
    templateUrl: 'tournament-overview-leaderboard.component.html',
    styleUrls: ['./tournament-overview-leaderboard.component.scss']
})

export class TournamentOverviewLeaderboardComponent implements OnInit, OnDestroy {

    roundId: string;
    tabItems: MenuItem[];
    activeTab: MenuItem;
    activeTabIndex: number = 0;
    tvFeedConfig: TvFeed;
    pages: any[];
    lastUpdateDate = '';

    public $stop: Subject<boolean> = new Subject<any>();
    roundIsDecimalScoring: boolean;

    constructor(private route: ActivatedRoute,
        private tvFeedService: TvFeedService,
        private router:Router) {
        window.setInterval(() => this.getLeaderboardConfigs(), 30000);
    }

    ngOnInit() {
        this.route.data.pipe(takeUntil(this.$stop)).subscribe((data) => {
            let round = <Round>data['round'];
            this.roundIsDecimalScoring = round.roundConfiguration.useDecimalScoring;
        });
        this.route.params.subscribe(params => {
            this.roundId = params['roundId'];

            this.tvFeedService.loadTVFeedConfig(this.roundId).subscribe(res => {
                this.tvFeedConfig = res;
                this.pages = [];
                this.pages.push(...res.leaderboard);

                this.pages.forEach((item) => {
                    item.dataLoaded = false;
                    item.screenPlayers = [];
                    item.showList = false;
                    item.teams = null;
                });


                this.pages = _.sortBy(this.pages, 'viewOrder');
   

                if (this.pages.length > 0) {
                    this.buildMenuItems();
                    //setInterval(() => {
                        this.getLeaderboardConfigs()
                    //}, 30000);
                }
            });
        });
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    private getLeaderboardConfigs() {
      window.addEventListener('resize', () => {
        if(this.isPublic() && window.innerWidth < 620){
          this.lastUpdateDate = '';
        }else{
          this.lastUpdateDate = moment(new Date()).format("HH:mm:ss");
        }
      });
      this.lastUpdateDate = moment(new Date()).format("HH:mm:ss");

        this.pages.forEach((page, index) => {
            this.tvFeedService.getRoundConfig(this.roundId, page.roundLeaderboardID).subscribe(res => {
                page.config = res;
                this.tvFeedService.getRoundLeaderboardData(this.pages[index].config.feedURL, this.pages[index].config.columns.length === 0).subscribe(res => {

                    // matchplay
                    if (this.pages[index].config.columns.length === 0) {

                        this.pages[index].screenPlayers = res.Response.Pairs ? res.Response.Pairs.Flight : [];
                        this.pages[index].teams = res.Response.Teams ? res.Response.Teams : null;

                    } else {
                        // sort the list by Pos
                        this.pages[index].screenPlayers = res.Response.L.T.sort((a, b) => {

                            // first transform pos value to something sortable
                            const valueA = isNaN(Number(a.Position.split('=')[0])) ? 99999 : Number(a.Position.split('=')[0]);
                            const valueB = isNaN(Number(b.Position.split('=')[0])) ? 99999 : Number(b.Position.split('=')[0]);

                            // sort ascending (check total in case equal value)
                            return valueA - valueB;
                        });
                    }
                    this.pages[index].dataLoaded = true;
                    this.pages[index].showList = true;
                });
            });
        });
    }


    private buildMenuItems() {
      this.tabItems = [];
      this.pages.forEach((page, index) => {
          this.tabItems.push({
              label: page.title,
              visible: true,
              command: (event) => {
                  this.setMenuOption(index);
              }
          });
      });

      // Set the initial active tab to index 0
      this.setMenuOption(this.activeTabIndex);
  }


    private setMenuOption(selectedIndex: number) {
      this.activeTabIndex = selectedIndex;
      this.activeTab = this.tabItems[selectedIndex];
    }
    isPublic():boolean{
      return this.router.url.includes("public");
    }
}
