import { Injectable } from '@angular/core';
import { Tracking } from '../models';
import { Observable ,  Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpServices } from './HttpServices';

@Injectable()
export class TrackingService {

    headers: HttpHeaders = new HttpHeaders();

    constructor(public httpService: HttpServices, private http: HttpClient) {
        this.headers.append('Content-Type', 'application/json; charset=utf-8');
    }

    // getRoundTracking(roundId: string): Observable<Tracking[]> {
    //     var trackingObserverable = new Subject<Tracking[]>();
    //   this.httpService.getTyped<Tracking[]>(`${environment.apiUrl}/api/tracking/round/${roundId}`)
    //         .subscribe(data => {
    //             trackingObserverable.next(data);
    //         },
    //             err => console.log(err)
    //         );
    //     return trackingObserverable;
    // }
    getRoundTracking(roundId: string): Observable<any> {
      var trackingObserverable = new Subject<any>();
    this.http.get(`${environment.apiUrl}/api/tracking/round/${roundId}`)
      .subscribe(data => {
        trackingObserverable.next(data);
      },
      err => console.log("Tracking service error: ", err));
      return trackingObserverable;
  }
}
