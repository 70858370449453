/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tab-menu/tab-menu.component.ngfactory";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "../../../shared/components/tab-menu/tab-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/cdk/layout";
import * as i6 from "./round-overview-menu.component";
import * as i7 from "../../../services/login.service";
import * as i8 from "../../../services/branding.service";
var styles_RoundOverviewMenuComponent = [];
var RenderType_RoundOverviewMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoundOverviewMenuComponent, data: {} });
export { RenderType_RoundOverviewMenuComponent as RenderType_RoundOverviewMenuComponent };
export function View_RoundOverviewMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "round-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "vpar-tabMenu", [["styleClass", "menu"]], null, null, null, i1.View_VparTabMenu_0, i1.RenderType_VparTabMenu)), i0.ɵprd(4608, null, i2.DomHandler, i2.DomHandler, []), i0.ɵdid(3, 638976, null, 0, i3.VparTabMenu, [i4.Router, i5.BreakpointObserver], { model: [0, "model"], activeItem: [1, "activeItem"], styleClass: [2, "styleClass"], initialIndex: [3, "initialIndex"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabItems; var currVal_1 = _co.activeRound; var currVal_2 = "menu"; var currVal_3 = _co.selectedIndex; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_RoundOverviewMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "round-overview-menu", [], null, null, null, View_RoundOverviewMenuComponent_0, RenderType_RoundOverviewMenuComponent)), i0.ɵdid(1, 638976, null, 0, i6.RoundOverviewMenuComponent, [i4.ActivatedRoute, i4.Router, i7.LoginService, i8.BrandingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoundOverviewMenuComponentNgFactory = i0.ɵccf("round-overview-menu", i6.RoundOverviewMenuComponent, View_RoundOverviewMenuComponent_Host_0, { roundId: "roundId", offeringLevel: "offeringLevel" }, {}, []);
export { RoundOverviewMenuComponentNgFactory as RoundOverviewMenuComponentNgFactory };
