<div class="page-content">
    <div class="panel">
        <div class="panel-content row" id="table-section">
            <div class="SetupWizard">
                <div class="col-lg-4 SetupWizard_step SetupWizard_step1">
                    <span class="span_active">Setup</span>
                </div>
                <div class="col-lg-4 SetupWizard_step SetupWizard_step2">
                    <div class="arrow-right"></div>
                    <span>Players</span>
                </div>
                <div class="col-lg-4 SetupWizard_step SetupWizard_step3">
                    <span>Leaderboards</span>
                </div>
            </div>
            <div class="col-lg-12 tabs tabs-linetriangle p-20">
                <form (ngSubmit)="onSubmit(competitionForm.value)" [formGroup]="competitionForm">
                    <p class="m-b-15 f-18 bold">First, let's get started by setting up your tournament</p>
                    <div class="row">
                        <div class="form-group col-lg-5">
                            <p class="text-muted bold">Tournament Name</p>
                            <input type="text" class="form-control input-lg" formControlName="title" placeholder="Enter Tournament Name">
                            <div [hidden]="competitionForm.controls.title.valid || competitionForm.controls.title.pristine" class="alert alert-danger">
                                Tournament Name is required
                            </div>
                        </div>                       
                        <div class="form-group col-lg-2" *ngIf="showVPARHandicap">
                            <p class="text-muted bold">
                                <span>
                                    <a href="#" class="popoverInfo"
                                       data-content="Enable if you want this game to count towards your players' VPAR handicap calculation.">
                                        <i class="fa fa-question-circle tooltip-colour"></i>
                                    </a>
                                </span>VPAR Handicap
                            </p>
                            <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!competitionForm.get('isHandicapQualified').value">
                                <p-inputSwitch styleClass="p-inputSwitch" (onChange)="setTeeAdjustment($event)" formControlName="isHandicapQualified" [disabled] = "handicapQualifiedDisabled"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="form-group col-lg-2" *ngIf="showTeeAdjustment">
                            <p class="text-muted bold">
                                <span>
                                    <a href="#" class="popoverInfo"
                                       data-content="Allows you to run the course difficulty tee adjustments to your players' handicaps when the game is not enabled for VPAR Handicap.">
                                        <i class="fa fa-question-circle m-r-10 tooltip-colour" ></i>
                                    </a>
                                </span>Tee Adjustment
                            </p>
                            <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!competitionForm.get('isTeeAdjustment').value">
                                <p-inputSwitch styleClass="p-inputSwitch" formControlName="isTeeAdjustment"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="form-group col-lg-2" *ngIf="orderOfMeritActive">
                            <p class="text-muted bold">
                                <span>
                                    <a href="#" class="popoverInfo"
                                       data-content="If this is on the scores from this round will update your order of merit leaderboard. When your competition is with Order Of Merit active you can have only one round">
                                        <i class="fa fa-question-circle m-r-10 tooltip-colour" ></i>
                                    </a>
                                </span>Order of Merit
                            </p>
                            <div class="form-control Override_input-lg inputSwitch" [class.inputSwitchOff]="!orderOfMeritRoundValue">
                                <p-inputSwitch styleClass="p-inputSwitch" (onChange)="setRoundOrderOfMerit($event)"></p-inputSwitch>
                            </div>
                        </div>
                    </div>
                    <p-tabView [activeIndex]="roundActiveIndex" class="roundsTab">
                        <p-tabPanel *ngFor="let item of getRounds(); let i=index" header="Round {{i + 1}}" [selected]="roundActiveIndex === i" [disabled]="!competitionForm.valid">
                            <round-renderer (gameTypeChange)="receiveGameTypeChange($event)" (roundDeleted)="deleteRound($event)" [roundCount]="competitionForm.get('rounds')['controls'].length" [isAgent]="isAgent" [venueID]="venueID" [index]="i" [item]="item" [dateFormat]="dateFormat" [dateFormatMoment]="dateFormatMoment"></round-renderer>
                        </p-tabPanel>
                    </p-tabView>
                    <button type="button" class="btn btn-link addRoundButton text-muted" (click)="addRound()" [disabled]="getRounds().length >= 10 || !competitionForm.valid || orderOfMeritRoundValue">
                        <i class="fa icon-plus"></i> Add another round
                    </button>
                    <div class="p-t-10">
                        <button type="submit" class="btn btn-default  pull-right" [disabled]="!competitionForm.valid">Next</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
