import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintTypeDetail, PrintTypeEnabledFilters } from './PrintTypeDetail';
import { PrintFormatDetail } from './PrintFormatDetail';
import { PrintOptionDetail } from './PrintOptionDetail';
import { PrintingService, CompetitionRouteService, FlightService, LeaderboardService, TeamsService, FlightMembersService } from '../../../services';
import { SelectItem } from 'primeng/primeng';
import { Round, Team, Flight, FlightMember, Competition, RoundLeaderboard } from '../../../models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

type PrintingFilter =
    "Team"
    | "TeeTime"
    | "Player"
    | "Leaderboard";

@Component({
    templateUrl: 'printing.component.html',
    styleUrls: ['printing.component.scss']
})

export class PrintingComponent implements OnInit, OnDestroy {
    roundId: string;
    tournamentId: string;

    PrintTypes: Array<PrintTypeDetail> = new Array<PrintTypeDetail>();
    PrintOptions: Array<PrintOptionDetail> = new Array<PrintOptionDetail>();
    PrintFormats: Array<PrintFormatDetail> = new Array<PrintFormatDetail>();

    selectedType: PrintTypeDetail = null;
    selectedOption: PrintOptionDetail = null;
    selectedFormat: PrintFormatDetail = null;

    teams: SelectItem[] = new Array<SelectItem>();
    flightMembers: SelectItem[] = new Array<SelectItem>();
    teeTimes: SelectItem[] = new Array<SelectItem>();
    leaderboards: SelectItem[] = new Array<SelectItem>();
    currentTeams: SelectItem[] = new Array<SelectItem>();
    currentFlightMembers: SelectItem[] = new Array<SelectItem>();
    currentTeeTimes: SelectItem[] = new Array<SelectItem>();
    currentLeaderboards: SelectItem[] = new Array<SelectItem>();
    teeTimeID?: number;
    learboardID?: number;
    flightMemberID?: number;
    teamID?: number;

    selectPlayersOptionEnabled: boolean;
    selectLeaderboardsOptionEnabled: boolean;
    selectTeamOptionEnabled: boolean;
    selectTeeTimeOptionEnabled: boolean;

    public $stop: Subject<boolean> = new Subject<any>();

    loading = false;

    constructor(private route: ActivatedRoute, private printingService: PrintingService, private teamsService: TeamsService,
        private flightService: FlightService, private flightMembersService: FlightMembersService, private leaderboardService: LeaderboardService) {

        var defaultTeamTeePlayerOption = {
            selectTeamOptionEnabled: true,
            selectTeamSingleOption: 999999,
            selectPlayersOptionEnabled: true,
            selectPlayersSingleOption: 999999,
            selectTeeTimeOptionEnabled: true,
            selectTeeTimeSingleOption: 999999
        };
        var defaultLeaderboardOption = {
            selectLeaderboardsOptionEnabled: true,
            selectLeaderboardsSingleOption: 999999
        };

        this.currentTeams = [{ label: '-', value: '' }];
        this.currentFlightMembers = [{ label: '-', value: '' }];
        this.currentTeeTimes = [{ label: '-', value: '' }];
        this.currentLeaderboards = [{ label: '-', value: '' }];

        this.PrintTypes.push(new PrintTypeDetail(1, "Start Sheet", ["PDF"], defaultTeamTeePlayerOption));
        this.PrintTypes.push(new PrintTypeDetail(201, "Score Labels", ["DOC"], defaultTeamTeePlayerOption));
        this.PrintTypes.push(new PrintTypeDetail(2, "Scorecards", ["PDF"], { selectTeamOptionEnabled: true, selectPlayersOptionEnabled: true, selectTeeTimeOptionEnabled: true }));
        this.PrintTypes.push(new PrintTypeDetail(4, "Leaderboards", ["PDF", "CSV"], defaultLeaderboardOption));
        this.PrintTypes.push(new PrintTypeDetail(7, "Cart Sign", ["PDF"], { selectTeamOptionEnabled: true, selectTeeTimeOptionEnabled: true }));
        this.PrintTypes.push(new PrintTypeDetail(5, "Results", ["PDF"], {}));
        this.PrintTypes.push(new PrintTypeDetail(3, "Scores", ["CSV"], defaultTeamTeePlayerOption));

        this.PrintOptions.push(new PrintOptionDetail(1, "Alpha List", "ProfileFirstName"));
    }

    ngOnInit() {

        var self = this;

        this.leaderboardService.RoundLeaderboard$.pipe(takeUntil(this.$stop)).subscribe(
            (data: Array<RoundLeaderboard>) => {
                if (data) {
                    var data = _.filter(data, function (roundLeaderboard) {
                        return (roundLeaderboard.title || '') != '';
                    });
                    var result = _.sortBy(data, 'viewOrder');
                    self.leaderboards = result.map(lb => { return { label: lb.title, value: lb.roundLeaderboardID } });
                }
            });


        this.teamsService.teams$.pipe(takeUntil(this.$stop)).subscribe(
            (data: Array<Team>) => {
                if (data) {
                    var result = data.map(team => { return { label: team.title, value: team.teamID } });
                    self.teams = _.sortBy(result, 'label');
                }
            });

        this.flightService.flights$.pipe(takeUntil(this.$stop)).subscribe(
            (data: Array<Flight>) => {
                this.teeTimes = this.teeTimes.concat(data.map(flight => { return { label: "Hole " + flight.startHole.toString() + " - " + moment.utc(flight.teeOffTime).format("HH:mm"), value: flight.flightID } }));
            });

        this.flightMembersService.flightMembers$.pipe(takeUntil(this.$stop)).subscribe(
            (flightMembers: Array<FlightMember>) => {
                var list = flightMembers.map(flightMember => { return { label: flightMember.profile.firstName + " " + flightMember.profile.surname, value: flightMember.flightMemberID } });
                this.flightMembers = _.sortBy(list, 'label');

            });

        this.route.parent.parent.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var tournament = <Competition>data['tournament'];
            this.tournamentId = tournament.competitionID.toString();
            this.teamsService.loadAll(tournament.competitionID.toString());
        });

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var round = <Round>data['round'];
            this.roundId = round.roundID.toString();
            this.flightService.loadAll(this.tournamentId, this.roundId);
            this.flightMembersService.loadAll(this.tournamentId, this.roundId);
            this.leaderboardService.loadAll(this.roundId);
        });       
    }

    printFormatUpdated(detail: PrintFormatDetail) {
        if (detail.selected) {
            this.selectedFormat = detail;
            var otherPrintFormatss = _.filter(this.PrintFormats, printFormat => {
                return printFormat.label != detail.label;
            })
            _.forEach(otherPrintFormatss, printFormat => {
                printFormat.selected = false;
            })
        } else {
            this.selectedFormat = null;
        }
    }

    printTypeUpdated(detail: PrintTypeDetail) {
        var current = _.find(this.PrintTypes, { 'id': detail.id });
        current.selected = detail.selected;
        this.PrintOptions.forEach(option => {
            option.available = false;
            option.selected = false;
        });

        if (detail.selected) {
            this.PrintFormats = current.formats.map(a => {
                var format = new PrintFormatDetail(a);
                return format;
            });
            if (this.PrintFormats.length == 1) {
                this.PrintFormats[0].selected = true;
                this.selectedFormat = this.PrintFormats[0];
            } else {
                if (this.selectedFormat != null && _.find(this.PrintFormats, { 'label': this.selectedFormat.label })) {
                    _.find(this.PrintFormats, { 'label': this.selectedFormat.label }).selected = true;

                };
            }

            this.teeTimeID = null;

            this.flightMemberID = null;
            this.teamID = null;
            this.selectedType = current;
            var otherPrintTypes = _.filter(this.PrintTypes, printType => {
                return printType.id != detail.id;
            })
            if (detail.id === 1) {
                var optionAlphaList = _.find(this.PrintOptions, 'id', 1);
                optionAlphaList.available = true;
            }

            this.selectPlayersOptionEnabled = detail.printTypeEnabledFilters.selectPlayersOptionEnabled;
            if (!this.selectPlayersOptionEnabled) {
                this.currentFlightMembers = [{ label: '-', value: '' }];
            }
            if (detail.printTypeEnabledFilters.selectPlayersSingleOption) {
                this.currentFlightMembers = [{ label: 'All Players', value: detail.printTypeEnabledFilters.selectPlayersSingleOption }];
            }
            if (!detail.printTypeEnabledFilters.selectPlayersSingleOption && this.selectPlayersOptionEnabled) {
                var flightMembers = [];
                flightMembers.push({ label: '-', value: null });
                flightMembers.push({ label: 'All Players', value: detail.printTypeEnabledFilters.selectPlayersSingleOption });
                flightMembers = flightMembers.concat(this.flightMembers);
                this.currentFlightMembers = flightMembers;
            }

            this.selectLeaderboardsOptionEnabled = detail.printTypeEnabledFilters.selectLeaderboardsOptionEnabled;
            if (!this.selectLeaderboardsOptionEnabled) {
                this.currentLeaderboards = [{ label: '-', value: '' }];
            }
            if (detail.printTypeEnabledFilters.selectLeaderboardsSingleOption && detail.printTypeEnabledFilters.selectLeaderboardsOptionEnabled) {
                this.currentLeaderboards = this.leaderboards;
                this.learboardID = this.currentLeaderboards[0].value;
            }


            this.selectTeamOptionEnabled = detail.printTypeEnabledFilters.selectTeamOptionEnabled;
            if (!this.selectTeamOptionEnabled) {
                this.currentTeams = [{ label: '-', value: '' }];
            }
            if (detail.printTypeEnabledFilters.selectTeamSingleOption) {
                this.currentTeams = [{ label: 'All Teams', value: detail.printTypeEnabledFilters.selectTeamSingleOption }];
            }
            if (!detail.printTypeEnabledFilters.selectTeamSingleOption && this.selectTeamOptionEnabled) {
                var teams = [];
                teams.push({ label: '-', value: null });
                teams.push({ label: 'All Teams', value: detail.printTypeEnabledFilters.selectTeamSingleOption });
                teams = teams.concat(this.teams);
                this.currentTeams = teams;
            }

            this.selectTeeTimeOptionEnabled = detail.printTypeEnabledFilters.selectTeeTimeOptionEnabled;
            if (!this.selectTeeTimeOptionEnabled) {
                this.currentTeeTimes = [{ label: '-', value: '' }];
            }
            if (detail.printTypeEnabledFilters.selectTeeTimeSingleOption) {
                this.currentTeeTimes = [{ label: 'All Tee Times', value: detail.printTypeEnabledFilters.selectTeeTimeSingleOption }];
            }
            if (!detail.printTypeEnabledFilters.selectTeeTimeSingleOption && this.selectTeeTimeOptionEnabled) {
                var teeTimes = [];
                teeTimes.push({ label: '-', value: null });
                teeTimes.push({ label: 'All Tee Times', value: detail.printTypeEnabledFilters.selectTeeTimeSingleOption });
                teeTimes = teeTimes.concat(this.teeTimes);
                this.currentTeeTimes = teeTimes;
            }
            _.forEach(otherPrintTypes, printType => {
                printType.selected = false;
            })
        } else {
            this.PrintFormats = [];
            this.selectedType = null;
            this.currentTeams = [{ label: '-', value: '' }];
            this.currentFlightMembers = [{ label: '-', value: '' }];
            this.currentTeeTimes = [{ label: '-', value: '' }];
            this.currentLeaderboards = [{ label: '-', value: '' }];
            this.selectPlayersOptionEnabled = false;
            this.selectLeaderboardsOptionEnabled = false;
            this.selectTeamOptionEnabled = false;
            this.selectTeeTimeOptionEnabled = false;
        }
    }

    printFilterUpdated(event: any, printingFilter: PrintingFilter) {
        if (event.value != 999999) {
            switch (printingFilter) {
                case "Team":
                    this.teeTimeID = null;
                    this.learboardID = null;
                    this.flightMemberID = null;
                    break;
                case "TeeTime":
                    this.learboardID = null;
                    this.flightMemberID = null;
                    this.teamID = null;
                    break;
                case "Player":
                    this.teeTimeID = null;
                    this.learboardID = null;
                    this.teamID = null;
                    break;
                case "Leaderboard":
                    this.teeTimeID = null;
                    this.flightMemberID = null;
                    this.teamID = null;
                    break;
            }
        }
    }

    printOptionUpdated(detail: PrintOptionDetail) {
        this.selectedOption = null;
        if (detail.selected) {
            this.selectedOption = detail;
        }
    }

    download() {
        this.loading = true;
        var reportType = this.selectedType.id;

        var sortColumn: string = null;
        if (this.selectedOption != null) {
            sortColumn = this.selectedOption.sortColumn
        }

        var outputFormatNumber = 0;
        if (this.selectedFormat.label == "PDF") {
            outputFormatNumber = 3;
        }
        if (this.selectedFormat.label == "CSV") {
            outputFormatNumber = 4;
        }
        if (this.selectedFormat.label == "DOC") {
            outputFormatNumber = 5;
        }


        let query: any;
        // if (reportType === 1) {
        //     query = {
        //         printingInput: {
        //             reportType: reportType,
        //             reportFormat: outputFormatNumber,
        //             reportOptions: _.pluck(_.where(this.PrintOptions, { 'selected': true }), 'id'),
        //             roundID: this.roundId,
        //             team: this.teamID,
        //             teeTime: this.teeTimeID,
        //             flightMember: this.flightMemberID,
        //             leaderboardID: this.learboardID
        //         }
        //     };

        //     this.printingService.Get2(query).subscribe(res => {
        //         this.loading = false;
        //         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //             window.navigator.msSaveOrOpenBlob(res);
        //         }
        //         else {
        //             var url = window.URL.createObjectURL(res);
        //             window.open(url);
        //         }
        //     }, (error) => {
        //         this.loading = false;
        //     });
        // } else {
        query = {
            roundID: this.roundId,
            leaderboardID: this.learboardID,
            reportType: reportType,
            team: this.teamID,
            teeTime: this.teeTimeID,
            flightMember: this.flightMemberID,
            options: _.pluck(_.where(this.PrintOptions, { 'selected': true }), 'id'),
            OutputFormat: outputFormatNumber,
            sortColumn: sortColumn
        };

        this.printingService.Get(this.tournamentId, query)
            .pipe(takeUntil(this.$stop))
            .subscribe((data: string) => {
                this.loading = false;   
                const url = data.substr(1,data.length -2);             
                window.open(url);
            }, (error) => {
                this.loading = false;
                console.log('Could not get Print Url.')
            });
        // }
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
}
