<div class="loading" *ngIf="loading || posting">
    <p-progressSpinner></p-progressSpinner>
</div>
<div style="height: 200px;" *ngIf="loading"></div>
<div class="scorecard-scroll">


    <table [@visibilityChanged]="!loading" class="scorecard-table" cellpadding="0" cellspacing="0" id="{{scorecardID}}">
        <tr class="header">
            <td class="first-column t-right">Hole</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>Out</td>
            <td *ngIf="numberOfHoles === 18">10</td>
            <td *ngIf="numberOfHoles === 18">11</td>
            <td *ngIf="numberOfHoles === 18">12</td>
            <td *ngIf="numberOfHoles === 18">13</td>
            <td *ngIf="numberOfHoles === 18">14</td>
            <td *ngIf="numberOfHoles === 18">15</td>
            <td *ngIf="numberOfHoles === 18">16</td>
            <td *ngIf="numberOfHoles === 18">17</td>
            <td *ngIf="numberOfHoles === 18">18</td>
            <td *ngIf="numberOfHoles === 18">In</td>
            <td>Total</td>
        </tr>
        <!-- MALE TEE COURSE DATA -->
        <tr class="sub-header top-separator" *ngIf="teeMale.length > 0">
            <td class="first-column t-right">Par</td>
            <td>{{teeMale[0].Par}}</td>
            <td>{{teeMale[1].Par}}</td>
            <td>{{teeMale[2].Par}}</td>
            <td>{{teeMale[3].Par}}</td>
            <td>{{teeMale[4].Par}}</td>
            <td>{{teeMale[5].Par}}</td>
            <td>{{teeMale[6].Par}}</td>
            <td>{{teeMale[7].Par}}</td>
            <td>{{teeMale[8].Par}}</td>
            <td class="sub-total">{{calculateSubtotal('Par', false, 'Male')}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[9].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[10].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[11].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[12].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[13].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[14].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[15].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[16].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[17].Par}}</td>
            <td *ngIf="numberOfHoles === 18" class="sub-total">{{calculateSubtotal('Par', true, 'Male')}}</td>
            <td class="total">{{calculateSubtotal('Par', false, 'Male') + calculateSubtotal('Par', true, 'Male')}}</td>
        </tr>
        <tr class="sub-header top-separator" *ngIf="teeMale.length > 0">
            <td class="first-column t-right">SI</td>
            <td>{{teeMale[0].SI}}</td>
            <td>{{teeMale[1].SI}}</td>
            <td>{{teeMale[2].SI}}</td>
            <td>{{teeMale[3].SI}}</td>
            <td>{{teeMale[4].SI}}</td>
            <td>{{teeMale[5].SI}}</td>
            <td>{{teeMale[6].SI}}</td>
            <td>{{teeMale[7].SI}}</td>
            <td>{{teeMale[8].SI}}</td>
            <td class="sub-total"></td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[9].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[10].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[11].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[12].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[13].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[14].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[15].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[16].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeMale[17].SI}}</td>
            <td *ngIf="numberOfHoles === 18" class="sub-total"></td>
            <td class="total"></td>
        </tr>
        <tr class="sub-header top-separator yard-row" *ngIf="teeMale.length > 0">
            <td class="first-column t-right" [style.background-color]="'#' + teeMale[0].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">Yds
            </td>
            <td [style.background-color]="'#' + teeMale[0].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[0].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[1].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[1].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[2].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[2].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[3].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[3].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[4].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[4].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[5].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[5].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[6].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[6].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[7].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[7].Yards}}</td>
            <td [style.background-color]="'#' + teeMale[8].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[8].Yards}}</td>
            <td class="sub-total">{{calculateSubtotal('Yards', false, 'Male')}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[9].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[9].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[10].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[10].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[11].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[11].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[12].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[12].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[13].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[13].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[14].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[14].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[15].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[15].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[16].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[16].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeMale[17].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeMale[0].TeeColourHex)">{{teeMale[17].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" class="sub-total">{{calculateSubtotal('Yards', true, 'Male')}}</td>
            <td class="total">{{calculateSubtotal('Yards', false, 'Male') + calculateSubtotal('Yards', true, 'Male')}}</td>
        </tr>
        <!-- TEE FEMALE COURSE DATA -->
        <tr class="sub-header top-separator" *ngIf="teeFemale.length > 0 && !equalTeeData">
            <td class="first-column t-right">Par</td>
            <td>{{teeFemale[0].Par}}</td>
            <td>{{teeFemale[1].Par}}</td>
            <td>{{teeFemale[2].Par}}</td>
            <td>{{teeFemale[3].Par}}</td>
            <td>{{teeFemale[4].Par}}</td>
            <td>{{teeFemale[5].Par}}</td>
            <td>{{teeFemale[6].Par}}</td>
            <td>{{teeFemale[7].Par}}</td>
            <td>{{teeFemale[8].Par}}</td>
            <td class="sub-total">{{calculateSubtotal('Par', false, 'Female')}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[9].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[10].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[11].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[12].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[13].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[14].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[15].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[16].Par}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[17].Par}}</td>
            <td *ngIf="numberOfHoles === 18" class="sub-total">{{calculateSubtotal('Par', true, 'Female')}}</td>
            <td class="total">{{calculateSubtotal('Par', false, 'Female') + calculateSubtotal('Par', true, 'Female')}}</td>
        </tr>
        <tr class="sub-header top-separator" *ngIf="teeFemale.length > 0 && !equalTeeData">
            <td class="first-column t-right">SI</td>
            <td>{{teeFemale[0].SI}}</td>
            <td>{{teeFemale[1].SI}}</td>
            <td>{{teeFemale[2].SI}}</td>
            <td>{{teeFemale[3].SI}}</td>
            <td>{{teeFemale[4].SI}}</td>
            <td>{{teeFemale[5].SI}}</td>
            <td>{{teeFemale[6].SI}}</td>
            <td>{{teeFemale[7].SI}}</td>
            <td>{{teeFemale[8].SI}}</td>
            <td class="sub-total"></td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[9].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[10].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[11].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[12].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[13].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[14].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[15].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[16].SI}}</td>
            <td *ngIf="numberOfHoles === 18">{{teeFemale[17].SI}}</td>
            <td *ngIf="numberOfHoles === 18" class="sub-total"></td>
            <td class="total"></td>
        </tr>
        <tr class="sub-header top-separator yard-row" *ngIf="teeFemale.length > 0">
            <td class="first-column t-right" [style.background-color]="'#' + teeFemale[0].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">Yds
            </td>
            <td [style.background-color]="'#' + teeFemale[0].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[0].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[1].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[1].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[2].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[2].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[3].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[3].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[4].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[4].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[5].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[5].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[6].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[6].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[7].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[7].Yards}}</td>
            <td [style.background-color]="'#' + teeFemale[8].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[8].Yards}}</td>
            <td class="sub-total">{{calculateSubtotal('Yards', false, 'Female')}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[9].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[9].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[10].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[10].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[11].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[11].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[12].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[12].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[13].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[13].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[14].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[14].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[15].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[15].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[16].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[16].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" [style.background-color]="'#' + teeFemale[17].TeeColourHex" [style.color]=" helpers.Utility.getTextColorLuma(teeFemale[0].TeeColourHex)">{{teeFemale[17].Yards}}</td>
            <td *ngIf="numberOfHoles === 18" class="sub-total">{{calculateSubtotal('Yards', true, 'Female')}}</td>
            <td class="total">{{calculateSubtotal('Yards', false, 'Female') + calculateSubtotal('Yards', true, 'Female')}}</td>
        </tr>
        <ng-container *ngFor="let player of players">
            <tr class="gross-score">
                <td class="player first-column">
                    <div style="display: flex; align-items: center;">
                        <p><i class="fa fa-chevron-circle-right" *ngIf="teamType < 60"></i>
                            <span *ngIf="teamType < 60">{{player.profile.firstName + ' ' + player.profile.surname}} ({{player.handicap}})</span>
                            <span *ngIf="teamType >= 60">{{player.profile.firstName}} ({{player.handicap}})</span>
                            <span class="spnTeeIcon" [style.background-color]="player.profile.gender === 1 ? '#' + teeMale[0].TeeColourHex : '#' + teeFemale[0].TeeColourHex"></span></p>
                    </div>
                </td>
                <td>
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="0" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[0].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(1, player.scores[0].scoreGross, player.scores[0].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[0].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[0].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="1" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[1].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(2, player.scores[1].scoreGross, player.scores[1].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[1].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[1].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="2" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[2].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(3, player.scores[2].scoreGross, player.scores[2].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[2].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[2].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="3" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[3].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(4, player.scores[3].scoreGross, player.scores[3].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[3].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[3].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="4" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[4].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(5, player.scores[4].scoreGross, player.scores[4].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[4].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[4].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="5" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[5].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(6, player.scores[5].scoreGross, player.scores[5].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[5].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[5].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="6" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[6].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(7, player.scores[6].scoreGross, player.scores[6].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[6].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[6].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="7" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[7].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(8, player.scores[7].scoreGross, player.scores[7].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[7].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[7].scorePoints}}</span>
                    </div>
                </td>
                <td><input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreOut" [attr.holeIndex]="8" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[8].scoreGross"
                        onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(9, player.scores[8].scoreGross, player.scores[8].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreOut" *ngIf="gameType !== 3">{{player.scores[8].scoreNet}}</span>
                        <span class="txtScoreOut" *ngIf="gameType === 3">{{player.scores[8].scorePoints}}</span>
                    </div>
                </td>
                <td class="total-column totalScoreOut">
                    <input type="text" [disabled]="true">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner totalNetOut">
                        <span class="no-calculate">0</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="9" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[9].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(10, player.scores[9].scoreGross, player.scores[9].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[9].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[9].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="10" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[10].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(11, player.scores[10].scoreGross, player.scores[10].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[10].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[10].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="11" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[11].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(12, player.scores[11].scoreGross, player.scores[11].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[11].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[11].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="12" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[12].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(13, player.scores[12].scoreGross, player.scores[12].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[12].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[12].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="13" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[13].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(14, player.scores[13].scoreGross, player.scores[13].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[13].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[13].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="14" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[14].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(15, player.scores[14].scoreGross, player.scores[14].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[14].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[14].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="15" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[15].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(16, player.scores[15].scoreGross, player.scores[15].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[15].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[15].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="16" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[16].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(17, player.scores[16].scoreGross, player.scores[16].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[16].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[16].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18">
                    <input type="text" [disabled]="!tournamentOpen || disabled" class="txtScore txtScoreIn" [attr.holeIndex]="17" [attr.playerID]="player.profile.profileID" [attr.playerGender]="player.profile.gender" [(ngModel)]="player.scores[17].scoreGross" onfocus="this.select();">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner {{getNetColor(18, player.scores[17].scoreGross, player.scores[17].scoreNet, player.profile.gender)}}">
                        <span class="txtScoreIn" *ngIf="gameType !== 3">{{player.scores[17].scoreNet}}</span>
                        <span class="txtScoreIn" *ngIf="gameType === 3">{{player.scores[17].scorePoints}}</span>
                    </div>
                </td>
                <td *ngIf="numberOfHoles === 18" class="total-column totalScoreIn">
                    <input type="text" [disabled]="true">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner totalNetIn">
                        <span class="no-calculate">0</span>
                    </div>
                </td>
                <td class="total-column totalScore">
                    <input type="text" [disabled]="true">
                    <div class="net-corner-shadow">&nbsp;</div>
                    <div class="net-corner totalNet">
                        <span class="no-calculate">0</span>
                    </div>
                </td>
            </tr>
            <tr class="separator" *ngIf="teamType >= 60">
                <td class="" colspan="22"></td>
            </tr>
            <tr class="profile-detail" *ngIf="teamType < 60">
                <td>
                    <div class="player-profile {{'player_' + player.profile.profileID}}">
                        <img class="profile-picture" src="{{getPlayerPicture(player.profile)}}" />
                        <div class="profile">
                            <p class="m-b-0">{{player.profile.firstName + ' ' + player.profile.surname}} ({{player.handicap}})</p>
                            <img *ngIf="player.profile.nationality != ''" class="smallFlag" src="{{environment.imagesCDNUrl}}/flags/{{getNationalityCode(player.profile.nationality)}}.svg">
                        </div>
                    </div>
                </td>
                <td colspan="21">
                    <!-- <div class="player-profile {{'player_' + player.profile.profileID}}">
                    <div class="averages m-l-0 ">
                        <div class="left icon ">
                            <i class="fa fa-trophy bg-green "></i>
                        </div>
                        <div class="right data ">
                            <p class="number ">{{player.profile.golferStats.bestRound}}</p>
                            <p class="text ">Best Round</p>
                        </div>
                    </div>
                    <div class="averages ">
                        <div class="left icon ">
                            <i class="fa fa-frown-o bg-red "></i>
                        </div>
                        <div class="right data ">
                            <p class="number ">{{player.profile.golferStats.worstRound}}</p>
                            <p class="text ">Worst Round</p>
                        </div>
                    </div>
                    <div class="averages ">
                        <div class="left icon ">
                            <i class="fa fa-line-chart bg-blue "></i>
                        </div>
                        <div class="right data ">
                            <p class="number ">{{player.profile.golferStats.averageRound}}</p>
                            <p class="text ">Avg. Score</p>
                        </div>
                    </div>
                </div> -->
                </td>
            </tr>
        </ng-container>
        <tr class="team-totals" *ngIf="teamTotals.length > 0">
            <td class="first-column">
                <p class="text-uppercase ">Team Total</p>
            </td>
            <td>{{teamTotals[0]}}</td>
            <td>{{teamTotals[1]}}</td>
            <td>{{teamTotals[2]}}</td>
            <td>{{teamTotals[3]}}</td>
            <td>{{teamTotals[4]}}</td>
            <td>{{teamTotals[5]}}</td>
            <td>{{teamTotals[6]}}</td>
            <td>{{teamTotals[7]}}</td>
            <td>{{teamTotals[8]}}</td>
            <td>{{calculateTeamSubtotal(false)}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[9]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[10]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[11]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[12]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[13]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[14]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[15]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[16]}}</td>
            <td *ngIf="numberOfHoles===18">{{teamTotals[17]}}</td>
            <td *ngIf="numberOfHoles===18">{{calculateTeamSubtotal(true)}}</td>
            <td>{{calculateTeamSubtotal(false) + calculateTeamSubtotal(true)}}</td>
        </tr>
    </table>
</div>
<div class="m-t-20" style="text-align: center;" *ngIf="!loading">
    <div class="m-l-10">
        <div class="legend pull-left">
            <div class="scoreAlbatross pull-left m-r-5">&nbsp;</div>
            <span>Albatross</span>
        </div>
        <div class="legend pull-left">
            <div class="scoreEagle pull-left m-r-5">&nbsp;</div>
            <span>Eagle</span>
        </div>
        <div class="legend pull-left">
            <div class="scoreBirdie pull-left m-r-5">&nbsp;</div>
            <span>Birdie</span>
        </div>
        <div class="legend pull-left">
            <div class="scorePar pull-left m-r-5">&nbsp;</div>
            <span>Par</span>
        </div>
        <div class="legend pull-left">
            <div class="scoreBogey pull-left m-r-5">&nbsp;</div>
            <span>Bogey</span>
        </div>
        <div class="legend pull-left">
            <div class="scoreDoubleBogey pull-left m-r-5">&nbsp;</div>
            <span>Double Bogey +</span>
        </div>
    </div>
    <button class="btn w-600 f-14 m-t-0 m-b-20 btn-scorecard" *ngIf="!disabled" [disabled]="!tournamentOpen" (click)="save()">Save Scorecard</button>
</div>
