<p-dialog [(visible)]="displayBulkUpload" [responsive]="false" header="Add Members" [modal]="true" [width]="650" [height]="500" [resizable]="true" [draggable]="false"
(visibleChange)="visibleChange($event)">
        <div class="ui-grid ui-grid-responsive ui-fluid">
            <p-dataTable [emptyMessage]="emptyMessage"  [value]="golfers" class="table table-dynamic"> 
                <p-column field="email" header="Email"></p-column>
                <p-column field="status" header="Status">
                    <ng-template  pTemplate="body" let-golfer="rowData">
                        <div class="badge"
                            [ngClass] ="[
                                golfer.status === 'No Profile' || golfer.status === 'Already Member' 
                                ? 'badge-info': '',
                                golfer.status === 'Error' ? 'badge-danger':'',
                                golfer.status === 'Added' ? 'badge-success':'',
                                golfer.status === 'Pending' ? 'badge-warning':''
                                ]"
                        >
                            {{golfer.status}}
                        </div>
                    </ng-template>
                </p-column>
            </p-dataTable>
        </div>
        
        <p-footer>
            <button type="submit" class="btn btn-dark m-b-5" label="Confirm"
            (click)="addMembersBulk()" [disabled]="disableConfirm">
                <i class="fa fa-check"></i>Confirm
            </button>
        </p-footer>

</p-dialog>