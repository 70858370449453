/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./poolplayer.renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "primeng/components/dragdrop/dragdrop";
import * as i4 from "@angular/common";
import * as i5 from "./poolplayer.renderer.component";
import * as i6 from "../../../services/FlightMemberService";
import * as i7 from "../../../services/FlightMembersService";
var styles_PoolplayerRenderer = [i0.styles];
var RenderType_PoolplayerRenderer = i1.ɵcrt({ encapsulation: 0, styles: styles_PoolplayerRenderer, data: {} });
export { RenderType_PoolplayerRenderer as RenderType_PoolplayerRenderer };
function View_PoolplayerRenderer_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge label-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.profile.vparHandicap ? _co.profile.vparHandicap : _co.profile.handicap); _ck(_v, 1, 0, currVal_0); }); }
function View_PoolplayerRenderer_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.profile.vparHandicap ? _co.profile.vparHandicap : _co.profile.handicap); _ck(_v, 1, 0, currVal_0); }); }
export function View_PoolplayerRenderer_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "dd-item dd3-item"], ["pDraggable", "dd"], ["pDroppable", "dd"]], [[8, "hidden", 0], [8, "draggable", 0]], [[null, "onDrop"], [null, "onDragStart"], [null, "onDragEnd"], [null, "dragstart"], [null, "dragend"], [null, "drop"], [null, "dragenter"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dragStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).dragEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("drop" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).drop($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).dragEnter($event) !== false);
        ad = (pd_3 && ad);
    } if (("dragleave" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).dragLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("onDrop" === en)) {
        var pd_5 = (_co.dropOnExistingPlayer($event) !== false);
        ad = (pd_5 && ad);
    } if (("onDragStart" === en)) {
        var pd_6 = (_co.dragStart($event, _co.profile) !== false);
        ad = (pd_6 && ad);
    } if (("onDragEnd" === en)) {
        var pd_7 = (_co.dragEnd($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i3.Draggable, [i1.ElementRef, i2.DomHandler, i1.NgZone], { scope: [0, "scope"] }, { onDragStart: "onDragStart", onDragEnd: "onDragEnd" }), i1.ɵdid(3, 4341760, null, 0, i3.Droppable, [i1.ElementRef, i2.DomHandler, i1.NgZone], { scope: [0, "scope"] }, { onDrop: "onDrop" }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "dd-handle dd3-handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "container dd3-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "cell2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "wrap playerName"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "cell4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PoolplayerRenderer_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PoolplayerRenderer_2)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "dd"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "dd"; _ck(_v, 3, 0, currVal_3); var currVal_6 = _co.profile.vparMember; _ck(_v, 11, 0, currVal_6); var currVal_7 = (_co.profile.vparMember != true); _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.visible; var currVal_1 = true; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.profile.firstName; var currVal_5 = _co.profile.surname; _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_PoolplayerRenderer_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "player-pool-renderer", [], null, null, null, View_PoolplayerRenderer_0, RenderType_PoolplayerRenderer)), i1.ɵdid(1, 245760, null, 0, i5.PoolplayerRenderer, [i6.FlightMemberService, i7.FlightMembersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PoolplayerRendererNgFactory = i1.ɵccf("player-pool-renderer", i5.PoolplayerRenderer, View_PoolplayerRenderer_Host_0, { profile: "profile" }, { dragPlayerEnd: "dragPlayerEnd" }, []);
export { PoolplayerRendererNgFactory as PoolplayerRendererNgFactory };
