import { catchError, share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { TeamType } from "../models";
import { Observable, Subject } from "rxjs";

import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class TeamTypesService {
  teamTypes$: Observable<TeamType[]>;
  private _teamTypesObserver = new Subject<TeamType[]>();

  private _dataStore: {
    teamTypes: TeamType[];
  };

  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this._dataStore = { teamTypes: [] };
    this.teamTypes$ = this._teamTypesObserver.asObservable().pipe(share());
    this.headers.append("Content-Type", "application/json; charset=utf-8");
  }

  loadAll(gameTypeId): void {
    this.http
      .get<TeamType[]>(
        gameTypeId == 2
          ? "/assets/json/matchplay-team-types.json"
          : "/assets/json/team-types.json"
      )
      .pipe(
        catchError((error) => {
          console.log("Could not load team types.", error);
          return [];
        })
      )
      .subscribe((data: TeamType[]) => {
        this._dataStore.teamTypes = data;
        this._teamTypesObserver.next(this._dataStore.teamTypes);
      });
  }
}
