export class PostScoresModel {
  roundId: number;
  flights: FlightForPostScores[];
}

export class FlightForPostScores {
  flightID: number;
  flightMembers: FlightMemberForPostScores[];
}

export class FlightMemberForPostScores {
  ball: number;
  flightID: number;
  flightMemberID: number;
  handicap: number;
  teamID: number;
  scores: ScoreForPostScoreCard[];
}

export class ScoreForPostScoreCard {
  holeID: number;
  holeNumber: number;
  gross: string;
  grossVpar: number;
  net: number;
  netVpar: number;
  accuracy: number;
  points: number;
  club: number;
  putts: number;
  scoreType: number;
  sandSaveType: number;
  si: number;
  par: number;
  shots: number;
  adjustedGross: number;
  scoreGrossPointsVPAR: number;
  grossPoints: number;
  grossWithBlob: number;  
  netVPAR: number;
  scorePointsVPAR: number;
  scoreMatchPlay: number;
}
