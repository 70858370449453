import { share, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Course, CourseForScorecard } from "../models";
import { Observable, Subject } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";
import { LoginService } from "./login.service";

@Injectable()
export class CourseService {
  course$: Observable<Course[]>;
  private _coursesObserver = new Subject<Course[]>();

  private _dataStore: {
    courses: Course[];
  };

  constructor(
    public httpService: HttpServices,
    private loginService: LoginService
  ) {
    this._dataStore = { courses: [] };
    this.course$ = this._coursesObserver.asObservable().pipe(share());
  }

  load(courseID: number): void {
    var url = `${environment.apiUrl}/api/Courses/${courseID}`;
    this.httpService.get(url).subscribe(
      (data: Course) => {
        let notFound = true;
        this._dataStore.courses.forEach((item, index) => {
          if (item.courseID === data.courseID) {
            this._dataStore.courses[index] = data;
            notFound = false;
          }
        });

        if (notFound) {
          this._dataStore.courses.push(data);
        }

        this._coursesObserver.next(this._dataStore.courses);
      },
      (error) => console.log("Could not loadcourse")
    );
  }
  loadAll(venueID: number): void {
    var url = `${environment.apiUrl}/api/Venues/${venueID}/Courses`;
    this.httpService.get(url).subscribe(
      (data: Array<Course>) => {
        this._dataStore.courses = data;
        this._coursesObserver.next(this._dataStore.courses);
      },
      (error) => console.log("Could not load courses.")
    );
  }

  getCourseHoles(courseID: number): Observable<CourseForScorecard> {
    let url = `${environment.apiUrl}/api/Courses/${courseID}/scorecard`;
    return this.httpService.getTyped<CourseForScorecard>(url).pipe(share());
  }

  sendMissingTeeDataSlackMessage(courseID: number) {
    let url = `${environment.venuesApiUrl}/api/5.0/courses/incorrect-tee-message/${courseID}`;

    return this.loginService.loggedInDetail$.pipe(
      switchMap(token => {
        if (token !== "" && token !== undefined && token !== null) {
          return this.httpService.post(url, null, [
            { name: "APIToken", value: token.AuthToken },
          ]);
        } else {
          return []; // or any default value
        }
      })
    );
  }
}
