
import {map, takeUntil} from 'rxjs/operators';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { UploadService } from '../../../services';
import { Profile } from '../../../models';
import { Subject } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'profile-picture-upload',
    templateUrl: 'profile-picture-upload.component.html',
    styleUrls: ['profile-picture-upload.component.scss']
})

export class ProfilePictureUpload implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form;
    @Input() profileID: string;
    @Input() disabled: boolean = false;
    @Output() fileUploaded: EventEmitter<Profile> = new EventEmitter();
    uploadInProgress: boolean;
    fileAdded: boolean;
    public type: any;
    public files: any;
    public $stop: Subject<boolean> = new Subject<any>();

    constructor(private uploadService: UploadService, private toastr: ToastrService) {
    }
    ngOnInit() {
        this.uploadService.progress$.pipe(takeUntil(this.$stop)).subscribe(
            data => {
                console.log('progress = ' + data);
            });
    }

    onChangeUpload(event) {
        this.files = event.target.files;
        if (this.profileID) {
            this.upload(this.profileID);
        } else {
            this.fileAdded = true;
        }
    }

    upload(profileID: string) {
        this.uploadInProgress = true;
        var fileUploadUrl = `${environment.apiUrl}/api/Profiles/${profileID}/Picture`;
        this.toastr.info('Uploading Profile Image. This may take a minute or so');
        if (this.files){
            this.uploadService.addMedia(fileUploadUrl, this.files).pipe(
                map(res => JSON.parse(res)))
                .subscribe((response: Profile) => {
                    this.form.nativeElement.reset();
                    this.toastr.success('Your Profile Image has been uploaded');
                    this.fileUploaded.next(response);
                    this.uploadInProgress = false;
                    this.files = null;
                }, err => {
                    this.toastr.error('Failed to upload Profile Image');
                    this.files = null;
                }, () => {
                });
        }
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.$stop.next(true);
    }
}
