/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./PrintOption";
var styles_PrintOption = [".printOption[_ngcontent-%COMP%] {\n    display: inline-block;\n    border: 2px solid #556973;\n    margin-right: 10px;\n    font-weight:bold;\n    width: 150px;\n    height: 30px;\n    padding-top: 3px;\n}\n.filled[_ngcontent-%COMP%] {\n    background:#556973;\n    color:#FFFFFF;\n}\n.disabled[_ngcontent-%COMP%] {\n    color:#f2f3f4;\nborder-color:#f2f3f4;\n}"];
var RenderType_PrintOption = i0.ɵcrt({ encapsulation: 0, styles: styles_PrintOption, data: {} });
export { RenderType_PrintOption as RenderType_PrintOption };
export function View_PrintOption_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "printOption text-center"]], [[2, "filled", null], [2, "disabled", null], [2, "cursor-pointer", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detail.selected; var currVal_1 = !_co.detail.available; var currVal_2 = _co.detail.available; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.detail.label; _ck(_v, 1, 0, currVal_3); }); }
export function View_PrintOption_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "PrintOption", [], null, null, null, View_PrintOption_0, RenderType_PrintOption)), i0.ɵdid(1, 49152, null, 0, i1.PrintOption, [], null, null)], null, null); }
var PrintOptionNgFactory = i0.ɵccf("PrintOption", i1.PrintOption, View_PrintOption_Host_0, { detail: "detail" }, { detailUpdated: "detailUpdated" }, []);
export { PrintOptionNgFactory as PrintOptionNgFactory };
