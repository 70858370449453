import { Component, OnInit, ViewChild } from '@angular/core';
import { FlightMember, Round, CourseForScorecard } from '../../../models';
import { CompetitionRouteService, FlightMembersService } from '../../../services';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialog } from '../../../shared/components/confirm-dialog/index';
import { ScoreTabComponent } from '../../../shared/components/scorecard/index';
import { takeUntil } from 'rxjs/operators';


@Component({
    templateUrl: 'individual-scorecard.component.html'
})

export class IndividualScorecardComponent extends ScoreTabComponent implements OnInit {

    public roundID: string;
    public competitionID: string;
    public players: Array<FlightMember>;
    public tournamentOpen = true;

    courseHoles: CourseForScorecard;
    round: Round;

    $stop: Subject<boolean> = new Subject<any>();

    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;
    scorecardsEditingStatus = [];
    subject: Subject<boolean>;

    constructor(private competitionRouteService: CompetitionRouteService,
        public flightMembersService: FlightMembersService,
        private route: ActivatedRoute) {
            super();
    }

    ngOnInit() {

        this.courseHoles = this.route.parent.snapshot.data['course'];
        this.round = this.route.parent.parent.snapshot.data['round'];

        this.competitionRouteService.competitionID$.subscribe(competitionID => {
            this.competitionID = competitionID;
        });

        this.competitionRouteService.roundID$.subscribe(roundID => {
            this.roundID = roundID;
        });

        this.competitionRouteService.currentCompetition$.subscribe(comp => {
            if (comp) {
                this.tournamentOpen = (comp.status != 3);
            }
        });

        this.flightMembersService.loadAll(this.competitionID, this.roundID);

        this.flightMembersService.flightMembers$.pipe(takeUntil(this.$stop)).subscribe((data: Array<FlightMember>) => {
            // initial status. All scorecards are not being edited
            data.forEach(() => {
                this.scorecardsEditingStatus.push(false);
            });
            this.players = data.sort((n1, n2) => {
                return n1.profile.displayName > n2.profile.displayName ? 1 : -1
            });            
        }, error => console.log(`Failed - Flight member service issue.${error}`), () => console.log(`Flight member service complete`));
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
}
