import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Round, FlightMember, Competition } from '../../../models';
import { FlightMembersService, LoginService, CompetitionDataService } from '../../../services';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialog } from '../../../shared/index';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { HttpServices } from 'src/app/services/HttpServices';

@Component({
    selector: 'round-overview-data',
    templateUrl: 'round-overview-data.component.html',
    styleUrls: ['./round-overview-data.component.scss']
})

export class RoundOverviewDataComponent implements OnInit, OnDestroy {

    @Input() tournament: Competition;
    @Input() round: Round;

    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;

    $stop: Subject<boolean> = new Subject<any>();
    registrationPercentage: number = 0;
    totalPlayers: number = 0;
    playersArrived: number = 0;

    vparMembers = 0;
    playersCheckedIn = 0;

    TVArrivalsUrl: string;
    TVLeaderboardUrl: string;
    offering : number;

    tooltipText = `<p class="tooltip-title">Checked In Golfers</p>
    <p>Players who have checked-in to the tournament using the VPAR app.</p>
    <p>i.e. Via a tournament code or joined from the play tab.</p>`;

    isTournamentOpen: boolean;
    isMultiRound = false;
    loggedId = true;

    constructor(
        private flightMembersService: FlightMembersService,
        private router: Router,
        private loginService: LoginService,
        private competitionDataService: CompetitionDataService,
        private httpService : HttpServices,
        private activatedRoute: ActivatedRoute) {
        this.loggedId = this.loginService.isLoggedIn();
        this.offering = this.activatedRoute.parent.snapshot.data['branding'].offeringType;
    }

    ngOnInit() {
        this.getTournamentDetail();

        var arrivalsUrl = `${environment.apiUrl}/api/LeaderBoard/TVArrivalsUrl/${this.tournament.competitionID}/round/${this.round.roundID}`
        this.httpService.get(arrivalsUrl).subscribe((data: string) => {
            this.TVArrivalsUrl = data;
        }, error => console.log('Could not load TV LeaderBoard Url.'));

      var tvLeaderboardUrl = `${environment.apiUrl}/api/LeaderBoard/TVLeaderboardUrl/${this.tournament.competitionID}/round/${this.round.roundID}`
        this.httpService.get(tvLeaderboardUrl).subscribe((data: string) => {
            this.TVLeaderboardUrl = data;
        }, error => console.log('Could not load TV LeaderBoard Url.'));

        this.flightMembersService.flightMembers$.pipe(takeUntil(this.$stop)).subscribe((data: Array<FlightMember>) => {
            this.registrationPercentage = this.CalculateArrival(data);
        }, error => console.log(`Failed - Flight member service issue.${error}`), () => console.log(`Flight member service complete`));
        if(this.isPublic()){
          document.getElementById("checkedIn").style.display = "none";
          document.getElementById("lastSection").style.display = "none";
          document.getElementById("arrived").style.height = "100px";
        }
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
    isPublic(): boolean{
      return this.router.url.includes("public");
    }
    getTournamentDetail() {

        this.competitionDataService.getTournament(this.tournament.competitionID.toString()).subscribe((tournament: Competition) => {
            this.tournament = tournament;
            this.isTournamentOpen = (this.tournament.status == 1 || this.tournament.status == 2);
            this.isMultiRound = this.tournament.rounds.length > 1;
        });
    }

    onStatusChange(e) {
        var self = this;
        var callBack = () => {
            self.setTournamentStatus(self.tournament.competitionID, e.target.checked);
        }
        var cancelCallback = () => {
            e.target.checked = true;
            setTimeout(() => {
                self.isTournamentOpen = true;
            }, 0);
        }

        if (!e.target.checked) {
            self.confirmDialog.showDualDialog(callBack, cancelCallback);
        } else {
            self.setTournamentStatus(self.tournament.competitionID, e.target.checked);
        }
    }

    //Refactor to service
    setTournamentStatus(tournamentId: number, tournamentStatus: boolean) {
      let url = `${environment.apiUrl}/api/Competitions/${tournamentId}/TournamentStatus/${tournamentStatus}`;
        this.httpService.patch(url, "N/A")
            .subscribe(data => {
            },
                err => console.log(err),
                () => console.log('Tournament Status Updated')
            );
    }

    buildRoundStartDate(date: any): any {
        return moment(date).format('Do MMMM, YYYY')
    }

    updateArrivalStatus(flightMemberId: number, isCheckedIn: boolean) {
        var arr = this.round.flightMembers;
        var index = _.indexOf(arr, _.find(arr, { flightMemberID: flightMemberId }));
        var updated = this.round.flightMembers[index];
        updated.isCheckedIn = isCheckedIn;
        arr.splice(index, 1, updated);
        this.round.flightMembers = arr;
    }

    CalculateArrival(flightMembers: Array<FlightMember>): number {
        this.totalPlayers = flightMembers.length;
        if (this.totalPlayers == 0) {
            return 0;
        }

        this.playersArrived = flightMembers.filter(x => x.isCheckedIn).length;
        this.vparMembers = flightMembers.filter(x => x.profile.vparMember).length;
        this.playersCheckedIn = flightMembers.filter(x => x.profile.competitionProfileDetails
            && x.profile.competitionProfileDetails.length > 0
            && x.profile.competitionProfileDetails[0].status === 1).length;

        return Math.floor((this.playersArrived / this.totalPlayers) * 100);
    }

    goToArrivalScreen() {
        window.open(window.location.origin + '/public/tournaments/' + this.tournament.competitionID + '/round/' + this.round.roundID + '/arrivals', '_blank');
    }

    editLeaderboard() {
        var redirectUrl = `/tournaments/${this.tournament.competitionID}/round/${this.round.roundID}/createleaderboard`;
        this.router.navigateByUrl(redirectUrl);
    }

    goToLeaderboard() {
        // window.open(this.TVLeaderboardUrl, '_blank');
        // New TV LB
        window.open(window.location.origin + '/public/tournaments/' + this.tournament.competitionID + '/round/' + this.round.roundID + '/tvleaderboard', '_blank');
    }
}
