import { NgModule } from '@angular/core';
import { TeamRendererComponent } from './team.renderer.component';
import { TournamentOverviewTeamsComponent } from './teams.component';
import { BrowserModule } from '@angular/platform-browser';
import { TeamsService } from "../../../services";
import { AppSharedModule } from '../../../shared/app.shared.module';
@NgModule({
    imports: [BrowserModule, AppSharedModule],    // module dependencies
    declarations: [TournamentOverviewTeamsComponent, TeamRendererComponent],   // components and directives
    exports: [TournamentOverviewTeamsComponent],     // root component
    providers: [TeamsService]
})
export class TeamScorecardModule { }
