<div class="row">
    <div class="col-md-12" style="width: 100%;">
        <div class="panel panel-default" id="panel-default">
            <div class="row panel-body p-10 p-l-20 p-r-20">
                <div class="clearfix col-sm-7 col-xs-12">
                    <h2 class="c-dark w-700 m-t-10 m-b-10 title">{{tournament?.title}}</h2>
                     <p class="c-gray m-b-5 f-15">
                        <i class="fa fa-calendar-o p-r-10"></i>
                        <span>{{buildTournamentDate(tournament.rounds)}}</span>&nbsp;&nbsp;<span *ngIf="offeringLevel != 1 && displayTournamentCode"><span *toggleFeature="'entryCode';offeringLevel:offeringLevel">|&nbsp;<i class="vpar-icon vpar-key p-l-10 p-r-10"></i>Tournament Code:<span class="tournament-code w-700 m-l-10">{{tournament?.accessCode}}</span></span></span>
                    </p>
                </div>
                <div class="clearfix col-sm-5 col-xs-12 m-t-15 tournament-buttons" id="tournament-btn">
                    <a class="next-btn sf-right sf-btn sf-btn-next" [routerLink]="[ '/tournaments',tournament.competitionID, 'edit']" *onlyLoggedIn>
                        <button class="btn btn-light pull-right w-600 f-14 m-t-0 m-b-0 top-button-tournament" type="button">Edit Tournament</button>
                    </a>
                    <a class="next-btn sf-right sf-btn sf-btn-next" [routerLink]="scoreCentreNavigation" target="_blank" *onlyLoggedIn>
                        <button class="btn btn-principal pull-right w-600 f-14 m-t-0 m-b-0 top-button-tournament" type="button">{{'TOURNAMENT.OVERVIEW.SHARE_SCORECENTRE' | translate}}</button>
                    </a>
                </div>
            </div>
            <!-- Menu -->
            <div class="panel-footer p-t-0 p-b-0 overview-menu ">
                <vpar-tabMenu *ngIf="tournament.rounds.length > 1" 
                    [model]="tabItems" 
                    styleClass="my-menu" 
                    [activeItem]="activeItem" 
                    [initialIndex]="activeRoundIdex"></vpar-tabMenu> 
      
                <!-- Menu for rounds only -->
                <div *ngIf="activeItemIndex > 0" id="tab-menu-section">
                    <round-overview-menu [roundId]="tournament.rounds[(activeItemIndex - 1)].roundID" [offeringLevel]="offeringLevel"></round-overview-menu>
                </div>
            </div>
        </div>

        <!-- Data for overview/rounds-->
        <div class="panel panel-default no-background" id="middle-section" *ngIf="showDetails">
            <div class="panel-body p-0">
                <div *ngIf="activeItemIndex === 0">
                    <tournament-overview-data [tournament]="tournament"></tournament-overview-data>
                </div>
                <div *ngIf="activeItemIndex > 0">
                    <round-overview-data [tournament]="tournament" [round]="tournament.rounds[(activeItemIndex - 1)]"></round-overview-data>
                </div>
            </div>
        </div>
    </div>
</div>
