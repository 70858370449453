<div class="row" style="margin-top:50px; " id="table-section">
  <div class="col-lg-12">
      <div class="panel" style="margin-top:20px; border-radius:05px;">
          <div class="panel-content admin">
            <div style="margin-top:20px;" *ngIf="settings">
              <div class="row m-b-10">
                <div class="header-group border-bottom" style="margin:5px 15px 20px 55px">
                    <div class="text-capitalize headerText">Default <span class="bold">SETTINGS</span> </div>
                </div>
            </div>
              <div class="row m-l-40" style="min-height: 50px;">
                  <label for="toggle1" data-toggle="tooltip" title="Tooltip 1">Course Handicap Type :</label>
                  <select class="form-select custom-select" [(ngModel)]="settings.handicapType">
                    <option value="1">R&A</option>
                    <option value="2">USGA</option>
                  </select>
                  <p>"Choose the option you prefer as your default setting for the course and game index calculator."</p>
              </div>
              <div class="row m-l-40" style="min-height: 50px;">
                  <label for="toggle2" data-toggle="tooltip" title="Tooltip 2">VPAR Handicap :</label>
                  <input type="checkbox" [checked]="settings.vparHandicap"
                    (change)="settings.vparHandicap = !settings.vparHandicap">
                    <p>"Untick this box if you wish to exclude an event from the VPAR handicap calculation by default. This setting will be remembered when creating a tournament."</p>
              </div>
              <div class="row m-l-40"style="min-height: 50px;">
                  <label for="dropdown" data-toggle="tooltip" title="Tooltip 3">On the day Handicap Adjustments:</label>
                  <input type="checkbox" [checked]="settings.hcpAdjustments"
                    (change)="settings.hcpAdjustments = !settings.hcpAdjustments">
                    <p>"Untick this box if you prefer to use the golfer's most recent handicap index for calculating the course and game handicap on the day of the event."</p>
              </div>
              <div class="row m-l-40" style="margin-bottom:20px; margin-top:20px;">
                <div class="col">
                  <a class="btn-save" (click)="update()">Save</a>
                </div>
              </div>
          </div>


           </div>
       </div>
  </div>
</div>
