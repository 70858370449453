import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { Round } from '../../../models';
import { Subject } from 'rxjs';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: 'scores.component.html',
    styleUrls: ['scores.component.scss']
})

export class ScoresComponent implements OnInit {
    public teamDisplay: boolean = true;
    public items: MenuItem[];
    public teeTimes: boolean;
    public activeItem: MenuItem;
    $stop: Subject<boolean> = new Subject<any>();

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {

        this.items = [];

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var round = <Round>data['round'];
            this.items = [];
            this.items.push({
                label: 'Tee Times', command: (event) => {
                    this.goToTeeTimes();
                }, visible: true,
                routerLink: 'teetimes'
            });
            if (DomainHelpers.RoundHelper.haveIndividualScores(round)) {
                this.items.push({
                    label: 'Individual', command: (event) => {
                        this.goToIndividual();
                    }, visible: true, routerLink:'./individual'
                });
            }
            if (DomainHelpers.RoundHelper.getTeamDisplay(round)) {
                this.items.push({
                    label: 'Teams', command: (event) => {
                        this.goToTeams();
                    }, visible: true,
                    routerLink:'./teams'
                });
            }
        });

        this.router.events.subscribe((val) => {
            this.setActiveItem();
        });

        this.setActiveItem();
      }

    setActiveItem() {
        if (this.items.length > 0) {

            const url = this.router.url;
            if (url.indexOf('/scores') > 0) {
                const item = url.split('/scores')[1].split('/')[1];
                switch (item) {
                    case undefined:
                    case 'teetimes':
                        this.activeItem = this.items[0];
                        break;
                    case 'individual':
                        this.activeItem = this.items[1];
                        break;
                    case 'teams':
                        this.activeItem = this.items[this.items.length - 1];
                        break;
                }
            }
        }
    }


    goToTeams() {
        this.router.navigate(["./teams"], { relativeTo: this.route });
    }

    goToTeeTimes() {
        this.router.navigate(["./teetimes"], { relativeTo: this.route });
    }

    goToIndividual() {
        this.router.navigate(["./individual"], { relativeTo: this.route });
    }

    ngOnDestroy() {
        //Cleanup using takeUntil
        this.$stop.next(true);
    }
}
