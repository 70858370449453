import { NgModule } from '@angular/core';
import { PrintType } from './PrintType';
import { PrintFormat } from './PrintFormat';
import { PrintOption } from './PrintOption';
import { PrintingComponent } from './printing.component';
import { PrintingComponent2 } from './v2/Print2.component';
import { BrowserModule } from '@angular/platform-browser';
import { DropdownModule } from 'primeng/primeng';
import { FormsModule } from '@angular/forms';
import { PrintingService, TeamsService, FlightService, FlightMembersService, MediaService } from '../../../services';
import { AppSharedModule } from '../../../shared/app.shared.module';
@NgModule({
    imports: [BrowserModule, DropdownModule, FormsModule, AppSharedModule],    // module dependencies
    declarations: [PrintingComponent, PrintType, PrintOption, PrintFormat, PrintingComponent2],   // components and directives
    exports: [PrintingComponent],     // root component
    providers: [PrintingService, FlightMembersService, TeamsService, FlightService, MediaService]
})
export class PrintingModule { }
