/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./teams.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./team.renderer.component.ngfactory";
import * as i3 from "./team.renderer.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/components/confirm-dialog/confirm-dialog.component.ngfactory";
import * as i6 from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import * as i7 from "./teams.component";
import * as i8 from "../../../services/CompetitionRouteService";
import * as i9 from "../../../services/FlightMembersService";
import * as i10 from "../../../services/TeamsService";
import * as i11 from "@angular/router";
var styles_TournamentOverviewTeamsComponent = [i0.styles];
var RenderType_TournamentOverviewTeamsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TournamentOverviewTeamsComponent, data: {} });
export { RenderType_TournamentOverviewTeamsComponent as RenderType_TournamentOverviewTeamsComponent };
function View_TournamentOverviewTeamsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "team-renderer", [], null, [[null, "onEditingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEditingChange" === en)) {
        var pd_0 = (_co.editScorecardChanged(_v.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TeamRendererComponent_0, i2.RenderType_TeamRendererComponent)), i1.ɵdid(1, 49152, null, 0, i3.TeamRendererComponent, [i1.ElementRef], { team: [0, "team"], roundID: [1, "roundID"], teamType: [2, "teamType"], femaleTeeColor: [3, "femaleTeeColor"], maleTeeColor: [4, "maleTeeColor"], courseData: [5, "courseData"], tournamentOpen: [6, "tournamentOpen"] }, { onEditingChange: "onEditingChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.roundID; var currVal_2 = _co.teamType; var currVal_3 = _co.round.femaleHoleColourID; var currVal_4 = _co.round.maleHoleColourID; var currVal_5 = _co.courseHoles; var currVal_6 = _co.tournamentOpen; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_TournamentOverviewTeamsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { confirmDialog: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TournamentOverviewTeamsComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "confirm-dialog", [["message", "You have pending changes, if you continue changes made will be lost"], ["titleText", "Scorecard"]], null, null, null, i5.View_ConfirmDialog_0, i5.RenderType_ConfirmDialog)), i1.ɵdid(4, 49152, [[1, 4]], 0, i6.ConfirmDialog, [], { titleText: [0, "titleText"], message: [1, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teams; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Scorecard"; var currVal_2 = "You have pending changes, if you continue changes made will be lost"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_TournamentOverviewTeamsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TournamentOverviewTeamsComponent_0, RenderType_TournamentOverviewTeamsComponent)), i1.ɵdid(1, 114688, null, 0, i7.TournamentOverviewTeamsComponent, [i8.CompetitionRouteService, i9.FlightMembersService, i10.TeamsService, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TournamentOverviewTeamsComponentNgFactory = i1.ɵccf("ng-component", i7.TournamentOverviewTeamsComponent, View_TournamentOverviewTeamsComponent_Host_0, {}, {}, []);
export { TournamentOverviewTeamsComponentNgFactory as TournamentOverviewTeamsComponentNgFactory };
