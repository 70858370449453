import { Component, OnInit, OnDestroy } from '@angular/core';
import { Flight, Team, Round, Hole, FlightMember, FlightTeamGroup, Competition } from '../../../models';
import { FlightService, FlightMembersService, CourseService, TeamsService } from "../../../services";
import { combineLatest, Observable ,  Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { takeUntil } from 'rxjs/operators';


export enum TeamGroupType {
    Individual = 1,
    Pairs = 2,
    Single = 3,
    Flight = 4
}
@Component({
    selector: 'tee-times',
    templateUrl: 'tournament-overview-teetimes.component.html',
    styleUrls: ['./tournament-overview-teetimes.component.scss']
})
export class TournamentOverviewTeeTimesComponent implements OnInit, OnDestroy {
    flights: Flight[] = new Array<Flight>();
    public roundID: string;
    public competitionID: string;
    public editable: boolean = false;
    public $stop: Subject<boolean> = new Subject<any>();
    public holes: SelectItem[] = [];
    public teams: Team[] = [];
    public currentRound: Round;
    public flightType: number = 4;
    public gameType: number;
    public teamType: number;
    public teamColoursSelect: SelectItem[] = [];
    isSharedBall: boolean = false;
    decimalScoring: boolean = false;
    constructor(private route: ActivatedRoute, public flightMembersService: FlightMembersService, private teamsService: TeamsService, public flightService: FlightService, public courseService: CourseService
    ) {
        this.teamColoursSelect = DomainHelpers.SelectLists.getTeamColours();
    }
    private FlightMemberGroupTeamMap(flightTeamGroup: FlightTeamGroup, team: Team): FlightTeamGroup {
        if (team != null && (!flightTeamGroup.team || flightTeamGroup.team.teamID == 0)) {
            flightTeamGroup.title = team.title;
            flightTeamGroup.colour = team.colour;
            flightTeamGroup.colourText = team.colourText;
            flightTeamGroup.teamID = team.teamID;
            flightTeamGroup.team = _.clone(team);
        }
        return flightTeamGroup;
    }

    ngOnInit() {
        var self = this;
        this.route.parent.parent.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var tournament = <Competition>data['tournament'];
            if (tournament) {
                this.decimalScoring = tournament.useDecimalScoring || false;
            }
        });
        combineLatest(self.flightMembersService.flightMembers$, self.flightService.flights$, self.teamsService.teams$,
            function (flightMembers: Array<FlightMember>, flights: Array<Flight>, teams: Array<Team>) {
                let teamGroupType = TeamGroupType.Individual;
                let teamType = self.teamType;
                var upperBoundBall = self.flightType + 1;
                var isMatchplay = self.gameType === 2;
                if (teamType == 40 || teamType == 50 || teamType == 80) {
                    teamGroupType = TeamGroupType.Single;
                }
                if (self.currentRound.pairsFormat) {
                    teamGroupType = TeamGroupType.Pairs;
                }
                if (teamType == 80) {
                    teamGroupType = TeamGroupType.Flight;
                }
                flights.forEach(function (flight) {
                    flight.flightTeamGroups = [];
                    let members = _.sortBy(_.filter(flightMembers, { 'flightID': flight.flightID }), 'ball');
                    flight.flightMembers = members;
                    if (teamGroupType === TeamGroupType.Individual) {
                        _.range(1, upperBoundBall).forEach(ball => {
                            let flightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                            flightTeamGroup.displayHeader = isMatchplay || teamType == 20;
                            if (isMatchplay) {
                                // flightTeamGroup.allowReassign = false;
                                flightTeamGroup.showShots = true;

                                var team = null;
                                if (_.includes([1, 3], ball)) {
                                    let flightMemberTeam = _.first(flightMembers.filter(a => a.ball == 1 || a.ball == 3));
                                    if (flightMemberTeam !== undefined) {
                                        team = <Team>_.find(teams, { 'teamID': +flightMemberTeam.teamID });
                                    }
                                }
                                if (_.includes([2, 4], ball)) {
                                    let flightMemberTeam = _.first(flightMembers.filter(a => a.ball == 2 || a.ball == 4));
                                    if (flightMemberTeam !== undefined) {
                                        team = <Team>_.find(teams, { 'teamID': +flightMemberTeam.teamID });
                                    }
                                }
                                if (team) {
                                    self.FlightMemberGroupTeamMap(flightTeamGroup, team);
                                }
                            }

                            let flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                flightTeamGroup = self.FlightMemberGroupTeamMap(flightTeamGroup, team);
                            } else {
                                flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                            }
                            flightTeamGroup.flightMembers.push(flightMember);
                            flight.flightTeamGroups.push(flightTeamGroup);
                        });
                    }
                    if (teamGroupType === TeamGroupType.Single) {
                        let individualFlightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        individualFlightTeamGroup.displayHeader = true;
                        //Can't occur matchplay is an individual or pairs format
                        _.range(1, upperBoundBall).forEach(ball => {
                            var flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                individualFlightTeamGroup = self.FlightMemberGroupTeamMap(individualFlightTeamGroup, team);
                            } else {
                                flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                            }
                            individualFlightTeamGroup.flightMembers.push(flightMember);
                        });
                        flight.flightTeamGroups.push(individualFlightTeamGroup);
                    }

                    if (teamGroupType === TeamGroupType.Flight) {
                        let flightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        flightTeamGroup.displayHeader = true;
                        flightTeamGroup.mergedCells = self.isSharedBall;
                        _.range(1, upperBoundBall).forEach(ball => {
                            var flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                flightTeamGroup = self.FlightMemberGroupTeamMap(flightTeamGroup, team);
                                flightTeamGroup.mergedValue = flightMember.handicap;
                            } else {
                                flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                            }
                            flightTeamGroup.flightMembers.push(flightMember);
                        });
                        flight.flightTeamGroups.push(flightTeamGroup);
                    }
                    if (teamGroupType === TeamGroupType.Pairs) {
                        //For matchplay we need to assign team titles. This will require all flightMembers
                        let firstFlightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        firstFlightTeamGroup.displayHeader = true;
                        firstFlightTeamGroup.mergedCells = self.isSharedBall;
                        if (isMatchplay) {
                            // firstFlightTeamGroup.allowReassign = false;
                            firstFlightTeamGroup.showShots = true;
                            let flightMember = _.first(flightMembers.filter(a => a.ball == 1 || a.ball == 2));
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                if (team) {
                                    self.FlightMemberGroupTeamMap(firstFlightTeamGroup, team);
                                }
                            }
                        }
                        let secondFlightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        secondFlightTeamGroup.displayHeader = true;
                        secondFlightTeamGroup.mergedCells = self.isSharedBall;
                        if (isMatchplay) {
                            // secondFlightTeamGroup.allowReassign = false;
                            secondFlightTeamGroup.showShots = true;
                            let flightMember = _.first(flightMembers.filter(a => a.ball == 3 || a.ball == 4));
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                // secondFlightTeamGroup.team = team;
                                if (team) {
                                    self.FlightMemberGroupTeamMap(secondFlightTeamGroup, team);
                                }
                            }
                        }
                        _.range(1, upperBoundBall).forEach(ball => {
                            let flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (_.includes([1, 2], ball)) {

                                if (flightMember) {
                                    let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                    firstFlightTeamGroup = self.FlightMemberGroupTeamMap(firstFlightTeamGroup, team);
                                } else {
                                    flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                                }
                                let flightMember1 = <FlightMember>_.find(flight.flightMembers, { 'ball': 1 });
                                let flightMember2 = <FlightMember>_.find(flight.flightMembers, { 'ball': 2 });
                                if (flightMember1 && flightMember2) {
                                    if (firstFlightTeamGroup.showShots) {
                                        firstFlightTeamGroup.mergedValue = flightMember1.shots;
                                    }
                                    else {
                                        firstFlightTeamGroup.mergedValue = flightMember1.handicap;
                                    }
                                }
                                firstFlightTeamGroup.flightMembers.push(flightMember);
                            }
                            if (_.includes([3, 4], ball)) {
                                if (flightMember) {
                                    let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                    secondFlightTeamGroup = self.FlightMemberGroupTeamMap(secondFlightTeamGroup, team);
                                } else {
                                    flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                                }
                                let flightMember3 = <FlightMember>_.find(flight.flightMembers, { 'ball': 3 });
                                let flightMember4 = <FlightMember>_.find(flight.flightMembers, { 'ball': 4 });
                                if (flightMember3 && flightMember4) {
                                    if (secondFlightTeamGroup.showShots) {
                                        secondFlightTeamGroup.mergedValue = flightMember3.shots;
                                    }
                                    else {
                                        secondFlightTeamGroup.mergedValue = flightMember3.handicap;
                                    }
                                }
                                secondFlightTeamGroup.flightMembers.push(flightMember);

                            }
                        });
                        flight.flightTeamGroups.push(firstFlightTeamGroup);
                        flight.flightTeamGroups.push(secondFlightTeamGroup);
                    }
                });
                return flights;
            })
            .pipe(takeUntil(this.$stop))
            .subscribe((data) => {
                self.flights = data;
            });

        combineLatest(this.teamsService.teams$, this.flightMembersService.flightMembers$,
            function (teams: Array<Team>, flightMembers: Array<FlightMember>) {
                if (flightMembers) {
                    flightMembers.forEach(flightMember => {
                        flightMember.profile.handicap = Math.round(flightMember.profile.handicap * 10) / 10;
                    });
                }
                if (teams) {
                    teams.forEach(function (team) {
                        team.flightMembers = _.filter(flightMembers, { 'teamID': team.teamID });
                        var color = _.find(self.teamColoursSelect, { 'value': team.colour });
                        if (color) {
                            if (color.value != '') {
                                team.colourText = color.label;
                            }
                        }
                    });
                }
                return teams;
            })
            .pipe(takeUntil(this.$stop))
            .subscribe((data: Array<Team>) => {
                //Basically this is a hack I have had to add to cause input change cycle. Clear array and then add
                this.teams = [];
                this.teams.push.apply(this.teams, data)
            });

        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            this.currentRound = <Round>data['round'];
            this.flightType = this.currentRound.flightType;
            this.isSharedBall = this.currentRound.isSharedBall;
            this.teamType = this.currentRound.teamType;
            this.gameType = this.currentRound.gameType;
            this.roundID = this.currentRound.roundID.toString();
            this.competitionID = this.currentRound.competitionID.toString();
            this.loadData(this.currentRound);
        });

        this.courseService.course$.pipe(takeUntil(this.$stop)).subscribe(courses => {
            let currentCourse = _.find(courses, { 'courseID': this.currentRound.courseID });
            this.holes = DomainHelpers.SelectLists.getHoleDetails(currentCourse);
        });
    }


    public loadData(round: Round) {
        this.teamsService.loadAll(round.competitionID.toString());
        this.flightService.loadAll(round.competitionID.toString(), round.roundID.toString());
        this.flightMembersService.loadAll(round.competitionID.toString(), round.roundID.toString());
        this.courseService.load(round.courseID);
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
}
