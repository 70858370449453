import { Component, Input } from '@angular/core';
import { Flight, Round, Team } from '../../models';
import { SelectItem } from 'primeng/primeng';
import * as moment from 'moment';

@Component({
    selector: 'flights-renderer',
    templateUrl: 'flights.renderer.component.html',
    styleUrls: ['flights.renderer.component.css']
})
export class FlightsRenderer {
    private _flights: Array<Flight> = [];
    team: Team;

    get flights(): Array<Flight> {
        return this._flights;
    }

    @Input() set flights(value: Array<Flight>) {
        if (value.constructor === Array && value.length > 0) {
            value.forEach((flight: any) => {
                flight.teeOffTimeText = this.getTime(flight.teeOffTime);
            });
            var sorted = _.sortByAll(value, ['teeOffTimeText', 'startHole']);
            this._flights = sorted;
            this.range = this.getRange(sorted);
        } else {
            value = [];
        }
    }
    @Input() teamColoursSelect: SelectItem[] = [];
    @Input() flightType: number;
    @Input() editable: boolean = false;
    @Input() deleteable: boolean = true;
    @Input() round: Round;
    @Input() holes: SelectItem[];
    @Input() public teams: Array<Team> = [];
    range: Array<Array<Flight>>;

    getRange(flights: Array<Flight>): Array<Array<Flight>> {
        var rows = [];
        if (flights == null) {
            return [];
        }
        var flightIndex = 0;
        for (let i = 0; i < flights.length; i++) {
            if (i % 3 === 0) {
                var flightRow = new Array<Flight>();
                for (let z of [0, 1, 2]) {
                    var flight = flights[flightIndex];
                    if (flight != null) {
                        flightRow.push(flight);
                    }
                    flightIndex++;
                }
                rows.push(flightRow);
            }
        }
        return rows;
    }

    getTime(dateTime: any): string {
        if (dateTime == null) {
            return "";
        }
        return moment.utc(dateTime).format("HH:mm");
    }
}
