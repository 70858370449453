<team-header [teams]="teams" [flightTeamGroup]="flightTeamGroup" *ngIf="flightTeamGroup.displayHeader"></team-header>
<div class="profilesWrapper">
    <div class="col-md-12 col-sm-12 col-xs-12 teeMembersContainer">
        <flight-member-renderer *ngFor="let flightMember of flightTeamGroup.flightMembers; let i = index;" [flightMember]="flightMember" [mergedCells]="flightTeamGroup.mergedCells" [showShots]="flightTeamGroup.showShots">
        </flight-member-renderer>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-2 mergedHandicapDetail" *ngIf="flightTeamGroup.mergedCells">
        <div class="HDPContainer">
            <div class="HDPContent" [class.invalid]="!teamHandicap.valid">
                <span class="hcpText" *ngIf="!editingHCP">{{flightTeamGroup.mergedValue}}</span>
                <span (click)="editHCP()" *ngIf="!editingHCP"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                <div *ngIf="editingHCP">
                    <div>
                        <p-spinner [step]="handicapStep" [formControl]="teamHandicap"></p-spinner>
                    </div>
                    <div class="text-center">
                        <span (click)="cancelHCPEdit()"><i class="fa fa-times" aria-hidden="true"></i></span>
                        <span (click)="saveHCP()">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>