import { Component, OnInit } from '@angular/core';

@Component({
    styleUrls:["notfound.component.scss"],
    templateUrl: 'notfound.component.html'
})

export class NotFoundComponent implements OnInit{
  year = new Date().getFullYear();

  constructor(){}

  ngOnInit(): void {
  }
}
