<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="panel-content tournament-summay-container">
                <div class="row text-right">
                    <label class="pull-left m-l-20 m-b-20 f-20 w-800">Please click on each round to view leaderboards</label>
                </div>               
            </div>
        </div>
    </div>
</div>