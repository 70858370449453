/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jumbo-leaderboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../pipes/visibleColumn.pipe";
import * as i4 from "./jumbo-leaderboard.component";
var styles_JumboLeaderboard = [i0.styles];
var RenderType_JumboLeaderboard = i1.ɵcrt({ encapsulation: 0, styles: styles_JumboLeaderboard, data: { "animation": [{ type: 7, name: "visibilityChanged", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "false", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "1 => 0", animation: { type: 4, styles: null, timings: "300ms" }, options: null }, { type: 1, expr: "0 => 1", animation: { type: 4, styles: null, timings: "300ms" }, options: null }], options: {} }] } });
export { RenderType_JumboLeaderboard as RenderType_JumboLeaderboard };
function View_JumboLeaderboard_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "small-column"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "big-column": 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "small-column"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.headerText === "")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.headerText; _ck(_v, 4, 0, currVal_2); }); }
function View_JumboLeaderboard_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buildColumnData(_v.parent.context.$implicit, _v.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_JumboLeaderboard_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "small-column"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pos": 0, "big-column": 1, "white-color": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumboLeaderboard_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "small-column"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.headerText === "Pos"), (_v.context.$implicit.headerText === ""), (_v.context.$implicit.dataItem === "Thumbnail")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.dataItem !== "Thumbnail"); _ck(_v, 5, 0, currVal_2); }, null); }
function View_JumboLeaderboard_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "player"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "fixed-height": 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_JumboLeaderboard_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.VisibleColumnPipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "player"; var currVal_1 = _ck(_v, 3, 0, (_co.leaderboard.screenPlayers.length < 10)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.leaderboard.config.columns)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_JumboLeaderboard_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "jumbo-leaderboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_JumboLeaderboard_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.VisibleColumnPipe, []), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "player-list"]], [[24, "@visibilityChanged", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumboLeaderboard_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.leaderboard.config.columns)); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.leaderboard.screenPlayers; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.leaderboard.showList; _ck(_v, 5, 0, currVal_1); }); }
export function View_JumboLeaderboard_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "jumbo-leaderboard", [], null, null, null, View_JumboLeaderboard_0, RenderType_JumboLeaderboard)), i1.ɵdid(1, 49152, null, 0, i4.JumboLeaderboard, [], null, null)], null, null); }
var JumboLeaderboardNgFactory = i1.ɵccf("jumbo-leaderboard", i4.JumboLeaderboard, View_JumboLeaderboard_Host_0, { leaderboard: "leaderboard", decimalScoring: "decimalScoring" }, {}, []);
export { JumboLeaderboardNgFactory as JumboLeaderboardNgFactory };
