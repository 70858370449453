import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { DisplayItem, Round, Competition, RoundLeaderboard } from '../../../models';
import { LeaderboardMediaService, LeaderboardService, LoginService, ResultService } from '../../../services';
import { Subject } from 'rxjs';
import { LeaderboardDisplaySettingsComponent } from './leaderboard.display.settings.component'
import { LeaderboardEditComponent } from './leaderboard.edit.component'
import { AdvertEditSettingsComponent } from './advert.edit.component'
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { ManageMediaModalComponent } from './manage-media-modal.component';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: 'tournament-create-leaderboard.component.html',
    styleUrls:['tournament-create-leaderboard.component.scss']
})

export class TournamentCreateLeaderboardComponent implements OnInit, OnDestroy {
    currentRound: Round = new Round();
    public leaderboardDetails: RoundLeaderboard[] = [];
    public LeaderboardPods: DisplayItem[] = [];
    public roundID: string;
    public tournamentId: string;
    public selectorText: string;
    public displayLbPodDialog: boolean = false;
    public displayIteamDeleteDialog: boolean = false;
    public displaySettingsDialog: boolean = false;
    public advertSettingsDialog: boolean = false;
    displayManageMediaDialog = false;
    public selectedRoundLeaderboard: RoundLeaderboard = new RoundLeaderboard();
    firstRoundId: number;
    subscription: any;

    roundsSelect: SelectItem[] = [];
    advertThumbnailURL: string;
    public showPods: boolean = false;
    public title: string = "";
    TVLeaderboardUrl: string;
    Item: any;
    lbTitle: any;
    $stop: Subject<boolean> = new Subject<any>();

    @ViewChild(LeaderboardDisplaySettingsComponent, { static: false }) displaySettings: LeaderboardDisplaySettingsComponent;
    @ViewChild(LeaderboardEditComponent, { static: false }) editLeaderboard: LeaderboardEditComponent;
    @ViewChild(AdvertEditSettingsComponent, { static: false }) editAdvert: AdvertEditSettingsComponent;
    @ViewChild(ManageMediaModalComponent, { static: false }) manageMediaModal: ManageMediaModalComponent;

    dragulaOptions: any = {
        direction: 'horizontal'
    };

    isAgent = false;
    isCalloway: boolean;
    offeringLevel: number;
    public environment = environment;

    constructor(private route: ActivatedRoute,
        private router: Router,
        public leaderboardMediaService: LeaderboardMediaService,
        public leaderboardService: LeaderboardService,        
        private translateService: TranslateService,
        private dragulaService: DragulaService,
        private loginService: LoginService,
        public resultService: ResultService ) {

        this.offeringLevel = this.route.parent.snapshot.data['branding'].offeringType;
    }

    ngOnInit() {
        this.loginService.loggedInDetail$.subscribe(token => {
            if (token !== '' && token !== undefined && token !== null) {
                this.isAgent = true /*token.IsAgent*/;
            }
        });

        this.route.data
            .pipe(takeUntil(this.$stop)).subscribe(data => {
                var currentCompetition = <Competition>data['tournament'];
                this.roundID = data['roundId'];
                this.currentRound = _.find(currentCompetition.rounds, { 'roundID': +this.roundID });
                this.isCalloway = currentCompetition.rounds.filter(r => r.roundID == parseInt(this.roundID))[0].isCallowayStyle;
                this.firstRoundId = currentCompetition.rounds[0].roundID;
                this.tournamentId = currentCompetition.competitionID.toString();
                this.title = currentCompetition.title;
                this.roundsSelect = this.createRoundList(currentCompetition.rounds);
                this.showPods = false;
                this.leaderboardService.loadAll(this.roundID);
                this.leaderboardService.LoadAllDisplayItems(this.roundID);
            })
        var self = this;

        this.leaderboardService.RoundLeaderboard$
            .pipe(takeUntil(this.$stop))
            .subscribe(data => {
                self.leaderboardDetails = data;
            });

        this.leaderboardService.DisplayItem$
            .pipe(takeUntil(this.$stop))
            .subscribe(data => {
                data.forEach((item, index) => {
                    item.viewOrder = index + 1;
                });
                self.LeaderboardPods = data;
                self.saveLBOrder();
                self.showPods = _.some(data, function (n) {
                    var itemId = n['itemId'];
                    return itemId > 0;
                });
            });

        this.subscription = this.dragulaService.drop.subscribe((value) => {
            this.saveLBOrder();
        });
    }

    private saveLBOrder() {
        let displays = [];
        this.LeaderboardPods.forEach((item, index) => {
            displays.push({ tvdisplayID: item.tvdisplayID, viewOrder: index + 1 });
        });
        this.leaderboardService.saveLBOrder(displays);
    }

    createRoundList(rounds: any): Array<SelectItem> {
        var roundDetails = new Array<SelectItem>();
        var i = 0;
        rounds.forEach((round: Round) => {
            i++;
            if (+this.roundID == round.roundID) {
                this.selectorText = "Round " + i.toString();
            };
            roundDetails.push({
                label: i.toString(),
                value: round.roundID.toString()
            });
        });

        return roundDetails;
    }

    navigateToTournament() {
        this.resultService.refresh(this.currentRound);

        if (this.roundsSelect.length > 1) {
            var firstRound = [`/tournaments/${this.tournamentId}/round/${this.firstRoundId}/players`]
            this.router.navigate(firstRound);
        } else {
            var round = ['/tournaments/' + this.tournamentId + '/round/' + this.roundID + '/players'];
            this.router.navigate(round);
        }

    }

    //Refactor to service
    preview() {
        if (this.roundID) {
            window.open(window.location.origin + '/public/tournaments/' + this.tournamentId + '/round/' + this.roundID + '/tvleaderboard', '_blank');
        } else {
            error => console.log('Could not load TV LeaderBoard Url.');
        }
    }

    //----- Manage Media ------
    showManageMediaModal() {
        this.manageMediaModal.resetModal();
        this.manageMediaModal.displayManageMediaDialog = true;
    }

    hideManageMediaModal() {
        this.manageMediaModal.displayManageMediaDialog = false
    }

    //---- Display Settings ----
    showTvDisplaySettings() {
        if (this.leaderboardDetails.length > 0) {
            this.displaySettings.rightLogoMediaID = this.leaderboardDetails[0].topRightMediaID;
            this.displaySettings.backVenueMediaID = this.leaderboardDetails[0].backgroundMediaID;
            this.displaySettings.leftLogoMediaID = this.leaderboardDetails[0].topLeftMediaID;
        }
        this.displaySettings.displaySettingsDialog = true;
    }

    hideTvDisplaySettings() {
        this.displaySettings.displaySettingsDialog = false
    }

    displaySettingsUpdated(roundLeaderboard: RoundLeaderboard) {
        if (this.leaderboardDetails.length > 0) {
            this.leaderboardDetails[0].backgroundMediaID = roundLeaderboard.backgroundMediaID;
            this.leaderboardDetails[0].topLeftMediaID = roundLeaderboard.topLeftMediaID;
            this.leaderboardDetails[0].topRightMediaID = roundLeaderboard.topRightMediaID;
        }
    }

    //---- Advert ----
    showAdvertdAdd() {
        this.editAdvert.roundID = this.roundID;
        this.editAdvert.headerText = this.translateService.instant('TOURNAMENT.LEADERBOARD.CREATE_NEW_ADVERT');

        this.editAdvert.tvDisplay.timer = null;
        this.editAdvert.tvDisplay.mediaID = null;
        this.editAdvert.tvDisplay.tvdisplayID = null;

        this.editAdvert.Show();
    }

    showAdvertEdit(event: DisplayItem) {

        var pod = _.find(this.LeaderboardPods, { 'itemId': event.itemId });

        this.editAdvert.roundID = this.roundID;
        this.editAdvert.headerText = this.translateService.instant('TOURNAMENT.LEADERBOARD.EDIT_ADVERT');

        this.editAdvert.tvDisplay.tvdisplayID = pod.tvdisplayID;
        this.editAdvert.tvDisplay.timer = pod.timer;
        this.editAdvert.tvDisplay.mediaID = pod.itemId;

        this.editAdvert.Show();
    }

    //---- Edit/Add Leaderboard ----
    showLeaderboardAdd() {
        this.editLeaderboard.roundID = this.roundID;
        this.editLeaderboard.leaderboardHeaderText = "Create New Leaderboard";
        this.editLeaderboard.leaderboardEditDetail = new RoundLeaderboard();
        this.editLeaderboard.leaderboardEditDetail.title = '';
        this.editLeaderboard.leaderboardEditDetail.flightMemberType = 100;
        this.editLeaderboard.leaderboardEditDetail.isCreationMode = true;
        this.editLeaderboard.timer = 10;
        this.editLeaderboard.show = true;
    }

    showLeaderboardEdit(event: DisplayItem) {

        var pod = _.find(this.LeaderboardPods, { 'itemId': event.itemId });
        this.editLeaderboard.roundID = this.roundID;
        this.editLeaderboard.leaderboardHeaderText = "Edit Leaderboard";
        this.editLeaderboard.leaderboardEditDetail = _.find(this.leaderboardDetails, { 'roundLeaderboardID': event.itemId });
        this.editLeaderboard.leaderboardEditDetail.isCreationMode = false;
        this.editLeaderboard.timer = pod.timer;
        this.editLeaderboard.show = true;
    }

    /**
     * Tooltip text for "Display settings" button
     */
    getTooltipText() {
        return this.leaderboardDetails.length === 0 ? 'Create a leaderboard to setup display settings' : '';
    }

    //---- Delete Settings ----
    showDeleteSettings(event: DisplayItem) {
        this.Item = event.tvdisplayID;
        this.lbTitle = event.title;
        this.displayIteamDeleteDialog = true;
    }

    hideDeleteSettings(value: boolean) {
        this.displayIteamDeleteDialog = false;
        if (value) {
            // remove leaderboard from list
            const indexToRemove = this.leaderboardDetails.findIndex((leaderboard) => leaderboard.roundLeaderboardID === this.Item);
            this.leaderboardDetails.splice(indexToRemove, 1);
        }
    }

    // ------- EDIT advert or leaderboard
    showEdit(event: DisplayItem) {

        if (event.tvdisplayType == 1) {
            this.showLeaderboardEdit(event)
        }
        else if (event.tvdisplayType == 2) {
            this.showAdvertEdit(event)
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.$stop.next(true);
    }

    // Run calloway adjustment
    runCallowayAdjustment() {
        this.leaderboardService.runCallowayAdjustment(this.roundID);
    }

}
