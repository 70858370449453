import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LeaderBoardMedia } from "../models";
import { Observable, Observer } from "rxjs";
import { CompetitionRouteService } from "./CompetitionRouteService";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class LeaderboardMediaService {
  Post$: Observable<LeaderBoardMedia[]>;
  BackgroundImg$: Observable<LeaderBoardMedia[]>;
  AdvertImg$: Observable<LeaderBoardMedia[]>;

  public RoundId: string;
  private LeaderBoardMediaObserver: Observer<LeaderBoardMedia[]>;
  private LeaderBoardBacImgObserver: Observer<LeaderBoardMedia[]>;
  private LeaderBoardAdvertObserver: Observer<LeaderBoardMedia[]>;

  private _dataStore: {
    leaderBoardMedia: LeaderBoardMedia[];
  };

  private _dataStoreBackImg: {
    leaderBoardBackImg: LeaderBoardMedia[];
  };

  private _dataStoreAdvertImg: {
    leaderBoardAdvertImg: LeaderBoardMedia[];
  };

  constructor(
    private competitionRouteService: CompetitionRouteService,
    public httpService: HttpServices
  ) {
    this.Post$ = new Observable<LeaderBoardMedia[]>(
      (observer) => (this.LeaderBoardMediaObserver = observer)
    ).pipe(share());
    this.BackgroundImg$ = new Observable<LeaderBoardMedia[]>(
      (observer) => (this.LeaderBoardBacImgObserver = observer)
    ).pipe(share());
    this.AdvertImg$ = new Observable<LeaderBoardMedia[]>(
      (observer) => (this.LeaderBoardAdvertObserver = observer)
    ).pipe(share());

    this._dataStore = { leaderBoardMedia: [] };
    this._dataStoreBackImg = { leaderBoardBackImg: [] };
    this._dataStoreAdvertImg = { leaderBoardAdvertImg: [] };

    this.competitionRouteService.roundID$.subscribe((id) => {
      this.RoundId = id;
    });
  }

  loadAll(): void {
    var url = `${environment.apiUrl}/api/LeaderBoard/Getleaderboardlogos`;
    this.httpService.get(url).subscribe(
      (data: Array<LeaderBoardMedia>) => {
        this._dataStore.leaderBoardMedia = data;
        this.LeaderBoardMediaObserver.next(this._dataStore.leaderBoardMedia);
      },
      (error) => console.log("Could not load all Logos.")
    );
  }

  loadAllBackImg(): void {
    var url = `${environment.apiUrl}/api/LeaderBoard/GetleaderboardBackgrounds`;
    this.httpService.get(url).subscribe(
      (data: Array<LeaderBoardMedia>) => {
        this._dataStoreBackImg.leaderBoardBackImg = data;
        this.LeaderBoardBacImgObserver.next(
          this._dataStoreBackImg.leaderBoardBackImg
        );
      },
      (error) => console.log("Could not load all Venue Images.")
    );
  }

  loadAllAdvertImg(): void {
    var url = `${environment.apiUrl}/api/LeaderBoard/GetleaderAdvertsBackgrounds`;
    this.httpService.get(url).subscribe(
      (data: Array<LeaderBoardMedia>) => {
        this._dataStoreAdvertImg.leaderBoardAdvertImg = data;
        this.LeaderBoardAdvertObserver.next(
          this._dataStoreAdvertImg.leaderBoardAdvertImg
        );
      },
      (error) => console.log("Could not load all Adverts.")
    );
  }
  update(LeaderboardDetails): void {
    var roundId = Number(this.RoundId);
    let url = `${environment.apiUrl}/api/LeaderBoard/Setlogo/${roundId}`;
    this.httpService.put(url, LeaderboardDetails).subscribe();
  }
}
