/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flight.team.group.renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./team.header.renderer.component.ngfactory";
import * as i3 from "./team.header.renderer.component";
import * as i4 from "../../services/FlightMembersService";
import * as i5 from "@angular/router";
import * as i6 from "./flight.member.renderer.component.ngfactory";
import * as i7 from "./flight.member.renderer.component";
import * as i8 from "../../services/FlightMemberService";
import * as i9 from "../../../../node_modules/primeng/components/spinner/spinner.ngfactory";
import * as i10 from "primeng/components/dom/domhandler";
import * as i11 from "primeng/components/spinner/spinner";
import * as i12 from "@angular/forms";
import * as i13 from "@angular/common";
import * as i14 from "./flight.team.group.renderer.component";
var styles_FlightTeamGroupRendererComponent = [i0.styles];
var RenderType_FlightTeamGroupRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlightTeamGroupRendererComponent, data: {} });
export { RenderType_FlightTeamGroupRendererComponent as RenderType_FlightTeamGroupRendererComponent };
function View_FlightTeamGroupRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "team-header", [], null, null, null, i2.View_TeamHeaderRendererComponent_0, i2.RenderType_TeamHeaderRendererComponent)), i1.ɵdid(1, 114688, null, 0, i3.TeamHeaderRendererComponent, [i4.FlightMembersService, i5.Router], { flightTeamGroup: [0, "flightTeamGroup"], teams: [1, "teams"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flightTeamGroup; var currVal_1 = _co.teams; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FlightTeamGroupRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "flight-member-renderer", [], null, null, null, i6.View_FlightMemberRendererComponent_0, i6.RenderType_FlightMemberRendererComponent)), i1.ɵdid(1, 49152, null, 0, i7.FlightMemberRendererComponent, [i8.FlightMemberService], { flightMember: [0, "flightMember"], mergedCells: [1, "mergedCells"], showShots: [2, "showShots"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.flightTeamGroup.mergedCells; var currVal_2 = _co.flightTeamGroup.showShots; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FlightTeamGroupRendererComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "hcpText"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flightTeamGroup.mergedValue; _ck(_v, 1, 0, currVal_0); }); }
function View_FlightTeamGroupRendererComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editHCP() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-pencil"]], null, null, null, null, null))], null, null); }
function View_FlightTeamGroupRendererComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "p-spinner", [], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i9.View_Spinner_0, i9.RenderType_Spinner)), i1.ɵprd(512, null, i10.DomHandler, i10.DomHandler, []), i1.ɵdid(4, 114688, null, 0, i11.Spinner, [i1.ElementRef, i10.DomHandler], { step: [0, "step"] }, null), i1.ɵprd(1024, null, i12.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i11.Spinner]), i1.ɵdid(6, 540672, null, 0, i12.FormControlDirective, [[8, null], [8, null], [6, i12.NG_VALUE_ACCESSOR], [2, i12.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i12.NgControl, null, [i12.FormControlDirective]), i1.ɵdid(8, 16384, null, 0, i12.NgControlStatus, [[4, i12.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelHCPEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveHCP() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.handicapStep; _ck(_v, 4, 0, currVal_9); var currVal_10 = _co.teamHandicap; _ck(_v, 6, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).filled; var currVal_1 = i1.ɵnov(_v, 4).focus; var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_FlightTeamGroupRendererComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-md-2 col-sm-2 col-xs-2 mergedHandicapDetail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "HDPContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "HDPContent"]], [[2, "invalid", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightTeamGroupRendererComponent_4)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightTeamGroupRendererComponent_5)), i1.ɵdid(6, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightTeamGroupRendererComponent_6)), i1.ɵdid(8, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.editingHCP; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.editingHCP; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.editingHCP; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.teamHandicap.valid; _ck(_v, 2, 0, currVal_0); }); }
export function View_FlightTeamGroupRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightTeamGroupRendererComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "profilesWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-12 col-sm-12 col-xs-12 teeMembersContainer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightTeamGroupRendererComponent_2)), i1.ɵdid(5, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightTeamGroupRendererComponent_3)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flightTeamGroup.displayHeader; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.flightTeamGroup.flightMembers; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.flightTeamGroup.mergedCells; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_FlightTeamGroupRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "flight-team-group", [], null, null, null, View_FlightTeamGroupRendererComponent_0, RenderType_FlightTeamGroupRendererComponent)), i1.ɵdid(1, 49152, null, 0, i14.FlightTeamGroupRendererComponent, [i4.FlightMembersService], null, null)], null, null); }
var FlightTeamGroupRendererComponentNgFactory = i1.ɵccf("flight-team-group", i14.FlightTeamGroupRendererComponent, View_FlightTeamGroupRendererComponent_Host_0, { flightTeamGroup: "flightTeamGroup", teams: "teams", competitionID: "competitionID" }, {}, []);
export { FlightTeamGroupRendererComponentNgFactory as FlightTeamGroupRendererComponentNgFactory };
