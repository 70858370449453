import { Component, Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { share } from "rxjs/operators";
import { AppCookieService } from './CookiesServices';

@Injectable()
export class UploadService {
    progress: number;
    progress$: Observable<any>;
    Media$: Observable<any>;
    jwtHelper: JwtHelperService = new JwtHelperService();
    private progressObserver: Observer<any>;
    private mediaObserver: Observer<any>;
    constructor(private cookieServices : AppCookieService) {
        this.progress$ = Observable.create(observer => {
            this.progressObserver = observer
        }).pipe(share());

        this.Media$ = Observable.create(observer => {
            this.mediaObserver = observer
        }).pipe(share());
    }

    public makeFileRequest(url: string, params: string[], files: File[]): Observable<any> {
        return Observable.create(observer => {
            let fd: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            var token = this.cookieServices.get('token');

            for (var i = 0; i < files.length; i++) {
                fd.append('Files', files[i]);
            }

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.setRequestHeader('CompetitionHostId', this.cookieServices.getCompetitionHostIdCookie());

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(xhr.response);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);
                if (this.progressObserver) {
                    this.progressObserver.next(this.progress);
                }
            };
            xhr.send(fd);
        });
    }

    public addMedia(url: string, files: File[]): Observable<any> {
        return Observable.create(observer => {
            let fd: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            for (var i = 0; i < files.length; i++) {
                fd.append('Files', files[i], files[i].name);
            }
            var token = this.cookieServices.get('token');

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.setRequestHeader('CompetitionHostId', this.cookieServices.getCompetitionHostIdCookie());
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(xhr.response);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);

                if (this.progressObserver) {
                    this.progressObserver.next(this.progress);
                }
            };
            xhr.send(fd);
        });
    }
}
