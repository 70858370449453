import { Directive } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { LoginService } from '../../services';

@Directive({ selector: '[onlyLoggedIn]' })
export class OnlyLoggedInDirective {
    private hasView = false;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef, private loginService: LoginService) {
        var notLoggedIn = !loginService.isLoggedIn();
        if (!notLoggedIn && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (notLoggedIn && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}