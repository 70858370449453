import { catchError, map, share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  RoundLeaderboard,
  DisplayItem,
  RoundLeaderboardConfig,
  TvDisplay,
} from "../models";
import { Observable, Observer, Subject, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";
import { CompetitionDataService } from "./competition.data.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LeaderboardService {
  RoundLeaderboard$: Observable<RoundLeaderboard[]>;
  DisplayItem$: Observable<DisplayItem[]>;
  HoleConfig$: Observable<RoundLeaderboardConfig[]>;
  private RoundLeaderboardObserver: Observer<RoundLeaderboard[]>;
  private DisplayItemObserver: Observer<DisplayItem[]>;
  private HoleConfigObserver: Observer<RoundLeaderboardConfig[]>;
  headers: HttpHeaders = new HttpHeaders();
  public http: HttpClient;

  private _dataStore: {
    roundleaderboards: RoundLeaderboard[];
  };
  private _dataStoreItems: {
    displayItems: DisplayItem[];
  };

  private _dataStoreHoleConfig: {
    roundLeaderboardConfig: RoundLeaderboardConfig[];
  };
  constructor(
    private toastr: ToastrService,
    public httpService: HttpServices,
    private competitionService: CompetitionDataService
  ) {
    this.RoundLeaderboard$ = new Observable<RoundLeaderboard[]>(
      (observer) => (this.RoundLeaderboardObserver = observer)
    ).pipe(share());
    this.DisplayItem$ = new Observable<DisplayItem[]>(
      (observer) => (this.DisplayItemObserver = observer)
    ).pipe(share());
    this.HoleConfig$ = new Observable<RoundLeaderboardConfig[]>(
      (observer) => (this.HoleConfigObserver = observer)
    ).pipe(share());
    this.headers.append("Content-Type", "application/json; charset=utf-8");
    this._dataStore = { roundleaderboards: [] };
    this._dataStoreItems = { displayItems: [] };
    this._dataStoreHoleConfig = { roundLeaderboardConfig: [] };
  }

  loadAll(RoundID: string): void {
    var url = `${environment.apiUrl}/api/LeaderBoard/${RoundID}`;
    this.httpService.get(url).subscribe(
      (data: Array<RoundLeaderboard>) => {
        this._dataStore.roundleaderboards = data;
        this.RoundLeaderboardObserver.next(this._dataStore.roundleaderboards);
      },
      (error) => console.log("Could not load all roundLeaderBoards.")
    );
  }

  LoadAllDisplayItems(RoundID: string): void {
    var url = `${environment.apiUrl}/api/Tvdisplay/${RoundID}`;
    this.httpService.get(url).subscribe(
      (data: Array<DisplayItem>) => {
        this._dataStoreItems.displayItems = data;
        this.DisplayItemObserver.next(this._dataStoreItems.displayItems);
      },
      (error) => console.log("Could not load all display Items.")
    );
  }

  saveLBOrder(leaderBoardPods: any[]) {
    let SaveLeaderboardObserver = new Subject<any>();
    let url = `${environment.apiUrl}/api/LeaderBoard/ViewOrders`;
    this.httpService.patch(url, leaderBoardPods).subscribe(
      (data: any) => {
        return data;
      },
      (error) => console.log("Could not save display items order.")
    );

    return SaveLeaderboardObserver;
  }

  add(Timer: any, LeaderboardDetails): Observable<DisplayItem> {
    let RoundLeaderboardObserver = new Subject<DisplayItem>();
    let url = `${environment.apiUrl}/api/LeaderBoard/CreateLeaderBoard/${Timer}`;
    this.httpService.post(url, LeaderboardDetails).subscribe(
      (data: DisplayItem) => {
        this._dataStoreItems.displayItems.push(data);
        this.DisplayItemObserver.next(this._dataStoreItems.displayItems);
        RoundLeaderboardObserver.next(data);
        RoundLeaderboardObserver.complete();
        this.toastr.success("Leaderboard has been added");
        return data;
      },
      (error) => console.log("Could not create round Leaderboard.")
    );

    return RoundLeaderboardObserver;
  }

  addAdvert(roundID: any, MediaID: any, timer: any): Observable<DisplayItem> {
    let RoundLeaderboardObserver = new Subject<DisplayItem>();
    this.httpService
      .post(
        `${environment.apiUrl}/api/LeaderBoard/round/${roundID}/mediaadvert/${MediaID}/${timer}`,
        ""
      )
      .subscribe(
        (data: DisplayItem) => {
          this._dataStoreItems.displayItems.push(data);
          this.DisplayItemObserver.next(this._dataStoreItems.displayItems);
          RoundLeaderboardObserver.next(data);
          RoundLeaderboardObserver.complete();
          this.toastr.success("Leaderboard Advert has been added");
          return data;
        },
        (error) => console.log("Could not create round Advert.")
      );

    return RoundLeaderboardObserver;
  }

  update(LeaderboardDetails, RoundID: string): Observable<RoundLeaderboard> {
    console.log(LeaderboardDetails);
    let RoundLeaderboardObserver = new Subject<RoundLeaderboard>();
    let url = `${environment.apiUrl}/api/LeaderBoard/${RoundID}`;
    this.httpService.put(url, LeaderboardDetails).subscribe(
      (data: RoundLeaderboard) => {
        this._dataStore.roundleaderboards.forEach((RoundLeaderboard, i) => {
          if (RoundLeaderboard.roundLeaderboardID === data.roundLeaderboardID) {
            this._dataStore.roundleaderboards[i] = data;
          }
        });
        this.RoundLeaderboardObserver.next(this._dataStore.roundleaderboards);
        RoundLeaderboardObserver.next(data);
        RoundLeaderboardObserver.complete();
        return data;
      },
      (error) => console.log("Could not update round.")
    );
    this.toastr.success("Leaderboard has been updated");
    return RoundLeaderboardObserver;
  }

  updateAdvert(tvDisplay: TvDisplay): void {
    let url = `${environment.apiUrl}/api/LeaderBoard/mediaadvert`;
    this.httpService.put(url, tvDisplay).subscribe(
      (data: DisplayItem) => {
        this._dataStoreItems.displayItems.forEach((displayItem, i) => {
          if (displayItem.tvdisplayID === data.tvdisplayID) {
            this._dataStoreItems.displayItems[i] = data;
          }
        });
        this.DisplayItemObserver.next(this._dataStoreItems.displayItems);
        this.toastr.success("Advert was successfully updated");
      },
      (error) => {
        this.toastr.error("Advert could not be updated");
      }
    );
  }

  removeItem(id: any): Observable<DisplayItem> {
    let RoundLeaderboardObserver = new Subject<DisplayItem>();
    let url = `${environment.apiUrl}/api/Tvdisplay/${id}`;
    this.httpService.delete(url).subscribe();
    this._dataStoreItems.displayItems.forEach((t, index) => {
      if (t.tvdisplayID == id) {
        this._dataStoreItems.displayItems.splice(index, 1);
        this.toastr.success("Item has been removed");
      }
      this.DisplayItemObserver.next(this._dataStoreItems.displayItems);
      RoundLeaderboardObserver.complete();
    });
    return RoundLeaderboardObserver;
  }

  GetHoleConfig(
    RoundLeaderboardID: number
  ): Observable<Array<RoundLeaderboardConfig>> {
    let RoundLeaderboardConfigdObserver = new Subject<
      Array<RoundLeaderboardConfig>
    >();
    let url = `${environment.apiUrl}/api/LeaderBoard/HoleConfig/${RoundLeaderboardID}`;
    this.httpService.get(url).subscribe(
      (data: Array<RoundLeaderboardConfig>) => {
        this._dataStoreHoleConfig.roundLeaderboardConfig = data;
        //this.HoleConfigObserver.next(this._dataStoreHoleConfig.roundLeaderboardConfig);
        RoundLeaderboardConfigdObserver.next(data);
        RoundLeaderboardConfigdObserver.complete();
      },
      (error) => console.log("Could not load all Hole Config.")
    );
    return RoundLeaderboardConfigdObserver;
  }

  SaveHoleConfig(RoundLeaderboardConfig): Observable<RoundLeaderboardConfig> {
    let roundLeaderboardConfig = new Subject<RoundLeaderboardConfig>();
    let url = `${environment.apiUrl}/api/LeaderBoard/HoleConfig/Save`;

    this.httpService.post(url, RoundLeaderboardConfig).subscribe(
      (data: RoundLeaderboardConfig) => {
        this._dataStoreHoleConfig.roundLeaderboardConfig.push(data);
        this.HoleConfigObserver.next(
          this._dataStoreHoleConfig.roundLeaderboardConfig
        );
        roundLeaderboardConfig.next(data);
        roundLeaderboardConfig.complete();
        this.toastr.success("Hole Config has been added");
        return data;
      },
      (error) => console.log("Could not create Leaderboard hole Config.")
    );

    return roundLeaderboardConfig;
  }

  UpdateHoleConfig(RoundLeaderboardConfig): Observable<RoundLeaderboardConfig> {
    let roundLeaderboardConfig = new Subject<RoundLeaderboardConfig>();
    let url = `${environment.apiUrl}/api/LeaderBoard/HoleConfig/Update`;

    this.httpService.put(url, RoundLeaderboardConfig).subscribe(
      (data: RoundLeaderboardConfig) => {
        this._dataStoreHoleConfig.roundLeaderboardConfig.push(data);
        this.HoleConfigObserver.next(
          this._dataStoreHoleConfig.roundLeaderboardConfig
        );
        roundLeaderboardConfig.next(data);
        roundLeaderboardConfig.complete();
        this.toastr.success("Hole Config has been added");
        return data;
      },
      (error) => console.log("Could not create Leaderboard hole Config.")
    );

    return roundLeaderboardConfig;
  }

  DeleteHoleConfig(id: any): Observable<RoundLeaderboardConfig> {
    let RoundLeaderboardHoleConfigObserver =
      new Subject<RoundLeaderboardConfig>();
    let url = `${environment.apiUrl}/api/LeaderBoard/HoleConfig/Delete/${id}`;
    this.httpService.delete(url).subscribe();
    this._dataStoreHoleConfig.roundLeaderboardConfig.forEach((t, index) => {
      if (t.roundLeaderboardConfigID == id) {
        this._dataStoreHoleConfig.roundLeaderboardConfig.splice(index, 1);
      }
      this.HoleConfigObserver.next(
        this._dataStoreHoleConfig.roundLeaderboardConfig
      );
      RoundLeaderboardHoleConfigObserver.complete();
      this.toastr.success("Item has been Removed");
    });
    return RoundLeaderboardHoleConfigObserver;
  }

  recalculateLeaderboard(roundID: any): Observable<any> {    
    const url =
      environment.competitionApiUrl +
      `/api/5.0/leaderboards/recalculate/${roundID}`;

    return this.httpService.post(url, null).pipe(
      map((response) => {
        // Check if the response is "OK" and treat it as success
        if (response === "OK") {
          return { success: true };
        } else {
          return { success: false };
        }
      }),
      catchError((error) => {
        return of({ success: false, error });
      })
    );
  }

  recalculateAllLeaderboards(competitionId: any) {
    this.competitionService
      .getTournament(competitionId.toString())
      .subscribe((s) => {
        s.rounds.forEach((r) => {
          this.recalculateLeaderboard(r.roundID).subscribe();
        });
      });
  }

  runCallowayAdjustment(roundID: any): void {
    const url = `${environment.apiVpar}/1.0/round/callawayscoring`;
    const options = { RoundId: roundID };
    this.httpService.post(url, options).subscribe((result: any) => {
      if (result.status === 200) {
        this.toastr.success("Adjustment finished successfully");
      } else {
        this.toastr.error("Adjustment finished with error");
      }
    });
  }
  removeLeaderboardData(roundId: string): Observable<boolean> {
    const url = `${environment.apiUrl}/api/LeaderBoard/leaderboard-data/${roundId}`;
    return new Observable((observer) => {
      this.httpService.delete(url).subscribe({
        next: () => {
          observer.next(true);
          observer.complete();
        },
        error: (err) => {
          console.log("Error:", err);
          observer.next(false); // Notify subscribers of failure
          observer.complete(); // Complete the observable stream
        },
      });
    });
  }
}
