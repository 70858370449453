import { Profile } from './profile';
import { Flight } from './flight';
import { Team } from './team';
export class FlightMember {
    flightMemberID: number = 0;
    flightID: number = 0;
    profileID: number = 0;
    roundID: number = 0;
    competitionID: number = 0;
    teamID: number;
    profile: Profile
    team: Team;
    flight: Flight
    handicap: number;
    ball: number;
    status: number;
    isCheckedIn: boolean;
    registered: boolean;
    shots: number;
    flightMemberType: number = 1;
    flightMemberTypeText: string;
    vparHandicap: number;
    isManuallyAdjusted: boolean;

    static EmptyFlightMember(roundID: number, flightID: number, ball: number): FlightMember {
        let flightMember = new FlightMember();
        flightMember.flightID = flightID ;
        flightMember.roundID = roundID;
        flightMember.ball = ball;
        return flightMember;
    }
}