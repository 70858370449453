<li class="dd-item dd3-item" pDraggable="dd" pDroppable="dd" (onDrop)="dropOnExistingPlayer($event)" (onDragStart)="dragStart($event,profile)" (onDragEnd)="dragEnd($event)" [hidden]="!visible">
    <div class="dd-handle dd3-handle"></div>
    <div class="container dd3-content">
        <div class="cell2">
            <div class="wrap playerName">
                {{profile.firstName}} {{profile.surname}}
            </div>
        </div>
        <div class="cell4">
            <span *ngIf="profile.vparMember" class="badge label-warning">{{profile.vparHandicap ? profile.vparHandicap : profile.handicap}}</span>
            <span *ngIf="profile.vparMember!= true" class="badge badge-primary">{{profile.vparHandicap ? profile.vparHandicap : profile.handicap}}</span>
        </div>
    </div>
</li>