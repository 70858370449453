/// <reference path="golfers-overview.component.ts" />
import { NgModule } from '@angular/core';
import { GolfersComponent } from './golfers-overview.component';
import { MediaUpload } from './MediaUpload';
import { RouterModule } from '@angular/router';
import { GolferProfileComponent } from './golfers-profile.component';
import { 
    SharedModule, DropdownModule, DataTableModule, 
    DialogModule, SpinnerModule, CalendarModule,
    ConfirmDialogModule,
    ConfirmationService

} from 'primeng/primeng';
import { BrowserModule } from '@angular/platform-browser';
import { GolfersServices } from '../../services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';;
import { AddGolferComponent } from './add-golfer.component'
;
import { AddGolfersBulkComponent } from './add-golfers-bulk.component'
import { MatProgressBarModule } from '@angular/material';
import { ProgressBarModule } 
   from 'primeng/progressbar';

@NgModule({
    imports: [CalendarModule, ReactiveFormsModule, DropdownModule, SpinnerModule, DialogModule, BrowserModule, SharedModule, FormsModule, DataTableModule, RouterModule,
    ConfirmDialogModule, MatProgressBarModule,ProgressBarModule],
    declarations: [GolfersComponent, GolferProfileComponent, MediaUpload, AddGolferComponent, AddGolfersBulkComponent],
    bootstrap: [GolfersComponent, GolferProfileComponent],
    providers: [GolfersServices, ConfirmationService]
})
export class GolfersModule { }