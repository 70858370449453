import { AbstractControl, ValidatorFn } from '@angular/forms';

function check_if_is_integer(value) {
    if ((parseFloat(value) == parseInt(value)) && !isNaN(value)) {
        // I can have spacespacespace1 - which is 1 and validators pases but
        // spacespacespace doesn't - which is what i wanted.
        // 1space2 doesn't pass - good
        // of course, when saving data you do another parseInt.
        return true;
    } else {
        return false;
    }
}

export function isInteger(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;
        const no = check_if_is_integer(value);
        return no ? { 'notInteger': { value } } : null;
    };
}
