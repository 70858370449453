import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { UploadService} from '../../../services';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'MediaUpload',
    templateUrl: 'MediaUpload.html'
})

export class MediaUpload implements OnInit, OnDestroy {
    @ViewChild('form', { static: false }) form;
    @Input() competitionID: string;
    @Input() roundID: string;
    @Input() url: string;
    @Input() index: number;
    @Output() fileUploaded: EventEmitter<any> = new EventEmitter();
    uploadInProgress: boolean;
    @Input() BackType: any;
    @Input() LogoType: any;
    public type: any;
    public $stop: Subject<boolean> = new Subject<any>();

    constructor(private uploadService: UploadService, private toastr: ToastrService) {
    }
    ngOnInit() {
        this.uploadService.progress$.pipe(takeUntil(this.$stop)).subscribe(
            data => {
                console.log('progress = ' + data);
            });
    }

    onChangeUpload(event) {   
        if (this.BackType == 90) { this.type = 10; } 
        if (this.LogoType == 60) { this.type = 60; } 

        var l = this.LogoType;

        this.uploadInProgress = true;
        var Files = event.target.files;
        var fileUploadUrl = `${environment.apiUrl}/api/Media/PostLogo/${this.type}`;
        this.toastr.info('Uploading Image. This may take a minute or so');

        this.uploadService.addMedia(fileUploadUrl, Files).subscribe(response => {
            this.form.nativeElement.reset();
            this.toastr.success('Your image has been uploaded. Please select for the drop down list');            
            this.fileUploaded.next(true);
            this.uploadInProgress = false;
        }, err => {
            this.toastr.error('Failed to upload Image');
        }, () => {
        });
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.$stop.next(true);
    }
}
