export const environment = {
  isLive: false,
  production: true,
  apiUrl: "https://clubplatform-api-dev.azurewebsites.net",
  competitionApiUrl: "https://dev-competitionservice.vpar.com",
  venuesApiUrl: "https://dev-venueservice.azurewebsites.net/",
  apiVpar: "api-dev.vpar.com",
  siteUrl: "dev.vpar.com",
  cdnUrl: "vpardev.blob.core.windows.net",
  imagesCDNUrl: "https://vpardev.blob.core.windows.net/clubplatform/vpar/",
  leaderboardAPIUrl: "https://dev-leaderboards.vpar.com",
  leaderboardRecalculateUrl: "https://tmscalcfunction-dev.azurewebsites.net",
  cryptoSecret : "wpencrypter11239",
  overrideCompetitionHost : 21  
};
