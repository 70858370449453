import { Profile } from './profile';
export class PlayerCreateDetail extends Profile {
    addToBallSlot: boolean = false;
    flightMemberID: number = 0;
    flightMemberType: number = 1;
    flightMemberTypeText: string;
    flightMemberStatus: number = 100;
    flightID: number = 0;
    roundID: number;
    competitionID: number;
    ball: number;
    startHole: number;
    teeOffTime: string;
    teamTitle: string;
    flightHandicap: number = 0;
    shots: number = 0;
    registered: boolean;
    vparHandicap: number;
    isManuallyAdjusted:boolean = false;
    get genderText(): string {
        if (this.gender == null) {
            return "";
        }
        switch (this.gender.toString()) {
            case "1":
                return "M";
            case "2":
                return "F";
        }
        return "";
    }
}