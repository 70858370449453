export class RoundForScoredcard {
  roundID: number;
  maleHoleColourID: number;
  femaleHoleColourID: number;
  gameType: number;
  profileId: number;
  flights: FlightForScoredcard[];
  roundLeaderboards: RoundLeaderboardForScorecard[];
}

export class RoundLeaderboardForScorecard {
  leaderboardTypeID: number;
  roundLeaderboardID: number;
  scoreBestOf: number;
  title: string;
}

export class FlightForScoredcard {
  flightID: number;
  flightMembers: FlightMemberForScoredcard[];
}

export class FlightMemberForScoredcard {
  ball: number;
  flightID: number;
  flightMemberID: number;
  handicap: number;
  teamID: number;
  profile: ProfileForScoredcard;
  scores: ScoreForScoredcard[];
}

export class ProfileForScoredcard {
  profileID: number;
  firstName: string;
  surname: string;
  gender: number;
  pictureFilename: string;
  handicap: number;
  nationality: string;
  golferStats: GolferStats;
}

export class GolferStats {
  averageRound: number;
  bestRound: number;
  compCount: number;
  roundCount: number;
  worstRound: number;
}

export class ScoreForScoredcard {
  holeID: number;
  holeNumber: number;  
  scoreGross: string;
  scoreGrossVPAR: number;
  scoreGrossWithBlob: number;
  scoreGrossPoints: number;
  scoreGrossPointsVPAR : number;
  scoreNet: number;
  scoreNetVPAR: number;
  scorePointsVPAR: number;
  accuracy: number;
  scorePoints: number;
  club: number;
  putts: number;
  scoreType: number;
  sandSaveType: number;
  strokeIndex: number;
  par: number;
  shots: number;
  adjustedGross: number;
}

// ---------------------
// COURSE DATA
// ---------------------
export class CourseForScorecard {
  courseID: number;
  title: string;
  holes: HoleForScorecard[];
}

export class HoleForScorecard {
  holeID: number;
  holeNumber: number;
  holeVersions: HoleVersionForScorecard[];
}

export class HoleVersionForScorecard {
  holeVersionID: number;
  par: number;
  si: number;
  yards: number;
  holeColour: HoleColourForScorecard;
}

export class HoleColourForScorecard {
  holeColourID: number;
  colour: string;
}
