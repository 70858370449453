export namespace OrderOfMeritEnums {
  export enum OrderOfMeritGameTypeEnum {
    Stableford = 1,
    Strokeplay = 2,
    Both = 3,
  }

  export enum PointsFormatEnum {
    MeritPoints = 1,
    StableFord = 2,
    StrokePlay = 3,
  }
}
