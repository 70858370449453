import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '../services';
import { AppCookieService } from '../services/CookiesServices';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

    constructor(private router: Router, private loginService: LoginService , private cookieService : AppCookieService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        var isLoggedIn = this.loginService.isLoggedIn();


        if (!isLoggedIn) {
            this.cookieService.remove("token");
            // save attempted view page
            localStorage.setItem('lastUrl', state.url.toString());
            // not logged in so redirect to login page
            this.router.navigate(['login']);
        }

        return isLoggedIn;
    }
}