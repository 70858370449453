<p-dialog [(visible)]="displayPlayerDialog" [responsive]="false" header="Add Members" [modal]="true" [width]="650"
    [height]="476" [resizable]="true" [draggable]="false" (visibleChange)="visibleChange($event)" (onHide)="cancel()">
    <form novalidate [formGroup]="golferForm"
        style="overflow-y: auto; width:99%; height:570px;padding-bottom:20px">
        <div class="ui-grid ui-grid-responsive ui-fluid">
            <div class="search-bar">
                <i class="fa fa-search"></i>
                <input #gb type="email" placeholder="Search Members" class="form-control"
                    [(ngModel)]="email" (ngModelChange)="onInputChange()" formControlName="emailAddress" #emailInput id="InputField">
                    <button class="search-btn" (click)="enterEmail($event)">Search</button>
               <!-- <button type="button" class="btn m-b-5 download-btn btn-design" style="float:right;" iconPos="left" label="CSV" (click)="downloadTemplate()">
                    <img src="assets/icons/menu-icons/download-icon.svg" alt="">
                    Download Template
                </button>-->
            </div>
            <span *ngIf="!isEmailValid && email.length > 0" class="error-message">Invalid email address</span>
            <div [hidden]="uploadProgress" *ngIf="!email;" class="results" >
                <div id="img-text">
                    <img src="assets/icons/menu-icons/add-golfer-icon.svg" alt="" id="golfer-icon">
                    <span> Look up existing Members within the VPAR databases. You'll find only registered users in your search results, and you must search for Members using their complete email address.</span>
                </div>
               <!-- <button type="file" class="btn m-b-5 download-btn btn-design" id="upload-btn" style="float:right;" iconPos="left" label="CSV">
                  <MediaUpload (isLoadingEvent)="updateProgress($event)"
                                 (isUploadSuccesful)="showUploadSuccess($event)"
                                 (uploadFailEvent)="showUploadFailed($event)"
                                 (fileUploaded)="showUploadResults($event)"
                                 (fileUploadedSuccess)="showBulkUpload($event)">
                    </MediaUpload>
                </button>-->
            </div>
            <div [hidden]="!uploadResult">
                <div [hidden]="uploadSuccesful" class="success" id="success">
                    <div style="display: inline-flex;">
                        <img src="../../../assets/icons/golfers-icons/tms_icons_done.svg" alt="">
                        <p style="margin-bottom:0px;">Upload Succesful</p>
                    </div>

                    <div class="progress" style="width: 100%;height: 4px;">
                        <div class="progress-bar" role="progressbar" style="width: 100%; background-color: #e65c24" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p style="font-weight:500;">100% Uploaded - Your list is now ready to preview.</p>
                </div>

                <div [hidden]="uploadFailed" class="fail" id="fail">
                    <div style="display: inline-flex;">
                        <img src="../../../assets/icons/golfers-icons/tms_icons_error.svg" alt="">
                        <p style="margin-bottom: 0px;color:red;">Upload Failed</p>
                    </div>
                    <div class="progress" style="width: 100%;height: 4px;">
                        <div class="progress-bar" role="progressbar" style="width: 100%; background-color: red;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p style="font-weight:500;">0% Uploaded - There was an error uploading your file. Please try again</p>
                </div>
            </div>

            <div *ngIf="email && golfer">
                <div class="line-separator-top"></div>
                <table id="golferTable">
                    <thead>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                        <td>{{member.displayName}}</td>
                        <td>{{member.team}}</td>
                        <td>{{member.handicap}}</td>
                        <td>
                            <button type="submit" class="btn" (click)="save(golferForm.value)"
                                *ngIf="!member.isMember;else alreadyMember">
                                Add member
                            </button>
                            <ng-template #alreadyMember>
                                <button type="submit" id="memberBtn">Member</button>
                            </ng-template>
                        </td>
                    </tbody>
                </table>
                <div class="line-separator-bottom"></div>
            </div>
        </div>
    </form>
</p-dialog>
<p-dialog [(visible)]="uploadSummary" [responsive]="false" header="Upload Summary" (visibleChange)="visibleChange($event)" [modal]="true" [width]="650"
    [height]="476" [resizable]="true">
    <h4>Your list with {{numberOfMembers}} Members has now been imported. Here is an upload summary: </h4>
    <div style="display: flex;gap: 30px;">
        <div class="uploadSummary">
            <img src="assets/icons/golfers-icons/tms_icons_addgolfer.svg" alt="">
            <p class="added">{{addedGolfersNumbers}} added</p>
            <p class="summaryText">{{addedGolfersNumbers}} Members with an existing VPAR account were added to your members list.</p>
            <button class="btn m-b-5 btn-design" (click)="showAddedGolferList()" [disabled]="addedGolfersNumbers == 0">View members</button>
        </div>
        <div class="uploadSummary">
            <img src="assets/icons/golfers-icons/tms_icons_errorupload.svg" alt="">
            <p class="added">{{duplicateGolfersNumbers}} Duplicates</p>
            <p class="summaryText">{{duplicateGolfersNumbers}} Members with an existing VPAR account were not added to your list.</p>
            <button class="btn m-b-5 btn-design" (click)="showDuplicateGolfersList()" [disabled]="duplicateGolfersNumbers == 0">View members</button>
        </div>
        <div class="uploadSummary">
            <img src="assets/icons/golfers-icons/tms_icons_help.svg" alt="">
            <p class="added">{{newGolfersNumbers}} New Members</p>
            <p class="summaryText">{{newGolfersNumbers}} Members without an existing VPAR account were found. Send them an invite to join?
            </p>
            <button class="btn btn-design" style="margin-top:7px;margin-bottom: 0;" (click)="showNewGolfersList()" [disabled]="newGolfersNumbers == 0">View Members</button>
            <button class="btn invite btn-design" [disabled]="newGolfersNumbers == 0" (click)="inviteAllNew()">Invite All</button>
        </div>
    </div>
    <button class="btn m-b-5 finish btn-design" (click)="cancel()">Finish</button>
</p-dialog>
<p-dialog [(visible)]="addedGolfersFromFile" [responsive]="false" header="Added Members" [modal]="true" [width]="650"
    [height]="476" [resizable]="true">
    <div class="uploadResults">
        <table class="golferTableSummary">
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </thead>
            <tbody class="tableRow">
                <tr *ngFor="let member of addedGolfers">
                    <td>{{member}}</td>

                    <td>
                        <button type="submit" class="btn" style="background-color: #fff;color:#e65c24;border-color: #e65c24;" (click)="save(golferForm.value)">
                            Remove
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</p-dialog>
<p-dialog [(visible)]="duplicateGolfersFromFile" [responsive]="false" header="Duplicate Members" [modal]="true" [width]="650"
    [height]="476" [resizable]="true">
    <div class="uploadResults">
        <table class="golferTableSummary">
            <thead>
                <th></th>
                <th></th>
            </thead>
            <tbody class="tableRow">
                <tr *ngFor="let member of duplicateGolfers">
                    <td id="duplicateEmail">{{member}}</td>

                    <!-- <td>
                        <button type="submit" class="btn" style="background-color: #fff;color:#e6e6e6;border-color: #e6e6e6;" (click)="save(golferForm.value)" disabled>
                            Added
                        </button>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</p-dialog>
<p-dialog [(visible)]="newGolfersFromFile" [responsive]="true" header="New Members" [modal]="true" [width]="650"
    [height]="476" [resizable]="true">
    <div class="uploadResults">
        <table class="golferTableSummary" id="newGolfersList">
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </thead>
            <tbody class="tableRow">
                <tr *ngFor="let member of newGolfers">
                    <td>{{member}}</td>

                    <td>
                        <button type="submit" class="btn" style="background-color: #fff;color:#e65c24;border-color: #e65c24;" (click)="save(golferForm.value)"
                            >
                            Invite
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</p-dialog>
