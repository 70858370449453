/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./player-renderer.component.ngfactory";
import * as i2 from "./player-renderer.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/components/confirm-dialog/confirm-dialog.component.ngfactory";
import * as i5 from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import * as i6 from "./individual-scorecard.component";
import * as i7 from "../../../services/CompetitionRouteService";
import * as i8 from "../../../services/FlightMembersService";
import * as i9 from "@angular/router";
var styles_IndividualScorecardComponent = [];
var RenderType_IndividualScorecardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IndividualScorecardComponent, data: {} });
export { RenderType_IndividualScorecardComponent as RenderType_IndividualScorecardComponent };
function View_IndividualScorecardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "player-renderer", [], null, [[null, "onEditingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEditingChange" === en)) {
        var pd_0 = (_co.editScorecardChanged(_v.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PlayerRendererComponent_0, i1.RenderType_PlayerRendererComponent)), i0.ɵdid(1, 49152, null, 0, i2.PlayerRendererComponent, [i0.ElementRef], { player: [0, "player"], roundID: [1, "roundID"], tournamentOpen: [2, "tournamentOpen"], femaleTeeColor: [3, "femaleTeeColor"], maleTeeColor: [4, "maleTeeColor"], courseData: [5, "courseData"] }, { onEditingChange: "onEditingChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.roundID; var currVal_2 = _co.tournamentOpen; var currVal_3 = _co.round.femaleHoleColourID; var currVal_4 = _co.round.maleHoleColourID; var currVal_5 = _co.courseHoles; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_IndividualScorecardComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { confirmDialog: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IndividualScorecardComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "confirm-dialog", [["message", "You have pending changes, if you continue changes made will be lost"], ["titleText", "Scorecard"]], null, null, null, i4.View_ConfirmDialog_0, i4.RenderType_ConfirmDialog)), i0.ɵdid(4, 49152, [[1, 4]], 0, i5.ConfirmDialog, [], { titleText: [0, "titleText"], message: [1, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.players; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Scorecard"; var currVal_2 = "You have pending changes, if you continue changes made will be lost"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_IndividualScorecardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_IndividualScorecardComponent_0, RenderType_IndividualScorecardComponent)), i0.ɵdid(1, 245760, null, 0, i6.IndividualScorecardComponent, [i7.CompetitionRouteService, i8.FlightMembersService, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndividualScorecardComponentNgFactory = i0.ɵccf("ng-component", i6.IndividualScorecardComponent, View_IndividualScorecardComponent_Host_0, {}, {}, []);
export { IndividualScorecardComponentNgFactory as IndividualScorecardComponentNgFactory };
