import {Component, Input, EventEmitter, Output} from '@angular/core';
import {PrintFormatDetail} from './PrintFormatDetail';

@Component({
    selector: 'PrintFormat',
    templateUrl: 'PrintFormat.html',
    styles: [`

.fa-5x {
       font-size: 5em;
}

.selected {
    color: #ff3300;
}
.fa-file-excel-o .selected {
    color: green;
}
.printFormat {
    color: #75868e;
}
`]
})

export class PrintFormat {
    @Input() detail: PrintFormatDetail;
    @Output() detailUpdated = new EventEmitter<PrintFormatDetail>();
    select() {
        this.detail.selected = !this.detail.selected;
        this.detailUpdated.emit(this.detail);
    }
}
