import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";

import {
  SharedModule,
  DialogModule,
  ButtonModule,
  SpinnerModule,
  DropdownModule,
  CheckboxModule,
  CalendarModule,
  ChipsModule,
  TooltipModule,
  ProgressSpinnerModule,
} from "primeng/primeng";

import {
  OnlyLoggedInDirective,
  NotLoggedInDirective,
  ToggleFeatureDirective,
} from "./directives/index";
import {
  PlayerModal,
  ProfilePictureUpload,
  SideMenuComponent,
  TeamLogoUpload,
  VparTabMenu,
  ScorecardComponent,
  ScoreTabComponent,
  HoleColourInputComponent,
  TopbarComponent,
  ConfirmDialog,
} from "./components/index";
import { MatTabsModule } from "@angular/material";
import { LeaderboardTabMenu } from "./components/leaderboard-menu";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DialogModule,
    ButtonModule,
    CommonModule,
    BrowserModule,
    DropdownModule,
    CheckboxModule,
    CalendarModule,
    SpinnerModule,
    ChipsModule,
    TooltipModule,
    RouterModule,
    TranslateModule,
    ProgressSpinnerModule,
    MatTabsModule,
  ], // module dependencies
  declarations: [
    ConfirmDialog,
    OnlyLoggedInDirective,
    NotLoggedInDirective,
    ScorecardComponent,
    ScoreTabComponent,
    TeamLogoUpload,
    PlayerModal,
    ProfilePictureUpload,
    VparTabMenu,
    SideMenuComponent,
    HoleColourInputComponent,
    TopbarComponent,
    ToggleFeatureDirective,
    LeaderboardTabMenu,
  ], // components and directives
  exports: [
    ConfirmDialog,
    OnlyLoggedInDirective,
    NotLoggedInDirective,
    ScorecardComponent,
    ScoreTabComponent,
    TeamLogoUpload,
    PlayerModal,
    ProfilePictureUpload,
    VparTabMenu,
    LeaderboardTabMenu,
    TranslateModule,
    SideMenuComponent,
    ProgressSpinnerModule,
    HoleColourInputComponent,
    TopbarComponent,
    ToggleFeatureDirective,
  ], // root component
  providers: [],
})
export class AppSharedModule {}
