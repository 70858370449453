import { Component, Output, OnInit, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { LeaderboardMediaService, UploadService } from '../../../services';
import { LeaderBoardMedia, RoundLeaderboard } from '../../../models'
import { Subject } from 'rxjs';
import { CropperSettings, ImageCropperComponent } from "ngx-img-cropper";
import { ToastrService } from 'ngx-toastr';
import { Helpers } from '../../../helpers/helpers';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'leaderboard-display-settings',
    templateUrl: 'leaderboard.display.settings.component.html',
    styleUrls: ['leaderboard.display.settings.component.scss']
})

export class LeaderboardDisplaySettingsComponent implements OnInit, OnDestroy {

    roundID: string;
    displaySettingsDialog: boolean = false;
    mediaOptions: LeaderBoardMedia[] = [];
    mediaBackImgOptions: LeaderBoardMedia[] = [];

    rightLogoMediaID: number = 0;
    backVenueMediaID: number = 0;
    leftLogoMediaID: number = 0;

    selectedSide = '';

    $stop: Subject<boolean> = new Subject<any>();

    @Output() displaySettingsUpdated = new EventEmitter<RoundLeaderboard>();

    showImageCropper = false;
    cropperData: any;
    fileName = '';
    cropperSettings: CropperSettings;
    @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
    uploadInProgress = false;

    constructor(public leaderboardMediaService: LeaderboardMediaService,
        private uploadService: UploadService,
        private toastr: ToastrService) {

        this.cropperSettings = new CropperSettings();
        this.cropperSettings.width = 220;
        this.cropperSettings.height = 120;

        this.cropperSettings.croppedWidth = 220;
        this.cropperSettings.croppedHeight = 120;

        this.cropperSettings.canvasWidth = 400;
        this.cropperSettings.canvasHeight = 220;

        this.cropperSettings.minWidth = 220;
        this.cropperSettings.minHeight = 120;
        this.cropperSettings.keepAspect = true;

        this.cropperSettings.rounded = false;
        this.cropperSettings.noFileInput = true;

        this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
        this.cropperSettings.cropperDrawSettings.strokeWidth = 1;

        this.cropperData = {};
    }

    ngOnInit() {
        this.leaderboardMediaService.Post$
            .pipe(takeUntil(this.$stop))
            .subscribe(data => { this.mediaOptions = data });

        this.leaderboardMediaService.BackgroundImg$
            .pipe(takeUntil(this.$stop))
            .subscribe(data => { this.mediaBackImgOptions = data });

        this.leaderboardMediaService.loadAll();
        this.leaderboardMediaService.loadAllBackImg();
    }

    toggleImageCropper(selectedSide: string) {
        this.showImageCropper = !this.showImageCropper;
        if (this.showImageCropper) {
            this.cropperData = {};
            this.fileName = '';
        }

        this.selectedSide = selectedSide;
    }

    UploadcompleteBackImg() {
        this.leaderboardMediaService.loadAllBackImg();
    }

    saveSettings(event) {
        var roundLeaderboard: RoundLeaderboard = new RoundLeaderboard();
        roundLeaderboard.roundID = Number(this.roundID);
        roundLeaderboard.backgroundMediaID = this.backVenueMediaID;
        roundLeaderboard.topLeftMediaID = this.leftLogoMediaID;
        roundLeaderboard.topRightMediaID = this.rightLogoMediaID;
        this.leaderboardMediaService.update(roundLeaderboard);
        this.displaySettingsUpdated.emit(roundLeaderboard);
        this.displaySettingsDialog = false;
    }

    close(event) {
        this.displaySettingsDialog = false;
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    /**
     * Event launched when the user selects a file to crop.
     * Load the image in the image cropper component
     * @param $event 
     */
    fileChangeListener($event) {
        const image: any = new Image();
        const file: File = $event.target.files[0];
        this.fileName = file.name;
        const myReader: FileReader = new FileReader();
        const that = this;
        myReader.onloadend = function (loadEvent: any) {
            image.src = loadEvent.target.result;
            that.cropper.setImage(image);
        }; 
        myReader.readAsDataURL(file);
    }

    uploadLogo() {
        const file = Helpers.ImageUtil.getFileFromURL(this.cropperData.image, this.fileName);

        this.uploadInProgress = true;
        this.toastr.info('Uploading Image. This may take a minute or so');

        this.uploadService.addMedia(`${environment.apiUrl}/api/Media/PostLogo/60`, [file]).subscribe(response => {
            this.toastr.success('Your image has been uploaded. Please select from the drop down list');
            this.uploadInProgress = false;
            this.leaderboardMediaService.loadAll();
            if (this.selectedSide === 'left') {
                this.leftLogoMediaID = response;
            } else if (this.selectedSide === 'right') {
                this.rightLogoMediaID = response;
            }
            this.toggleImageCropper('');
        }, err => {
            this.toastr.error('Failed to upload Image');
            this.uploadInProgress = false;
        });
    }
}
