import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import {
  InputSwitchModule,
  FileUploadModule,
  SharedModule,
  DropdownModule,
  DragDropModule,
  DataTableModule,
  CalendarModule,
  SpinnerModule,
  CheckboxModule,
  PanelModule,
  DialogModule,
  PaginatorModule,
  AutoCompleteModule,
  AccordionModule,
  TabViewModule,
  ChipsModule,
} from "primeng/primeng";
import { PoolplayerRenderer } from "./poolplayer.renderer.component";
import { PlayerSheetUpload } from "./PlayerSheetUpload";
import { TeamManagement } from "./teammanagement";
import { PlayerPool } from "./playerpool";
import { RoundRendererComponent } from "./round.renderer.component";
import { RoundConfigurationComponent } from "./round.configuration.component";
import { TournamentCreateComponent } from "./tournament-create.component";
import { TournamentEditComponent } from "./tournament-edit.component";
import { TournamentPlayerlistComponent } from "./tournament-playerlist.component";
import { RouterModule } from "@angular/router";
import { AppSharedModule } from "../../../shared/app.shared.module";
import { FlightRenderingModule } from "../../flightrendering/flight.rendering.module";
import {
  FlightMemberService,
  UploadService,
  TeamTypesService,
  VenueService,
  ResultService,
  CourseService,
  FlightMembersService,
  TeamsService,
  PlayerPoolService,
  FlightService,
  ProfileService,
} from "../../../services";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  imports: [
    CommonModule,
    InputSwitchModule,
    RouterModule,
    SpinnerModule,
    DialogModule,
    BrowserModule,
    CheckboxModule,
    AutoCompleteModule,
    AccordionModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    DropdownModule,
    DataTableModule,
    PaginatorModule,
    CalendarModule,
    DragDropModule,
    TabViewModule,
    ReactiveFormsModule,
    FileUploadModule,
    PanelModule,
    AppSharedModule,
    FlightRenderingModule,
    ChipsModule,
    TranslateModule,
  ], // module dependencies
  declarations: [
    TournamentCreateComponent,
    TournamentEditComponent,
    TournamentPlayerlistComponent,
    PoolplayerRenderer,
    PlayerPool,
    PlayerSheetUpload,
    PoolplayerRenderer,
    RoundRendererComponent,
    TeamManagement,
    RoundConfigurationComponent,
  ], // components and directives
  exports: [
    TournamentCreateComponent,
    TournamentEditComponent,
    TournamentPlayerlistComponent,
  ],
  providers: [
    UploadService,
    TeamTypesService,
    VenueService,
    ResultService,
    CourseService,
    FlightMemberService,
    TeamsService,
    FlightService,
    PlayerPoolService,
    FlightMembersService,
    ProfileService,
  ], // services
})
export class TournamentCreateModule {}
