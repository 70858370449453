import { Injectable } from "@angular/core";
import { Team, Round } from "../models";
import { Observable, Subject, BehaviorSubject } from "rxjs";

import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class TeamsService {
  teams$: BehaviorSubject<Team[]> = new BehaviorSubject<Team[]>(null);

  private _dataStore: {
    teams: Team[];
  };

  constructor(public httpService: HttpServices) {
    this._dataStore = { teams: [] };
  }

  load(competitionId: string, teamId: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/Teams/${teamId}`;
    this.httpService.get(url).subscribe(
      (loadedTeam: Team) => {
        this._dataStore.teams.forEach((team, i) => {
          if (loadedTeam.teamID === team.teamID) {
            this._dataStore.teams[i] = loadedTeam;
          }
        });
        this.teams$.next(this._dataStore.teams);
      },
      (error) => console.log("Could not update team.")
    );
  }

  loadAll(competitionId: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/Teams`;
    this.httpService.get(url).subscribe(
      (data: Array<Team>) => {
        this._dataStore.teams = data;
        this.teams$.next(this._dataStore.teams);
      },
      (error) => console.log("Could not load teams.")
    );
  }

  add(competitionID: string, addTeam: Team): Observable<Team> {
    var flightObserver = new Subject<Team>();
    this.httpService
      .post(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Teams`,
        addTeam
      )
      .subscribe(
        (team: Team) => {
          addTeam.teamID = team.teamID;
          this._dataStore.teams.push(addTeam);
          this.teams$.next(this._dataStore.teams);
          flightObserver.next(addTeam);
          flightObserver.complete();
          return team;
        },
        (error) => console.log("Could not create team.")
      );
    return flightObserver;
  }

  //This is less than ideal
  updateLocal(team: Team) {
    if (team) {
      var updated = false;
      var localTeam = _.find(this._dataStore.teams, "teamID", team.teamID);
      if (localTeam) {
        if (localTeam.title != team.title) {
          var i = _.indexOf(this._dataStore.teams, localTeam);
          this._dataStore.teams.splice(i, 1);
          this._dataStore.teams.push(team);
          updated = true;
        }
      } else {
        updated = true;
        this._dataStore.teams.push(team);
      }
      if (updated) {
        this.teams$.next(this._dataStore.teams);
      }
    }
  }

  update(competitionID: string, updatedTeam: Team): Observable<Team> {
    var teamObserver = new Subject<Team>();
    this.httpService
      .put(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Teams`,
        updatedTeam
      )
      .subscribe(
        (team: Team) => {
          this._dataStore.teams.forEach((team, i) => {
            if (updatedTeam.teamID === team.teamID) {
              this._dataStore.teams[i] = updatedTeam;
            }
          });
          this.teams$.next(this._dataStore.teams);
          teamObserver.next(team);
          teamObserver.complete();
        },
        (error) => console.log("Could not update team.")
      );
    return teamObserver;
  }

  remove(competitionID: number, teamID: number): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionID}/Teams/${teamID}`;
    var self = this;
    this.httpService.delete(url).subscribe(
      (response) => {
        self._dataStore.teams.forEach((team, index) => {
          if (team.teamID === teamID) {
            self._dataStore.teams.splice(index, 1);
          }
        });
        self.teams$.next(self._dataStore.teams);
      },
      (error) => console.log("Could not delete team.")
    );
  }

  saveTeams(competitionID: string, teamsList: Team[]): Observable<Team[]> {
    var teamObserver = new Subject<Team[]>();
    this.httpService
      .post(
        `${environment.apiUrl}/api/Competitions/${competitionID}/TeamsBulk`,
        this.transformTeams(competitionID, teamsList)
      )
      .subscribe(
        (teams: Team[]) => {
          this._dataStore.teams = teamsList;
          this.teams$.next(this._dataStore.teams);
          teamObserver.next(teamsList);
          teamObserver.complete();
        },
        (error) => console.log("Could not save teams.")
      );
    return teamObserver;
  }

  private transformTeams(competitionID: string, teams: Team[]) {
    const newTeams = {
      CompetitionID: competitionID,
      Teams: [],
    };

    teams.forEach((team) => {
      newTeams.Teams.push({
        TeamID: team.teamID,
        CompetitionID: team.competitionID,
        Title: team.title,
        Category: team.categoryArray ? team.categoryArray.toString() : "",
        Colour: team.colour,
        LogoFileName: team.logoFilename,
      });
    });

    return newTeams;
  }
}
