import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Round } from "src/app/models";
import { CourseData, TeeData } from "src/app/models/RoundInfo";
import { FlightMemberDatas } from "src/app/models/flightMemberDatas";
import { MemberInfo } from "src/app/models/flightMemberInfo";
import { RoundService } from "src/app/services";

@Component({
  selector: "round-handicap",
  templateUrl: "round-handicap.component.html",
  styleUrls: ["round-handicap.component.scss"],
})
export class RoundHandicapComponent implements OnInit {
  courseData: CourseData;
  roundId: number;
  memberInfo: MemberInfo[];
  flightMemberDatas: FlightMemberDatas;
  dataLoaded: boolean = false;
  roundsSubscription: Subscription;
  showHandicapDetails: boolean = true;
  round: Round;

  constructor(
    private roundService: RoundService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.router.params.subscribe((params) => {
      this.roundId = +params["roundId"];
      this.roundService.load(this.roundId);
    });
    this.roundsSubscription = this.roundService.rounds$.subscribe((rounds) => {
      this.round = rounds.find((r) => r.roundID == this.roundId);
      if (
        this.round  &&
        (this.round .gameType == 2 || this.round .teamType == 60 || this.round .teamType == 70)
      ) {
        this.showHandicapDetails = false;
      } else this.showHandicapDetails = true;
    });
    this.roundService
      .calculateHandicap(this.roundId)
      .subscribe((data: CourseData) => {
        this.courseData = data;
        this.dataLoaded = true;
      });
    this.roundService
      .getFlightMembers(this.roundId)
      .subscribe((data: MemberInfo[]) => {
        this.memberInfo = data;
      });
  }
  calculateCourseHandicapMale(ProfileIndex: number,teeData: CourseData): number {
    const courseHandicap = (ProfileIndex * (teeData.maleTeeData.slopeRating / 113)  + (teeData.maleTeeData.courseRating - teeData.maleTeeData.par));
    const roundedCourseHandicap = +courseHandicap.toFixed(2);
    return roundedCourseHandicap;
  }
  calculateCourseHandicapFemale(ProfileIndex: number,teeData: CourseData): number {
    const courseHandicap = (ProfileIndex  * (teeData.femaleTeeData.slopeRating / 113) + (teeData.femaleTeeData.courseRating - teeData.femaleTeeData.par));
    const roundedCourseHandicap = +courseHandicap.toFixed(2);
    return roundedCourseHandicap;
  }
  calculateGameHandicap(courseHandicap: number, Allowances: number): number {
    const GameHandicap = Math.round(courseHandicap * Allowances);
    return GameHandicap;
  }
  onClickAdjustAll(memberInfo: MemberInfo[]) {
    memberInfo.forEach((member) => {
      if (!member.isManuallyAdjusted) {
        if (member.gender === "Male") {
          member.courseHandicap = this.calculateCourseHandicapMale(member.profileIndex,this.courseData);
        } else {
          member.courseHandicap = this.calculateCourseHandicapFemale(member.profileIndex,this.courseData);
        }
        member.handicap = this.calculateGameHandicap(member.courseHandicap,member.handicapMultiplier);
      }
    });
    this.toggleDisabledButton();
    this.removeHiddenClass();
  }
  onClickAdjustMale(memberInfo: MemberInfo[]) {
    memberInfo.forEach((member) => {
      if (member.gender === "Male") {
        if (!member.isManuallyAdjusted) {
          member.courseHandicap = this.calculateCourseHandicapMale(
            member.profileIndex,
            this.courseData
          );
          member.handicap = this.calculateGameHandicap(
            member.courseHandicap,
            member.handicapMultiplier
          );
        }
      }
    });
    this.toggleDisabledButton();
    this.removeHiddenClass();
  }
  onClickAdjustFemale(memberInfo: MemberInfo[]) {
    memberInfo.forEach((member) => {
      if (member.gender === "Female") {
        if (!member.isManuallyAdjusted) {
          member.courseHandicap = this.calculateCourseHandicapFemale(
            member.profileIndex,
            this.courseData
          );
          member.handicap = this.calculateGameHandicap(
            member.courseHandicap,
            member.handicapMultiplier
          );
        }
      }
    });
    this.toggleDisabledButton();
    this.removeHiddenClass();
  }
  updatedInfo() {
    const flightMemberDataArray = [];
    this.memberInfo.forEach((member) => {
      if (!member.isManuallyAdjusted) {
        const flightMemberData = {
          flightMemberId: Number(member.flightMemberID),
          courseHandicap: member.courseHandicap,
          handicap: member.handicap,
          isManuallyAdjusted: member.isManuallyAdjusted,
        };

        flightMemberDataArray.push(flightMemberData);
      }
    });
    this.roundService.updateFlightMemberInfo(flightMemberDataArray);
    this.addHiddenClass();
  }
  toggleDisabledButton() {
    const myButton = document.getElementById("save-btn") as HTMLButtonElement;
    myButton.disabled = false;
  }

  removeHiddenClass() {
    const divElement = document.getElementById(
      "div-save-message"
    ) as HTMLElement;
    divElement.classList.remove("hidden");
  }
  addHiddenClass() {
    const divElement = document.getElementById(
      "div-save-message"
    ) as HTMLElement;
    divElement.classList.add("hidden");
  }

  ngOnDestroy(): void {
    this.roundsSubscription.unsubscribe();
  }
}
