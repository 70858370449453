import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CompetitionRouteService, CourseService } from '../services';
import { Round, CourseForScorecard } from '../models';
import { Observable, of } from 'rxjs';

@Injectable()
export class CourseResolver implements Resolve<Observable<CourseForScorecard>> {
    constructor(private competitionRouteService: CompetitionRouteService, private courseService:CourseService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CourseForScorecard> {
        if (route.params['roundId']) {
            this.competitionRouteService.roundID$.next(route.params['roundId']);
            var compObservable = this.courseService.getCourseHoles((<Round>route.parent.data['round']).courseID);
            // compObservable.subscribe(course => {
            //     this.competitionRouteService.currentCourse$.next(course);
            // });   
            return compObservable; 
        }
        return of(new CourseForScorecard()) ;
    }
}