import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'tee-time, [tee-time]',
    templateUrl: './tee-time.component.html',
    styleUrls: ['./tee-time.component.scss']
})

export class TeeTimeComponent implements OnInit, OnChanges {

    @Input() teeTime: any;
    @Input() gameType: number;
    @Input() teamType: number;

    leftSideText = '';
    rightSideText = '';
    centerText = '';
    vsTeamFormat = false;
    bigTeamFormat = false;
    letfTeamColor = '';
    rightTeamColor = '';

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.teeTime) {
            this.teeTime.players = _.sortBy(this.teeTime.players,"ball");
            // matchplay and individual/singles (special case)
            if (this.gameType === 2 && this.teamType <= 20) {
                this.leftSideText = this.getTeamName(false, true);
                this.centerText = this.buildHeader();
                this.rightSideText = this.getTeamName(true, true);

                this.letfTeamColor = this.getTeamColor(false, true);
                this.rightTeamColor = this.getTeamColor(true, true);

                this.vsTeamFormat = true;
                this.bigTeamFormat = false;
            } else {
                // matchplay Or pairs, greensomes, foursomes
                if (this.gameType === 2 || this.teamType === 30 || this.teamType === 60 || this.teamType === 70) {
                    this.leftSideText = this.getTeamName(false, false);
                    this.centerText = this.buildHeader();
                    this.rightSideText = this.getTeamName(true, false);

                    this.letfTeamColor = this.getTeamColor(false, false);
                    this.rightTeamColor = this.getTeamColor(true, false);

                    this.vsTeamFormat = true;
                    this.bigTeamFormat = false;
                    // individual, singles
                } else if (this.teamType <= 20) {
                    this.leftSideText = '';
                    this.centerText = this.buildHeader();
                    this.rightSideText = '';

                    this.letfTeamColor = '';
                    this.rightTeamColor = '';

                    this.vsTeamFormat = false;
                    this.bigTeamFormat = false;
                } else {
                    this.leftSideText = this.getTeamName(false, false);
                    this.centerText = '';
                    this.rightSideText = this.buildHeader();

                    this.letfTeamColor = '';
                    this.rightTeamColor = '';

                    this.vsTeamFormat = false;
                    this.bigTeamFormat = true;
                }
            }
        }
    }

    /**
     * Get team Name
     * @param isRightSideTeam left/right side team
     * @param special matchplay and singles/individual
     */
    getTeamName(isRightSideTeam: boolean, special: boolean) {

        let player1 = 0;
        let player2 = special ? 2 : 1;

        if (isRightSideTeam) {
            player1 = special ? 1 : 2;
            player2 = 3;
        }

        if (this.teeTime) {
            if (this.teeTime.players[player1] && this.teeTime.players[player1].team !== null) {
                return this.teeTime.players[player1].team.title;
            } else if (this.teeTime.players[player2] && this.teeTime.players[player2].team !== null) {
                return this.teeTime.players[player2].team.title;
            }
        }
    }

    getTeamColor(isRightSideTeam: boolean, special: boolean) {
        let player1 = 0;
        let player2 = special ? 2 : 1;

        if (isRightSideTeam) {
            player1 = special ? 1 : 2;
            player2 = 3;
        }

        if (this.teeTime) {
            if (this.teeTime.players[player1] && this.teeTime.players[player1].team !== null) {
                return this.teeTime.players[player1].team.colour;
            } else if (this.teeTime.players[player2] && this.teeTime.players[player2].team !== null) {
                return this.teeTime.players[player2].team.colour;
            }
        }
    }

    buildHeader() {

        let result = ''

        if (this.teeTime) {
            this.teeTime.players.forEach(player => {
                result = moment.utc(player.flight.teeOffTime).format("HH:mm") + ' - Hole ' + player.flight.startHole;
            });
        }

        return result;
    }

    showProfilePicture(profile) {
        let profilePictureUrl = "https://" + environment.cdnUrl + "/profile/defaultThumbnail.jpg";

        if (profile && profile.pictureFilename != null && profile.pictureFilename !== '') {
            profilePictureUrl = "https://" + environment.cdnUrl + "/" + profile.pathPictureFilename;

        }
        return profilePictureUrl;
    }
}
