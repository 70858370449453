import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable ,  Subject } from 'rxjs';
import { environment } from "../../environments/environment";
import { TvFeed, RoundLeaderboardTableConfig } from "../models";
import { HttpServices } from "./HttpServices";

@Injectable()
export class TvFeedService {

    constructor(
        public httpService: HttpServices, public http: HttpClient) {
    }

    loadTVFeedConfig(roundID: string): Observable<TvFeed> {
        var scoresLoadObserverable = new Subject<TvFeed>();
      const url = `${environment.apiUrl}/api/TvFeed/round/${roundID}`;
        this.httpService.getTyped<TvFeed>(url)
            .subscribe(data => {
                scoresLoadObserverable.next(data);
            },
                err => console.log(err)
            );
        return scoresLoadObserverable;
    }

    getRoundConfig(roundID: string, roundLeaderboardID: string): Observable<RoundLeaderboardTableConfig> {
        var scoresLoadObserverable = new Subject<RoundLeaderboardTableConfig>();
        const url = `${environment.leaderboardAPIUrl}/api/LeaderboardConfig/GetSingleConfig?RoundId=${roundID}&RoundLeaderboardId=${roundLeaderboardID}`;
        this.httpService.getTyped<RoundLeaderboardTableConfig>(url)
            .subscribe(data => {
                scoresLoadObserverable.next(data);
            },
                err => console.log(err)
            );
        return scoresLoadObserverable;
    }

    getRoundLeaderboardData(url: string, isMatchPlay: boolean): Observable<any> {
        var scoresLoadObserverable = new Subject<any>();

        if (isMatchPlay) {
            this.http.get(url)
                .subscribe(data => {
                    scoresLoadObserverable.next(data);
                },
                    err => console.log(err)
                );
        } else {
            this.httpService.get(url)
                .subscribe(data => {
                    scoresLoadObserverable.next(data);
                },
                    err => console.log(err)
                );
        }
        return scoresLoadObserverable;
    }

    getSocietyConfig(roundID: string, roundLeaderboardID: string): Observable<RoundLeaderboardTableConfig> {
        var scoresLoadObserverable = new Subject<RoundLeaderboardTableConfig>();
        const url = `${environment.leaderboardAPIUrl}/api/LeaderboardConfig/GetSingleConfigSociety?SocietyId=${roundID}&SocietyLeaderboardId=${roundLeaderboardID}`;
        this.httpService.getTyped<RoundLeaderboardTableConfig>(url)
            .subscribe(data => {
                scoresLoadObserverable.next(data);
            },
                err => console.log(err)
            );
        return scoresLoadObserverable;
    }

    getSoceityLeaderboardData(url: string): Observable<any> {
        var scoresLoadObserverable = new Subject<any>();

         this.httpService.get(url)
             .subscribe(data => {
                 scoresLoadObserverable.next(data);
             },
                 err => console.log(err)
             );
        return scoresLoadObserverable;
    }
}
