<div [formGroup]="item">
    <input type="hidden" formControlName="roundID" />
    <input type="hidden" formControlName="competitionID" />
    <div class="row p-t-10">
        <div class="col-lg-3">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'startDate'+index">Start Date</p>
                <p-calendar class="input-lg form-control col-lg-12" formControlName="startDate" [readonlyInput]="true"
                    [dateFormat]="dateFormat" placeholder="Select Date"></p-calendar>
                <div [hidden]="item.controls['startDate'].valid || item.controls['startDate'].pristine"
                    class="alert alert-danger">
                    Start Date is required
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'startTime'+index">Start Time</p>
                <input type="text" onkeypress="return false;" (click)="openTimePicker($event)"
                    (focus)="openTimePicker($event)" atp-time-picker class="form-control input-lg" value="{{startTime}}"
                    placeholder="Select Time">
                <div [hidden]="item.controls['startTime'].valid || item.controls['startTime'].pristine"
                    class="alert alert-danger">
                    Start Time is required
                </div>
            </div>
        </div>
    </div>
    <div class="row p-t-10">
        <div class="col-lg-3" *ngIf="isAgent">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'venueSelect'+index">Venue</p>
                <p-autoComplete class="input-lg form-control" formControlName="venueSelect"
                    [suggestions]="venueSuggestions" (completeMethod)="getVenues($event)" placeholder="Enter Venue Name"
                    field="title" minLength="5" delay="4"></p-autoComplete>
                <span class="selectedVenueSpan" *ngIf="venueIsSelected" (click)="clearVenueSelection()"><i
                        class="fa fa-close fa-lg"></i></span>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'courseID'+index">Course</p>
                <select class="form-control input-lg" data-placeholder="Select an option..." formControlName="courseID">
                    <option *ngFor="let p of courseSelect" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-3" *ngIf="holeColours.length > 0 ">
            <div class="form-group">
                <p class="text-muted bold">{{'TOURNAMENT.CREATE.MALE_TEE_COLOUR' | translate}}</p>
                <select class="form-control input-lg" data-placeholder="Select an option..."
                    formControlName="maleHoleColourID">
                    <option *ngFor="let p of maleColors" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-3" *ngIf="holeColours.length > 0 ">
            <div class="form-group">
                <p class="text-muted bold">{{'TOURNAMENT.CREATE.FEMALE_TEE_COLOUR' | translate}}</p>
                <select class="form-control input-lg" data-placeholder="Select an option..."
                    formControlName="femaleHoleColourID">
                    <option *ngFor="let p of femaleColors" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <p class="m-t-20 m-b-20 f-18 bold">Scoring Format</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'gameType'+index">{{'TOURNAMENT.CREATE.GAME_FORMAT' | translate}}
                </p>
                <select class="form-control input-lg" formControlName="gameType">
                    <option *ngFor="let p of gameTypes" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'teamType'+index">Team Format</p>
                <select class="form-control input-lg" data-style="white" data-placeholder="Select an option..."
                    formControlName="teamType">
                    <option *ngFor="let p of teamTypes" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2" *ngIf="displayFlightSize">
            <div class="form-group">
                <p [attr.for]="'flightType'+index">Players Per Tee Time</p>
                <select class="form-control input-lg" data-style="white" data-placeholder="Select an option..."
                    formControlName="flightType">
                    <option *ngFor="let p of flightTypes" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>

        <div class="col-md-2" *ngIf="displayAllowance">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'handicapMultiplier'+index">HCP Allowance %</p>
                <select class="form-control input-lg" data-style="white" data-placeholder="Select an option..."
                    formControlName="handicapMultiplier">
                    <option *ngFor="let p of handicapMultipliers" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2" *ngIf="displayTeamHandicap">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'teamHandicap'+index">Team HCP %</p>
                <select class="form-control input-lg" data-style="white" data-placeholder="Select an option..."
                    formControlName="teamHandicap">
                    <option *ngFor="let p of teamHandicapMultipliers" [value]="p.value">{{p.label}}</option>
                </select>
            </div>
        </div>

        <div class="col-md-3" *ngIf="displayTeamHandicapLabel">
            <div [ngSwitch]="teamFormat">
                <!-- Case for Greensomes and Foursomes -->
                <p *ngSwitchCase="'Greensomes'" style="padding-top: 10%;">Team HCP Allowance for this format is 60% low / 40% high</p>
                <p *ngSwitchCase="'Foursomes'" style="padding-top: 8%;">Team HCP Allowance for this format is 50% of combined team
                    handicap</p>

                <!-- Case for Scramble -->
                <div *ngSwitchCase="'Scramble'" style="padding-left: 20px;">
                    <p style="padding-left: 20px;">Team HCP Allowance</p>
                    <ul style="list-style-type:none;">
                        <li>4 players-25%low/20%/15%/10%high</li>
                        <li>3 players-30%low/20%/10%high</li>
                        <li>2 players-35%low/15%high</li>
                    </ul>
                </div>

                <!-- Default case -->
                <div *ngSwitchDefault>No specific format selected.</div>
            </div>
        </div>

        <div class="col-lg-3" *ngIf="showCalloway">
            <div class="form-group">
                <p class="text-muted bold" [attr.for]="'isCallowayStyle'+index">
                    <span>
                        <a href="#" class="popoverInfo"
                            data-content="The Callaway System is a sort of 1-day handicapping method that can be used in events where most of the golfers do not have real handicap indexes.">
                            <i class="fa fa-question-circle tooltip-colour"></i>
                        </a>
                    </span>Calloway Scoring
                </p>
                <div class="form-control Override_input-lg inputSwitch"
                    [class.inputSwitchOff]="!item.get('isCallowayStyle').value">
                    <p-inputSwitch styleClass="p-inputSwitch" formControlName="isCallowayStyle"></p-inputSwitch>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="displaySkins">
        <div class="row">
            <div class="col-lg-3">
                <div class="form-group">
                    <p class="text-muted bold" [attr.for]="'skinValue'+index">Skin Value</p>
                    <input class="form-control input-lg" type="number" min="0" step="0.1" name="skinValue" formControlName="skinValue" />
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <p class="text-muted bold" [attr.for]="'skinCurrencyType'+index">Skin Currency</p>
                    <select class="form-control input-lg" data-style="white" data-placeholder="Select an option..." formControlName="skinCurrencyType">
                        <option *ngFor="let p of skinCurrencyTypes" [value]="p.value">{{p.label}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <p class="text-muted bold" [attr.for]="'skinRolloverType'+index">Rollover</p>
                    <select class="form-control input-lg" data-style="white" data-placeholder="Select an option..." formControlName="skinRolloverType">
                        <option *ngFor="let p of skinRolloverTypes" [value]="p.value">{{p.label}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <p class="text-muted bold" [attr.for]="'skinWinBonus'+index">Skin Win Bonus</p>
                    <input class="form-control input-lg" type="number" min="0" name="skinWinBonus" formControlName="skinWinBonus" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <div class="form-group">
                    <p class="text-muted bold" [attr.for]="'skinValue'+index">Skin Draw Bonus</p>
                    <input class="form-control input-lg" type="number" min="0" name="skinDrawBonus" formControlName="skinDrawBonus" />
                </div>
            </div>
        </div>
    </div> -->

    <round-configuration [index]="index"
        [roundConfiguration]="item.controls['roundConfiguration']"></round-configuration>
    <div class="row" *ngIf="canDelete">
        <button type="button" class="btn btn-default pull-right" (click)="roundDelete()">Delete Round</button>
    </div>
</div>

<confirm-dialog #removeround titleText="Delete round" message="Are you sure you want to delete this round?"
    subMessage="This will permanentely remove it from your tournament"></confirm-dialog>

<confirm-dialog #changehandicap titleText="Change Handicap"
    message="Update HCP Allowance? Please note you will lose any manual handicap changes previously made."
    *ngIf="existingRound"></confirm-dialog>

<p-dialog header="Incorrect tee data" [(visible)]="displayNoTeeDialog" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <p class="m-0">This course has incomplete tee data, please select another course to continue or contact your VPAR
        representative for assistance. </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayNoTeeDialog=false" label="Ok"
            styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>