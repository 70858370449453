import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { FlightMemberRendererComponent } from './flight.member.renderer.component';
import { TeamHeaderRendererComponent } from './team.header.renderer.component';
import { FlightsRenderer } from './flights.renderer.component';
import { EditableFlightRendererComponent } from './editable.flight.renderer.component';
import { FlightTeamGroupRendererComponent } from './flight.team.group.renderer.component';
import { DropdownModule, DragDropModule, DialogModule, SpinnerModule } from 'primeng/primeng';
import { AppSharedModule } from "../../shared/app.shared.module";

@NgModule({
    imports: [DialogModule, BrowserModule, FormsModule, ReactiveFormsModule, DropdownModule, AppSharedModule, DragDropModule, SpinnerModule],    // module dependencies
    declarations: [            
        FlightMemberRendererComponent, TeamHeaderRendererComponent,
        FlightsRenderer,        
        FlightTeamGroupRendererComponent,
        EditableFlightRendererComponent],   // components and directives
    exports: [FlightMemberRendererComponent, FlightTeamGroupRendererComponent, TeamHeaderRendererComponent, FlightsRenderer, EditableFlightRendererComponent],
    providers: []                // services
})
export class FlightRenderingModule { }
