<div class="matchplay">
    <div class="teams-header">
        <div class="team left">
            <span>{{matchplayLeaderboard.teams.Team[0].Title}}</span>
            <span class="points" [style.background-color]="'#' + matchplayLeaderboard.teams.Team[0].Colour">{{matchplayLeaderboard.teams.Team[0].Pts}}</span>
        </div>
        <div class="total">
            <span>TOTAL</span>
        </div>
        <div class="team">
            <span class="points" [style.background-color]="'#' + matchplayLeaderboard.teams.Team[1].Colour">{{matchplayLeaderboard.teams.Team[1].Pts}}</span>
            <span>{{matchplayLeaderboard.teams.Team[1].Title}}</span>
        </div>
    </div>
    <div class="matchplay-header">
        <div class="team left">
            <span class="points">{{matchplayLeaderboard.teams['@PredictedTeam1']}}</span>
        </div>
        <div class="total">
            <span>PROJECTED</span>
        </div>
        <div class="team">
            <span class="points">{{matchplayLeaderboard.teams['@PredictedTeam2']}}</span>

        </div>
    </div>
    <div class="matchplay-players" [@visibilityChanged]="matchplayLeaderboard.showList">
        <div class="matchplay-row" *ngFor="let player of matchplayLeaderboard.screenPlayers" [ngClass]="{'fixed-height': matchplayLeaderboard.screenPlayers.length < 10}">
            <div class="team" [style.background-color]="player.Pair[0].CombinedStatus !== '' ? '#' + player.Pair[0].Colour : ''" [style.color]="player.Pair[0].CombinedStatus !== '' ? helpers.Utility.getTextColorLuma(player.Pair[0].Colour) : 'black'">
                <div class="players">
                    <div class="left">
                        <span>{{player.Pair[0].Line1}}</span><br>
                        <span>{{player.Pair[0].Line2}}</span>
                    </div>
                </div>
                <div class="score">
                    <span>{{player.Pair[0].CombinedPts === 'All Sq' && player.Thr === '0' ? '' : replaceAmp(player.Pair[0].CombinedPts)}}</span>
                </div>
            </div>
            <div class="projected">
                <span>{{player.Thr === '0' ? '-' : (player.Thr === '18' ? 'F' : player.Thr)}}</span>
            </div>
            <div class="team" [style.background-color]="player.Pair[1].CombinedStatus !== '' ? '#' + player.Pair[1].Colour : ''" [style.color]="player.Pair[1].CombinedStatus !== '' ? helpers.Utility.getTextColorLuma(player.Pair[1].Colour) : 'black'">
                <div class="score">
                    <span>{{player.Pair[1].CombinedPts === 'All Sq' && player.Thr === '0' ? '' : replaceAmp(player.Pair[1].CombinedPts)}}</span>
                </div>
                <div class="players">
                    <div>
                        <span>{{player.Pair[1].Line1}}</span><br>
                        <span>{{player.Pair[1].Line2}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>