import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CompetitionDataService, CompetitionRouteService } from '../services';
import { Competition } from '../models';
import { Observable, of } from 'rxjs';

@Injectable()
export class CompetitionResolver implements Resolve<Observable<Competition>> {
    constructor(private competitionRouteService: CompetitionRouteService, private competitionDataService:CompetitionDataService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Competition> {
        if (route.params['tournamentId']) {
            this.competitionRouteService.competitionID$.next(route.params['tournamentId']);
            var compObservable = this.competitionDataService.getTournament(route.params['tournamentId']);
            compObservable.subscribe(competetion => {
                this.competitionRouteService.currentCompetition$.next(competetion);
            });   
            return compObservable; 
        }
        return of(new Competition()) ;
    }
}