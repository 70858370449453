import { Component, AfterViewInit, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable ,  Subject, timer } from 'rxjs';
import { Competition } from '../../../models';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

//leaderboard - could make typing
declare var $: any;

@Component({
    templateUrl: 'tournament-summary.component.html',
    styles: [`
    .smallFlag {
      height:15px;
      width:20px;
    }
.ui-datatable-data ui-widget-content {
    border: none;
    border-color: white;
    border-bottom: 1px solid #dddddd !important;
}
  `],
    styleUrls: ['tournament-summary.component.scss']
})

export class TournamentSummaryComponent implements AfterViewInit, OnInit, OnDestroy {
    public tournamentId: string;
    public thumbnailHeight: string = "24";
    public canEditScorecards: string = "false";
    public leaderboardTarget: any;
    public scoresDomain: string;
    @ViewChild('spinner', { static: false }) spinner: ElementRef;
    @ViewChild('leaderboard', { static: false }) vparleaderboard: ElementRef;
    $stop: Subject<boolean> = new Subject<any>();

    constructor(private route: ActivatedRoute, public elementRef: ElementRef) {
        this.scoresDomain = "https://" + environment.siteUrl;
    }

    ngOnInit() {
        this.route.parent.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var tournament = <Competition>data['tournament'];
            this.tournamentId = tournament.competitionID.toString();
        });
    }

    ngAfterViewInit() {
        var leaderboardTarget = $(this.elementRef.nativeElement).find('div.vpar-leaderboard');
        this.leaderboardTarget = leaderboardTarget;
        leaderboardTarget.leaderboard();
        //Basically as the Js loads the inner leaderboard content I have no obvious way to know the leaderboard is loaded and hide the spinner
        //This is a timed hook that checks every 50ms for loaded content and then hides the spinner once it loads.
        var subscription = timer(100, 50).subscribe(t => {
            var table = $(this.vparleaderboard.nativeElement).find('.vpar-leaderboardTable');
            var tbody = table.find('tbody');
            if (tbody && tbody.children().length > 0) {
                this.spinner.nativeElement.style.display = 'none';
                subscription.unsubscribe();
            }
        });
    }
    ngOnDestroy() {
        var target = $(this.leaderboardTarget)[0];
        if (target != null && (typeof target.destroy === "function")) {
            target.destroy();
        }
        //Cleanup using takeUntil
        this.$stop.next(true);
    }
}
