import { FormGroup } from "@angular/forms";
import { OrderOfMeritEnums } from "src/app/models/OrderOfMerit/OrderOfMeritEnums";
import { OrderOfMeritConfig } from "src/app/models/OrderOfMerit/orderofmerit";

export class OrderOfMeritCommon {
  public mapFormGroupToConfig(formGroup: FormGroup): OrderOfMeritConfig {
    let object = new OrderOfMeritConfig();
    object.title = formGroup.get("title").value;
    object.bestOf = formGroup.get("bestOf").value;
    object.description = formGroup.get("description").value;
    object.startDate = formGroup.get("startDate").value;
    object.endDate = formGroup.get("endDate").value;
    object.gameFormat = formGroup.get("gameFormats").value;
    object.pointsFormat = formGroup.get("pointsFormats").value;
    object.pointsAllocation = formGroup.get("pointsAllocation").value;
    object.qualification = formGroup.get("qualifications").value;
    object.worstScore = formGroup.get("worstScore").value;
    object.bestScore = formGroup.get("bestScore").value;
    object.orderOfMeritConfigId = formGroup.get("orderOfMeritConfigId")
      ? formGroup.get("orderOfMeritConfigId").value
      : 0;
    return object;
  }

  public mapNumberToPointsFormatEnum(
    value: number
  ): OrderOfMeritEnums.PointsFormatEnum | undefined {
    switch (value) {
      case 1:
        return OrderOfMeritEnums.PointsFormatEnum.MeritPoints;
      case 2:
        return OrderOfMeritEnums.PointsFormatEnum.StableFord;
      case 3:
        return OrderOfMeritEnums.PointsFormatEnum.StrokePlay;
      default:
        return undefined;
    }
  }
  public mapNumberToGameFormatEnum(
    selectedFormat: number
  ): OrderOfMeritEnums.OrderOfMeritGameTypeEnum | undefined {
    switch (selectedFormat) {
      case 1:
        return OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Stableford;
      case 2:
        return OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Strokeplay;
      case 3:
        return OrderOfMeritEnums.OrderOfMeritGameTypeEnum.Both;
      default:
        return undefined;
    }
  }
}
