<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="panel-content tournament-leaderboard-container" style="min-height:128px;">
                <div *ngIf="lastUpdateDate !== ''" class="lastUpdated">last updated: {{lastUpdateDate}}</div>
                <div class="menu-container">
                    <leaderboard-menu [model]="tabItems" styleClass="media-manage-menu" [activeItem]="activeTab"></leaderboard-menu>
                </div>
                <div *ngFor="let leaderboard of pages">
                    <div *ngIf="leaderboard.title === activeTab.label">
                        <div class="loading" *ngIf="!leaderboard.dataLoaded">
                            <p-progressSpinner></p-progressSpinner>
                        </div>
                        <div class="leaderboard-container" *ngIf="leaderboard.dataLoaded">
                            <!-- DEFAULT -->
                            <default-leaderboard [leaderboard]="leaderboard" [decimalScoring]="roundIsDecimalScoring" *ngIf="leaderboard.dataLoaded && leaderboard.config.columns.length > 0 && leaderboard.screenPlayers.length > 0" style="flex:1; display: flex;flex-direction: column"></default-leaderboard>
                            <!-- MATCHPLAY -->
                            <matchplay-leaderboard style="flex: 1;display: flex;flex-direction: column;" [matchplayLeaderboard]="leaderboard" *ngIf="leaderboard.dataLoaded && leaderboard.config.columns.length === 0 && leaderboard.screenPlayers.length > 0"></matchplay-leaderboard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
