/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./PrintFormat";
var styles_PrintFormat = [".fa-5x[_ngcontent-%COMP%] {\n       font-size: 5em;\n}\n\n.selected[_ngcontent-%COMP%] {\n    color: #ff3300;\n}\n.fa-file-excel-o[_ngcontent-%COMP%]   .selected[_ngcontent-%COMP%] {\n    color: green;\n}\n.printFormat[_ngcontent-%COMP%] {\n    color: #75868e;\n}"];
var RenderType_PrintFormat = i0.ɵcrt({ encapsulation: 0, styles: styles_PrintFormat, data: {} });
export { RenderType_PrintFormat as RenderType_PrintFormat };
export function View_PrintFormat_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "printFormat text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "cursor-pointer"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fa": 0, "fa-5x": 1, "fa-file-word-o": 2, "fa-file-excel-o": 3, "fa-file-pdf-o": 4 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "cursor-pointer"; var currVal_2 = _ck(_v, 4, 0, true, true, (_co.detail.label == "DOC"), (_co.detail.label == "CSV"), (_co.detail.label == "PDF")); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detail.selected; _ck(_v, 1, 0, currVal_0); }); }
export function View_PrintFormat_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "PrintFormat", [], null, null, null, View_PrintFormat_0, RenderType_PrintFormat)), i0.ɵdid(1, 49152, null, 0, i2.PrintFormat, [], null, null)], null, null); }
var PrintFormatNgFactory = i0.ɵccf("PrintFormat", i2.PrintFormat, View_PrintFormat_Host_0, { detail: "detail" }, { detailUpdated: "detailUpdated" }, []);
export { PrintFormatNgFactory as PrintFormatNgFactory };
