/// <reference path="../../../models/tvDisplay.ts" />
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UploadService, LeaderboardMediaService, LeaderboardService } from '../../../services';
import { LeaderBoardMedia, TvDisplay } from '../../../models'
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'advert-edit',
    templateUrl: 'advert.edit.component.html',
    styleUrls: ['advert.edit.component.scss']
})

export class AdvertEditSettingsComponent implements OnInit, OnDestroy {


    public tvDisplay: TvDisplay;
    public show: boolean = false;
    public roundID: string;
    public headerText: string; 
    selectedFile:File=null;

    
    @ViewChild('form', { static: false }) form;
    
    public advertForm: FormGroup;
    public submitted: boolean = false;
    public advertPreview: string = environment.imagesCDNUrl + "advertEmptyState.png";
    public leaderboardAdvertBackImgSelect: SelectItem[];

    private leaderboardAdvertBackImgData: LeaderBoardMedia[] = [];
    private $stop: Subject<boolean> = new Subject<any>();
    http: any;
    public uploadedFilename: string;
    
    
    constructor(private _fb: FormBuilder,
                public leaderboardMediaService: LeaderboardMediaService,
                private uploadService: UploadService,
                public leaderboardService: LeaderboardService,
                private toastr: ToastrService,
                
                ) {
    }

    ngOnInit() {
        this.tvDisplay = new TvDisplay();

        this.advertForm = this._fb.group({
            title: [''],
            timer: [5, [Validators.min(1)]],
            advertMediaID: [null, [Validators.required]],
            advertUpload: [null]
        });
        
        this.leaderboardMediaService.AdvertImg$
            .pipe(takeUntil(this.$stop))
            .subscribe(data => {
                this.leaderboardAdvertBackImgSelect = [{ label: "Choose...", value: null }].concat(data.map(media => { return { label: media.title, value: media.mediaID } }));
                this.leaderboardAdvertBackImgData = data;
            });
        this.leaderboardMediaService.loadAllAdvertImg()
    }
    onFileSelected(event){
        this.selectedFile=event.target.files[0];
    }
   

    
    onChangeUpload(event) {
        const Files = event.target.files;
    
        if (Files.length > 0) {
            const file = Files[0];
            const filenameWithExtension = file.name;
            let filenameWithoutExtension = filenameWithExtension.replace(/\.[^/.]+$/, "");
            filenameWithoutExtension = this.advertForm.controls["title"].value ? this.advertForm.controls["title"].value : filenameWithoutExtension;
            
            const fileUploadUrl = `${environment.apiUrl}/api/Media/PostAdverts/${this.roundID}/${filenameWithoutExtension}`;
    
            this.advertForm.get('advertMediaID').disable();
            this.toastr.info('Uploading Image. This may take a minute or so');
    
            this.uploadService.addMedia(fileUploadUrl, Files).pipe(
                map(response => JSON.parse(response))
            ).subscribe((response: LeaderBoardMedia) => {
                this.advertForm.get('advertMediaID').enable();
            
                
                const uploadedOption = { label: filenameWithoutExtension, value: response.mediaID };
                this.leaderboardAdvertBackImgSelect.unshift(uploadedOption); 
            
               
                this.form.nativeElement.querySelector('input[type="file"]').value = '';
            
               
                this.advertForm.get('timer').setValue(this.advertForm.get('timer').value); 
                this.advertForm.get('advertMediaID').setValue(response.mediaID); 
            
                this.toastr.success('Your image has been uploaded. Please select from the dropdown list');
                this.SetAdvertImage(response.filename);
            
               
            }, err => {
                this.toastr.error('Failed to upload Image');
                this.advertForm.get('advertMediaID').enable(); 
            });
            
        }
    }
    
    


    Updatelist() {
        this.leaderboardMediaService.loadAllAdvertImg();
    }

    SetAdvertImage(filename: string) {
        var advertMediaID = this.advertForm.get('advertMediaID').value;
        var ld = this.leaderboardAdvertBackImgData;
        
        if (filename == null) {
            ld.filter(function (ld) {
                
                if (ld.mediaID == advertMediaID) {
                    filename = ld.filename;
                }
            });
        }
        
        if (filename) {
            this.advertPreview = "https://" + environment.cdnUrl + "/media/adverts/" + filename;
    
           
            const selectedMedia = ld.find(media => media.filename === filename);
            if (selectedMedia) {
                this.advertForm.get('advertMediaID').setValue(selectedMedia.mediaID);
            }
        } else {
            this.advertPreview = environment.imagesCDNUrl + "advertEmptyState.png";
        }
    }
    

    Save(detail: any) {
        this.submitted = true;
        if (this.advertForm.valid) {

            if (this.tvDisplay.tvdisplayID != null) {
               
                this.tvDisplay.timer = detail.timer;
                this.tvDisplay.mediaID = detail.advertMediaID;
                this.leaderboardService.updateAdvert(this.tvDisplay);
            }
            else
            {
                this.leaderboardService.addAdvert(this.roundID, detail.advertMediaID, detail.timer);
            }
            this.Close();
        }
    }

    Show() {
        this.submitted = false;
        this.advertForm.controls["timer"].setValue(5);
        this.advertForm.controls["advertMediaID"].setValue(this.tvDisplay.mediaID);
        this.advertForm.controls["title"].setValue("");
        this.show = true;
        this.SetAdvertImage(null);
    }

    Close() {
        this.show = false;
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }
}
