import { SelectItem } from 'primeng/primeng';
import { Round, Course } from '../models';
export module DomainHelpers {
    export class SelectLists {
        static getShirtSizes(): SelectItem[] {
            var shirtSizes = new Array<SelectItem>();
            shirtSizes.push({ label: 'Choose....', value: '' });
            shirtSizes.push({ label: 'XS', value: 'XS' });
            shirtSizes.push({ label: 'S', value: 'S' });
            shirtSizes.push({ label: 'M', value: 'M' });
            shirtSizes.push({ label: 'ML', value: 'ML' });
            shirtSizes.push({ label: 'L', value: 'L' });
            shirtSizes.push({ label: 'XL', value: 'XL' });
            shirtSizes.push({ label: 'XXL', value: 'XXL' });
            shirtSizes.push({ label: 'XXXL', value: 'XXXL' });
            return shirtSizes;
        }

        static getNationalities(): SelectItem[] {
            var nationalities = new Array<SelectItem>();
            nationalities.push({ label: 'Choose....', value: '' });
            nationalities.push({ label: 'Afghan', value: 'Afghan' });
            nationalities.push({ label: 'Albanian', value: 'Albanian' });
            nationalities.push({ label: 'Algerian', value: 'Algerian' });
            nationalities.push({ label: 'American', value: 'American' });
            nationalities.push({ label: 'American Samoan', value: 'American Samoan' });
            nationalities.push({ label: 'Andorran', value: 'Andorran' });
            nationalities.push({ label: 'Angolan', value: 'Angolan' });
            nationalities.push({ label: 'Anguillan', value: 'Anguillan' });
            nationalities.push({ label: 'Antarctican', value: 'Antarctican' });
            nationalities.push({ label: 'Antiguan', value: 'Antiguan' });
            nationalities.push({ label: 'Antillean', value: 'Antillean' });
            nationalities.push({ label: 'Argentinian', value: 'Argentinian' });
            nationalities.push({ label: 'Armenian', value: 'Armenian' });
            nationalities.push({ label: 'Aruban', value: 'Aruban' });
            nationalities.push({ label: 'Australian', value: 'Australian' });
            nationalities.push({ label: 'Austrian', value: 'Austrian' });
            nationalities.push({ label: 'Azerbaijani', value: 'Azerbaijani' });
            nationalities.push({ label: 'Bahamian', value: 'Bahamian' });
            nationalities.push({ label: 'Bahraini', value: 'Bahraini' });
            nationalities.push({ label: 'Bangladeshi', value: 'Bangladeshi' });
            nationalities.push({ label: 'Barbadian', value: 'Barbadian' });
            nationalities.push({ label: 'Basotho', value: 'Basotho' });
            nationalities.push({ label: 'Belarusian', value: 'Belarusian' });
            nationalities.push({ label: 'Belgian', value: 'Belgian' });
            nationalities.push({ label: 'Belizean', value: 'Belizean' });
            nationalities.push({ label: 'Beninese', value: 'Beninese' });
            nationalities.push({ label: 'Bermudian', value: 'Bermudian' });
            nationalities.push({ label: 'Bhutanese', value: 'Bhutanese' });
            nationalities.push({ label: 'Bolivian', value: 'Bolivian' });
            nationalities.push({ label: 'Bosnia and Herzegovina national', value: 'Bosnia and Herzegovina national' });
            nationalities.push({ label: 'Botswanan', value: 'Botswanan' });
            nationalities.push({ label: 'Bouvet Island', value: 'Bouvet Island' });
            nationalities.push({ label: 'Brazilian', value: 'Brazilian' });
            nationalities.push({ label: 'British', value: 'British' });
            nationalities.push({ label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' });
            nationalities.push({ label: 'British Virgin Islander', value: 'British Virgin Islander' });
            nationalities.push({ label: 'Bruneian', value: 'Bruneian' });
            nationalities.push({ label: 'Bulgarian', value: 'Bulgarian' });
            nationalities.push({ label: 'Burkinabe', value: 'Burkinabe' });
            nationalities.push({ label: 'Burmese', value: 'Burmese' });
            nationalities.push({ label: 'Burundian', value: 'Burundian' });
            nationalities.push({ label: 'Cambodian', value: 'Cambodian' });
            nationalities.push({ label: 'Cameroonian', value: 'Cameroonian' });
            nationalities.push({ label: 'Canadian', value: 'Canadian' });
            nationalities.push({ label: 'Cape Verdean', value: 'Cape Verdean' });
            nationalities.push({ label: 'Caymanian', value: 'Caymanian' });
            nationalities.push({ label: 'Central African', value: 'Central African' });
            nationalities.push({ label: 'Chadian', value: 'Chadian' });
            nationalities.push({ label: 'Chilean', value: 'Chilean' });
            nationalities.push({ label: 'China', value: 'China' });
            nationalities.push({ label: 'Chinese', value: 'Chinese' });
            nationalities.push({ label: 'Christmas Islander', value: 'Christmas Islander' });
            nationalities.push({ label: 'Cocos Islander', value: 'Cocos Islander' });
            nationalities.push({ label: 'Colombian', value: 'Colombian' });
            nationalities.push({ label: 'Comorian', value: 'Comorian' });
            nationalities.push({ label: 'Congolese', value: 'Congolese' });
            nationalities.push({ label: 'Cook Islander', value: 'Cook Islander' });
            nationalities.push({ label: 'Costa Rican', value: 'Costa Rican' });
            nationalities.push({ label: 'Croat', value: 'Croat' });
            nationalities.push({ label: 'Cuban', value: 'Cuban' });
            nationalities.push({ label: 'Cypriot', value: 'Cypriot' });
            nationalities.push({ label: 'Czech', value: 'Czech' });
            nationalities.push({ label: 'Dane', value: 'Dane' });
            nationalities.push({ label: 'Djiboutian', value: 'Djiboutian' });
            nationalities.push({ label: 'Dominican', value: 'Dominican' });
            nationalities.push({ label: 'Dutch', value: 'Dutch' });
            nationalities.push({ label: 'Netherlander', value: 'Netherlander' });
            nationalities.push({ label: 'East Timorese', value: 'East Timorese' });
            nationalities.push({ label: 'Ecuadorian', value: 'Ecuadorian' });
            nationalities.push({ label: 'Egyptian', value: 'Egyptian' });
            nationalities.push({ label: 'Emirian', value: 'Emirian' });
            nationalities.push({ label: 'English', value: 'English' });
            nationalities.push({ label: 'Equatorial Guinean', value: 'Equatorial Guinean' });
            nationalities.push({ label: 'Eritrean', value: 'Eritrean' });
            nationalities.push({ label: 'Estonian', value: 'Estonian' });
            nationalities.push({ label: 'Ethiopian', value: 'Ethiopian' });
            nationalities.push({ label: 'Faeroese', value: 'Faeroese' });
            nationalities.push({ label: 'Falkland Islander', value: 'Falkland Islander' });
            nationalities.push({ label: 'Fiji Islander', value: 'Fiji Islander' });
            nationalities.push({ label: 'Filipino', value: 'Filipino' });
            nationalities.push({ label: 'Finn', value: 'Finn' });
            nationalities.push({ label: 'French', value: 'French' });
            nationalities.push({ label: 'French Southern Territories', value: 'French Southern Territories' });
            nationalities.push({ label: 'Gabonese', value: 'Gabonese' });
            nationalities.push({ label: 'Gambian', value: 'Gambian' });
            nationalities.push({ label: 'Georgian', value: 'Georgian' });
            nationalities.push({ label: 'German', value: 'German' });
            nationalities.push({ label: 'Ghanaian', value: 'Ghanaian' });
            nationalities.push({ label: 'Gibraltarian', value: 'Gibraltarian' });
            nationalities.push({ label: 'Greek', value: 'Greek' });
            nationalities.push({ label: 'Greenlander', value: 'Greenlander' });
            nationalities.push({ label: 'Grenadian', value: 'Grenadian' });
            nationalities.push({ label: 'Guadeloupean', value: 'Guadeloupean' });
            nationalities.push({ label: 'Guamanian', value: 'Guamanian' });
            nationalities.push({ label: 'Guatemalan', value: 'Guatemalan' });
            nationalities.push({ label: 'Guianese', value: 'Guianese' });
            nationalities.push({ label: 'Guinea-Bissau national', value: 'Guinea-Bissau national' });
            nationalities.push({ label: 'Guinean', value: 'Guinean' });
            nationalities.push({ label: 'Guyanese', value: 'Guyanese' });
            nationalities.push({ label: 'Haitian', value: 'Haitian' });
            nationalities.push({ label: 'Heard Island and McDonald Islands', value: 'Heard Island and McDonald Islands' });
            nationalities.push({ label: 'Honduran', value: 'Honduran' });
            nationalities.push({ label: 'Hong Kong Chinese', value: 'Hong Kong Chinese' });
            nationalities.push({ label: 'Hungarian', value: 'Hungarian' });
            nationalities.push({ label: 'Icelander', value: 'Icelander' });
            nationalities.push({ label: 'Indian', value: 'Indian' });
            nationalities.push({ label: 'Indonesia', value: 'Indonesia' });
            nationalities.push({ label: 'Indonesian', value: 'Indonesian' });
            nationalities.push({ label: 'Iranian', value: 'Iranian' });
            nationalities.push({ label: 'Iraqi', value: 'Iraqi' });
            nationalities.push({ label: 'Irish', value: 'Irish' });
            nationalities.push({ label: 'Israeli', value: 'Israeli' });
            nationalities.push({ label: 'Italian', value: 'Italian' });
            nationalities.push({ label: 'Ivorian', value: 'Ivorian' });
            nationalities.push({ label: 'Jamaican', value: 'Jamaican' });
            nationalities.push({ label: 'Japanese', value: 'Japanese' });
            nationalities.push({ label: 'Jordanian', value: 'Jordanian' });
            nationalities.push({ label: 'Kazakh', value: 'Kazakh' });
            nationalities.push({ label: 'Kenyan', value: 'Kenyan' });
            nationalities.push({ label: 'Kiribatian', value: 'Kiribatian' });
            nationalities.push({ label: 'Kuwaiti', value: 'Kuwaiti' });
            nationalities.push({ label: 'Kyrgyz', value: 'Kyrgyz' });
            nationalities.push({ label: 'Lao', value: 'Lao' });
            nationalities.push({ label: 'Latvian', value: 'Latvian' });
            nationalities.push({ label: 'Lebanese', value: 'Lebanese' });
            nationalities.push({ label: 'Liberian', value: 'Liberian' });
            nationalities.push({ label: 'Libyan', value: 'Libyan' });
            nationalities.push({ label: 'Liechtensteiner', value: 'Liechtensteiner' });
            nationalities.push({ label: 'Lithuanian', value: 'Lithuanian' });
            nationalities.push({ label: 'Luxembourger', value: 'Luxembourger' });
            nationalities.push({ label: 'Macanese', value: 'Macanese' });
            nationalities.push({ label: 'Macedonian', value: 'Macedonian' });
            nationalities.push({ label: 'Mahorais', value: 'Mahorais' });
            nationalities.push({ label: 'Malagasy', value: 'Malagasy' });
            nationalities.push({ label: 'Malawian', value: 'Malawian' });
            nationalities.push({ label: 'Malaysian', value: 'Malaysian' });
            nationalities.push({ label: 'Maldivian', value: 'Maldivian' });
            nationalities.push({ label: 'Malian', value: 'Malian' });
            nationalities.push({ label: 'Maltese', value: 'Maltese' });
            nationalities.push({ label: 'Marshallese', value: 'Marshallese' });
            nationalities.push({ label: 'Martinican', value: 'Martinican' });
            nationalities.push({ label: 'Mauritanian', value: 'Mauritanian' });
            nationalities.push({ label: 'Mauritian', value: 'Mauritian' });
            nationalities.push({ label: 'Mexican', value: 'Mexican' });
            nationalities.push({ label: 'Micronesian', value: 'Micronesian' });
            nationalities.push({ label: 'Moldovan', value: 'Moldovan' });
            nationalities.push({ label: 'Monegasque', value: 'Monegasque' });
            nationalities.push({ label: 'Mongolian', value: 'Mongolian' });
            nationalities.push({ label: 'Montenegrian', value: 'Montenegrian' });
            nationalities.push({ label: 'Montserratian', value: 'Montserratian' });
            nationalities.push({ label: 'Moroccan', value: 'Moroccan' });
            nationalities.push({ label: 'Mozambican', value: 'Mozambican' });
            nationalities.push({ label: 'Namibian', value: 'Namibian' });
            nationalities.push({ label: 'Nauruan', value: 'Nauruan' });
            nationalities.push({ label: 'Nepalese', value: 'Nepalese' });
            nationalities.push({ label: 'New Caledonian', value: 'New Caledonian' });
            nationalities.push({ label: 'New Zealander', value: 'New Zealander' });
            nationalities.push({ label: 'Nicaraguan', value: 'Nicaraguan' });
            nationalities.push({ label: 'Nigerian', value: 'Nigerian' });
            nationalities.push({ label: 'Nigerien', value: 'Nigerien' });
            nationalities.push({ label: 'Niuean', value: 'Niuean' });
            nationalities.push({ label: 'Norfolk Islander', value: 'Norfolk Islander' });
            nationalities.push({ label: 'North Korean', value: 'North Korean' });
            nationalities.push({ label: 'Northern Irish', value: 'Northern Irish' });
            nationalities.push({ label: 'Northern Mariana Islander', value: 'Northern Mariana Islander' });
            nationalities.push({ label: 'Norwegian', value: 'Norwegian' });
            nationalities.push({ label: 'Omani', value: 'Omani' });
            nationalities.push({ label: 'Pakistani', value: 'Pakistani' });
            nationalities.push({ label: 'Palauan', value: 'Palauan' });
            nationalities.push({ label: 'Panamanian', value: 'Panamanian' });
            nationalities.push({ label: 'Papua New Guinean', value: 'Papua New Guinean' });
            nationalities.push({ label: 'Paraguayan', value: 'Paraguayan' });
            nationalities.push({ label: 'Peruvian', value: 'Peruvian' });
            nationalities.push({ label: 'Pitcairner', value: 'Pitcairner' });
            nationalities.push({ label: 'Polish', value: 'Polish' });
            nationalities.push({ label: 'Pole', value: 'Pole' });
            nationalities.push({ label: 'Polynesian', value: 'Polynesian' });
            nationalities.push({ label: 'Portuguese', value: 'Portuguese' });
            nationalities.push({ label: 'Puerto Rican', value: 'Puerto Rican' });
            nationalities.push({ label: 'Qatari', value: 'Qatari' });
            nationalities.push({ label: 'Reunionese', value: 'Reunionese' });
            nationalities.push({ label: 'Romanian', value: 'Romanian' });
            nationalities.push({ label: 'Russian', value: 'Russian' });
            nationalities.push({ label: 'Rwandan', value: 'Rwandan' });
            nationalities.push({ label: 'Sahrawi', value: 'Sahrawi' });
            nationalities.push({ label: 'Saint Helenian', value: 'Saint Helenian' });
            nationalities.push({ label: 'Saint Kitts and Nevis national', value: 'Saint Kitts and Nevis national' });
            nationalities.push({ label: 'Saint Lucian', value: 'Saint Lucian' });
            nationalities.push({ label: 'Saint Pierre and Miquelon national', value: 'Saint Pierre and Miquelon national' });
            nationalities.push({ label: 'Salvadorian', value: 'Salvadorian' });
            nationalities.push({ label: 'Samoan', value: 'Samoan' });
            nationalities.push({ label: 'San Marinese', value: 'San Marinese' });
            nationalities.push({ label: 'Sao Tomean', value: 'Sao Tomean' });
            nationalities.push({ label: 'Saudi Arabian', value: 'Saudi Arabian' });
            nationalities.push({ label: 'Scottish', value: 'Scottish' });
            nationalities.push({ label: 'Senegalese', value: 'Senegalese' });
            nationalities.push({ label: 'Serbian', value: 'Serbian' });
            nationalities.push({ label: 'Seychellois', value: 'Seychellois' });
            nationalities.push({ label: 'Sierra Leonean', value: 'Sierra Leonean' });
            nationalities.push({ label: 'Singapore', value: 'Singapore' });
            nationalities.push({ label: 'Singaporean', value: 'Singaporean' });
            nationalities.push({ label: 'Slovak', value: 'Slovak' });
            nationalities.push({ label: 'Slovene', value: 'Slovene' });
            nationalities.push({ label: 'Solomon Islander', value: 'Solomon Islander' });
            nationalities.push({ label: 'Somali', value: 'Somali' });
            nationalities.push({ label: 'South African', value: 'South African' });
            nationalities.push({ label: 'South Georgia and the South Sandwich Islands', value: 'South Georgia and the South Sandwich Islands' });
            nationalities.push({ label: 'South Korean', value: 'South Korean' });
            nationalities.push({ label: 'Spanish', value: 'Spanish' });
            nationalities.push({ label: 'Sri Lankan', value: 'Sri Lankan' });
            nationalities.push({ label: 'Sudanese', value: 'Sudanese' });
            nationalities.push({ label: 'Surinamer', value: 'Surinamer' });
            nationalities.push({ label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' });
            nationalities.push({ label: 'Swazi', value: 'Swazi' });
            nationalities.push({ label: 'Swede', value: 'Swede' });
            nationalities.push({ label: 'Swiss', value: 'Swiss' });
            nationalities.push({ label: 'Syrian', value: 'Syrian' });
            nationalities.push({ label: 'Taiwan', value: 'Taiwan' });
            nationalities.push({ label: 'Taiwanese', value: 'Taiwanese' });
            nationalities.push({ label: 'Tajik', value: 'Tajik' });
            nationalities.push({ label: 'Tanzanian', value: 'Tanzanian' });
            nationalities.push({ label: 'Thai', value: 'Thai' });
            nationalities.push({ label: 'Togolese', value: 'Togolese' });
            nationalities.push({ label: 'Tokelauan', value: 'Tokelauan' });
            nationalities.push({ label: 'Tongan', value: 'Tongan' });
            nationalities.push({ label: 'Trinidad and Tobago national', value: 'Trinidad and Tobago national' });
            nationalities.push({ label: 'Tunisian', value: 'Tunisian' });
            nationalities.push({ label: 'Turk', value: 'Turk' });
            nationalities.push({ label: 'Turkish', value: 'Turkish' });
            nationalities.push({ label: 'Turkmen', value: 'Turkmen' });
            nationalities.push({ label: 'Turks and Caicos Islander', value: 'Turks and Caicos Islander' });
            nationalities.push({ label: 'Tuvaluan', value: 'Tuvaluan' });
            nationalities.push({ label: 'Ugandan', value: 'Ugandan' });
            nationalities.push({ label: 'Ukrainian', value: 'Ukrainian' });
            nationalities.push({ label: 'Uruguayan', value: 'Uruguayan' });
            nationalities.push({ label: 'US Virgin Islander', value: 'US Virgin Islander' });
            nationalities.push({ label: 'Uzbek', value: 'Uzbek' });
            nationalities.push({ label: 'Vanuatuan', value: 'Vanuatuan' });
            nationalities.push({ label: 'Vatican', value: 'Vatican' });
            nationalities.push({ label: 'Venezuelan', value: 'Venezuelan' });
            nationalities.push({ label: 'Vietnamese', value: 'Vietnamese' });
            nationalities.push({ label: 'Vincentian', value: 'Vincentian' });
            nationalities.push({ label: 'Wallis and Futuna Islander', value: 'Wallis and Futuna Islander' });
            nationalities.push({ label: 'Welsh', value: 'Welsh' });
            nationalities.push({ label: 'Yemeni', value: 'Yemeni' });
            nationalities.push({ label: 'Zambian', value: 'Zambian' });
            nationalities.push({ label: 'Zimbabwean', value: 'Zimbabwean' });
            return nationalities;
        }

        static getGenders(): SelectItem[] {
            var genders = new Array<SelectItem>();
            genders.push({ label: 'Choose....', value: '' });
            genders.push({ label: 'M', value: '1' });
            genders.push({ label: 'F', value: '2' });
            return genders;
        }

        static getScoreTypes(gameypeID?: number, teamTypeID?: number): Array<SelectItem> {
            if (!gameypeID || teamTypeID === null) {
                return [];
            }
            if (gameypeID == 2 || ((gameypeID == 1 || gameypeID == 3) && (teamTypeID == 20 || teamTypeID == 60 || teamTypeID == 70 || teamTypeID == 80))) {
                return [
                    { value: 1, label: 'Best One' }
                ];
            }
            if ((gameypeID == 1 || gameypeID == 3) && teamTypeID == 30) {
                return [
                    { value: 1, label: 'Best One' },
                    { value: 99, label: 'Best All' }
                ];
            }
            if ((gameypeID == 1 || gameypeID == 3) && teamTypeID == 40) {
                return [
                    { value: 1, label: 'Best One' },
                    { value: 2, label: 'Best Two' },
                    { value: 99, label: 'Best All' }
                ];
            }

            if ((gameypeID == 1 || gameypeID == 3) && teamTypeID == 50) {
                return [
                    { value: 1, label: 'Best One' },
                    { value: 2, label: 'Best Two' },
                    { value: 3, label: 'Best Three' },
                    { value: 99, label: 'Best All' }
                ];
            }

            return [
                { value: 1, label: 'Best One' },
                { value: 2, label: 'Best Two' },
                { value: 3, label: 'Best Three' },
                { value: 4, label: 'Best Four' },
                { value: 5, label: 'Best Five' },
                { value: 99, label: 'Best All' }
            ];
        }

        static getHoleDetails(currentCourse: Course): SelectItem[] {
            var holes = _.sortBy(currentCourse.holes, 'holeNumber');
            var courseHoles = new Array<SelectItem>();
            courseHoles.push({ label: 'Choose....', value: '' });
            for (let z = 0; z < holes.length; z++) {
                courseHoles.push({ label: holes[z].holeNumber.toString(), value: holes[z].holeNumber });
            }
            //Can't even remember why we are doing this - I suspect dirty data
            var emptyRemoved = _.remove(courseHoles, function (hole) {
                return hole.value != '';
            });
            courseHoles = emptyRemoved;
            return courseHoles;
        }


        static getTeamformat(): SelectItem[] {
            var teamformat = new Array<SelectItem>();
            teamformat.push({ label: 'Choose....', value: null });
            teamformat.push({ label: 'Individual', value: '1' });
            teamformat.push({ label: 'Team', value: '2' });
            teamformat.push({ label: 'Matchplay', value: '3' });
            teamformat.push({ label: 'Tournament Skins', value: '6' });
            return teamformat;
        }

        static getPlayerTypes(includeAll: boolean = false): SelectItem[] {
            var playertype = new Array<SelectItem>();
            playertype.push({ label: 'Choose....', value: '' });
            if (includeAll) {
                playertype.push({ label: 'All Players', value: '100' });
            }

            //Player = 1
            //Captain = 2
            //Guest = 3
            //Pro = 4
            //Celeb = 5
            //Local = 6
            //VIP = 7
            //Junior = 8
            //Club = 9
            //Senior = 10
            //Open = 11
            //Staff = 12
            //PGN = 71
            //Premier = 72
            //Non_Premier = 73
            //Cat_1 = 75
            //Cat_2 = 76
            //Cat_3 = 77
            //Cat_4 = 78
            //Cat_5 = 79
            //Gross = 81
            //Net = 82
            //Marker = 91

            playertype.push({ label: 'Player', value: '1' });
            playertype.push({ label: 'Captain', value: '2' });
            playertype.push({ label: 'Guest', value: '3' });
            playertype.push({ label: 'Pro', value: '4' });
            playertype.push({ label: 'Celeb', value: '5' });
            playertype.push({ label: 'Local', value: '6' });
            playertype.push({ label: 'VIP', value: '7' });
            playertype.push({ label: 'Junior', value: '8' });
            playertype.push({ label: 'Club', value: '9' });
            playertype.push({ label: 'Senior', value: '10' });
            playertype.push({ label: 'Open', value: '11' });
            playertype.push({ label: 'Staff', value: '12' });

            playertype.push({ label: 'PGN', value: '71' });
            playertype.push({ label: 'Premier', value: '72' });
            playertype.push({ label: 'Non Premier', value: '73' });
            playertype.push({ label: 'Cat 1', value: '75' });
            playertype.push({ label: 'Cat 2', value: '76' });
            playertype.push({ label: 'Cat 3', value: '77' });
            playertype.push({ label: 'Cat 4', value: '78' });
            playertype.push({ label: 'Cat 5', value: '79' });
            playertype.push({ label: 'Gross', value: '81' });
            playertype.push({ label: 'Net', value: '82' });
            playertype.push({ label: 'Marker', value: '91' });
            return playertype;
        }

        static getPlayerTypeText(typeId: string): string {
            var playerTypes = this.getPlayerTypes(false);
            if (typeId) {
                var type = _.find(playerTypes, 'value', typeId);
                if (type) {
                    return type.label;
                }
            }
            return '';
        }
        static getBasicGameformat(): SelectItem[] {
            var gameformat = new Array<SelectItem>();
            gameformat.push({ label: 'Choose....', value: null });
            gameformat.push({ label: 'Strokeplay', value: '1' });
            gameformat.push({ label: 'Matchplay', value: '2' });
            gameformat.push({ label: 'Stableford', value: '3' });
            gameformat.push({ label: 'Skins', value: '4' });
            return gameformat;
        }
        static getPointsGameFormat(): SelectItem[] {
            var gameformat = new Array<SelectItem>();
            gameformat.push({ label: 'Choose....', value: null });
            gameformat.push({ label: 'Strokeplay', value: '1' });            
            gameformat.push({ label: 'Stableford', value: '3' });            
            return gameformat;
        }
        static getStableFordFormat(): SelectItem[] {
            var gameformat = new Array<SelectItem>();            
            gameformat.push({ label: 'Stableford', value: '3' });            
            return gameformat;
        }
        static getStrokeplayFormat(): SelectItem[] {
            var gameformat = new Array<SelectItem>();            
            gameformat.push({ label: 'Strokeplay', value: '1' });                        
            return gameformat;
        }
        static getGameformat(): SelectItem[] {
            var gameformat = new Array<SelectItem>();
            gameformat.push({ label: 'Choose....', value: null });
            gameformat.push({ label: 'Strokeplay', value: '1' });
            gameformat.push({ label: 'Matchplay', value: '2' });
            gameformat.push({ label: 'Stableford', value: '3' });
            gameformat.push({ label: 'Skins', value: '4' });
            gameformat.push({ label: 'PowerPlay', value: '5' });
            gameformat.push({ label: 'Staff', value: '12' });
            gameformat.push({ label: 'Pro', value: '4' });
            return gameformat;
        }

        static getScoringmethod(): SelectItem[] {
            var scoringmethod = new Array<SelectItem>();
            scoringmethod.push({ label: 'Choose....', value: '' });
            scoringmethod.push({ label: 'Best One', value: '1' });
            scoringmethod.push({ label: 'Best Two', value: '2' });
            scoringmethod.push({ label: 'Best Three', value: '3' });
            scoringmethod.push({ label: 'Best Four', value: '4' });
            scoringmethod.push({ label: 'Best Five', value: '5' });
            scoringmethod.push({ label: 'All Scores Count', value: '99' });
            return scoringmethod;
        }

        static getSelectedtees(): SelectItem[] {
            var selectedtees = new Array<SelectItem>();
            selectedtees.push({ label: 'Choose....', value: null });
            selectedtees.push({ label: 'All', value: '0' });
            selectedtees.push({ label: 'Male', value: '1' });
            selectedtees.push({ label: 'Female', value: '2' });
            return selectedtees;
        }

        static getScoringtype(): SelectItem[] {
            var scoringtype = new Array<SelectItem>();
            scoringtype.push({ label: 'Net', value: '3' });
            scoringtype.push({ label: 'Gross', value: '2' });
            return scoringtype;
        }

        static getAggregatetype(): SelectItem[] {
            var aggregatetype = new Array<SelectItem>();
            aggregatetype.push({ label: 'Choose....', value: null });
            aggregatetype.push({ label: 'Inherit', value: '0' });
            aggregatetype.push({ label: 'All', value: '1' });
            aggregatetype.push({ label: 'Only Previous', value: '2' });
            aggregatetype.push({ label: 'None', value: '3' });
            return aggregatetype;
        }

        static getAggregatethru(): SelectItem[] {
            var aggregatethru = new Array<SelectItem>();
            aggregatethru.push({ label: 'Choose....', value: null });
            aggregatethru.push({ label: 'All', value: '1' });
            aggregatethru.push({ label: 'Only Previous', value: '2' });
            aggregatethru.push({ label: 'None', value: '3' });
            return aggregatethru;
        }

        static getCountbacktype(): SelectItem[] {
            var countbacktype = new Array<SelectItem>();
            countbacktype.push({ label: 'Choose....', value: null });
            countbacktype.push({ label: 'Standard 9631', value: '1' });
            countbacktype.push({ label: 'Handicap', value: '2' });
            countbacktype.push({ label: 'Stroke Index', value: '3' });
            countbacktype.push({ label: 'None', value: '4' });
            return countbacktype;
        }


        static getSkincurrencytypes(): SelectItem[] {
            var skincurrencytypes = new Array<SelectItem>();
            skincurrencytypes.push({ label: 'Choose....', value: null });
            skincurrencytypes.push({ label: 'Points', value: '1' });
            skincurrencytypes.push({ label: 'Pounds', value: '2' });
            skincurrencytypes.push({ label: 'Dollars', value: '3' });
            skincurrencytypes.push({ label: 'Euros', value: '4' });
            return skincurrencytypes;
        }
        static getFreezeOnXHole(): SelectItem[] {
            var FreezeOnXHole = new Array<SelectItem>();
            FreezeOnXHole.push({ label: '0', value: 0 });
            FreezeOnXHole.push({ label: '1', value: 1 });
            FreezeOnXHole.push({ label: '2', value: 2 });
            FreezeOnXHole.push({ label: '3', value: 3 });
            FreezeOnXHole.push({ label: '4', value: 4 });
            FreezeOnXHole.push({ label: '5', value: 5 });
            FreezeOnXHole.push({ label: '6', value: 6 });
            FreezeOnXHole.push({ label: '7', value: 7 });
            FreezeOnXHole.push({ label: '8', value: 8 });
            FreezeOnXHole.push({ label: '9', value: 9 });
            FreezeOnXHole.push({ label: '10', value: 10 });
            FreezeOnXHole.push({ label: '11', value: 11 });
            FreezeOnXHole.push({ label: '12', value: 12 });
            FreezeOnXHole.push({ label: '13', value: 13 });
            FreezeOnXHole.push({ label: '14', value: 14 });
            FreezeOnXHole.push({ label: '15', value: 15 });
            FreezeOnXHole.push({ label: '16', value: 16 });
            FreezeOnXHole.push({ label: '17', value: 17 });
            FreezeOnXHole.push({ label: '18', value: 18 });
            return FreezeOnXHole;
        }

        static getSkinrollovertype(): SelectItem[] {
            var skinrollovertype = new Array<SelectItem>();
            skinrollovertype.push({ label: 'Choose....', value: null });
            skinrollovertype.push({ label: 'Halved', value: '1' });
            skinrollovertype.push({ label: 'Rollover', value: '2' });
            return skinrollovertype;
        }

        static getStattypes(): SelectItem[] {
            var stattypes = new Array<SelectItem>();
            stattypes.push({ label: 'Choose....', value: null });
            stattypes.push({ label: 'Score Breakdown', value: '1' });
            stattypes.push({ label: 'Driving Accuracy', value: '2' });
            stattypes.push({ label: 'Green In Regulation', value: '3' });
            stattypes.push({ label: 'Putting', value: '4' });
            stattypes.push({ label: 'Sand Saves', value: '5' });
            stattypes.push({ label: 'Best On Par 3s', value: '20' });
            stattypes.push({ label: 'Best On Par 4s', value: '21' });
            stattypes.push({ label: 'Best On Par 5s', value: '22' });
            stattypes.push({ label: 'Best On Front 9', value: '23' });
            stattypes.push({ label: 'Best On Back 9', value: '24' });
            stattypes.push({ label: 'Total Birdies', value: '25' });
            stattypes.push({ label: 'Total Pars', value: '26' });
            stattypes.push({ label: 'Total Bogeys', value: '27' });
            stattypes.push({ label: 'Msot Rounds', value: '28' });
            stattypes.push({ label: 'Most Courses', value: '29' });
            return stattypes;
        }

        static getHoleConfig(): SelectItem[] {
            var holeconfig = new Array<SelectItem>();
            holeconfig.push({ label: 'Choose....', value: '' });
            holeconfig.push({ label: 'Best 1 Net', value: 'Q:1.GN:3.G:0' });
            holeconfig.push({ label: 'Best 2 Net', value: 'Q:2.GN:3.G:0' });
            holeconfig.push({ label: 'Best 3 Net', value: 'Q:3.GN:3.G:0' });
            holeconfig.push({ label: 'Best 4 Net (All)', value: 'Q:4.GN:3.G:0' });
            holeconfig.push({ label: 'Best Net + Best Gross', value: 'Q:1.GN:3.G:0|Q:1.GN:2.G:0' });
            //holeconfig.push({ label: 'Best 2 Net + Best 1 Gross', value: 'Q:2.GN:3.G:0|Q:1.GN:2.G:0' });
            //holeconfig.push({ label: 'Best 1 Net + Best 2 Gross', value: 'Q:1.GN:3.G:0|Q:1.GN:2.G:0' });
            // holeconfig.push({ label: 'Best 1 Female + Best 1 Male', value: 'Q:1.GN:0.G:2|Q:1.GN:0.G:0' });
            // holeconfig.push({ label: 'Best 2 Female + Best 1 Male', value: 'Q:2.GN:0.G:2|Q:1.GN:0.G:0' });
            // holeconfig.push({ label: 'Best 1 Female + Best 2 Male', value: 'Q:1.GN:0.G:2|Q:1.GN:0.G:0' });
            // holeconfig.push({ label: 'Best 1 Female Net + Best 1 Male Gross', value: 'Q:1.GN:3.G:2|Q:1.GN:2.G:1' });
            return holeconfig;
        }

        static getTeamColours(): SelectItem[] {
            var teamColoursSelect = new Array<SelectItem>();
            teamColoursSelect.push({ value: '', label: 'Choose...', });
            teamColoursSelect.push({ value: '49176d', label: 'Aubergine', });
            teamColoursSelect.push({ value: '000000', label: 'Black' });
            teamColoursSelect.push({ value: 'a25043', label: 'Blended' });
            teamColoursSelect.push({ value: '1843e2', label: 'Blue' });
            teamColoursSelect.push({ value: 'cd7f32', label: 'Bronze' });
            teamColoursSelect.push({ value: 'b87333', label: 'Copper' });
            teamColoursSelect.push({ value: 'fefdfd', label: 'Female White' });
            teamColoursSelect.push({ value: 'c3a300', label: 'Gold' });
            teamColoursSelect.push({ value: '045c06', label: 'Green' });
            teamColoursSelect.push({ value: 'e0dad1', label: 'Grey' });
            teamColoursSelect.push({ value: '11ac8d', label: 'Jade' });
            teamColoursSelect.push({ value: 'df0101', label: 'New Red' });
            teamColoursSelect.push({ value: 'f88600', label: 'Orange' });
            teamColoursSelect.push({ value: 'ffc0cb', label: 'Pink' });
            teamColoursSelect.push({ value: '6d4061', label: 'Purple' });
            teamColoursSelect.push({ value: 'db0e0e', label: 'Red' });
            teamColoursSelect.push({ value: '9fd6f1', label: 'Sapphire Blue' });
            teamColoursSelect.push({ value: 'e2e2e2', label: 'Silver' });
            teamColoursSelect.push({ value: 'b07aa2', label: 'Violet' });
            teamColoursSelect.push({ value: 'fe5000', label: 'VPAR Orange' });
            teamColoursSelect.push({ value: 'ffffff', label: 'White' });
            teamColoursSelect.push({ value: 'f3f524', label: 'Yellow' });
            return teamColoursSelect;
        }

        static getFlighStatus(): SelectItem[] {
            var flighststus = new Array<SelectItem>();
            flighststus.push({ label: 'Choose....', value: '100' });
            flighststus.push({ label: 'Finished', value: '10' });
            flighststus.push({ label: 'Playing', value: '20' });
            flighststus.push({ label: 'Arrived', value: '30' });
            flighststus.push({ label: 'Missed Cut', value: '40' });
            flighststus.push({ label: 'No Result', value: '50' });
            flighststus.push({ label: 'Retired', value: '60' });
            flighststus.push({ label: 'Withdrawn', value: '80' });
            flighststus.push({ label: 'Disqualified', value: '90' });
            return flighststus;
        }

        static getBooleanOptions(): SelectItem[] {
            var options = new Array<SelectItem>();
            options.push({ label: 'Yes', value: true });
            options.push({ label: 'No', value: false });
            return options;
        }
    }
    export class RoundHelper {
        static getTeamDisplay(currentRound: Round): boolean {
            var notIndividual = (currentRound.teamType != 0);
            var MP = (currentRound.gameType == 2);
            var teamDisplay = MP || notIndividual;
            return teamDisplay;
        }
        static shotsDisplay(currentRound: Round): boolean {
            return (currentRound.gameType == 2);
        }
        static haveIndividualScores(currentRound: Round) {
            // exclude greensomes(60), foursomes(70) and scramble(80)
            return currentRound.teamType < 60;
        }

        static getStatsLines(isStableford: boolean) {
            return [{ title: 'Best on Par 3s', total: isStableford ? 'AVG PTS' : 'AVG', field: 'AveragePar3s', order: isStableford ? 'desc' : 'asc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true},
                { title: 'Best on Par 4s', total: isStableford ? 'AVG PTS' : 'AVG', field: 'AveragePar4s', order: isStableford ? 'desc' : 'asc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true },
                { title: 'Best on Par 5s', total: isStableford ? 'AVG PTS' : 'AVG', field: 'AveragePar5s', order: isStableford ? 'desc' : 'asc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true},
                { title: 'Best Front 9', total: 'TOTAL', field: 'Front9', order: 'asc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true},
                { title: 'Best Back 9', total: 'TOTAL', field: 'Back9', order: 'asc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true},
                { title: 'Most Pars', total: 'TOTAL', field: 'Pars', order: 'desc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true},
                { title: 'Most Birdies', total: 'TOTAL', field: 'Birdies', order: 'desc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true },
                { title: 'Most Eagles', total: 'TOTAL', field: 'Eagles', order: 'desc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true},
                { title: 'Most Bogeys', total: 'TOTAL', field: 'Bogeys', order: 'desc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: true },
                { title: 'Total Putts', total: 'TOTAL', field: 'totalPutts', order: 'desc', players: [], searchedPlayer: null, showSearchedPlayer: false, netGross: false }]
        }
    }
}
