<div *ngIf="showHandicapDetails === false; else showcontent" class="panel-content no-results panel">
    <span>The handicap tool has been disabled because this is a {{ round.gameType === 2 ? 'Matchplay' : (round.teamType === 60 ? 'Greensomes' : 'Foursomes') }} format game.</span>
    <span>This tool will be included in this format in our phase two release.</span>
</div>
<ng-template #showcontent>
    <div class="row">
        <div class="col-lg-12">
            <div class="panel">
                <div class="panel-content admin">
                    <div class="row m-b-10">
                        <div class="header-group border-bottom" style="margin:5px 15px 0px 15px">
                            <div class="text-capitalize headerText">Handicap <span class="bold">CALCULATOR</span> </div>
                        </div>
                    </div>
                    <div *ngIf="dataLoaded">
                        <div id="div-save-message" class="unsavedchanges hidden"><p class="unsavedchangestag">You have unsaved handicap changes. Please press the SAVE button to save all changes.</p></div>
                        <div class="row m-l-40">
                            <h3>Course : {{courseData.courseTitle}}</h3>
                            <button class="btn m-b-5 update-btn" (click)="onClickAdjustMale(memberInfo)">Adjust Male</button>
                            <button class="btn m-b-5 update-btn" (click)="onClickAdjustFemale(memberInfo)">Adjust Female</button>
                            <button class="btn m-b-5 update-btn" (click)="onClickAdjustAll(memberInfo)">Adjust All</button>
                            <button class="btn m-b-5 update-btn pull-right m-R-100" id="save-btn" (click)="updatedInfo()" disabled>Save Changes</button>
                        </div>
                        <div class="row m-l-40">
                            <h3 >Male Tee Data </h3>
                            <div class="col-md-3">
                                <div>
                                    <label class="lbl_m5" for="male-course-rating">CR:</label>
                                    <input type="text" class="lbl_width" [(ngModel)]="courseData.maleTeeData.courseRating" name="CourseRating" id="male-course-rating">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div>
                                    <label class="lbl_m5" for="male-slope-rating">SR:</label>
                                    <input type="text" class="lbl_width" [(ngModel)]="courseData.maleTeeData.slopeRating" name="SlopeRating" id="male-slope-rating">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div>
                                    <label class="lbl_m5" for="male-par">Par:</label>
                                    <input type="text" class="lbl_width" [(ngModel)]="courseData.maleTeeData.par" name="malePar" id="male-par">
                                </div>
                            </div>
                        </div>
                        <div class="row m-l-40">
                        <h3>Female Tee Data</h3>
                        <div class="col-md-3">
                            <div>
                                <label class="lbl_m5" for="female-course-rating">CR:</label>
                                <input type="text" class="lbl_width" [(ngModel)]="courseData.femaleTeeData.courseRating" name="femaleCourseRating" id="female-course-rating">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label class="lbl_m5" for="female-slope-rating">SR:</label>
                                <input type="text" class="lbl_width" [(ngModel)]="courseData.femaleTeeData.slopeRating" name="femaleSlopeRating" id="female-slope-rating">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label class="lbl_m5" for="female-par">Par:</label>
                                <input type="text" class="lbl_width" [(ngModel)]="courseData.femaleTeeData.par" name="femalePar" id="female-par">
                            </div>
                        </div>
                    </div>
                    </div>
                    <hr />
                    <div class="row m-l-20 m-r-20">
                        <p-dataTable [value]="memberInfo" responsiveLayout="stack" [resizableColumns]="true" columnResizeMode="fit" [tableStyle]="{'min-width':'50rem'}" selectionMode="single">
                          <p-column field="fullName" header="Name" styleClass="format-left"></p-column>
                          <p-column field="gender" header="Gender" styleClass="format-left"></p-column>
                          <p-column field="externalSystemProvider" header="Preferred Hcp" styleClass="format-left"></p-column>
                          <p-column field="profileIndex" header="Profile Index" styleClass="format-left"></p-column>
                          <p-column field="courseHandicap" header="Course Hcp" styleClass="format-left"></p-column>
                          <p-column field="handicapMultiplierPerc" header="Allowance %" styleClass="format-left"></p-column>
                          <p-column field="handicap" header="Game Hcp" styleClass="format-left"></p-column>
                          <p-column field="isManuallyAdjusted" header="Manually Adj" styleClass="format-left">
                            <ng-template let-memberInfo="rowData" pTemplate="body">
                                <input type="checkbox" data-checkbox="icheckbox_square-green" class="icheckbox_square-green" [(ngModel)]="memberInfo.isManuallyAdjusted" [checked]="memberInfo.isManuallyAdjusted" value="{{memberInfo.isManuallyAdjusted}}" />
                            </ng-template>
                        </p-column>
                        </p-dataTable>
                        <hr />
                    </div>
                 </div>
             </div>
        </div>
    </div>
</ng-template>
