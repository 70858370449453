import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent {
    title: string = 'VPAR';

    constructor(private translate: TranslateService) {
        this.translate.addLangs(["en-US", "en-GB"]);
        this.translate.setDefaultLang('en-GB');

        // get language from browser
        const userLang = navigator['language'] || navigator['userLanguage']; 

        this.translate.use(userLang);
    }
}
