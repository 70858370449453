<div class="row tournament-overview-data">
    <!-- Tournament Details -->
    <div class="detail-section first-section">
        <div class="overview-section width-100p">
            <p class="m-b-20 section-title">Tournament Details:</p>
            <p class="c-gray m-b-5 f-13" *ngFor="let round of tournament.rounds; let index = index"><span
                    class="round-list-bullet m-r-10">R{{index + 1}}</span><span
                    class="c-dark w-600">{{round.course.venueTitle}},&nbsp;{{round.course.title}}</span></p>
        </div>
    </div>
    <!-- Tournament Status and registration -->
    <div class="detail-section middle-section" [ngClass]="{'mobile-hidden': !loggedId}">
        <div class="overview-section top-icon-section">
            <div class="quick-link overview-icon">
                <div class="row m-auto">
                    <div class="icon circle">
                        <img src="assets/icons/menu-icons/avatar.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="counter-section">
                <p class="m-b-5 section-title">Registered Golfers</p>
                <label class="data">{{tournament?.numberOfPlayers}}</label>
            </div>
        </div>
        <div class="overview-section bottom-simple-section">
            <div>
                <p class="m-b-5 section-title">Tournament Status:</p>
            </div>
            <div class="label-open">
                <label *ngIf="isTournamentOpen">Open</label>
                <label *ngIf="!isTournamentOpen">Closed</label>
                <label class="switch pull-right" *onlyLoggedIn>
                    <input type="checkbox" [(ngModel)]="isTournamentOpen" (change)="onStatusChange($event)">
                    <span class="slider round"></span>
                </label>
            </div>

        </div>
    </div>
    <!-- Total golfers and VPAR members -->
    <div class="detail-section middle-section mobile-hidden">
        <div class="overview-section top-icon-section">
            <div class="quick-link overview-icon">
                <div class="row m-auto">
                    <div class="icon circle">
                        <i class="vpar-icon vpar-vpar"></i>
                    </div>
                </div>
            </div>
            <div class="counter-section">
                <p class="m-b-5 section-title">VPAR Member</p>
                <label class="data">{{vparMembers}}</label>
            </div>
        </div>
        <div class="overview-section bottom-simple-section">
            <p class="m-b-5 section-title">Tournament Progress:</p>
            <label class="f-28 w-600 c-dark m-b-0">Registration</label>
        </div>
    </div>
</div>

<confirm-dialog titleText="Close Tournament" message="Do you wish to close this tournament?"></confirm-dialog>