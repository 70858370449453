/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./player-renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/scorecard/scorecard.component.ngfactory";
import * as i4 from "../../../shared/components/scorecard/scorecard.component";
import * as i5 from "../../../services/scores.service";
import * as i6 from "../../../services/LeaderboardService";
import * as i7 from "ngx-toastr";
import * as i8 from "../../../services/login.service";
import * as i9 from "./player-renderer.component";
var styles_PlayerRendererComponent = [i0.styles];
var RenderType_PlayerRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlayerRendererComponent, data: {} });
export { RenderType_PlayerRendererComponent as RenderType_PlayerRendererComponent };
function View_PlayerRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-circle-right vparorange"]], null, null, null, null, null))], null, null); }
function View_PlayerRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-circle-down vparorange"]], null, null, null, null, null))], null, null); }
export function View_PlayerRendererComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scorecardComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "col-xs-12 border m-b-15 team"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-sm-10 col-xs-8 tall center cursor-pointer hole-config"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlayerRendererComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlayerRendererComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-sm-2 col-xs-4 background tall center teeTime"], ["style", "border-bottom:1px solid white;display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "bold hole-config"], ["style", "margin: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["Hole ", " - ", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "playerHolder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "scorecard", [], null, [[null, "onEditingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEditingChange" === en)) {
        var pd_0 = (_co.editScorecardChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ScorecardComponent_0, i3.RenderType_ScorecardComponent)), i1.ɵdid(15, 114688, [[1, 4]], 0, i4.ScorecardComponent, [i5.ScoresService, i6.LeaderboardService, i7.ToastrService, i8.LoginService], { tournamentOpen: [0, "tournamentOpen"], roundID: [1, "roundID"], teamType: [2, "teamType"], flightMemberID: [3, "flightMemberID"], flightID: [4, "flightID"], courseData: [5, "courseData"], maleTeeColor: [6, "maleTeeColor"], femaleTeeColor: [7, "femaleTeeColor"] }, { onEditingChange: "onEditingChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.open; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.open; _ck(_v, 7, 0, currVal_1); var currVal_5 = _co.tournamentOpen; var currVal_6 = _co.roundID; var currVal_7 = 0; var currVal_8 = _co.player.flightMemberID; var currVal_9 = _co.player.flightID; var currVal_10 = _co.courseData; var currVal_11 = _co.maleTeeColor; var currVal_12 = _co.femaleTeeColor; _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.player.profile.displayName; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.player.flight.startHole; var currVal_4 = _co.getTime(_co.player.flight.teeOffTime); _ck(_v, 12, 0, currVal_3, currVal_4); }); }
export function View_PlayerRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "player-renderer", [], null, null, null, View_PlayerRendererComponent_0, RenderType_PlayerRendererComponent)), i1.ɵdid(1, 49152, null, 0, i9.PlayerRendererComponent, [i1.ElementRef], null, null)], null, null); }
var PlayerRendererComponentNgFactory = i1.ɵccf("player-renderer", i9.PlayerRendererComponent, View_PlayerRendererComponent_Host_0, { player: "player", roundID: "roundID", tournamentOpen: "tournamentOpen", femaleTeeColor: "femaleTeeColor", maleTeeColor: "maleTeeColor", courseData: "courseData" }, { onEditingChange: "onEditingChange" }, []);
export { PlayerRendererComponentNgFactory as PlayerRendererComponentNgFactory };
