import {FlightMember} from './flightMember';
export class Team {
    teamID: number = 0;
    competitionID: number = 0;
    title: string = '';
    colour: string = '';
    colourText: string = '';
    logoFilename: string = '';
    pathLogoFilename: string = '';
    categoryArray: Array<string> = [];
    flightMembers: Array<FlightMember>;

    highlighted: boolean = false;
}