import { Component, EventEmitter, Input, OnDestroy, OnInit, Output ,ViewChild, ElementRef,SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { Subject, timer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Golfer, GolferMember } from 'src/app/models';
import { Member } from 'src/app/models/GolferMember';
import { GolfersServices } from 'src/app/services';
import { AppCookieService } from 'src/app/services/CookiesServices';

@Component({
  selector: 'add-golfer',
  templateUrl: './add-golfer.component.html',
  styleUrls: ['./add-golfer.component.scss']
})
export class AddGolferComponent implements OnInit, OnDestroy {
  // @ViewChild('emailInput',{static:true}) emailInputRef: ElementRef;

  @Input() displayPlayerDialog: boolean = false;
  @Output() displayPlayerDialogChange = new EventEmitter();
  @Output() closePlayerDialog = new EventEmitter();

  golferForm: FormGroup;
  submitted: boolean = false;
  email: string = '';
  isEmailValid: boolean = true;
  golfer: Golfer;
  member: Member;
  unsubscribe$ = new Subject<void>();
  competitionHostId: number;
  uploadProgress: boolean = false;
  uploadSuccesful: boolean = false;
  uploadResult: boolean = false;
  uploadFailed: boolean = false;
  uploadSummary: boolean = false;
  showGolfersDialog : boolean = true;
  numberOfMembers: number;
  duplicateGolfersNumbers:number;
  newGolfersNumbers:number;
  addedGolfersNumbers:number;
  members: GolferMember[];
  @Input() duplicateGolfers: string[] = [];
  @Input() addedGolfers: string[] = [];
  @Input() newGolfers:string[] = [];
  addedGolfersFromFile: boolean = false;
  duplicateGolfersFromFile: boolean = false;
  newGolfersFromFile: boolean = false;

  constructor(private _fb: FormBuilder, private golferService: GolfersServices, private cookieService : AppCookieService) { }

  ngOnInit() {    
    this.golferService.Golfer$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.golfer = data;
    });    
    
    this.golferForm = this._fb.group({
      emailAddress: [this.email, Validators.compose([Validators.required, EmailValidators.normal])]
    });
    this.competitionHostId = this.cookieService.getCompetitionHostIdCookie();        
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateProgress(change: boolean) {
    this.uploadProgress = change;
  }

  showUploadSuccess(change: boolean) {
    this.uploadSuccesful = change;
  }

  showUploadFailed(change: boolean) {
    this.uploadFailed = change;
  }

  showUploadResults(change: boolean) {
    this.uploadResult = change;
    if (this.uploadFailed && this.uploadResult) {
      this.uploadFailed = true;
    } else {
      this.uploadSuccesful = true;
      const obs$ = timer(1000).subscribe(() => {
        this.showSummary();
      })
      setTimeout(() => { obs$.unsubscribe() }, 4500)
    }
    if (this.uploadSuccesful) {
      document.getElementById("fail").style.zIndex = "98";
    }
  }


  showSummary() {    
    this.uploadSummary = true;
    this.duplicateGolfersNumbers = this.duplicateGolfers.length;
    this.addedGolfersNumbers = this.addedGolfers.length;
    this.newGolfersNumbers = this.newGolfers.length;
    this.numberOfMembers = this.duplicateGolfersNumbers+this.addedGolfersNumbers+this.newGolfersNumbers;    
  }

  showAddedGolferList() {
    this.addedGolfersFromFile = true;
  }
  showDuplicateGolfersList(){
    this.duplicateGolfersFromFile = true;
  }
  showNewGolfersList(){
    this.newGolfersFromFile = true;
  }

  inviteAllNew() {    
    this.golferService.inviteAll(this.newGolfers)
  }

  visibleChange(event) {
    this.displayPlayerDialogChange.emit(event);
    this.submitted = false;
  }
  showBulkUpload(emails: GolferMember[]) {
    this.members = emails;
    this.golferService.addByEmailBulk(this.members);
}

  save(golfer: any) {
    this.submitted = true;
    if (this.golferForm.valid) {
      this.golferService.addByEmail(golfer.emailAddress,this.competitionHostId).subscribe(data => {
        this.displayPlayerDialog = false;
        this.golferService.stopConnection();
        this.displayPlayerDialogChange.emit(this.displayPlayerDialog);
      }, err => console.error(err),
        () => console.log('Allocated player')
      );
      this.golferForm.reset();
      this.submitted = false;
    }
  } 

  uploadList(event) {
    this.golferService.uploadDocument(event);
  }
  cancel(){        
    this.golferService.stopConnection()
    this.uploadResult = false;
    this.uploadSummary = false;
    this.duplicateGolfers = [];
    this.addedGolfers = [];
    this.newGolfers = [];
    this.email = '';
    this.golfer = null;
    this.isEmailValid = true;     
    this.closePlayerDialog.emit();
  }
  onInputChange(){
    if(this.email === ''){
      this.member = null;
    }
  }
  downloadTemplate() {
    const fileName = 'template.xlsx';
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileUrl = 'assets/templates/sample.xlsx';
    const link = document.createElement('a');

    link.href = fileUrl;
    link.download = fileName;
    link.type = fileType;
    link.click();
  }

  enterEmail(event) {
    const inputValue = (document.getElementById('InputField') as HTMLInputElement).value;
    if(inputValue === ''){
      this.member = null;
      this.golfer = null;
    }

    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!pattern.test(this.email)) {
      this.isEmailValid = false;
    }else{
      this.isEmailValid = true;
    }

    this.golferService.getGolfersByEmail(this.email);
    this.golferService.Branding$
    .pipe(
      takeUntil(this.unsubscribe$),
      map(res => res.competitionHostID = this.competitionHostId)
    ).subscribe();
    this.golferService.checkGolferIsMember(this.email, this.competitionHostId).subscribe(data => this.member = data);

  }
}
