import { Component } from "@angular/core";
import { OrderOfMeritService } from "src/app/services/orderOfMeritService";
import { Router } from "@angular/router";

@Component({
  selector: "orderofmerit-component",
  template: "",
})
export class OrderOfMeritComponent {
  constructor(
    private orderOfMeritService: OrderOfMeritService,
    private router: Router
  ) {}
  ngOnInit() {
    this.orderOfMeritService.getConfig().subscribe(
      (cf) => {
        if (cf) {
          this.router.navigate(["/order-of-merit/overview"]);
        } else {
          this.router.navigate(["/order-of-merit/create"]);
        }
      },
      (error) => {
        console.error("Error fetching config:", error);
      }
    );
  }
}
