/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./orderofmerit-component";
import * as i2 from "../../services/orderOfMeritService";
import * as i3 from "@angular/router";
var styles_OrderOfMeritComponent = [];
var RenderType_OrderOfMeritComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OrderOfMeritComponent, data: {} });
export { RenderType_OrderOfMeritComponent as RenderType_OrderOfMeritComponent };
export function View_OrderOfMeritComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_OrderOfMeritComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "orderofmerit-component", [], null, null, null, View_OrderOfMeritComponent_0, RenderType_OrderOfMeritComponent)), i0.ɵdid(1, 114688, null, 0, i1.OrderOfMeritComponent, [i2.OrderOfMeritService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderOfMeritComponentNgFactory = i0.ɵccf("orderofmerit-component", i1.OrderOfMeritComponent, View_OrderOfMeritComponent_Host_0, {}, {}, []);
export { OrderOfMeritComponentNgFactory as OrderOfMeritComponentNgFactory };
