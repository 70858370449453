import { Component, Input } from '@angular/core';
import { Team, FlightMember, PlayerCreateDetail } from '../../models';
import { FlightMemberService } from '../../services';

@Component({
    selector: 'flight-member-renderer',
    templateUrl: 'flight.member.renderer.component.html',
    styleUrls: ['flight.member.renderer.component.scss']
})

export class FlightMemberRendererComponent {
    @Input() flightMember: FlightMember;
    @Input() competitionID: number;
    @Input() mergedCells: boolean;
    @Input() showShots: boolean;
    public draggedOver: boolean = false;
    constructor(public flightMemberService: FlightMemberService) {
    }

    dragStart(event) {
        var JSONflightMember = JSON.stringify(this.flightMember);
        event.dataTransfer.setData("flightmember", JSONflightMember);
    }

    dropEmpty($event) {
        var dropped = $event.dataTransfer.getData("flightmember") || $event.dataTransfer.getData("profile");
        if (dropped) {
            var detail = JSON.parse(dropped);
            if (detail.profileID) {
                this.flightMemberService.DroppedOnEmptyBallSlot(this.flightMember, this.competitionID, detail.profileID);
            }
        }
    }
    onDragEnter(event) {
    }

    onDragLeave(event) {
        this.draggedOver = false;;
    }

    onDragOver($event) {
        //var targetSelf = $event.srcElement == $event.currentTarget;
        //if (targetSelf) {
        //    this.draggedOver = false;
        //} else {

        //}
        this.draggedOver = true;
    }

    addFlightMember(): void {
        this.flightMemberService.AddFlightMemberToBall(this.flightMember);
    }

    dropExistingBall($event) {
        var JSONdroppedProfile = $event.dataTransfer.getData("profile");
        var JSONdroppedFlightMember = $event.dataTransfer.getData("flightmember");
        if (JSONdroppedProfile) {
            this.flightMemberService.DropPoolPlayerOnExistingFlightMember(this.flightMember, JSON.parse(JSONdroppedProfile));
        }
        if (JSONdroppedFlightMember) {
            this.flightMemberService.DropExistingBall(this.flightMember, JSON.parse(JSONdroppedFlightMember));
        }
    }

    dragEnd(event) {
        event.dataTransfer.clearData();
        this.draggedOver = false;
    }

    onDragEvent(event) {

        // move page scroll up/down while dragging
        if (event.clientY < 125) {
            window.scrollTo(0, window.scrollY - 15);
        } else if (window.innerHeight - 100 < event.clientY) {
            window.scrollTo(0, window.scrollY + 15);
        }
    }
}
