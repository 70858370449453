<div class="row">
    <div class="col-lg-12">
        <div class="panel">
            <div class="panel-content admin">
                <div class="row m-b-10">
                    <div class="header-group border-bottom" style="margin:5px 15px 0px 15px">
                        <div class="text-capitalize headerText">ADMIN <span class="bold">OVERVIEW</span> </div>
                    </div>
                </div>
                <div class="row m-l-40">
                    <h3>Wipe Devices</h3>
                    <div class="col-md-3 ">
                        <div><label>Team</label></div>
                        <div style="padding-bottom:25px;">
                            <p-dropdown [options]="currentTeams" [(ngModel)]="teamID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false"></p-dropdown>
                        </div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="wipeByTeam()">Wipe by Team</button>
                    </div>
                    <div class="col-md-3">
                        <div><label>Tee-time</label></div>
                        <div style="padding-bottom:25px;">
                            <p-dropdown [options]="currentTeeTimes" [(ngModel)]="teeTimeID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false"></p-dropdown>
                        </div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="wipeByTeeTime()">Wipe by Tee-time</button>
                    </div>
                    <div class="col-md-3">
                        <div><label>Players</label></div>
                        <div style="padding-bottom:25px;">
                            <p-dropdown [options]="currentFlightMembers" [(ngModel)]="playerID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false"></p-dropdown>
                        </div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="wipeByPlayer()">Wipe by Player</button>
                    </div>
                    <div class="col-md-3">
                        <div><label style="margin-bottom:54px;">All</label></div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="wipeAll()">Wipe All</button>
                    </div>
                </div>
                <hr />
                <div class="row m-l-40">
                    <div class="col-md-3">
                        <h3>Refresh Devices</h3>
                        <div><label>Device</label></div>
                        <div style="padding-bottom:25px;">
                            <p-dropdown [options]="currentTeeTimes" [(ngModel)]="deviceTeeTimeID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false"></p-dropdown>
                        </div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="refreshByTeeTime()">Refresh Device</button>
                    </div>
                    <div class="col-md-3">
                        <h3></h3>
                        <div><label style="margin-bottom:54px;">All</label></div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="refreshAll()">Refresh All</button>
                    </div>
                    <div class="col-md-3">
                        <h3>Manage Player Pool</h3>
                        <div><label>Player</label></div>
                        <div style="padding-bottom:25px;">
                            <p-dropdown [options]="currentplayerPool" [(ngModel)]="playerPoolID" [scrollHeight]="'250px'" [style]="{width:'180px'}" [autoWidth]="false"></p-dropdown>
                        </div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="removeByPlayer()">Remove Player</button>
                    </div>
                    <!--<div class="col-md-3">
                        <h3></h3>
                        <div><label style="margin-bottom:54px;">Select Player</label></div>
                        <button class="btn btn-dark m-b-5" (click)="removeAll()">Remove All Players</button>
                    </div>-->
                </div>
                <hr />
                <div class="row m-l-40">
                    <div class="col-md-3">
                        <h3>Leaderboards</h3>
                        <div><label style="margin-bottom:54px;">Refresh Round All Leaderboards</label></div>
                        <button class="btn btn-dark m-b-5 wipe-btn" (click)="refreshResults()">Refresh</button>
                    </div>
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-3">
                    </div>
                    <!--<div class="col-md-3">
                        <h3></h3>
                        <div><label style="margin-bottom:54px;">Select Player</label></div>
                        <button class="btn btn-dark m-b-5" (click)="removeAll()">Remove All Players</button>
                    </div>-->
                </div>
             </div>
         </div>
    </div>
</div>
<confirm-dialog titleText="Wipe Scores" message="Do you wish to wipe scores?"></confirm-dialog>