/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tournament-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tournament-summary.component";
import * as i3 from "@angular/router";
var styles_TournamentSummaryComponent = [".smallFlag[_ngcontent-%COMP%] {\n      height:15px;\n      width:20px;\n    }\n.ui-datatable-data[_ngcontent-%COMP%]   ui-widget-content[_ngcontent-%COMP%] {\n    border: none;\n    border-color: white;\n    border-bottom: 1px solid #dddddd !important;\n}", i0.styles];
var RenderType_TournamentSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TournamentSummaryComponent, data: {} });
export { RenderType_TournamentSummaryComponent as RenderType_TournamentSummaryComponent };
export function View_TournamentSummaryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { spinner: 0 }), i1.ɵqud(671088640, 2, { vparleaderboard: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "panel-content tournament-summay-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "row text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "pull-left m-l-20 m-b-20 f-20 w-800"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please click on each round to view leaderboards"]))], null, null); }
export function View_TournamentSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TournamentSummaryComponent_0, RenderType_TournamentSummaryComponent)), i1.ɵdid(1, 4440064, null, 0, i2.TournamentSummaryComponent, [i3.ActivatedRoute, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TournamentSummaryComponentNgFactory = i1.ɵccf("ng-component", i2.TournamentSummaryComponent, View_TournamentSummaryComponent_Host_0, {}, {}, []);
export { TournamentSummaryComponentNgFactory as TournamentSummaryComponentNgFactory };
