import { Component, Input } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { RoundLeaderboardConfig } from '../../../models';

@Component({
    selector: 'hole-configuration',
    templateUrl: 'hole.configuration.component.html',
    styleUrls: ['hole.configuration.component.scss']
})


export class HoleConfigurationComponent{

    holeConfig: SelectItem[] = [];

    @Input() roundLeaderboardConfig: RoundLeaderboardConfig;

    constructor() {
        this.holeConfig = DomainHelpers.SelectLists.getHoleConfig();
    }

}
